/*
 * PaymentData.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file PaymentData.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect } from 'react';
import { Fieldset } from 'primereact/fieldset';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Loader from '@abstract/abstractwebcommon-client/Loader';

/**
 * Interface for PaymentData properties.
 */
interface IPaymentDataProperties {
  wrapperRef: any;
  loading: boolean;
  error: any;
  values: any;
  serialNumber: number;
  frontendPaymentError: any;
  isShopPage?: boolean /**< If braintree form is on the shop page(public route) */;
  additionalClassNames?: string /**< Additional class names to perform validation or change styles */;
  loggedOutMessageBanner: JSX.Element /**< Logged out message banner */;
  isDisabled: boolean /**< To disable the product options or not */;
}

const PaymentData = ({
  wrapperRef,
  loading,
  error,
  values,
  serialNumber,
  frontendPaymentError,
  isShopPage,
  additionalClassNames = '',
  loggedOutMessageBanner,
  isDisabled
}: IPaymentDataProperties) => {
  const { t } = useTranslation();

  const getErrorBox = (errorMessage) => {
    if (errorMessage === 'No payment method is available.') {
      return (
        <Alert variant="warning" className="in">
          {t('checkoutPage.paymentData.warning')}
        </Alert>
      );
    }
  };

  const getFrontendErrorBox = (frontendError) => {
    return (
      <Alert variant="warning" className="in">
        Payment error <b>#{frontendError.errorId}</b>
        <br />
        Please contact our support and provide the error code
      </Alert>
    );
  };

  // Style Choose another way to pay span button inside Braintree Drop in Component.
  // If we update Braintree drop in NPM version, we should look if anything broke here.
  useEffect(() => {
    // wrapperRef.current?.children[0]?.children[1]?.children[6] is the path for the required element.
    if (wrapperRef.current?.children[0]?.children[1]?.children[6]) {
      const chooseAnotherWayToPaySpanHTML: HTMLDivElement =
        wrapperRef.current?.children[0]?.children[1]?.children[6];

      if (isShopPage) {
        chooseAnotherWayToPaySpanHTML.classList.add('p-button');
        chooseAnotherWayToPaySpanHTML.classList.add('p-button-primary');
      }
      chooseAnotherWayToPaySpanHTML.classList.add('btn');
      chooseAnotherWayToPaySpanHTML.classList.add('btn-primary');
      chooseAnotherWayToPaySpanHTML.classList.add('mt-3');
      chooseAnotherWayToPaySpanHTML.classList.add(
        'custom-choose-another-way-to-pay-button-style'
      );
      chooseAnotherWayToPaySpanHTML.classList.add('justify-content-center');
    }
  }, [wrapperRef.current?.children[0]?.children[1]?.children[6]]);

  return (
    <Fieldset
      legend={t('checkoutPage.paymentData.header', {
        number: `${serialNumber}.`
      })}
      className={`h-100 custom-fieldset ${additionalClassNames}`}
    >
      {process.env.NODE_ENV === 'development' &&
        'Sample credit card for testing: 4111 1111 1111 1111 02/22 CVV 123'}
      <Row>
        <Col className="mt-2 mb-0">
          {error && getErrorBox(error)}
          {frontendPaymentError && getFrontendErrorBox(frontendPaymentError)}
        </Col>
      </Row>
      <Row>
        {loggedOutMessageBanner}
        <Col className="h-auto">
          <div
            className={`${isDisabled ? 'disabled-payment' : ''}`}
            ref={wrapperRef}
          />
          {loading && <Loader />}
        </Col>
      </Row>
    </Fieldset>
  );
};

export default PaymentData;
