/*
 * SharedSubscriptions.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file SharedSubscriptions.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { PaymentMethod } from 'braintree';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {
  cancelSubscriptionService,
  getSubscriptionPaymentMethodService,
  refundSubscriptionService,
  retrySubscription,
  updateSubscriptionPaymentMethodService
} from '../../Services/Subscriptions/SharedSubscriptions';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { getAllSubscriptionsSuccess as getAllAdminSubscriptionsSuccess } from './AdminSubscriptions';
import { getSubscriptionsByUserUUIDSuccess } from './ClientSubscriptions';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { SubscriptionStatus } from '@abstract/abstractwebcommon-shared/enum/ecommerce/braintreeSubscriptionStatus';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const REFUND_SUBSCRIPTION_REQUEST = 'subscriptions/shared/refund/request';
const REFUND_SUBSCRIPTION_SUCCESS = 'subscriptions/shared/refund/success';
const REFUND_SUBSCRIPTION_FAILURE = 'subscriptions/shared/refund/failure';

const CANCEL_SUBSCRIPTION_REQUEST = 'subscriptions/shared/cancel/request';
const CANCEL_SUBSCRIPTION_SUCCESS = 'subscriptions/shared/cancel/success';
const CANCEL_SUBSCRIPTION_FAILURE = 'subscriptions/shared/cancel/failure';

const RETRY_SUBSCRIPTION_REQUEST: string = 'subscriptions/shared/retry/request';
const RETRY_SUBSCRIPTION_SUCCESS: string = 'subscriptions/shared/retry/success';
const RETRY_SUBSCRIPTION_FAILURE: string = 'subscriptions/shared/retry/failure';

const GET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST =
  'subscriptions/shared/get/subscription/paymentMethod/request';
const GET_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS =
  'subscriptions/shared/get/subscription/paymentMethod/success';
const GET_SUBSCRIPTION_PAYMENT_METHOD_FAILURE =
  'subscriptions/shared/get/subscription/paymentMethod/failure';

const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_REQUEST =
  'subscriptions/shared/update/subscription/paymentMethod/request';
const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS =
  'subscriptions/shared/update/subscription/paymentMethod/success';
const UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE =
  'subscriptions/shared/update/subscription/paymentMethod/failure';

const RESET_PAYMENT_METHOD_STATE =
  'subscriptions/paymentMethod/reset'; /**< Reset subscriptions paymentMethod state. */

const INITIAL_STATE = {
  isRequestingEndpoint: null,
  successReturn: null,
  errorReturn: null,
  isSubscriptionPaymentMethodFetching: false,
  subscriptionPaymentMethod: null,
  isSubscriptionPaymentMethodUpdating: false,
  isRetryingSubscription: false,
  isRetrySubscriptionSuccessful: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFUND_SUBSCRIPTION_REQUEST:
      return { ...state, isRequestingEndpoint: true };
    case REFUND_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isRequestingEndpoint: false,
        successReturn: action.payload.message
      };
    case REFUND_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isRequestingEndpoint: false,
        errorReturn: action.error
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { ...state, isRequestingEndpoint: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isRequestingEndpoint: false,
        successReturn: action.payload.message
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isRequestingEndpoint: false,
        errorReturn: action.error
      };
    case GET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        isSubscriptionPaymentMethodFetching: true,
        subscriptionPaymentMethod: null
      };
    case GET_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isSubscriptionPaymentMethodFetching: false,
        subscriptionPaymentMethod: action.payload.data
      };
    case GET_SUBSCRIPTION_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isSubscriptionPaymentMethodFetching: false,
        subscriptionPaymentMethod: null
      };
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_REQUEST:
      return { ...state, isSubscriptionPaymentMethodUpdating: true };
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isSubscriptionPaymentMethodUpdating: false
      };
    case UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isSubscriptionPaymentMethodUpdating: false
      };
    case RESET_PAYMENT_METHOD_STATE:
      return { ...state, subscriptionPaymentMethod: null };
    case RETRY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isRetryingSubscription: true,
        isRetrySubscriptionSuccessful: null
      };
    case RETRY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isRetryingSubscription: false,
        isRetrySubscriptionSuccessful: true
      };
    case RETRY_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isRetryingSubscription: false,
        isRetrySubscriptionSuccessful: false
      };
    default:
      return state;
  }
};

const refundSubscriptionRequest = () => ({
  type: REFUND_SUBSCRIPTION_REQUEST
});

const refundSubscriptionSuccess = (message: string) => ({
  type: REFUND_SUBSCRIPTION_SUCCESS,
  payload: { message }
});

const refundSubscriptionFailure = (error: string) => ({
  type: REFUND_SUBSCRIPTION_FAILURE,
  error
});

const cancelSubscriptionRequest = () => ({
  type: CANCEL_SUBSCRIPTION_REQUEST
});

const cancelSubscriptionSuccess = (message: string) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload: { message }
});

const cancelSubscriptionFailure = (error: string) => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  error
});

/// Get subscription payment method
const getSubscriptionPaymentMethodRequest = () => ({
  type: GET_SUBSCRIPTION_PAYMENT_METHOD_REQUEST
});

const getSubscriptionPaymentMethodSuccess = (data: PaymentMethod) => ({
  type: GET_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
  payload: { data }
});

const getSubscriptionPaymentMethodFailure = () => ({
  type: GET_SUBSCRIPTION_PAYMENT_METHOD_FAILURE
});

/// Update subscription payment method
const updateSubscriptionPaymentMethodRequest = () => ({
  type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_REQUEST
});

const updateSubscriptionPaymentMethodSuccess = () => ({
  type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS
});

const updateSubscriptionPaymentMethodFailure = () => ({
  type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE
});

/// Reset payment method state
export const resetPaymentMethodState = () => ({
  type: RESET_PAYMENT_METHOD_STATE
});

const retrySubscriptionRequest = () => ({
  type: RETRY_SUBSCRIPTION_REQUEST
});

const retrySubscriptionSuccess = (message: string) => ({
  type: RETRY_SUBSCRIPTION_SUCCESS,
  payload: { message }
});

const retrySubscriptionFailure = (error: string) => ({
  type: RETRY_SUBSCRIPTION_FAILURE,
  error
});

export const refundSubscriptionAction = (
  subscriptionID: string,
  subscriptionsList: ISubscription[],
  isAdminCall: boolean
) => async (dispatch) => {
  const errorMessage: string = 'Refund Subscription Failed.';

  try {
    dispatch(refundSubscriptionRequest());

    const result: IAPIEntityResponse<string> = await asyncErrorHandler(
      refundSubscriptionService(subscriptionID)
    );

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(refundSubscriptionFailure(result.error as string));
    } else {
      dispatch(refundSubscriptionSuccess(result.message ?? ''));

      // NOTE: Update cached subscription.
      // NOTE: Avoid the Refund button color to 'flick' between this request response and the request that fetches the new subscription list from the API.
      const updatedSubscriptionList: ISubscription[] = subscriptionsList.map(
        (subscription: ISubscription) => {
          if (subscription._id === subscriptionID) {
            const newSubscriptionStatus: ISubscription = { ...subscription };

            newSubscriptionStatus.status = SubscriptionStatus.Refunded;
            newSubscriptionStatus.localSubscriptionStatus =
              SubscriptionStatus.Refunded;
            newSubscriptionStatus.subscriptionEndDate = new Date();
            newSubscriptionStatus.lastBillingDate = new Date();

            return newSubscriptionStatus;
          }
          return subscription;
        }
      );

      if (isAdminCall) {
        dispatch(
          getAllAdminSubscriptionsSuccess(
            updatedSubscriptionList,
            updatedSubscriptionList.length
          )
        );
      } else {
        dispatch(
          getSubscriptionsByUserUUIDSuccess(
            updatedSubscriptionList,
            updatedSubscriptionList.length
          )
        );
      }

      showSuccessToast('Refund successfully requested!');
    }
  } catch (error) {
    dispatch(refundSubscriptionFailure(error as string));
    handleError({ message: errorMessage });
  }
};

export const cancelSubscriptionAction = (
  subscriptionID: string,
  subscriptionsList: ISubscription[],
  isAdminCall: boolean,
  isTerminateRequest = false
) => async (dispatch) => {
  const errorMessage: string =
    'Something went wrong, please contact our support team.';

  try {
    dispatch(cancelSubscriptionRequest());

    const result: IAPIEntityResponse<Partial<
      ISubscription
    >> = await asyncErrorHandler(
      cancelSubscriptionService(subscriptionID, isTerminateRequest)
    );

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(cancelSubscriptionFailure(result.error as string));
    } else {
      dispatch(cancelSubscriptionSuccess(result.message ?? ''));

      //NOTE: Update cached subscription.
      //NOTE: Avoid the Cancel button color to 'flick' between this request response and the request that fetches the new subscription list from the API.
      const updatedSubscriptionList: ISubscription[] = subscriptionsList.map(
        (subscription: ISubscription) => {
          if (subscription._id === subscriptionID) {
            const newSubscriptionStatus: ISubscription = { ...subscription };

            newSubscriptionStatus.status = result.data?.status as string;
            newSubscriptionStatus.localSubscriptionStatus =
              SubscriptionStatus.Canceled;

            if (result.data?.numberOfBillingCycles != null) {
              newSubscriptionStatus.subscriptionEndDate =
                result.data?.subscriptionEndDate;
            }

            newSubscriptionStatus.lastBillingDate =
              result.data?.lastBillingDate;
            newSubscriptionStatus.numberOfBillingCycles =
              result.data?.numberOfBillingCycles;

            return newSubscriptionStatus;
          }
          return subscription;
        }
      );

      if (isAdminCall) {
        dispatch(
          getAllAdminSubscriptionsSuccess(
            updatedSubscriptionList,
            updatedSubscriptionList.length
          )
        );
      } else {
        dispatch(
          getSubscriptionsByUserUUIDSuccess(
            updatedSubscriptionList,
            updatedSubscriptionList.length
          )
        );
      }

      showSuccessToast(result.message);
    }
  } catch (error) {
    dispatch(cancelSubscriptionFailure(error as string));
    handleError({ message: errorMessage });
  }
};

/**
 * Get subscription payment method
 * @param subscriptionID
 * @returns
 */
export const getSubscriptionPaymentMethodAction = (
  subscriptionID: string
) => async (dispatch) => {
  const errorMessage: string = 'Get subscription payment method Failed.';

  try {
    dispatch(getSubscriptionPaymentMethodRequest());

    const result: IAPIEntityResponse<PaymentMethod> = await asyncErrorHandler(
      getSubscriptionPaymentMethodService(subscriptionID)
    );

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(getSubscriptionPaymentMethodFailure());
    } else {
      dispatch(getSubscriptionPaymentMethodSuccess(result.data));
    }
  } catch (error) {
    dispatch(getSubscriptionPaymentMethodFailure());
    handleError({ message: errorMessage });
  }
};

/**
 * Update subscription payment method
 * @param subscriptionID
 * @param paymentMethodNonce
 * @returns
 */
export const updateSubscriptionPaymentMethodAction = (
  subscriptionID: string,
  paymentMethodNonce: string
) => async (dispatch) => {
  const errorMessage: string = 'Error in updating subscription payment method.';

  try {
    dispatch(updateSubscriptionPaymentMethodRequest());

    const result: IAPIEntityResponse<string> = await asyncErrorHandler(
      updateSubscriptionPaymentMethodService(subscriptionID, paymentMethodNonce)
    );

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(updateSubscriptionPaymentMethodFailure());
    } else {
      dispatch(updateSubscriptionPaymentMethodSuccess());
      showSuccessToast(
        result.message ?? 'Subscription payment method updated successfully!'
      );
    }
  } catch (error) {
    dispatch(updateSubscriptionPaymentMethodFailure());
    handleError({ message: errorMessage });
  }
};

export const retrySubscriptionAction = (
  subscriptionID: string,
  transactionPrice?: string /* The Production environment won't pass the transaction price. */,
  isAdminRequest: boolean = false /* Defines if the request is called by an admin or a client user. */
) => async (dispatch) => {
  const errorMessage: string = isAdminRequest
    ? 'Failed to retry subscription. Please check the logs for more details.'
    : 'Something went wrong, please contact our support team.';

  try {
    dispatch(retrySubscriptionRequest());

    const result: IAPIEntityResponse<string> = await asyncErrorHandler(
      retrySubscription(subscriptionID, transactionPrice)
    );

    if (result.error) {
      const returnedErrorMessage: string = result.error.message as string;

      // As our client side is recording logs instead of the API, we should do this hardcode validation as workaround to display the correct message to the user.
      // Otherwise we can't display a friendly message and also have a detailed log with the subscription information, as for example, its IDs.
      const messageToDisplay: string = returnedErrorMessage.includes(
        'The payment is being processed by our payment processor'
      )
        ? 'This subscription was recently retried. The payment is being processed by our payment processor'
        : errorMessage;

      handleError({ message: messageToDisplay });
      dispatch(retrySubscriptionFailure(result.error as string));
    } else {
      dispatch(retrySubscriptionSuccess(result.message ?? ''));
      showSuccessToast(
        'Your payment is being processed by our payment processor.'
      );
    }
  } catch (error) {
    dispatch(retrySubscriptionFailure(error as string));
    handleError({ message: errorMessage });
  }
};
