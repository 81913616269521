/*
 * CustomerTableExpansion.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file CustomerTableExpansion.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';

import './CustomerPage.css';

/**
 * Interface for CustomerTableExpansion properties.
 */
interface ICustomerTableExpansionProperties {
  transactions: any[];
}

export const CustomerTableExpansion = (
  properties: ICustomerTableExpansionProperties
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BaseDatatable
      value={properties.transactions}
      paginator={false}
      rows={100}
      parentClass="customerDatatable nestedDatatable nestedDatatable-withoutPagination" /**< ClassName for div Component.*/
    >
      {/* Used to prevent row selection on row click */}
      <Column selectionMode="multiple" className="d-none" />
      <Column
        field="customer"
        header={t('customersTable.customer.name')}
        className="p-col-descriptive-lg p-col-customer"
        body={(row: any) => {
          const customerDisplayName = row.customer
            ? `${row.customer.firstName} ${row.customer.lastName}`
            : '';
          return (
            <DatatableColumn
              title={t('customersTable.customer.name')}
              data={`${customerDisplayName}`}
            />
          );
        }}
      />
      <Column
        field="payment.transaction.customer.email"
        header={t('customersTable.customer.email')}
        className="p-col-descriptive-lg"
        body={(row: any) => (
          <DatatableColumn
            title={t('customersTable.customer.email')}
            data={row.customer ? row.customer.email : ''}
          />
        )}
      />
      <Column
        field="customer.address"
        header={t('customersTable.customer.address')}
        className="p-col-descriptive-lg d-table-cell d-sm-none d-xl-table-cell"
        headerClassName="p-col-descriptive-lg d-table-cell d-sm-none d-xl-table-cell"
        body={(row: any) => {
          const customer = row.customer;
          return (
            <>
              <DatatableColumn
                title={t('customersTable.customer.address')}
                data={`${customer.address1}`}
              />
              <DatatableColumn
                title={''}
                data={`${customer.city} ${customer.zipcode}`}
              />
              <DatatableColumn
                title={''}
                data={`${customer.stateOrProvince} ${customer.country}`}
              />
            </>
          );
        }}
      />
      <Column
        className="p-col-number"
        header={t('customersTable.customer.price')}
        field="priceCalculation.priceNet"
        body={(row: any) => (
          <DatatableColumn
            title={t('customersTable.customer.price')}
            data={currencyFormatter(row.priceCalculation.priceNet)}
          />
        )}
      />
      <Column
        field="products.product.name"
        header={t('customersTable.customer.product')}
        className="p-col-descriptive-lg d-table-cell d-sm-none d-xl-table-cell"
        headerClassName="p-col-descriptive-lg d-table-cell d-sm-none d-xl-table-cell"
        body={(row: any) => (
          <DatatableColumn
            title={t('customersTable.customer.product')}
            data={
              <>
                {row.products.map((productPurchase: any, index: number) => {
                  return (
                    <span key={index + 1}>
                      <span className="badge badge-secondary">
                        {productPurchase.product
                          ? productPurchase.product.name
                          : ''}
                      </span>{' '}
                      &nbsp;{' '}
                    </span>
                  );
                })}
              </>
            }
          />
        )}
      />
      <Column
        field="transactionFulfilled"
        header={t('customersTable.customer.recordCreatedSuccessfully')}
        className="p-col-date d-table-cell d-sm-none d-xxl-table-cell"
        headerClassName="p-col-date d-table-cell d-sm-none d-xxl-table-cell"
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.customer.recordCreatedSuccessfully')}
            data={<ShowCheckOrUncheckIcon value={!row.failureReasonID}/>
            }
          />
        )}
      />
      <Column
        field="createdAt"
        header={t('customersTable.customer.created')}
        body={(row: any) => (
          <DatatableColumn
            title={t('customersTable.customer.created')}
            data={row.createdAt ? formatDate(row.createdAt) : ''}
          />
        )}
        className="p-col-date d-table-cell d-sm-none d-xxl-table-cell"
        headerClassName="p-col-date d-table-cell d-sm-none d-xxl-table-cell"
      />
      <Column
        field="updatedAt"
        header={t('customersTable.customer.updated')}
        body={(row: any) => (
          <DatatableColumn
            title={t('customersTable.customer.updated')}
            data={row.updatedAt ? formatDate(row.updatedAt) : ''}
          />
        )}
        className="p-col-date d-table-cell d-sm-none d-3xl-table-cell"
        headerClassName="p-col-date d-table-cell d-sm-none d-3xl-table-cell"
      />
    </BaseDatatable>
  );
};
