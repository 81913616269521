/*
 * TransactionTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file TransactionTable.tsx
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Hooks';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { useTranslation } from 'react-i18next';
import { getUserTransactions } from '../../../Store/UserTransaction';
import { Col, Row } from 'react-bootstrap';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import TransactionTableExpansion from '../../Admin/Transactions/TransactionTableExpansion';
import { licenseCreated, regenerateInvoice } from '../../../Store/Transactions';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import Price from '../../SubComponents/Price';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { BASE_API_URL } from 'src/Config';
import { CustomerEntityHelper } from '@abstract/abstractwebcommon-shared/utils/CustomerEntityHelper';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';

/**
 * @interface IRows transactions rows from the database
 */
interface IRows {
  quantity: number;
  productRef: IProduct;
  product: {
    name: string;
  };
}
const TransactionsTable = () => {
  const { t } = useTranslation();
  const transactions: ITransactionState = useAppSelector(
    (state) => state.userTransactions
  );
  const dispatch = useDispatch();
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const [expandedRows, setExpandedRows] = useState<any>(); /**< Expand Rows. */
  const [isLicenseUpdateLoading, setLicenseUpdateLoading] = useState(
    false
  ); /**< LicenseUpdate Loading state. */

  const hashedCustomerEmail: string = CustomerEntityHelper.getHashedCustomerEmail(
    LocalStorage.getEmail()
  );

  /* TODO: We must change this any type to the ITransaction interface when we add the it in the AWC. */
  const getFreeTransactionBillingPeriod = (transaction: any): string =>
    `${formatDate(transaction.billingPeriodStartDate)} — ${formatDate(
      transaction.billingPeriodEndDate
    )}`;

  /* TODO: We must change this any type to the ITransaction interface when we add the it in the AWC. */
  const getPaidTransactionBillingPeriod = (transaction: any): string =>
    `${formatDate(transaction.billingPeriodStartDate)} — ${
      transaction.subscription.nextBillingDate
    }`;

  const productField = (
    products: IRows[],
    field: string,
    title: string,
    transaction: any /* TODO: We must change this any type to the ITransaction interface when we add the it in the AWC. */
  ) => {
    return (
      <DatatableColumn
        title={title}
        data={products.map((product: IRows, index: number) => (
          <div key={index}>
            {product.quantity ? field === 'quantity' && product.quantity : ''}

            {product.productRef ? (
              field === 'name' &&
              `${product.productRef?.name ?? product.product?.name}: ${
                transaction.subscription &&
                transaction.subscription.nextBillingDate != null
                  ? getPaidTransactionBillingPeriod(transaction)
                  : getFreeTransactionBillingPeriod(transaction)
              }`
            ) : (
              <span className="badge badge-danger">Deleted product</span>
            )}
          </div>
        ))}
      />
    );
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar onSearchTermChanged={(data) => handleFilterUpdate(data)} />
    </div>
  );

  /// Filter event handler
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(getUserTransactions(payload));
  };

  /// Sort event handler
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(getUserTransactions(updatedPayload));
  };

  /// Pagination event handler
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(getUserTransactions(updatedPayload));
  };

  /// Fetch all user transactions
  useEffect(() => {
    dispatch(getUserTransactions(payload));
  }, []);

  /// Regenerate Invoice handler.
  const regenerateInvoiceHandler = async (rowData: any) => {
    await asyncErrorHandler(
      dispatch(regenerateInvoice(rowData.customer[0].email, rowData._id))
    );
  };

  const markLicenseCreatedHandler = async (rowData: any) => {
    setLicenseUpdateLoading(true);
    await asyncErrorHandler(dispatch(licenseCreated(rowData._id)));
    setLicenseUpdateLoading(false);
    await asyncErrorHandler(dispatch(getUserTransactions(payload)));
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };
  
  return (
    <Row>
      <Col sm={12}>
        <BaseDatatable
          value={transactions.list}
          isLoading={!transactions.list}
          autoLayout
          expandedRows={expandedRows}
          header={header}
          first={payload.skip}
          rows={payload.limit}
          totalRecords={transactions.count}
          onPage={handlePageUpdate}
          onFilter={handleFilterUpdate}
          onSort={handleSortUpdate}
          sortField={payload.sort.sortField}
          sortOrder={payload.sort.sortOrder}
          rowsPerPageOptions={[10, 20, 50]}
          onRowExpand={expandRow}
          onRowCollapse={() => setExpandedRows([])}
          rowExpansionTemplate={(data: any) => (
            <TransactionTableExpansion
              rowData={data}
              regenerateInvoiceHandler={regenerateInvoiceHandler}
              markLicenseCreatedHandler={() => markLicenseCreatedHandler(data)}
              licenseUpdateLoading={isLicenseUpdateLoading}
              isAdminView={false}
            />
          )}
          responsive /**< Datatable responsive layout.*/
        >
          {/* Used to prevent row selection on row click */}
          <Column selectionMode="multiple" className="d-none" />
          <Column expander className="p-0 p-col-expander" />
          <Column
            sortable
            field="products.productName"
            header={t('user.page.transaction.productName')}
            body={(row: any) =>
              productField(
                row.products,
                'name',
                t('user.page.transaction.productName'),
                row
              )
            }
          />
          <Column
            sortable
            field="products.quantity"
            header={t('user.page.transaction.quantity')}
            className="d-table-cell d-sm-none d-md-table-cell p-col-number"
            headerClassName="d-table-cell d-sm-none d-md-table-cell p-col-number"
            body={(row: any) =>
              productField(
                row.products,
                'quantity',
                t('user.page.transaction.quantity'),
                row
              )
            }
          />
          <Column
            sortable
            header={t('user.page.transaction.price')}
            field="priceCalculation.priceNet"
            className="p-col-price"
            body={(row: any) => (
              <DatatableColumn
                title="Price"
                data={<Price amount={row.priceCalculation.priceNet} />}
              />
            )}
          />
          <Column
            sortable
            field="transactionFulfilled"
            header={t('user.page.transaction.licenseCreated')}
            className="d-table-cell d-sm-none d-xl-table-cell custom-header-min-width-allowed"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell custom-header-min-width-allowed"
            body={(rows: any) => (
              <DatatableColumn
                title={t('user.page.transaction.licenseCreated')}
                data={
                  <ShowCheckOrUncheckIcon value={rows.transactionFulfilled} />
                }
              />
            )}
          />
          <Column
            sortable
            field="failureReasonID"
            header={t('user.page.transaction.successFulPayment')}
            className="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
            headerClassName="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
            body={(rows: any) => (
              <DatatableColumn
                title={t('user.page.transaction.successFulPayment')}
                data={
                  <ShowCheckOrUncheckIcon
                    value={isStringEmptyOrNullOrUndefined(rows.failureReasonID)}
                  />
                }
              />
            )}
          />
          <Column
            field="purchaseDate"
            header={t('user.page.transaction.purchaseDate')}
            sortable
            className="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
            headerClassName="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
            body={(row: any) => (
              <DatatableColumn
                title={t('user.page.transaction.purchaseDate')}
                data={
                  row.purchaseDate
                    ? formatDate(new Date(Number(row.purchaseDate)))
                    : ''
                }
              />
            )}
          />
          <Column
            field="invoiceNo"
            header={t('user.page.transaction.invoice')}
            sortable
            className="p-col-number d-table-cell d-sm-none d-xxl-table-cell"
            headerClassName="p-col-number d-table-cell d-sm-none d-xxl-table-cell"
            body={(row: any) => (
              <DatatableColumn
                title={t('user.page.transaction.invoice')}
                data={
                  <a
                    key={row._id}
                    href={`${BASE_API_URL}/pdf/${row._id}/${hashedCustomerEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-transparent"
                  >
                    {row.invoiceNo}
                  </a>
                }
              />
            )}
          />
        </BaseDatatable>
      </Col>
    </Row>
  );
};

export default TransactionsTable;
