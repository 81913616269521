/*
 * Settings.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Settings.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchUserApplications, readSafe} from '../Services/UserSetting';
import {ISettings} from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {IApplications} from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import {IReducerAction} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {LocalStorage} from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const SETTING_FEATURE_KEY = 'settings';

const INITIAL_STATE: ISettings = {
  setting: {},
  isFetchingSettings: false,
  isFavouriteChanging: false,
  isChangingSetting: false,
  isLogoChanging: false,
  isFetchingUserApplications: true,
  userApplications: []
};

export const settingSlice = createSlice({
  name: SETTING_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getSettingsRequest(state) {
      state.isFetchingSettings = true;
      state.isFavouriteChanging = true;
      state.isLogoChanging = true;
    },
    getSettingsSuccess(state, action: any) {
      state.isFetchingSettings = false;
      state.isFavouriteChanging = false;
      state.isLogoChanging = false;
      const logo: string = action.payload.store.logo;
      const favicon: string = action.payload.store.favicon;
      const userFrontendBaseURL: string =
        action.payload.store.userFrontendBaseURL; /**< UserFrontendURL. */
      const applicationTitle: string =
        action.payload.store.applicationTitle; /**< Application Title. */
      state.setting = {
        logo,
        favicon,
        userFrontendBaseURL,
        applicationTitle
      };
      state.userApplications =
        action.payload.userApplications; /**< User Applications. */
    },
    getSettingsFailure(state) {
      state.isFavouriteChanging = false;
      state.isLogoChanging = false;
      state.isFetchingSettings = false;
    },
    getUserApplicationsRequest(state: ISettings) {
      state.isFetchingUserApplications = true;
    },
    getUserApplicationsSuccess(
      state: ISettings,
      action: IReducerAction<IApplications[]>
    ) {
      state.userApplications = action.payload;
      state.isFetchingUserApplications = false;
    },
    getUserApplicationsFailure(state: ISettings) {
      state.isFetchingUserApplications = false;
    }
  },
  extraReducers: {}
});

export const settingReducer = settingSlice.reducer;
export const settingActions = settingSlice.actions;

/**
 * Get all safe settings Action.
 */
export const getSafeSettings = createAsyncThunk(
  'setting/all',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getSettingsSuccess,
      getSettingsRequest,
      getSettingsFailure
    } = settingActions;

    try {
      dispatch(getSettingsRequest());
      const response = await asyncErrorHandler(readSafe());
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getSettingsFailure());
      } else {
        dispatch(getSettingsSuccess(response));
      }
    } catch (exception) {
      dispatch(getSettingsFailure());
      handleError({ message: exception.message });
    }
  }
);

/**
 * Get user's applications action.
 * @param payload
 */
export const getUserApplicationsAction = createAsyncThunk(
  'user/get/applications',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getUserApplicationsRequest: any =
      settingActions.getUserApplicationsRequest;
    const getUserApplicationsSuccess: any =
      settingActions.getUserApplicationsSuccess;
    const getUserApplicationsFailure: any =
      settingActions.getUserApplicationsFailure;

    try {
      dispatch(getUserApplicationsRequest());
      const response: IAPIEntityResponse<IApplications[]> = await asyncErrorHandler(fetchUserApplications());
      if (response.error) {
        handleError({
          message: response.error.message || 'Failed to fetch user applications'
        });
        dispatch(getUserApplicationsFailure());
      } else {
        dispatch(
          getUserApplicationsSuccess(
            response.data?.filter(
              (application) =>
                application.applicationUUID !==
                LocalStorage.getApplicationUUID()
            )
          )
        );
      }
    } catch (error) {
      dispatch(getUserApplicationsFailure());
      handleError({ message: error.message });
    }
  }
);
