/**
* NotFoundRedirect.tsx (abstractlicense) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2023
* @file NotFoundRedirect.tsx
* @author Alaguvelammal Alagusubbiah
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundRedirect: React.FC<any> = () => {
  const history: any = useHistory();

  useEffect(() => {
    history.push({ pathname: '/404' });
  }, []);

  return <></>;
};

export default NotFoundRedirect;
