/*
 * ProductDetails.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductDetails.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { BASE_API_URL } from '../../Config';
import { useDispatch } from 'react-redux';
import { reconnectAction } from '../../Store/UserAuth';
import { useTranslation } from 'react-i18next';
import { getTranslatedProductDescription } from '../../Utils/DynamicTranslate';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';

/**
 * Interface for ProductDetails properties.
 */
interface IProductDetailsProperties {
  product: {
    name?: string;
    _id?: any;
    image?: any;
    formattedSKU?: string /**< Removed non alpha-numeric characters from product SKU */;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  loading: boolean;
  purchaseEnabled?: boolean;
}

const ProductDetails = ({
  product,
  loading,
  purchaseEnabled = false
}: IProductDetailsProperties) => {
  const translation = useTranslation().t;
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();

  const handleImageLoaded = (e) => {
    setImageLoaded(true);
  };

  const history = useHistory();

  useEffect(() => {
    dispatch(reconnectAction());
  }, []);

  return (
    <>
      <Row id="productdetails">
        {loading && <Loader />}
        {product && !loading && (
          <>
            <Col sm={12}>
              <h1>
                {translation('shopPages.getProduct')} {product.name}
              </h1>
            </Col>
            <Col sm={12} className="text-center">
              <>
                {product.image ? (
                  <img src={product.image} className="product-image" />
                ) : (
                  <></>
                )}
              </>
            </Col>
            <Col sm={12}>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: getTranslatedProductDescription(product as IProduct)
                }}
              />
            </Col>
            {purchaseEnabled && (
              <Col sm={{ value: 3, offset: 3 }}>
                <Button
                  className="p-button p-button-primary btn-block"
                  variant="primary"
                  onClick={() => history.push(`/${product?.formattedSKU}/buy`)}
                >
                  {translation('checkoutPage.product_buy')}
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default React.memo(ProductDetails);
