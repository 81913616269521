/**
* DataTableSkeleton.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues K, 2022 
* @file DataTableSkeleton.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../SkeletonWrapper'

import './DataTableSkeleton.css'

/**
 * @interface DataTableSkeleton
 */
interface IDataTableSkeleton {
  numberOfLines?: number /**< Number of lines to render the skeleton */
  numberOfHeaders?: number[] /**< Number of headers and your width to render the skeleton */
}

/**
 * Render a skeleton for DataTable component when data is loading for it.
 * 
 * @param properties DataTableSkeleton interface
 * @returns JSX.Element
 */
const DataTableSkeleton = (properties: IDataTableSkeleton): JSX.Element => {
  const numberOfLinesToRender: number = properties.numberOfLines ?? 15 //15 number of lines to render if no value is informed
  const numberOfheadersToRender: number[] = properties.numberOfHeaders ?? []

  const windowWidth: number = window.innerWidth;

  //Render a skeleton for desktop view
  const SkeletonTemplateDesktopView: React.FunctionComponent  = () => (
    <div className="d-flex container-main-skeleton">
      {numberOfheadersToRender.map((headerWidth: number, index: number) =>
        <h1 className={`container-individual-skeleton ${index + 1 === numberOfheadersToRender.length ? '' : 'container-individual-skeleton-margin-right'}`}>
          <SkeletonWrapper width={headerWidth} />
        </h1>
      )}
    </div>
  )

  //Render a skeleton for tablet and mobile view.
  const SkeletonTemplateTabletMobileView: React.FunctionComponent = () => (
    <div className="container-main-skeleton">
      <h1><SkeletonWrapper /></h1>
      <h1><SkeletonWrapper /></h1>
      <h1><SkeletonWrapper /></h1>
    </div>
  )

  //Render the skeleton UI based on window size. If above 576px (the mobile <> desktop breakpoint) render the desktop skeleton.
  const handleNumberOfLinesToRender = () => {
    const htmlSkeleton = []
    
    for (let counter: number = 0; counter < numberOfLinesToRender; counter++) {
      //576px our current breakpoint to hide sidebar from left and display it on top.
      htmlSkeleton.push(windowWidth >= 576 ? <SkeletonTemplateDesktopView /> : <SkeletonTemplateTabletMobileView />);
    }

    return htmlSkeleton
  }

  return (
    <>
      {handleNumberOfLinesToRender()}
    </>
  )
}

export default DataTableSkeleton