/*
 * ProductPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useAnalytics } from 'use-analytics';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import {
  currencyFormatter,
  currencyType
} from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import { fetchOneProduct, resetProductState } from '../../Store/Products';
import { BASE_API_URL } from '../../Config';
import eventConstants from '../../Utils/EventConstants';
import { useTranslation } from 'react-i18next';
import ShopWrapper from './ShopWrapper';
import './ProductPage.css';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../Services/Logs';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { getTranslatedProductDescription } from '../../Utils/DynamicTranslate';
import { isUniqueProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';

/**
 * Interface for ProductPage properties.
 */
interface IProductPageProperties {
  product: any;
  fetchOneProduct: any;
  loading: boolean;
  isPublicRoute: boolean /**< Is Public Route */;
  shopHeaderHTML: string /**< ShopHeader HTML */;
  shopFooterHTML: string /**< ShopFooter HTML */;
  languageSelector: JSX.Element /**< Language selector element */;
}

const ProductPage = ({
  product,
  fetchOneProduct,
  loading,
  isPublicRoute = false,
  shopHeaderHTML = '',
  shopFooterHTML = '',
  languageSelector
}: IProductPageProperties) => {
  const translation = useTranslation().t;
  const analytics = useAnalytics();
  const { track } = eventConstants.event;
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();

  const imageLoadedHandler = () => {
    setImageLoaded(true);
  };

  const logAnalytics = (event, productId) => {
    analytics.track(event.name, {
      description: event.description,
      product: productId
    });
  };
  const productSKU = useParams().productSKU; /**< Product SKU */

  useEffect(() => {
    if (productSKU) {
      fetchOneProduct(productSKU); // To fetch product by sku
    }
    if (!LocalStorage.getXAuthToken()) {
      window.location.href = `${SharedCommomRouteName.validateRoute}?redirect_url=/`;
    }
  }, [fetchOneProduct, productSKU]);

  useEffect(() => {
    logAnalytics(track.VIEW_PRODUCT, product?.productId);
  }, [product]);

  useEffect(() => {
    if (product && !product.image) {
      setImageLoaded(
        true
      ); /**< If there is no image to be loaded, set setImageLoaded to true.*/
    }
  }, [product]);

  useEffect(() => {
    // on unmount
    return () => {
      dispatch(resetProductState());
    };
  }, [dispatch]);

  const history = useHistory();

  /// Get ProductPage Container
  const getProductPageContainer = () => {
    return (
      <>
        <Container>
          <Row className="float-right languageSelector">{languageSelector}</Row>
        </Container>
        <Container>
          <Row className="d-flex justify-content-center">
            {loading && !product && <Loader />}
            {product && !loading && product.live && (
              <>
                <Col sm={12} className="pt-3">
                  <h1>{product.name}</h1>
                </Col>
                <Col sm={12}>
                  <>
                    {product.image ? (
                      <img
                        src={product.image}
                        onLoad={() => imageLoadedHandler()}
                        onError={() => imageLoadedHandler()}
                        className="product-image"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                </Col>
                <>
                  <Col sm={12}>
                    <p
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: getTranslatedProductDescription(product)
                      }}
                    />
                    <Button
                      className="p-button p-button-primary btn-block d-flex justify-content-center custom-buy-button-container"
                      variant="primary"
                      onClick={() => {
                        logAnalytics(
                          track.INITIATE_PURCHASE,
                          product?.productId
                        );
                        history.push(`/${product?.formattedSKU}/buy`);
                      }}
                    >
                      {isUniqueProduct(product) ? (
                        <>
                          {translation('checkoutPage.getFreeProduct')}
                          <div className="mx-2"></div>
                          {currencyFormatter(
                            (product.price / product.duration) as number
                          )}{' '}
                          {translation('shopPages.priceMonth')}
                        </>
                      ) : (
                        <>
                          {translation('checkoutPage.product_buy')}
                          <div className="mx-2"></div>
                          {currencyFormatter(product.price as number)}
                        </>
                      )}
                    </Button>
                  </Col>
                </>
              </>
            )}
          </Row>
        </Container>
      </>
    );
  };

  /// Product Page in public route.
  if (isPublicRoute) {
    if (loading || !product || !imageLoaded) {
      document.documentElement.setAttribute('hidden', 'hidden');
      document.body.classList.add('invisible');
    } else {
      document.documentElement.removeAttribute('hidden');
      document.body.classList.remove('invisible');
    }

    return (
      <ShopWrapper
        shopHeaderHTML={shopHeaderHTML}
        shopFooterHTML={shopFooterHTML}
      >
        {getProductPageContainer()}
      </ShopWrapper>
    );
  }

  return <>{getProductPageContainer()}</>;
};

function mapStateToProps(state) {
  return {
    product: state.products.product,
    loading: state.products.productIsFetching
  };
}

const mapDispatchToProps = {
  fetchOneProduct
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(ProductPage, createLog));
