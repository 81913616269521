/*
 * ProductFormFields.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductFormFields.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBraintreePlansAction } from '../../../Store/Braintree';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { fetchAllApplications } from '../../../Store/Applications';
import { UseSeatQuantityEnum } from '@abstract/abstractwebcommon-shared/enum/ecommerce/products';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import {
  ProductType,
  isUniqueProduct,
  isPaidSubscription
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { MultiSelect } from 'primereact/multiselect';

/**
 * Interface for ProductFormFields properties.
 */
interface IProductFormFieldsProperties {
  values: {
    image?: any;
    name?: string;
    sku?: any;
    maxQuantity?: any;
    live?: any;
    sla?: boolean;
    price?: any;
    duration?: number;
    type?: ProductType;
    isLoginRequired?: boolean;
    translationData?: IProductTranslation[] /**< Product translation data */;
    isDisabled?: boolean /**< Product is dsiabled or not */;
    [key: string]: any /**< Dynamic properties */;
  };
  errors: {
    name?: string;
    sku?: any;
    maxQuantity?: any;
    price?: any;
    duration?: number;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  handleChange: any;
  loading: boolean;
  product: any;
  handleBlur: any;
  touched: {
    name?: string;
    sku?: any;
    maxQuantity?: any;
    price?: any;
    duration?: number;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  uploadFiles?: any;
  setFieldValue: any;
  customForm?: any;
  setLogoUploadStatus?: any;
  setDeactivateConfirmationDialogVisible?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Deactivate Confirm dialog. */;
  setShowSLAForm: React.Dispatch<
    React.SetStateAction<boolean>
  > /**<To show SLA form. */;
  selectedLanguage: string /**< Selected language */;
  setSelectedLanguage: React.Dispatch<
    React.SetStateAction<string>
  > /**< set selected language */;
  selectedLanguageTranslationIndex: number /**< Selected language translation data index */;
  englishTranslationIndex: number /**< English translation data index */;
  selectedApplication: string /**< Selected application */;
  setSelectedApplication: React.Dispatch<
    React.SetStateAction<IApplication>
  > /**< set selected application */;
}

const ProductFormFields = ({
  values,
  errors,
  handleChange,
  loading,
  product,
  handleBlur,
  touched,
  uploadFiles,
  setFieldValue,
  customForm = false,
  setLogoUploadStatus,
  setDeactivateConfirmationDialogVisible,
  setShowSLAForm,
  selectedLanguage,
  setSelectedLanguage,
  selectedLanguageTranslationIndex,
  englishTranslationIndex,
  selectedApplication,
  setSelectedApplication
}: IProductFormFieldsProperties) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const braintreePlans = useSelector((state) => state.braintree);
  const applicationState = useSelector(
    (state) => state.applications
  ); /**< Application state */
  const [croppedLogo, setCroppedLogo] = useState(null); /**< Cropped Logo. */
  const [initiateLogoUpload, setInitiateLogoUpload] = useState(
    null
  ); /**Initiate Logo Upload. */
  const [isLogoFileChanged, setLogoFileChanged] = useState(
    false
  ); /**< Logo File changed or not. */

  // To upload Product Logo.
  const uploadLogo = async (files) => {
    const file = files && files[0];
    if (file.type.indexOf('image/') !== -1) {
      // Crop Dialog.
      setInitiateLogoUpload(file);
      setLogoFileChanged(true);
      setLogoUploadStatus('add');
    } else {
      showToast({
        severity: 'error',
        summary: t('insta_image.upload_valid_image')
      });
    }
  };

  // To delete Product Logo.
  const deleteProductLogo = () => {
    setLogoUploadStatus('delete');
  };

  /// Handle Logo crop event
  const handleLogoImageCropComplete = (image) => {
    setCroppedLogo(image);
    setInitiateLogoUpload(image);
    setFieldValue('file', image);
  };

  /// Handle Image Upload Error handler.
  const errorHandler = (error) => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({
        severity: 'error',
        summary: t('insta_image.no_image_uploaded')
      });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({
        severity: 'error',
        summary: t('insta_image.upload_valid_image')
      });
    }
  };

  /// Get logo div
  const getProductLogo = () => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={values.image || null}
          showDelete={true}
          deleteHandler={deleteProductLogo}
          imgContainerClass={'px-3'}
          onChange={uploadLogo}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={t('insta_image.logo_alt')}
          isPlainBtn={true}
          plainBtnLabel={t('product.form.choose_product_image')}
          croppedImage={croppedLogo}
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(getAllBraintreePlansAction());
    dispatch(fetchAllApplications()); /**< To fetch all applications. */
  }, []);

  useEffect(() => {
    if (
      !isStringEmptyOrNullOrUndefined(values.applicationID) &&
      applicationState.allApplications &&
      applicationState.allApplications.length
    ) {
      const application: IApplication = applicationState.allApplications.find(
        (application: IApplication) => application._id === values.applicationID
      );
      setSelectedApplication(application);
    }
  }, [values.applicationID, applicationState.allApplications]);

  return (
    <>
      {!customForm && <Col sm={12}>{getProductLogo()}</Col>}
      <Form.Group as={Col} sm="12" md="12">
        <LanguageSelector
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </Form.Group>
      <Form.Group as={Col} {...(customForm ? { sm: 12, md: 12 } : null)}>
        <InstaInputText
          label={t('fields.product.name')}
          name="name"
          id={'name'}
          isLoading={loading}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          value={values.name}
          labelClassName="required"
        />
      </Form.Group>
      <Form.Group as={Col} sm="12" md="6">
        <InstaInputText
          label={t('fields.product.sku')}
          name="sku"
          id={'sku'}
          isLoading={loading}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.sku}
          errors={errors.sku}
          value={values.sku}
          labelClassName="required"
        />
      </Form.Group>
      {!customForm && (
        <Form.Group as={Col} sm="12" md="6">
          <InstaInputText
            label={t('fields.product.maxQuantity')}
            name="maxQuantity"
            id={'maxQuantity'}
            isLoading={loading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.maxQuantity}
            errors={errors.maxQuantity}
            value={values.maxQuantity}
            labelClassName="required"
          />
        </Form.Group>
      )}
      <Form.Group as={Col} sm="12" md="6">
        <InstaInputText
          label={t('fields.product.price')}
          name="price"
          id={'price'}
          isLoading={loading}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.price}
          errors={errors.price}
          value={values.price}
          labelClassName="required"
        />
      </Form.Group>
      <InstaTextArea
        sizing={{ sm: 12, md: 12 }}
        label={t('fields.product.description')}
        fieldName={`translationData.${selectedLanguageTranslationIndex}.description`}
        id={`translationData.${selectedLanguageTranslationIndex}.description`}
        isLoading={loading}
        value={
          values.translationData &&
          values.translationData.length &&
          values.translationData[selectedLanguageTranslationIndex] &&
          values.translationData[selectedLanguageTranslationIndex].description
        }
        onChange={handleChange}
        onBlur={handleBlur}
        touched={
          touched.translationData &&
          touched.translationData.length &&
          touched.translationData[selectedLanguageTranslationIndex] &&
          touched.translationData[selectedLanguageTranslationIndex].description
        }
        errors={
          errors.translationData &&
          errors.translationData.length &&
          errors.translationData[selectedLanguageTranslationIndex] &&
          errors.translationData[selectedLanguageTranslationIndex].description
        }
        labelClassName={
          selectedLanguage === LanguageSettingsMode.english ? 'required' : ''
        }
        rows={2}
      />

      <Form.Group as={Col} sm="12" md="12">
        <Form.Label className="required">{t('fields.product.type')}</Form.Label>
        <Dropdown
          name="type"
          inputId="type"
          onChange={(event) => {
            setFieldValue('type', event.target.value);
            // If the product type is not PaidSubscription, the subscriptionPlanID is null
            if (event.target.value !== ProductType.PaidSubscription) {
              setFieldValue('subscriptionPlanID', null);
            }
            handleChange(event);
          }}
          onBlur={handleBlur}
          value={values.type}
          options={Object.keys(ProductType).map((key) => {
            return {
              value: key,
              label: t(`fields.productType.${key}`)
            };
          })}
          className={`${touched.type && errors.type ? 'p-invalid' : ''}`}
        />
        {touched.type && errors.type ? (
          <small id="type-invalid" className="p-invalid">
            {errors.type}
          </small>
        ) : null}
      </Form.Group>

      {isPaidSubscription(values) ? (
        <Form.Group as={Col} sm="12" md="6">
          <Form.Label>{t('fields.product.braintreePlan')}</Form.Label>
          <Dropdown
            placeholder={
              !braintreePlans.list || braintreePlans.list.length === 0
                ? t('fields.product.braintreePlan_placeholder')
                : ''
            }
            name="subscriptionPlanID"
            inputId="subscriptionPlanID"
            onChange={(e) => {
              if (!e.target.value) {
                e.target.value = '';
              }
              handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.subscriptionPlanID}
            disabled={!braintreePlans.list || braintreePlans.list.length === 0}
            options={braintreePlans.listValueAndLabel}
            showClear={
              !isStringEmptyOrNullOrUndefined(values.subscriptionPlanID)
            }
          />
        </Form.Group>
      ) : (
        <></>
      )}
      <Form.Group as={Col} sm="12" md="6">
        <Form.Label className="required">
          {t('fields.product.company')}
        </Form.Label>
        <Dropdown
          placeholder={
            !applicationState.allApplications ||
            applicationState.allApplications.length === 0
              ? t('fields.product.company_placeholder')
              : ''
          }
          name="applicationID"
          inputId="applicationID"
          onChange={(event) => {
            if (!event.target.value) {
              event.target.value = '';
            }
            handleChange(event);
          }}
          onBlur={handleBlur}
          value={values.applicationID}
          disabled={
            !applicationState.allApplications ||
            applicationState.allApplications.length === 0
          }
          options={applicationState.listValueAndLabel}
          showClear={!isStringEmptyOrNullOrUndefined(values.applicationID)}
          className={`${
            touched.applicationID && errors.applicationID ? 'p-invalid' : ''
          }`}
        />
        {touched.applicationID && errors.applicationID ? (
          <small id="application-invalid" className="p-invalid">
            {errors.applicationID}
          </small>
        ) : null}
      </Form.Group>
      {selectedApplication &&
      selectedApplication.customFieldKeys &&
      selectedApplication.customFieldKeys.length ? (
        <Form.Group as={Col} sm="12" md="6">
          <Form.Label>{t('fields.product.customFields')}</Form.Label>
          <MultiSelect
            name="customFieldKeys"
            value={values?.customFieldKeys?.filter((fieldName: string) =>
              selectedApplication.customFieldKeys.includes(fieldName)
            )}
            options={selectedApplication.customFieldKeys}
            placeholder={t('fields.product.customFields_placeholder')}
            onChange={handleChange}
            className={
              touched.customFieldKeys && errors.customFieldKeys
                ? 'p-invalid multi-select w-100'
                : 'multi-select w-100'
            }
            onBlur={handleBlur}
            disabled={
              !selectedApplication.customFieldKeys ||
              selectedApplication.customFieldKeys.length == 0
            }
          />
        </Form.Group>
      ) : (
        <></>
      )}
      <Form.Group as={Col} sm="12" md="6">
        <Form.Label className="required">
          {t('fields.product.use_seat_quantity')}
        </Form.Label>
        <Dropdown
          name="quantityType"
          inputId="quantityType"
          onChange={(event) => {
            if (event.target.value === UseSeatQuantityEnum.Fixed) {
              setFieldValue('seatQuantity', 0);
            } else {
              // If seat quantity is null or 0, set the default value to 1
              if (!values.seatQuantity || values.seatQuantity == 0) {
                setFieldValue('seatQuantity', 1); // Default value
              }
            }
            handleChange(event);
          }}
          onBlur={handleBlur}
          value={values.quantityType}
          options={Object.entries(UseSeatQuantityEnum).map(([key, value]) => {
            return {
              value,
              label: key
            };
          })}
          className={`${
            touched.quantityType && errors.quantityType ? 'p-invalid' : ''
          }`}
        />
        {touched.quantityType && errors.quantityType ? (
          <small id="quantityType-invalid" className="p-invalid">
            {errors.quantityType}
          </small>
        ) : null}
      </Form.Group>
      {values.quantityType === UseSeatQuantityEnum.Seat ? (
        <Form.Group as={Col} sm="12" md="6">
          <InstaInputText
            label={t('fields.product.use_seat_quantity')}
            name="seatQuantity"
            id={'seatQuantity'}
            isLoading={loading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.seatQuantity}
            errors={errors.seatQuantity}
            value={values.seatQuantity}
            labelClassName="required"
          />
        </Form.Group>
      ) : (
        <></>
      )}

      {!customForm && (
        <Form.Group as={Col} sm={12} md={12}>
          <Checkbox
            inputId="live"
            onChange={(e) => {
              product && product.live
                ? setDeactivateConfirmationDialogVisible(true)
                : setFieldValue('live', !values.live);
            }}
            name="live"
            checked={values.live}
          />
          <Form.Label htmlFor="live" className="ml-2">
            {t('fields.product.live')}
          </Form.Label>
        </Form.Group>
      )}
      <Form.Group as={Col} sm={12} md={12}>
        <Checkbox
          inputId="isDisabled"
          onChange={(event) => {
            setFieldValue('isDisabled', event.checked);
          }}
          name="isDisabled"
          checked={values.isDisabled}
        />
        <Form.Label htmlFor="isDisabled" className="ml-2">
          {t('fields.product.disable')}
        </Form.Label>
      </Form.Group>
      {values.isDisabled ? (
        <Form.Group as={Col} md="12" sm="12">
          <InstaInputText
            label={t('fields.product.disabledProductMessage')}
            name={`translationData.${selectedLanguageTranslationIndex}.disabledProductMessage`}
            id={`translationData.${selectedLanguageTranslationIndex}.disabledProductMessage`}
            isLoading={loading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={
              touched.translationData &&
              touched.translationData.length &&
              touched.translationData[selectedLanguageTranslationIndex] &&
              touched.translationData[selectedLanguageTranslationIndex]
                .disabledProductMessage
            }
            errors={
              errors.translationData &&
              errors.translationData.length &&
              errors.translationData[selectedLanguageTranslationIndex] &&
              errors.translationData[selectedLanguageTranslationIndex]
                .disabledProductMessage
            }
            value={
              values.translationData &&
              values.translationData.length &&
              values.translationData[selectedLanguageTranslationIndex] &&
              values.translationData[selectedLanguageTranslationIndex]
                .disabledProductMessage
            }
            labelClassName={
              selectedLanguage === LanguageSettingsMode.english
                ? 'required'
                : ''
            }
            placeholder={t('fields.product.disabledProductMessagePlaceholder')}
          />
        </Form.Group>
      ) : (
        <></>
      )}
      <Form.Group as={Col} sm={12} md={12}>
        <Checkbox
          inputId="isLoginRequired"
          onChange={(event) => {
            setFieldValue('isLoginRequired', event.checked);
          }}
          name="isLoginRequired"
          checked={values.isLoginRequired}
        />
        <Form.Label htmlFor="isLoginRequired" className="ml-2">
          {t('fields.product.login_required')}
        </Form.Label>
      </Form.Group>
      {isUniqueProduct(values) ? (
        <>
          <Form.Group as={Col} md="12" sm="12">
            <InstaInputText
              label={t('fields.product.unique_product_info')}
              name={`translationData.${selectedLanguageTranslationIndex}.freeProductDiscountName`}
              id={`translationData.${selectedLanguageTranslationIndex}.freeProductDiscountName`}
              isLoading={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.translationData &&
                touched.translationData.length &&
                touched.translationData[selectedLanguageTranslationIndex] &&
                touched.translationData[selectedLanguageTranslationIndex]
                  .freeProductDiscountName
              }
              errors={
                errors.translationData &&
                errors.translationData.length &&
                errors.translationData[selectedLanguageTranslationIndex] &&
                errors.translationData[selectedLanguageTranslationIndex]
                  .freeProductDiscountName
              }
              value={
                values.translationData &&
                values.translationData.length &&
                values.translationData[selectedLanguageTranslationIndex] &&
                values.translationData[selectedLanguageTranslationIndex]
                  .freeProductDiscountName
              }
              labelClassName={
                selectedLanguage === LanguageSettingsMode.english
                  ? 'required'
                  : ''
              }
              placeholder={t(
                'fields.product.freeProductDiscountNamePlaceholder'
              )}
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={12}>
            <Checkbox
              inputId="isPaymentSectionHidden"
              onChange={(event) => {
                setFieldValue('isPaymentSectionHidden', event.checked);
              }}
              name="isPaymentSectionHidden"
              checked={values.isPaymentSectionHidden}
            />
            <Form.Label htmlFor="isPaymentSectionHidden" className="ml-2">
              {t('fields.product.isPaymentSectionHidden')}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col} sm={12} md={12}>
            <Checkbox
              inputId="isCustomerDetailsSectionHidden"
              onChange={(event) => {
                setFieldValue('isCustomerDetailsSectionHidden', event.checked);
              }}
              name="isCustomerDetailsSectionHidden"
              checked={values.isCustomerDetailsSectionHidden}
            />
            <Form.Label
              htmlFor="isCustomerDetailsSectionHidden"
              className="ml-2"
            >
              {t('fields.product.isCustomerDetailsSectionHidden')}
            </Form.Label>
          </Form.Group>
        </>
      ) : (
        <></>
      )}
      {(values.sla || isUniqueProduct(values)) &&
      values.type !== ProductType.OneShot ? (
        <Form.Group as={Col} sm="12" md="12">
          <InstaInputText
            label={t('fields.product.duration')}
            name="duration"
            id={'duration'}
            isLoading={loading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.duration}
            errors={errors.duration}
            value={values.duration}
            placeholder={t('fields.product.durationPlaceholder')}
            labelClassName="required"
          />
        </Form.Group>
      ) : (
        <></>
      )}
      <Form.Group as={Col} sm={12} md={12}>
        <Checkbox
          inputId="sla"
          onChange={(event) => {
            setFieldValue('sla', event.checked);
            setShowSLAForm(event.checked as boolean);
            /// If you uncheck the sla, all fields should be cleared.
            if (!event.checked) {
              if (!isUniqueProduct(values)) {
                // If the product is not unique and has no SLA, the duration is null
                setFieldValue('duration', null);
              }
              if (values.translationData && values.translationData.length) {
                for (
                  let index = 0;
                  index < values.translationData.length;
                  index++
                ) {
                  setFieldValue(`translationData.${index}.slaInformation`, []);
                }
              }
            }
          }}
          name="sla"
          checked={values.sla}
        />
        <Form.Label htmlFor="sla" className="ml-2">
          {t('fields.product.show_sla')}
        </Form.Label>
      </Form.Group>
      <CropDialog
        isVisible={isLogoFileChanged}
        setVisible={setLogoFileChanged}
        file={initiateLogoUpload}
        onImageCropComplete={handleLogoImageCropComplete}
      />
    </>
  );
};

export default React.memo(ProductFormFields);
