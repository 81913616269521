import { isStringEmptyOrNullOrUndefined } from "@abstract/abstractwebcommon-shared/utils/sharedFunctions";
import { UserAuthenticationToken, IUserInformationToken } from "@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken";
import jwtDecode from "jwt-decode";

export const isTokenValid = (token: string | null): boolean => {
  try {
    if (!token) return false;

    const decodedToken: IUserInformationToken = new UserAuthenticationToken(jwtDecode(token)).getUserAuthenticationToken();
    const currentDate: Date = new Date();
    let isValid: boolean;

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  } catch (error) {
    return false;
  }
};

/**
  * Defines if a given token has a valid structure.
  * @returns {boolean} Is the token structure valid.
*/
export const isValidTokenStructure = (token: string | null): boolean => {
  try {
    if (isStringEmptyOrNullOrUndefined(token)) return false

    jwtDecode(token);
    return true;
  } catch (error) {
    return false;
  }
}