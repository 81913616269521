/**
* ConfigurationSettingsPage.ts (InstaLOD GmbH) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022 
* @file ConfigurationSettingsPage.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useEffect } from 'react';
import ConfigurationSettingsPageComponent from '@abstract/abstractwebcommon-client/ConfigurationSettingsPage/ConfigurationSettingsPage';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { saveSettings } from '../../Store/ShopSettings';
import { useAppSelector } from '../../Hooks';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../Services/Logs';
import { RouteName } from '../../Utils/routesNames';

const ConfigurationSettingsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const configurationSettings: Record<string, any> =
    location.state; /**< Settings to configure application. */
  const shopSettings: Record<string, any> = useAppSelector(
    (state) => state.shopSettings
  ); /**< Settings State. */
  const history: any = useHistory();

  /// Update configuration settings.
  const handleUpdateSettings = async (
    settings: Record<string, any>
  ): Promise<void> => {
    const payload: Record<string, any> = {
      settings
    }; /**< Store settings payload */
    dispatch(saveSettings(payload));
  };

  useEffect(() => {
    if (shopSettings.isSettingsUpdated) {
      history.push({ pathname: RouteName.adminDashboardRoute });
    }
  }, [shopSettings, dispatch]);

  return (
    <>
      <ConfigurationSettingsPageComponent
        configurationSettings={configurationSettings}
        handleUpdateSettings={handleUpdateSettings}
      />
    </>
  );
};

export default withErrorBoundary(ConfigurationSettingsPage, createLog);
