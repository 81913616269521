/**
 * Analytics.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Analytics.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { addAnalytics } from '../Store/Analytics';
import { useDispatch } from 'react-redux';
import { IAnalyticsData } from 'src/Services/Analytics';

export function analyticsPlugin() {
  const dispatch = useDispatch();
  return {
    name: 'instaLOD-analytics',
    page: async ({ payload }) => {
      const analyticsData: IAnalyticsData = {
        event: payload.properties.event,
        description: `${payload.properties.description} at ${payload.properties.url}`,
        userAgent: payload.properties.userAgent,
        initiatedAt: payload.meta.timestamp,
        trackingId: payload.anonymousId
      };
      dispatch(addAnalytics(analyticsData));
    },
    track: ({ payload }) => {
      const analyticsData: IAnalyticsData = {
        event: payload.event,
        description: payload.properties.description,
        userAgent: navigator.userAgent,
        initiatedAt: payload.meta.timestamp,
        trackingId: payload.anonymousId
      };
      if (payload.properties.product) {
        analyticsData.product = payload.properties.product;
      }
      dispatch(addAnalytics(analyticsData));
    }
  };
}
