/**
* themeModeUtils.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file themeModeUtils.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { ThemeMode } from "../../Shared/enum/theme";

/**
  Use to change document body theme.
  @param String themeMode - theme state to update
  @return void
*/
export const changeUserTheme = (themeMode: string): void => {
  document.body.classList.add(
    themeMode === ThemeMode.lightMode ? ThemeMode.lightMode : ThemeMode.darkMode
  );
  document.body.classList.remove(
    themeMode === ThemeMode.lightMode ? ThemeMode.darkMode : ThemeMode.lightMode
  );
}