/*
 * DiscountForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file DiscountForm.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'primereact/dropdown';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import i18n from '../../../Services/I18n';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { useTranslation } from 'react-i18next';
import { IDiscount } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Discount';
import {
  discountTypeOptionsDropdown,
  usedOptionsDropdown
} from './DiscountsTypes';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { DiscountTerm } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Discount';

/**
 * Interface for DiscountForm properties.
 */
interface IDiscountFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  loading: boolean;
  discount: any;
  handleBlur: any;
  touched: any;
  products: any;
  productsLoading: any;
  handleDelete: (productsUUIDs: string[]) => void /**< Handle delete item */;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void /**< To set formik field value */;
}

const DiscountForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  discount,
  handleBlur,
  touched,
  products,
  productsLoading,
  handleDelete,
  setFieldValue
}: IDiscountFormProperties) => {
  const translation = useTranslation().t;
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */

  const productOptions =
    products &&
    products.map((product) => {
      return { label: product.name, value: product._id };
    });

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete product on Accept
  const onAccept = () => {
    const discountIDs: string[] = [discount].map(
      (eachProduct: IDiscount) => eachProduct._id
    ); /**< discountIDs */
    handleDelete(discountIDs);
    setIsShowingConfirmation(false);
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={discount && Object.keys(discount).length > 0}
        isLoading={loading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => handleSubmit(values)}
      >
        <Row>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={i18n.t('fields.discount.name')}
              name="name"
              id={'name'}
              isLoading={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.name}
              errors={errors.name}
              value={values.name}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6}>
            <Form.Label>{i18n.t('fields.discount.type')}</Form.Label>
            <Dropdown
              name="discountType"
              inputId="discountType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.discountType}
              disabled={loading}
              options={discountTypeOptionsDropdown}
              className={
                touched.discountType && errors.discountType ? 'p-invalid' : ''
              }
            />
            {touched.discountType && errors.discountType ? (
              <small id="email-invalid" className="p-invalid error-text">
                {errors.discountType}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6}>
            <Form.Label>{i18n.t('fields.discount.status')}</Form.Label>
            <Dropdown
              name="used"
              inputId="used"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.used}
              disabled={loading}
              options={usedOptionsDropdown}
              className={touched.used && errors.used ? 'p-invalid' : ''}
            />
            {touched.used && errors.used ? (
              <small id="email-invalid" className="p-invalid error-text">
                {errors.used}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={i18n.t('fields.discount.amount')}
              labelClassName="required"
              name="amount"
              id={'amount'}
              isLoading={loading}
              onChange={(e) => {
                handleChange(e);
                values.percentage = undefined;
              }}
              onBlur={handleBlur}
              touched={touched.amount}
              errors={errors.amount}
              value={values.amount}
              disabled={values.percentage && Number(values.percentage) !== 0}
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={i18n.t('fields.discount.percentage')}
              labelClassName="required"
              name="percentage"
              id={'percentage'}
              isLoading={loading}
              onChange={(e) => {
                handleChange(e);
                values.amount = undefined;
              }}
              onBlur={handleBlur}
              touched={touched.percentage}
              errors={errors.percentage}
              value={values.percentage}
              disabled={values.amount && Number(values.amount) !== 0}
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={i18n.t('fields.discount.code')}
              name="code"
              id={'code'}
              isLoading={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.code}
              errors={errors.code}
              value={values.code}
              disabled={values.code === ''}
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6}>
            <Form.Label>{i18n.t('fields.discount.product_text')}</Form.Label>
            <Dropdown
              name="discountProductId"
              inputId="discountProductId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.discountProductId}
              disabled={productsLoading}
              options={productOptions}
              showClear={
                !isStringEmptyOrNullOrUndefined(values.discountProductId)
              }
              className={
                touched.discountProductId && errors.discountProductId
                  ? 'p-invalid'
                  : ''
              }
            />
            {touched.discountProductId && errors.discountProductId ? (
              <small id="email-invalid" className="p-invalid error-text">
                {errors.discountProductId}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6}>
            <Form.Label className="required">
              {i18n.t('fields.discount.discountTerm')}
            </Form.Label>
            <Dropdown
              name="discountTerm"
              inputId="discountTerm"
              onChange={(event) => {
                if (event.target.value !== DiscountTerm.Custom) {
                  setFieldValue('duration', null);
                }
                handleChange(event);
              }}
              onBlur={handleBlur}
              value={values.discountTerm}
              disabled={loading}
              options={Object.keys(DiscountTerm).map((key: string) => {
                return {
                  value: key,
                  label: key
                };
              })}
              className={
                touched.discountTerm && errors.discountTerm ? 'p-invalid' : ''
              }
            />
            {touched.discountTerm && errors.discountTerm ? (
              <small id="email-invalid" className="p-invalid error-text">
                {errors.discountTerm}
              </small>
            ) : null}
          </Form.Group>
          {values.discountTerm === DiscountTerm.Custom ? (
            <Form.Group as={Col} sm="12" md="6">
              <InstaInputText
                label={i18n.t('fields.discount.duration')}
                name="duration"
                id={'duration'}
                isLoading={loading}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.duration}
                errors={errors.duration}
                value={values.duration}
                type="number"
                labelClassName="required"
                isShowInformationPopup={true}
                popupText={i18n.t('fields.discount.duration_popupText')}
              />
            </Form.Group>
          ) : (
            <></>
          )}
        </Row>
      </FormWrapper>

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowingConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={() => setIsShowingConfirmation(false)}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="top"
      />
    </>
  );
};

export default withFormik({
  mapPropsToValues: ({ discount }) => {
    if (!discount) {
      return {
        name: '',
        discountType: 'oneTime',
        duration: null,
        status: 'enabled',
        amount: undefined,
        percentage: undefined,
        code: '',
        used: false,
        discountProductId: '',
        discountTerm: DiscountTerm.Once
      };
    }

    return {
      name: discount.name,
      discountType: discount.discountType,
      duration: discount.duration === 0 ? '' : discount.duration,
      status: discount.status,
      amount: discount.amount === 0 ? undefined : discount.amount,
      percentage: discount.percentage === 0 ? undefined : discount.percentage,
      code: discount.code,
      used: discount.used,
      discountProductId:
        discount.discountProductId && discount.discountProductId,
      discountTerm: discount.discountTerm
    };
  },
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: Yup.object().shape(
    {
      name: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .max(30, 'Must be 50 characters or less')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.discount.name')
          })
        ),
      discountType: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('fields.discount.discountType')
        })
      ),
      status: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('fields.discount.status')
        })
      ),
      amount: Yup.number()
        .typeError('Must be a number')
        .when('percentage', {
          is: undefined,
          then: Yup.number().required(
            i18n.t('validation.required', {
              field: i18n.t('fields.discount.amount')
            })
          )
        }),
      percentage: Yup.number()
        .typeError('Must be a number')
        .when('amount', {
          is: undefined,
          then: Yup.number().required(
            i18n.t('validation.required', {
              field: i18n.t('fields.discount.percentage')
            })
          )
        }),
      code: Yup.string(),
      discountTerm: Yup.string()
        .oneOf(Object.keys(DiscountTerm))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.discount.discountTerm')
          })
        ),
      duration: Yup.number()
        .nullable()
        .when('discountTerm', {
          is: (discountTerm: string) => discountTerm === DiscountTerm.Custom,
          then: Yup.number()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.discount.duration')
              })
            )
            .typeError('Must be a number')
            .positive('Must be a number above 0')
        })
    },
    ['percentage', 'amount']
  ),
  handleSubmit: (values, bag) => {
    if (Number(values.percentage) === 0 && Number(values.amount) === 0) {
      return;
    }

    if (bag.props.discount) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (
          values[key as keyof typeof bag.props.discount] !==
          bag.props.discount[key as keyof typeof bag.props.discount]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit({
          _id: bag.props.discount._id,
          ...values
        });
      }
      return;
    }
    return bag.props.handleSubmit({ ...values });
  }
})(DiscountForm);
