/*
 * theme.ts (AbstractWebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file theme.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum ThemeMode
 */
 export enum ThemeMode {
    lightMode = 'light-mode',
    darkMode = 'dark-mode'
  }
  