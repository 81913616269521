/*
 * WarningsBanner.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file WarningsBanner.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState, ReactElement } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from 'react-bootstrap/Col';

import "./WarningsBanner.css";

export interface IWarningsBannerProperties {
  warningsData: any[];
}

const WarningsBanner = ({
  warningsData,
}: IWarningsBannerProperties): ReactElement => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const isWarningsDataValid: boolean = warningsData !== undefined && warningsData !== null && warningsData.length > 0;

  const warningsAsJSX: any = [];
  isWarningsDataValid && warningsData.forEach((eachWarning, index) => {
    warningsAsJSX.push(
      <p key={`warning-${index}`} className="m-0">- {eachWarning.description}</p>
    );
  });

  useEffect(() => {
    if (isWarningsDataValid) {
      setIsShow(true);
    }
  }, [warningsData]);

  return (
    <>
      {isShow && <Col xs={12}>
        <Alert show={isShow} variant="warning">
          {warningsAsJSX}
          <div className="d-flex justify-content-end">
            <Button onClick={() => setIsShow(false)} variant="primary">
              Hide warnings!
            </Button>
          </div>
        </Alert>
      </Col>}
    </>
  );
};

export default WarningsBanner;
