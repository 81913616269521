/**
 * UserSetting.ts (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file UserSetting.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import Resizer from 'react-image-file-resizer';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * @interface Settings
 */
interface ISettings {
  favicon: string /** favicon */;
  logo: string /** Logo URL */;
}

export const resizer = (image: any, size: number) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      image,
      size,
      size,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });
};

/**
 * readSafe api call
 * @returns An API response
 */

export const readSafe = async (): Promise<any> => {
  return httpClient.get(`/store`);
};

export const resizeImage = async (imageURL: any, size: number) => {
  const image: Blob = await asyncErrorHandler(
    fetch(imageURL).then((response) => response.blob())
  );
  const newImage = await asyncErrorHandler(resizer(image, size));
  return newImage;
};

/**
 * getFaviconURL api call
 * @param settings The settings object
 * @param size The target dimension of resize the image
 * @returns A new URL containing the resized image
 */
export const getFaviconURL = async (
  settings: ISettings,
  size: number
): Promise<any> => {
  try {
    if (!settings.favicon) {
      return;
    }

    const faviconURL = settings.favicon;
    const resizedImage = await asyncErrorHandler(resizeImage(faviconURL, size));
    return resizedImage;
  } catch (error) {
    console.error('Error during fetch of resized favicon image:', error);
  }
};

/**
 * Fetch logged in user's applications
 * @returns Promise
 */
export const fetchUserApplications = async (): Promise<IAPIEntityResponse<
  IApplications[]
>> => {
  return httpClient.get(`/store/user/applications`);
};
