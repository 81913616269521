/**
 * Logs.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Logs.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { BASE_API_URL } from '../Config';
import { format } from 'date-fns';
import { downloadFile } from '@abstract/abstractwebcommon-client/utils/downloadFile';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { CreateErrorLog } from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

/**
 * Create log
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createLog = async (payload: CreateErrorLog) => {
  return httpClient.post(`/logs/create`, payload, undefined, false, false);
};

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const getLogs = async (
  skip: number,
  limit: number,
  searchTerm: any,
  sort: any,
  startDate: Date,
  endDate: Date,
  filter: Record<string, any>
) => {
  const url = paginationRequest(`/logs`, {
    skip,
    limit,
    searchTerm,
    filter
  });

  return httpClient.post(
    `${url}&startDate=${encodeURIComponent(
      format(startDate, 'yyyy-MM-dd HH:mm:ss')
    )}&endDate=${encodeURIComponent(format(endDate, 'yyyy-MM-dd HH:mm:ss'))}`,
    {
      sort
    }
  );
};

export const exportLogs = async (
  skip: number,
  limit: number,
  searchTerm: any,
  sort: any,
  startDate: Date,
  endDate: Date
) => {
  const url = paginationRequest(`${BASE_API_URL}/logs/exportLogs`, {
    skip,
    limit,
    searchTerm
  });
  return downloadFile(
    `${url}&startDate=${encodeURIComponent(
      format(startDate, 'yyyy-MM-dd HH:mm:ss')
    )}&endDate=${encodeURIComponent(format(endDate, 'yyyy-MM-dd HH:mm:ss'))}`,
    'POST',
    'logs.csv',
    { sort }
  );
};

/**
 * Delete logs
 * @param deleteOption Defines the option to delete logs. Options are from the PruneLogsTimeFrameEnum enum.
 * @returns Promise<IAPIEntityResponse<any>>
 */
export const deleteLogsAPI = (
  deleteOption: string
): Promise<IAPIEntityResponse<any>> =>
  httpClient.delete(
    `/logs/prune?timeFrame=${encodeURIComponent(deleteOption)}`
  );
