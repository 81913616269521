/*
 * BaseDatatable.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file BaseDatatable.tsx
 * @author Sai Charan K
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * This component is a simple table.
 * Use this component if you want to create your own custom implementation of datatable with custom css.
 *
 */
import React, { useRef, forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { useTranslation } from "react-i18next";
import { defaultTableLimit, defaultTableOptions } from '../Constants';
import { IPageEvent } from '../../Shared/interfaces/pagination';
import DataTableSkeleton from "../SkeletonLoader/DataTableSkeleton/DataTableSkeleton";

const BaseDatatable = forwardRef((properties: any, ref) => {
  const { t } = useTranslation();
  const datatableReference: any = useRef(null);

  const [headersCountToRenderInSkeleton, setHeadersCountToRenderInSkeleton] = useState<number[]>([])
  const [isCountHeadersFinished, setIsCountHeadersFinished] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    reset() {
      if (datatableReference && datatableReference.current) {
        datatableReference.current.reset();
      }
    },
  }));

  // ClassName for div component based on expand row.
  let classname = '';
  if (properties.expandedRows) {
    classname = Object.keys(properties.expandedRows).length
      ? `expanded ${properties.parentClass}`
      : `${properties.parentClass}`;
  } else if (properties.parentClass) {
    classname = `${properties.parentClass}`;
  }

  //Do logic to count how many headers are being displayed in the UI and also takes the width of each header.
  //The width is sent to the DataTableSkeleton and applied to each skeleton render.
  //Only counts the header where 'display' property is different than 'none'. 
  useEffect(() => {
    if (!isCountHeadersFinished) {
      const htmlTableElement: HTMLCollectionOf<Element> = document.getElementsByClassName('p-datatable-thead')
      const htmlHeaderElements: HTMLCollectionOf<HTMLTableCellElement> = htmlTableElement[0].getElementsByTagName('th')

      for (let counter: number = 0; counter < htmlHeaderElements.length; counter++) {
        //Access when is last index to set state to render the datatable skeleton.
        if (htmlHeaderElements.length - 1 === counter) {
          setIsCountHeadersFinished(true)
        }

        //Access when html header element is displayed in the UI.
        if (window.getComputedStyle(htmlHeaderElements[counter]).display !== 'none') {
          //Remove the last two characters (px) of the display property value.
          const headerWidth: string = window.getComputedStyle(htmlHeaderElements[counter]).width.slice(0, -2)

          //Used to subtract the header width to create space between headers render, like a margin-right.
          const subtractWidth: number = 1

          //Example above: If the header width is 50px, then 50 - subtractWidth.
          setHeadersCountToRenderInSkeleton((previousValue: number[]) => [...previousValue, (Number(headerWidth) - subtractWidth)])
        }
      }
    }
  }, [isCountHeadersFinished])

  /// Add classname to last visible child
  const addClassNameToLastVisibleChild = (tableCells: Element[] | ChildNode[]) => {
    if (tableCells) {
      const visibleTableCells: Element[] = [];
      for(let i = 0 ; i < tableCells.length; i++) {
        const eachElement: Element = tableCells[i] as Element;
        eachElement.classList.remove('last-visible-child');
        const style = window.getComputedStyle(eachElement, null).display;
        if (style !== 'none') {
          visibleTableCells.push(eachElement);
        }
      };

      if (visibleTableCells && visibleTableCells.length) {
        visibleTableCells[visibleTableCells.length - 1].classList.add('last-visible-child');
      }
    }
  };

  /// Revalidate last visible child
  const revalidateLastVisibleChild = () => {
    /// Add last-visible-child className to last visible tableheader cells.
    const tableHeaderCells: Element[] = Array.from(
      document.querySelectorAll(
        '.nestedDatatable .p-datatable .p-datatable-thead > tr:last-child > th'
      )
    ); /**< table header cells */
    addClassNameToLastVisibleChild(tableHeaderCells);

    /// Add last-visible-child className to last visible tabledata cells.
    const tableDataRows: NodeListOf<Element> = document.querySelectorAll(
      '.nestedDatatable .p-datatable .p-datatable-tbody > tr'
    );
    for (let i = 0; i < tableDataRows.length; i++) {
      const tableDataCells: ChildNode[] = Array.from(
        tableDataRows[i].childNodes
      ); /**< table data cells */
      addClassNameToLastVisibleChild(tableDataCells);
    }
  };

  // Revalidate last visible child when window is resized
  useEffect(() => {
    revalidateLastVisibleChild();
    window.addEventListener('resize', revalidateLastVisibleChild);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', revalidateLastVisibleChild);
    },
    []
  );
  
  return (
    <div className={`responsiveBaseDataTable ${classname}`}>
      <DataTable
        //className validation: Hide data table tbody when data is loading and display Skeleton component
        className={`${properties.isLoading && 'hide-table-body-while-loading-data'}`}
        lazy
        paginator
        emptyMessage={t("awc:/.base_datatable.no_records")}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={t('awc:/paginator', {
          first: "{first}",
          last: "{last}",
          totalRecords: "{totalRecords}",
        })}
        ref={datatableReference}
        rows={defaultTableLimit}
        rowsPerPageOptions={defaultTableOptions}
        selectionMode="checkbox"
        {...properties}
        onPage={(event: IPageEvent) => {
          properties.onPage(event);
          if (!classname.includes('nestedDatatable')) {
            window.scrollTo(0, 0); // Scroll to top position.
          }
        }}
      />

      {(properties.isLoading && isCountHeadersFinished) && <DataTableSkeleton numberOfHeaders={headersCountToRenderInSkeleton} />}
    </div>
  );
});

export default BaseDatatable;
