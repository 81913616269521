/*
 * ProfileCard.tsx (AbstractUser)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file ProfileCard.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import jwtDecode from "jwt-decode";

import { IUserProfile } from '../../Shared/interfaces/user/user';
import { IUserInformationToken, UserAuthenticationToken } from '../../Shared/utils/UserAuthenticationToken';
import { LocalStorage } from '../utils/sharedLocalStorage';

import './ProfileCard.css';
import './ProfileCardDarkMode.scss';
import '../cssLoader.css'; // License project isn't recognizing this file when imported by the ProfileHeader component. So, I'm importing this here.

import AbstractIDLogo from './assets/abstract-id-logo.png'
import AbstractIDLogoBlackTransparent from './assets/abstract-id-logo-black-transparent.png'

/**
 * @interface IProfileCardProperties
 */
export interface IProfileCardProperties {
  userInformation: IUserProfile /**< User information to display on the card */
  isRenderingThroughSidebar?: boolean /**< If the card is rendered in the Sidebar component it should display the notification bar and the bottom buttons */
  isTokenCountVisible?: boolean /**< Defines if the token count container should be rendered */
  onLogout?: () => void; /**< Handle logout button click */
  onAccount?: () => void; /**< Handle account button click */
}

/**
 * Renders the profile card component.
 * Currently, we're using the ProfileCard component on the sidebar component and on hover effects where we have user information to display. Example: Logs page.
 * When using this component in the sidebar, we display some user information using the JWT token.
 * When using it on hover effects, we need to use the userInformation property to display the information based on the rendered users.
 */
const ProfileCard = (properties: IProfileCardProperties): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  
  let decodedToken: IUserInformationToken = null
  const isRenderingThroughSidebar: boolean = properties.isRenderingThroughSidebar ?? false
  const isTokenCountVisible: boolean = properties.isTokenCountVisible ?? false

  // Only load the token when the profile is rendered in the Sidebar component or when the token count is visible.
  if (isRenderingThroughSidebar || isTokenCountVisible) {
    decodedToken = new UserAuthenticationToken(
      jwtDecode(LocalStorage.getXAuthToken()), true
    ).getUserAuthenticationToken();
  }

  // Handle if the user full name should be rendered from the token or the userInformation property.
  const renderFullNameText = (): string => isRenderingThroughSidebar ? `${decodedToken.firstName} ${decodedToken.lastName}` : `${properties.userInformation.firstName} ${properties.userInformation.lastName}`

  // Handle if the user email should be rendered from the token or the userInformation property.
  const renderEmailText = (): string => isRenderingThroughSidebar ? decodedToken.email : properties.userInformation.email

  // Handle if the user token balance should be rendered from the token or the userInformation property.
  // Render '0' if a user's token balance is not defined.
  const renderTokenBalanceText = (): string => {
    if (isRenderingThroughSidebar || isTokenCountVisible) {
      return decodedToken.tokenBalance === 0 ? "0" : String(decodedToken.tokenBalance).slice(0, -3)
    }

    return properties.userInformation.tokenBalance == 0 ? "0" : String(properties.userInformation.tokenBalance).slice(0, -3)
  }

  return (
    <div className={`${isRenderingThroughSidebar ? 'from-sidebar-component' : ''} main-profile-card-container p-2 mb-2`} onClick={properties.onAccount}>
      <div className={`d-flex notification-and-abstract-id-image-container ${isRenderingThroughSidebar ? '' : 'justify-content-center'}`}>
        {isRenderingThroughSidebar ? (
          <div className="notification-container d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="mr-1" role="img" aria-label="user-solid" viewBox="0 0 14 18">
              <path fill="currentColor" d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z"></path>
            </svg>

            {/* TODO: We need standardize our JWT token generator to include the notifications object into it. */}
            <span>{decodedToken?.notifications?.unread ?? 0}</span>
          </div>
        ) : <></>}

        <img
          className={`${isRenderingThroughSidebar ? 'abstract-id-img-container-margin-left' : ''} abstract-id-img-container img-filter-grayscale-invert`}
          src={AbstractIDLogo}
        />
      </div>

      <div className="d-flex mt-1 pb-1">
        {properties.userInformation.profilePictureURL ?
          <img className="user-image-container" src={properties.userInformation.profilePictureURL} /> :
          <svg className="user-image-container no-user-image-container" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
            <path fillRule="evenodd" d="M8 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
          </svg>
        }

        <div className="d-flex flex-column ml-2 user-information-container">
          <span>{renderFullNameText()}</span>
          <span className="user-email">{renderEmailText()}</span>
        </div>
      </div>

      {isTokenCountVisible ? <div className="w-100">
        <div className="token-container mt-2">
          <span className="font-weight-bold token-container-tokens-value">{renderTokenBalanceText()}</span>

          <img
            className="ml-3 mr-1 token-container-image-section img-filter-grayscale-invert"
            src={AbstractIDLogoBlackTransparent}
          />

          <span className="font-weight-bold custom-tokens-label-container">{translation('awc:/.profile_card.tokens')}</span>
        </div>
      </div> : <></>}

      {isRenderingThroughSidebar ? (
        <div className="bottom-buttons-container d-flex mt-3 align-items-center justify-content-center">
          <Button 
            variant="link" 
            className="custom-link-btn p-0 border-0 account-icon-container" 
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              if (properties.onAccount != null) {
                properties.onAccount()
              }
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="account-icon-margin" role="img" aria-label="user-solid" viewBox="0 0 14 18">
              <path fill="currentColor" d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"></path>
            </svg>

            {translation('awc:/.profile_card.account')}
          </Button>

          <Button variant="link" className="custom-link-btn p-0 border-0" onClick={properties.onLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="signout-icon-margin" role="img" aria-label="arrow-right-from-bracket-solid" viewBox="0 0 16 16">
              <g fill="currentColor">
                <path d="M15.923 8.382a1 1 0 0 0-.217-1.09l-4-4a1 1 0 1 0-1.414 1.414L12.586 7H4a1 1 0 0 0 0 2h8.586l-2.293 2.293a1 1 0 1 0 1.414 1.414l4-4a.989.989 0 0 0 .216-.325Z"></path>     <path d="M6 14H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 0 0-2H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h3a1 1 0 0 0 0-2Z"></path>   </g>  </svg>

            {translation('awc:/.profile_card.signout')}
          </Button>
        </div>
      ) : <></>}
    </div>
  );
};

export default ProfileCard