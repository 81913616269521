/**
* DashboardApi.ts (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022

* @file DashboardApi.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import { HttpClient } from "../utils/HttpClient";
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IMappedFeed } from '@abstract/abstractwebcommon-shared/interfaces/feed';
import {
  IFetchSystemVersionResponse
} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { CreateErrorLog } from "../../Shared/utils/CreateErrorLog";

let httpClient: HttpClient = null;

/**
 * Dashboard Api
 */
export class DashboardApi {

  constructor(baseApiUrl: string, createLogApi: (payload: CreateErrorLog) => void) {
    httpClient = new HttpClient(baseApiUrl, createLogApi);
  }

  /**
   * Fetches the dashboard statistics
   * @returns Promise
   */
  fetchStatisticsApi = (url: string): Promise<IAPIEntityResponse<Record<string,number>>> => {
    return httpClient.get(url);
  };

  /**
   * Fetches the dashboard version
   * @returns Promise
   */
  fetchVersionApi = (url: string): Promise<IAPIEntityResponse<IFetchSystemVersionResponse>> => {
    return httpClient.get(url);
  };

  /**
   * Fetches the dashboard feeds
   * @returns Promise
   */
  fetchFeedApi = (url: string): Promise<IAPIEntityResponse<IMappedFeed>> => {
    return httpClient.get(url);
  };

  /**
   * Fetches the dashboard system warnings
   * @returns Promise
   */
  fetchSystemWarningsApi = (url: string): Promise<IAPIEntityResponse<Record<string, string>[]>> => {
    return httpClient.get(url);
  };
}