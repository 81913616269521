/**
 * vatAcronyms.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file vatAcronyms.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum VatAcronyms
 * Possibles VAT acronyms/suffix to be validated.
 * Complete list: https://www.avalara.com/vatlive/en/eu-vat-rules/eu-vat-number-registration/eu-vat-number-formats.html
 */
 export enum VatAcronyms {
  Austria = 'AT',
  Belgium = 'BE',
  Bulgaria = 'BG',
  Croatia = 'HR',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Estonia = 'EE',
  Finland = 'FI',
  France = 'FR',
  Germany = 'DE',
  Greece = 'GR',
  Hungary = 'HU',
  // Ireland = 'IE', // We should comment out this option for now.
  Italy = 'IT',
  Latvia = 'LV',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Malta = 'MT',
  Netherlands = 'NL',
  Poland = 'PL',
  Portugal = 'PT',
  Romania = 'RO',
  Slovakia = 'SK',
  Slovenia = 'SI',
  Spain = 'ES',
  Sweden = 'SE',
}