/**
* localStorageUtils.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file localStorageUtils.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

/**
  Use to clear localStorage. 
  This function not clear the follow storages (please add to list below a storage that must be kept):
  * theme-mode
  * language-settings-mode
  @return void
*/
export const customLocalStorageClearUtil = (): void => {
  LocalStorage.removeIsAdmin();
  LocalStorage.removeFullName();
  LocalStorage.removeUserName();
  LocalStorage.removeXAuthToken();
  LocalStorage.removeRole();
  LocalStorage.removeEmail();
  LocalStorage.removeApplicationUUID();
};
