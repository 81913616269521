/**
 * EventConstants.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file EventConstants.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export default {
  event: {
    page: {
      '/': {
        name: 'OPEN_SHOP',
        description: 'opened shop page',
        title: 'Shop'
      },
      '/product/:productId': {
        name: 'OPEN_PRODUCT_DETAILS',
        description: 'opened product details page',
        title: 'Product details'
      },
      '/product/:productId/buy': {
        name: 'OPEN_CHECKOUT',
        description: 'opened checkout page',
        title: 'Checkout'
      },
      '/transaction/:transactionId': {
        name: 'TRANSACTION_SUCCESS_PAGE',
        description: 'opened transaction successful page',
        title: 'Payment Successful'
      },
      '/terms-and-conditions': {
        name: 'TERMS_AND_CONDITION_PAGE',
        description: 'opened terms and condition page',
        title: 'T&C'
      },
      '/login': {
        name: 'LOGIN_PAGE',
        description: 'opened login page',
        title: 'Login'
      }
    },
    track: {
      VIEW_PRODUCT: {
        name: 'VIEW_PRODUCT',
        description: 'clicked button to view product'
      },
      INITIATE_PURCHASE: {
        name: 'INITIATE_PURCHASE',
        description: 'clicked button to initiate buying the product'
      },
      CHECKOUT_AND_PAYMENT: {
        name: 'CHECKOUT_AND_PAYMENT',
        description: 'clicked button to pay and checkout'
      },
      ADDED_DISCOUNT_CODE: {
        name: 'ADDED_DISCOUNT_CODE',
        description: 'added purchase code to purchase the product'
      },
      REMOVED_DISCOUNT_CODE: {
        name: 'REMOVED_DISCOUNT_CODE',
        description: 'removed purchase code to purchase the product'
      },
      SUBSCRIBED_TO_NEWSLETTER: {
        name: 'SUBSCRIBED_TO_NEWSLETTER',
        description: 'subscribed to the newsletter'
      },
      UNSUBSCRIBED_TO_NEWSLETTER: {
        name: 'UNSUBSCRIBED_TO_NEWSLETTER',
        description: 'unsubscribed to the newsletter'
      },
      PURCHASE_SUCCESSFUL: {
        name: 'PURCHASE_SUCCESSFUL',
        description: 'product purchase was successful'
      },
      PURCHASE_UNSUCCESSFUL: {
        name: 'PURCHASE_UNSUCCESSFUL',
        description: 'product purchase was unsuccessful'
      }
    }
  }
};
