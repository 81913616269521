/**
 * TransactionConstants.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file TransactionConstants.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section Ecommerce
 */

/**
 * Transaction status constant to be used across project. Change value here so we don't need change individual files.
 */
export const TransactionStatus = {
  SubmittedForSettlement: 'submitted_for_settlement',
  SettlementConfirmed: 'settlement_confirmed',
  SettlementPending: 'settlement_pending',
  Settling: 'settling',
  Settled: 'settled',
  Authorized: 'authorized',
  Authorizing: 'authorizing',
  Refunded: 'refunded',
  Voided: 'voided',
  ProcessDeclined: 'processor_declined'
};
