/*
 * Price.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Price.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const fetchPriceCalculation = (
  products: { id: string; quantity: number; options: [] }[],
  country: any,
  stateOrProvince: any,
  discountCode: any,
  isCompany: boolean = false
) => {
  return httpClient.post(
    `/get-price`,
    {
      products,
      country,
      stateOrProvince,
      discountCode,
      isCompany
    },
    {},
    false
  );
};
