/*
 * TransactionsAnalyticsTable.ts (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file TransactionsAnalyticsTable.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Country } from 'country-state-city';
import { useTranslation } from 'react-i18next';

/**
 * Interface for TransactionsAnalyticsTable properties.
 */
interface ITransactionsAnalyticsTableProperties {
  tableData: {
    transactionCount: number /**< Transaction Count.*/;
    _id: {
      country: string /**< Country.*/;
      product: string /**< Product.*/;
    };
  };
}

export const TransactionsAnalyticsTable = ({
  tableData
}: ITransactionsAnalyticsTableProperties): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BaseDatatable
      value={tableData}
      header={null}
      rows={5}
      rowsPerPageOptions={[5, 10, 20]}
      autoLayout
      responsive /**< Datatable responsive layout.*/
    >
      <Column
        field="_id.product"
        header={t('analytics.transaction.productName')}
        sortable
        body={(rowData: any) => (
          <DatatableColumn
            title={t('analytics.transaction.productName')}
            data={rowData._id.product}
          />
        )}
      />
      <Column
        field="_id.country"
        header={t('analytics.transaction.country')}
        sortable
        body={(rowData: any) => (
          <DatatableColumn
            title={t('analytics.transaction.country')}
            data={Country.getCountryByCode(rowData._id.country)?.name}
          />
        )}
      />
      <Column
        field="transactionCount"
        header={t('analytics.transaction.transactionCount')}
        sortable
        body={(rowData: any) => (
          <DatatableColumn
            title={t('analytics.transaction.transactionCount')}
            data={rowData.transactionCount}
          />
        )}
      />
    </BaseDatatable>
  );
};
