/*
 * SidebarPage.tsx (AbstractWebCommon)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file SidebarPage.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useRef, useEffect, useState } from "react";
import "./sidebar.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Button as PrimereactButton } from 'primereact/button';
import ImagePreview from "../ImagePreview";
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/staticLinks'
import { PanelMenu } from 'primereact/panelmenu';
import { useLocation } from 'react-router-dom';
import { dynamicSort } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { LocalStorage } from '../utils/sharedLocalStorage'
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import ProfileCard from "../ProfileCard/ProfileCard";
import { IUser, IUserProfile } from "../../Shared/interfaces/user/user";

/**
 * Menu item
 */
export interface IMenuItem {
  id?: string; /**< Menu item id */
  label: any; /**< Menu item label or JSX element */
  icon: string; /**< Menu item icon */
  url?: string; /**< Menu item url */
  command?: (data: any) => void; /**< Function to execute on menu item click */
  items?: IMenuItem[]; /**< Menu item sub-items */
  target?: string /**< Menu item target */
  className?: string /**< className to style */
  pathToHighlight?: string | string[]/**< highlight selected menu */
  isApplicationOrStaticLink?: boolean /**< Used for linked applications and static links */
  [key: string]: any /**< custom properties */
}

/**
 * User applications menu items
 */
export interface IApplicationsMenuItem {
  applicationUUID: string /**< Application uuid */;
  applicationName: string /**< Application name */;
  verificationURL: string /**< Verification url */;
  icon: string /**< Icon image */;
}

/**
 * User menu items
 */
export interface IUserMenuItems {
  applications: IApplicationsMenuItem[]; /**< User applications */
  staticLinks: IStaticLink[] /**< User static links */
}

/**
 * Menu Separator
 */
interface IMenuSeparator {
  separator?: boolean; /**< Menu separator */
  className?: string /**< className to style */
}

interface ISidebarProperties {
  menu: any; /**< Menu */
  menuItems: IMenuItem[]; /**< Menu items */
  logoUrl: string | ArrayBuffer | null; /**< Logo image url (S3 Bucket) */
  logoNoImageContainer: any; /**< Container for logo with no image */
  logoAlt: string; /**< Logo image alt */
  onLogout: () => void; /**< Handle logout button click */
  onAccount: () => void; /**< Handle account button click */
  userInformation: IUserProfile | IUser; /**< User information to display on the card */
  handleUserMenuItemsPopulated?: (extraStyles: string) => void; /**< Handle user menu items populated */
  logoutAllText: string; /**< Logout All text */
  onLogoutAll: () => void; /**< Handle logout all applications */
  themeMode: string; /**< theme mode to use */
  didChangeTheme: (theme: ThemeMode) => void; /**< change theme function */
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (language: string) => void /**< change language function */;
  i18nService: any;
}

const SidebarPage = ({
  menu,
  menuItems,
  logoAlt,
  logoNoImageContainer,
  logoUrl,
  onAccount,
  onLogout,
  userInformation,
  handleUserMenuItemsPopulated,
  themeMode,
  didChangeTheme,
  logoutAllText,
  onLogoutAll,
  languageSettingsMode,
  didChangeLanguage,
  i18nService
}: ISidebarProperties): ReactElement => {
  const translation: TFunction = useTranslation().t;
  const location = useLocation();
  const { pathname } = location;
  const sidebarNavReference = useRef(null);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(languageSettingsMode || LocalStorage.getLanguageSettingsMode()); /**< Selected language */
  
  const separator: IMenuSeparator[] = [
    {
      separator: true,
      className: 'separator-menu-item'
    }
  ];

  const handleClickOutside = (event: any) => {
    if (event.target.classList.contains('navbar-toggler-icon')) return;
    if (sidebarNavReference.current && !sidebarNavReference.current.contains(event.target)) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarNavReference]);

  useEffect(() => {
    didChangeLanguage && didChangeLanguage(selectedLanguage)
  }, [selectedLanguage]);

  // Set selected language when language settings mode changes
  useEffect(() => {
    setSelectedLanguage(languageSettingsMode);
  }, [languageSettingsMode]);

  /// Get Tiered menu items for sidebar
  const getTieredMenu = () => {
    const userMenuItems: IMenuItem[] = menuItems;
    if (userMenuItems && userMenuItems.length > 0) {
      let allMenuItems: (IMenuItem | IMenuSeparator)[] = []
      if (menuItems && menuItems.length > 0) {
        // filter menu to remove undefined, null or empty arrays
        allMenuItems = [...menuItems.filter(menu => menu)];
      } else {
        allMenuItems = userMenuItems.filter(menu => menu);
      }

      const sortedApplicationOrStaticLink: (IMenuItem | IMenuSeparator)[] = allMenuItems.filter((item: IMenuItem) => item.isApplicationOrStaticLink).sort(dynamicSort('label'))
      const hardcodedMenuItems: (IMenuItem | IMenuSeparator)[] = allMenuItems.filter((item: IMenuItem) => !item.isApplicationOrStaticLink)
      allMenuItems = hardcodedMenuItems;

      // Added the separator style after the last hardcoded menu item
      if (sortedApplicationOrStaticLink.length > 0) {
        allMenuItems = [...hardcodedMenuItems, ...separator, ...sortedApplicationOrStaticLink];
      }

      let extraStyles: string = '';

      allMenuItems?.forEach((item: IMenuItem, index: number) => {
        const menuItemClassName = item.menuClassName ? item.menuClassName : ''; /**< Item className. */
        //Removed the last url path if url has more than 2 url pathname.
        const lastSlashPosition: string = pathname.lastIndexOf('/');
        const firstSlashPosition = pathname.indexOf('/');
        const secondSlashPosition = pathname.indexOf('/', firstSlashPosition + 1);

        const path: string = (lastSlashPosition > secondSlashPosition) && secondSlashPosition !== -1 ? pathname.substring(0, lastSlashPosition) : pathname;

        if (!item.isApplicationOrStaticLink) {
          if (item.items && item.items.length > 0) {
            item.items.forEach(subItem => {
              /// Note: If the submenu contains multiple pages, you should check the current path with array of url and add a highlight to that menu. 
              if(Array.isArray(subItem.pathToHighlight)) {
                if (subItem.pathToHighlight.indexOf(pathname) > -1) {
                  subItem.className = 'panel-menu-activated-item';
                } else {
                  subItem.className = '';
                }
              } else {
                subItem.className = subItem.pathToHighlight === pathname ? 'panel-menu-activated-item' : ''
              }
            })
          }

          if (!item.separator) {
            item.className = item.pathToHighlight === path ? `${menuItemClassName}panel-menu-activated-item` : `${menuItemClassName}`;
          }
        }


        if (item.isApplicationOrStaticLink) {
          if (item.icon == '' || !item.icon) {
            // Used to apply CSS when item.icon is empty or the application doesn't have any icon applied to itself.
            item.icon = `icon-to-apply-css-${index}`
          }

          const updated: string = item.update ? `?${item.update}` : ''; /**< Updated date. */
          extraStyles += `
          span[class*="${item.icon}"] {
            width: 20px;
            margin-left: 2px;
          }
          span[class*="${item.icon}"]:before {
            content: "";
            display: block;
            width: 15px;
            height:15px;
            background-image: url("${item.icon}${updated}");
            background-size: 15px 15px;
          }
          `;
        }
      })

      handleUserMenuItemsPopulated(extraStyles);

      return (
        <>
          <PanelMenu model={allMenuItems} ref={menu} />
        </>
      )
    } else {
      return (
        <>
          <PanelMenu model={menuItems} ref={menu} />
        </>
      )
    }
  };

  /// Render logo in navbar brand
  const getBrandImage = (className = "") => {
    return (
      <Navbar.Brand className={className}>
        <ImagePreview
          showInverted={true}
          showDelete={false}
          imageUrl={logoUrl}
          isLogo={true}
          noImageContainer={logoNoImageContainer}
          altText={logoAlt}
          imgClass={"img-fixed-height"}
        />
      </Navbar.Brand>
    );
  };

  const getThemeModeButton = () => {
    return (
      <div className="theme-mode-button theme-mode-button-from-sidebar ml-2">
        {LocalStorage.getThemeMode() === ThemeMode.darkMode ? (
          <PrimereactButton
            onClick={() => didChangeTheme(ThemeMode.lightMode)}
            icon="far fa-sun sidebar_icon"
            className="p-button-text w-100 d-flex px-2"
          />
        ) : (
          <PrimereactButton
            onClick={() => didChangeTheme(ThemeMode.darkMode)}
            icon="far fa-moon sidebar_icon"
            className="p-button-text w-100 d-flex px-2"
          />
        )}
      </div>
    );
  };

 /**
  * Get language selector
  * @returns 
  */
  const getLanguageSwitchButton = () => {
    return (
      <div className="language-menu dark-mode">
        <LanguageSelector 
          i18nService={i18nService}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          isFromSidebar
        />
      </div>
    );
  };

  const ProfileCardComponent = (): JSX.Element => (
    // The profile card should be rendered only when the token exists.
    // This component is using some data from the token.
    LocalStorage.getXAuthToken() ? 
      <ProfileCard 
        userInformation={userInformation as IUserProfile} 
        isRenderingThroughSidebar
        onAccount={onAccount} 
        onLogout={onLogout}
        isTokenCountVisible
      /> : <></>
  )

  /// Get Brand icon and welcome user
  const getMenuHeader = () => {
    return (
      <Row className="menuHeader justify-content-center">
        <Col className="p-0 brand-image-container">
          {getBrandImage()}
        </Col>

        <ProfileCardComponent />
      </Row>
    );
  };

  return (
    <>
      <Col sm={3} md={3} xl={2} className="sidebarCol p-0">
        <div className="sidebarContainer">
          {getMenuHeader()}
          {getTieredMenu()}
          <div className="d-flex align-items-center justify-content-center mt-5">
            {getLanguageSwitchButton()}
            {getThemeModeButton()}
          </div>
        </div>
      </Col>

      <Navbar
        fixed="top"
        expand="lg"
        variant="dark"
        className="sidebarNav col-12 py-2 px-2"
        expanded={isExpanded}
      >
        <Navbar.Toggle
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          onClick={() => setExpanded(!isExpanded)}
        />
        {getBrandImage("pl-2")}

        <Navbar.Collapse id="navbarCollapse" ref={sidebarNavReference}>
          <Nav>
            <div className="d-flex justify-content-center">
              <ProfileCardComponent />
            </div>

            {getTieredMenu()}
            <div className="d-flex flex-direction-row justify-content-center">
              <div className="mt-4 mb-1">{getLanguageSwitchButton()}</div>
              <div className="mt-4 mb-1 theme-mode-button">{getThemeModeButton()}</div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>


    </>
  );
};

export default SidebarPage;