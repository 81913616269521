import { MutableRefObject } from "react";

/// Triggers on sidemenu click - Adds active class to the clicked menu item and navigates to the specified page
export const menuItemClick = (
  menu: MutableRefObject<any>,
  setMenuItems: (data: any) => void,
  history: any,
  path: string,
  id = '',
  isMobile: boolean = false,
  shallNavigate = true
): void => {
  if (!path) {
    return;
  }
  const items = 
    menu && 
    menu.current && 
    menu.current.properties &&
    menu.current.properties.model;
    
  if (items && items.length) {
    if (!id) {
      id = path.substring(path.lastIndexOf("/") + 1, path.length);
    }
    const item: any = items.find((menuItem: any) => menuItem.id === id);
    if (item) {
      // Add active class to the selected item
      item.className = "activeMenuItemLink";
      setMenuItems(
        items.map((menuItem: any) => {
          if (menuItem.id === item.id) {
            return item;
          }
          if (menuItem.className !== "") {
            menuItem.className = "";
          }
          return menuItem;
        })
      );
    } else {
      setMenuItems(
        items.map((eachItem: any) => {
          eachItem.className = "";
          return eachItem;
        })
      );
    }
  }
    if (shallNavigate) {
      if(isMobile) {
        window.location.href = path;
      } else {
      history.push(path);
      }
    }
};

/**
 * Interface for Dropdown Menu reference.
 */
export interface IDropdownMenuReference {
  current: HTMLCollection | null; /**< Current value. */
}

/**
 * Triggers on Dropdown Menu click - Add/Remove 'panel-menu-activated-item' class to the clicked parent menu based on dropdown open/close event
 *
 * @param event 
 * @param pathNames 
 * @param parentMenu 
 */
export const dropDownMenuClickHandler = (
  event: any, 
  pathNames: string[],
  parentMenu: HTMLCollection
): void => {
  const ariaExpandedValue: string =
    event.originalEvent.currentTarget.getAttribute('aria-expanded'); /**< aria-expanded value */
  if(ariaExpandedValue === 'true') {
    //Note: Add className 'panel-menu-activated-item' if current path is one of the submenus and dropdown menu is closed.
    addClassNameToDropdownMenu(parentMenu, pathNames)
  } else {
    //Note: Remove className 'panel-menu-activated-item' when dropdown is opened
    removeClassNameFromDropdownMenu(parentMenu);
  }
}

/**
 * Remove 'panel-menu-activated-item' className from dropdown menu.
 * @param parentMenu 
 */
export const removeClassNameFromDropdownMenu = (parentMenu: HTMLCollection | null) => {
  if (parentMenu) {
    for (let i = 0; i < parentMenu.length; i++) {
      parentMenu[i].classList.remove('panel-menu-activated-item')
    }
  }
}

/**
 * Add 'panel-menu-activated-item' className to dropdown menu.
 * @param parentMenu
 * @param pathNames
 */
 export const addClassNameToDropdownMenu = (parentMenu: HTMLCollection | null, pathNames: string[]) => {
  const windowPath: string = window.location.pathname; /**< Location path. */
  if (pathNames.indexOf(windowPath) > -1) { // if current path is one of the submenus
    if (parentMenu) {
      for (let i = 0; i < parentMenu.length; i++) {
        parentMenu[i].classList.add('panel-menu-activated-item')
      }
    }
  }
}