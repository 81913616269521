/*
 * Templates.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Templates.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {createSlice} from '@reduxjs/toolkit';

import {get, getAll, getCss, update, updateCss} from '../Services/Templates';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {Placeholders} from '../Config';
import {showSuccessToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const TEMPLATE_FEATURE_KEY = 'templates';

const GET_TEMPLATES_REQUEST = 'templates/fetchList/request';
const GET_TEMPLATES_SUCCESS = 'templates/fetchList/success';
const GET_TEMPLATES_FAILURE = 'templates/fetchList/failure';

const GET_PLACEHOLDERS_REQUEST = 'templates/placeholderfetch/request';
const GET_PLACEHOLDERS_SUCCESS = 'templates/placeholderfetch/success';
const GET_PLACEHOLDERS_FAILURE = 'templates/placeholderfetch/failure';

const UPDATE_PLACEHOLDERS_REQUEST = 'templates/placeholderUpdate/request';
const UPDATE_PLACEHOLDERS_SUCCESS = 'templates/placeholderUpdate/success';
const UPDATE_PLACEHOLDERS_FAILURE = 'templates/placeholderUpdate/failure';

const GET_TEMPLATE_REQUEST = 'templates/fetch/request';
const GET_TEMPLATE_SUCCESS = 'templates/fetch/success';
const GET_TEMPLATE_FAILURE = 'templates/fetch/failure';

const GET_CSS_REQUEST = 'templates/cssFetch/request';
const GET_CSS_SUCCESS = 'templates/cssFetch/success';
const GET_CSS_FAILURE = 'templates/cssFetch/failure';

const UPDATE_TEMPLATES_REQUEST = 'templates/update/request';
const UPDATE_TEMPLATES_SUCCESS = 'templates/update/success';
const UPDATE_TEMPLATES_FAILURE = 'templates/update/failure';

const UPDATE_CSS_REQUEST = 'templates/cssUpdate/request';
const UPDATE_CSS_SUCCESS = 'templates/cssUpdate/success';
const UPDATE_CSS_FAILURE = 'templates/cssUpdate/failure';

const INITIAL_STATE = {
  list: [],
  listIsFetching: true,
  templateIsFetching: true,
  template: '',
  cssIsFetching: true,
  css: '',
  placeholders: {},
  placeholderIsFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATES_REQUEST:
      return { ...state, listIsFetching: true };
    case GET_TEMPLATES_FAILURE:
      return { ...state, listIsFetching: false };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        list: action.payload.templates
      };

    case GET_TEMPLATE_REQUEST:
      return { ...state, templateIsFetching: true };
    case GET_TEMPLATE_FAILURE:
      return { ...state, templateIsFetching: false };
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateIsFetching: false,
        template: action.payload.template
      };

    case GET_CSS_REQUEST:
      return { ...state, cssIsFetching: true };
    case GET_CSS_FAILURE:
      return { ...state, cssIsFetching: false };
    case GET_CSS_SUCCESS:
      return {
        ...state,
        cssIsFetching: false,
        css: action.payload.css
      };

    case GET_PLACEHOLDERS_REQUEST:
      return { ...state, placeholderIsFetching: true };
    case GET_PLACEHOLDERS_FAILURE:
      return { ...state, placeholderIsFetching: false, placeholders: {} };
    case GET_PLACEHOLDERS_SUCCESS:
      return {
        ...state,
        placeholderIsFetching: false,
        placeholders: action.payload.placeholders
      };

    case UPDATE_PLACEHOLDERS_REQUEST:
      return { ...state, placeholderIsFetching: true };
    case UPDATE_PLACEHOLDERS_FAILURE:
      return { ...state, placeholderIsFetching: false };
    case UPDATE_PLACEHOLDERS_SUCCESS:
      const { key, value } = action.payload;
      const newPlaceholder = { ...state.placeholders, [key]: value };
      return {
        ...state,
        placeholderIsFetching: false,
        placeholders: newPlaceholder
      };

    case UPDATE_TEMPLATES_REQUEST:
    case UPDATE_TEMPLATES_SUCCESS:
    case UPDATE_TEMPLATES_FAILURE:

    case UPDATE_CSS_REQUEST:
      return {
        ...state,
        cssIsFetching: true
      };
    case UPDATE_CSS_SUCCESS:
      return {
        ...state,
        cssIsFetching: false
      };
    case UPDATE_CSS_FAILURE:
      return {
        ...state,
        cssIsFetching: false
      };
    default:
      return state;
  }
};

const getTemplatesRequest = () => ({
  type: GET_TEMPLATES_REQUEST
});

const getTemplatesSuccess = (templates) => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: { templates }
});

const getTemplatesFailure = () => ({
  type: GET_TEMPLATES_FAILURE
});

const getTemplateRequest = () => ({
  type: GET_TEMPLATE_REQUEST
});

const getTemplateSuccess = (template) => ({
  type: GET_TEMPLATE_SUCCESS,
  payload: { template }
});

const getTemplateFailure = () => ({
  type: GET_TEMPLATE_FAILURE
});

const getCssRequest = () => ({
  type: GET_CSS_REQUEST
});

const getCssSuccess = (css) => ({
  type: GET_CSS_SUCCESS,
  payload: { css }
});

const getCssFailure = () => ({
  type: GET_CSS_FAILURE
});

const updateTemplateRequest = () => ({
  type: UPDATE_TEMPLATES_REQUEST
});

const updateTemplateSuccess = () => ({
  type: UPDATE_TEMPLATES_SUCCESS
});

const updateTemplateFailure = () => ({
  type: UPDATE_TEMPLATES_FAILURE
});

const updateCssRequest = () => ({
  type: UPDATE_CSS_REQUEST
});

const updateCssSuccess = () => ({
  type: UPDATE_CSS_SUCCESS
});

const updateCssFailure = () => ({
  type: UPDATE_CSS_FAILURE
});

const getPlaceholderRequest = () => ({
  type: GET_PLACEHOLDERS_REQUEST
});

const getPlaceholderSuccess = (placeholders) => ({
  type: GET_PLACEHOLDERS_SUCCESS,
  payload: { placeholders }
});

const getPlaceholderFailure = () => ({
  type: GET_PLACEHOLDERS_FAILURE
});

const updatePlaceholderRequest = () => ({
  type: UPDATE_PLACEHOLDERS_REQUEST
});

const updatePlaceholderSuccess = (key, value) => ({
  type: UPDATE_PLACEHOLDERS_SUCCESS,
  payload: { key, value }
});

const updatePlaceholderFailure = () => ({
  type: UPDATE_PLACEHOLDERS_FAILURE
});

export const getAllTemplates = () => async (dispatch) => {
  try {
    dispatch(getTemplatesRequest());
    const response = await asyncErrorHandler(getAll());
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getTemplatesFailure());
    } else {
      dispatch(getTemplatesSuccess(response.templates));
    }
  } catch (e) {
    dispatch(getTemplatesFailure());
    if (e && e.error && e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const getOneTemplate = (templateId) => async (dispatch) => {
  try {
    dispatch(getTemplateRequest());
    const response = await asyncErrorHandler(get(templateId));
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getTemplateFailure());
    } else {
      dispatch(getTemplateSuccess(response.template.template));
    }
  } catch (e) {
    dispatch(getTemplateFailure());
    if (e && e.error && e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const updateTemplate = (templateId, template) => async (dispatch) => {
  try {
    dispatch(updateTemplateRequest());
    const result = await asyncErrorHandler(update(templateId, template));
    if (result.error) {
      dispatch(updateTemplateFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(updateTemplateSuccess());
      showSuccessToast(result.message ?? '');
      dispatch(getAllTemplates());
    }
  } catch (e) {
    dispatch(updateTemplateFailure());
    if (e && e.error && e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const getCustomCss = () => async (dispatch) => {
  try {
    dispatch(getCssRequest());
    const response = await asyncErrorHandler(getCss());
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getCssFailure());
    } else {
      dispatch(getCssSuccess(response));
    }
  } catch (e) {
    dispatch(getCssFailure());
    handleError({ message: e.message });
  }
};

export const updateCustomCss = (css) => async (dispatch) => {
  try {
    dispatch(updateCssRequest());
    const result = await asyncErrorHandler(updateCss(css));
    if (result.error) {
      dispatch(updateCssFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(updateCssSuccess());
      showSuccessToast(result.message ?? '');
      dispatch(getCustomCss());
    }
  } catch (e) {
    dispatch(updateCssFailure());
    if (e && e.error && e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const getTemplatePlaceholder = (template) => (dispatch) => {
  try {
    dispatch(getPlaceholderRequest());
    const result = Placeholders[template];
    if (!result) {
      dispatch(getPlaceholderFailure());
    } else {
      dispatch(getPlaceholderSuccess(result));
    }
  } catch (e) {
    dispatch(getPlaceholderFailure());
  }
};

export const updateTemplatePlaceholder = (name, newValue) => (dispatch) => {
  try {
    dispatch(updatePlaceholderRequest());
    if (!newValue) {
      dispatch(updatePlaceholderFailure());
    } else {
      dispatch(updatePlaceholderSuccess(name, newValue));
    }
  } catch (e) {
    dispatch(updatePlaceholderFailure());
  }
};

/**
 * Creates Slice - All Settings related state will be stored here
 */
export const templatesSlice = createSlice({
  name: TEMPLATE_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getTemplatesRequest(state) {
      state.list = [];
    },
    getTemplatesSuccess(state, action) {
      state.list = action.payload;
    },
    getTemplatesFailure(state) {
      state.list = [];
    }
  },
  extraReducers: {}
});

export const settingReducer = templatesSlice.reducer;
export const getAllTemplatesState = (rootState) =>
  rootState[TEMPLATE_FEATURE_KEY];
