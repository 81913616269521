/**
 * DynamicTranslate (AbstractECommerce) *
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved. *
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all it's contents are proprietary and confidential. *
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 * @file DynamicTranslate
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { ICountryTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/CountryTranslation';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';
import { ICountry } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * Get product / store settings / country translation
 * @param translationData
 * @param callback
 */
const getSelectedTranslation = (
  translationData:
    | IProductTranslation[]
    | IStoreTranslation[]
    | ICountryTranslation[],
  callback: (
    selectedLanguageTranslation:
      | IProductTranslation
      | IStoreTranslation
      | ICountryTranslation,
    defaultTranslation:
      | IProductTranslation
      | IStoreTranslation
      | ICountryTranslation
  ) => any
) => {
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */

  const selectedLanguageTranslation:
    | IProductTranslation
    | IStoreTranslation
    | ICountryTranslation = translationData?.find(
    (data: IProductTranslation | IStoreTranslation | ICountryTranslation) =>
      data.language === languageSettingsMode
  ) as
    | IProductTranslation
    | IStoreTranslation
    | ICountryTranslation; /**< Selected Translated data */

  const defaultTranslation:
    | IProductTranslation
    | IStoreTranslation
    | ICountryTranslation = translationData?.find(
    (data: IProductTranslation | IStoreTranslation | ICountryTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as
    | IProductTranslation
    | IStoreTranslation
    | ICountryTranslation; /**< English Translated data */

  return callback(selectedLanguageTranslation, defaultTranslation);
};

/**
 * Get array of text translation
 * @param defaultTranslationTextArray
 * @param selectedTranslationTextArray
 * @returns
 */
const getArrayOfTextTranslation = (
  defaultTranslationTextArray: string[],
  selectedTranslationTextArray: string[]
): string[] => {
  let formattedTextArray: string[] = [];
  if (defaultTranslationTextArray && defaultTranslationTextArray.length) {
    formattedTextArray = [...defaultTranslationTextArray];
  }

  if (
    formattedTextArray &&
    formattedTextArray.length &&
    selectedTranslationTextArray &&
    selectedTranslationTextArray.length
  ) {
    for (let index = 0; index < selectedTranslationTextArray.length; index++) {
      if (
        !isStringEmptyOrNullOrUndefined(selectedTranslationTextArray[index])
      ) {
        formattedTextArray[index] = selectedTranslationTextArray[index];
      }
    }
  }

  return formattedTextArray;
};

/**
 * Get translated product description
 * @param product
 */
export const getTranslatedProductDescription = (product: IProduct): string => {
  return getSelectedTranslation(
    product?.translationData as IProductTranslation[],
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.description as string
      )
        ? selectedLanguageTranslation?.description
        : defaultTranslation?.description
  );
};

/**
 * Get translated SLA Information
 * @param product
 */
export const getTranslatedSLAInformation = (product: IProduct): string[] => {
  return getSelectedTranslation(
    product?.translationData as IProductTranslation[],
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.slaInformation as string[],
        selectedLanguageTranslation?.slaInformation as string[]
      )
  );
};

/**
 * Get translated Product options
 * @param productTranslation
 */
export const getTranslatedProductOptions = (
  productTranslation: IProductTranslation[] | IStoreTranslation[]
): string[] => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation | IStoreTranslation,
      defaultTranslation: IProductTranslation | IStoreTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.productOptionsDescription as string[],
        selectedLanguageTranslation?.productOptionsDescription as string[]
      )
  );
};

/**
 * Get translated checkout option
 * @param product
 */
export const getTranslatedCheckoutOption = (product: IProduct): string[] => {
  return getSelectedTranslation(
    product?.translationData as IProductTranslation[],
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.checkoutOptionsDescription as string[],
        selectedLanguageTranslation?.checkoutOptionsDescription as string[]
      )
  );
};

/**
 * Get translated SLA terms text
 * @param storeTranslation
 */
export const getTranslatedSLATermsText = (
  storeTranslation: IStoreTranslation[]
): string => {
  return getSelectedTranslation(
    storeTranslation,
    (
      selectedLanguageTranslation: IStoreTranslation,
      defaultTranslation: IStoreTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.slaTermsText as string
      )
        ? selectedLanguageTranslation?.slaTermsText
        : defaultTranslation?.slaTermsText
  );
};

/**
 * Get translated Web terms text
 * @param storeTranslation
 */
export const getTranslatedWebTermsText = (
  storeTranslation: IStoreTranslation[]
): string => {
  return getSelectedTranslation(
    storeTranslation,
    (
      selectedLanguageTranslation: IStoreTranslation,
      defaultTranslation: IStoreTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.webTermsText as string
      )
        ? selectedLanguageTranslation?.webTermsText
        : defaultTranslation?.webTermsText
  );
};

/**
 * Get translated freeProductDiscountName
 * @param productTranslation
 */
export const getTranslatedFreeProductDiscountName = (
  productTranslation: IProductTranslation[]
): string => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.freeProductDiscountName as string
      )
        ? selectedLanguageTranslation?.freeProductDiscountName
        : defaultTranslation?.freeProductDiscountName
  );
};

/**
 * Get translated checkout condition
 * @param country
 */
export const getTranslatedCheckoutCondition = (country: ICountry): string[] => {
  return getSelectedTranslation(
    country?.translationData as ICountryTranslation[],
    (
      selectedLanguageTranslation: ICountryTranslation,
      defaultTranslation: ICountryTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.checkoutConditionsDescription as string[],
        selectedLanguageTranslation?.checkoutConditionsDescription as string[]
      )
  );
};

/**
 * Get translated Product options information
 * @param productTranslation
 */
export const getTranslatedProductOptionsInformation = (
  productTranslation: IProductTranslation[] | IStoreTranslation[]
): string[] => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation | IStoreTranslation,
      defaultTranslation: IProductTranslation | IStoreTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.productOptionsInformation as string[],
        selectedLanguageTranslation?.productOptionsInformation as string[]
      )
  );
};

/**
 * Get translated disabledProductMessage
 * @param productTranslation
 */
export const getTranslatedDisabledProductMessage = (
  productTranslation: IProductTranslation[]
): string => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.disabledProductMessage as string
      )
        ? selectedLanguageTranslation?.disabledProductMessage
        : defaultTranslation?.disabledProductMessage
  );
};

/**
 * Get translated next step text
 * @param productTranslation
 */
export const getTranslatedNextStepText = (
  productTranslation: IProductTranslation[]
): string => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.nextStepText as string
      )
        ? selectedLanguageTranslation?.nextStepText
        : defaultTranslation?.nextStepText
  );
};

/**
 * Get translated nextStepButtonsText
 * @param product
 */
export const getTranslatedNextStepButtonsText = (
  productTranslation: IProductTranslation[]
): string[] => {
  return getSelectedTranslation(
    productTranslation,
    (
      selectedLanguageTranslation: IProductTranslation,
      defaultTranslation: IProductTranslation
    ) =>
      getArrayOfTextTranslation(
        defaultTranslation?.nextStepButtonsText as string[],
        selectedLanguageTranslation?.nextStepButtonsText as string[]
      )
  );
};

/**
 * Get translated loggedOutWarningMessage
 * @param storeTranslation
 */
export const getTranslatedLoggedOutWarningMessage = (
  storeTranslation: IStoreTranslation[]
): string => {
  return getSelectedTranslation(
    storeTranslation,
    (
      selectedLanguageTranslation: IStoreTranslation,
      defaultTranslation: IStoreTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.loggedOutWarningMessage as string
      )
        ? selectedLanguageTranslation?.loggedOutWarningMessage
        : defaultTranslation?.loggedOutWarningMessage
  );
};

/**
 * Get translated Template Web terms text
 * @param storeTranslation
 */
export const getTranslatedTemplateWebTermsText = (
  storeTranslation: IStoreTranslation[]
): string => {
  return getSelectedTranslation(
    storeTranslation,
    (
      selectedLanguageTranslation: IStoreTranslation,
      defaultTranslation: IStoreTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.templateWebTermsText as string
      )
        ? selectedLanguageTranslation?.templateWebTermsText
        : defaultTranslation?.templateWebTermsText
  );
};

/**
 * Get translated Template SLA terms text
 * @param storeTranslation
 */
export const getTranslatedTemplateSLATermsText = (
  storeTranslation: IStoreTranslation[]
): string => {
  return getSelectedTranslation(
    storeTranslation,
    (
      selectedLanguageTranslation: IStoreTranslation,
      defaultTranslation: IStoreTranslation
    ) =>
      !isStringEmptyOrNullOrUndefined(
        selectedLanguageTranslation?.templateSLATermsText as string
      )
        ? selectedLanguageTranslation?.templateSLATermsText
        : defaultTranslation?.templateSLATermsText
  );
};
