/*
 * ShopSettings.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ShopSettings.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Resizer from 'react-image-file-resizer';
import {
  deleteShopFaviconCall,
  deleteShopLogoCall,
  getApplicationEnvironmentVariablesAPI,
  getSecureSettings,
  getSettings,
  getUserSettingsAPI,
  savePdfInvoiceSummaryCall,
  saveSettingsCall,
  testSMTPActionAPI,
  uploadShopFaviconCall,
  uploadShopLogoCall
} from '../Services/Settings';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {showSuccessToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IPublicEnvironmentVariables
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/EnvironmentVariables';
import {fetchSystemWarningsAction} from './Dashboard';
import {IGetSafeSettingsResponse} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_SETTINGS_REQUEST = 'settings/fetch/request';
const GET_SETTINGS_SUCCESS = 'settings/fetch/success';
const GET_SETTINGS_FAILURE = 'settings/fetch/failure';

const GET_SECURE_SETTINGS_REQUEST = 'settings/admin/fetch/request';
const GET_SECURE_SETTINGS_SUCCESS = 'settings/admin/fetch/success';
const GET_SECURE_SETTINGS_FAILURE = 'settings/admin/fetch/failure';

const SAVE_SETTINGS_REQUEST = 'settings/save/request';
const SAVE_SETTINGS_SUCCESS = 'settings/save/success';
const SAVE_SETTINGS_FAILURE = 'settings/save/failure';

const UPLOAD_SHOP_LOGO_REQUEST = 'settings/shop-logo/upload/request';
const UPLOAD_SHOP_LOGO_FAILURE = 'settings/shop-logo/upload/failure';

const UPLOAD_SHOP_FAVICON_FAILURE = 'settings/shop-favicon/upload/failure';

const SAVE_PDF_INVOICE_SUMMARY_REQUEST =
  'settings/pdf-invoice-summary/save/request';
const SAVE_PDF_INVOICE_SUMMARY_SUCCESS =
  'settings/pdf-invoice-summary/save/success';
const SAVE_PDF_INVOICE_SUMMARY_FAILURE =
  'settings/pdf-invoice-summary/save/failure';

const DELETE_IMAGE_FAILURE = 'settings/image/failure';

/// Test SMTP settings
const TEST_SMTP_REQUEST = 'settings/smtpTest/request';
const TEST_SMTP_SUCCESS = 'settings/smtpTest/success';
const TEST_SMTP_FAILURE = 'settings/smtpTest/failure';

/// Get Environment variables
const GET_ENVIRONMENTVARIABLES_REQUEST =
  'settings/environmentvariables/request';
const GET_ENVIRONMENTVARIABLES_SUCCESS =
  'settings/environmentvariables/success';
const GET_ENVIRONMENTVARIABLES_FAILURE =
  'settings/environmentvariables/failure';

/// Get user application settings
const GET_USER_APPLICATION_SETTINGS_REQUEST =
  'userApplication/settings/fetch/request';
const GET_USER_APPLICATION_SETTINGS_SUCCESS =
  'userApplication/settings/fetch/success';
const GET_USER_APPLICATION_SETTINGS_FAILURE =
  'userApplication/settings/fetch/failure';

const INITIAL_STATE = {
  list: {},
  secureList: null,
  listIsFetching: true,
  secureListIsFetching: false,
  uploadedImage: null,
  uploadedFavicon: null,
  uploadedPDFImage: null,
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  },
  isSettingsUpdated: false /**< Is settings update. */,
  environmentVariables: null /**< Environment variables. */,
  isLoadingTestSMTP: false /**< Loading state for test smtp */,
  userApplicationSafeSettings: null /**< User application safe settings. */
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return { ...state, listIsFetching: true };
    case GET_SETTINGS_SUCCESS:
      return { ...state, listIsFetching: false, list: action.payload };
    case GET_SETTINGS_FAILURE:
      return { ...state, listIsFetching: false };
    case GET_SECURE_SETTINGS_REQUEST:
      return { ...state, secureListIsFetching: true };
    case GET_SECURE_SETTINGS_SUCCESS:
      return {
        ...state,
        secureListIsFetching: false,
        secureList: action.payload
      };
    case GET_SECURE_SETTINGS_FAILURE:
      return { ...state, secureListIsFetching: false };
    case SAVE_SETTINGS_REQUEST:
      return { ...state, listIsFetching: true };
    case SAVE_SETTINGS_SUCCESS:
      return { ...state, listIsFetching: false, isSettingsUpdated: true };
    case SAVE_SETTINGS_FAILURE:
      return { ...state, listIsFetching: false };

    case UPLOAD_SHOP_LOGO_REQUEST:
      return { ...state, listIsFetching: true };
    case UPLOAD_SHOP_LOGO_FAILURE:
      return { ...state, listIsFetching: false };

    case UPLOAD_SHOP_FAVICON_FAILURE:
      return { ...state, listIsFetching: false };

    case SAVE_PDF_INVOICE_SUMMARY_REQUEST:
      return { ...state, listIsFetching: true };
    case SAVE_PDF_INVOICE_SUMMARY_SUCCESS:
      return { ...state, listIsFetching: false };
    case SAVE_PDF_INVOICE_SUMMARY_FAILURE:
      return { ...state, listIsFetching: false };

    case DELETE_IMAGE_FAILURE:
      return { ...state, listIsFetching: false };
    case TEST_SMTP_REQUEST:
      return { ...state, listIsFetching: true, isLoadingTestSMTP: true };
    case TEST_SMTP_SUCCESS:
      return { ...state, listIsFetching: false, isLoadingTestSMTP: false };
    case TEST_SMTP_FAILURE:
      return { ...state, listIsFetching: false, isLoadingTestSMTP: false };
    case GET_ENVIRONMENTVARIABLES_REQUEST:
      return { ...state, listIsFetching: true, environmentVariables: null };
    case GET_ENVIRONMENTVARIABLES_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        environmentVariables: action.payload
      };
    case GET_ENVIRONMENTVARIABLES_FAILURE:
      return { ...state, listIsFetching: false };
    case GET_USER_APPLICATION_SETTINGS_REQUEST:
      return {
        ...state,
        listIsFetching: true,
        userApplicationSafeSettings: null
      };
    case GET_USER_APPLICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        userApplicationSafeSettings: action.payload
      };
    case GET_USER_APPLICATION_SETTINGS_FAILURE:
      return { ...state, listIsFetching: false };
    default:
      return state;
  }
};

const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST
});

const getSettingsSuccess = (settings) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings
});

const getSettingsFailure = () => ({
  type: GET_SETTINGS_FAILURE
});

const getSecureSettingsRequest = () => ({
  type: GET_SECURE_SETTINGS_REQUEST
});

const getSecureSettingsSuccess = (settings) => ({
  type: GET_SECURE_SETTINGS_SUCCESS,
  payload: settings
});

const getSecureSettingsFailure = () => ({
  type: GET_SECURE_SETTINGS_FAILURE
});

const saveSettingsRequest = () => ({
  type: SAVE_SETTINGS_REQUEST
});

const saveSettingsSuccess = () => ({
  type: SAVE_SETTINGS_SUCCESS
});

const saveSettingsFailure = () => ({
  type: SAVE_SETTINGS_FAILURE
});

const uploadShopLogoFailure = () => ({
  type: UPLOAD_SHOP_LOGO_FAILURE
});

const uploadShopFaviconFailure = () => ({
  type: UPLOAD_SHOP_FAVICON_FAILURE
});

const savePdfInvoiceSummaryRequest = () => ({
  type: SAVE_PDF_INVOICE_SUMMARY_REQUEST
});

const savePdfInvoiceSummarySuccess = () => ({
  type: SAVE_PDF_INVOICE_SUMMARY_SUCCESS
});

const savePdfInvoiceSummaryFailure = () => ({
  type: SAVE_PDF_INVOICE_SUMMARY_FAILURE
});

const deleteShopFaviconFailure = () => ({
  type: DELETE_IMAGE_FAILURE
});

const deleteShopLogoFailure = () => ({
  type: DELETE_IMAGE_FAILURE
});

const testSMTPActionRequest = () => ({
  type: TEST_SMTP_REQUEST
});

const testSMTPActionSuccess = () => ({
  type: TEST_SMTP_SUCCESS
});

const testSMTPActionFailure = () => ({
  type: TEST_SMTP_FAILURE
});

const getEnvironmentVariablesActionRequest = () => ({
  type: GET_ENVIRONMENTVARIABLES_REQUEST
});

const getEnvironmentVariablesActionSuccess = (
  environmentVariables: IPublicEnvironmentVariables
) => ({
  type: GET_ENVIRONMENTVARIABLES_SUCCESS,
  payload: environmentVariables
});

const getEnvironmentVariablesActionFailure = () => ({
  type: GET_ENVIRONMENTVARIABLES_FAILURE
});

const getUserApplicationSettingsActionRequest = () => ({
  type: GET_USER_APPLICATION_SETTINGS_REQUEST
});

const getUserApplicationSettingsActionSuccess = (
  userSettings: IGetSafeSettingsResponse
) => ({
  type: GET_USER_APPLICATION_SETTINGS_SUCCESS,
  payload: userSettings
});

const getUserApplicationSettingsActionFailure = () => ({
  type: GET_USER_APPLICATION_SETTINGS_FAILURE
});

export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(getSettingsRequest());
    const result = await asyncErrorHandler(getSettings());
    if (result.error) {
      dispatch(getSettingsFailure(result.error));
    } else {
      dispatch(getSettingsSuccess(result.store));
    }
  } catch (error) {
    dispatch(getSettingsFailure(error.message));
  }
};

export const fetchSecureSettings = () => async (dispatch) => {
  try {
    dispatch(getSecureSettingsRequest());
    const result = await asyncErrorHandler(getSecureSettings());
    if (result.error) {
      dispatch(getSecureSettingsFailure(result.error));
    } else {
      dispatch(getSecureSettingsSuccess(result));
    }
  } catch (error) {
    dispatch(getSecureSettingsFailure(error.message));
  }
};

export const saveSettings = (payload: Record<string, any>) => async (
  dispatch
) => {
  try {
    dispatch(saveSettingsRequest());
    const settings: Record<string, any> =
      payload.settings; /**< Store settings. */
    const uploadLogo: Record<string, Blob> =
      payload.uploadLogo; /**< To upload logo. */
    const deleteLogo: boolean = payload.deleteLogo; /**< To delete logo. */
    const uploadFavouriteIcon: Record<string, Blob> =
      payload.uploadFavouriteIcon; /**< To upload favicon. */
    const deleteFavouriteIcon: boolean =
      payload.deleteFavouriteIcon; /**< To delete favicon. */

    // To upload logo
    if (uploadLogo) {
      const result = await asyncErrorHandler(uploadShopLogoCall(uploadLogo.file));
      if (result.error) {
        dispatch(uploadShopLogoFailure());
        handleError({ message: result.error.message });
        return false;
      }
      settings['logo'] = result.data.imageID;
    }
    // To delete logo
    if (deleteLogo) {
      const result = await asyncErrorHandler(deleteShopLogoCall());
      if (result.error) {
        dispatch(deleteShopLogoFailure());
        handleError({
          message: result.error.message || 'Failed to delete Store Logo.'
        });
        return false;
      }
      settings['logo'] = '';
    }
    // To upload favicon
    if (uploadFavouriteIcon) {
      const result = await asyncErrorHandler(uploadShopFaviconCall(uploadFavouriteIcon.file));
      if (result.error) {
        dispatch(uploadShopFaviconFailure());
        handleError({ message: result.error.message });
        return false;
      }
      settings['favicon'] = result.data.imageID;
    }
    // To delete favicon
    if (deleteFavouriteIcon) {
      const result = await asyncErrorHandler(deleteShopFaviconCall());
      if (result.error) {
        dispatch(deleteShopFaviconFailure());
        handleError({
          message: result.error.message || 'Failed to delete Store Favicon.'
        });
        return false;
      }
      settings['favicon'] = '';
    }
    const result = await asyncErrorHandler(saveSettingsCall(settings));
    if (result.error) {
      dispatch(saveSettingsFailure(result.error));
      handleError({ message: result.error.message });
    } else {
      dispatch(saveSettingsSuccess());
      dispatch(fetchSettings());
      dispatch(fetchSecureSettings());
      dispatch(fetchSystemWarningsAction());
      showSuccessToast('Application Settings Updated');
    }
  } catch (error) {
    dispatch(saveSettingsFailure());
    if (error && error.error && error.error.message) {
      handleError({ message: error.error.message });
    } else {
      handleError({ message: error.message });
    }
  }
};

const resizer = (image, size) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      image,
      size,
      size,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export const resizeImage = async (imageUrl, size) => {
  const image = await asyncErrorHandler(fetch(imageUrl).then((response) => response.blob()));
  const newImage = await asyncErrorHandler(resizer(image, size));
  return newImage;
};

export const savePdfInvoiceSummary = (payload) => async (dispatch) => {
  try {
    dispatch(savePdfInvoiceSummaryRequest());
    const result = await asyncErrorHandler(savePdfInvoiceSummaryCall(payload));
    if (result.error) {
      dispatch(savePdfInvoiceSummaryFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(savePdfInvoiceSummarySuccess());
      dispatch(fetchSettings());
      dispatch(fetchSecureSettings());
      showSuccessToast(result.message);
    }
  } catch (error) {
    dispatch(savePdfInvoiceSummaryFailure());
    if (error.error.message) {
      handleError({ message: error.error.message });
    } else {
      handleError({ message: error.message });
    }
  }
};

/**
 * Update Analytics Visibility
 * @param {*} analyticItems Analytics items.
 * @returns
 */
export const updateAnalyticsVisibility = (analyticItems) => async (
  dispatch
) => {
  try {
    dispatch(saveSettingsRequest());
    const result = await asyncErrorHandler(saveSettingsCall({analyticItems}));
    if (result.error) {
      dispatch(saveSettingsFailure(result.error));
    } else {
      dispatch(saveSettingsSuccess());
    }
  } catch (error) {
    dispatch(saveSettingsFailure());
    if (error && error.error && error.error.message) {
      handleError({ message: error.error.message });
    } else {
      handleError({ message: error.message });
    }
  }
};

/**
 * Test SMTP settings
 */
export const testSMTPAction = (payload: any) => async (dispatch: any) => {
  const errorMessage: string = 'Error while testing SMTP';
  try {
    dispatch(testSMTPActionRequest());
    const result: IAPIEntityResponse<string> = await asyncErrorHandler(testSMTPActionAPI(payload));
    if (result.error) {
      dispatch(testSMTPActionFailure());
      handleError({ message: result.error.message || errorMessage });
    } else {
      dispatch(testSMTPActionSuccess());
      dispatch(fetchSystemWarningsAction());
      showSuccessToast(result.data || 'Test SMTP successfully');
    }
  } catch (error) {
    dispatch(testSMTPActionFailure());
    handleError({ message: error.message || errorMessage });
  }
};

/**
 * Get application environment variables Action.
 */
export const getApplicationEnvironmentVariablesAction = (
  payload: void
) => async (dispatch: any) => {
  const errorMessage: string = 'Error while retrieving Environment Variables';
  try {
    dispatch(getEnvironmentVariablesActionRequest());
    const result: IAPIEntityResponse<IPublicEnvironmentVariables> = await asyncErrorHandler(getApplicationEnvironmentVariablesAPI());
    if (result.error) {
      dispatch(getEnvironmentVariablesActionFailure());
      handleError({ message: result.error.message || errorMessage });
    } else if (result.data) {
      dispatch(getEnvironmentVariablesActionSuccess(result.data));
    }
  } catch (error) {
    dispatch(getEnvironmentVariablesActionFailure());
    handleError({ message: error.message || errorMessage });
  }
};

/**
 * Get user application safe settings .
 */
export const getUserApplicationSafeSettingsAction = () => async (
  dispatch: any
) => {
  const errorMessage: string =
    'Error while retrieving User application safe settings';
  try {
    dispatch(getUserApplicationSettingsActionRequest());
    const result: IAPIEntityResponse<IGetSafeSettingsResponse> = await asyncErrorHandler(getUserSettingsAPI());
    if (result.error) {
      dispatch(getUserApplicationSettingsActionFailure());
      handleError({ message: result.error.message || errorMessage });
    } else if (result.userSettings) {
      dispatch(getUserApplicationSettingsActionSuccess(result.userSettings));
    }
  } catch (error) {
    dispatch(getUserApplicationSettingsActionFailure());
    handleError({ message: error.message || errorMessage });
  }
};
