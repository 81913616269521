/**
 * SharedSubscriptions.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file SharedSubscriptions.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from '../Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { PaymentMethod } from 'braintree';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';

const httpClient = new HttpClient(BASE_API_URL, createLog);

const baseURLPath: string = '/subscriptions/shared/';

/**
 * Refund a subscription.
 * @param subscriptionID Subscription ID to be refunded.
 * @param returns Promise<IAPIEntityResponse<string>>.
 */
export const refundSubscriptionService = (
  subscriptionID: string
): Promise<IAPIEntityResponse<string>> => {
  return httpClient.post(`${baseURLPath}refund/${subscriptionID}`, {});
};

/**
 * Cancel a subscription.
 * @param subscriptionID Subscription ID to be canceled.
 * @param returns Promise<IAPIEntityResponse<string>>.
 */
export const cancelSubscriptionService = (
  subscriptionID: string,
  isTerminateRequest: boolean
): Promise<IAPIEntityResponse<Partial<ISubscription>>> => {
  return httpClient.post(
    `${baseURLPath}cancel/${subscriptionID}?isTerminate=${isTerminateRequest}`,
    {}
  );
};

/**
 * Get subscription payment method.
 * @param subscriptionID Subscription ID
 * @param returns Promise<IAPIEntityResponse<PaymentMethod>>.
 */
export const getSubscriptionPaymentMethodService = (
  subscriptionID: string
): Promise<IAPIEntityResponse<PaymentMethod>> => {
  return httpClient.get(`${baseURLPath}get/paymentMethod/${subscriptionID}`);
};

/**
 * Update a subscription payment method.
 * @param subscriptionID Subscription ID to be updated.
 * @param returns Promise<IAPIEntityResponse<string>>.
 */
export const updateSubscriptionPaymentMethodService = (
  subscriptionID: string,
  paymentMethodNonce: string
): Promise<IAPIEntityResponse<string>> => {
  return httpClient.post(
    `${baseURLPath}update/paymentMethod/${subscriptionID}`,
    { paymentMethodNonce }
  );
};

/**
 * Retry a subscription.
 * @param subscriptionID The subscription UUID to trigger the retry function from the server side.
 * @param transactionPrice The transaction price to retry. The Production environment won't pass the transaction price.
 * @param returns Promise<IAPIEntityResponse<string>>.
 */
export const retrySubscription = (
  subscriptionID: string,
  transactionPrice?: string
): Promise<IAPIEntityResponse<string>> => {
  const urlParameters: URLSearchParams = new URLSearchParams();

  // Only add transactionPrice if it's not undefined. On the Production environment, transactionPrice is always undefined.
  // We allow a custom price on Local and Staging to test the retry functionality properly.
  if (transactionPrice !== undefined) {
    urlParameters.append('transactionPrice', transactionPrice);
  }

  return httpClient.post<string>(`${baseURLPath}retry/${subscriptionID}?${urlParameters.toString()}`, {});
};