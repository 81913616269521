/*
 * i18n.js (AbstractWebCommon)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file i18n.js
 * @author Etienne Daher
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "../translations/en.json";

i18n.use(initReactI18next).init({
  debug: false,
  resources: {
    en: {
      awc: translationEn.translation,
    },
  },
  lng: "en",
  interpolation: { escapeValue: false },
});

i18n.hasResourceBundle('en', 'awc');

export default i18n;
