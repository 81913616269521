/*
 * InstaCheckbox.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file InstaCheckbox.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { IInstaFormControlProperties } from "./form-control.interface";
import PopupInformation from "./PopupInformation";

/**
 * Interface defining the React properties for the InstaCheckbox component.
 */
interface ICheckboxProperties extends Omit<IInstaFormControlProperties, 'onChange'> {
  onChange(e: CheckboxChangeEvent): void; /**< OnChange handler */
  isShowInformationPopup?: boolean; /**< To show information popup */
  popupText?: string; /**< Popup text */
}

const InstaCheckbox = ({
  errors,
  id,
  label,
  name,
  onChange,
  value,
  className,
  isShowInformationPopup = false,
  popupText
}: ICheckboxProperties): ReactElement => {
  return (
    <>
      <Checkbox
        inputId={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={value}
        className={errors[name] ? `p-invalid ${className}` : `${className}`}
      />
      <label htmlFor={id} className="mb-0 pl-2">
        {label}
      </label>
      {isShowInformationPopup ?
       <PopupInformation 
          id={id} 
          popupText={popupText}
        />
         : <></> 
      }
      {errors[name] ? (
        <small id={`${id}-invalid`} className="p-invalid">
          {errors[name]}
        </small>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstaCheckbox;
