/*
 * ApplicationTable.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ApplicationTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useRef, useState } from 'react';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

/**
 * Interface for Application Table properties
 */
interface IApplicationProperties {
  applications: IApplication[] /**< Applications */;
  isLoading: boolean /**< Loading state */;
  handleDialogOpen: () => Promise<void> /**< Handler for dialog open */;
  handleEdit: (e: any) => Promise<void> /**< Handler for edit application*/;
  handleDelete: (
    applicationIDs: string[]
  ) => Promise<void> /**<Handle delete application event. */;
  tablePayload: ITablePayload /**  Table payload. */;
  handlePageUpdate: (
    event: IPageEvent
  ) => void /**< Handle Table page update. */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  handleFilterUpdate: (
    event: string
  ) => void /**< Handle filter update event. */;
  totalRecords: number;
}

const ApplicationTable = (properties: IApplicationProperties): JSX.Element => {
  const translation = useTranslation().t;
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const sort: ISortEvent = properties.tablePayload?.sort; /**< Sort */
  const [selectedApplications, setSelectedApplications] = useState<
    IApplication[] | null
  >(null); /**< Selected Applications. */
  const deleteButtonReference: any = useRef(
    null
  ); /**< Delete Button Reference. */
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event.target);
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedApplications &&
              Object.keys(selectedApplications).length === 0) ||
              !selectedApplications) ??
            false
          }
          buttonReference={deleteButtonReference}
        />
        <ActionButton onClick={properties.handleDialogOpen} />
      </div>

      <SearchBar
        onSearchTermChanged={(data) => properties.handleFilterUpdate(data)}
      />
    </div>
  );

  /// Delete application on Accept
  const onAccept = () => {
    const applicationIDs: string[] = selectedApplications?.map(
      (eachApplication: IApplication) => eachApplication._id
    ) as string[]; /**< applicationIDs */
    properties.handleDelete(applicationIDs);
    setShowConfirmationPopup(false);
    setSelectedApplications(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmationPopup(false);
  };

  /// Initialize confirmation dialog
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: IApplication[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow: IApplication[] = selectedValue.map(
        (row: IApplication) => row
      );
      setSelectedApplications(selectedRow);
    }
  };

  const GetRowExpansionTemplate = (values: { rowData: IApplication }) => (
    <>
      <tr>
        <th>{translation('application.fields.applicationName')}</th>
        <td>{values.rowData['applicationName']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.description')}</th>
        <td>{values.rowData['description']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyLegalName')}</th>
        <td>{values.rowData['companyLegalName']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyShortName')}</th>
        <td>{values.rowData['companyShortName']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyAddress')}</th>
        <td>{values.rowData['companyAddress']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyCity')}</th>
        <td>{values.rowData['companyCity']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyCountry')}</th>
        <td>{values.rowData['companyCountry']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyZIPCode')}</th>
        <td>{values.rowData['companyZIPCode']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyWebsite')}</th>
        <td>{values.rowData['companyWebsite']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyContactEmail')}</th>
        <td>{values.rowData['companyContactEmail']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyContactNumber')}</th>
        <td>{values.rowData['companyContactNumber']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyDETaxID')}</th>
        <td>{values.rowData['companyDETaxID']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.companyEUVatID')}</th>
        <td>{values.rowData['companyEUVatID']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.invoiceNumberPrefix')}</th>
        <td>{values.rowData['invoiceNumberPrefix']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.notificationURL')}</th>
        <td>{values.rowData['notificationURL']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.applicationPageURL')}</th>
        <td>{values.rowData['applicationPageURL']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.slaTermsLink')}</th>
        <td>{values.rowData['slaTermsLink']}</td>
      </tr>
      <tr>
        <th>{translation('application.fields.created')}</th>
        <td>
          {values.rowData.createdAt ? formatDate(values.rowData.createdAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('application.fields.updated')}</th>
        <td>
          {values.rowData.updatedAt ? formatDate(values.rowData.updatedAt) : ''}
        </td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: IApplication) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={properties.applications}
        isLoading={!properties.applications}
        expandedRows={expandedRows}
        dataKey="_id"
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        rowExpansionTemplate={renderExpansionRows}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        first={properties.tablePayload.skip}
        rows={properties.tablePayload.limit}
        onPage={properties.handlePageUpdate}
        onSort={properties.handleSortUpdate}
        header={header}
        totalRecords={properties.totalRecords}
        selection={selectedApplications}
        onSelectionChange={onSelectionChange}
        emptyMessage={translation('application.table.empty_msg')}
      >
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="applicationName"
          header={translation('application.fields.applicationName')}
          headerClassName="table-col-10"
          sortable
          body={(rowData: IApplication) => (
            <DatatableColumn
              title={translation('application.fields.applicationName')}
              data={rowData.applicationName}
            />
          )}
        />
        <Column
          field="description"
          header={translation('application.fields.description')}
          headerClassName="table-col-10"
          sortable
          body={(rowData: IApplication) => (
            <DatatableColumn
              title={translation('application.fields.description')}
              data={rowData.description}
            />
          )}
        />
        <Column
          field="createdAt"
          header={translation('application.fields.created')}
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rowData: IApplication) => (
            <DatatableColumn
              title={translation('application.fields.created')}
              data={rowData.createdAt ? formatDate(rowData.createdAt) : ''}
            />
          )}
        />
        <Column
          field="updatedAt"
          header={translation('application.fields.updated')}
          className="updatedDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="updatedDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rowData: IApplication) => (
            <DatatableColumn
              title={translation('application.fields.updated')}
              data={rowData.updatedAt ? formatDate(rowData.updatedAt) : ''}
            />
          )}
        />
        <Column
          field="edit"
          body={(rowData: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => {
                  properties.handleEdit({ data: rowData });
                }}
                variant="outline"
              >
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="col-width-45 p-0 absolute-position-responsive-screen"
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmationPopup()}
    </>
  );
};

export default ApplicationTable;
