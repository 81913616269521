/**
* SkeletonWrapper.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues K, 2022 
* @file SkeletonWrapper.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import * as React from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

/**
 * Render the Skeleton wrapper component.
 * @param properties SkeletonProps and SkeletonThemeProps interfaces
 * @returns JSX.Element
 */
const SkeletonWrapper = (properties: SkeletonProps): JSX.Element => (<Skeleton {...properties} />)

export default SkeletonWrapper