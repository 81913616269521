/**
 * VatIdAPIService.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file VatIdAPIService.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

const httpClient = new HttpClient(BASE_API_URL, createLog);

const baseURLPath: string = '/external/';

/**
 * Check if a VAT ID is valid.
 * @param vatID Vat id to check.
 * @returns Promise<IAPIEntityResponse<{ isVatIdValid: boolean }>>
 */
export const checkIsVatIdValid = (
  vatID: string
): Promise<IAPIEntityResponse<{ isVatIdValid: boolean }>> =>
  httpClient.get(`${baseURLPath}check/vatID/${vatID}/status`);
