/*
 * AnalyticsTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AnalyticsTable.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';

/**
 * Interface for AnalyticsTable properties.
 */
interface IAnalyticsTableProperties {
  data: any;
}

export const AnalyticsTable = ({ data }: IAnalyticsTableProperties) => {
  return (
    <BaseDatatable
      value={data}
      header={null}
      rows={5}
      rowsPerPageOptions={[5, 10, 20]}
      autoLayout
      responsive /**< Datatable responsive layout.*/
    >
      <Column
        field="name"
        header="Product Name"
        sortable
        body={(rowData) => (
          <DatatableColumn
            title="Product Name"
            data={
              rowData.name === '' ? (
                <span className="badge badge-danger">Deleted product</span>
              ) : (
                <a
                  href={`/${rowData?.formattedSKU}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {rowData.name}
                </a>
              )
            }
          />
        )}
      />
      <Column
        field="_id"
        header="Product ID"
        sortable
        body={(rowData) => (
          <DatatableColumn title="Product ID" data={rowData._id} />
        )}
      />
      <Column
        field="count"
        header="Views"
        sortable
        body={(rowData) => (
          <DatatableColumn title="Views" data={rowData.count} />
        )}
      />
    </BaseDatatable>
  );
};
