/**
 * Settings.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Settings.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { uploadCall } from './Api';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/EnvironmentVariables';
import { IGetSafeSettingsResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const getSettings = async () => {
  return httpClient.get(`/store`);
};

export const getSecureSettings = async () => {
  return httpClient.get(`/store/admin`);
};

export const saveSettingsCall = (settings: any) => {
  return httpClient.put(`/store`, settings);
};

export const uploadShopLogoCall = (image: any) => {
  const url = `${BASE_API_URL}/store/image`;
  return uploadCall(url, { file: image });
};

/**
 * Delete Shop logo
 */
export const deleteShopLogoCall = () => {
  return httpClient.delete(`/store/image/logo`);
};

export const uploadShopFaviconCall = (image: any) => {
  const url = `${BASE_API_URL}/store/image/favicon`;
  return uploadCall(url, { file: image });
};

export const savePdfInvoiceSummaryCall = (payload: any) => {
  return httpClient.put(`/store/pdf/summary`, payload);
};

export const deleteShopFaviconCall = () => {
  return httpClient.delete(`/store/image/favicon`);
};

/// fetch application
export const readApplicationDetails = () => {
  return httpClient.get(`/store/application/details`);
};

/// fetch application roles
export const readApplicationRoles = () => {
  return httpClient.get(`/store/application/roles`);
};

/// Get Configuration settings
export const getConfigurationSettings = (): Promise<IAPIEntityResponse<
  Record<string, any>
>> => {
  return httpClient.get('/store/settings/configuration');
};

/**
 * Test SMTP settings
 * @param body Payload to send with the request
 * @returns Promise
 */
export const testSMTPActionAPI = (
  body: Record<string, any>
): Promise<IAPIEntityResponse<string>> => {
  return httpClient.post<string>(`/store/settings/smtp/test`, body);
};

/**
 * Get environment variables
 * @returns Promise
 */
export const getApplicationEnvironmentVariablesAPI = (): Promise<IAPIEntityResponse<
  IPublicEnvironmentVariables
>> => {
  return httpClient.get<IPublicEnvironmentVariables>(
    '/store/settings/environment/information'
  );
};

/**
 * Get safe user app settings
 * @returns Promise
 */
export const getUserSettingsAPI = (): Promise<IAPIEntityResponse<
  IGetSafeSettingsResponse
>> => {
  return httpClient.get<IGetSafeSettingsResponse>('/store/userSettings');
};

/**
 * Call the Braintree service to update the customer address
 */
export const updateBraintreeCustomerAddressesAPI = () => {
  return httpClient.put(`/store/update/customers/addresses`, {});
};
