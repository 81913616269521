/**
* CustomerDetailsTable.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file CustomerDetailsTable.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

/**
 * Interface for CustomerDetailsTable properties.
 */
interface ICustomerDetailsTableProperties {
  className?: any;
  customer: {
    firstName?: string;
    lastName?: string;
    email?: string;
    address1?: string;
    city?: any;
    zipcode?: any;
    stateOrProvince?: any;
    country?: any;
    address2: string;
    [key: string]: any /**< Dynamic properties */;
  };
}

export const CustomerDetailsTable = ({
  className,
  customer
}: ICustomerDetailsTableProperties) => {
  const { t } = useTranslation();

  return (
    <table className={classnames('table', className)}>
      <tbody>
        <tr>
          <td className="billing-description-col text-left border-top-0">
            <b>{t('fields.customer.name')}:</b>
          </td>
          <td className="billing-details text-right border-top-0">
            {customer.firstName} {customer.lastName}
          </td>
        </tr>
        <tr>
          <td className="billing-description-col text-left">
            <b>{t('fields.customer.email')}: </b>
          </td>
          <td className="billing-details text-right">{customer.email}</td>
        </tr>
        {customer.address1 ? (
          <tr>
            <td className="billing-description-col text-left">
              <b>{t('fields.customer.address')}: </b>
            </td>
            <td className="billing-details text-right">
              {customer.address1}
              <br />
              {customer.city} {customer.zipcode}
              <br />
              {customer.stateOrProvince} {customer.country}
            </td>
          </tr>
        ) : (
          <></>
        )}
        {customer.address2 && (
          <tr>
            <td className="billing-description-col billing-address2-description-col text-left custom-container-address-label">
              <b>{t('fields.customer.address2')}: </b>
            </td>
            <td className="billing-details text-right">{customer.address2}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
