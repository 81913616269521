/*
 * SubscriptionsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file SubscriptionsPage.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionsByUserUUIDAction } from '../../../Store/Subscriptions/ClientSubscriptions';
import SubscriptionTable from './SubscriptionsTable';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  cancelSubscriptionAction,
  getSubscriptionPaymentMethodAction,
  refundSubscriptionAction,
  resetPaymentMethodState,
  retrySubscriptionAction,
  updateSubscriptionPaymentMethodAction
} from '../../../Store/Subscriptions/SharedSubscriptions';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const SubscriptionsPage = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.clientSubscriptions);

  const isLoading = useSelector(
    (state) => state.clientSubscriptions.isFetchingList
  );
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */

  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(
      getSubscriptionsByUserUUIDAction(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      getSubscriptionsByUserUUIDAction(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      getSubscriptionsByUserUUIDAction(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Refund a subscription and fetch subscription list again.
  const refundSubscription = (subscriptionID: string) => {
    dispatch(
      refundSubscriptionAction(subscriptionID, subscriptions.list, false)
    );
  };

  /// Cancel a subscription and fetch subscription list again.
  const cancelSubscription = async (subscriptionID: string) => {
    dispatch(
      cancelSubscriptionAction(subscriptionID, subscriptions.list, false)
    );
  };

  useEffect(() => {
    dispatch(
      getSubscriptionsByUserUUIDAction(
        tablePayload.skip,
        tablePayload.limit,
        tablePayload.searchTerm,
        tablePayload.sort
      )
    ); /**< Fetch all subscriptions. */
  }, []);

  /// Update handler for subscription payment method
  const handleSubscriptionPaymentMethodUpdate = async (
    subscriptionID: string,
    paymentMethodNonce: string
  ) => {
    await asyncErrorHandler(
      dispatch(
        updateSubscriptionPaymentMethodAction(
          subscriptionID,
          paymentMethodNonce
        )
      )
    );
    /**< To update subscription's payment method */
    await asyncErrorHandler(
      dispatch(
        getSubscriptionsByUserUUIDAction(
          tablePayload.skip,
          tablePayload.limit,
          tablePayload.searchTerm,
          tablePayload.sort
        )
      )
    ); /**< To fetch all subscriptions. */
  };
  /// To reset payment method
  const resetPaymentMethod = async () => {
    await asyncErrorHandler(dispatch(resetPaymentMethodState()));
  };

  /// To get subscription payment method
  const getSubscriptionPaymentMethod = async (subscriptionID: string) => {
    await asyncErrorHandler(
      dispatch(getSubscriptionPaymentMethodAction(subscriptionID))
    );
  };

  // Retry a subscription and fetch subscription list again.
  const retrySubscription = async (
    subscriptionID: string,
    transactionPrice?: string /* The Production environment won't pass the transaction price. */
  ) => {
    dispatch(retrySubscriptionAction(subscriptionID, transactionPrice));
  };

  return (
    <Row>
      <Col sm={12}>
        <SubscriptionTable
          refundSubscription={refundSubscription}
          cancelSubscription={cancelSubscription}
          retrySubscription={retrySubscription}
          subscriptions={subscriptions.list}
          isLoading={isLoading}
          handlePageUpdate={handlePageUpdate}
          handleSortUpdate={handleSortUpdate}
          handleFilterUpdate={handleFilterUpdate}
          tablePayload={tablePayload}
          totalRecords={subscriptions.totalRecords}
          handleSubscriptionPaymentMethodUpdate={
            handleSubscriptionPaymentMethodUpdate
          }
          resetPaymentMethod={resetPaymentMethod}
          getSubscriptionPaymentMethod={getSubscriptionPaymentMethod}
        />
      </Col>
    </Row>
  );
};

export default SubscriptionsPage;