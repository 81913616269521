/*
 * SearchBar.tsx (WebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file SearchBar.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section WebCommon
 */

import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import './SearchBar.css'

/**
 * @interface ISearchBarProperties
 */
interface ISearchBarProperties {
  onSearchTermChanged: (termToFind: string) => void /**< function to trigger backend integration */
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>> /**< search term to set onChange */
  searchBarWidth?: string /**< component width */
  lengthToTriggerFieldValidation?: number /**< length to trigger field validation */
  maximumCharacterAllowed?: number /**< maximum characters allowed */
  minimumCharacterAllowed?: number /**< minimum characters allowed */
  [x: string]: any /**< others properties */
}

/**
 * Render a search bar to be used across the project, mainly in data tables.
 * 
 * @param onSearchTermChanged Function to trigger backend integration
 * @param setSearchTerm Search term to set onChange (Optional)
 * @param searchBarWidth Component width (Optional)
 * @param lengthToTriggerFieldValidation Length to trigger field validation (Optional)
 * @param maximumCharacterAllowed Maximum character allowed (Optional)
 * @param minimumCharacterAllowed Minimum character allowed (Optional)
 * @returns JSX.Element
 */
const SearchBar = (properties: ISearchBarProperties) => {
  const t: TFunction = useTranslation().t;

  const minimumCharacterAllowed: number = properties.minimumCharacterAllowed ?? 2
  const maximumCharacterAllowed: number = properties.maximumCharacterAllowed ?? 50

  const handleOnSearch = (searchTerm: string) => {
    if (searchTerm.length > (properties.lengthToTriggerFieldValidation ? properties.lengthToTriggerFieldValidation : 0)) {
      properties.onSearchTermChanged(searchTerm)
    }
    return null
  }

  return (
    <Formik
      initialValues={{ searchTerm: '' }}
      onSubmit={async (formsData: { searchTerm: string }) => handleOnSearch(formsData.searchTerm)}
      validationSchema={Yup.object({
        searchTerm: Yup.string()
          .min(minimumCharacterAllowed, t('/validations.min', { field: minimumCharacterAllowed }))
          .max(maximumCharacterAllowed, t('/validations.max', { field: maximumCharacterAllowed }))
      })}
      {...properties}
    >
      {({ values, handleChange, handleSubmit, touched, errors, setFieldValue, setFieldError }) => (
        <Form>
          <div className='align-items-searchbar-input'>
            <span className="p-input-icon-left search-input-container" style={{ width: properties.searchBarWidth }}>
              <i className={`bi bi-search earch-bar-left-icon`} onClick={() => handleSubmit()} />

              <InputText
                name="searchTerm"
                value={values.searchTerm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e)
                  e.target.value.length === 0 && properties.onSearchTermChanged('')
                  properties.setSearchTerm && properties.setSearchTerm(e.target.value)
                }}
                placeholder={`${t('I18N.base_datatable.search_placeholder')}`}
                className={
                  touched.searchTerm && errors.searchTerm
                    ? `p-invalid custom-style-search-bar}`
                    : `custom-style-search-bar}`
                }
              />
            </span>

            {values.searchTerm.length > 0 &&
              <span className="p-input-icon-right">
                <i
                  className="bi bi-x-lg clean-icon-hover"
                  onClick={() => {
                    properties.onSearchTermChanged('')
                    setFieldValue('searchTerm', '');
                    setFieldError('searchTerm', '');
                    properties.setSearchTerm && properties.setSearchTerm('')
                  }}
                />
              </span>
            }
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SearchBar