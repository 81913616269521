/*
 * LoginWidget.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LoginWidget.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Card } from 'primereact/card';
import './LoginWidget.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ImagePreview from '../ImagePreview';
import Navbar from 'react-bootstrap/Navbar';
import { IUser, IUserProfile } from '../../Shared/interfaces/user/user';
import ProfileCard from '../ProfileCard/ProfileCard';

/**
 * @interface ILoginWidgetProperties
 */
interface ILoginWidgetProperties {
  isShowLoginForm: boolean; /**< To show login form or not */
  loginText: string; /**< Login Application text */
  registerText: string; /**< Register Application text */
  loginButtonText: string; /**< Login button text */
  manageMyAbstractIDButtonText: string; /**< Manage My Abstract ID button text */
  logoutButtonText: string; /**< Logout button text */
  registerButtonText: string; /**< Register button text */
  loggedInText: string; /**< Logged in text */
  loginHandler: () => void; /**< Login handler */
  logoutHandler: () => void; /**< Logout handler */
  registerHandler: () => void; /**< Register handler */
  applicationTitle?: string; /**< Application title */
  applicationLogoURL?: string; /**< Application logo URL */
  userInformation?: IUser | null; /**< User information to show it in Identity card */
  manageAccountURL: string; /**< URL to redirect user when onClick Manage My Account ID */
}

const LoginWidget = (properties: ILoginWidgetProperties): JSX.Element => {
  const noImageContainer: JSX.Element = (
    <legend className="application-title">{properties.applicationTitle}</legend>
  ); /**< NoImageContainer */

  /// Render logo in login card
  const getBrandImage = () => {
    return (
      <Navbar.Brand className={'p-0'}>
        <ImagePreview
          showInverted={true}
          showDelete={false}
          imageUrl={properties.applicationLogoURL}
          isLogo={true}
          noImageContainer={noImageContainer}
          altText={properties.applicationTitle}
          imgClass={'img-fixed-height'}
        />
      </Navbar.Brand>
    );
  };

  return (
    <>
      {Object.keys(properties.userInformation).length > 0 ? (
        <Card className="loginWidget">
          <Row className="card-row">
            <Col>{getBrandImage()}</Col>
          </Row>
          <Row className='loginWidget-row'>
            <Col>
              <span>{properties.loggedInText}</span>
            </Col>
            <Col>
              <div className="d-flex float-right">
                <Button
                  className={`btn-block p-button p-button-primary`}
                  variant="primary"
                  onClick={properties.logoutHandler}
                >
                  <i className="fa-solid fa-right-from-bracket loginWidget-icon"></i>
                  {properties.logoutButtonText}
                </Button>
              </div>
            </Col>
          </Row>

          <hr className="divider" />
          <Row className="loggedIn-card d-flex align-items-center">
            <Col xs={12} md={6}>
              <ProfileCard userInformation={properties.userInformation as IUserProfile} isTokenCountVisible />
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex float-right">
                <Button
                  className={`btn-block p-button p-button-primary`}
                  variant="primary"
                  onClick={() => window.open(properties.manageAccountURL, '_blank')}
                >
                  <i className="fa fa-solid fa-id-badge btn-icon loginWidget-icon"></i>
                  {properties.manageMyAbstractIDButtonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          {properties.isShowLoginForm ? (
            <Card className="loginWidget">
              <Row className="card-row">
                <Col>{getBrandImage()}</Col>
              </Row>
              <Row className="card-row loginWidget-row">
                <Col sm={12} md={5}>
                  <span>{properties.loginText}</span>
                </Col>
                <Col sm={12} md={7}>
                  <div className="d-flex float-right">
                    <Button
                      className="btn-block p-button p-button-primary"
                      onClick={properties.loginHandler}
                    >
                      {' '}
                      <i className="fa fa-solid fa-user btn-icon loginWidget-icon"></i>
                      {properties.loginButtonText}
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr className="divider" />
              <Row className="card-row">
                <Col sm={12} md={5}>
                  <span>{properties.registerText}</span>
                </Col>
                <Col sm={12} md={7}>
                  <div className="d-flex float-right">
                    <Button
                      className="btn-block p-button p-button-primary"
                      onClick={properties.registerHandler}
                    >
                      {' '}
                      <i className="fa fa-solid fa-id-card btn-icon loginWidget-icon"></i>
                      {properties.registerButtonText}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(LoginWidget);
