/**
 * StaticLinks.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file StaticLinks.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { uploadCall } from './Api';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * Fetch staticLinks
 * @return IStaticLink[]
 */
export const getPaginatedStaticLinks = async (
  payload: ITablePayload
): Promise<IAPIEntityResponse<IStaticLink[]>> => {
  const url = paginationRequest(`/staticLinks/paginated`, {
    skip: payload.skip,
    limit: payload.limit,
    searchTerm: payload.searchTerm
  });
  return httpClient.post(url, { sort: payload.sort });
};

/**
 * Fetch all staticLinks
 * @return IStaticLink[]
 */
export const getAllStaticLinks = async (): Promise<IAPIEntityResponse<
  IStaticLink[]
>> => {
  return httpClient.get(`/staticLinks/all`);
};

/**
 * Create or update staticLink.
 */
export const createOrUpdateStaticLink = async (
  staticLink: any,
  staticLinkID: string | null
) => {
  let url: string;

  if (staticLinkID) {
    url = `${BASE_API_URL}/staticLinks/${staticLinkID}/update`;
  } else {
    url = `${BASE_API_URL}/staticLinks/create`;
  }

  return uploadCall(url, {
    file: staticLink.file,
    data: staticLink
  });
};

/**
 * Delete staticLink(s).
 */
export const deleteStaticLinks = async (payload: string[]) => {
  return httpClient.post(`/staticLinks/delete`, { staticLinkIDs: payload });
};
