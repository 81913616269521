/*
 * TransactionForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file TransactionForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Dropdown} from 'primereact/dropdown';
import {addOrEditProduct, deleteProduct, fetchOneProduct, fetchProducts} from '../../../Store/Products';
import CheckoutPage from '../../Shop/Checkout/CheckoutPage';
import CustomProductForm from '../Products/CustomProductForm';
import Button from 'react-bootstrap/Button';
import {IProduct} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

/**
 * Interface for TransactionForm properties.
 */
interface ITransactionFormProperties {
  setModalOpen: any;
}

const TransactionForm = ({ setModalOpen }: ITransactionFormProperties) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const transactionIsCreating = useSelector(
    (state) => state.transactions.transactionIsCreating
  );
  const productsList: IProduct[] =
    products.list &&
    products.list.filter(
      (product) => product.live === true
    ); /**< Only Live Products */
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customProduct, setCustomProduct] = useState(null);
  const [selectingProduct, setSelectingProduct] = useState(true);
  const [creatingCustomProduct, setCreatingCustomProduct] = useState(false);

  const selectProductHandler = async (e) => {
    setSelectingProduct(true);
    setSelectedProduct(e.value);
    setSelectingProduct(false);
  };

  const resetForm = async () => {
    setSelectedProduct(null);
    if (customProduct) {
        await asyncErrorHandler(dispatch(deleteProduct([customProduct], true)));
        await asyncErrorHandler(dispatch(fetchProducts()));
    }
    setCustomProduct(null);
    setSelectingProduct(true);
    setCreatingCustomProduct(false);
  };

  const handleCustomProductForm = async (values) => {
    setSelectingProduct(true);
    const result = await asyncErrorHandler(dispatch(addOrEditProduct(values, true)));
      await asyncErrorHandler(dispatch(fetchProducts()));
    if (result.error) {
      resetForm();
    } else {
      const productID = result._id ? result._id : values._id;
        await asyncErrorHandler(dispatch(fetchOneProduct(productID)));
      setCustomProduct(productID);
      setSelectedProduct(productID);
      setSelectingProduct(false);
      setCreatingCustomProduct(false);
    }
  };

  const setEditingHandler = () => {
    setCreatingCustomProduct(true);
    setSelectedProduct(null);
    setSelectingProduct(true);
  };

  const finalCustomProductCleanUp = async () => {
    // deactivate product if custom product
    if (customProduct) {
        await asyncErrorHandler(dispatch(fetchOneProduct(customProduct)));
      const { product } = products;
      product.live = false;
        await asyncErrorHandler(dispatch(addOrEditProduct(product, true)));
        await asyncErrorHandler(dispatch(fetchProducts()));
    }
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  return (
    <div>
      <Row className="justify-content-center align-items-center flex-column dialog-container">
        {(selectedProduct || creatingCustomProduct) && (
          <Col md={12} sm={12}>
            <Button
              className="float-left"
              variant="primary"
              onClick={resetForm}
              disabled={transactionIsCreating}
            >
              Back
            </Button>
            {!creatingCustomProduct && customProduct && (
              <Button
                className="float-right"
                onClick={setEditingHandler}
                disabled={transactionIsCreating}
                variant="outline"
              >
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            )}
          </Col>
        )}
        {!creatingCustomProduct && !customProduct && (
          <Col md={6} sm={6}>
            <Dropdown
              value={selectedProduct}
              options={productsList}
              optionLabel="name"
              optionValue="_id"
              onChange={selectProductHandler}
              placeholder="Select the product"
            />
          </Col>
        )}
        <div>
          {!selectedProduct && !customProduct && !creatingCustomProduct && (
            <div className="text-center">
              <p>Or</p>
              <a
                href="#"
                onClick={() => {
                  setCreatingCustomProduct(true);
                }}
              >
                <h6>Create A Custom Product And Transaction</h6>
              </a>
            </div>
          )}
          {(creatingCustomProduct || customProduct) && (
            <CustomProductForm
              productId={customProduct}
              handleSubmit={handleCustomProductForm}
              editing={creatingCustomProduct}
            />
          )}
          {!selectingProduct && (
            <CheckoutPage
              productID={selectedProduct}
              type="manual"
              setModalOpen={setModalOpen}
              cleanUpMethod={finalCustomProductCleanUp}
            />
          )}
        </div>
      </Row>
    </div>
  );
};

export default TransactionForm;
