/*
 * index.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file index.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState, ReactElement } from "react";
import "./index.css";
import { Fieldset } from "primereact/fieldset";
import ImageContainer from "./ImageContainer";

export interface IInstaUploadInterface {
  showLegend: boolean;
  legend?: string;
  imageUrl: string | ArrayBuffer | null;
  showDelete: boolean;
  deleteHandler?: (event: any) => void;
  imgClass?: string;
  imgContainerClass?: string;
  onChange?: (files: any) => void;
  croppedImage?: any;
  noImageContainer?: any;
  noImageText?: string;
  errorHandler?: (error: string) => void;
  showUploadBtn?: boolean;
  altText: string;
  formGroupClass?: string;
  isPlainBtn?: boolean;
  plainBtnLabel?: string;
  previewSizes?: any[];
  isHorizontalLayout?: boolean,
  setToggleFullSizePreview?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFullSizePreview?: boolean;
  uploadButtonClass?: string;
  removeButtonStyle?: string
}

/**
 * Controls where the delete file button is displayed in the UI
 */
export enum RemoveButtonStyle {
  SEPARATE = 'separate', /**< Display the remove file option as a button and separate from the selected file */
  INLINE = 'inline', /**< Display the remove file option as an icon upon the selected file */
}

const INITIAL_INSTA_UPLOAD_VALUES: IInstaUploadInterface = {
  showLegend: false,
  legend: "",
  imageUrl: "",
  showDelete: true,
  imgClass: "",
  imgContainerClass: "",
  noImageContainer: "",
  noImageText: "",
  showUploadBtn: true,
  altText: "",
  formGroupClass: "",
  isPlainBtn: false,
  plainBtnLabel: "",
  isHorizontalLayout: false,
  setToggleFullSizePreview: undefined,
  toggleFullSizePreview: false,
  uploadButtonClass: "",
  removeButtonStyle: RemoveButtonStyle.INLINE
};

export enum FILE_UPLOAD_ERROR {
  NO_FILE_UPLOADED = "NO_FILE_UPLOADED",
  NOT_AN_IMAGE = "NOT_AN_IMAGE",
}

const InstaImageUpload = ({
  onChange,
  croppedImage,
  errorHandler,
  deleteHandler,
  showUploadBtn,
  imageUrl,
  legend,
  showLegend,
  formGroupClass,
  previewSizes,
  setToggleFullSizePreview,
  toggleFullSizePreview,
  isHorizontalLayout: horizontalLayout,
  uploadButtonClass,
  ...properties
}: IInstaUploadInterface = INITIAL_INSTA_UPLOAD_VALUES): ReactElement => {
  const [uploadedFile, setUploadFile] = useState<any>(null);

  const [src, setSrc] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (imageUrl) {
      setSrc(imageUrl);
    }
  }, [imageUrl, setSrc]);

  useEffect(() => {
    if(croppedImage) {
      loadPreview({files: [croppedImage]})
    } else { // If croppedImage is null, remove the file from ImageContainer
      if(src && src !== imageUrl) {
        setSrc("");
      }
    }
  }, [croppedImage])

  /// Reads file as dataurl and renders the image.
  const loadPreview = (event: any): void => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setSrc(reader.result);
      },
      false
    );
    if (event && event.files) {
      reader.readAsDataURL(event?.files[0]);
    }
  };

  /// Triggered when the file is changed, If it is not an image file, errors are triggered
  const onFileChange = (event: any): void => {
    const validImageFileExtensions: string[] = ["jpeg", "jpg", "png", "webp", "avif", "bmp", "gif"];/**< Allowed image file extensions. */
    let isValid: boolean = false; /**< Check image file extension is valid or not. */

    if ((!event || !event.files) && errorHandler) {
      errorHandler(FILE_UPLOAD_ERROR.NO_FILE_UPLOADED);
      return;
    }

    // Check an image file is valid or not based on allowed extensions.
    validImageFileExtensions.forEach(function(extension: string) {
      if (event.files[0].type.match('image/'+extension)){
        isValid = true;
      }
    })

    if (
      event.files[0] &&
      !isValid &&
      errorHandler
    ) {
      errorHandler(FILE_UPLOAD_ERROR.NOT_AN_IMAGE);
      return;
    }
    setUploadFile(event.files);
    if (!showUploadBtn) {
      if (onChange) {
        onChange(event.files);
      }
    }
    event.value = null;
  };

  /// Triggers on clicking upload button
  const onUpload = (): void => {
    if (onChange) {
      onChange(uploadedFile);
    }
  };

  /// Triggers on clicking delete button, Sets the imageLoaded to false to reload the state
  const onDelete = (event: any): void => {
    if (deleteHandler) {
      setSrc("");
      deleteHandler(event);
    }
  };

  const getImageContainer = (): JSX.Element => {
    if (showLegend) {
      return (
        <Fieldset legend={legend} className="logoAndFavouriteIconFieldset">
          <ImageContainer
            imageUrl={src}
            errorHandler={errorHandler}
            onFileChange={onFileChange}
            onUpload={onUpload}
            deleteHandler={onDelete}
            showLegend={showLegend}
            showUploadBtn={showUploadBtn}
            formGroupClass={formGroupClass}
            previewSizes={previewSizes}
            isHorizontalLayout={horizontalLayout}
            setToggleFullSizePreview={setToggleFullSizePreview}
            toggleFullSizePreview={toggleFullSizePreview}
            uploadButtonClass={uploadButtonClass}
            {...properties}
          />
        </Fieldset>
      );
    }
    return (
      <ImageContainer
        imageUrl={src}
        errorHandler={errorHandler}
        onFileChange={onFileChange}
        onUpload={onUpload}
        deleteHandler={onDelete}
        showLegend={showLegend}
        showUploadBtn={showUploadBtn}
        formGroupClass={formGroupClass}
        previewSizes={previewSizes}
        isHorizontalLayout={horizontalLayout}
        setToggleFullSizePreview={setToggleFullSizePreview}
        toggleFullSizePreview={toggleFullSizePreview}
        uploadButtonClass={uploadButtonClass}
        {...properties}
      />
    );
  };

  return getImageContainer();
};

export default InstaImageUpload;
