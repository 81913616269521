/*
 * TemplateConverter.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file TemplateConverter.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {formatTemplate} from '../../../Utils/Formatter';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

/**
 * Interface for TemplateConverter properties.
 */
interface ITemplateConverterProperties {
  template: any;
  customPlaceholderValues: any;
  setLoading?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Loading state. */;
}

const TemplateConverter = ({
  template,
  customPlaceholderValues,
  setLoading
}: ITemplateConverterProperties) => {
  const shopSettings = useSelector((state) => state.shopSettings.list);
  const [headLinerTemplate, setHeadLinerTemplate] = useState('');

  useEffect(() => {
    const getTemplate = async () => {
      const formattedTemplate = await asyncErrorHandler(formatTemplate(
          template,
          shopSettings,
          customPlaceholderValues
      ));
      setHeadLinerTemplate(formattedTemplate);
    };
    getTemplate();
  });

  if (headLinerTemplate) {
    setLoading && setLoading(false);
  }

  return <div dangerouslySetInnerHTML={{ __html: headLinerTemplate }} />;
};

export default TemplateConverter;
