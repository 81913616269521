/*
 * App.tsx (AbstractECommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file App.tsx
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {extractStringfromText, getAllTag} from '../Utils/Formatter';
import {initializeStore} from '../Store';
import {getMaster} from '../Services/Templates';
import '@abstract/abstractwebcommon-client/common.css';
import '@abstract/abstractwebcommon-client/scss/app.scss';
import '../Scss/Shop.scss';
import './global.css';
import '../Scss/braintree.scss';
import '@abstract/abstractwebcommon-client/theme.scss';
import Main from './Main';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const store = initializeStore();

interface ITemplate {
  template: string;
}

const App = () => {
  const [isRendering, setRendering] = useState<boolean>(true);

  useEffect(() => {
    setRendering(true);
    const addHeadScripts = async () => {
      const masterTemplate: ITemplate = await asyncErrorHandler(getMaster());
      if (masterTemplate) {
        // get scripts from tmeplate html head
        const head: string = await asyncErrorHandler(extractStringfromText(
            masterTemplate.template,
            '<head>',
            '</head>'
        ));
        const headScriptTags: NodeListOf<any> = await asyncErrorHandler(getAllTag(head, 'script'));
        if (headScriptTags) {
          for (let i = 0; i < headScriptTags.length; i++) {
            const script = document.createElement('script');
            script.src = headScriptTags[i].src;
            script.async = false;
            document.head.appendChild(script);
          }
        }
      }
    };
    addHeadScripts();
    setRendering(false);
  }, []);

  if (isRendering) {
    return <></>;
  }

  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
};

export default App;
