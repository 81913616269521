/*
 * UserCache.ts (AbstractECommerce)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file UserCache.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { synchronizeUserCacheData } from '../Services/UserCache';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const SYNCHRONIZE_USER_DATA_REQUEST = 'userCache/synchronize/user/data/request';
const SYNCHRONIZE_USER_DATA_SUCCESS = 'userCache/synchronize/user/data/success';
const SYNCHRONIZE_USER_DATA_FAILURE = 'userCache/synchronize/user/data/failure';

const INITIAL_STATE = {
  isRequestingEndpoint: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SYNCHRONIZE_USER_DATA_REQUEST:
      return { ...state, isRequestingEndpoint: true };
    case SYNCHRONIZE_USER_DATA_SUCCESS:
      return {
        ...state,
        isRequestingEndpoint: false
      };
    case SYNCHRONIZE_USER_DATA_FAILURE:
      return {
        ...state,
        isRequestingEndpoint: false
      };
    default:
      return state;
  }
};

const synchronizeUserDataRequest = () => ({
  type: SYNCHRONIZE_USER_DATA_REQUEST
});

const synchronizeUserDataSuccess = () => ({
  type: SYNCHRONIZE_USER_DATA_SUCCESS
});

const synchronizeUserDataFailure = () => ({
  type: SYNCHRONIZE_USER_DATA_FAILURE
});

export const synchronizeUserDataAction = () => async (dispatch) => {
  try {
    dispatch(synchronizeUserDataRequest());
    const result = await asyncErrorHandler(synchronizeUserCacheData());

    if (result.error) {
      handleError({ message: result.error.message as string });
      dispatch(synchronizeUserDataFailure());
    } else {
      dispatch(synchronizeUserDataSuccess());
      showSuccessToast(result.data?.message as string);
    }
  } catch (error) {
    dispatch(synchronizeUserDataFailure());
    handleError({
      message:
        'An error occurred while attempting to fetch users from User project to populated/updated the user cache table.'
    });
  }
};
