/*
 * index.ts (AbstractEcommerceUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file index.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { settingReducer, SETTING_FEATURE_KEY } from './Settings';
import {
  transactionReducer,
  TRANSACTIONS_FEATURE_KEY
} from './UserTransaction';
import { authReducer, AUTH_FEATURE_KEY } from './UserAuth';
import products from './Products';
import braintree from './Braintree';
import adminSubscriptions from './Subscriptions/AdminSubscriptions';
import clientSubscriptions from './Subscriptions/ClientSubscriptions';
import sharedSubscriptions from './Subscriptions/SharedSubscriptions';
import customers from './Customers';
import users from './Users';
import transactions from './Transactions';
import price from './Price';
import discounts from './Discounts';
import shopSettings from './ShopSettings';
import logs from './Logs';
import analytics from './Analytics';
import templates from './Templates';
import vatIdAPI from './VatIdAPI';
import addressAPIValidation from './AddressValidation';
import countries from './Vat';
import { staticLinkReducer, STATICLINK_FEATURE_KEY } from './StaticLinks';
import {
  termsAndCondionReducer,
  TERMSANDCONDITION_FEATURE_KEY
} from './TermsAndCondition';
import {
  dashboardReducer,
  dashboardFeatureKey
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import {
  userAPIFeatureKey,
  userAPIReducer
} from '@abstract/abstractwebcommon-client/store/UserAPISlice';
import applications from './Applications';
import userCache from './UserCache';

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [SETTING_FEATURE_KEY]: settingReducer,
  [TRANSACTIONS_FEATURE_KEY]: transactionReducer,
  [STATICLINK_FEATURE_KEY]: staticLinkReducer,
  [TERMSANDCONDITION_FEATURE_KEY]: termsAndCondionReducer,
  products,
  customers,
  transactions,
  users,
  price,
  discounts,
  shopSettings,
  logs,
  analytics,
  templates,
  countries,
  [dashboardFeatureKey]: dashboardReducer,
  [userAPIFeatureKey]: userAPIReducer,
  braintree,
  adminSubscriptions,
  clientSubscriptions,
  sharedSubscriptions,
  applications,
  vatIdAPI,
  addressAPIValidation,
  userCache
});

export const initializeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production'
  });
  return store;
};

export type IRootState = ReturnType<typeof rootReducer>;
