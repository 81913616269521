/**
* index.tsx (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022

* @file index.tsx
* @author Alaguvelammal Alagusubbiah
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React from 'react';
import FeedCard from './FeedCard';
import SoftwareVersion, { ISoftwareVersionProperties } from './SoftwareVersion';
import WarningsBanner from './WarningsBanner';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { useTranslation } from 'react-i18next';
import { TFunction } from "i18next";
import { IMappedFeed } from '@abstract/abstractwebcommon-shared/interfaces/feed';

/**
 * @interface IDashboardProperties
 */
interface IDashboardProperties {
  dashboardTitle: string; /**< Dashboard Title. */
  systemWarnings: Record<string, string>[] /**< System Warnings. */
  softwareVersion: ISoftwareVersionProperties; /**< Software Version data. */
  feedsData: IMappedFeed; /**< Feed data. */
  isLoading: boolean; /**< Loading state. */
  themeMode: string; /**< Theme mode. */
  systemData: any /**< System data. */
  systemDataNotes: string; /**< System data notes. */
}

const Dashboard = (properties: IDashboardProperties): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const softwareVersionData: ISoftwareVersionProperties = properties.softwareVersion; /**< Software version data. */

  /**< Basic options for System Data Chart */
  const basicOptions = {
    animation: {
      duration: properties.isLoading ? 3000 : 0
    },
    legend: {
      labels: {
        fontColor: properties.themeMode === ThemeMode.lightMode ? '#495057' : '#fff'
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: properties.themeMode === ThemeMode.lightMode ? '#495057' : '#fff'
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: properties.themeMode === ThemeMode.lightMode ? '#495057' : '#fff',
            beginAtZero: true,
            precision: 0 // dont show decimal point
          }
        }
      ]
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <h3 className="text-left font-weight-bold">
            {properties.dashboardTitle}
          </h3>
        </Col>

        <WarningsBanner warningsData={properties.systemWarnings} />
        
        <Col xs={12}>
          <SoftwareVersion
            frontendCommitLink={softwareVersionData.frontendCommitLink}
            frontendCurrentVersion={softwareVersionData.frontendCurrentVersion}
            backendCommitLink={softwareVersionData.backendCommitLink}
            backendCurrentVersion={softwareVersionData.backendCurrentVersion}
            awcCommitLink={softwareVersionData.awcCommitLink}
            awcCurrentVersion={softwareVersionData.awcCurrentVersion}
          />
        </Col>
        <Col xs={12} lg={6}>
          <FeedCard feedsData={properties.feedsData} isLoading={properties.isLoading} />
        </Col>
        <Col xs={12} lg={6}>
          <Card title={translation("awc:/.dashboard.dashboard_fieldset.systemData")}>
            <p>
              <strong>{translation("awc:/.dashboard.dashboard_fieldset.notes")}: </strong>
              {properties.systemDataNotes}
            </p>
            {<Chart type="bar" data={properties.systemData} options={basicOptions} />}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;