/*
 * index.tsx (AbstractUser)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file index.tsx
 * @author Sai Charan K
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import { Badge, Button } from "react-bootstrap";
import "./index.css";
import { Dialog } from "primereact/dialog";

import { RemoveButtonStyle } from "../InstaImageUpload";

export interface IImagePreviewProperties {
  isImageLoaded?: boolean;
  showInverted?: boolean;
  imageUrl?: string | ArrayBuffer | null;
  imgClass?: string;
  showDelete?: boolean;
  deleteHandler?: (event: any) => void;
  noImageText?: string;
  noImageContainer?: any;
  isLogo?: boolean;
  altText: string;
  setToggleFullSizePreview?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFullSizePreview?: boolean;
  removeButtonStyle?: string /**< Accepts 'separate' or 'inline' as value. Controls where the delete file button is displayed in the UI */
  setLogoFullLoaded?: React.Dispatch<React.SetStateAction<boolean>> /**< state to control when logo is fully loaded */
}

const ImagePreview = ({
  deleteHandler,
  showDelete,
  noImageText,
  showInverted,
  imageUrl,
  isLogo,
  imgClass,
  altText,
  noImageContainer,
  setToggleFullSizePreview,
  toggleFullSizePreview = false,
  removeButtonStyle = RemoveButtonStyle.INLINE,
  setLogoFullLoaded,
}: IImagePreviewProperties): ReactElement => {
  /// Show clear button only if showDelete is present in properties.
  const getClearButton = () => {
    if (showDelete && removeButtonStyle === RemoveButtonStyle.INLINE) {
      return (
        <Button
          className="removeImageBtn"
          variant={"link"}
          onClick={deleteHandler}
        >
          <i className="fas fa-times" />
        </Button>
      );
    }
    return <></>;
  };

  /// This is rendered if no image is present
  const getNoImageContainer = () => {
    return (
      <Badge variant="secondary" className="noImageBadge">
        {noImageText}
      </Badge>
    );
  };

  /// Returns the image with delete button or noImageContainer if applicable
  const getImage = () => {
    if (imageUrl) {
      return (
        <>
          <img
            onLoad={() => setLogoFullLoaded && setLogoFullLoaded(true)}
            onError={() => setLogoFullLoaded && setLogoFullLoaded(true)}
            className={`${!isLogo ? "bg-checkerboard" : ""} ${imgClass || ""
              } ${showInverted ? "inverted" : ""}`}
            src={imageUrl as string}
            alt={altText}
            onClick={() => setToggleFullSizePreview && setToggleFullSizePreview(true)}
          />
          {getClearButton()}
          <Dialog
            focusOnShow={false}
            draggable={false}
            visible={toggleFullSizePreview}
            onHide={() => {
              setToggleFullSizePreview && setToggleFullSizePreview(false);
            }}
          >
            <img src={imageUrl as string} alt={altText} className="fullSizePreview image" />
          </Dialog>
        </>
      );
    } else {
      return noImageContainer ? noImageContainer : getNoImageContainer();
    }
  };

  return getImage();
};

export default ImagePreview;
