/*
 * ConfirmationDialog.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ConfirmationDialog.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { useState } from 'react';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './ConfirmationDialog.css';

/**
 * @enum IConfirmationDialogMode
 */
export enum ConfirmationDialogMode {
  Delete = "delete" /**< Defines if the modal is used for delete an item */,
  Warning = "warning" /**< Defines if the modal is used for a warning scope */,
  None = "none" /**< Defines when the modal has no mode */
}

/**
 * Defines the interface for the setModalType function.
 * @interface ISetModalType
 */
interface ISetModalType {
  modalTypeClassName: string /**< Defines the CSS class name to use in the modal */;
  modalTypeRole: string /**< Defines the CSS role to use in the modal */;
}

/**
 * @interface IConfirmationDialog
 */
interface IConfirmationDialog {
  dialogTitle: string /**< Title of the dialog */;
  dialogText?: string /**< Paragraph text of dialog */;
  alertMessage?: string | JSX.Element /**< Defines the alert message to display in the modal */;
  isShowConfirmationModal: boolean /**< To show confirmation modal */;
  onReject: () => void /**< Handle reject */;
  onAccept: (() => void) | ((event: any) => void) /**< Handle accept */;
  children?: JSX.Element | React.ReactNode /** Children elements */;
  cancelButtonText?: string /** Defines the name of the button that cancel the action */
  confirmButtonText?: string /** Defines the name of the button that confirm the action */
  additionalClassNames?: string /** Defines any additional class names */
  modalMode?: ConfirmationDialogMode /** Defines the mode of the modal */
}

const ConfirmationDialog = (properties: IConfirmationDialog): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const [confirmText, setConfirmText] = useState<string>('');
  
  const inputText: string  = 'CONFIRM';
  const cancelButtonText: string  = properties.cancelButtonText ?? translation('awc:/.confirmation_dialog.no');
  const confirmButtonText: string  = properties.confirmButtonText ?? translation('awc:/.confirmation_dialog.yes');
  const modalMode: string  = properties.modalMode ?? ConfirmationDialogMode.None;

  // Hide confirmation on reject
  const handleReject = (): void => {
    setConfirmText('');
    properties.onReject();
  }

  const DeleteModalModeIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="confirmation-dialog-icon-message" role="img" aria-label="trash-bin-outline" viewBox="0 0 20 20">   
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"></path>  
    </svg>
  )

  const WarningModalModeIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" className="confirmation-dialog-icon-message" role="img">
      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8.2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0-64 0 32 32 0 1 0 64 0z"/>
    </svg>
  )

  // NOTE: Defines the modal type. We should improve the if/else condition in case we need more modals in the future.
  const setModalType = (): ISetModalType => {
    if (modalMode === ConfirmationDialogMode.Warning) {
      return {
        modalTypeClassName: 'confirmation-modal-warning-message',
        modalTypeRole: 'warning'
      }
    } else {
      return {
        modalTypeClassName: 'alert-message',
        modalTypeRole: 'alert'
      }
    }
  }

  const renderTerminateDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => handleReject()}
          variant="secondary"
          className="d-flex align-items-center"
        >
          <i className="bi bi-x-circle btn-icon"></i>
          {cancelButtonText}
        </Button>
        <Button
          onClick={(event: any) => {
            setConfirmText('');
            properties.onAccept(event);
          }}
          variant="danger"
          disabled={
            confirmText != inputText
          }
          className="d-flex align-items-center m-0"
        >
          <i className="bi bi-check2-circle btn-icon"></i>
          {confirmButtonText}
        </Button>
      </div>
    );
  };

  return (
    <DialogWrapper
      isDialogVisible={properties.isShowConfirmationModal}
      onHide={() => handleReject()}
      headerTitle={properties.dialogTitle}
      footer={renderTerminateDialogFooter()}
      className={`overflow-auto custom-dialog-container ${properties.additionalClassNames || ''}`}
    >
      <div className="d-flex justify-content-center">
        {modalMode === ConfirmationDialogMode.None && <></>}
        {modalMode === ConfirmationDialogMode.Delete && <DeleteModalModeIcon />}
        {modalMode === ConfirmationDialogMode.Warning && <WarningModalModeIcon />}
      </div>
      <Col className="mb-2 px-0">{properties.dialogText ?? ''}</Col>
      {properties.children ?? <></>}
      <Formik
        initialValues={{ confirmText: '' }}
        onSubmit={async (formsData: { confirmText: string }) => setConfirmText(formsData.confirmText)}
        validationSchema={Yup.object({
          confirmText: Yup.string().matches(
            inputText,
            translation('awc:/.confirmation_dialog.confirm_validation')
          )
        })}
      >
        {({ values, handleChange, handleBlur, touched, errors }) => (
          <>
          <div className={`${setModalType().modalTypeClassName} mb-4 p-3 mt-4`} role={setModalType().modalTypeRole}>
            {properties.alertMessage ?? translation('awc:/.confirmation_dialog.alert_message')}
          </div>
            <InputText
              name="confirmText"
              value={values.confirmText}
              onBlur={handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
                setConfirmText(event.target.value);
              }}
              placeholder={translation('awc:/.confirmation_dialog.confirmText')}
              className={
                touched.confirmText && errors.confirmText ? 'p-invalid' : ''
              }
            />
            {touched.confirmText && errors.confirmText ? (
              <small id="name-invalid" className="p-invalid">
                {errors.confirmText}
              </small>
            ) : null}
          </>
        )}
      </Formik>
    </DialogWrapper>
  );
};

export default ConfirmationDialog;
