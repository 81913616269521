/*
 * Discounts.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Discounts.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {check, createOrUpdate, getAll, remove} from '../Services/Discounts';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {showSuccessToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_DISCOUNTS_REQUEST = 'discounts/fetchList/request';
const GET_DISCOUNTS_SUCCESS = 'discounts/fetchList/success';
const GET_DISCOUNTS_FAILURE = 'discounts/fetchList/failure';

const EDIT_DISCOUNT_REQUEST = 'discounts/edit/request';
const EDIT_DISCOUNT_SUCCESS = 'discounts/edit/success';
const EDIT_DISCOUNT_FAILURE = 'discounts/edit/failure';

const DELETE_DISCOUNT_REQUEST = 'discounts/delete/request';
const DELETE_DISCOUNT_SUCCESS = 'discounts/delete/success';
const DELETE_DISCOUNT_FAILURE = 'discounts/delete/failure';

const CHECK_DISCOUNT_REQUEST = 'discounts/check/request';
const CHECK_DISCOUNT_SUCCESS = 'discounts/check/success';
const CHECK_DISCOUNT_FAILURE = 'discounts/check/failure';

const TOGGLE_DISCOUNT_DIALOG = 'discounts/dialog/toggle';
const RESET_STATE = 'discounts/reset'; /**< Reset Discount state. */

const INITIAL_STATE = {
  list: null,
  listIsFetching: true,
  discountDialogOpened: false,
  editedDiscount: null,
  checkedDiscount: null,
  checkedDiscountError: false,
  sort: {
    sortField: 'discounts.createdAt',
    sortOrder: -1
  },
  totalRecords: 0 /**< Total Records */
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DISCOUNTS_REQUEST:
      return { ...state, listIsFetching: true };
    case GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        list: action.payload.discounts,
        totalRecords: action.payload.totalRecords
      };
    case GET_DISCOUNTS_FAILURE:
      return { ...state, listIsFetching: false };
    case EDIT_DISCOUNT_REQUEST:
      return { ...state, discountIsChanging: true };
    case EDIT_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountIsChanging: false,
        discountDialogOpened: false
      };
    case EDIT_DISCOUNT_FAILURE:
      return { ...state, discountIsChanging: false };
    case CHECK_DISCOUNT_REQUEST:
      return { ...state, listIsFetching: true, checkedDiscountError: false };
    case CHECK_DISCOUNT_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        checkedDiscount: action.payload,
        checkedDiscountError: false
      };
    case CHECK_DISCOUNT_FAILURE:
      return {
        ...state,
        listIsFetching: false,
        checkedDiscount: null,
        checkedDiscountError: true
      };
    case DELETE_DISCOUNT_REQUEST:
      return { ...state, discountIsChanging: true };
    case DELETE_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountIsChanging: false,
        discountDialogOpened: false
      };
    case DELETE_DISCOUNT_FAILURE:
      return { ...state, discountIsChanging: false };
    case TOGGLE_DISCOUNT_DIALOG:
      return {
        ...state,
        discountDialogOpened: action.payload.opened,
        editedDiscount: action.payload.discountId
      };
    case RESET_STATE:
      return { ...state, checkedDiscount: null };
    default:
      return state;
  }
};

const getDiscountsRequest = () => ({
  type: GET_DISCOUNTS_REQUEST
});

const getDiscountsSuccess = (discounts, totalRecords) => ({
  type: GET_DISCOUNTS_SUCCESS,
  payload: { discounts, totalRecords }
});

const getDiscountsFailure = () => ({
  type: GET_DISCOUNTS_FAILURE
});

const editDiscountRequest = () => ({
  type: EDIT_DISCOUNT_REQUEST
});

const editDiscountsuccess = () => ({
  type: EDIT_DISCOUNT_SUCCESS
});

const editDiscountFailure = () => ({
  type: EDIT_DISCOUNT_FAILURE
});

const checkedDiscountRequest = () => ({
  type: CHECK_DISCOUNT_REQUEST
});

const checkedDiscountsuccess = (discount) => ({
  type: CHECK_DISCOUNT_SUCCESS,
  payload: discount
});

const checkedDiscountFailure = () => ({
  type: CHECK_DISCOUNT_FAILURE
});

const deleteDiscountRequest = () => ({
  type: DELETE_DISCOUNT_REQUEST
});

const deleteDiscountsuccess = () => ({
  type: DELETE_DISCOUNT_SUCCESS
});

const deleteDiscountFailure = () => ({
  type: DELETE_DISCOUNT_FAILURE
});

export const toggleDiscountDialog = (opened, discountId) => ({
  type: TOGGLE_DISCOUNT_DIALOG,
  payload: {
    opened,
    discountId
  }
});

/// Reset state
export const resetState = () => ({
  type: RESET_STATE
});

export const getAllDiscounts = (
  skip?: number,
  limit?: number,
  searchTerm?: string,
  sort?: any
) => async (dispatch) => {
  try {
    dispatch(getDiscountsRequest());
    const response = await asyncErrorHandler(getAll(skip, limit, searchTerm, sort));
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getDiscountsFailure());
    } else {
      dispatch(getDiscountsSuccess(response.discounts, response.totalRecords));
    }
  } catch (e) {
    dispatch(getDiscountsFailure());
    handleError({ message: e.message });
  }
};

export const createOrUpdateDiscount = (discount) => async (dispatch) => {
  try {
    dispatch(editDiscountRequest());

    if (!discount.discountProductId) {
      discount.discountProductId = '';
    }

    if (!discount.percentage) {
      discount.percentage = 0;
    }

    if (!discount.amount) {
      discount.amount = 0;
    }

    const result = await asyncErrorHandler(createOrUpdate(discount));
    if (result.error) {
      dispatch(editDiscountFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(editDiscountsuccess());
      showSuccessToast(result.message ?? '');
    }
  } catch (e) {
    dispatch(editDiscountFailure());
    handleError({ message: e.message });
  }
};

export const deleteDiscount = (discountIDs: string[]) => async (dispatch) => {
  try {
    dispatch(deleteDiscountRequest());
    const result = await asyncErrorHandler(remove(discountIDs));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(deleteDiscountFailure());
    } else {
      dispatch(deleteDiscountsuccess());
      showSuccessToast(result.message ?? '');
    }
  } catch (e) {
    dispatch(deleteDiscountFailure());
    handleError({ message: e.message });
  }
};

export const checkDiscount = (discountCode) => async (dispatch) => {
  try {
    dispatch(checkedDiscountRequest());
    const result = await asyncErrorHandler(check(discountCode));
    if (result.error) {
      dispatch(checkedDiscountFailure(result.error.message));
    } else {
      dispatch(checkedDiscountsuccess(result));
    }
  } catch (e) {
    dispatch(checkedDiscountFailure(e.message));
  }
};
