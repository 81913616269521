/**
* index.tsx (AWC) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2021 
* @file index.tsx
* @author Timothy Fadayini
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { ReactElement } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { InputText } from "primereact/inputtext";
import Loader from "../Loader";

interface IEnvironmentVariablePopup {
  environmentVariables?: any;
  onClose: any;
}

const EnvironmentVariablePopup = ({
  environmentVariables,
  onClose,
}: IEnvironmentVariablePopup): ReactElement => {
  if (!environmentVariables) {
    return <Loader />;
  }
  return (
    <>
      <Col xs="12" className="p-0">
        <Row>
          {Object.keys(environmentVariables).map((key) => {
            return (
              <Col xs={12}>
                <label htmlFor={key}>{key}</label>
                <InputText value={environmentVariables[key]} disabled />
              </Col>
            );
          })}
        </Row>
      </Col>
    </>
  );
};

export default EnvironmentVariablePopup;
