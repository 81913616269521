/*
 * withErrorBoundary.tsx (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file withErrorBoundary.tsx
 * @author James Ugbanu
 * @copyright 20201InstaLOD GmbH. All rights reserved.
 * @section Ecommerce
 */

import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { CreateErrorLog } from '../../Shared/utils/CreateErrorLog';


const AppContainer = ({ children, ...props }: any) => (
    <Container id="app" fluid className="p-0 m-0 h-100" {...props}>
      {children}
    </Container>
  );

const withErrorBoundary = <P extends any>(
  ComponentToBeRendered: React.ComponentType<P>,
  onCreateErrorLog?: (payload: CreateErrorLog) => void, /**< Create Log for React Exception/Error. */
  isStackVisible = true, /**< Error stack visibility. */
  errorTemplate?: any
): any => {
  class ErrorBoundary extends Component<P, { isError: boolean, error: any, errorInfo: any }> {
    constructor(props: any) {
      super(props);
      this.state = { isError: false, error: null, errorInfo: null };
    }

    // Catch errors in any components below and re-render with error message.
    componentDidCatch(error, errorInfo) {
      const logPayload: CreateErrorLog = {
        error: `${error && error.toString()} ${errorInfo && errorInfo.componentStack.toString()} at ${window.location}`,
        errorMessage: error && error.toString()
      }; /**< Log Payload */
      onCreateErrorLog && onCreateErrorLog(logPayload); /**< Log the React error. */
      
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    }

    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { isError: true };
    }

    render() {
      if (this.state.errorInfo) {
        const errorComponent = errorTemplate ? (
          errorTemplate
        ) : (
          <Row className="error-page d-flex justify-content-center align-items-center mt-5 flex-column">
            <h2>Application Error</h2>
            <p>We're terribly sorry for this.</p>
            <p>
              Please visit <a href="https://instalod.com/">instalod.com</a> for more information.
            </p>
            <h6>An error has occurred in this component.</h6>
            <p>{this.state.error && this.state.error.toString()}</p>
            { isStackVisible && (
              <details style={{ whiteSpace: 'pre-wrap' }}>
                <summary>Click for error details</summary>
                {this.state.errorInfo && this.state.errorInfo.componentStack.toString()}
              </details>
            )}
          </Row>
        );
        if (this.state.isError && this.state.errorInfo) {
          return <AppContainer className="">{errorComponent}</AppContainer>;
        }
      }
      return <ComponentToBeRendered {...(this.props)} />;
    }
  }

  return ErrorBoundary;
};

export default withErrorBoundary;

