/**
* ProductFormOptionsGlobal.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file ProductFormOptionsGlobal.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import ProductFormOptions from './ProductFormOptions';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import i18n from '../../../Services/I18n';
import { IOption } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';

/**
 * Interface for ProductFormOptionsGlobal properties.
 */
interface IProductFormOptionsGlobalProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  loading: boolean;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  options: IOption[];
  translationData: IStoreTranslation[] /**< Store translation data */;
}

const ProductFormOptionsGlobal = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  handleBlur,
  touched,
  setFieldValue,
  options,
  translationData
}: IProductFormOptionsGlobalProperties) => {
  const translation = useTranslation().t;
  const [isConfirmationPopupVisible, setConfirmationPopupVisible] = useState(
    false
  ); /**< Confirmation popup visibility. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState(
    null
  ); /**< Confirmation popup target. */
  const [isUpdateExists, setUpdateExists] = useState(
    false
  ); /**< To check update exists or not. */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    values?.translationData?.findIndex(
      (data: IStoreTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = values?.translationData?.findIndex(
    (data: IStoreTranslation) => data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */

  /// Handle save productOptions event
  const onSave = (event) => {
    setConfirmationPopupVisible(true);
    setConfirmationPopupTarget(event.target);
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);

    /// Check for update exists
    const currentUpdate: any = event.target;
    const updatedOptions: IOption[] = values.options.map((option: IOption) => ({
      ...option
    }));
    const splittedID: string[] = currentUpdate.id.split('.');
    let updatedValue: any = currentUpdate.value;
    if (splittedID.length) {
      const index: number = parseInt(splittedID[1]); /**< Option index */
      const propertyName: string = splittedID[2]; /**< Option property name */

      if (propertyName === 'amount' || propertyName === 'percentage') {
        if (!isStringEmptyOrNullOrUndefined(updatedValue)) {
          updatedValue = parseInt(updatedValue);
        } else {
          updatedValue = 0;
        }
      }
      updatedOptions[index][
        propertyName as keyof Partial<IOption>
      ] = updatedValue;
    }
    checkIfOptionsUpdateExists(updatedOptions);
  };

  /// To check if any update exists in options or not.
  const checkIfOptionsUpdateExists = (updatedOptions: IOption[]) => {
    let isOptionsUpdated: boolean = false;
    if (updatedOptions.length == options.length) {
      const newOptionsString: string = JSON.stringify(updatedOptions.sort());
      const initialOptionsString: string = JSON.stringify(options.sort());
      if (newOptionsString != initialOptionsString) {
        isOptionsUpdated = true;
      }
    } else {
      isOptionsUpdated = true;
    }
    setUpdateExists(isOptionsUpdated);
  };

  /// Handler for option array change
  const onOptionArrayChange = (data: {
    index?: number;
    newOption?: IOption;
  }) => {
    const updatedOptions: IOption[] = [...values.options];
    if (data.newOption) {
      updatedOptions.push(data.newOption);
    } else if (data.index) {
      updatedOptions.splice(data.index, 1);
    }
    checkIfOptionsUpdateExists(updatedOptions);
  };

  /// To check if any update exists in translation or not
  const checkIfTranslationUpdateExists = (
    key: string,
    translationIndex: number,
    optionsIndex: number,
    updatedValue: string
  ) => {
    let isTranslationsUpdated: boolean = false;
    if (translationData && translationData.length) {
      const data: IStoreTranslation = translationData[translationIndex];
      if (data) {
        if (data[key] && data[key].length) {
          if (data[key]?.[optionsIndex] !== updatedValue) {
            isTranslationsUpdated = true;
          }
        } else {
          isTranslationsUpdated = true;
        }
      } else {
        isTranslationsUpdated = true;
      }
    } else {
      isTranslationsUpdated = true;
    }
    setUpdateExists(isTranslationsUpdated);
  };

  useEffect(() => {
    setSelectedLanguageTranslationIndex(
      values?.translationData?.findIndex(
        (data: IStoreTranslation) => data.language === selectedLanguage
      )
    );
  }, [selectedLanguage, values?.translationData]);

  return (
    <Fieldset legend={translation('fields.product.global_options_header')}>
      <Form>
        <Row>
          <Col className="p-0">
            <Form.Group as={Col} sm="12" md="6">
              <LanguageSelector
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <ProductFormOptions
              values={values.options}
              errors={errors.options}
              handleChange={onChange}
              loading={loading}
              handleBlur={handleBlur}
              touched={touched.options}
              setFieldValue={setFieldValue}
              header=""
              isGlobalProductOptions
              onOptionArrayChange={onOptionArrayChange}
              selectedLanguage={selectedLanguage}
              translationData={values?.translationData}
              translationErrors={errors?.translationData}
              translationTouched={touched?.translationData}
              checkIfTranslationUpdateExists={checkIfTranslationUpdateExists}
              selectedLanguageTranslationIndex={
                selectedLanguageTranslationIndex
              }
              englishTranslationIndex={englishTranslationIndex}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={4} className="p-0">
            <Button
              className="p-button-raised p-button-primary btn-block mt-2"
              onClick={(event) => onSave(event)}
              disabled={loading || !isUpdateExists}
            >
              {loading
                ? translation('product.options.saving')
                : translation('product.options.save')}
            </Button>
          </Col>
        </Row>
        <ConfirmationPopup
          target={confirmationPopupTarget}
          isShow={isConfirmationPopupVisible}
          title={translation('confirm_messages.save_record')}
          onAccept={async () => {
            handleSubmit(values);
            setConfirmationPopupVisible(false);
            setUpdateExists(false);
          }}
          onReject={() => {
            setConfirmationPopupVisible(false);
          }}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={translation('confirm_messages.no')}
          acceptLabel={translation('confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
        />
      </Form>
    </Fieldset>
  );
};

Yup.addMethod(Yup.array, 'unique', function(message, mapper) {
  return this.test(
    'unique',
    message,
    (list) => list.length === new Set(list.map(mapper)).size
  );
});

export default withFormik({
  mapPropsToValues: ({ options, translationData }) => {
    // Format inital store translation
    const initialTranslationData: IStoreTranslation[] = [];
    for (let index = 0; index < Languages.length; index++) {
      const language: string = Languages[index].value;
      initialTranslationData.push({
        language: language,
        productOptionsDescription: [],
        productOptionsInformation: []
      });
    }

    if (!options) {
      return {
        options: [],
        translationData: initialTranslationData
      };
    }

    // Note: Get the store translation if it already exists and corresponds to the same language array. Otherwise, add/delete a language object or use initialTranslationData.
    let storeTranslationData: IStoreTranslation[] = [];
    if (translationData && translationData.length) {
      initialTranslationData.forEach((initialData: IStoreTranslation) => {
        translationData.forEach((existingData: IStoreTranslation) => {
          if (initialData.language === existingData.language) {
            storeTranslationData.push(existingData);
          }
        });
      });
      if (storeTranslationData.length !== initialTranslationData.length) {
        const newLanguageData: IStoreTranslation[] = initialTranslationData.filter(
          (initialData: IStoreTranslation) =>
            !storeTranslationData.some(
              (existingData: IStoreTranslation) =>
                initialData.language === existingData.language
            )
        );
        storeTranslationData = storeTranslationData.concat(newLanguageData);
      }
    } else {
      storeTranslationData = initialTranslationData;
    }
    return {
      options: options,
      translationData: storeTranslationData
    };
  },
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: (props) =>
    Yup.object({
      options: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string()
              .min(3, 'Must be 3 characters or more')
              .required(
                i18n.t('validation.required', {
                  field: i18n.t('fields.productOptions.name')
                })
              ),
            amount: Yup.number(),
            percentage: Yup.number(),
            per_product_qty: Yup.boolean()
          })
        )
        .unique(
          { label: 'validation.unique', field: 'Name' },
          (option) => option.name
        ),
      translationData: Yup.array().of(
        Yup.object().shape({
          productOptionsDescription: Yup.array().when('language', {
            is: LanguageSettingsMode.english,
            then: Yup.array().of(
              Yup.string()
                .min(3, 'Must be 3 characters or more')
                .required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.productOptions.description')
                  })
                )
            ),
            otherwise: Yup.array().of(
              Yup.string()
                .nullable()
                .min(3, 'Must be 3 characters or more')
            )
          }),
          productOptionsInformation: Yup.array().when('language', {
            is: LanguageSettingsMode.english,
            then: Yup.array().of(
              Yup.string()
                .min(3, 'Must be 3 characters or more')
                .required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.productOptions.information')
                  })
                )
            ),
            otherwise: Yup.array().of(
              Yup.string()
                .nullable()
                .min(3, 'Must be 3 characters or more')
            )
          })
        })
      )
    }),
  handleSubmit: (values, bag) => {
    if (bag.props.options) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (key == 'options') {
          if (values[key].length == bag.props[key].length) {
            const newOptionsString: string = JSON.stringify(values[key].sort());
            const initialOptionsString: string = JSON.stringify(
              bag.props[key].sort()
            );
            if (newOptionsString != initialOptionsString) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'translationData') {
          if (values[key].length == bag.props[key].length) {
            const newTranslationsString: string = JSON.stringify(
              values[key].sort()
            );
            const initialTranslationsString: string = JSON.stringify(
              bag.props[key].sort()
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          } else {
            const newTranslationsString: string = JSON.stringify(
              values[key].sort()
            );

            // Format inital store translation
            const initialTranslationData: IStoreTranslation[] = [];
            for (let index = 0; index < Languages.length; index++) {
              const language: string = Languages[index].value;
              initialTranslationData.push({
                language: language,
                productOptionsDescription: [],
                productOptionsInformation: []
              });
            }
            const initialTranslationsString: string = JSON.stringify(
              initialTranslationData
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          }
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit({ ...values });
      }
      return;
    }
    return bag.props.handleSubmit({ ...values });
  }
})(ProductFormOptionsGlobal);
