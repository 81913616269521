/*
 * TemplateComponent.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file TemplateComponent.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, ReactElement } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import TemplatePreview from "./TemplatePreview";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/css/css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import PlaceholderComponent from "./PlaceholderComponent";
import { useTranslation } from "react-i18next";
import "./index.css";
import { IPlaceholder } from "@abstract/abstractwebcommon-shared/interfaces/user/placeholder";
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import SkeletonWrapper from "../SkeletonLoader/SkeletonWrapper";
import { Splitter, SplitterPanel } from 'primereact/splitter';

/**
 * ITemplateProperties interface.
 */
interface ITemplateProperties {
  templates: any[];
  templateTypePropertyName?: string;
  placeholderValuePropertyName?: string;
  masterBodyPlaceholder?: string;
  masterTemplate: string;
  submitHandler: (updatedTemplate: any) => void;
  submitClasses?: string;
  changeLayoutClasses?: string;
  isLoading?: boolean;
  codeMirrorOptions: any;
  editorClasses?: string;
  applicationCSS?: any;
  customCSSLink: string;
  copyPlaceholderHandler: (item: any) => void;
  isTemplateNameReadonly?: boolean; /**< Template Name Readonly. */
}

/**
 * ITemplateFields interface.
 */
interface ITemplateFields {
  name: string; /**< Template name */
  type: string; /**< Template type */
  html: string; /**< Template body (html). */
  subject: string; /**< Template subject. */
  placeholder: IPlaceholder[]; /**< Template placeholders. */
  isEmail: boolean; /**< Is template an email. */
}

/**
 * Template Editor component.
 * @name TemplateComponent
 */
const TemplateComponent = ({
  templates,
  templateTypePropertyName = "type",
  masterBodyPlaceholder = "{{message}}",
  placeholderValuePropertyName = "placeholderValue",
  masterTemplate,
  submitHandler,
  submitClasses,
  changeLayoutClasses,
  isLoading = false,
  codeMirrorOptions,
  editorClasses,
  copyPlaceholderHandler,
  applicationCSS = "",
  customCSSLink = "",
  isTemplateNameReadonly = false
}: ITemplateProperties): ReactElement => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [html, setHtml] = useState<any>("");
  const [isLayoutChanged, setIsLayoutChanged] = useState<boolean>(true);
  const [templateType, setTemplateType] = useState<any>(null);
  const [template, setTemplate] = useState<any>();
  const [placeholders, setPlaceholders] = useState<any>();
  const [templateIsEmail, setTemplateIsEmail] = useState<any>();

  const [initialTemplateFields, setInitialTemplateFields] = useState<ITemplateFields>();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: template?.name || '',
      subject: template?.subject || '',
      html: template?.html
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, t('/validations.min', { field: '2' }))
        .max(255, t('/validations.max', { field: '255' }))
        .required(t('awc:/validations.required', { field: t('awc:/.templateEditor.template.templateName') })),
      subject: Yup.string().max(255, t('/validations.max', { field: '255' })),
      html: Yup.string().required(t('awc:/validations.required', { field: t('awc:/.templateEditor.template.html') })),
    }),
    onSubmit: () => {
      const updatedPayload = {
        ...template,
        html: html,
        name: templateName,
        placeholder: placeholders,
        subject,
      };
      let updatedFields: number = 0;
      Object.keys(initialTemplateFields).forEach((key: string, i) => {
        if (key == "placeholder") {
          const newPlaceholdersString: string = JSON.stringify([...updatedPayload[key]].sort());
          const initialPlaceholdersString: string = JSON.stringify([...initialTemplateFields[key]].sort());
          if (newPlaceholdersString !== initialPlaceholdersString) {
            updatedFields++;
          }
        } else if (
          initialTemplateFields[key as keyof typeof updatedPayload] !==
          updatedPayload[key as keyof typeof updatedPayload]
        ) {
          updatedFields++;
        }
      });
      if (updatedFields > 0) {
        submitHandler(updatedPayload);
        setInitialTemplateFields(updatedPayload as ITemplateFields);
        setTemplate(updatedPayload)
      }
    }
  });

  /// Triggers on selection of template from the dropdown. Sets the html content and the template information.
  const handleSelectTemplate = (val: any) => {
    if (!val) {
      setTemplateName("");
      setTemplateType(null);
      setHtml("");
      setSubject("");
      setTemplate(null);
      setPlaceholders(null);
      setTemplateIsEmail(true);
      setInitialTemplateFields({
        name: "",
        type: null,
        html: "",
        subject: "",
        placeholder: null,
        isEmail: true
      });
    } else {
      setTemplateName(val["name"]);
      setTemplateType(val[templateTypePropertyName]);
      setHtml(val["html"] || val["template"]);
      setSubject(val["subject"]);
      setTemplate(val);
      setPlaceholders(val["placeholder"]);
      setTemplateIsEmail(val["isEmail"]);
      setInitialTemplateFields({
        name: val["name"],
        type: val[templateTypePropertyName],
        html: val["html"] || val["template"],
        subject: val["subject"],
        placeholder: val["placeholder"],
        isEmail: val["isEmail"]
      });
    }
  };

  //editing placeholders
  const updatePlaceholders = (key: any, placeholder: string) => {
    const duplicatePlaceholders = [...placeholders];
    const placeholderIndex = duplicatePlaceholders.findIndex(
      (item: any) => item.id && item.id === key.id || item.placeholderKey === key.placeholderKey
    );
    duplicatePlaceholders[placeholderIndex] = {
      ...duplicatePlaceholders[placeholderIndex],
      [placeholderValuePropertyName]: placeholder,
    };
    setPlaceholders(duplicatePlaceholders);
  };

  /// Initializes dropdown which contains available templates
  const getDropDown = () => {
    const isTemplatesLoaded = templates && !isLoading
    return (
      <Dropdown
        className="dropdown-input-container"
        optionLabel="name"
        dataKey="name"
        value={isTemplatesLoaded ? template : null}
        options={isTemplatesLoaded ? templates : []}
        placeholder={t("awc:/.templateEditor.template.dropdownPlaceholder")}
        showClear={isTemplatesLoaded}
        onChange={(e: any) => handleSelectTemplate(e.value)}
        scrollHeight="1000px" //* Any value to open the list options without the scroll */
      />
    );
  };

  /// Splitter Component
  const splitterComponent = (): JSX.Element => {
    return (
      <Col xs={12} className='pl-0 templates'>
        <Splitter
          className={`${isLayoutChanged ? '' : 'vertical-splitter'}`}
          layout={`${isLayoutChanged ? 'horizontal' : 'vertical'}`}>
          <SplitterPanel
            className={`codemirror-splitterPanel ${isLayoutChanged ? '' : 'vertical-splitter-panel'}`}
            minSize={20}>
              <div className="p-d-block overflow-hidden h-100 primary-border-radius">
                <label htmlFor="name" className="required">
                  {t('awc:/.templateEditor.template.html')}
                </label>
                <CodeMirror
                  value={html}
                  options={codeMirrorOptions}
                  onBeforeChange={(
                    editor: any,
                    data: any,
                    htmlOnChange: any
                  ) => {
                    setHtml(htmlOnChange);
                    formik.setFieldValue('html', htmlOnChange)
                  }}
                  className={`editorClasses ${formik.values.html === '' ? 'custom-html-invalid-field' : ''}`}
                />
              </div>
          </SplitterPanel>
          <SplitterPanel
            className={`${isLayoutChanged ? 'horizontal-splitter-panel' : ''}`}
            minSize={10}>
            <div className="p-d-block overflow-hidden h-100 primary-border-radius">
              <p>{t("awc:/.templateEditor.template.preview")}</p>
              <TemplatePreview
                html={html}
                masterTemplate={
                  template[templateTypePropertyName] === "masterTemplate" || template.isMainEmailTemplate
                    ? ""
                    : masterTemplate
                }
                placeholders={placeholders}
                masterBodyPlaceholder={masterBodyPlaceholder}
                className="template-preview"
                applicationCSS={applicationCSS}
                customCSSLink={customCSSLink}
                isEmail={templateIsEmail}
              />
            </div>
          </SplitterPanel>
        </Splitter>
        {formik.values.html === '' ? (
          <small id="html-invalid" className="p-invalid">
            {formik.errors?.html}
          </small>
        ) : null}
      </Col>
    );
  }

  /// Initializes editor and preview div's.
  const getEditors = () => {
    if (templateType && !isLoading) {
      return (
        <Form onSubmit={formik.handleSubmit}>
          <Col xs={12} className="pl-0 d-flex">
            <Button
              className={`mr-2 custom-button-height text-center ${changeLayoutClasses}`}
              onClick={() => setIsLayoutChanged(!isLayoutChanged)}
            >
              {t("awc:/.templateEditor.template.changeLayout")}
            </Button>
            <Button
              type="submit"
              className={`custom-button-height ${submitClasses}`}
            >
              <i className="bi bi-check2-circle mr-2"></i>
              {t("awc:/.templateEditor.template.update")}
            </Button>
          </Col>
          <Col xs={12} className="pl-0">
            <label htmlFor="name" className="required">
              {t('awc:/.templateEditor.template.templateName')}
            </label>
            <InputText
              id="name"
              placeholder={t("awc:/.templateEditor.template.templateName")}
              value={formik.values?.name}
              onChange={(e: any) => { formik.handleChange(e); setTemplateName(e.target.value) }}
              onBlur={formik.handleBlur}
              className={formik.touched?.name && formik.errors?.name ? 'p-invalid' : ''}
              disabled={isTemplateNameReadonly}
            />
            {formik.touched?.name && formik.errors?.name ? (
              <small id="email-invalid" className="p-invalid">
                {formik.errors?.name}
              </small>
            ) : null}
          </Col>
          {subject !== undefined ? (
            <Col xs={12} className="pl-0">
              <label htmlFor="name">
                {t('awc:/.templateEditor.template.subject')}
              </label>
              <InputText
                id="subject"
                placeholder={t("awc:/.templateEditor.template.subject")}
                onChange={(e: any) => { formik.handleChange(e); setSubject(e.target.value) }}
                value={formik.values?.subject}
                onBlur={formik.handleBlur}
              />
            </Col>
          ) : (
            <></>
          )}
          {splitterComponent()}
          <Col xs={12} className="pl-0">
            <div className="p-d-block">
              <PlaceholderComponent
                placeholders={placeholders}
                templateType={templateType}
                updatePlaceholderHandler={updatePlaceholders}
                copyPlaceholderHandler={copyPlaceholderHandler}
              />
            </div>
          </Col>
        </Form>
      );
    }
    return null;
  };
  
  return (
    <>
      {templates ? (
        <>
          <Col className="pl-0 p-col-12 pt-0">
            {getDropDown()}
          </Col>
          {getEditors()}
        </>
      ) : (
        <SkeletonWrapper width={500} height={37.72} className="custom-skeleton-container" />
      )}
    </>
  );
};

export default TemplateComponent;
