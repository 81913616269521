/*
 * Discounts.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Discounts.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const read = (discountId: string) => {
  return httpClient.get(`/discounts/${discountId}`);
};

export const getAll = async (
  skip = 0,
  limit = defaultTableLimit,
  searchTerm = '',
  sort = {
    sortField: 'discounts.createdAt',
    sortOrder: -1
  }
) => {
  const url = paginationRequest(`/discounts/admin`, {
    skip,
    limit,
    searchTerm
  });
  return httpClient.post(url, { sort });
};

export const createOrUpdate = async (discount: any) => {
  if (discount._id) {
    return httpClient.put(`/discounts/${discount._id}`, discount);
  }
  return httpClient.post(`/discounts`, discount);
};

/**
 * Service to remove Discount(s)
 * @param payload discountIDs
 * @returns Promise
 */
export const remove = async (payload: string[]) => {
  return httpClient.post(`/discounts/delete`, { discountIDs: payload });
};

export const check = async (discountCode: string) => {
  return httpClient.get(`/discounts/check/${discountCode}`);
};
