/*
 * SettingsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file SettingsPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsForm from './SettingsForm';
import SettingsPermittedCountriesTable from './SettingsPermittedCountriesTable';
import SettingsTermsAndConditionTable from './SettingsTermsAndConditionTable';
import SettingsInvoiceSummaryForm from './SettingsInvoiceSummaryForm';
import SettingsBraintreeForm from './SettingsBraintreeForm';
import SettingsSMTPForm from './SettingsSMTPForm';
import {
  fetchSecureSettings,
  getApplicationEnvironmentVariablesAction,
  savePdfInvoiceSummary,
  saveSettings,
  testSMTPAction
} from '../../../Store/ShopSettings';
import {
  createCountry,
  deleteCountry,
  getAllCountriesAction,
  updateCountry
} from '../../../Store/Vat';
import { IPListForm } from '@abstract/abstractwebcommon-client/IPList/IPListForm';
import { FILE_UPLOAD_ERROR } from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { useTranslation } from 'react-i18next';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import StaticLinkForm from '@abstract/abstractwebcommon-client/StaticLinks/StaticLinksForm';
import {
  createStaticLinkAction,
  deleteStaticLinkAction,
  getPaginatedStaticLinksAction,
  IStaticLinksState,
  updateStaticLinkAction
} from '../../../Store/StaticLinks';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';
import { useAppSelector } from '../../../Hooks';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import Button from 'react-bootstrap/Button';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { fillUserDetailsInTransactions } from '../../../Store/Customers';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import EnvironmentVariablePopup from '@abstract/abstractwebcommon-client/EnvironmentVariablePopup';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/EnvironmentVariables';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import FieldSkeleton, {
  controlInputClassName,
  SkeletonHeight
} from '@abstract/abstractwebcommon-client/SkeletonLoader/FieldSkeleton/FieldSkeleton';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { updateBraintreeCustomerAddressesAPI } from '../../../Services/Settings';
import { synchronizeUserDataAction } from '../../../Store/UserCache';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const translation = useTranslation().t;
  const settings = useSelector((state) => state.shopSettings.list);
  const userCacheState = useSelector((state) => state.userCache);
  const environmentVariables: IPublicEnvironmentVariables = useAppSelector(
    (state) => state.shopSettings.environmentVariables
  ); /**< Get environmentVariables. */
  const countriesData = useSelector((state) => state.countries.list);
  const countriesTotalRecords = useSelector(
    (state) => state.countries.totalRecords
  );
  const adminSettings = useSelector((state) => state.shopSettings.secureList);
  const loading = useSelector((state) => state.shopSettings.listIsFetching);
  const isLoadingTestSMTP: boolean = useAppSelector(
    (state) => state.shopSettings.isLoadingTestSMTP
  ); /**< Loading state for test smtp */
  const staticLinksState: IStaticLinksState = useAppSelector(
    (state) => state.staticLinks
  ); /**< Get staticLink state. */
  const [searchTerm, setSearchTerm] = useState('');
  const [countryTablePayload, setCountryPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'countries.createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const [staticLinksTablePayload, setStaticLinksPayload] = useState<
    ITablePayload
  >({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'staticLinks.createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const [croppedLogo, setCroppedLogo] = useState(null); /**< Cropped Logo. */
  const [croppedFavouriteIcon, setCroppedFavouriteIcon] = useState(
    null
  ); /**< Cropped Favourite Icon. */
  const [croppedStaticLinkIcon, setCroppedStaticLinkIcon] = useState<any>(
    null
  ); /**< Croppped Static Link Icon. */
  const [initiateLogoUpload, setInitiateLogoUpload] = useState(
    null
  ); /**Initiate Logo Upload. */
  const [logoUploadStatus, setLogoUploadStatus] = useState<string>(
    ''
  ); /**< Logo upload status. */
  const [
    initiateFavouriteIconUpload,
    setInitiateFavouriteIconUpload
  ] = useState(null); /**Initiate FavouriteIcon Upload. */
  const [favouriteIconUploadStatus, setFavouriteIconUploadStatus] = useState<
    string
  >(''); /**< FavouriteIcon upload status. */
  const [
    initiateStaticLinkIconUpload,
    setInitiateStaticLinkIconUpload
  ] = useState<any>(null); /**Initiate StaticLink Icon Upload. */
  const [isLogoFileChanged, setLogoFileChanged] = useState(
    false
  ); /**< Logo File changed or not. */
  const [isFavouriteIconFileChanged, setFavouriteIconFileChanged] = useState(
    false
  ); /**< FavouriteIcon File changed or not. */
  const [isStaticLinkIconFileChanged, setStaticLinkIconFileChanged] = useState<
    boolean
  >(false); /**< StaticLink Icon File changed or not. */
  const [staticLinkIconUploadStatus, setStaticLinkIconUploadStatus] = useState<
    string
  >(''); /**< StaticLink Icon upload status. */
  const [showEnvironmentVariable, setShowEnvironmentVariable] = useState<
    boolean
  >(false); /**< Show/Hide EnvironmentVariable Dialog */
  const countryDataTableReference = useRef<any>(null);
  const staticLinkDataTableReference = useRef<any>(null);

  const [
    isCustomerAddressesUpdateLoading,
    setIsCustomerAddressesUpdateLoading
  ] = useState<boolean>(false);

  useEffect(() => {
    countryTablePayload.searchTerm = searchTerm;
    dispatch(getAllCountriesAction(countryTablePayload));
  }, [searchTerm]);

  useEffect(() => {
    dispatch(fetchSecureSettings()); /**< To fetch Admin settings. */
    dispatch(
      getApplicationEnvironmentVariablesAction()
    ); /**< To fetch environment variables. */
  }, []);

  useEffect(() => {
    if (
      !staticLinksState.staticLinks ||
      staticLinksState.staticLinks.length <= 0
    ) {
      dispatch(getPaginatedStaticLinksAction(staticLinksTablePayload));
    }
  }, [dispatch]);

  const handleSave = async (settings) => {
    if (settings.cacheFeedsTTL === '') {
      settings.cacheFeedsTTL = 0;
    }
    if (settings.rssDelay === '') {
      settings.rssDelay = 0;
    }

    const payload: Record<string, any> = {
      settings
    }; /**< Store settings payload */

    // To upload/delete store logo
    if (logoUploadStatus === 'add') {
      payload['uploadLogo'] = { file: initiateLogoUpload };
    } else if (logoUploadStatus === 'delete') {
      payload['deleteLogo'] = true;
    }
    // To upload/delete store favicon
    if (favouriteIconUploadStatus === 'add') {
      payload['uploadFavouriteIcon'] = { file: initiateFavouriteIconUpload };
    } else if (favouriteIconUploadStatus === 'delete') {
      payload['deleteFavouriteIcon'] = true;
    }

    dispatch(saveSettings(payload));
    setLogoUploadStatus('');
    setFavouriteIconUploadStatus('');

    return;
  };

  const handleSaveWhitelistedIPs = (selectedIPs) => {
    const settings: Record<string, any> = {
      whitelistedIPs: selectedIPs
    };
    const payload: Record<string, any> = {
      settings
    }; /**< Store settings payload */

    return dispatch(saveSettings(payload));
  };

  /// Save handler for blacklistedIPs
  const handleSaveBlacklistedIPs = (selectedIPs: string[]) => {
    const settings: Record<string, any> = {
      blacklistedIPs: selectedIPs
    };
    const payload: Record<string, any> = {
      settings
    }; /**< Store settings payload */

    return dispatch(saveSettings(payload));
  };

  // Upload Shop Logo.
  const uploadLogo = async (files) => {
    const file = files && files[0];
    if (file.type.indexOf('image/') !== -1) {
      // Crop Dialog.
      setInitiateLogoUpload(file);
      setLogoFileChanged(true);
      setLogoUploadStatus('add');
    } else {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  // Upload Favorite Icon.
  const uploadFavoriteIcon = async (files) => {
    const file = files && files[0];
    if (file.type.indexOf('image/') !== -1) {
      // Crop Dialog.
      setInitiateFavouriteIconUpload(file);
      setFavouriteIconFileChanged(true);
      setFavouriteIconUploadStatus('add');
    } else {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  // Delete Shop Logo.
  const deleteLogo = () => {
    setLogoUploadStatus('delete');
  };

  // Delete Shop Favicon.
  const deleteFavoriteIcon = () => {
    setFavouriteIconUploadStatus('delete');
  };

  const handleCountrySave = (country) => {
    country = {
      data: country,
      payload: countryTablePayload
    };
    if (country && country.id) {
      dispatch(updateCountry(country));
    } else {
      dispatch(createCountry(country));
    }
  };

  const handleCountryDelete = (countryIDs: string[]) => {
    dispatch(deleteCountry(countryIDs, countryTablePayload));
  };

  const handlePdfInvoiceSummarySave = async (summary) => {
    await asyncErrorHandler(dispatch(savePdfInvoiceSummary(summary)));
  };

  /// Handle Image Upload Error handler.
  const errorHandler = (error) => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({
        severity: 'error',
        summary: translation('insta_image.no_image_uploaded')
      });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  /// Handle Logo crop event
  const handleLogoImageCropComplete = async (image) => {
    if (!image) {
      setLogoUploadStatus('');
    }
    setCroppedLogo(image);
    setInitiateLogoUpload(image);
  };

  /// Handle FavouriteIcon crop event
  const handleFavouriteIconImageCropComplete = async (image) => {
    if (!image) {
      setFavouriteIconUploadStatus('');
    }
    setCroppedFavouriteIcon(image);
    setInitiateFavouriteIconUpload(image);
  };

  /// Handle StaticLink Submit event
  const handleStaticLinkSubmit = async (
    staticLink: any,
    staticLinkID: string | undefined
  ): Promise<void> => {
    const payload: any = {
      staticLink
    };

    if (staticLinkIconUploadStatus === 'add') {
      payload['uploadStaticLinkIcon'] = { file: initiateStaticLinkIconUpload };
    } else if (staticLinkIconUploadStatus === 'delete') {
      payload['deleteStaticLinkIcon'] = true;
    }

    Object.assign(payload, { tablePayload: staticLinksTablePayload });
    if (staticLinkID && staticLinkID !== '') {
      await asyncErrorHandler(
        dispatch(updateStaticLinkAction({ data: payload, staticLinkID }))
      );
    } else {
      await asyncErrorHandler(dispatch(createStaticLinkAction(payload)));
    }
    setStaticLinkIconUploadStatus('');
  };

  /// Upload staticLinkIcon handler
  const uploadStaticLinkIcon = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    if (file.type.indexOf('image/') !== -1) {
      setInitiateStaticLinkIconUpload(file);
      setStaticLinkIconFileChanged(true);
      setStaticLinkIconUploadStatus('add');
    } else {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  /// Delete staticLinkIcon handler
  const deleteStaticLinkIcon = (): void => {
    setStaticLinkIconUploadStatus('delete');
  };

  /// Delete StaticLinks handler
  const handleDeleteStaticLinks = async (
    selectedStaticLinks: IStaticLink[]
  ): Promise<void> => {
    const staticLinks: Record<string, string[]> = {
      staticLinksIDs: selectedStaticLinks.map(
        (staticLink: IStaticLink) => staticLink.id
      )
    };
    const payload = {
      uuids: staticLinks.staticLinksIDs,
      tablePayload: staticLinksTablePayload
    };
    dispatch(deleteStaticLinkAction(payload));
  };

  /// StaticLinkIcon Crop handler.
  const handleStaticLinkIconImageCropComplete = (image: any): void => {
    setCroppedStaticLinkIcon(image);
    setInitiateStaticLinkIconUpload(image);
  };

  /// Handle sort update event
  const handleSortUpdateCountry = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = countryTablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setCountryPayload(updatedPayload);
    dispatch(getAllCountriesAction(updatedPayload));
  };

  /// Handle pagination and sort change of static links
  const refreshSaticLinksList = (updatedCriteria: ITablePayload) => {
    setStaticLinksPayload(updatedCriteria);
    dispatch(getPaginatedStaticLinksAction(updatedCriteria));

    //Note: only scroll to bottom if pagination was changed
    if (updatedCriteria.skip !== staticLinksTablePayload.skip) {
      staticLinkDataTableReference.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  // Handle test SMTP
  const handleTestSMTP = async (values: any): Promise<void> => {
    dispatch(testSMTPAction(values));
  };

  // Fill user details in transactions.
  const fillTransactionsUserData = async () => {
    const response: IAPIEntityResponse<Record<
      string,
      any
    >> = await asyncErrorHandler(dispatch(fillUserDetailsInTransactions()));
    if (response) {
      showToast({
        severity: response.isUpdated ? 'success' : 'info',
        summary: response.message ?? ''
      });
    }
  };

  // Update Braintree customers addresses.
  const updateBraintreeCustomerAddresses = async () => {
    try {
      setIsCustomerAddressesUpdateLoading(true);

      const response = await asyncErrorHandler(
        updateBraintreeCustomerAddressesAPI()
      );

      if (response && response?.message) {
        showToast({
          severity: 'success',
          summary: response?.message ?? ''
        });
        setIsCustomerAddressesUpdateLoading(false);
      }
    } catch (error) {
      showToast({
        severity: 'error',
        summary:
          'Failed to update Braintree customers addresses. Check the logs for more details.'
      });

      setIsCustomerAddressesUpdateLoading(false);
    }
  };

  // Update the user cache content.
  const synchronizeUserData = async () => {
    dispatch(synchronizeUserDataAction());
  };

  /// Hide Environament variable dialog
  const hideEnvironmentVariablesDialog = (): void => {
    setShowEnvironmentVariable(false);
  };

  /// Get Environament variables
  const getApplicationEnvironmentVariables = (): JSX.Element => {
    return (
      <EnvironmentVariablePopup
        environmentVariables={environmentVariables}
        onClose={hideEnvironmentVariablesDialog}
      />
    );
  };

  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: countryTablePayload.sort.sortField,
        sortOrder: countryTablePayload.sort.sortOrder
      },
      searchTerm: countryTablePayload.searchTerm
    };

    setCountryPayload(updatedPayload);
    dispatch(getAllCountriesAction(updatedPayload));

    //Note: only scroll to bottom if pagination was changed
    if (event.first !== countryTablePayload.skip) {
      countryDataTableReference.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const RenderSkeletonComponent = (isFileInput = false): false | JSX.Element =>
    !adminSettings && (
      <FieldSkeleton
        skeletonHeightType={
          isFileInput ? SkeletonHeight.FileType : SkeletonHeight.Default
        }
      />
    );

  const changeInputClassName = (isFileField = false): string =>
    controlInputClassName(!adminSettings, isFileField);

  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <SettingsForm
            settings={settings}
            adminSettings={adminSettings}
            handleSubmit={handleSave}
            loading={loading}
            uploadLogo={uploadLogo}
            deleteLogo={deleteLogo}
            croppedLogo={croppedLogo}
            uploadFavoriteIcon={uploadFavoriteIcon}
            deleteFavoriteIcon={deleteFavoriteIcon}
            croppedFavouriteIcon={croppedFavouriteIcon}
            errorHandler={errorHandler}
            logoUploadStatus={logoUploadStatus}
            favouriteIconUploadStatus={favouriteIconUploadStatus}
            RenderSkeletonComponent={RenderSkeletonComponent}
            changeInputClassName={changeInputClassName}
          />
        </Col>
        <Col xs={12} lg={6}>
          <SettingsInvoiceSummaryForm
            adminSettings={adminSettings}
            loading={loading}
            handleSubmit={handlePdfInvoiceSummarySave}
            RenderSkeletonComponent={RenderSkeletonComponent}
            changeInputClassName={changeInputClassName}
          />
          <div className="mt-3">
            <SettingsSMTPForm
              adminSettings={adminSettings}
              settings={settings}
              handleSubmit={handleSave}
              loading={!adminSettings}
              handleTestSMTP={handleTestSMTP}
              isLoadingTestSMTP={isLoadingTestSMTP}
            />
          </div>
          <div className="mt-3">
            <StaticLinkForm
              refreshSaticLinksList={refreshSaticLinksList}
              staticLinksState={staticLinksState}
              tableData={staticLinksState.staticLinks}
              isLoading={staticLinksState.isStaticLinksLoading}
              deleteLinks={handleDeleteStaticLinks}
              uploadStaticLinkIcon={uploadStaticLinkIcon}
              deleteStaticLinkIcon={deleteStaticLinkIcon}
              displayCroppedStaticLinkIcon={croppedStaticLinkIcon}
              handleSubmit={handleStaticLinkSubmit}
              setCroppedStaticLinkIcon={setCroppedStaticLinkIcon}
              useObjectID={true}
              referenceHook={staticLinkDataTableReference}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SettingsBraintreeForm
            adminSettings={adminSettings}
            settings={settings}
            loading={loading}
            handleSubmit={handleSave}
            RenderSkeletonComponent={RenderSkeletonComponent}
            changeInputClassName={changeInputClassName}
          />
        </Col>
        <Col xs={12} lg={6}>
          <IPListForm
            isLoading={!settings}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              handleSaveWhitelistedIPs(selectedIPs)
            }
            ipRanges={settings.whitelistedIPs}
            isWhitelistedIPs={true}
          />
          <IPListForm
            isLoading={!settings}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              handleSaveBlacklistedIPs(selectedIPs)
            }
            ipRanges={settings.blacklistedIPs}
            isWhitelistedIPs={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SettingsPermittedCountriesTable
            handleSubmit={handleCountrySave}
            handleDelete={handleCountryDelete}
            isLoading={loading}
            data={countriesData}
            tablePayload={countryTablePayload}
            setSearchTerm={setSearchTerm}
            handleSortUpdate={handleSortUpdateCountry}
            totalRecords={countriesTotalRecords}
            handlePageUpdate={handlePageUpdate}
            countryDataTableReference={countryDataTableReference}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <SettingsTermsAndConditionTable />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="py-0">
          <Row className="justify-content-end align-items-center mt-3 px-3">
            <Button
              variant="primary"
              className="p-button-raised p-button-primary btn-block userData-button mr-3"
              onClick={() => {
                setShowEnvironmentVariable(!showEnvironmentVariable);
              }}
            >
              {showEnvironmentVariable ? (
                <span className="d-flex align-items-center">
                  <i className="pi pi-eye-slash" style={{ fontSize: '1em' }} />
                  <span className="flex-grow-1">
                    {translation('setting.hideEnvironmentVariables')}
                  </span>
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  <i className="pi pi-eye" style={{ fontSize: '1em' }} />
                  <span className="flex-grow-1">
                    {translation('setting.showEnvironmentVariables')}
                  </span>
                </span>
              )}
            </Button>
            <Button
              variant="primary"
              className="p-button-raised p-button-primary btn-block userData-button m-0"
              onClick={fillTransactionsUserData}
            >
              <span className="d-flex align-items-center">
                <i className="bi bi-arrow-repeat" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {translation('customersTable.header_button.userdata')}
                </span>
              </span>
            </Button>
            <Button
              variant="primary"
              disabled={isCustomerAddressesUpdateLoading}
              className="update-braintree-customer-address-button ml-3 p-button-raised p-button-primary btn-block userData-button m-0"
              onClick={updateBraintreeCustomerAddresses}
            >
              <span className="d-flex align-items-center">
                <i className="bi bi-arrow-repeat" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {isCustomerAddressesUpdateLoading
                    ? 'Requesting...'
                    : 'Update Customer Addresses'}
                </span>
              </span>
            </Button>
            <Button
              variant="primary"
              disabled={userCacheState.isRequestingEndpoint}
              className="update-braintree-customer-address-button ml-3 p-button-raised p-button-primary btn-block userData-button m-0"
              onClick={synchronizeUserData}
            >
              <span className="d-flex align-items-center">
                <i className="bi bi-arrow-repeat" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {userCacheState.isRequestingEndpoint
                    ? 'Requesting...'
                    : 'Populate User Cache Table'}
                </span>
              </span>
            </Button>
          </Row>
        </Col>
      </Row>
      <CropDialog
        isVisible={isLogoFileChanged}
        setVisible={setLogoFileChanged}
        file={initiateLogoUpload}
        onImageCropComplete={handleLogoImageCropComplete}
      />
      <CropDialog
        isVisible={isFavouriteIconFileChanged}
        setVisible={setFavouriteIconFileChanged}
        file={initiateFavouriteIconUpload}
        onImageCropComplete={handleFavouriteIconImageCropComplete}
      />
      <CropDialog
        isVisible={isStaticLinkIconFileChanged}
        setVisible={setStaticLinkIconFileChanged}
        file={initiateStaticLinkIconUpload}
        onImageCropComplete={handleStaticLinkIconImageCropComplete}
        isIcon
      />
      <DialogWrapper
        isDialogVisible={showEnvironmentVariable}
        onHide={() => hideEnvironmentVariablesDialog()}
        headerTitle={translation('setting.headers.enviromentVariables')}
      >
        {getApplicationEnvironmentVariables()}
      </DialogWrapper>
    </>
  );
};

export default SettingsPage;
