/**
* routesNames.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file routesNames.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

/**
 * Use this file to centralize all route names in the application
 * Add route value below as needed.
 */
export enum RouteName {
  productRoute = '/product' /**< /product route name */,
  transactionRoute = '/transaction' /**< /product route name */,

  adminDashboardRoute = `${SharedMainRouteName.adminRoute}/dashboard` /**< /admin/dashboard route name */,
  adminProductsRoute = `${SharedMainRouteName.adminRoute}/products` /**< /admin/products route name */,
  adminCustomersRoute = `${SharedMainRouteName.adminRoute}/customers` /**< /admin/customers route name */,
  adminTransactionsRoute = `${SharedMainRouteName.adminRoute}/transactions` /**< /admin/transactions route name */,
  adminDiscountsRoute = `${SharedMainRouteName.adminRoute}/discounts` /**< /admin/discounts route name */,
  adminTemplateEditorRoute = `${SharedMainRouteName.adminRoute}/template-editor` /**< /admin/template-editor route name */,
  adminCSSEditorRoute = `${SharedMainRouteName.adminRoute}/css-editor` /**< /admin/css-editor route name */,
  adminSettingsRoute = `${SharedMainRouteName.adminRoute}/settings` /**< /admin/settings route name */,
  adminLogsRoute = `${SharedMainRouteName.adminRoute}/logs` /**< /admin/logs route name */,
  adminSubscriptionsRoute = `${SharedMainRouteName.adminRoute}/subscriptions` /**< /admin/subscriptions route name */,
  adminApplicationManagementRoute = `${SharedMainRouteName.adminRoute}/application-management` /**< /admin/application-management route name */,

  clientTransactionsRoute = `${SharedMainRouteName.clientRoute}/transactions` /**< /client/transactions route name */,
  clientSubscriptionsRoute = `${SharedMainRouteName.clientRoute}/subscriptions` /**< /client/subscriptions route name */
}
