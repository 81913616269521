/*
 * PageHeader.tsx (Web Common)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file PageHeader.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section Web Common
 */

import * as React from 'react';

/**
 * @interface PageHeaderProperties
 */
interface PageHeaderProperties {
  title: string /**< title to display on header */
  [key:string]: any /**< others properties */
}

import './PageHeader.css'

const PageHeader = (properties: PageHeaderProperties) => {
  const { title, ...otherProperties } = properties // NOTE: De-structuring here to not pass title property to the H2 HTML tag
  return (
    <h2 className="page-header-container" {...otherProperties}>{title}</h2>
  )
}

export default PageHeader