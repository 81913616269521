/*
 * TopBar.tsx (AbstractWebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file TopBar.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect } from 'react';
import { Toolbar } from 'primereact/toolbar';
import './index.css'
import { ThemeMode } from '../../Shared/enum/theme';
import { Button } from 'primereact/button';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { changeUserLanguage } from '../utils/LanguageSettingsModeUtils';

/**
 * @interface ITopBarProperties
 */
interface ITopBarProperties {
  themeMode?: string; /**< theme mode to use */
  didChangeTheme?: (theme: ThemeMode) => void; /**< change theme function */
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (language: string) => void /**< change language function */;
  i18nService: any;
  isThemeModeVisible?: boolean /**< Theme mode visibility */;
}

const TopBar = (properties: ITopBarProperties) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(properties.languageSettingsMode); /**< Selected language */
  const isThemeModeVisible: boolean = properties.isThemeModeVisible === undefined ? true : properties.isThemeModeVisible; /**> Theme mode  visibility */

  useEffect(() => {
    properties.didChangeLanguage(selectedLanguage)
  }, [selectedLanguage]);

  useEffect(() => {
    changeUserLanguage(selectedLanguage, properties.i18nService);
  },[]);

  const rightContents = (
    <div className='d-flex mt-4'>
      <div className="mr-2">
        <LanguageSelector 
          i18nService={properties.i18nService}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </div>
      { isThemeModeVisible ? 
        <div className="theme-mode-button theme-mode-button-from-login-page custom-theme-mode-className">
          {properties.themeMode === ThemeMode.darkMode ? (
            <Button
              onClick={() => properties.didChangeTheme(ThemeMode.lightMode)}
              icon="far fa-sun sidebar_icon"
              className="p-button-text w-100 d-flex px-2"
            />
          ) : (
            <Button
              onClick={() => properties.didChangeTheme(ThemeMode.darkMode)}
              icon="far fa-moon sidebar_icon"
              className="p-button-text w-100 d-flex px-2"
            />
          )}
        </div> :
        <></>
      }
    </div>
  );
  return (
    <div className="top-bar">
      <Toolbar right={rightContents as any} />
    </div>
  );
};

export default TopBar;
