/*
 * environmentType.ts (AbstractUser)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file environmentType.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Defines the environment type that the project is running in.
 * @enum EnvironmentType
 */
export enum EnvironmentType {
  Local = "local",
  Staging = "staging",
  Production = "production",
}
