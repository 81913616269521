/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';

import Dashboard from '../Components/Admin/Dashboard';
import ProductsPage from '../Components/Admin/Products/ProductsPage';
import CustomersPage from '../Components/Admin/Customers/CustomersPage';
import TransactionsPage from '../Components/Admin/Transactions/TransactionPage';
import SettingsPage from '../Components/Admin/Settings/SettingsPage';
import DiscountsPage from '../Components/Admin/Discounts/DiscountsPage';
import TemplateEditorPage from '../Components/Admin/Template/TemplateEditorPage';
import CssEditorPage from '../Components/Admin/Template/CssEditorPage';
import LogsPage from '../Components/Admin/Logs/LogsPage';
import AdminSubscriptionsPage from '../Components/Admin/Subscriptions/SubscriptionsPage';
import ClientSubscriptionsPage from '../Components/Client/Subscriptions/SubscriptionsPage';
import TransactionsTable from '../Components/Client/Transaction/TransactionTable';
import NotFoundRedirect from '@abstract/abstractwebcommon-client/NotFound/NotFoundRedirect';
import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../Utils/routesNames';
import ApplicationManagementPage from '../Components/Admin/ApplicationManagement/ApplicationManagementPage';

/**
 * Get admin routes
 * @returns adminRoutes
 */
export const getRoutes = () => {
  const SHARED_HEADER_TITLE = {
    transactionsPage: i18next.t('admin.menu.transactions'),
    customersPage: i18next.t('admin.menu.customers')
  };

  const routes = [
    {
      path: SharedMainRouteName.clientRoute,
      Component: TransactionsTable,
      authenticated: true,
      headingKey: SHARED_HEADER_TITLE.transactionsPage
    },
    {
      path: RouteName.clientTransactionsRoute,
      Component: TransactionsTable,
      authenticated: true,
      headingKey: SHARED_HEADER_TITLE.transactionsPage
    },
    {
      path: RouteName.clientSubscriptionsRoute,
      Component: ClientSubscriptionsPage,
      authenticated: true,
      headingKey: i18next.t('user.menu.subscriptions')
    },
    {
      path: SharedMainRouteName.adminRoute,
      Component: TransactionsPage,
      authenticated: true,
      headingKey: SHARED_HEADER_TITLE.transactionsPage
    },
    {
      path: RouteName.adminDashboardRoute,
      Component: Dashboard,
      authenticated: true,
      showHeading: false
    },
    {
      path: RouteName.adminSettingsRoute,
      Component: SettingsPage,
      authenticated: true,
      showHeading: false
    },
    {
      path: RouteName.adminProductsRoute,
      Component: ProductsPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.products')
    },
    {
      path: RouteName.adminCustomersRoute,
      Component: CustomersPage,
      authenticated: true,
      headingKey: SHARED_HEADER_TITLE.customersPage
    },
    {
      path: `${RouteName.adminTransactionsRoute}/:transactionId?`,
      Component: TransactionsPage,
      authenticated: true,
      headingKey: SHARED_HEADER_TITLE.transactionsPage
    },
    {
      path: RouteName.adminLogsRoute,
      Component: LogsPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.logs')
    },
    {
      path: RouteName.adminDiscountsRoute,
      Component: DiscountsPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.discounts')
    },
    {
      path: RouteName.adminApplicationManagementRoute,
      Component: ApplicationManagementPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.applicationManagement')
    },
    {
      path: RouteName.adminTemplateEditorRoute,
      Component: TemplateEditorPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.template-editor')
    },
    {
      path: RouteName.adminCSSEditorRoute,
      Component: CssEditorPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.css')
    },
    {
      path: RouteName.adminSubscriptionsRoute,
      Component: AdminSubscriptionsPage,
      authenticated: true,
      headingKey: i18next.t('admin.menu.subscriptions')
    },
    {
      Component: NotFoundRedirect,
      authenticated: false
    }
  ];

  return routes;
};
