/*
 * DiscountCode.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file DiscountCode.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

/**
 * Interface for DiscountCode properties.
 */
interface IDiscountCodeProperties {
  values?: {
    discoundCode?: any;
    [key: string]: any /**< Dynamic properties */;
  };
  errors?: any;
  handleChange?: any;
  handleSubmit: (code: any) => void;
  loading?: boolean;
  handleBlur?: any;
  touched?: any;
  discount: any;
  discountNotFound: any;
  billingInfo: any;
  serialNumber: number;
  isShopPage?: boolean /**< If discount code is on the shop page(public route) */;
  loggedOutMessageBanner: JSX.Element /**< Logged out message banner */;
}

const DiscountCode = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  handleBlur,
  touched,
  discount,
  discountNotFound,
  billingInfo,
  serialNumber,
  isShopPage,
  loggedOutMessageBanner
}: IDiscountCodeProperties) => {
  const { t } = useTranslation();

  return (
    <Fieldset
      legend={t('checkoutPage.summary.discounts', {
        number: `${serialNumber}.`
      })}
      className="custom-fieldset"
    >
      {loggedOutMessageBanner}
      <Row>
        <Form.Group as={Col} className="pb-0">
          <InputText
            type="text"
            name="discountCode"
            disabled={loading}
            value={values && values.discountCode}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classnames({
              'p-error':
                touched && (Object.keys(errors).length > 0 || discountNotFound),
              'is-invalid':
                touched && (Object.keys(errors).length > 0 || discountNotFound),
              'is-valid': touched && Object.keys(errors).length === 0
            })}
          />
          {discountNotFound && (
            <Form.Control.Feedback type="invalid">
              {t('checkoutPage.discount.failure')}
            </Form.Control.Feedback>
          )}
          {discount && (
            <Form.Control.Feedback type="valid">
              {t('checkoutPage.discount.success')}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Col sm={12} md={6} className="text-right pb-0">
          <Button
            className={`mt-2 mt-md-0 justify-content-center ${
              isShopPage ? 'p-button p-button-primary' : ''
            }`}
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {t('checkoutPage.discount.apply')}
          </Button>
        </Col>
      </Row>
    </Fieldset>
  );
};

export default withFormik({
  mapPropsToValues: ({ discountCode }) => {
    return {
      discountCode
    };
  },
  validationSchema: Yup.object({
    discountCode: Yup.string()
  }),
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit(values.discountCode);
  }
})(DiscountCode);
