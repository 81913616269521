/*
 * UserTransaction.ts (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file UserTransaction.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchUserTransactions} from '../Services/UserTransaction';
import {defaultTableLimit} from '@abstract/abstractwebcommon-client/Constants';
import {ITablePayload} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {LocalStorage} from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const TRANSACTIONS_FEATURE_KEY = 'userTransactions';

/**
 * Transaction Intial state
 */
const INITIAL_STATE: ITransactionState = {
  list: null,
  isFetchingTransactionList: true,
  skip: 0,
  limit: defaultTableLimit,
  count: 0,
  searchTerm: '',
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  }
};

export const transactionSlice = createSlice({
  name: TRANSACTIONS_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getUserTransactionRequest(state, action: any) {
      const skip: number = action.payload.skip;
      const limit: number = action.payload.limit;
      const sort: any = action.payload.sort;
      const searchTerm: any = action.payload.searchTerm;
      if (sort) {
        state.sort = sort;
      }
      state.skip = skip;
      state.limit = limit;
      state.searchTerm = searchTerm;
      state.isFetchingTransactionList = true;
    },
    getUserTransactionSuccess(state, action: any) {
      state.list = action.payload?.data;
      state.count = action.payload?.count;
      state.isFetchingTransactionList = false;
    },
    getUserTransactionFailure(state) {
      state.isFetchingTransactionList = false;
    }
  },
  extraReducers: {}
});

export const transactionReducer = transactionSlice.reducer;
export const transactionActions = transactionSlice.actions;

/**
 * Get orders By UserID action.
 * @param payload
 */
export const getUserTransactions = createAsyncThunk(
  'transactions/find',
  async (payload: ITablePayload, thunkAPI) => {
    const userUUID:
      | string
      | null = LocalStorage.getXUserUUID(); /**< UserUUID. */
    const { skip, sort, limit, searchTerm } = payload;
    const { dispatch } = thunkAPI;

    const getUserTransactionRequest: any =
      transactionActions.getUserTransactionRequest;
    const getUserTransactionSuccess: any =
      transactionActions.getUserTransactionSuccess;
    const getUserTransactionFailure: any =
      transactionActions.getUserTransactionFailure;

    try {
      dispatch(getUserTransactionRequest({ skip, limit, sort, searchTerm }));
      const response: any = await asyncErrorHandler(fetchUserTransactions(
          userUUID ? userUUID : '',
          skip,
          limit,
          searchTerm,
          sort
      ));
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getUserTransactionFailure());
      } else {
        dispatch(getUserTransactionSuccess(response));
      }
    } catch (exception) {
      dispatch(getUserTransactionFailure());
      handleError({ message: exception.message });
    }
  }
);
