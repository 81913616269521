/*
 * AnalyticsContent.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AnalyticsContent.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { Card } from 'primereact/card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AnalyticsTable } from './AnalyticsTable';
import { TransactionsAnalyticsTable } from './TransactionsAnalyticsTable';
import { VisitorsAnalyticsTable } from './VisitorsAnalyticsTable';
import {
  ChartDashboardItem,
  NumericDashboardItem,
  CustomComponentDashboardItem
} from './DashboardItem';
import { MultiSelect } from 'primereact/multiselect';

/**
 * Interface for AnalyticsContent properties.
 */
interface IAnalyticsContentProperties {
  data: any;
  date: Date;
}

export const AnalyticsContent = ({
  data,
  date
}: IAnalyticsContentProperties) => {
  const [selectedDashboardItems, setSelectedDashboardItems] = useState(null);
  const [formattedDashboardItems, setFormattedDashboardItems] = useState([]);
  const [visibleDashboardItems, setVisibleDashboardItems] = useState([]);
  const darkGrey = '#495057'; /**< Label color. */
  // get and format data for the charts
  const {
    viewsPerPage,
    groupByHour,
    groupByDevice,
    purchaseSuccess,
    purchaseFailure,
    mostViewedProduct,
    transactionCountByProductAndCountry,
    visitorsCountByCountry
  } = data;
  const pageVisitData = {};
  const hitRateData = {
    xaxisData: [],
    yaxisData: []
  };
  if (viewsPerPage) {
    pageVisitData.xaxisData = viewsPerPage.xaxisData;
    pageVisitData.yaxisData = viewsPerPage.yaxisData;
  }
  if (groupByHour) {
    for (let i = 0; i <= 23; i++) {
      const hits = groupByHour[i] ? groupByHour[i].length : 0;
      hitRateData.xaxisData.push(`${i}:00`);
      hitRateData.yaxisData.push(hits);
    }
  }
  const devicesOption = {
    series: groupByDevice && groupByDevice.series ? groupByDevice.series : [],
    options: {
      colors: ['#0290FA', '#cc285c'],
      labels: groupByDevice && groupByDevice.labels ? groupByDevice.labels : [],
      dataLabels: {
        style: {
          colors: ['#FFF', '#fff']
        },
        value: {}
      },
      legend: {
        horizontalAlign: 'center',
        labels: {
          colors: darkGrey
        }
      },
      stroke: {
        colors: ['#b1b9c4']
      }
    }
  };
  const purchaseOption = {
    series: [purchaseSuccess, purchaseFailure],
    options: {
      colors: ['#0290FA', '#cc285c'],
      labels: ['Successful purchase', 'Unsuccessful purchase'],
      dataLabels: {
        style: {
          colors: ['#FFF', '#fff', '#fff', '#fff', '#fff']
        },
        value: {}
      },
      legend: {
        horizontalAlign: 'center',
        labels: {
          colors: darkGrey
        }
      },
      stroke: {
        colors: ['#b1b9c4']
      }
    }
  };
  const pageVisitOptions = {
    options: {
      chart: {
        id: `page-views_${date[0].startDate.toDateString()} - ${date[0].endDate.toDateString()}`
      },
      colors: ['#cc285c'],
      tooltip: {
        theme: 'dark'
      },
      title: {
        text: 'visits per page',
        align: 'left',
        style: { color: darkGrey }
      },
      plotOptions: {
        bar: {
          columnWidth: '55%'
        }
      },
      xaxis: {
        categories: pageVisitData.xaxisData,
        labels: {
          style: {
            colors: darkGrey,
            fontSize: '10px'
          }
        }
      },
      yaxis: {
        seriesName: 'visits',
        labels: {
          show: true,
          style: { colors: darkGrey }
        }
      },
      dataLabels: {
        style: {
          colors: [darkGrey]
        }
      }
    },
    series: [
      {
        name: 'Page Visits',
        data: pageVisitData.yaxisData
      }
    ]
  };

  const hitRateOptions = {
    series: [
      {
        name: 'hits',
        data: hitRateData.yaxisData ? hitRateData.yaxisData : []
      }
    ],
    options: {
      // colors : ['#ff007c'],
      colors: ['#007ad9'],
      chart: {
        id: `shop-traffic_${date[0].startDate.toDateString()} - ${date[0].endDate.toDateString()}`,
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      tooltip: {
        theme: 'dark'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'hits per hour of the day (24 hours)',
        align: 'left',
        style: { color: darkGrey }
      },
      labels: hitRateData.xaxisData ? hitRateData.xaxisData : [],
      xaxis: {
        labels: {
          style: { colors: darkGrey }
        }
      },
      yaxis: {
        labels: {
          style: { colors: darkGrey }
        },
        opposite: true
      },
      legend: {
        horizontalAlign: 'left'
      }
    }
  };

  const numericDashboardItems = [
    new NumericDashboardItem('No of Visitors', data.noOfVisitors),
    new NumericDashboardItem('Successful Purchase', data.purchaseSuccess),
    new NumericDashboardItem('Unsuccessful Purchase', data.purchaseFailure),
    new NumericDashboardItem('Page Views', data.totalPageViews)
  ];
  const chartDashboardItems = [
    new ChartDashboardItem('Page Views', {
      options: pageVisitOptions.options,
      series: pageVisitOptions.series,
      type: 'bar',
      width: '100%',
      height: 350
    }),
    new ChartDashboardItem('Shop Traffic', {
      options: hitRateOptions.options,
      series: hitRateOptions.series,
      type: 'area',
      height: 350
    }),
    new ChartDashboardItem(
      'Devices',
      {
        options: devicesOption.options,
        series: devicesOption.series,
        type: 'pie',
        height: 200
      },
      {
        small: 12,
        medium: 6
      }
    ),
    new ChartDashboardItem(
      'Purchase success rate',
      {
        options: purchaseOption.options,
        series: purchaseOption.series,
        type: 'pie',
        height: 200
      },
      {
        small: 12,
        medium: 6
      }
    )
  ];
  const customComponentDashboardItems = [
    new CustomComponentDashboardItem(
      'Most Viewed Products',
      (<AnalyticsTable data={mostViewedProduct} />)
    ),
    new CustomComponentDashboardItem(
      'Number of Transactions',
      (
        <TransactionsAnalyticsTable
          tableData={transactionCountByProductAndCountry}
        />
      )
    ),
    new CustomComponentDashboardItem(
      'Number of Visitors',
      (<VisitorsAnalyticsTable tableData={visitorsCountByCountry} />)
    )
  ];
  const dashboardItems = {
    numericDashboardItems,
    chartDashboardItems,
    customComponentDashboardItems
  };

  useEffect(() => {
    setFormattedDashboardItems(formattedMultiSelectDashboardItems());
  }, []);

  useEffect(() => {
    const newVisibleDashboardItems = {
      numericDashboardItems: [],
      chartDashboardItems: [],
      customComponentDashboardItems: []
    };
    selectedDashboardItems &&
      selectedDashboardItems.map((eachSelectedDashboardItem) => {
        if (
          eachSelectedDashboardItem.title === 'No of Visitors' ||
          eachSelectedDashboardItem.title === 'Successful Purchase' ||
          eachSelectedDashboardItem.title === 'Unsuccessful Purchase' ||
          (eachSelectedDashboardItem.title === 'Page Views' &&
            eachSelectedDashboardItem instanceof NumericDashboardItem)
        ) {
          newVisibleDashboardItems.numericDashboardItems.push(
            eachSelectedDashboardItem
          );
        }
        if (
          (eachSelectedDashboardItem.title === 'Page Views' &&
            eachSelectedDashboardItem instanceof ChartDashboardItem) ||
          eachSelectedDashboardItem.title === 'Shop Traffic' ||
          eachSelectedDashboardItem.title === 'Devices' ||
          eachSelectedDashboardItem.title === 'Purchase success rate'
        ) {
          newVisibleDashboardItems.chartDashboardItems.push(
            eachSelectedDashboardItem
          );
        }
        if (
          eachSelectedDashboardItem.title === 'Most Viewed Products' ||
          eachSelectedDashboardItem.title === 'Number of Transactions' ||
          eachSelectedDashboardItem.title === 'Number of Visitors'
        ) {
          newVisibleDashboardItems.customComponentDashboardItems.push(
            eachSelectedDashboardItem
          );
        }
      });

    setVisibleDashboardItems(newVisibleDashboardItems);
    // handleAnalyticsVisibilityChange(newVisibleDashboardItems);
  }, [selectedDashboardItems]);

  const formattedMultiSelectDashboardItems = () => {
    const multiSelectOptions = [];
    Object.entries(dashboardItems).forEach(([key, value]) => {
      let titleKey = key.replace(/([A-Z])/g, ' $1');
      titleKey = titleKey.charAt(0).toUpperCase() + titleKey.slice(1);
      const items = value.map((eachValue) => ({
        label: eachValue.getTitle(),
        value: eachValue
      }));
      multiSelectOptions.push({
        label: titleKey,
        items
      });
    });

    return multiSelectOptions;
  };

  return (
    <>
      <MultiSelect
        value={selectedDashboardItems}
        className="analytics multiSelectDropdown mb-3"
        options={formattedDashboardItems}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        placeholder="Select Dashboard Items"
        maxSelectedLabels={4}
        onChange={(event) => setSelectedDashboardItems(event.value)}
      />
      <Row>
        {visibleDashboardItems.numericDashboardItems !== null &&
        visibleDashboardItems.numericDashboardItems !== undefined
          ? visibleDashboardItems.numericDashboardItems.length !== 0 &&
            visibleDashboardItems.numericDashboardItems.map((eachDashboard) =>
              eachDashboard.getDashboard()
            )
          : null}
      </Row>
      <Row>
        {visibleDashboardItems.chartDashboardItems !== null &&
        visibleDashboardItems.chartDashboardItems !== undefined
          ? visibleDashboardItems.chartDashboardItems.length !== 0 &&
            visibleDashboardItems.chartDashboardItems.map(
              (eachDashboard) =>
                eachDashboard.getChartType() !== 'pie' &&
                eachDashboard.getDashboard()
            )
          : null}
        <Col sm={12} md={12}>
          <Row>
            {visibleDashboardItems.chartDashboardItems !== null &&
            visibleDashboardItems.chartDashboardItems !== undefined
              ? visibleDashboardItems.chartDashboardItems.length !== 0 &&
                visibleDashboardItems.chartDashboardItems.map(
                  (eachDashboard) =>
                    eachDashboard.getChartType() === 'pie' &&
                    eachDashboard.getDashboard()
                )
              : null}
          </Row>
        </Col>
        {visibleDashboardItems.customComponentDashboardItems !== null &&
        visibleDashboardItems.customComponentDashboardItems !== undefined
          ? visibleDashboardItems.customComponentDashboardItems.length !== 0 &&
            visibleDashboardItems.customComponentDashboardItems.map(
              (eachDashboard) => eachDashboard.getDashboard()
            )
          : null}
      </Row>
    </>
  );
};
