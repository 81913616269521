/**
 * Product.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Product.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { uploadCall } from './Api';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { Plan } from 'braintree';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const createOrUpdate = async (product: any) => {
  const productData = {
    ...product,
    url: product.sku,
    store: 1
  };

  if (product._id) {
    // Update both image file and data.
    return uploadCall(`${BASE_API_URL}/products/${product._id}`, {
      file: product.file,
      data: productData
    });
  }

  const formDataRequest = new FormData();

  if (!isStringEmptyOrNullOrUndefined(product.file)) {
    formDataRequest.append('file', product.file, product.file['name']);
  }

  formDataRequest.append('data', JSON.stringify(productData));

  return httpClient.imageUpload(`/products`, formDataRequest);
};

export const createOrUpdateGlobalOptions = async (options: any) => {
  return httpClient.put(`/products/options/global`, options);
};

export const read = async (producSKUOrID: string) => {
  return httpClient.get(`/products/${producSKUOrID}`);
};

/**
 * Service to remove Product(s)
 * @param payload productIDs
 * @returns Promise
 */
export const remove = (payload: string[]) => {
  return httpClient.post(`/products/delete`, { productIDs: payload });
};

export const getProducts = async (
  skip = 0,
  limit = defaultTableLimit,
  searchTerm = '',
  sort = {
    sortField: 'createdAt',
    sortOrder: -1
  },
  isViewAllProducts: boolean,
  filter: Record<string, any>
) => {
  const url = paginationRequest(`/products/admin`, {
    skip,
    limit,
    searchTerm,
    filter
  });
  return httpClient.post(url, { sort, isViewAllProducts });
};

/**
 * Get product subscription
 * @param subscriptionPlanID
 * @returns
 */
export const getProductSubscription = async (
  subscriptionPlanID: string
): Promise<IAPIEntityResponse<Plan>> => {
  return await asyncErrorHandler(
    httpClient.get(`/products/${subscriptionPlanID}/subscriptionData`)
  );
};

/**
 * Service to copy product(s)
 * @param payload productIDs
 * @returns Promise
 */
export const copyProduct = (
  payload: string[]
): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post('/products/copy', { productIDs: payload });
};

/**
 * Service to get all products
 * @returns Promise<IAPIEntityResponse<IProduct[]>>
 */
export const getAllProducts = (): Promise<IAPIEntityResponse<IProduct[]>> => {
  return httpClient.get('/products/all');
};

/**
 * Service to get all products group by type
 * @returns Promise<IAPIEntityResponse<IProduct[]>>
 */
export const getAllProductsGroupByType = (): Promise<IAPIEntityResponse<
  IProduct[]
>> => {
  return httpClient.get('/products/allProductsGroupByType');
};
