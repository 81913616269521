/*
 * constants.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file constants.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

// Dynamic templates delimiters
export const startListTemplateDelimiter: string = `{{startList}}`;
export const endListTemplateDelimiter: string = `{{endList}}`;

// Minutes to milliseconds multiplier
export const minutesToMilliseconds: number = 60000;

export const defaultRenewalRefreshTokenPeriodInSeconds: number = 300; // 5 minutes
export const maximumCharactersAllowedInPassword: number = 100; /**< MaximumCharacters allowed in password */
export const minimumCharactersAllowedInPassword: number = 8; /**< MinimumCharacters allowed in password */