/**
 * Templates.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Templates.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import {getCssCall} from './Api';
import {BASE_API_URL} from '../Config';
import {HttpClient} from '@abstract/abstractwebcommon-client/utils/HttpClient';
import {createLog} from './Logs';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * API service to update templates.
 * @function
 */
export const update = (templateId: string, templateData: string) => {
  return httpClient.put(`/templates/${templateId}`, { template: templateData });
};

/**
 * API service to update templates.
 * @function
 */
export const updateCss = (cssData: string) => {
  return httpClient.put(`/templates/css`, { css: cssData });
};

/**
 * API service to get templates.
 * @function
 */
export const getAll = () => {
  return httpClient.get(`/templates`);
};

/**
 * API service to get one template.
 * @function
 */
export const get = (templateId: string) => {
  return httpClient.get(`${BASE_API_URL}/templates/${templateId}`);
};

/**
 * API service to get css content.
 * @function
 */
export const getCss = () => {
  const url = `${BASE_API_URL}/templates/css`;
  return getCssCall(url);
};

/**
 * API service to get master template.
 * @function
 */
export const getMaster = async () => {
  try {
    const response = await asyncErrorHandler(httpClient.get(`/templates/master`));
    if (!response.error) {
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
};
