/**
 * IPListForm.tsx (InstaLOD GmbH)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file IPListForm.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { Fieldset } from 'primereact/fieldset';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AutoComplete } from 'primereact/autocomplete';
import CidrRegex from 'cidr-regex';
import './IPListForm.css';
import { isIP } from 'is-in-subnet'
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import FieldSkeleton from '../SkeletonLoader/FieldSkeleton/FieldSkeleton';
import PopupInformation from '../FormControl/PopupInformation';

// Initial subnets
const initialIPv4SubnetPrefix: string[] = ['0', '8', '16', '24', '32'];
const initialIPv6SubnetPrefix: string[] = ['0', '16', '32', '48', '64', '80', '96', '112', '128'];
const invalidCIDRBlockSuggestion: ISuggestionOption[] = [{ name: 'Not a valid CIDR Block' }];

/**
 * @interface ISuggestionOption
 */
interface ISuggestionOption {
  name: string; /** name or label of the suggestion */
}

/**
 * IP List Form
 * 
 * @param properties
 * @returns JSX Element
 */
export const IPListForm = (properties: any): JSX.Element => {
  const { isLoading, showConfirmation, saveButtonClick, ipRanges } = properties;
  const translate: TFunction = useTranslation().t;
  // Initial suggestions
  const INITIAL_IPV4_VALUES: ISuggestionOption[] = initialIPv4SubnetPrefix.map(
    (IPv4Subnet: string) => ({
      name: `0.0.0.0/${IPv4Subnet}`
    })
  );
  const INITIAL_IPV6_VALUES: ISuggestionOption[] = initialIPv6SubnetPrefix.map(
    (IPv6Subnet: string) => ({
      name: `::/${IPv6Subnet}`
    })
  );
  const INITIAL_SUGGESTIONS: ISuggestionOption[] = INITIAL_IPV4_VALUES.concat(INITIAL_IPV6_VALUES);

  const [isChanged, setChanged] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>();
  const [filteredIPs, setFilteredIPs] = useState<ISuggestionOption[]>(INITIAL_SUGGESTIONS);
  const [selectedIPRanges, setSelectedIPRanges] = useState<string[]>([]);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [isInvalid, setInvalid] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');
  const [isDisabled, setDisabled] = useState<boolean>(false);

  // Regex to check if current value is number and dot (for ipv4) or hex and colon (for ipv6)
  const numberAndDotRegularExpression: RegExp = new RegExp('^[0-9\\.]*$');
  const hexadecimalAndColonRegularExpression: RegExp = new RegExp('^[a-fA-F0-9\\:]*$');
  const containsHexadecimalRegularExpression: RegExp = new RegExp('([A-Fa-f])+');

  // Assign fetched values to selectedIPs
  useEffect(() => {
    if (ipRanges !== undefined && ipRanges !== null) {
      if (ipRanges.includes('0.0.0.0/0')) {
        setDisabled(true);
        setCurrentValue('0.0.0.0/0 covers all IP Addresses');
      }
      setSelectedIPRanges(ipRanges);
      setSelected(true);
    }
  }, [ipRanges]);

  /**
   * suggest IPs
   * 
   * @param event
   * @returns void
   */
  const suggestIPs = (event: any): void => {
    const inputValue: string = event.query;
    const ipv4suggestion: string[] = inputValue.split('.');
    const ipv6suggestion: string[] = inputValue.split(':');

    // Set initial suggestions
    if (inputValue === '' || inputValue === undefined) {
      setFilteredIPs(INITIAL_SUGGESTIONS);
    }
    // Condition for common filtered values between IPv4 and IPv6
    else if (
      (ipv4suggestion[1] === undefined || ipv4suggestion[1] === '') &&
      (ipv6suggestion[1] === undefined || ipv6suggestion[1] === '') &&
      ipv4suggestion[0].length <= 4
    ) {
      const ipv4suggestionValue: string = `${ipv4suggestion[0] || '0'}.${ipv4suggestion[1] || '0'
        }.${ipv4suggestion[2] || '0'}.${ipv4suggestion[3] || '0'}`;
      // Handles cases where user inputs a colon to avoid having extra colons
      let colonValue: string = '';
      const lastCharacterOfInputValue: string =
        inputValue[inputValue.length - 1];
      if (lastCharacterOfInputValue === ':') {
        colonValue = inputValue[inputValue.length - 2] === ':' ? '' : ':';
      } else {
        colonValue = '::';
      }
      const IPv4SubnetPrefix: string[] = initialIPv4SubnetPrefix;
      const IPv6SubnetPrefix: string[] = initialIPv6SubnetPrefix;

      const ipv4suggestions: ISuggestionOption[] = IPv4SubnetPrefix.map(
        (prefix: string) => ({
          name: `${ipv4suggestionValue}/${prefix}`,
        })
      );
      const ipv6suggestions: ISuggestionOption[] = IPv6SubnetPrefix.map(
        (prefix: string) => ({
          name: `${inputValue}${colonValue}/${prefix}`,
        })
      );

      // input contains a dot
      if (lastCharacterOfInputValue === '.') {
        // Validate if inputValue contains anything other than number and dot
        if (numberAndDotRegularExpression.test(inputValue)) {
          // Only show IPv4 suggestions
          setFilteredIPs(ipv4suggestions);
        } else {
          setFilteredIPs(invalidCIDRBlockSuggestion);
        }
      } else if (
        lastCharacterOfInputValue === ':' || // input contains a colon
        containsHexadecimalRegularExpression.test(inputValue) || // input contains hexadecimal letters
        ipv6suggestion[0].length > 3 ||
        parseInt(ipv6suggestion[0]) > 255
      ) {
        // Validate if inputValue contains anything other than hex and colon
        if (hexadecimalAndColonRegularExpression.test(inputValue)) {
          // Only show IPv6 suggestions
          setFilteredIPs(ipv6suggestions);
        } else {
          setFilteredIPs(invalidCIDRBlockSuggestion);
        }
      } else {
        if (
          numberAndDotRegularExpression.test(inputValue) ||
          hexadecimalAndColonRegularExpression.test(inputValue)
        ) {
          // Common suggestions
          setFilteredIPs(ipv4suggestions.concat(ipv6suggestions));
        }
        else {
          setFilteredIPs(invalidCIDRBlockSuggestion);
        }
      }
      // IPv4
    } else if (
      numberAndDotRegularExpression.test(inputValue) &&
      ipv4suggestion.length <= 4 && // Only 4 octets
      ipv4suggestion.every(
        (ipv4suggestion: string) =>
          (ipv4suggestion === undefined || ipv4suggestion === '') ||
          (ipv4suggestion.length <= 3 && parseInt(ipv4suggestion) <= 255)
      ) // Octet does not exceed 255 characters
    ) {
      const ipv4suggestionValue: string = `${ipv4suggestion[0] || '0'}.${ipv4suggestion[1] || '0'
        }.${ipv4suggestion[2] || '0'}.${ipv4suggestion[3] || '0'}`;

      // Gets all prefixes except 0
      const subnetPrefix: string[] = initialIPv4SubnetPrefix;

      setFilteredIPs(
        subnetPrefix.map((prefix: string) => ({
          name: `${ipv4suggestionValue}/${prefix}`,
        }))
      );
    }
    // IPv6
    else if (
      hexadecimalAndColonRegularExpression.test(inputValue) &&
      ipv6suggestion.length <= 8 && // Only 8 octets
      ipv6suggestion.every((ipv4suggestion: string) => ipv4suggestion.length <= 4) // 4 digits maximum per octet
    ) {
      // Handles cases where user inputs a colon to avoid having extra colons
      let colonValue: string = '';
      const lastCharacterOfInputValue: string =
        inputValue[inputValue.length - 1];
      if (lastCharacterOfInputValue === ':') {
        colonValue = inputValue[inputValue.length - 2] === ':' ? '' : ':';
      } else {
        colonValue = '::';
      }
      const subnetPrefix: string[] = initialIPv6SubnetPrefix;
      setFilteredIPs(
        subnetPrefix.map((prefix: string) => ({
          name: `${inputValue}${colonValue}/${prefix}`,
        }))
      );
    } else if (inputValue.includes('/')) {
      const ipAddressAndSubnet: string[] = inputValue.split('/');
      const ipType: number = isIP(ipAddressAndSubnet[0]);
      const currentInputSubnet: string = ipAddressAndSubnet[1];
      const currentValueSuggestion: ISuggestionOption = { name: inputValue };

      if (ipType === 0) {
        // Invalid IP address
        setFilteredIPs(invalidCIDRBlockSuggestion);
      } else if (ipType === 4) {
        // IPv4 address
        const subnetSuggestions: ISuggestionOption[] =
          initialIPv4SubnetPrefix.map((prefix: string) => {
            if (
              prefix === currentInputSubnet ||
              prefix.includes(currentInputSubnet)
            ) {
              return { name: `${ipAddressAndSubnet[0]}/${prefix}` };
            }
          });
        const validSuggestions: ISuggestionOption[] = subnetSuggestions.filter(
          (eachSuggestion) => eachSuggestion !== undefined
        );
        if (validSuggestions !== undefined && validSuggestions.length > 0) {
          setFilteredIPs([currentValueSuggestion, ...validSuggestions]);
        } else {
          if (
            parseInt(currentInputSubnet) !== undefined &&
            parseInt(currentInputSubnet) <= 32
          ) {
            setFilteredIPs([currentValueSuggestion]);
          } else {
            setFilteredIPs(invalidCIDRBlockSuggestion);
          }
        }
      } else if (ipType === 6) {
        // IPv6 address
        const subnetSuggestions: ISuggestionOption[] =
          initialIPv6SubnetPrefix.map((prefix: string) => {
            if (
              prefix === currentInputSubnet ||
              prefix.includes(currentInputSubnet)
            ) {
              return { name: `${ipAddressAndSubnet[0]}/${prefix}` };
            }
          });
        const validSuggestions: ISuggestionOption[] = subnetSuggestions.filter(
          (eachSuggestion) => eachSuggestion !== undefined
        );
        if (validSuggestions !== undefined && validSuggestions.length > 0) {
          setFilteredIPs(validSuggestions);
        } else {
          if (
            parseInt(currentInputSubnet) !== undefined &&
            parseInt(currentInputSubnet) <= 128
          ) {
            setFilteredIPs([currentValueSuggestion]);
          } else {
            setFilteredIPs(invalidCIDRBlockSuggestion);
          }
        }
      } else {
        setFilteredIPs(invalidCIDRBlockSuggestion);
      }
    } else {
      setFilteredIPs(invalidCIDRBlockSuggestion);
    }
  };

  /**
   * Handles item removal
   * 
   * @param itemIndex number
   * @returns void
   */
  const onRemoveItem = (itemIndex: number): void => {
    const filteredIPRanges: string[] = selectedIPRanges.filter((item, index) => index !== itemIndex); /**< Filtered IP ranges */
    setSelectedIPRanges(filteredIPRanges);
    if (selectedIPRanges.length === 0) {
      setInvalid(false);
      setSelected(false);
    }
    setDisabled(false);
    if(ipRanges && [...ipRanges].sort().toString() !== filteredIPRanges.sort().toString()) {
      setChanged(true);
    } else {
      setChanged(false);
    }
    setCurrentValue('');
  };

  /**
   * Checks CIDR Validity
   * 
   * @param value string
   * @returns boolean
   */
  const checkIsValid = (value: string): boolean => {
    if (value === '') {
      return false;
    }
    if (!CidrRegex({ exact: true }).test(value)) {
      setValidationError(translate('awc:/.whitelistedIPsFieldset.validationError'));
      setChanged(false);
      setCurrentValue('');
      setInvalid(true);
      return false;
    }
    return true;
  };

  /**
   * Adds selected item if valid
   * 
   * @param value string
   * @returns void
   */
  const addToItems = (value: string): void => {
    const isValid: boolean = checkIsValid(value);
    setInvalid(!isValid);

    if (isValid) {
      if (value === '0.0.0.0/0') {
        setSelectedIPRanges([value]);
        setDisabled(true);
        setCurrentValue('0.0.0.0/0 covers all IP Addresses');
      } else {
        setSelectedIPRanges(Array.from(new Set([...selectedIPRanges, value])));
        setCurrentValue('');
      }
      if(!ipRanges || ipRanges && !ipRanges.includes(value)) {
        setChanged(true);
      } else {
        setChanged(false);
      }
    }
  };

  /**
   * Handles when an option is selected
   * 
   * @param event string
   * @returns void
   */
  const onSelect = (event: any): void => {
    let selectedValue: string = event.value.name;

    if (selectedValue === 'Not a valid CIDR Block' || selectedValue === '') {
      setCurrentValue('');
      return;
    }
    setInvalid(false);
    setSelected(true);
    addToItems(selectedValue as string);
  };

  /**
   * Filters out duplicate suggestions
   * @param suggestionOptions Array of suggestions to filter
   * @returns Distinct suggestion options array
   */
  const filterDuplicateSuggestions = (
    suggestionOptions: ISuggestionOption[]
  ): ISuggestionOption[] => {
    return (
      suggestionOptions !== undefined &&
      Array.isArray(suggestionOptions) &&
      suggestionOptions.filter(
        (value, index, self) =>
          index ===
          self.findIndex((eachSuggestion) => eachSuggestion.name === value.name)
      )
    );
  };

  return (
    <>
      <Col xs="12" className="px-0">
        <Fieldset legend={properties.isWhitelistedIPs ? 
            translate('awc:/.whitelistedIPsFieldset.legend') :  
            translate('awc:/.blacklistedIPsFieldset.legend')
        }>
          <Row>
            <Col xs={12}>
              <label htmlFor={properties.isWhitelistedIPs ? 'whitelistedIPs' : 'blacklistedIPs'}>
                {properties.isWhitelistedIPs ? 
                  translate('awc:/.whitelistedIPsFieldset.label') :
                  translate('awc:/.blacklistedIPsFieldset.label')
                }
              </label>
              <PopupInformation 
                id={properties.isWhitelistedIPs ? "whitelistedIPs": "blacklistedIPs"} 
                popupText={properties.isWhitelistedIPs ?
                  translate('awc:/.whitelistedIPsFieldset.popupText') :
                  translate('awc:/.blacklistedIPsFieldset.popupText')
                }
              />
              <AutoComplete
                dropdown
                disabled={isDisabled}
                className="w-100"
                value={currentValue}
                suggestions={filterDuplicateSuggestions(filteredIPs)}
                completeMethod={suggestIPs}
                onSelect={onSelect}
                onBlur={(event: any) => {
                  onSelect({ value: { name: event.target.value } })
                }}
                field="name"
                onChange={(event: any) => setCurrentValue(event.value)}
              />
              {properties.isLoading && (<FieldSkeleton className='mt-2'/>)}
              {isSelected && (
                <>
                  <div className="d-flex justify-content-start flex-wrap mt-2">
                    {selectedIPRanges &&
                      selectedIPRanges.map &&
                      selectedIPRanges.map((selectedIPRange: string, selectedIPRangeIndex: number) => {
                        return (
                          <>
                            {' '}
                            <div className="p-tag mx-1 mb-2 selectedIP">
                              {selectedIPRange}{' '}
                              <span
                                className="pi pi-times-circle clickable"
                                onClick={(event: any) => onRemoveItem(selectedIPRangeIndex)}
                              />
                            </div>{' '}
                          </>
                        );
                      })}
                  </div>
                </>
              )}
              {isInvalid ? (
                <small id={`whitelistedIPs-invalid`} className="p-invalid">
                  {validationError}
                </small>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="clearfix pb-0">
              <Button
                className={`w-100 ${isLoading || showConfirmation || !isChanged ? 'custom-disabled-button' : ''}`}
                onClick={(event) => {
                  setChanged(false);
                  if (saveButtonClick) {
                    return saveButtonClick(event, selectedIPRanges);
                  }
                }}
                disabled={isLoading || showConfirmation || !isChanged}
              >
                {translate('awc:/.whitelistedIPsFieldset.save')}
              </Button>
            </Col>
          </Row>
        </Fieldset>
      </Col>
    </>
  );
};
