/*
 * Users.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Users.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  getAuthenticatedUserInformation,
  createOrUpdateAddressAPI,
  getAllUsers
} from '../Services/Users';
import { getUserProfile } from '../Services/UserAPI';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {
  IUser,
  IUserProfile
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const GET_AUTHENTICATED_USER_REQUEST = 'user/authenticated/request';
const GET_AUTHENTICATED_USER_SUCCESS = 'user/authenticated/success';
const GET_AUTHENTICATED_USER_FAILURE = 'user/authenticated/failure';

const GET_USER_PROFILE_INFORMATION_REQUEST =
  'user/get/profile-information/request';
const GET_USER_PROFILE_INFORMATION_SUCCESS =
  'user/get/profile-information/success';
const GET_USER_PROFILE_INFORMATION_FAILURE =
  'user/get/profile-information/failure';

const ADD_ADDRESS_REQUEST = 'address/add/request';
const ADD_ADDRESS_SUCCESS = 'address/add/success';
const ADD_ADDRESS_FAILURE = 'address/add/failure';

/// Get all users without pagination
const GET_ALL_USERS_WITHOUT_PAGINATION_REQUEST = 'users/all/request';
const GET_ALL_USERS_WITHOUT_PAGINATION_SUCCESS = 'users/all//success';
const GET_ALL_USERS_WITHOUT_PAGINATION_FAILURE = 'users/all/failure';

const INITIAL_STATE = {
  list: [],
  listIsFetching: false,
  user: null,
  userIsFetching: false,
  userIsChanging: false,
  userDialogOpened: false,
  isAddressChanging: false,
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  },
  userList: [] /**< All users */,
  isUserListFetching: false,
  successReturn: null,
  errorReturn: null,
  isUserProfileInformationLoading: null /**< Defines if is requesting the endpoint to retrieve user profile information */,
  userProfileInformation: null /**< User profile information to render in the Profile Card of the Sidebar component */
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AUTHENTICATED_USER_REQUEST:
      return { ...state, userIsFetching: true };
    case GET_AUTHENTICATED_USER_SUCCESS:
      return { ...state, userIsFetching: false };
    case GET_AUTHENTICATED_USER_FAILURE:
      return { ...state, userIsFetching: false };
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        isAddressChanging: true,
        errorReturn: null,
        successReturn: null
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        isAddressChanging: false,
        successReturn: action.payload.successReturn,
        errorReturn: null
      };
    case ADD_ADDRESS_FAILURE:
      return {
        ...state,
        isAddressChanging: false,
        errorReturn: action.payload.errorReturn,
        successReturn: null
      };
    case GET_ALL_USERS_WITHOUT_PAGINATION_REQUEST:
      return { ...state, isUserListFetching: true };
    case GET_ALL_USERS_WITHOUT_PAGINATION_SUCCESS:
      return { ...state, isUserListFetching: false, ...action.payload };
    case GET_ALL_USERS_WITHOUT_PAGINATION_FAILURE:
      return { ...state, isUserListFetching: false };
    case GET_USER_PROFILE_INFORMATION_REQUEST:
      return { ...state, isUserProfileInformationLoading: true };
    case GET_USER_PROFILE_INFORMATION_SUCCESS:
      return {
        ...state,
        isUserProfileInformationLoading: false,
        userProfileInformation: action.payload
      };
    case GET_USER_PROFILE_INFORMATION_FAILURE:
      return { ...state, isUserProfileInformationLoading: false };
    default:
      return state;
  }
};

const getUserProfileInformationRequest = () => ({
  type: GET_USER_PROFILE_INFORMATION_REQUEST
});

const getUserProfileInformationSuccess = (
  userProfileInformation: IUserProfile
) => ({
  type: GET_USER_PROFILE_INFORMATION_SUCCESS,
  payload: userProfileInformation
});

const getUserProfileInformationFailure = () => ({
  type: GET_USER_PROFILE_INFORMATION_FAILURE
});

const getAuthenticatedUserRequest = () => ({
  type: GET_AUTHENTICATED_USER_REQUEST
});

const getAuthenticatedUserSuccess = () => ({
  type: GET_AUTHENTICATED_USER_SUCCESS
});

const getAuthenticatedUserFailure = () => ({
  type: GET_AUTHENTICATED_USER_FAILURE
});

const addAddressRequest = () => ({
  type: ADD_ADDRESS_REQUEST
});

const addAddressSuccess = (successMessage: string) => ({
  type: ADD_ADDRESS_SUCCESS,
  payload: { successReturn: successMessage }
});

const addAddressFailure = (errorMessage: string) => ({
  type: ADD_ADDRESS_FAILURE,
  payload: { successReturn: errorMessage }
});

/// Get all users without pagination
const getAllUsersWithoutPaginationRequest = () => ({
  type: GET_ALL_USERS_WITHOUT_PAGINATION_REQUEST
});

const getAllUsersWithoutPaginationSuccess = (result: IUser[]) => ({
  type: GET_ALL_USERS_WITHOUT_PAGINATION_SUCCESS,
  payload: { userList: result }
});

const getAllUsersWithoutPaginationFailure = () => ({
  type: GET_ALL_USERS_WITHOUT_PAGINATION_FAILURE
});

export const getUserProfileInformationAction = () => async (dispatch) => {
  try {
    dispatch(getUserProfileInformationRequest());
    const result: IAPIEntityResponse<IUserProfile> = await asyncErrorHandler(
      getUserProfile()
    );

    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getUserProfileInformationFailure());
    } else {
      dispatch(getUserProfileInformationSuccess(result.data!));
    }
  } catch (error) {
    dispatch(getUserProfileInformationFailure());
    handleError({ message: error.message });
  }
};

export const getAuthenticatedUser = (token, successCallBack) => async (
  dispatch
) => {
  try {
    dispatch(getAuthenticatedUserRequest());
    const result = await asyncErrorHandler(
      getAuthenticatedUserInformation(token)
    );
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getAuthenticatedUserFailure());
    } else {
      dispatch(getAuthenticatedUserSuccess());
      successCallBack(result);
    }
  } catch (error) {
    dispatch(getAuthenticatedUserFailure());
    handleError({ message: error.message });
  }
};

/**
 * Create Address
 */
export const createOrUpdateAddress = (data, addressUUID) => async (
  dispatch
) => {
  try {
    dispatch(addAddressRequest());
    const result = await asyncErrorHandler(
      createOrUpdateAddressAPI(data, addressUUID)
    );
    if (result.error) {
      dispatch(addAddressFailure(result.error.message ?? ''));
      handleError({ message: result.error.message });
    } else {
      dispatch(addAddressSuccess(result.message ?? ''));
      showSuccessToast(result.message ?? '');
    }
  } catch (e) {
    dispatch(addAddressFailure(e.message ?? ''));
    handleError({ message: e.message });
  }
};

/**
 * Get all users without pagination
 */
export const getAllUsersWithoutPagination = (
  payload: Record<string, any>
) => async (dispatch: any) => {
  const errorMessage: string =
    'An error occured while fetching all users from user backend.';
  try {
    dispatch(getAllUsersWithoutPaginationRequest());
    const result: IAPIEntityResponse<IUser[]> = await asyncErrorHandler(
      getAllUsers(payload)
    );
    if (result.error) {
      handleError({ message: result?.error?.message || errorMessage });
      dispatch(getAllUsersWithoutPaginationFailure());
    } else {
      dispatch(getAllUsersWithoutPaginationSuccess(result.userData));
    }
  } catch (error) {
    dispatch(getAllUsersWithoutPaginationFailure());
    handleError({ message: error?.message || errorMessage });
  }
};
