/*
 * SettingsLogoUpload.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file SettingsLogoUpload.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import InstaImageUpload, {
  RemoveButtonStyle
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';

/**
 * Interface for SettingsLogoUpload properties.
 */
interface ISettingsLogoUploadProperties {
  logo: string /**< Logo Id. */;
  uploadLogo: (event: any) => Promise<void> /**< Upload Logo Handler. */;
  deleteLogo: (event: any) => void /**< Delete Logo Handler. */;
  errorHandler: (error: string) => void /**< Error Handler. */;
  displayCroppedLogo: Blob | null /**< Cropped Logo. */;
  children: React.ReactNode /** Children elements */;
  changeInputClassName: (
    isFileField?: boolean
  ) =>
    | string
    | undefined /**< Control when to display the field in the form based on data loading */;
}

const SettingsLogoUpload = (properties: ISettingsLogoUploadProperties) => {
  const translation: TFunction = useTranslation().t;
  const [toggleFullSizePreviewLogo, setToggleFullSizePreviewLogo] = useState<
    boolean
  >(false); /**< Full Size Preview. */

  /// Get logo div
  const getLogo = (): JSX.Element => {
    return (
      <>
        <div id="logo" className="flex-grow-1 input-fixed-height">
          <label htmlFor="logo" className="flex-grow-1">
            {translation('setting.headers.change_logo')}
          </label>
          <PopupInformation
            id={'logo'}
            popupText={translation('setting.headers.logo_popupText')}
          />
          <div
            className={`p-2 p-inputtext ${properties.changeInputClassName(
              true
            )}`}
          >
            <InstaImageUpload
              showLegend={false}
              imageUrl={properties.logo}
              showDelete={true}
              removeButtonStyle={RemoveButtonStyle.SEPARATE}
              deleteHandler={properties.deleteLogo}
              isHorizontalLayout
              onChange={properties.uploadLogo}
              errorHandler={properties.errorHandler}
              imgClass={'imageUrl rounded'}
              altText={translation('insta_image.logo_alt')}
              plainBtnLabel={translation('generic_button_choose_file')}
              isPlainBtn={true}
              setToggleFullSizePreview={setToggleFullSizePreviewLogo}
              toggleFullSizePreview={toggleFullSizePreviewLogo}
              croppedImage={properties.displayCroppedLogo}
            />
          </div>
          {properties.children}
        </div>
      </>
    );
  };

  return <>{getLogo()}</>;
};

export default SettingsLogoUpload;
