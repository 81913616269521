/**
* CustomerBillingDetailsTable.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file CustomerBillingDetailsTable.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { decode } from 'html-entities';

import { getTaxIDLabelForCountry } from 'src/Utils/BillingEntity';

import './Customer.css';

export const CustomerBillingDetailsTable = ({ className, customer }) => {
  const { t } = useTranslation();

  return (
    <table className={classnames('table', className)}>
      <tbody>
        {customer.billingCompanyName !== '' && (
          <tr>
            <td className="billing-company-col">
              <b>Company:</b>
            </td>
            <td className="billing-details text-right">
              {decode(customer.billingCompanyName)}
            </td>
          </tr>
        )}
        <tr>
          <td className="billing-description-col text-left border-top-0">
            <b>{t('fields.customer.address')}: </b>
          </td>
          <td className="billing-details text-right border-top-0">
            {customer.billingAddress1}
            <br />
            {customer.billingCity} {customer.billingZipcode}
            <br />
            {customer.billingStateOrProvince} {customer.billingCountry}
          </td>
        </tr>
        {customer.billingAddress2 && (
          <tr>
            <td className="billing-description-col billing-address2-description-col text-left custom-container-address-label">
              <b>{t('fields.customer.address2')}: </b>
            </td>
            <td className="billing-details text-right">
              {customer.billingAddress2}
            </td>
          </tr>
        )}
        <tr>
          <td className="billing-description-col text-left">
            <b>{getTaxIDLabelForCountry(customer.billingCountry, t)}: </b>
          </td>
          <td className="billing-details text-right">
            {customer.billingVatID ?? customer.vatId}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
