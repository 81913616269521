/*
 * Language.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Language.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @interface ILanguageData
 */
export interface ILanguageData {
  value: string; /**< Value */
  label: string; /**< Label */
  imageURL: string; /**< Image URL */
}

/**
 * @enum LanguageSettingsMode
 */
export enum LanguageSettingsMode {
  english = 'en',
  german = 'de',
  japanese = 'ja',
  chinese = 'zh',
  korean = 'ko'
}

/**
 * Available languages
 */
export const Languages: ILanguageData[] = [
  {
    value: LanguageSettingsMode.english,
    label: 'EN',
    imageURL: 'https://instamaterial.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/en_US.png'
  },
  {
    value: LanguageSettingsMode.german,
    label: 'DE',
    imageURL: 'https://instamaterial.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/de_DE.png'
  },
  {
    value: LanguageSettingsMode.japanese,
    label: 'JA',
    imageURL: 'https://instamaterial.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/ja.png'
  },
  {
    value: LanguageSettingsMode.chinese,
    label: 'ZH',
    imageURL: 'https://instamaterial.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/zh_CN.png'
  },
  {
    value: LanguageSettingsMode.korean,
    label: 'KO',
    imageURL: 'https://instamaterial.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/ko_KR.png'
  },
];