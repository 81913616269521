/**
 * UserAPI.ts (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file UserAPI.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  IAPIEntityResponse,
} from "@abstract/abstractwebcommon-shared/interfaces/api";
import { HttpClient } from "../utils/HttpClient";
import { CreateErrorLog } from "@abstract/abstractwebcommon-shared/utils/CreateErrorLog";
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

let httpClient: HttpClient = null;

export class UserAPI {

  constructor(baseApiUrl: string, onCreateErrorLog: (payload: CreateErrorLog) => void) {
    httpClient = new HttpClient(baseApiUrl, onCreateErrorLog);
  }

  /**
   * Update user theme mode.
   * @param userUUID User UUID to update theme mode
   * @param themeMode Current application theme mode saved by User
   * @returns Promise
   */
  setUserThemeModeStateAPI = async (userUUID: string, themeMode: string): Promise<IAPIEntityResponse<IUser>> => {
    return httpClient.put<IUser>('/user/update/themeMode', {
      userUUID,
      themeMode
    })
  };

  /**
   * Update user language preference
   * @param userUUID User UUID to update language settings mode
   * @param languageSettingsMode Current application language settings mode saved by User
   * @returns Promise<IAPIEntityResponse<IUser>>
   */
  setUserLanguageSettingsModeAPI = async (userUUID: string, languageSettingsMode: string): Promise<IAPIEntityResponse<IUser>> => {
    return httpClient.put(`/user/update/languageSettingsMode`, {
      userUUID,
      languageSettingsMode
    });
  };
};


