/*
 * TransactionSuccessPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file TransactionSuccessPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import EmailConfirmationForm from './EmailConfirmationForm';
import TransactionDetails from './TransactionDetails';
import { getTransaction } from '../../../Store/Transactions';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../../Services/Logs';
import ShopWrapper from '../ShopWrapper';
import Row from 'react-bootstrap/Row';

/**
 * Interface for TransactionSuccessPage properties.
 */
interface ITransactionSuccessPageProperties {
  isPublicRoute: boolean /**< Is Public Route */;
  shopHeaderHTML: string /**< ShopHeader HTML */;
  shopFooterHTML: string /**< ShopFooter HTML */;
  languageSelector: JSX.Element /**< Language selector element */;
}

const TransactionSuccessPage = ({
  isPublicRoute = false,
  shopHeaderHTML = '',
  shopFooterHTML = '',
  languageSelector
}: ITransactionSuccessPageProperties) => {
  const { transactionId } = useParams();
  const [isTransactionSummaryLoading, setTransactionSummaryLoading] = useState<
    boolean
  >(true); /**< Transaction summary loading state. */

  const dispatch = useDispatch();
  const {
    transactionData: transaction,
    transactionIsFetching,
    error
  } = useSelector((state) => state.transactions);
  const customer = transaction.customer && transaction.customer[0];
  const transactionEmail = window.sessionStorage.getItem('transactionEmail');

  // We shouldn't allow any user access this page in case the payment of the transaction has failed.
  // Only a technical person would try to access the URL of this page directly in the browser, for this, I think the alert is enough.
  // A regular user can only access this page by the Transaction table page or by the email link when the payment is successful.
  if (transaction && transaction.failureReasonID) {
    alert(`Due to failed payment, this page isn't available.`);
    return <></>;
  }

  useEffect(() => {
    if (transactionEmail) {
      dispatch(getTransaction(transactionId, decodeURI(transactionEmail)));
    } else {
      setTransactionSummaryLoading(false);
    }
  }, [getTransaction, transactionId, transactionEmail]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const dataReady = Object.keys(transaction).length !== 0;

  /// Get TransactionSuccessPage Container
  const getTransactionSuccessPageContainer = () => {
    return (
      <>
        <Container className="p-1 p-md-4">
          <Row className="float-right languageSelector">{languageSelector}</Row>
        </Container>
        <Container className="p-1 p-md-4">
          {!dataReady && transactionEmail && <Loader />}
          {!transactionEmail && (
            <EmailConfirmationForm
              transactionId={transactionId}
              loading={transactionIsFetching}
            />
          )}
          {dataReady && (
            <TransactionDetails
              transaction={transaction}
              customer={customer}
              setTransactionSummaryLoading={setTransactionSummaryLoading}
            />
          )}
        </Container>
      </>
    );
  };

  /// TransactionSuccessPage in public route.
  if (isPublicRoute) {
    if (
      (!dataReady && transactionEmail) ||
      isTransactionSummaryLoading ||
      transactionIsFetching
    ) {
      document.documentElement.setAttribute('hidden', 'hidden');
      document.body.classList.add('invisible');
    } else {
      document.documentElement.removeAttribute('hidden');
      document.body.classList.remove('invisible');
    }

    return (
      <ShopWrapper
        shopHeaderHTML={shopHeaderHTML}
        shopFooterHTML={shopFooterHTML}
      >
        {getTransactionSuccessPageContainer()}
      </ShopWrapper>
    );
  }

  return <>{getTransactionSuccessPageContainer()}</>;
};

export default withErrorBoundary(TransactionSuccessPage, createLog);
