/**
 * Api.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Api.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { CreateErrorLog } from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';
import { BASE_API_URL } from '../Config';
import { createLog } from './Logs';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { IAPIErrorData } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { customLocalStorageClearUtil } from '../Utils/localStorageUtils';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const uploadCall = async (url: string, payload: any) => {
  const file: Blob = payload.file; /**<Image file. */
  const data: any = payload.data; /**<Data to update.. */
  const fd = new FormData();
  if (file) {
    fd.append('file', file, file['name']);
  }
  if (data) {
    fd.append('data', JSON.stringify(data));
  }

  const authToken = LocalStorage.getXAuthToken();

  // Don't allow to upload if it doesn't have accesstoken.
  if (!authToken) {
    customLocalStorageClearUtil();
    sessionStorage.clear();
    window.location.href = '/login#session-timeout';
    return;
  }

  const config = {
    method: 'PUT',
    headers: new Headers({
      Authorization: `Bearer ${LocalStorage.getXAuthToken()}`
    }),
    body: fd
  };

  const result = await asyncErrorHandler(fetch(url, config));

  if (!result.ok) {
    const body = createErrorLog(result);
    return body;
  }

  return result.json();
};

/**
 * API service to receive a client token to generate payment nonce.
 * We also get the user IP address to inform in the 'threeDSecure' object from Braintree.
 * @function
 */
export const getToken = async () => {
  return await asyncErrorHandler(httpClient.get(`/get-token`));
};

/**
 * API service to submit payment nonce for processing the payment.
 * @param {string} nonce - Nonce passed back from Braintree on payment submission.
 */
export const relayNonce = (data: any) => {
  if (data && data.type === 'manual') {
    return httpClient.post(`/admin/process-nonce`, data);
  } else {
    return httpClient.post(`/process-nonce`, data, {}, false);
  }
};

/**
 * API service to get css.
 * @fucntion
 */
export const getCssCall = async (url: string) => {
  const result = await asyncErrorHandler(fetch(url));
  if (!result.ok) {
    const body = createErrorLog(result);
    return body;
  }

  return result.text();
};

/**
 * load create style or script link.
 * @param url link to the style or script.
 * @param id style or script id.
 * @param style True if style link is to be create and false otherwise.
 * @param script True if script link is to be create and false otherwise.
 */
export const load = (url: string, id: string, style = true, script = false) => {
  if (style) {
    //Note: Remove current CSS link to avoid stacking unnecessary stylesheets and overrides.
    const cssLinkToRemove = document.getElementById(id);

    if (cssLinkToRemove) {
      cssLinkToRemove.remove();
    }
    cssLinkToRemove?.remove();

    const link = document.createElement('link');
    if (id !== '') link.id = id;
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  }
  if (script) {
    const link = document.createElement('script');
    if (id !== '') link.id = id;
    link.src = url;
    document.head.appendChild(link);
  }
};

/**
 * Create Error Log.
 * @param reponse Fetch response.
 * @return error
 */
const createErrorLog = async (reponse: Response): Promise<IAPIErrorData> => {
  // If response is not ok, create Error Log.
  const statusCode: number = reponse.status; // Response status code
  const statusText: string = reponse.statusText; // Response status text
  const fetchURL: string = reponse.url; // Fetch URL

  const logPayload: CreateErrorLog = {
    error: `${statusCode} ${statusText} in ${fetchURL}`
  }; /**< Log Payload */

  let body;
  try {
    body = await asyncErrorHandler(reponse.json());
    logPayload.error = `${JSON.stringify(body)} in ${fetchURL}`;
    logPayload.errorMessage = body.error.message ?? body.error;
    createLog(logPayload); /**< Create Log with response body. */
    return body;
  } catch (error) {
    logPayload.errorMessage = error as string;
    createLog(logPayload); /**< Create Log with reponse status details. */
    throw new Error(reponse.statusText);
  }
};

/**
 * API service to send an invoice email after transaction.
 * @param {string} transactionID - transactionID.
 */
export const sendInvoice = async (transactionID: string) => {
  return httpClient.get(`/sendInvoice/${transactionID}`);
};

/**
 * API service to process free product transaction.
 * @param {string} transactionID - transactionID.
 */
export const processFreeProductTransactionAPI = async (
  transactionID: string
) => {
  return httpClient.get(`/process/freeProduct/${transactionID}`);
};

/**
 * API service to check if the user has already purchased this product.
 * @param {string} productID - productID.
 *  @param {string} userUUID - userUUID.
 */
export const toCheckProductAlreadyPurchasedAPI = async (
  productID: string,
  userUUID: string
) => {
  return httpClient.get(
    `/toCheckProductAlreadyPurchased?productID=${productID}&userUUID=${userUUID}`
  );
};
