/*
 * SettingsPermittedCountriesOptions.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file SettingsPermittedCountriesOptions.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Panel } from 'primereact/panel';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import Button from 'react-bootstrap/Button';
import { ICountryTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/CountryTranslation';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * Interface for SettingsPermittedCountriesOptions properties.
 */
interface ISettingsPermittedCountriesOptionsProperties {
  values: any;
  errors: any;
  handleChange: any;
  loading: boolean;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  translationData:
    | ICountryTranslation[]
    | undefined /**< Country translation data */;
  translationErrors: any /**< Country translation errors */;
  translationTouched: any /**< Country translation touched */;
}

const SettingsPermittedCountriesOptions = ({
  values,
  errors,
  handleChange,
  loading,
  handleBlur,
  touched,
  setFieldValue,
  translationData,
  translationErrors,
  translationTouched
}: ISettingsPermittedCountriesOptionsProperties) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    translationData?.findIndex(
      (data: ICountryTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = translationData?.findIndex(
    (data: ICountryTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */

  useEffect(() => {
    setSelectedLanguageTranslationIndex(
      translationData?.findIndex(
        (data: ICountryTranslation) => data.language === selectedLanguage
      )
    );
  }, [selectedLanguage, translationData]);

  const renderOptionList = (formikArray) =>
    values.map((option, index) => (
      <Row key={index}>
        <div className="w-100 d-flex mt-2">
          <Col sm={9} className="d-flex align-items-center mt-2">
            <Form.Group>
              <Checkbox
                inputId={`options.${index}.value`}
                sizing={{ sm: 2 }}
                label="Value"
                checked={option.value}
                onChange={(e) => {
                  setFieldValue(`options.${index}.value`, !option.value);
                }}
                fieldName={`options.${index}.value`}
              />
              <Form.Label htmlFor={`options.${index}.value`} className="ml-2">
                {t('fields.countrySettings.defaultValue')}
              </Form.Label>
            </Form.Group>
          </Col>
          <Col>
            <Button
              className="p-button-raised p-button-primary float-right"
              onClick={() => {
                formikArray.remove(index);
                // Remove corresponding translations
                if (translationData && translationData.length) {
                  for (
                    let translationIndex = 0;
                    translationIndex < translationData.length;
                    translationIndex++
                  ) {
                    const newArray = translationData[
                      translationIndex
                    ].checkoutConditionsDescription?.filter(
                      (condition: string, conditionIndex: number) =>
                        conditionIndex !== index
                    );
                    setFieldValue(
                      `translationData.${translationIndex}.checkoutConditionsDescription`,
                      newArray
                    );
                  }
                }
              }}
            >
              <i className="bi bi-dash btn-icon mr-0"></i>
            </Button>
          </Col>
        </div>
        <Form.Group as={Col} md="10" sm="9">
          <InstaInputText
            label={t('fields.countrySettings.description')}
            name={`translationData.${selectedLanguageTranslationIndex}.checkoutConditionsDescription.${index}`}
            id={`translationData.${selectedLanguageTranslationIndex}.checkoutConditionsDescription.${index}`}
            isLoading={loading}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              (translationData &&
                translationData.length &&
                translationData[selectedLanguageTranslationIndex] &&
                translationData[selectedLanguageTranslationIndex]
                  .checkoutConditionsDescription?.[index]) ??
              ''
            }
            touched={
              translationTouched &&
              translationTouched.length &&
              translationTouched[selectedLanguageTranslationIndex] &&
              translationTouched[selectedLanguageTranslationIndex]
                .checkoutConditionsDescription?.[index]
            }
            errors={
              translationErrors &&
              translationErrors.length &&
              translationErrors[selectedLanguageTranslationIndex] &&
              translationErrors[selectedLanguageTranslationIndex]
                .checkoutConditionsDescription?.[index]
            }
            placeholder={t('fields.countrySettings.description')}
          />
        </Form.Group>
      </Row>
    ));

  return (
    <Panel
      header={t('fields.countrySettings.customCondition_header')}
      className="mt-3 additional-options-panel"
    >
      <Row>
        <Form.Group as={Col} sm="9" md="10" className="pt-2">
          <LanguageSelector
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </Form.Group>
      </Row>
      <FieldArray
        name="options"
        render={(formikArray) => (
          <>
            {renderOptionList(formikArray)}
            <Row className={!errors && 'd-none'}>
              <Col sm={12}>
                {errors &&
                  errors.label &&
                  errors.field &&
                  t(errors.label, { field: errors.field })}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Button
                  variant="primary"
                  className="d-flex align-items-center"
                  onClick={() => {
                    formikArray.push({
                      value: false
                    });
                    // Add description in corresponding translations when adding formikarray
                    if (translationData && translationData.length) {
                      for (
                        let translationIndex = 0;
                        translationIndex < translationData.length;
                        translationIndex++
                      ) {
                        const newArray = translationData[
                          translationIndex
                        ].checkoutConditionsDescription?.concat('');
                        setFieldValue(
                          `translationData.${translationIndex}.checkoutConditionsDescription`,
                          newArray
                        );
                      }
                    }
                  }}
                >
                  <i className="bi bi-plus btn-icon"></i>
                  {t('setting.country.add_condition')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </Panel>
  );
};

export default React.memo(SettingsPermittedCountriesOptions);
