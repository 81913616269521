/*
 * UserDetailsPopup.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file UserDetailsPopup.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import "./index.css";
import { Popup } from "reactjs-popup";
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'primereact/tooltip';
import { IUser, IUserProfile } from '../../Shared/interfaces/user/user';
import SkeletonWrapper from "../SkeletonLoader/SkeletonWrapper";

import { IGetUserInformationProperties } from './types'
import ProfileCard from "../ProfileCard/ProfileCard";

/**
 * Enum for the popup's position.
 */
export enum popupPosition {
  topLeft = "top left",
  topRight = "top right",
  bottomRight = "bottom right",
  bottomLeft = "bottom left",
  rightCenter = "right center",
  leftCenter = "left center",
  topCenter = "top center",
  bottomCenter = "bottom center",
  centerCenter = "center center",
}

/**
 * Enum for the popup trigger
 */
export enum popupTrigger {
  hover = "hover",
  click = "click",
  focus = "focus",
  rightClick = "right-click",
}

/**
 * Enum for the popup trigger
 */
export enum PopupMode {
  Simple = "simple",
  Complete = "complete",
}

/**
 * Interface for user details properties
 */
export interface IUserDetailsPopupProperties {
  users?: any; /**< List of users. */
  userUUID?: string; /**< Specific userUUID. */
  isLoading?: boolean;
  key?: string | null;
  user?: IUser; /** User object from the list of users. */
  className?: string; /**< Classname. */
  mode?: PopupMode; /**< Indicates what the popup should display */
  isShowTooltipForDeletedUser?: boolean /**< To show tooltip for deleted user */
  isHoverEffectDisabled?: boolean /**< Defines if we should display the hover effect. We don't display the hover effect when used in for client user pages. */
}
/**
 * Interface for Identity card data
 */
export interface IIdentityCardData {
  key: string; /**< The unique name of this card datum */
  value: string; /**< The value of the card datum */
  iconStyle: string; /**< the icon class name */
}

 /**
  * Get user information for a specific userUUID
  * @param userUUID userUUID to fetch details for
  * @param users list of users
  * @returns userInformation (object) if found or userUUID otherwise.
  */
 export const getUserInformation = (userUUID: string, users: IGetUserInformationProperties[]): IUser | IUserProfile | string => {
   // TODO: use explicit type instead of any
  if (userUUID && users && users.length > 0) {
    const userInformation: IGetUserInformationProperties | undefined = users.find((client) => client.userUUID === userUUID);
    if (userInformation) {
      return userInformation;
    }
  }
  return userUUID;
};

/**
 * Check if user is deleted.
 * @param user User object
 * @returns True if user is deleted and false otherwise.
 */
const isUserDeleted = (user: IUser):boolean => {
  if(user.email === null) {
    return true;
  }
  return false;
}

const UserDetailsPopup = ({
  users,
  userUUID,
  isLoading,
  key = null,
  user,
  className,
  mode = PopupMode.Simple,
  isShowTooltipForDeletedUser = true,
  isHoverEffectDisabled = false
}: IUserDetailsPopupProperties): ReactElement => {
  const { t } = useTranslation();
  if (isLoading) {
    return <SkeletonWrapper />;
  }

  // To show IDcard popup on user.
  const getIdentityCardPopup = () => {
    // TODO: use explicit type instead of any
    const userInformation: IUser | IUserProfile | string = getUserInformation(userUUID as string, users);
    const fullName: string = userInformation
    ? `${(userInformation as IUser).fullName}`
    : ''; /**< User full name */
    const email: string | undefined = userInformation
    ? (userInformation as IUser).email
    : ''; /**< User email */
  
    // Return an empty div in case no user is found.
    if (!userInformation) {
      return <></>
    }

    const userInformationProfileCard: IUserProfile = {
      firstName: (userInformation as IUserProfile).firstName,
      lastName: (userInformation as IUserProfile).lastName,
      tokenBalance: (userInformation as IUserProfile).tokenBalance,
      profilePictureURL: (userInformation as IUser).imageUrl,
      email: (userInformation as IUserProfile).email
    }

    if (userInformation === userUUID) {
      if(!user) {
        return (
          // Tooltip for deleted User.
          <div className="p-d-flex p-ai-center">
            <Tooltip target=".custom-target-icon" />
            <i 
              className="custom-target-icon text-warning bi bi-exclamation-triangle mr-2"
              data-pr-tooltip={t('awc:/.tooltip.notFoundUser')}
              data-pr-position="top"
              data-pr-my="left center-2"></i>
            {userInformation}
          </div>
        );
      }
       // Link to show ID card.
       const popupTriggerTemplate = (
        <div>
          {user.email}
        </div>
      ); 
        return (
          <div>
          <Popup
           key={key && key != "" ? key : `popup-${new Date()}`}
           trigger={popupTriggerTemplate}
           keepTooltipInside="#main-body"
           on={[popupTrigger.hover, popupTrigger.focus]}
           arrow={false}
           className={className}
           disabled={isHoverEffectDisabled}
         >
            <ProfileCard
              userInformation={userInformationProfileCard}
            />
         </Popup>
          </div>
        );
      }  else {
        if(isUserDeleted(userInformation as IUser)) {
          return (
            // Tooltip for deleted User.
            <div className="p-d-flex p-ai-center">
              <Tooltip target=".custom-target-icon" className={className}/>
              { isShowTooltipForDeletedUser ? 
                <i 
                  className="custom-target-icon text-warning bi bi-exclamation-triangle mr-2"
                  data-pr-tooltip={t('awc:/.tooltip.deletedUser')}
                  data-pr-position="top"
                  data-pr-my="left center-2">
                </i> : 
                <></>
              }
              {(userInformation as IUser).userUUID}
            </div>
          );
        }
        // Link to show ID card.
        const popupTriggerTemplate = mode === PopupMode.Complete ? (
          <div>
            <span>{(userInformation as IUser).username}</span>
            <br></br>
            <span>{fullName}</span>
            <br></br>
            <span>{email}</span>
          </div>
        ) : (
          <div>{(userInformation as IUser).username || (userInformation as any).author}</div>
        );

       return (
         <div>
         <Popup
           key={key && key != "" ? key : `popup-${new Date()}`}
           trigger={popupTriggerTemplate}
           keepTooltipInside="#main-body"
           on={[popupTrigger.hover, popupTrigger.focus]}
           arrow={false}
           className={className}
           disabled={isHoverEffectDisabled}
         >
          <ProfileCard
            userInformation={userInformationProfileCard}
          />
         </Popup>
       </div>
       );
     }
}
  return (
    getIdentityCardPopup()
  );
};

export default UserDetailsPopup;