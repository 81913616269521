/*
 * InstaInputText.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file InstaInputText.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState} from "react";
import { InputText } from "primereact/inputtext";
import { IInstaFormControlProperties } from "./form-control.interface";
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PopupInformation from "./PopupInformation";

interface IInstaInputTextProperties extends IInstaFormControlProperties {
  type?: string; /**< Type of InputText. */
  placeholder?: string;
  labelClassName?: string; /**< The label's CSS class name to be used. */
  spanClassName?: string; /**< The span CSS class name to be used. */
  labelAfter?: string; /**< Add text after label. */
  isLoading?: boolean; /**< Enable/Disable based on loading. */
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement> | Event) => void; /**< Keypress event of InputText. */
  isReadOnly?: boolean; /**< True is to make the input readonly and false otherwise. */
  isPassword?: boolean; /**< True if the input is password and false otherwise. */
  isShowInformationPopup?: boolean; /**< To show information popup */
  popupText?: string; /**< Popup text */
}

const InstaInputText = ({
  name,
  id,
  value,
  type = 'text',
  onChange,
  onBlur,
  onKeyPress,
  errors,
  touched,
  label,
  labelClassName = '',
  labelAfter = '',
  placeholder = "",
  isLoading,
  isReadOnly,
  className,
  spanClassName = '',
  isPassword = false,
  isShowInformationPopup = false,
  popupText,
  ...properties
}: IInstaInputTextProperties): ReactElement => {
  const { t } = useTranslation();
  const [ passwordVisible, setPasswordVisible ] = useState<{[key: string]: boolean}>({});

  const onPasswordVisible = (name: string) => {
      setPasswordVisible({...passwordVisible, 
                            [name]: passwordVisible[name] ? !passwordVisible[name] : true
                          });
  }

  return (
    <>
      <Form.Label htmlFor={id} className={labelClassName}>{label}</Form.Label>
      {isShowInformationPopup ?
       <PopupInformation 
          id={id} 
          popupText={popupText}
        />
         : <></> 
      }
      <div>
      <span  className={classnames("full-width-container", "p-input-icon-right", spanClassName)}>
         {isPassword && <i onClick={() => onPasswordVisible(name)} className={passwordVisible[name] ? "pi pi-eye-slash" : "pi pi-eye"} /> } 
      <InputText
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={classnames(className, {
          'p-invalid':  touched && errors
        })}
        onBlur={onBlur}
        type={passwordVisible[name] ? "text" : type}
        placeholder={placeholder}
        disabled={isLoading || isReadOnly}
        onKeyPress={(event) => {
          if(onKeyPress) {
            onKeyPress(event);
          }

          if(type === 'decimal') {
            (/[a-zA-Z!@#$%^&*()_+{}[\]:;<>?~ç]/.test(event.key)) && event.preventDefault();
          }
          
          if(type === 'number') {
            ['e', 'E', '+', '-', ',', '.'].includes(event.key) && event.preventDefault();
          } /// Decimal input is not allowed.
        }}
        {...properties}
      />
       </span>
       </div>
      {labelAfter && <Form.Label>{labelAfter}</Form.Label>}
      {touched && errors ? 
        (
          <small id="email-invalid" className="p-invalid error-text">
            {t(errors)}
          </small>
        ) : 
        null
      }
    </>
  );
};

export default InstaInputText;
