/*
 * log.ts (AbstractUser)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file log.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum PruneLogsTimeFrameEnum
 * Defines the enum options for the prune logs time frame.
 */
export enum PruneLogsTimeFrameEnum {
  OneWeek = 'One Week',
  ThreeMonths = 'Three Months',
  SixMonths = 'Six Months',
  OneYear = 'One Year',
}
