/*
 * SettingsBraintreeForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingsBraintreeForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import Button from 'react-bootstrap/Button';
import i18n from '../../../Services/I18n';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IBraintree } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';

/**
 * Interface for SettingsBraintreeForm properties.
 */
interface ISettingsBraintreeFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  loading: boolean;
  handleDelete: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  adminSettings: Record<string, any> /**< Admin Settings. */;
  RenderSkeletonComponent: any /**< Render a skeleton for each field in the form */;
  changeInputClassName: () =>
    | string
    | undefined /**< Control when to display the field in the form based on data loading */;
}

const SettingsBraintreeForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  handleDelete,
  handleBlur,
  touched,
  setFieldValue,
  adminSettings,
  RenderSkeletonComponent,
  changeInputClassName
}: ISettingsBraintreeFormProperties) => {
  const { t } = useTranslation();
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const [isBraintreeUpdatesExist, setBraintreeUpdatesExist] = useState<boolean>(
    false
  ); /**< isBraintreeUpdatesExist. */
  const isBraintreeErrorsExists: boolean =
    errors && Object.keys(errors).length > 0; /**< isBraintreeErrorsExists. */

  /// Show save popup
  const handleSave = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event?.target);
  };

  /// Save on Accept
  const onAccept = () => {
    setShowConfirmationPopup(false);
    handleSubmit();
    setBraintreeUpdatesExist(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmationPopup(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={t('confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('confirm_messages.no')}
        acceptLabel={t('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidateBraintreeUpdates(event.target);
  };

  /// Set Field value
  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateBraintreeUpdates({
      id: key,
      value
    });
  };

  /// Validate Braintree updates
  const revalidateBraintreeUpdates = (currentUpdate: any): void => {
    const braintreeValues: IBraintree = { ...values };
    if (braintreeValues) {
      braintreeValues[currentUpdate.id as keyof IBraintree] =
        currentUpdate.value;
    }
    setBraintreeUpdatesExist(checkIfBraintreeUpdatesExists(braintreeValues));
  };

  /// Check Braintree updates exists or not.
  const checkIfBraintreeUpdatesExists = (
    braintreeValues: IBraintree
  ): boolean => {
    return (
      braintreeValues &&
      Object.keys(mapBraintreeValues(braintreeValues)).length !== 0
    );
  };

  /// Braintree Values
  const mapBraintreeValues = (braintreeValues: IBraintree) => {
    const payload: IBraintree = {};
    const braintree: Record<string, any> =
      adminSettings && adminSettings.braintree; /**< Braintree */
    if (!braintree) {
      return {};
    }

    if (
      braintree.BRAINTREE_MERCHANT_ID !== braintreeValues.BRAINTREE_MERCHANT_ID
    ) {
      payload['BRAINTREE_MERCHANT_ID'] = braintreeValues.BRAINTREE_MERCHANT_ID;
    }
    if (
      braintree.BRAINTREE_MERCHANT_ACCOUNT_ID !==
      braintreeValues.BRAINTREE_MERCHANT_ACCOUNT_ID
    ) {
      payload['BRAINTREE_MERCHANT_ACCOUNT_ID'] =
        braintreeValues.BRAINTREE_MERCHANT_ACCOUNT_ID;
    }
    if (
      braintree.BRAINTREE_PUBLIC_KEY !== braintreeValues.BRAINTREE_PUBLIC_KEY
    ) {
      payload['BRAINTREE_PUBLIC_KEY'] = braintreeValues.BRAINTREE_PUBLIC_KEY;
    }
    if (
      braintree.BRAINTREE_PRIVATE_KEY !== braintreeValues.BRAINTREE_PRIVATE_KEY
    ) {
      payload['BRAINTREE_PRIVATE_KEY'] = braintreeValues.BRAINTREE_PRIVATE_KEY;
    }
    if (
      braintree.BRAINTREE_ENVIRONMENT !== braintreeValues.BRAINTREE_ENVIRONMENT
    ) {
      payload['BRAINTREE_ENVIRONMENT'] = braintreeValues.BRAINTREE_ENVIRONMENT;
    }

    return payload;
  };

  return (
    <Fieldset legend={t('setting.headers.change_braintree_settings')}>
      <Form>
        <Row>
          <Form.Group as={Col} {...{ sm: 12 }}>
            <Form.Label>
              {t('fields.braintree.braintreeEnvironment')}
            </Form.Label>
            <PopupInformation
              id={'BRAINTREE_ENVIRONMENT'}
              popupText={i18n.t('fields.braintree.environment_popupText')}
            />
            <Dropdown
              name="BRAINTREE_ENVIRONMENT"
              inputId="BRAINTREE_ENVIRONMENT"
              placeholder="Select a environment"
              onChange={(e) =>
                setFieldValueLocal('BRAINTREE_ENVIRONMENT', e.value)
              }
              onBlur={handleBlur}
              value={values?.BRAINTREE_ENVIRONMENT}
              options={[
                {
                  value: 'Production',
                  label: 'Production'
                },
                {
                  value: 'Sandbox',
                  label: 'Sandbox'
                },
                {
                  value: 'Development',
                  label: 'Development'
                },
                {
                  value: 'Qa',
                  label: 'Qa'
                }
              ]}
              className={`
              ${changeInputClassName()} ${
                touched.BRAINTREE_ENVIRONMENT && errors.BRAINTREE_ENVIRONMENT
                  ? 'p-invalid'
                  : ''
              }`}
            />
            {RenderSkeletonComponent()}
            {touched.BRAINTREE_ENVIRONMENT && errors.BRAINTREE_ENVIRONMENT ? (
              <small id="email-invalid" className="p-invalid error-text">
                {errors.BRAINTREE_ENVIRONMENT}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={t('fields.braintree.merchantID')}
              name="BRAINTREE_MERCHANT_ID"
              id={'BRAINTREE_MERCHANT_ID'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.BRAINTREE_MERCHANT_ID}
              errors={errors.BRAINTREE_MERCHANT_ID}
              value={values?.BRAINTREE_MERCHANT_ID}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.braintree.merchantID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={t('fields.braintree.merchantAccountID')}
              name="BRAINTREE_MERCHANT_ACCOUNT_ID"
              id={'BRAINTREE_MERCHANT_ACCOUNT_ID'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.BRAINTREE_MERCHANT_ACCOUNT_ID}
              errors={errors.BRAINTREE_MERCHANT_ACCOUNT_ID}
              value={values?.BRAINTREE_MERCHANT_ACCOUNT_ID}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.braintree.merchantAccountID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={t('fields.braintree.publicKey')}
              name="BRAINTREE_PUBLIC_KEY"
              id={'BRAINTREE_PUBLIC_KEY'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.BRAINTREE_PUBLIC_KEY}
              errors={errors.BRAINTREE_PUBLIC_KEY}
              value={values?.BRAINTREE_PUBLIC_KEY}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.braintree.publicKey_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={t('fields.braintree.privateKey')}
              name="BRAINTREE_PRIVATE_KEY"
              id={'BRAINTREE_PRIVATE_KEY'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.BRAINTREE_PRIVATE_KEY}
              errors={errors.BRAINTREE_PRIVATE_KEY}
              value={values?.BRAINTREE_PRIVATE_KEY}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.braintree.privateKey_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
        </Row>
        <Row>
          <Col sm={12} className="clearfix pb-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                loading ||
                isShowConfirmationPopup ||
                !isBraintreeUpdatesExist ||
                isBraintreeErrorsExists
                  ? 'custom-disabled-button'
                  : ''
              }`}
              variant="primary"
              disabled={
                loading ||
                isShowConfirmationPopup ||
                !isBraintreeUpdatesExist ||
                isBraintreeErrorsExists
              }
              onClick={handleSave}
            >
              {t('setting.save')}
            </Button>
          </Col>
        </Row>
      </Form>
      {getConfirmPopup()}
    </Fieldset>
  );
};

export default withFormik({
  mapPropsToValues: ({ adminSettings }) => {
    if (!adminSettings) return;

    const { braintree } = adminSettings;
    if (!braintree) {
      return {
        BRAINTREE_MERCHANT_ID: '',
        BRAINTREE_PUBLIC_KEY: '',
        BRAINTREE_PRIVATE_KEY: '',
        BRAINTREE_ENVIRONMENT: 'Sandbox'
      };
    }

    return {
      BRAINTREE_MERCHANT_ID: braintree.BRAINTREE_MERCHANT_ID,
      BRAINTREE_MERCHANT_ACCOUNT_ID: braintree.BRAINTREE_MERCHANT_ACCOUNT_ID,
      BRAINTREE_PUBLIC_KEY: braintree.BRAINTREE_PUBLIC_KEY,
      BRAINTREE_PRIVATE_KEY: braintree.BRAINTREE_PRIVATE_KEY,
      BRAINTREE_ENVIRONMENT: braintree.BRAINTREE_ENVIRONMENT
    };
  },
  validationSchema: Yup.object({
    BRAINTREE_MERCHANT_ID: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.braintree.merchantID')
      })
    ),
    BRAINTREE_MERCHANT_ACCOUNT_ID: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.braintree.merchantAccountID')
      })
    ),
    BRAINTREE_PUBLIC_KEY: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.braintree.publicKey')
      })
    ),
    BRAINTREE_PRIVATE_KEY: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.braintree.privateKey')
      })
    )
  }),
  enableReinitialize: true,
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit({
      ...values,
      ...bag.props.settings,
      ...bag.props.adminSettings.smtp
    });
  }
})(SettingsBraintreeForm);
