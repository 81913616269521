/*
 * DiscountsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file DiscountsPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDispatch, useSelector} from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import {createOrUpdateDiscount, deleteDiscount, getAllDiscounts, toggleDiscountDialog} from '../../../Store/Discounts';
import {fetchProducts} from '../../../Store/Products';
import DiscountsTable from './DiscountsTable';
import DiscountForm from './DiscountForm';
import {useTranslation} from 'react-i18next';
import {IPageEvent, ISortEvent, ITablePayload} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {defaultTableLimit} from '@abstract/abstractwebcommon-client/Constants';
import {useAppSelector} from '../../../Hooks';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const DiscountsPage = () => {
  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const discounts = useSelector((state) => state.discounts.list);
  const editedDiscount = useSelector((state) => state.discounts.editedDiscount);
  const dialogOpened = useSelector(
    (state) => state.discounts.discountDialogOpened
  );
  const loading = useSelector((state) => state.discounts.listIsFetching);
  const products = useSelector((state) => state.products.list);
  const productsLoading = useSelector((state) => state.products.listIsFetching);
  const isDiscountChanging: boolean = useAppSelector(
    (state) => state.discounts.discountIsChanging
  ); /**< isDiscountChanging */
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'discounts.createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const totalRecords: number = useSelector(
    (state) => state.discounts.totalRecords
  ); /**< Discount TotalRecords. */

  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(
      getAllDiscounts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      getAllDiscounts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      getAllDiscounts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  useEffect(() => {
    dispatch(
      getAllDiscounts(
        tablePayload.skip,
        tablePayload.limit,
        tablePayload.searchTerm,
        tablePayload.sort
      )
    ); /**< Fetch all discounts. */
    dispatch(fetchProducts()); /**< Fetch all products. */
  }, []);

  const handleAddOrEditDiscount = async (values) => {
      await asyncErrorHandler(dispatch(createOrUpdateDiscount(values)));
      await asyncErrorHandler(dispatch(
          getAllDiscounts(
              tablePayload.skip,
              tablePayload.limit,
              tablePayload.searchTerm,
              tablePayload.sort
          )
      ));
  };

  const handleDialogOpen = () => {
    dispatch(toggleDiscountDialog(true, null));
  };
  const handleEditDiscount = (e) => {
    dispatch(toggleDiscountDialog(true, e.data._id));
  };

  const handleDelete = async (discountIDs: string[]) => {
      await asyncErrorHandler(dispatch(deleteDiscount(discountIDs)));
      await asyncErrorHandler(dispatch(
          getAllDiscounts(
              tablePayload.skip,
              tablePayload.limit,
              tablePayload.searchTerm,
              tablePayload.sort
          )
      ));
  };

  const discountToEdit =
    editedDiscount &&
    discounts.find((discount) => discount._id === editedDiscount);

  return (
    <>
      <Row>
        <Col sm={12}>
          <DiscountsTable
            discounts={discounts}
            handleDialogOpen={handleDialogOpen}
            handleEdit={handleEditDiscount}
            loading={loading}
            handleDelete={handleDelete}
            handlePageUpdate={handlePageUpdate}
            handleSortUpdate={handleSortUpdate}
            handleFilterUpdate={handleFilterUpdate}
            tablePayload={tablePayload}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <DialogWrapper
        isDialogVisible={dialogOpened}
        headerTitle={
          discountToEdit
            ? translation('discount.form.header.edit_discount_dialog')
            : translation('discount.form.header.add_discount_dialog')
        }
        onHide={() => {
          dispatch(toggleDiscountDialog(false));
        }}
      >
        <DiscountForm
          products={products}
          productsLoading={productsLoading}
          handleSubmit={handleAddOrEditDiscount}
          discount={discountToEdit}
          handleDelete={handleDelete}
          loading={isDiscountChanging}
        />
      </DialogWrapper>
    </>
  );
};

export default DiscountsPage;
