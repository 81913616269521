/*
 * ProductTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductTable.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { Button } from 'react-bootstrap';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { useTranslation } from 'react-i18next';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import ImageRow from '@abstract/abstractwebcommon-client/Table/ImageRow/ImageRow';
import { useDispatch, useSelector } from 'react-redux';
import { areAllImagesLoadedSuccessfuly } from '../../../Store/Products';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { MultiSelect } from 'primereact/multiselect';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { fetchAllApplications } from '../../../Store/Applications';
import { useAppSelector } from '../../../Hooks';

/**
 * Interface for ProductTable properties.
 */
interface IProductTableProperties {
  products: any;
  loading: boolean;
  handleDialogOpen: () => void;
  handleEditProduct: (event: any) => void;
  setSearchTerm: any;
  handleDelete: (
    productIDs: string[]
  ) => void /**<Handle delete Product event. */;
  tablePayload: ITablePayload /**  Table payload. */;
  handlePageUpdate: (
    event: IPageEvent
  ) => void /**< Handle Table page update. */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  totalRecords: number /** Total number of products */;
  handleViewAllProducts: () => void /**< To view all products. */;
  isShowDeletedColumn: boolean /**< To show deleted column. */;
  handleCopyProduct: (productIDs: string[]) => void /**< Copy product(s). */;
  handleFilterUpdate: (
    payload: ITablePayload
  ) => void /**< Handle filter update event */;
  isAdminRole: boolean /**< Admin role or not */;
}

const ProductTable = ({
  products,
  loading,
  handleDialogOpen,
  handleEditProduct,
  setSearchTerm,
  handleDelete,
  tablePayload,
  handlePageUpdate,
  handleSortUpdate,
  totalRecords,
  handleViewAllProducts,
  isShowDeletedColumn,
  handleCopyProduct,
  handleFilterUpdate,
  isAdminRole
}: IProductTableProperties) => {
  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.products);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */
  const sort: ISortEvent = tablePayload?.sort; /**< Sort */
  const [selectedProducts, setSelectedProducts] = useState<IProduct[] | null>(
    null
  ); /**< Selected Products. */
  const deleteButtonReference: any = useRef(
    null
  ); /**< Delete Button Reference. */
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [imageCount, setImageCount] = useState<number>(1);
  const applications: IApplication[] = useAppSelector(
    (state) => state.applications.allApplications
  ); /**< Applications */
  const [selectedApplications, setSelectedApplications] = useState<
    IApplication[]
  >([]); /**< Selected Applications. */

  /**
   * copy button clicked handler
   */
  const copyButtonClicked = async () => {
    const productIDs: string[] | undefined = selectedProducts?.map(
      (eachProduct: IProduct) => eachProduct._id
    );
    /**< productIDs */
    await asyncErrorHandler(handleCopyProduct(productIDs!));
    setSelectedProducts(null);
  };

  const isButtonDisabled: boolean =
    ((selectedProducts && Object.keys(selectedProducts).length === 0) ||
      !selectedProducts) ??
    false;

  // Triggers on application multiselect change - Sets the value to the filter
  const onApplicationChange = (event: any): void => {
    const applications: IApplication[] =
      event.value; /**< Selected Applications. */
    setSelectedApplications(applications);
    const applicationIDs: string[] = applications.map(
      (application: IApplication) => application._id
    ); /**< Selected applicationIDs */

    let updatedFilter: Record<string, any> = {};

    if (applicationIDs && applicationIDs.length) {
      // If values exist then update the filter object with applicationID key
      updatedFilter = {
        ...tablePayload.filter,
        applicationID: { value: applicationIDs }
      };
    } else {
      // If not omit applicationID key from object
      updatedFilter = { ...tablePayload.filter };
      if (updatedFilter.applicationID) {
        delete updatedFilter.applicationID;
      }
    }

    const updatedPayload: ITablePayload = tablePayload;
    updatedPayload.filter = updatedFilter;
    handleFilterUpdate(updatedPayload);
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={isButtonDisabled}
          buttonReference={deleteButtonReference}
        />
        <ActionButton onClick={handleDialogOpen} />
        <Button
          onClick={handleViewAllProducts}
          style={{ minWidth: '83px' }}
          className="d-none d-sm-flex align-items-center mr-2 button-without-icon"
        >
          {translation('product.table.header.view_all')}
        </Button>
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-none d-sm-flex align-items-center mr-2 button-without-icon"
        >
          {translation('product.table.header.copy')}
        </Button>
      </div>
      <div className="d-flex d-sm-none margin-bottom-on-wrap productTable-header-margin">
        <Button
          onClick={handleViewAllProducts}
          style={{ minWidth: '83px' }}
          className="d-flex d-sm-none align-items-center mr-2 button-without-icon"
        >
          {translation('product.table.header.view_all')}
        </Button>
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-flex d-sm-none align-items-center mr-2 button-without-icon"
        >
          {translation('product.table.header.copy')}
        </Button>
      </div>
      {isAdminRole ? (
        <div className="headerTableContainer header-search-filter">
          <SearchBar onSearchTermChanged={(data) => setSearchTerm(data)} />
          <MultiSelect
            optionLabel="applicationName"
            dataKey="_id"
            name="application"
            inputId="applicationFilter"
            value={selectedApplications}
            options={applications}
            onChange={onApplicationChange}
            placeholder={translation('fields.product.company')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
          />
        </div>
      ) : (
        <SearchBar onSearchTermChanged={(data) => setSearchTerm(data)} />
      )}
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  useEffect(() => {
    if (isAdminRole) {
      dispatch(fetchAllApplications()); /**< To fetch all applications. */
    }
  }, []);

  /// Delete product on Accept
  const onAccept = () => {
    const productIDs: string[] | undefined = selectedProducts?.map(
      (eachProduct: IProduct) => eachProduct._id
    ); /**< productIDs */
    handleDelete(productIDs!);
    setShowConfirmation(false);
    setSelectedProducts(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation dialog
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };
  const image = (product) => {
    return (
      <DatatableColumn
        title="Image"
        data={
          <ImageRow
            setImageLoading={setImageLoading}
            imageURL={product.image}
            totalImagesCount={
              products &&
              products.filter(
                (productItem) =>
                  productItem.image !== '' && productItem.image !== undefined
              ).length
            }
            setImageCount={setImageCount}
            imageCount={imageCount}
          />
        }
      />
    );
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: IProduct[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow: IProduct[] = selectedValue.map((row: IProduct) => row);
      setSelectedProducts(selectedRow);
    }
  };

  const GetRowExpansionTemplate = (values: { rowData: IProduct }) => (
    <>
      <tr>
        <th>{translation('product.table.name')}</th>
        <td>{values.rowData['name']}</td>
      </tr>
      <tr>
        <th>{translation('product.table.sku')}</th>
        <td>{values.rowData['sku']}</td>
      </tr>
      <tr>
        <th>{translation('product.table.maxQuantity')}</th>
        <td>{values.rowData['maxQuantity']}</td>
      </tr>
      <tr>
        <th>{translation('product.table.price')}</th>
        <td>{currencyFormatter(values.rowData['price'])}</td>
      </tr>
      <tr>
        <th>{translation('product.table.transactionCount')}</th>
        <td>{values.rowData['transactionCount'] ?? 0}</td>
      </tr>
      <tr>
        <th>{translation('product.table.custom')}</th>
        <td>
          {
            <ShowCheckOrUncheckIcon
              value={
                values.rowData.options && values.rowData.options.length > 0
              }
            />
          }
        </td>
      </tr>
      <tr>
        <th>{translation('product.table.live')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.live} />}</td>
      </tr>
      <tr>
        <th>{translation('product.table.created')}</th>
        <td>
          {values.rowData.createdAt ? formatDate(values.rowData.createdAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('product.table.updated')}</th>
        <td>
          {values.rowData.updatedAt ? formatDate(values.rowData.updatedAt) : ''}
        </td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: IProduct) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  useEffect(() => {
    if (!isImageLoading || products?.length === 0) {
      dispatch(areAllImagesLoadedSuccessfuly());
    }
  }, [isImageLoading, products]);

  const isSelectable = (data: IProduct) =>
    !data.isDeleted; /**< Is data selectable */
  const rowClassName = (data: IProduct) =>
    isSelectable(data) ? '' : 'p-disabled'; /**< Row class name */
  const isRowSelectable = (event: any) =>
    event.data ? isSelectable(event.data) : true; /**< Is row selectable */

  /// Initialize Datatable
  const getDataTable = () => {
    return (
      <BaseDatatable
        value={products}
        isLoading={!products || productsState.isLoadingImages}
        expandedRows={expandedRows}
        dataKey="_id"
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        rowExpansionTemplate={renderExpansionRows}
        header={header}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        first={tablePayload.skip}
        rows={tablePayload.limit}
        totalRecords={totalRecords}
        onPage={handlePageUpdate}
        onSort={handleSortUpdate}
        selection={selectedProducts}
        onSelectionChange={onSelectionChange}
        isDataSelectable={isRowSelectable}
        rowClassName={rowClassName}
      >
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="image"
          header={translation('product.table.image')}
          className="icon-column-style d-none d-sm-table-cell"
          headerClassName="icon-column-style d-none d-sm-table-cell"
          body={image}
        />
        <Column
          field="productName"
          header={translation('product.table.name')}
          sortable
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.name')}
              data={product.name}
            />
          )}
        />
        <Column
          field="maxQuantity"
          header={translation('product.table.maxQuantity')}
          sortable
          className="p-col-number d-table-cell d-sm-none d-md-table-cell"
          headerClassName="p-col-number d-table-cell d-sm-none d-md-table-cell"
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.maxQuantity')}
              data={product.maxQuantity}
            />
          )}
        />
        <Column
          field="price"
          header={translation('product.table.price')}
          sortable
          className="p-col-price"
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.price')}
              data={currencyFormatter(product.price)}
            />
          )}
        />
        <Column
          field="transactionCount"
          header={translation('product.table.transactionCount')}
          sortable
          headerClassName="p-col-number d-table-cell d-sm-none d-xl-table-cell"
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.transactionCount')}
              data={product.transactionCount ? product.transactionCount : 0}
            />
          )}
          className="p-col-number d-table-cell d-sm-none d-xl-table-cell"
        />
        <Column
          field="options"
          header={translation('product.table.custom')}
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.custom')}
              data={
                <ShowCheckOrUncheckIcon
                  value={product.options && product.options.length > 0}
                />
              }
            />
          )}
          sortable
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
        />
        <Column
          field="live"
          header={translation('product.table.live')}
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.live')}
              data={<ShowCheckOrUncheckIcon value={product.live} />}
            />
          )}
          sortable
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
        />
        <Column
          field="createdAt"
          header={translation('product.table.created')}
          sortable
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.created')}
              data={product.createdAt ? formatDate(product.createdAt) : ''}
            />
          )}
          className="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
        />
        <Column
          field="updatedAt"
          header={translation('product.table.updated')}
          sortable
          className="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
          headerClassName="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
          body={(product) => (
            <DatatableColumn
              title={translation('product.table.updated')}
              data={product.updatedAt ? formatDate(product.updatedAt) : ''}
            />
          )}
        />
        {isShowDeletedColumn && (
          <Column
            field="deletedAt"
            header={translation('product.table.deleted')}
            sortable
            className="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
            headerClassName="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
            body={(product) => (
              <DatatableColumn
                title={translation('product.table.deleted')}
                data={product.deletedAt ? formatDate(product.deletedAt) : ''}
              />
            )}
          />
        )}
        <Column
          field="edit"
          className="absolute-position-responsive-screen col-width-45 p-0"
          body={(product: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => {
                  handleEditProduct({ data: product });
                }}
                disabled={product.isDeleted}
                variant="outline"
              >
                <i
                  className={`bi bi-pencil-square editIcon fa-lg ${
                    product.isDeleted ? 'custom-disabled-icon' : ''
                  }`}
                />
              </Button>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmationPopup()}
    </>
  );
};

export default ProductTable;
