/*
 * VisitorsAnalyticsTable.ts (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file VisitorsAnalyticsTable.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';

/**
 * Interface for VisitorsAnalyticsTable properties.
 */
interface IVisitorsAnalyticsTableProperties {
  tableData: {
    country: string /**< Country.*/;
    visitorsCount: number /**< No of visitors.*/;
  };
}

export const VisitorsAnalyticsTable = (
  properties: IVisitorsAnalyticsTableProperties
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <BaseDatatable
      value={properties.tableData}
      header={null}
      rows={5}
      rowsPerPageOptions={[5, 10, 20]}
      autoLayout
      responsive /**< Datatable responsive layout.*/
    >
      <Column
        field="country"
        header={t('analytics.visitors.country')}
        sortable
        body={(rowData: any) => (
          <DatatableColumn
            title={t('analytics.visitors.country')}
            data={Country.getCountryByCode(rowData.country)?.name}
          />
        )}
      />
      <Column
        field="visitorsCount"
        header={t('analytics.visitors.visitorsCount')}
        sortable
        body={(rowData: any) => (
          <DatatableColumn
            title={t('analytics.visitors.visitorsCount')}
            data={rowData.visitorsCount}
          />
        )}
      />
    </BaseDatatable>
  );
};
