/**
 * products.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file products.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum UseSeatQuantityEnum
 * Possibles options for the use seat quantity selector
 */
 export enum UseSeatQuantityEnum {
  Fixed = 'FIXED', /** Uses the quantity of the purchase. */
  Seat = 'SEAT', /** Uses the quantity of the seat quantity field. */
}