/*
 * constants.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file constants.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

// tables limits
export const defaultTableOptions: number[] = [10, 20, 50];
export const defaultTableLimit: number = 50;
export const tabletMediumBreakpointWidth = 800;
export const userAuthenticationVerificationUrlPath = '/auth/verify';