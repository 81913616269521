/*
 * Analytics.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Analytics.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {create, get} from '../Services/Analytics';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_ANALYTICS_REQUEST = 'analytics/fetchList/request';
const GET_ANALYTICS_SUCCESS = 'analytics/fetchList/success';
const GET_ANALYTICS_FAILURE = 'analytics/fetchList/failure';

const ADD_ANALYTICS_REQUEST = 'analytics/add/request';
const ADD_ANALYTICS_SUCCESS = 'analytics/add/success';
const ADD_ANALYTICS_FAILURE = 'analytics/add/failure';

const INITIAL_STATE = {
  list: [],
  formattedList: {},
  retrievalError: false,
  listIsFetching: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTICS_REQUEST:
      return { ...state, listIsFetching: true };
    case GET_ANALYTICS_FAILURE:
      return { ...state, listIsFetching: false, retrievalError: true };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        retrievalError: false,
        list: action.payload.analytics,
        formattedList: action.payload.formattedAnalytics
      };
    case ADD_ANALYTICS_REQUEST:
    case ADD_ANALYTICS_SUCCESS:
    case ADD_ANALYTICS_FAILURE:
    default:
      return state;
  }
};

const getAnalyticsRequest = () => ({
  type: GET_ANALYTICS_REQUEST
});

const getAnalyticsSuccess = (analytics, formattedAnalytics) => ({
  type: GET_ANALYTICS_SUCCESS,
  payload: { analytics, formattedAnalytics }
});

const getAnalyticsFailure = () => ({
  type: GET_ANALYTICS_FAILURE
});

const addAnalyticsRequest = () => ({
  type: ADD_ANALYTICS_REQUEST
});

const addAnalyticsSuccess = () => ({
  type: ADD_ANALYTICS_SUCCESS
});

const addAnalyticsFailure = () => ({
  type: ADD_ANALYTICS_FAILURE
});

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const groupTimeBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    const resultKey = new Date(currentValue.createdAt).getHours();
    (result[resultKey] = result[resultKey] || []).push(currentValue);
    return result;
  }, {});
};

const filterBy = (array, key, value) => {
  return array.filter((item) => {
    return item[key] === value;
  });
};

const groupDevices = (array) => {
  return array.reduce(
    (result, currentValue) => {
      if (
        currentValue.userAgent.match(
          /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
        )
      ) {
        result.mobile++;
      } else {
        result.computer++;
      }
      return result;
    },
    { mobile: 0, computer: 0 }
  );
};

const count = (object) => {
  return Object.keys(object).length;
};

const formatAnalyticData = async (data, params) => {
  const { analytics } = data;
  const { successfulTransactionCount } = data;
  const {
    transactionCountByProductAndCountry
  } = data; /**< Number of transactions from which  country for which product. */
  const groupedByTrackingId = await asyncErrorHandler(groupBy(analytics, 'trackingId'));
  const noOfVisitors = count(groupedByTrackingId);
  const groupedByEvent = await asyncErrorHandler(groupBy(analytics, 'event'));
  const groupByHour = await asyncErrorHandler(groupTimeBy(analytics, 'hour'));
  const groupByCountry = await asyncErrorHandler(groupBy(
      analytics,
      'country'
  )); /**< Group by country to get number of visitors. */
  const {
    PURCHASE_SUCCESSFUL,
    PURCHASE_UNSUCCESSFUL,
    VIEW_PRODUCT
  } = params.event.track;
  const pageEvents = params.event.page;
  // const noOfSuccessfulPurchase = groupedByEvent[PURCHASE_SUCCESSFUL.name]
  //   ? groupedByEvent[PURCHASE_SUCCESSFUL.name].length
  //   : 0;
  const noOfUnSuccessfulPurchase = groupedByEvent[PURCHASE_UNSUCCESSFUL.name]
    ? groupedByEvent[PURCHASE_UNSUCCESSFUL.name].length
    : 0;
  const viewProductEventArray = groupedByEvent[VIEW_PRODUCT.name]
    ? groupedByEvent[VIEW_PRODUCT.name]
    : [];
  const groupViewProducts = await asyncErrorHandler(groupBy(viewProductEventArray, 'product'));
  let pageViews = 0;
  const viewsPerPage = {
    xaxisData: [],
    yaxisData: []
  };
  let mostViewedProduct = [];
  Object.keys(groupViewProducts).forEach((key, index) => {
    if (
      mostViewedProduct.length === 0 ||
      groupViewProducts[key].length > mostViewedProduct[0].count
    ) {
      mostViewedProduct = [
        {
          name: groupViewProducts[key][0].selectedProduct[0]
            ? groupViewProducts[key][0].selectedProduct[0].name
            : '',
          _id: groupViewProducts[key][0].selectedProduct[0]
            ? groupViewProducts[key][0].selectedProduct[0]._id
            : '',
          count: groupViewProducts[key].length
        }
      ];
    } else if (groupViewProducts[key].length === mostViewedProduct[0].count) {
      mostViewedProduct.push({
        name: groupViewProducts[key][0].selectedProduct[0]
          ? groupViewProducts[key][0].selectedProduct[0].name
          : '',
        _id: groupViewProducts[key][0].selectedProduct[0]
          ? groupViewProducts[key][0].selectedProduct[0]._id
          : '',
        count: groupViewProducts[key].length
      });
    }
  });
  Object.keys(pageEvents).forEach((key, index) => {
    const pages = filterBy(analytics, 'event', pageEvents[key].name);
    viewsPerPage.xaxisData.push(pageEvents[key].title);
    viewsPerPage.yaxisData.push(pages.length);
    pageViews += pages.length;
  });
  let groupByDevice = await asyncErrorHandler(groupDevices(analytics));
  groupByDevice = {
    labels: ['Mobile', 'Computer'],
    series: [groupByDevice.mobile, groupByDevice.computer]
  };

  const visitorsCountByCountry = []; // Number of visits from which country.
  Object.keys(groupByCountry).forEach((key, index) => {
    if (key !== 'undefined' && key !== '') {
      visitorsCountByCountry.push({
        country: key,
        visitorsCount: groupByCountry[key].length
      });
    }
  });

  const formattedAnalytics = {
    purchaseSuccess: successfulTransactionCount,
    purchaseFailure: noOfUnSuccessfulPurchase,
    noOfVisitors,
    totalPageViews: pageViews,
    viewsPerPage,
    groupByHour,
    groupByDevice,
    mostViewedProduct,
    transactionCountByProductAndCountry,
    visitorsCountByCountry
  };
  return formattedAnalytics;
};

export const getAnalytics = (
  startDate = new Date(),
  endDate = new Date(),
  params
) => async (dispatch) => {
  try {
    const selectedStartDate = new Date(startDate);
    const isValidStartDate =
      selectedStartDate instanceof Date && !isNaN(selectedStartDate);
    const selectedEndDate = new Date(endDate);
    const isValidEndDate =
      selectedEndDate instanceof Date && !isNaN(selectedEndDate);
    // check start date
    if (isValidStartDate) {
      selectedStartDate.setHours(0, 0, 0, 0);
      startDate = selectedStartDate;
    } else {
      // dispatching error when date is not valid. this shouldn't happen anyways
      handleError({ message: 'Invalid start date.' });
      dispatch(getAnalyticsFailure());
    }
    // check end date
    if (isValidEndDate) {
      selectedEndDate.setHours(23, 59, 59, 999);
      endDate = selectedEndDate;
    } else {
      // dispatching error when date is not valid. this shouldn't happen anyways
      handleError({ message: 'Invalid end date.' });
      dispatch(getAnalyticsFailure());
    }
    dispatch(getAnalyticsRequest());
    const response = await asyncErrorHandler(get(startDate, endDate));
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getAnalyticsFailure());
    } else {
      const formattedAnalytics = await asyncErrorHandler(formatAnalyticData(response, params));
      dispatch(getAnalyticsSuccess(response, formattedAnalytics));
    }
  } catch (e) {
    dispatch(getAnalyticsFailure());
    handleError({ message: e.message });
  }
};

export const addAnalytics = (analytics) => async (dispatch) => {
  try {
    dispatch(addAnalyticsRequest());
    const result = await asyncErrorHandler(create(analytics));
    if (result.error) {
      dispatch(addAnalyticsFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(addAnalyticsSuccess());
      // dispatch(getAnalytics());
    }
  } catch (e) {
    dispatch(addAnalyticsFailure());
    if (e && e.error && e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};
