/**
* ActionButton.tsx (Web Common) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022
* @file ActionButton.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section Web Common
*/

import React, { RefObject } from 'react'
import Button from 'react-bootstrap/Button';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

/**
 * @interface IActionButtonProperties
 */
interface IActionButtonProperties {
  onClick: (event?: React.MouseEvent<HTMLElement>, isEdit?: boolean) => void /**< On click function. */
  isDisabled?: boolean /**< Is disabled?. */
  buttonReference?: RefObject<HTMLButtonElement> /**< Ref to manipulate the element. */
  variant?: string /**< Type of the button. */
  className?: string /**< custom style. */
  [x: string]: any /**< Others properties. */
}

const ADD_BUTTON = 'primary'
const DELETE_BUTTON = 'danger'

/**
 * Add an Add or Delete button to the interface
 * @param properties type of IActionButtonProperties
 * @returns JSX.Element
 */
const ActionButton = (properties: IActionButtonProperties): JSX.Element => {
  const t: TFunction = useTranslation().t;

  const handleTypeButton = () => (
    <>
      {properties.variant === DELETE_BUTTON
        ?
        <>
          <i className="bi bi-trash btn-icon button-icon-margin"></i>
          {properties.name ? properties.name : t('I18N.buttons.delete')}
        </>
        :
        <>
          <i className="bi bi-plus btn-icon button-icon-margin"></i>
          {properties.name ? properties.name : t('I18N.buttons.add')}
        </>
      }
    </>
  )

  return (
    <Button
      variant={properties.variant ?? ADD_BUTTON}
      onClick={properties.onClick}
      disabled={properties.isDisabled ?? false}
      ref={properties.buttonReference ?? null}
      className={`mr-2 d-flex align-items-center ${properties.className ?? ''}`}
      {...properties}
    >
      {handleTypeButton()}
    </Button>
  )
}

export default ActionButton