import React, {useCallback, useEffect, useRef, useState} from 'react';
import FixedShapeCropper from 'react-easy-crop';
import FreeSizeCropper from 'react-cropper';
import {Area, Point} from "react-easy-crop/types";
import {Slider} from 'primereact/slider';
import {Button, Col, Row} from "react-bootstrap";
import './CropDialog.css'
import 'material-icons/iconfont/material-icons.css';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import {getCroppedImage} from './cropImage';
import {dataURLtoBlob} from '../utils/dataURLtoBlob';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {showToast} from '../AlertToast/AlertToast';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

interface ICropDialogProperties {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  file: any;
  isIcon?: boolean;
  onImageCropComplete: (image: any) => void;
  isCircular?: boolean;
  fixedAspectRatio?: number; /**< Fixed aspect ratio defined by the component. */
  isCropOptionsVisible?: boolean; /**< Crop options visibiltiy. */
}

// Crop Shape for cropper component.
enum CropShape {
  CIRCULAR = "round",
  SQUARE = "rect",
}

const CropDialog = ({
  isVisible: visible,
  setVisible,
  isIcon = false,
  file,
  isCircular = true,
  fixedAspectRatio = 1,
  isCropOptionsVisible = true,
  onImageCropComplete
}: ICropDialogProperties) => {
  const [image, setImage] = useState<string>('');
  const [fixedShapeCrop, setFixedShapeCrop] = useState<Point>({ x: 0, y: 0 }); /**< Crop of fixed shape. */
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);
  const [freeSizeCrop, setfreeSizeCrop] = useState<Cropper>(); /**< Crop of free size. */
  const [aspectRatio, setAspectRatio] = useState<number>(1); /**< Aspect Ratio. */
  const [cropShape, setCropShape] = useState<CropShape>(isCircular ? CropShape.CIRCULAR : CropShape.SQUARE); /**< Crop Shape. */
  const [activeDiv, setActiveDiv] = useState<string>(isCircular ? 'circular' : 'square'); /**< Selected div. */
  const translate: TFunction = useTranslation().t;
  const croppedImageReference: any = useRef(); /**< CroppedImage Reference. */
  const [isAdjustedHeight, setAdjustedHeight] = useState<boolean>(false); /**< Is height adjusted */

  // Get blob of cropped image
  const getFreeSizeCropData = (): Blob | null => {
    if (freeSizeCrop) {
      const blob: Blob | null = dataURLtoBlob(freeSizeCrop.getCroppedCanvas().toDataURL());
      if (blob) {
        (blob as any).name = file.name;
        return blob;
      }
    }
    return null;
  };

  const onCropChange = (crop: Point) => {
    setFixedShapeCrop(crop)
  }

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onZoomChange = (zoom: number) => {
    setZoom(zoom)
  }

  // Triggers when the shape of the crop changes.
  const onCropShapeChange = (aspect: number, shape: CropShape, div: string) => {
    setAspectRatio(aspect);
    setCropShape(shape);
    setActiveDiv(div);
  }

  // Initialize image load
  useEffect(() => {
    croppedImageReference.current = null;
    if (file && file.size) {
      const reader: FileReader = new FileReader();
      reader.addEventListener('load', (event: any) => setImage(reader.result as string));
      reader.readAsDataURL(file);
    } else {
      resetCropDialog();
    }
  }, [file]);

  const getCropDialogFooter = (): JSX.Element => {
    return (
      <div className="cropper-footer">
        <Button
          className="d-flex align-items-center"
          variant="danger"
          onClick={() => {
            ///Note: If the cancel button is clicked, croppedImageReference is set to null.
            croppedImageReference.current = null;
            resetCropDialog();
          }}
          autoFocus>
          <i className="bi bi-x-circle btn-icon"></i>
          {translate('awc:/.cropDialog.cancel')}
        </Button>

        {isCropOptionsVisible && (
          <>
            <div className="cropper-shape"
              onClick={() => { 
                onCropShapeChange(1, CropShape.CIRCULAR, 'circular')
              }}
            >
            <span className={ activeDiv === 'circular' ? "material-icons-outlined custom-active" : "material-icons-outlined" }>circle</span>
            <span className="cropper-text">Circular</span>
            </div>
            <div className="cropper-shape"
              onClick={() => {
                onCropShapeChange(0, CropShape.SQUARE, 'free')
              }}
            >
              <span className={ activeDiv === 'free' ? "material-icons custom-active" : "material-icons"}>crop</span>
              <span className="cropper-text">Free</span>
            </div>
          </>
        )}
       
        <Button
          className="d-flex align-items-center"
          onClick={() => {
            aspectRatio === 0 ? cropImageToFreeSize() : cropImageToFixedShape();
          }}
          autoFocus>
          <i className="bi bi-check2-circle btn-icon"></i>
          {translate('awc:/.cropDialog.crop')}
        </Button>
      </div>
    );
  };


  // On confirm crop
  const cropImageToFreeSize = (): void => {
    const croppedImage: Blob | null = getFreeSizeCropData();
    if (croppedImage) {
      croppedImageReference.current = croppedImage; // Set croppedImage.
    } else {
      showToast({ severity: 'error', summary: translate('awc:/.cropDialog.cropError') });
    }
    resetCropDialog();
    handleHideDialog();
  };

  // On confirm crop
  const cropImageToFixedShape = async (): Promise<void> => {
    try {
      const croppedImage: string = await asyncErrorHandler(getCroppedImage(
          image,
          croppedAreaPixels
      ))
      const blob: Blob | null = dataURLtoBlob(croppedImage);
      if (blob) {
        (blob as any).name = file.name;
        croppedImageReference.current = blob; // Set croppedImage.
      }
      resetCropDialog();
      handleHideDialog();
    } catch (e) {
      showToast({ severity: 'error', summary: translate('awc:/.cropDialog.cropError') });
    }
  }

  /// Reset Crop dialog
  const resetCropDialog = () => {
    setVisible(false);
    setZoom(1);
    setAspectRatio(1);
    setCropShape(CropShape.CIRCULAR);
    setActiveDiv('circular');
  }

  /// Fix for position detection failure in component
  const onInteractionStart = () => {
    const cropContainer: HTMLElement = document.getElementsByClassName('reactEasyCrop_Container')[0] as HTMLElement; /**< Crop Container Element. */
    if(cropContainer && !isAdjustedHeight) {
      setAdjustedHeight(true);
      const height: number = cropContainer.clientHeight + 1;
      cropContainer.style["height"] = `${height}px`;
    }
  }
    
  const fixedShapeCropper = () => {
    const cropImage: HTMLElement  = document.getElementsByClassName('reactEasyCrop_Image')[0] as HTMLElement; /**< Crop Image Element. */
    const cropArea: HTMLElement = document.getElementsByClassName('reactEasyCrop_CropArea')[0] as HTMLElement; /**< Crop Area Element. */
    if(cropImage) {
      const height: number = cropImage.clientHeight; /** Get Image height */
      if(cropArea) { /// Set Crop area based on Image height.
        cropArea.style["width"] = `${height}px`;
        cropArea.style["height"] = `${height}px`;
      }
    }
    
    return (
      <Row className="justify-content-md-center">
        <Col md={10} className="vh-100 cropper-height">
          <FixedShapeCropper
            image={image || ''}
            crop={fixedShapeCrop}
            zoom={zoom}
            aspect={aspectRatio}
            cropShape={cropShape}
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onInteractionStart={onInteractionStart}
            onMediaLoaded={() => setAdjustedHeight(false)}
          />
        </Col>
        <Col md={6} xs={12} className="mt-2">
          <h6>Zoom</h6>
          <Slider
            value={zoom}
            min={1}
            max={20}
            onChange={(zoom: any) => onZoomChange(zoom.value)}
          />
        </Col>
      </Row>
    )
  }

  const freeSizeCropper = () => {
    return (
      <Row className="justify-content-md-center">
        <Col md={10} className="vh-100 cropper-height">
          <FreeSizeCropper
            className="cropper-free"
            initialAspectRatio={aspectRatio}
            src={image || ''}
            viewMode={1}
            zoomable={false}
            aspectRatio={aspectRatio}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance: Cropper) => {
              setfreeSizeCrop(instance);
            }}
            guides={true}
          />
        </Col>
        <Col md={6} xs={12} className="mt-2"></Col>
      </Row>
    )
  }
  
  /// Handle hide dialog event.
  const handleHideDialog = () => {
    if(croppedImageReference.current) {
      ///Note: When the crop button is clicked, add the croppedImage to the ImageContainer.
      onImageCropComplete(croppedImageReference.current);
    } else {
      ///Note: When the dialog's close button is clicked or cancel button is clicked, remove the image from the ImageContainer.
      onImageCropComplete(null);
    }
  }

  return (
    <DialogWrapper
      className="custom-dialog-container"
      headerTitle={translate('awc:/.cropDialog.header')}
      isDialogVisible={visible}
      onHide={() => {
        resetCropDialog()
        handleHideDialog()
      }}
      footer={getCropDialogFooter()}>
        {aspectRatio === 0 ? freeSizeCropper() : fixedShapeCropper()}
    </DialogWrapper>
  );
};

export default CropDialog;
