/**
* SettingsTermsAndConditionForm.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file SettingsTermsAndConditionForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'primereact/dropdown';
import { Country, ICountry } from 'country-state-city';
import { translate } from '../../../Utils/Translate';
import {
  ITermsAndCondition,
  ITermsAndConditionTableProperties
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { useTranslation } from 'react-i18next';
import { deleteTermsAndCondionAction } from '../../../Store/TermsAndCondition';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

/**
 * @interface ISettingsTermsAndConditionFormProperties
 */
interface ISettingsTermsAndConditionFormProperties {
  editTermsAndCondition: ITermsAndCondition /**< The terms and condition that needs to be edited */;
  handleSubmit: (
    payload: ITermsAndCondition,
    id?: string
  ) => void /**< handle terms and condition submit function*/;
  isLoading: boolean /**< checks for any terms and condition API request. True if there is, false otherwise */;
  termsAndCondition: ITermsAndCondition[] /** Array of ITermsAndCondition */;
  handleHideDialog: () => void /** Hide dialog */;
  tablePayload: ITablePayload /** Table payload */;
}

/**
 * @interface ICountryData
 */
interface ICountryData {
  value: string;
  label: string;
}

/**
 * SettingsTermsAndConditionForm form component.
 */
const SettingsTermsAndConditionForm = (
  properties: ISettingsTermsAndConditionFormProperties
): JSX.Element => {
  const translation = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const editTermsAndCondition: ITermsAndCondition =
    properties.editTermsAndCondition;
  const isLoading: boolean = properties.isLoading;
  const [countries, setCountries] = useState<ICountryData[]>([]);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: editTermsAndCondition?.country || '',
      link: editTermsAndCondition?.link || ''
    },
    validationSchema: Yup.object({
      country: Yup.string().required(
        translate('validation.required', {
          field: translate('termsAndCondition.country')
        })
      ),
      link: Yup.string()
        .max(500, translate('validation.max', { field: '50' }))
        .required(
          translate('validation.required', {
            field: translate('termsAndCondition.termsAndConditionLink')
          })
        )
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (editTermsAndCondition && editTermsAndCondition.id) {
            if (Object.keys(payload).length) {
              properties.handleSubmit(payload, editTermsAndCondition.id);
            }
          } else {
            properties.handleSubmit(payload);
          }
        }
      });
    }
  });

  /// Countries names
  const getCountries = () => {
    const countries: ICountryData[] = Country.getAllCountries()
      .map(
        (country: ICountry) =>
          ({
            value: country.isoCode,
            label: country.name
          } as ICountryData)
      )
      .sort((country1: ICountryData, country2: ICountryData) =>
        country1.label.localeCompare(country2.label)
      );
    const filteredCountries: ICountryData[] = countries.filter(
      (country: ICountryData) =>
        properties.termsAndCondition.every(
          (term: ITermsAndCondition) =>
            term.country !== country.value ||
            properties.editTermsAndCondition?.country === country.value
        )
    );

    setCountries(filteredCountries);
  };

  useEffect(() => {
    getCountries();
  }, []);

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete on Accept
  const onAccept = async () => {
    const deletionPayload: ITermsAndConditionTableProperties = {
      data: [editTermsAndCondition],
      limit: properties.tablePayload.limit,
      skip: properties.tablePayload.skip,
      searchTerm: properties.tablePayload.searchTerm,
      sort: properties.tablePayload.sort
    };
    dispatch(deleteTermsAndCondionAction(deletionPayload));
    setIsShowingConfirmation(false);
    properties.handleHideDialog();
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={editTermsAndCondition && editTermsAndCondition.id}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => formik.handleSubmit()}
      >
        <Row>
          <Col>
            <label htmlFor="stateOrProvince" className="required">
              {translate('termsAndCondition.country')}
            </label>
            <Dropdown
              name="country"
              inputId="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              options={countries}
              placeholder={translate('termsAndCondition.country')}
              className={
                formik.touched.country && formik.errors.country
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.country && formik.errors.country ? (
              <small id="country-invalid" className="p-invalid">
                {formik.errors.country}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-3">
            <label htmlFor="link" className="required">
              {translate('termsAndCondition.termsAndConditionLink')}
            </label>
            <InputText
              id="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              className={
                formik.touched.link && formik.errors.link ? 'p-invalid' : ''
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.link && formik.errors.link ? (
              <small id="name-invalid" className="p-invalid">
                {formik.errors.link}
              </small>
            ) : null}
          </Col>
        </Row>
      </FormWrapper>

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowingConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={() => setIsShowingConfirmation(false)}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="top"
      />

      <div className="d-flex">
        {formik.status?.message ? (
          <small className="p-invalid">{formik.status?.message}</small>
        ) : null}
      </div>
    </>
  );
};

export default SettingsTermsAndConditionForm;
