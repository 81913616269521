/**
* dateRangePicker.tsx (AWC) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2022 
* @file index.tsx
* @author Etienne Daher
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useEffect, useState  } from 'react';
import { defaultStaticRanges } from '../utils/dateRangeSelectors';
import { DateRangePicker, Range as IDateRange } from 'react-date-range';
import { InputText } from 'primereact/inputtext';
import { getFormattedDate } from '@abstract/abstractwebcommon-shared/utils/DateConversion';

import './DateRangePicker.css'

interface IManualDateRangePickerProperties {
  node: any; /**< Node reference for the popup */
  date: IDateRange[]; /**< Currently selected date range */
  setDate?: React.Dispatch<React.SetStateAction<IDateRange[]>> /**< Set state action for the date range */
  defaultDate: IDateRange[]; /**< Default date range value */
  handleDateChange: (event: any) => void; /**< Handle date range changed */
  showStartDateChangeWarning?: () => void; /**< Optional to show warning if start date changed */
  isDisabled?: boolean; /**< True if component is disabled, false otherwise */
  isClearButtonVisible?: boolean; /**< Condition to show clear icon */
}
export const ManualDateRangePicker = ({
  node,
  date,
  isDisabled = false,
  handleDateChange,
  showStartDateChangeWarning,
  defaultDate,
  isClearButtonVisible = true
}: IManualDateRangePickerProperties): JSX.Element => {
  /**
   * Format Date to display to the user
   * @param date Date range from the library
   */
  const formatDate = (date: IDateRange[]) => getFormattedDate(date[0].startDate, date[0].endDate)

  const [startAndEndDateField, setStartAndEndDateField] = useState<string>(formatDate(date));
  const [isCalendarVisible, setCalendarVisible] = useState<boolean>(false);

  /**
   * Handle onChange in manual date update
   * @param event onChange event
   */
  const handleManualDateChange = (event: any): void => {
    const inputValue: string = event.target !== undefined && event.target.value;
    if (inputValue !== undefined) {
      setStartAndEndDateField(inputValue);
    }
  };
  
  /**
   * Handle onKeyPress in manual date update
   * @param event onKeyPress event
   */
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleOnPressEnterKey(event)
    }
  }

  /**
   * Handle onPressKey in manual date update
   * @param event onPressKey event
   */
  const handleOnPressEnterKey = (event: any): void => {
    const inputValue: string = event.target !== undefined && event.target.value;
    const startAndEndDates: string[] = inputValue.split('-');

    let startDate: Date = new Date(startAndEndDates[0] !== undefined && startAndEndDates[0].trim());
    let endDate: Date = new Date(startAndEndDates[1] !== undefined && startAndEndDates[1].trim());
    
    if (startDate.toString() === 'Invalid Date' || endDate.toString() === 'Invalid Date') {
      startDate = date[0].startDate;
      endDate = date[0].endDate;
    } else if (startDate.getTime() >= endDate.getTime()) {
      endDate = new Date(startDate);
    }

    // If the start date has changed, display warning message.
    if (
      showStartDateChangeWarning !== undefined &&
      showStartDateChangeWarning !== null &&
      date[0].startDate.getTime() !== startDate.getTime()
    ) {
      showStartDateChangeWarning();
    }

    const newDate: IDateRange[] = [
      {
        startDate,
        endDate,
        key: 'selection'
      }
    ];
    setStartAndEndDateField(formatDate(newDate));
    handleDateChange({ selection: newDate[0] });
  };

  //work around to close date-range calendaron click outside for safari and mozilla.
  const handleClickOutsideDateRange = (event: any) => {
    const calendar = document.getElementsByClassName('rdrDateRangePickerWrapper')[0];
    if (node.current.contains(event.target) || (calendar && calendar.contains(event.target))) {
      return;
    }
    setCalendarVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDateRange);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDateRange);
    };
  }, []);

  return (
    <>
      <span
        className="p-calendar p-inputwrapper w-100 p-calendar-w-btn p-inputwrapper-filled"
        ref={node}>

        <span id='datepickerInput' className={`p-input-icon-right ${isDisabled ? 'p-disabled' : ''}`}>
          <InputText
            type="text"
            name="duration"
            className="p-inputtext p-component custom-datepicker-input"
            value={startAndEndDateField}
            onChange={handleManualDateChange}
            onKeyPress={onKeyPress}
            disabled={isDisabled}
          />

          <i className={`bi bi-x-lg ${!isClearButtonVisible && 'd-none'}`} onClick={() => {
            handleDateChange({ selection: defaultDate[0] })
            setStartAndEndDateField(formatDate(defaultDate));
          }} />
        </span>

        <button
          id='datepickerButton'
          type="button"
          tabIndex={-1}
          className={`${isDisabled ? 'custom-disabled-button p-disabled' : '' } button-color p-button p-component p-datepicker-trigger p-button-icon-only`}
          onClick={() => {
            if(!isDisabled) {
              setCalendarVisible(!isCalendarVisible);
            }
          }}>

          <i className="bi bi-calendar2"></i>
          <span className="p-button-label p-c">&nbsp;</span>
        </button>
      </span>

      {isCalendarVisible && (
        <DateRangePicker
          onChange={(event: any) => {
            setStartAndEndDateField(formatDate([event.selection]));
            handleDateChange(event);
          }}
          showPreview={true}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={true} /**< To retain end date. */
          editableDateInputs={true} /**< To enter a date manually. */
          staticRanges={defaultStaticRanges}
          months={1}
          scroll={{ enabled: true }}
          ranges={date}
          direction="horizontal"
          className="date-picker-container"
          inputRanges={[]}
        />
      )}
    </>
  );
}