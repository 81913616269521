/**
 * AsyncErrorHandler.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rogiel Sulzbach, 2023
 *
 * @file CreateErrorLog.ts
 * @author Rogiel Sulzbach
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Gets a formatted error message from the given error.
 *
 * @param error	The error to be formatted.
 */
export function getErrorMessage(error: any): string
{
    if(error instanceof Error)
    {
        let message = error.stack;
        if ((error as any).cause != null)
        {
            message += '\n\nfrom ' + getErrorMessage((error as any).cause);
        }
        return message;
    } else if (typeof error === 'number' || typeof error === 'string' || typeof error === 'boolean') {
        return error.toString();
    } else {
        // In case the error isn't from an instance error, number, string or boolean, we should log here.
        return JSON.stringify(error);
    }
}

/**
 * Handles errors for an asynchronous operation. Allows getting full call stack information over async calls.
 *
 * @param promise The asynchronous operation to handle errors for.
 * @param error The error object to be thrown in case of an error. If not provided, a new Error will be created.
 *
 * @returns The result of the asynchronous operation.
 * @throws If an error occurred during the asynchronous operation.
 */
export async function asyncErrorHandler<T>(promise: T | PromiseLike<T>, error?: Error): Promise<T>
{
    error ??= new Error();
    try
    {
        // noinspection async-error-checking
        return await promise;
    }
    catch(thrownError)
    {
        (error as any).cause = thrownError;
        throw error;
    }
}

/**
 * As the function asyncErrorHandler allows getting full call stack information over async calls. We need a handler to get the last cause object to register as log.
 *
 * @param error The error object to check the .cause object.
 *
 * @returns The error object or the function recursively in case there are more nested .cause objects.
 */
export function getLastErrorCause(error: Error): any
{
    return (error as any).cause != null ? getLastErrorCause((error as any).cause) : error;
}