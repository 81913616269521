/*
 * NotFoundBase.tsx (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file NotFound.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import './NotFoundBase.css'

/**
 * @interface INotFoundBaseProperties
 */
interface INotFoundBaseProperties {
  isLoading: boolean /**< If the tempate is loading. Condition to show the template */
  templateToShow: string /**< Show the html template */
}

const NotFoundBase = (props: INotFoundBaseProperties) => {
  const { t } = useTranslation();
  
  const DefaultNotFoundPage = () => (
    <div className="not-found-container">
      <div className="not-found-404">404</div>
      <div>
        <div>{t('I18N.page_not_found.not_found_message')}</div>
        <div>
          {t('I18N.page_not_found.navigate_back.navigation_message')}
          <a href="/"> {t('I18N.page_not_found.navigate_back.home')}</a>
        </div>
      </div>
    </div>
  );


  return (
    <div className="not-found-container">
      {props.isLoading ? (
        <div dangerouslySetInnerHTML={{ __html: props.templateToShow }} />
      ) : (
        <DefaultNotFoundPage />
      )}
    </div>
  );
};

export default NotFoundBase;
