/*
 * InstaImage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Pascal Mayr, 2020
 *
 * @file InstaImage.tsx
 * @author Pascal Mayr
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';

export const InstaImagePlaceholder = ({ placeholderClassName, ...props }) => (
  <div
    className={`skeleton ${placeholderClassName} ${props.hideDivClass}`}
    {...props}
  />
);

export const InstaImage = ({
  onLoadCallBack = () => {},
  onErrorCallBack = () => {},
  deleteHandler = () => {},
  deleteButton = false,
  placeholderClassName = '',
  containerClassName = '',
  ...props
}) => {
  const [exists, setExists] = useState(false);
  const [hideDivClass, setHideDivClass] = useState('');

  useEffect(() => {
    if (props && props.src) {
      const image = new Image();
      image.onload = (e) => {
        setHideDivClass('hide');
        onLoadCallBack(e);
        setExists(true);
      };
      image.onerror = (e) => {
        onErrorCallBack(e);
        setExists(false);
      };
      image.src = props.src;
    }
    if (!props.src) {
      setExists(false);
    }
  }, [props]);
  return (
    <div className={containerClassName}>
      {exists ? (
        <img {...props} />
      ) : (
        props.children || (
          <InstaImagePlaceholder
            placeholderClassName={placeholderClassName}
            hideDivClass={hideDivClass}
          />
        )
      )}
      {exists && deleteButton && (
        <a className="remove-image" href="#" onClick={deleteHandler}>
          &#215;
        </a>
      )}
    </div>
  );
};
