/*
 * ClientPanel.tsx (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file ClientPanel.tsx
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import ClientContent from './ClientContent';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { useHistory, Redirect } from 'react-router-dom';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import { useAppSelector } from '../../Hooks';
import MenuWrapper from '../Menu/MenuWrapper';
import { useTranslation } from 'react-i18next';
import { menuItemClick } from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import { createLog } from '../../Services/Logs';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { ISettings } from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import { RouteName } from '../../Utils/routesNames';
import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

/**
 * Interface for ClientPanel properties.
 */
interface IClientPanelProperties {
  fnLogout: () => void;
  themeMode: string /**< theme mode to use */;
  didChangeTheme: (theme: ThemeMode) => void /**< change theme function */;
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (
    language: string
  ) => void /**< change language function */;
}

const ClientPanel: React.FC<any> = ({
  fnLogout,
  themeMode,
  didChangeTheme,
  languageSettingsMode,
  didChangeLanguage
}: IClientPanelProperties) => {
  //NOTE: useHistory is passed to ConsoleMenu instead of being used there because that way we can avoid menu rerender
  const history = useHistory();
  const user: IAuth = useAppSelector(
    (state) => state.userAuth
  ); /**< User state. */
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(
    []
  ); /**< Menu items. */
  const menu: any = useRef(null); /**< Menu. */
  const translation = useTranslation().t;
  const [logoURL, setLogoURL] = useState<string>(''); /**< Logo URL. */
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(
    false
  ); /**< Logo Loading state. */
  const safeSettings: ISettings = useAppSelector(
    (state) => state.settings
  ); /**< Safe settings. */

  // Get client menu items
  const getClientMenuItems = (): Array<IMenuItem> => {
    /// Menu items for client user
    const clientMenuItems: IMenuItem[] = [
      {
        id: 'transactions',
        label: translation('user.menu.transactions'),
        icon: 'fas fa-sack-dollar sidebar_icon',
        url: RouteName.clientTransactionsRoute,
        pathToHighlight: RouteName.clientTransactionsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.clientTransactionsRoute,
            'transactions'
          );
        }
      },
      {
        id: 'subscriptions',
        label: translation('user.menu.subscriptions'),
        icon: 'fas fa-envelope-open-dollar sidebar_icon',
        url: RouteName.clientSubscriptionsRoute,
        pathToHighlight: RouteName.clientSubscriptionsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.clientSubscriptionsRoute,
            'subscriptions'
          );
        }
      }
    ];

    return clientMenuItems;
  };

  // If user is admin, redirect to admin route.
  if (user.isAuthenticated && user.isAdmin) {
    return <Redirect to={SharedMainRouteName.adminRoute} />;
  }

  /// Populate menuItems
  useEffect(() => {
    const updatedMenuItems: IMenuItem[] = getClientMenuItems();
    if (!menuItems.length) {
      setMenuItems(updatedMenuItems);
    }
  }, [menuItems.length]);

  /// Get Logo URL.
  useEffect(() => {
    const getLogoURL = async () => {
      if (safeSettings && safeSettings.setting && safeSettings.setting.logo) {
        setLogoURL(safeSettings.setting.logo);
        setLogoLoaded(true);
      } else {
        setLogoLoaded(true);
      }
    };
    if (user.isAuthenticated) {
      getLogoURL();
    } else {
      setLogoLoaded(true);
    }
  }, [safeSettings]);

  /// Menuwrapper and page content
  const getPageContent = () => {
    return (
      <>
        <MenuWrapper
          menuItems={menuItems}
          menu={menu}
          fnLogout={fnLogout}
          setMenuItems={setMenuItems}
          history={history}
          isAdmin={false}
          didChangeTheme={didChangeTheme}
          themeMode={themeMode}
          logoURL={logoURL}
          languageSettingsMode={languageSettingsMode}
          didChangeLanguage={didChangeLanguage}
        />
        <Col className="main-body" id="main-body">
          {<ClientContent />}
        </Col>
      </>
    );
  };

  /// Populate menuItems when language changes
  useEffect(() => {
    const updatedMenuItems: IMenuItem[] = getClientMenuItems();
    setMenuItems([...updatedMenuItems]);
  }, [languageSettingsMode]);

  //Note: Get admin menu items when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const updatedMenuItems: IMenuItem[] = getClientMenuItems();
      if (updatedMenuItems) {
        setMenuItems([...updatedMenuItems]);
      }
    }
  });

  return (
    <>
      {isLogoLoaded ? (
        <main className="d-flex flex-column h-100">
          <Container fluid={true}>
            <Row>{getPageContent()}</Row>
          </Container>
        </main>
      ) : (
        <></>
      )}
    </>
  );
};

export default withErrorBoundary(ClientPanel, createLog, false);
