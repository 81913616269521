/*
 * SoftwareVersion.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file SoftwareVersion.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import { Card } from "primereact/card";
import { useTranslation } from 'react-i18next';
import { TFunction } from "i18next";

/**
 * ISoftwareVersionProperties interface.
 */
export interface ISoftwareVersionProperties {
  frontendCommitLink?: string;
  frontendCurrentVersion: string;
  backendCommitLink?: string;
  backendCurrentVersion: string;
  awcCommitLink?: string; /**< AWC commit link. */
  awcCurrentVersion?: string; /**< AWC commit link. */
}

const SoftwareVersionValues: ISoftwareVersionProperties = {
  frontendCommitLink: "",
  frontendCurrentVersion: "",
  backendCommitLink: "",
  backendCurrentVersion: "",
  awcCommitLink: "",
  awcCurrentVersion: ""
};

const SoftwareVersion = ({
  frontendCommitLink,
  frontendCurrentVersion,
  backendCommitLink,
  backendCurrentVersion,
  awcCommitLink,
  awcCurrentVersion
}: ISoftwareVersionProperties): ReactElement => {
  const translation: TFunction = useTranslation().t;

  frontendCommitLink =
    frontendCommitLink || SoftwareVersionValues.frontendCommitLink;
  frontendCurrentVersion =
    frontendCurrentVersion || SoftwareVersionValues.frontendCurrentVersion;
  backendCommitLink =
    backendCommitLink || SoftwareVersionValues.backendCommitLink;
  backendCurrentVersion =
    backendCurrentVersion || SoftwareVersionValues.backendCurrentVersion;
  awcCommitLink =
    awcCommitLink || SoftwareVersionValues.awcCommitLink;
  awcCurrentVersion =
    awcCurrentVersion || SoftwareVersionValues.awcCurrentVersion;

  return (
    <Card title={translation("awc:/.dashboard.dashboard_fieldset.softwareVersion")}>
      {translation("awc:/.dashboard.dashboard_fieldset.frontendVersion")}
      <p className="text-monospace text-break">
        <a href={frontendCommitLink} target="_blank" rel="noopener noreferrer">
          {frontendCurrentVersion}
        </a>
      </p>
      {translation("awc:/.dashboard.dashboard_fieldset.backendVersion")}
      <p className="text-monospace text-break">
        <a href={backendCommitLink} target="_blank" rel="noopener noreferrer">
          {backendCurrentVersion}
        </a>
      </p>
      {translation("awc:/.dashboard.dashboard_fieldset.awcVersion")}
      <p className="text-monospace text-break">
        <a href={awcCommitLink} target="_blank" rel="noopener noreferrer">
          {awcCurrentVersion}
        </a>
      </p>
    </Card>
  );
};

export default SoftwareVersion;
