/*
 * TransactionError.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file TransactionError.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { Message } from 'primereact/message';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Interface for TransactionError properties.
 */
interface ITransactionErrorProperties {
  error: {
    status?: string;
    reasons?: {
      processorResponseCode?: any;
      processorResponseText?: string;
      gatewayRejectionReason?: string;
      [key: string]: any /**< Dynamic properties */;
    };
    errorId?: any;
    [key: string]: any /**< Dynamic properties */;
  };
}

const TransactionError = ({ error }: ITransactionErrorProperties) => {
  const { t } = useTranslation();

  // We should hard code the contact URL.
  const abstractContactHtml: string = `<a href="https://theabstract.co/contact/" target="_blank" rel="noopener noreferrer">https://theabstract.co/contact/</a>`;

  if (!error) {
    return null;
  }
  
  if (error.status === 'processor_declined') {
    const processorDeclinedMessageTemplate: string = t([
      `checkoutPage.paymentErrors.processor_declined`
    ], { 
      error: error.reasons.processorResponseCode,
      errorText: error.reasons.processorResponseText,
      errorId: error.errorId, abstractContact: abstractContactHtml 
    });
  
    // Safely insert the HTML content into the message.
    const processorDeclinedMessageWithHtml: { __html: string }  = { __html: processorDeclinedMessageTemplate };

    return (
      <Message
        text={<span dangerouslySetInnerHTML={processorDeclinedMessageWithHtml} />}
        severity="error"
        className="mb-2"
      />
    );
  }

  if (error.status === 'gateway_rejected') {
    const gatewayRejectedMessageTemplate: string = t([
      `checkoutPage.paymentErrors.gateway_rejected`
    ], { 
      errorText: error.reasons.gatewayRejectionReason,
      errorId: error.errorId, 
      abstractContact: abstractContactHtml 
    });
  
    // Safely insert the HTML content into the message.
    const gatewayRejectedMessageWithHtml: { __html: string }  = { __html: gatewayRejectedMessageTemplate };
    
    return (
      <Message
        text={<span dangerouslySetInnerHTML={gatewayRejectedMessageWithHtml} />}
        severity="error"
        className="mb-2"
      />
    );
  }

  if (error.status === 'form_error') {
    return <Message text={t(`checkoutPage.formError`)} severity="error" className="mb-2" />;
  }

  const defaultMessageTemplate: string = t([
    `checkoutPage.paymentErrors.${error.status}`,
    'checkoutPage.paymentErrors.default_error'
  ], { errorId: error.errorId, abstractContact: abstractContactHtml });

  // Safely insert the HTML content into the message.
  const defaultMessageWithHtml: { __html: string }  = { __html: defaultMessageTemplate };

  return (
    <Message
      text={<span dangerouslySetInnerHTML={defaultMessageWithHtml} />}
      severity="error"
      className="mb-2"
    />
  );
};

export default TransactionError;
