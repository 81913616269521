/*
 * ProductFormOptions.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductFormOptions.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import Price from '../../SubComponents/Price';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import Button from 'react-bootstrap/Button';
import i18n from '../../../Services/I18n';
import './ProductFormOptions.css';
import { IOption } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * Interface for ProductFormOptions properties.
 */
interface IProductFormOptionsProperties {
  values: any;
  errors: any;
  handleChange: any;
  loading: boolean;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  header?: string;
  price?: number;
  isGlobalProductOptions?: boolean /** Control when to use the class name global-products-container*/;
  onOptionArrayChange?: (data: {
    index?: number;
    newOption?: IOption;
  }) => void /**< Handler for option array change */;
  selectedLanguage: string /**< Selected language */;
  translationData:
    | IProductTranslation[]
    | undefined /**< Product translation data */;
  translationErrors: any /**< Product translation errors */;
  translationTouched: any /**< Product translation touched */;
  checkIfTranslationUpdateExists?: (
    key: string,
    translationIndex: number,
    optionsIndex: number,
    updatedValue: string
  ) => void;
  selectedLanguageTranslationIndex: number /**< Selected language translation data index */;
  englishTranslationIndex: number /**< English translation data index */;
}

const ProductFormOptions = ({
  values,
  errors,
  handleChange,
  loading,
  handleBlur,
  touched,
  setFieldValue,
  header = i18n.t('fields.product.options_header'),
  price,
  isGlobalProductOptions = false,
  onOptionArrayChange,
  selectedLanguage,
  translationData,
  translationErrors,
  translationTouched,
  checkIfTranslationUpdateExists,
  selectedLanguageTranslationIndex,
  englishTranslationIndex
}: IProductFormOptionsProperties) => {
  const { t } = useTranslation();

  /// Handle blur event
  const onBlur = (event: any): void => {
    handleBlur(event);
    const currentUpdate: any = event.target;

    /// If option amount or percentage has empty string, change to 0
    if (isStringEmptyOrNullOrUndefined(currentUpdate.value)) {
      setFieldValue(currentUpdate.id, 0);
    }
  };

  const renderOptionList = (formikArray) =>
    values.map((option, index) => (
      <Row key={index}>
        <div className="w-100 d-flex mt-2">
          <Col sm={9} className="d-flex align-items-center mt-2">
            <Form.Group>
              <Checkbox
                inputId={
                  isGlobalProductOptions
                    ? `global-products-container.options.${index}.per_product_qty`
                    : `individual-product-container.options.${index}.per_product_qty `
                }
                sizing={{ sm: 1 }}
                label={t('product.options.apply_option_field_label')}
                checked={option.per_product_qty}
                onChange={(e) => {
                  setFieldValue(
                    `options.${index}.per_product_qty`,
                    !option.per_product_qty
                  );
                }}
                fieldName={`options.${index}.per_product_qty`}
              />
              <Form.Label
                htmlFor={
                  isGlobalProductOptions
                    ? `global-products-container.options.${index}.per_product_qty`
                    : `individual-product-container.options.${index}.per_product_qty `
                }
                className="ml-2"
              >
                {t('product.options.apply_option_checkbox')}
              </Form.Label>
            </Form.Group>
          </Col>

          <Col>
            <Button
              className="p-button-raised p-button-primary float-right"
              onClick={() => {
                formikArray.remove(index);
                onOptionArrayChange && onOptionArrayChange({ index });
                // Remove corresponding translations
                if (translationData && translationData.length) {
                  for (
                    let translationIndex = 0;
                    translationIndex < translationData.length;
                    translationIndex++
                  ) {
                    const newArray = translationData[
                      translationIndex
                    ].productOptionsDescription?.filter(
                      (option: string, optionIndex: number) =>
                        optionIndex !== index
                    );
                    setFieldValue(
                      `translationData.${translationIndex}.productOptionsDescription`,
                      newArray
                    );
                    const newOptionInformationArray: string[] = translationData[
                      translationIndex
                    ].productOptionsInformation?.filter(
                      (option: string, optionIndex: number) =>
                        optionIndex !== index
                    );
                    setFieldValue(
                      `translationData.${translationIndex}.productOptionsInformation`,
                      newOptionInformationArray
                    );
                  }
                }
              }}
            >
              <i className="bi bi-dash btn-icon mr-0"></i>
            </Button>
          </Col>
        </div>

        <Col>
          <Row>
            <Form.Group as={Col} sm="6">
              <InstaInputText
                label={t('fields.productOptions.name')}
                name={`options.${index}.name`}
                id={`options.${index}.name`}
                isLoading={loading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values && values[index] && values[index].name}
                touched={touched && touched[index] && touched[index].name}
                errors={errors && errors[index] && errors[index].name}
                labelClassName="required"
              />
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <InstaInputText
                label={t('fields.productOptions.description')}
                name={`translationData.${selectedLanguageTranslationIndex}.productOptionsDescription.${index}`}
                id={`translationData.${selectedLanguageTranslationIndex}.productOptionsDescription.${index}`}
                isLoading={loading}
                onChange={(event) => {
                  handleChange(event);
                  checkIfTranslationUpdateExists &&
                    checkIfTranslationUpdateExists(
                      'productOptionsDescription',
                      selectedLanguageTranslationIndex,
                      index,
                      event.target.value
                    );
                }}
                onBlur={handleBlur}
                value={
                  (translationData &&
                    translationData.length &&
                    translationData[selectedLanguageTranslationIndex] &&
                    translationData[selectedLanguageTranslationIndex]
                      .productOptionsDescription?.[index]) ??
                  ''
                }
                touched={
                  translationTouched &&
                  translationTouched.length &&
                  translationTouched[selectedLanguageTranslationIndex] &&
                  translationTouched[selectedLanguageTranslationIndex]
                    .productOptionsDescription?.[index]
                }
                errors={
                  translationErrors &&
                  translationErrors.length &&
                  translationErrors[selectedLanguageTranslationIndex] &&
                  translationErrors[selectedLanguageTranslationIndex]
                    .productOptionsDescription?.[index]
                }
                placeholder={t('fields.productOptions.description_placeholder')}
                labelClassName={
                  selectedLanguage === LanguageSettingsMode.english
                    ? 'required'
                    : ''
                }
              />
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <InstaInputText
                label={t('fields.productOptions.information')}
                name={`translationData.${selectedLanguageTranslationIndex}.productOptionsInformation.${index}`}
                id={`translationData.${selectedLanguageTranslationIndex}.productOptionsInformation.${index}`}
                isLoading={loading}
                onChange={(event) => {
                  handleChange(event);
                  checkIfTranslationUpdateExists &&
                    checkIfTranslationUpdateExists(
                      'productOptionsInformation',
                      selectedLanguageTranslationIndex,
                      index,
                      event.target.value
                    );
                }}
                onBlur={handleBlur}
                value={
                  (translationData &&
                    translationData.length &&
                    translationData[selectedLanguageTranslationIndex] &&
                    translationData[selectedLanguageTranslationIndex]
                      .productOptionsInformation?.[index]) ??
                  ''
                }
                touched={
                  translationTouched &&
                  translationTouched.length &&
                  translationTouched[selectedLanguageTranslationIndex] &&
                  translationTouched[selectedLanguageTranslationIndex]
                    .productOptionsInformation?.[index]
                }
                errors={
                  translationErrors &&
                  translationErrors.length &&
                  translationErrors[selectedLanguageTranslationIndex] &&
                  translationErrors[selectedLanguageTranslationIndex]
                    .productOptionsInformation?.[index]
                }
                placeholder={t('fields.productOptions.information_placeholder')}
                labelClassName={
                  selectedLanguage === LanguageSettingsMode.english
                    ? 'required'
                    : ''
                }
              />
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <InstaInputText
                label={t('fields.productOptions.amount')}
                labelAfter={
                  price && values[index].amount ? (
                    <div className="amount-calculation">
                      =&nbsp;
                      <Price amount={values[index].amount} />
                    </div>
                  ) : null
                }
                type="number"
                name={`options.${index}.amount`}
                id={`options.${index}.amount`}
                isLoading={loading}
                onChange={handleChange}
                onBlur={onBlur}
                value={values && values[index] && values[index].amount}
                touched={touched && touched[index] && touched[index].amount}
                errors={errors && errors[index] && errors[index].amount}
              />
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <InstaInputText
                label={t('fields.productOptions.percentage')}
                labelAfter={
                  price && values[index].percentage ? (
                    <div className="percentage-calculation">
                      =&nbsp;
                      <Price
                        amount={(price / 100) * values[index].percentage}
                      />
                    </div>
                  ) : null
                }
                type="number"
                name={`options.${index}.percentage`}
                id={`options.${index}.percentage`}
                isLoading={loading}
                onChange={handleChange}
                onBlur={onBlur}
                value={values && values[index] && values[index].percentage}
                touched={touched && touched[index] && touched[index].percentage}
                errors={errors && errors[index] && errors[index].percentage}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
    ));

  return (
    <Panel
      header={header}
      className={`p-0 ${
        isGlobalProductOptions
          ? 'global-products-container'
          : 'additional-options-panel'
      }`}
    >
      <FieldArray
        name="options"
        render={(formikArray) => (
          <>
            {renderOptionList(formikArray)}
            <Row className={!errors ? 'd-none' : 'text-center'}>
              <Col sm={12}>
                {errors &&
                  errors.label &&
                  errors.field &&
                  t(errors.label, { field: errors.field })}
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <ActionButton
                onClick={() => {
                  const newOption: IOption = {
                    name: '',
                    amount: 0,
                    percentage: 0,
                    per_product_qty: false
                  };

                  // Add description in corresponding translations when adding formikarray
                  if (translationData && translationData.length) {
                    for (
                      let translationIndex = 0;
                      translationIndex < translationData.length;
                      translationIndex++
                    ) {
                      const newArray = translationData[
                        translationIndex
                      ].productOptionsDescription?.concat('');
                      setFieldValue(
                        `translationData.${translationIndex}.productOptionsDescription`,
                        newArray
                      );
                      const newOptionInformationArray: string[] = translationData[
                        translationIndex
                      ].productOptionsInformation?.concat('');
                      setFieldValue(
                        `translationData.${translationIndex}.productOptionsInformation`,
                        newOptionInformationArray
                      );
                    }
                  }
                  formikArray.push(newOption);
                  onOptionArrayChange && onOptionArrayChange({ newOption });
                }}
              />
            </Row>
          </>
        )}
      />
    </Panel>
  );
};

export default React.memo(ProductFormOptions);
