/*
 * SettingsForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file SettingsForm.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { defaultRenewalRefreshTokenPeriodInSeconds } from '@abstract/abstractwebcommon-shared/constants';
import i18n from '../../../Services/I18n';
import SettingsFaviconUpload from './SettingsFaviconUpload';
import SettingsLogoUpload from './SettingsLogoUpload';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IApplicationSettings } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';
import TextEditorDialog from '@abstract/abstractwebcommon-client/TextEditorDialog/TextEditorDialog';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import InstaCheckbox from '@abstract/abstractwebcommon-client/FormControl/InstaCheckbox';

/**
 * Interface for SettingsForm properties.
 */
interface ISettingsFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  setFieldValue: any;
  loading: boolean;
  product: any;
  handleDelete: any;
  handleBlur: any;
  touched: any;
  settings: any;
  adminSettings: any /**< Admin settings data. */;
  uploadLogo: (event: any) => Promise<void> /**< Upload Logo Handler. */;
  deleteLogo: (event: any) => void /**< Delete Logo Handler. */;
  croppedLogo: Blob | null /**< Cropped Logo. */;
  uploadFavoriteIcon: (
    event: any
  ) => Promise<void> /**< Upload FavoriteIcon Handler. */;
  deleteFavoriteIcon: (event: any) => void /**< Delete FavoriteIcon Handler. */;
  croppedFavouriteIcon: Blob | null /**< Cropped FavoriteIcon. */;
  errorHandler: (error: string) => void /**< Error Handler. */;
  logoUploadStatus: string /**< Logo upload status */;
  favouriteIconUploadStatus: string /**< Favourite Icon upload status */;
  RenderSkeletonComponent: any /**< Render a skeleton for each field in the form */;
  changeInputClassName: (
    isFileField?: boolean
  ) =>
    | string
    | undefined /**< Control when to display the field in the form based on data loading */;
}

const SettingsForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
  loading,
  product,
  handleDelete,
  handleBlur,
  touched,
  settings,
  uploadLogo,
  deleteLogo,
  croppedLogo,
  uploadFavoriteIcon,
  deleteFavoriteIcon,
  croppedFavouriteIcon,
  errorHandler,
  logoUploadStatus,
  favouriteIconUploadStatus,
  adminSettings,
  RenderSkeletonComponent,
  changeInputClassName
}: ISettingsFormProperties) => {
  const translation = useTranslation().t;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const [
    isApplicationSettingsUpdatesExist,
    setApplicationSettingsUpdatesExist
  ] = useState<boolean>(false); /**< isApplicationSettingsUpdatesExist. */
  const isApplicationSettingsErrorsExists: boolean =
    errors &&
    Object.keys(errors).length > 0; /**< isApplicationSettingsErrorsExists. */

  const [isLogoUpdateExists, setLogoUpdateExists] = useState<boolean>(
    false
  ); /**< isLogoUpdateExists. */
  const [isFavouriteIconUpdateExists, setFavouriteIconUpdateExists] = useState<
    boolean
  >(false); /**< isFavouriteIconUpdateExists. */
  const [isShowWebTermsTextDialog, setShowWebTermsTextDialog] = useState<
    boolean
  >(false); /**< To Show Web terms text dialog. */
  const [
    selectedLanguageTranslation,
    setSelectedLanguageTranslation
  ] = useState<IStoreTranslation>(
    values?.translationData[
      values.translationData?.findIndex(
        (data: IStoreTranslation) => data.language === selectedLanguage
      )
    ]
  ); /**< Selected language translation data. */
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    values?.translationData?.findIndex(
      (data: IStoreTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = values?.translationData?.findIndex(
    (data: IStoreTranslation) => data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */
  const [webTermsTextHTML, setWebTermsTextHTML] = useState<string>(
    selectedLanguageTranslation?.webTermsText ?? ''
  ); /**< Web terms text HTML */
  const [
    isShowTemplateWebTermsTextDialog,
    setShowTemplateWebTermsTextDialog
  ] = useState<boolean>(false); /**< To Show template Web terms text dialog. */
  const [templateWebTermsTextHTML, setTemplateWebTermsTextHTML] = useState<
    string
  >(
    selectedLanguageTranslation?.templateWebTermsText ?? ''
  ); /**< Template Web terms text HTML */
  const [isShowSLATermsTextDialog, setShowSLATermsTextDialog] = useState<
    boolean
  >(false); /**< To Show SLA terms text dialog. */
  const [slaTermsTextHTML, setSlaTermsTextHTML] = useState<string>(
    selectedLanguageTranslation?.slaTermsText ?? ''
  ); /**< SLA terms text HTML */
  const [
    isShowTemplateSLATermsTextDialog,
    setShowTemplateSLATermsTextDialog
  ] = useState<boolean>(false); /**< To Show Template SLA terms text dialog. */
  const [templateSLATermsTextHTML, setTemplateSLATermsTextHTML] = useState<
    string
  >(
    selectedLanguageTranslation?.templateSLATermsText ?? ''
  ); /**< Template SLA terms text HTML */
  const webTermsTextHTMLElement: HTMLSpanElement = document.createElement(
    'span'
  );
  const templateWebTermsTextHTMLElement: HTMLSpanElement = document.createElement(
    'span'
  );
  const slaTermsTextHTMLElement: HTMLSpanElement = document.createElement(
    'span'
  );
  const templateSLATermsTextHTMLElement: HTMLSpanElement = document.createElement(
    'span'
  );
  const [webTermsTextValue, setWebTermsTextValue] = useState<
    string
  >(); /**< Web terms text value */
  const [templateWebTermsTextValue, setTemplateWebTermsTextValue] = useState<
    string
  >(); /**< Template Web terms text value */
  const [slaTermsTextValue, setSlaTermsTextValue] = useState<
    string
  >(); /**< SLA terms text value */
  const [templateSLATermsTextValue, setTemplateSLATermsTextValue] = useState<
    string
  >(); /**< Template SLA terms text value */

  useEffect(() => {
    const selectedIndex: number = values?.translationData?.findIndex(
      (data: IStoreTranslation) => data.language === selectedLanguage
    );
    setSelectedLanguageTranslationIndex(selectedIndex);
    setSelectedLanguageTranslation(
      values.translationData &&
        values.translationData.length &&
        values.translationData[selectedIndex]
    );
  }, [selectedLanguage, values.translationData]);

  useEffect(() => {
    webTermsTextHTMLElement.innerHTML =
      selectedLanguageTranslation?.webTermsText ?? '';
    setWebTermsTextValue(webTermsTextHTMLElement.innerText);
  }, [values.translationData, selectedLanguageTranslation]);

  useEffect(() => {
    const textHTML: string = selectedLanguageTranslation?.webTermsText ?? '';
    setWebTermsTextHTML(textHTML);
  }, [selectedLanguageTranslation]);

  useEffect(() => {
    templateWebTermsTextHTMLElement.innerHTML =
      selectedLanguageTranslation?.templateWebTermsText ?? '';
    setTemplateWebTermsTextValue(templateWebTermsTextHTMLElement.innerText);
  }, [values.translationData, selectedLanguageTranslation]);

  useEffect(() => {
    const textHTML: string =
      selectedLanguageTranslation?.templateWebTermsText ?? '';
    setTemplateWebTermsTextHTML(textHTML);
  }, [selectedLanguageTranslation]);

  useEffect(() => {
    slaTermsTextHTMLElement.innerHTML =
      selectedLanguageTranslation?.slaTermsText ?? '';
    setSlaTermsTextValue(slaTermsTextHTMLElement.innerText);
  }, [values.translationData, selectedLanguageTranslation]);

  useEffect(() => {
    const textHTML: string = selectedLanguageTranslation?.slaTermsText ?? '';
    setSlaTermsTextHTML(textHTML);
  }, [selectedLanguageTranslation]);

  useEffect(() => {
    templateSLATermsTextHTMLElement.innerHTML =
      selectedLanguageTranslation?.templateSLATermsText ?? '';
    setTemplateSLATermsTextValue(templateSLATermsTextHTMLElement.innerText);
  }, [values.translationData, selectedLanguageTranslation]);

  useEffect(() => {
    const textHTML: string =
      selectedLanguageTranslation?.templateSLATermsText ?? '';
    setTemplateSLATermsTextHTML(textHTML);
  }, [selectedLanguageTranslation]);

  // To check logo update exists or not.
  useEffect(() => {
    if (
      (logoUploadStatus === 'delete' && !settings.logo) ||
      !logoUploadStatus
    ) {
      setLogoUpdateExists(false);
    } else if (logoUploadStatus) {
      setLogoUpdateExists(true);
    }
  }, [logoUploadStatus]);

  // To check favouriteicon update exists or not.
  useEffect(() => {
    if (
      (favouriteIconUploadStatus === 'delete' && !settings.favicon) ||
      !favouriteIconUploadStatus
    ) {
      setFavouriteIconUpdateExists(false);
    } else if (favouriteIconUploadStatus) {
      setFavouriteIconUpdateExists(true);
    }
  }, [favouriteIconUploadStatus]);

  /// Show save popup
  const handleSave = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event?.target);
  };

  /// Save on Accept
  const onAccept = () => {
    setShowConfirmationPopup(false);
    handleSubmit();
    setApplicationSettingsUpdatesExist(false);
    setLogoUpdateExists(false);
    setFavouriteIconUpdateExists(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmationPopup(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={translation('confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidateApplicationSettingsUpdates(event.target);
  };

  /// Validate Application Settings updates
  const revalidateApplicationSettingsUpdates = (currentUpdate: any): void => {
    const applicationSettingsValues: IApplicationSettings = { ...values };
    if (applicationSettingsValues) {
      applicationSettingsValues[
        currentUpdate.id as keyof IApplicationSettings
      ] = currentUpdate.value;
    }
    setApplicationSettingsUpdatesExist(
      checkIfApplicationSettingsUpdatesExists(applicationSettingsValues)
    );
  };

  /// Check ApplicationSettings updates exists or not.
  const checkIfApplicationSettingsUpdatesExists = (
    applicationSettingsValues: IApplicationSettings
  ): boolean => {
    return (
      applicationSettingsValues &&
      Object.keys(mapApplicationSettingsValues(applicationSettingsValues))
        .length !== 0
    );
  };

  /// ApplicationSettings Values
  const mapApplicationSettingsValues = (
    applicationSettingsValues: IApplicationSettings
  ) => {
    const payload: IApplicationSettings = {};
    if (!settings) {
      return {};
    }
    if (
      settings.applicationTitle !== applicationSettingsValues.applicationTitle
    ) {
      payload['applicationTitle'] = applicationSettingsValues.applicationTitle;
    }
    if (settings.salesEmail !== applicationSettingsValues.salesEmail) {
      payload['salesEmail'] = applicationSettingsValues.salesEmail;
    }
    if (
      settings.userAuthenticationVerificationURL !==
      applicationSettingsValues.userAuthenticationVerificationURL
    ) {
      payload['userAuthenticationVerificationURL'] =
        applicationSettingsValues.userAuthenticationVerificationURL;
    }
    if (
      settings.userFrontendBaseURL !==
      applicationSettingsValues.userFrontendBaseURL
    ) {
      payload['userFrontendBaseURL'] =
        applicationSettingsValues.userFrontendBaseURL;
    }
    if (
      settings.licenseInstanceLockTimeout !=
      applicationSettingsValues.licenseInstanceLockTimeout
    ) {
      payload['licenseInstanceLockTimeout'] =
        applicationSettingsValues.licenseInstanceLockTimeout;
    }
    if (settings.adminRoleUUID !== applicationSettingsValues.adminRoleUUID) {
      payload['adminRoleUUID'] = applicationSettingsValues.adminRoleUUID;
    }
    if (
      settings.productManagerRoleUUID !==
      applicationSettingsValues.productManagerRoleUUID
    ) {
      payload['productManagerRoleUUID'] =
        applicationSettingsValues.productManagerRoleUUID;
    }
    if (settings.salesRoleUUID !== applicationSettingsValues.salesRoleUUID) {
      payload['salesRoleUUID'] = applicationSettingsValues.salesRoleUUID;
    }
    if (settings.rssFeedUrl !== applicationSettingsValues.rssFeedUrl) {
      payload['rssFeedUrl'] = applicationSettingsValues.rssFeedUrl;
    }
    if (
      settings.renewalRefreshTokenPeriodInSeconds !=
      applicationSettingsValues.renewalRefreshTokenPeriodInSeconds
    ) {
      payload['renewalRefreshTokenPeriodInSeconds'] =
        applicationSettingsValues.renewalRefreshTokenPeriodInSeconds;
    }
    if (settings.cacheFeedsTTL != applicationSettingsValues.cacheFeedsTTL) {
      payload['cacheFeedsTTL'] = applicationSettingsValues.cacheFeedsTTL;
    }
    if (settings.rssDelay != applicationSettingsValues.rssDelay) {
      payload['rssDelay'] = applicationSettingsValues.rssDelay;
    }
    if (settings.baseURL !== applicationSettingsValues.baseURL) {
      payload['baseURL'] = applicationSettingsValues.baseURL;
    }
    if (settings.refundWindow != applicationSettingsValues.refundWindow) {
      payload['refundWindow'] = applicationSettingsValues.refundWindow;
    }
    if (
      settings.jobsLockDuration != applicationSettingsValues.jobsLockDuration
    ) {
      payload['jobsLockDuration'] = applicationSettingsValues.jobsLockDuration;
    }
    if (
      settings.unlicensedTransactionTimeLimit !=
      applicationSettingsValues.unlicensedTransactionTimeLimit
    ) {
      payload['unlicensedTransactionTimeLimit'] =
        applicationSettingsValues.unlicensedTransactionTimeLimit;
    }
    if (
      settings.frontendBaseURL !== applicationSettingsValues.frontendBaseURL
    ) {
      payload['frontendBaseURL'] = applicationSettingsValues.frontendBaseURL;
    }
    if (
      settings.uniqueProductSummaryTimeslot !=
      applicationSettingsValues.uniqueProductSummaryTimeslot
    ) {
      payload['uniqueProductSummaryTimeslot'] =
        applicationSettingsValues.uniqueProductSummaryTimeslot;
    }
    if (
      settings.googleAddressValidationAPIKey !=
      applicationSettingsValues.googleAddressValidationAPIKey
    ) {
      payload['googleAddressValidationAPIKey'] =
        applicationSettingsValues.googleAddressValidationAPIKey;
    }
    if (
      settings.isSubscriptionPaymentNotificationEmailEnabled !==
      applicationSettingsValues.isSubscriptionPaymentNotificationEmailEnabled
    ) {
      payload['isSubscriptionPaymentNotificationEmailEnabled'] =
        applicationSettingsValues.isSubscriptionPaymentNotificationEmailEnabled;
    }
    return payload;
  };

  // Set field value
  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    const applicationSettingsValues: IApplicationSettings = { ...values };
    if (applicationSettingsValues) {
      applicationSettingsValues[key as keyof IApplicationSettings] = value;
    }
    setApplicationSettingsUpdatesExist(
      checkIfApplicationSettingsUpdatesExists(applicationSettingsValues)
    );
  };

  return (
    <Fieldset legend={translation('setting.headers.change_store_settings')}>
      <Form>
        <Row>
          <Form.Group as={Col} sm="12">
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </Form.Group>
        </Row>
        <div className="mb-2">
          <SettingsLogoUpload
            logo={settings.logo}
            uploadLogo={uploadLogo}
            deleteLogo={deleteLogo}
            errorHandler={errorHandler}
            displayCroppedLogo={croppedLogo}
            changeInputClassName={changeInputClassName}
          >
            {RenderSkeletonComponent(true)}
          </SettingsLogoUpload>
          <SettingsFaviconUpload
            favoriteIcon={settings.favicon}
            uploadFavoriteIcon={uploadFavoriteIcon}
            deleteFavoriteIcon={deleteFavoriteIcon}
            errorHandler={errorHandler}
            displayCroppedLogo={croppedFavouriteIcon}
            changeInputClassName={changeInputClassName}
          >
            {RenderSkeletonComponent(true)}
          </SettingsFaviconUpload>
        </div>
        <Row>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.applicationTitle')}
              name="applicationTitle"
              id={'applicationTitle'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.applicationTitle}
              errors={errors.applicationTitle}
              value={values.applicationTitle}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.applicationTitle_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.webTermsText')}
              name={`translationData.${selectedLanguageTranslationIndex}.webTermsText`}
              id={`translationData.${selectedLanguageTranslationIndex}.webTermsText`}
              onBlur={handleBlur}
              touched={
                touched.translationData &&
                touched.translationData.length &&
                touched.translationData[selectedLanguageTranslationIndex] &&
                touched.translationData[selectedLanguageTranslationIndex]
                  .webTermsText
              }
              errors={
                errors.translationData &&
                errors.translationData.length &&
                errors.translationData[selectedLanguageTranslationIndex] &&
                errors.translationData[selectedLanguageTranslationIndex]
                  .webTermsText
              }
              value={webTermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName={
                selectedLanguage === LanguageSettingsMode.english
                  ? 'required'
                  : ''
              }
              placeholder={i18n.t('fields.settings.webTermsTextPlaceholder')}
              onClick={() => {
                setShowWebTermsTextDialog(true);
              }}
              role="button"
              readOnly
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.webTermsText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.templateWebTermsText')}
              name={`translationData.${selectedLanguageTranslationIndex}.templateWebTermsText`}
              id={`translationData.${selectedLanguageTranslationIndex}.templateWebTermsText`}
              onBlur={handleBlur}
              touched={
                touched?.translationData?.[selectedLanguageTranslationIndex]
                  ?.templateWebTermsText
              }
              errors={
                errors?.translationData?.[selectedLanguageTranslationIndex]
                  ?.templateWebTermsText
              }
              value={templateWebTermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName={
                selectedLanguage === LanguageSettingsMode.english
                  ? 'required'
                  : ''
              }
              placeholder={i18n.t(
                'fields.settings.templateWebTermsTextPlaceholder'
              )}
              onClick={() => {
                setShowTemplateWebTermsTextDialog(true);
              }}
              role="button"
              readOnly
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.templateWebTermsText_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.slaTermsText')}
              name={`translationData.${selectedLanguageTranslationIndex}.slaTermsText`}
              id={`translationData.${selectedLanguageTranslationIndex}.slaTermsText`}
              onBlur={handleBlur}
              touched={
                touched.translationData &&
                touched.translationData.length &&
                touched.translationData[selectedLanguageTranslationIndex] &&
                touched.translationData[selectedLanguageTranslationIndex]
                  .slaTermsText
              }
              errors={
                errors.translationData &&
                errors.translationData.length &&
                errors.translationData[selectedLanguageTranslationIndex] &&
                errors.translationData[selectedLanguageTranslationIndex]
                  .slaTermsText
              }
              value={slaTermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName={
                selectedLanguage === LanguageSettingsMode.english
                  ? 'required'
                  : ''
              }
              placeholder={i18n.t('fields.settings.slaTermsTextPlaceholder')}
              onClick={() => {
                setShowSLATermsTextDialog(true);
              }}
              role="button"
              readOnly
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.slaTermsText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.templateSLATermsText')}
              name={`translationData.${selectedLanguageTranslationIndex}.templateSLATermsText`}
              id={`translationData.${selectedLanguageTranslationIndex}.templateSLATermsText`}
              onBlur={handleBlur}
              touched={
                touched?.translationData?.[selectedLanguageTranslationIndex]
                  ?.templateSLATermsText
              }
              errors={
                errors?.translationData?.[selectedLanguageTranslationIndex]
                  ?.templateSLATermsText
              }
              value={templateSLATermsTextValue}
              spanClassName={changeInputClassName()}
              labelClassName={
                selectedLanguage === LanguageSettingsMode.english
                  ? 'required'
                  : ''
              }
              placeholder={i18n.t(
                'fields.settings.templateSLATermsTextPlaceholder'
              )}
              onClick={() => {
                setShowTemplateSLATermsTextDialog(true);
              }}
              role="button"
              readOnly
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.templateSLATermsText_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.salesEmail')}
              name="salesEmail"
              id={'salesEmail'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.salesEmail}
              errors={errors.salesEmail}
              value={values.salesEmail}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.salesEmail_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t(
                'fields.settings.userAuthenticationVerificationURL'
              )}
              name="userAuthenticationVerificationURL"
              id={'userAuthenticationVerificationURL'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.userAuthenticationVerificationURL}
              errors={errors.userAuthenticationVerificationURL}
              value={values.userAuthenticationVerificationURL}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.userAuthenticationVerificationURL_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.userFrontendBaseURL')}
              name="userFrontendBaseURL"
              id={'userFrontendBaseURL'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.userFrontendBaseURL}
              errors={errors.userFrontendBaseURL}
              value={values.userFrontendBaseURL}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.userFrontendBaseURL_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.licenseInstanceLockTimeout')}
              name="licenseInstanceLockTimeout"
              id={'licenseInstanceLockTimeout'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.licenseInstanceLockTimeout}
              errors={errors.licenseInstanceLockTimeout}
              value={values.licenseInstanceLockTimeout}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.licenseInstanceLockTimeout_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.adminRoleUUID')}
              name="adminRoleUUID"
              id={'adminRoleUUID'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.adminRoleUUID}
              errors={errors.adminRoleUUID}
              value={values.adminRoleUUID}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.adminRoleUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.productManagerRoleUUID')}
              name="productManagerRoleUUID"
              id={'productManagerRoleUUID'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.productManagerRoleUUID}
              errors={errors.productManagerRoleUUID}
              value={values.productManagerRoleUUID}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.productManagerRoleUUID_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.salesRoleUUID')}
              name="salesRoleUUID"
              id={'salesRoleUUID'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.salesRoleUUID}
              errors={errors.salesRoleUUID}
              value={values.salesRoleUUID}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.salesRoleUUID_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.rssFeedUrl')}
              name="rssFeedUrl"
              id={'rssFeedUrl'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.rssFeedUrl}
              errors={errors.rssFeedUrl}
              value={values.rssFeedUrl}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.rssFeedUrl_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t('fields.settings.rssDelay')}
              name="rssDelay"
              id={'rssDelay'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.rssDelay}
              errors={errors.rssDelay}
              value={values.rssDelay}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.rssDelay_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t(
                'fields.settings.renewalRefreshTokenPeriodInSeconds'
              )}
              name="renewalRefreshTokenPeriodInSeconds"
              id={'renewalRefreshTokenPeriodInSeconds'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.renewalRefreshTokenPeriodInSeconds}
              errors={errors.renewalRefreshTokenPeriodInSeconds}
              value={values.renewalRefreshTokenPeriodInSeconds}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.renewalRefreshTokenPeriodInSeconds_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t('fields.settings.cacheFeedsTTL')}
              name="cacheFeedsTTL"
              id={'cacheFeedsTTL'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.cacheFeedsTTL}
              errors={errors.cacheFeedsTTL}
              value={values.cacheFeedsTTL}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.cacheFeedsTTL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.baseURL')}
              name="baseURL"
              id={'baseURL'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.baseURL}
              errors={errors.baseURL}
              value={values.baseURL}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.baseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t('fields.settings.refundWindow')}
              name="refundWindow"
              id={'refundWindow'}
              onChange={onChange}
              labelClassName="required"
              onBlur={handleBlur}
              touched={touched.refundWindow}
              errors={errors.refundWindow}
              value={values.refundWindow}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.refundWindow_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t('fields.settings.jobsLockDuration')}
              name="jobsLockDuration"
              id={'jobsLockDuration'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.jobsLockDuration}
              errors={errors.jobsLockDuration}
              value={values.jobsLockDuration}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.jobsLockDuration_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              type="number"
              label={i18n.t('fields.settings.unlicensedTransactionTimeLimit')}
              name="unlicensedTransactionTimeLimit"
              id={'unlicensedTransactionTimeLimit'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.unlicensedTransactionTimeLimit}
              errors={errors.unlicensedTransactionTimeLimit}
              value={values.unlicensedTransactionTimeLimit}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.unlicensedTransactionTimeLimit_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.frontendBaseURL')}
              name="frontendBaseURL"
              id={'frontendBaseURL'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.frontendBaseURL}
              errors={errors.frontendBaseURL}
              value={values.frontendBaseURL}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t('fields.settings.frontendBaseURL_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.uniqueProductSummaryTimeslot')}
              name="uniqueProductSummaryTimeslot"
              id={'uniqueProductSummaryTimeslot'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.uniqueProductSummaryTimeslot}
              errors={errors.uniqueProductSummaryTimeslot}
              value={values.uniqueProductSummaryTimeslot}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.uniqueProductSummaryTimeslot_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.settings.googleAddressValidationAPIKey')}
              name="googleAddressValidationAPIKey"
              id={'googleAddressValidationAPIKey'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.googleAddressValidationAPIKey}
              errors={errors.googleAddressValidationAPIKey}
              value={values.googleAddressValidationAPIKey}
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={i18n.t(
                'fields.settings.googleAddressValidationAPIKey_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <InstaTextArea
            sizing={{ sm: 12, md: 12 }}
            label={i18n.t('fields.settings.loggedOutWarningMessage')}
            name={`translationData.${selectedLanguageTranslationIndex}.loggedOutWarningMessage`}
            id={`translationData.${selectedLanguageTranslationIndex}.loggedOutWarningMessage`}
            onChange={(event: any) => {
              handleChange(event);
              if (
                event.target.value !==
                settings?.translationData?.[selectedLanguageTranslationIndex]
                  ?.loggedOutWarningMessage
              ) {
                setApplicationSettingsUpdatesExist(true);
              }
            }}
            onBlur={handleBlur}
            touched={
              touched?.translationData?.[selectedLanguageTranslationIndex]
                ?.loggedOutWarningMessage
            }
            errors={
              errors?.translationData?.[selectedLanguageTranslationIndex]
                ?.loggedOutWarningMessage
            }
            value={
              values?.translationData?.[selectedLanguageTranslationIndex]
                ?.loggedOutWarningMessage ?? ''
            }
            labelClassName={
              selectedLanguage === LanguageSettingsMode.english
                ? 'required'
                : ''
            }
            spanClassName={changeInputClassName()}
            isShowInformationPopup
            popupText={i18n.t(
              'fields.settings.loggedOutWarningMessage_popupText'
            )}
            rows={2}
          />
          <Form.Group as={Col} sm="12">
            <InstaCheckbox
              label={i18n.t(
                'fields.settings.isSubscriptionPaymentNotificationEmailEnabled'
              )}
              id={'isSubscriptionPaymentNotificationEmailEnabled'}
              errors={errors}
              name={'isSubscriptionPaymentNotificationEmailEnabled'}
              onBlur={handleBlur}
              onChange={(event: any) => {
                setFieldValueLocal(
                  'isSubscriptionPaymentNotificationEmailEnabled',
                  event.checked
                );
              }}
              touched={touched}
              value={values.isSubscriptionPaymentNotificationEmailEnabled}
              isShowInformationPopup
              popupText={i18n.t(
                'fields.settings.isSubscriptionPaymentNotificationEmailEnabled_popupText'
              )}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
        </Row>
        <Row>
          <Col sm={12} className="clearfix pb-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                loading ||
                isShowConfirmationPopup ||
                (!isApplicationSettingsUpdatesExist &&
                  !isLogoUpdateExists &&
                  !isFavouriteIconUpdateExists) ||
                isApplicationSettingsErrorsExists
                  ? 'custom-disabled-button'
                  : ''
              }`}
              variant="primary"
              disabled={
                loading ||
                isShowConfirmationPopup ||
                (!isApplicationSettingsUpdatesExist &&
                  !isLogoUpdateExists &&
                  !isFavouriteIconUpdateExists) ||
                isApplicationSettingsErrorsExists
              }
              onClick={handleSave}
            >
              {translation('setting.save')}
            </Button>
          </Col>
        </Row>
      </Form>
      {getConfirmPopup()}
      <TextEditorDialog
        isShowDialog={isShowWebTermsTextDialog}
        setShowDialog={setShowWebTermsTextDialog}
        formikValue={selectedLanguageTranslation?.webTermsText ?? ''}
        settingValue={
          settings.translationData &&
          settings.translationData.length &&
          settings.translationData[selectedLanguageTranslationIndex]
            ?.webTermsText
        }
        textHTML={webTermsTextHTML}
        setTextHTML={setWebTermsTextHTML}
        editHeaderText={i18n.t('fields.settings.edit-webTermsText')}
        addHeaderText={i18n.t('fields.settings.add-webTermsText')}
        formikField={`translationData.${selectedLanguageTranslationIndex}.webTermsText`}
        isLoading={loading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />
      <TextEditorDialog
        isShowDialog={isShowTemplateWebTermsTextDialog}
        setShowDialog={setShowTemplateWebTermsTextDialog}
        formikValue={selectedLanguageTranslation?.templateWebTermsText ?? ''}
        settingValue={
          settings?.translationData?.[selectedLanguageTranslationIndex]
            ?.templateWebTermsText
        }
        textHTML={templateWebTermsTextHTML}
        setTextHTML={setTemplateWebTermsTextHTML}
        editHeaderText={i18n.t('fields.settings.edit-templateWebTermsText')}
        addHeaderText={i18n.t('fields.settings.add-templateWebTermsText')}
        formikField={`translationData.${selectedLanguageTranslationIndex}.templateWebTermsText`}
        isLoading={loading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />
      <TextEditorDialog
        isShowDialog={isShowSLATermsTextDialog}
        setShowDialog={setShowSLATermsTextDialog}
        formikValue={selectedLanguageTranslation?.slaTermsText ?? ''}
        settingValue={
          settings.translationData &&
          settings.translationData.length &&
          settings.translationData[selectedLanguageTranslationIndex]
            ?.slaTermsText
        }
        textHTML={slaTermsTextHTML}
        setTextHTML={setSlaTermsTextHTML}
        editHeaderText={i18n.t('fields.settings.edit-slaTermsText')}
        addHeaderText={i18n.t('fields.settings.add-slaTermsText')}
        formikField={`translationData.${selectedLanguageTranslationIndex}.slaTermsText`}
        isLoading={loading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />
      <TextEditorDialog
        isShowDialog={isShowTemplateSLATermsTextDialog}
        setShowDialog={setShowTemplateSLATermsTextDialog}
        formikValue={selectedLanguageTranslation?.templateSLATermsText ?? ''}
        settingValue={
          settings?.translationData?.[selectedLanguageTranslationIndex]
            ?.templateSLATermsText
        }
        textHTML={templateSLATermsTextHTML}
        setTextHTML={setTemplateSLATermsTextHTML}
        editHeaderText={i18n.t('fields.settings.edit-templateSLATermsText')}
        addHeaderText={i18n.t('fields.settings.add-templateSLATermsText')}
        formikField={`translationData.${selectedLanguageTranslationIndex}.templateSLATermsText`}
        isLoading={loading}
        setFieldValue={setFieldValue}
        setApplicationSettingsUpdatesExist={setApplicationSettingsUpdatesExist}
      />
    </Fieldset>
  );
};

export default withFormik({
  mapPropsToValues: ({ settings }) => {
    // Format inital store translation
    const initialTranslationData: IStoreTranslation[] = [];
    for (let index = 0; index < Languages.length; index++) {
      const language: string = Languages[index].value;
      initialTranslationData.push({
        language: language,
        webTermsText: '',
        slaTermsText: '',
        loggedOutWarningMessage: '',
        templateWebTermsText: '',
        templateSLATermsText: ''
      });
    }

    if (!settings) {
      return {
        applicationTitle: '',
        salesEmail: '',
        userAuthenticationVerificationURL: '',
        userFrontendBaseURL: '',
        licenseInstanceLockTimeout: 0,
        adminRoleUUID: '',
        productManagerRoleUUID: '',
        salesRoleUUID: '',
        rssFeedUrl: '',
        renewalRefreshTokenPeriodInSeconds: '',
        cacheFeedsTTL: 0,
        rssDelay: 0,
        baseURL: '',
        refundWindow: 0,
        jobsLockDuration: 0,
        unlicensedTransactionTimeLimit: 0,
        frontendBaseURL: '',
        uniqueProductSummaryTimeslot: 0,
        googleAddressValidationAPIKey: '',
        translationData: initialTranslationData,
        isSubscriptionPaymentNotificationEmailEnabled: false
      };
    }

    // Note: Get the store translation if it already exists and corresponds to the same language array. Otherwise, add/delete a language object or use initialTranslationData.
    let storeTranslationData: IStoreTranslation[] = [];
    if (settings.translationData && settings.translationData.length) {
      initialTranslationData.forEach((initialData: IStoreTranslation) => {
        settings.translationData.forEach((existingData: IStoreTranslation) => {
          if (initialData.language === existingData.language) {
            storeTranslationData.push(existingData);
          }
        });
      });
      if (storeTranslationData.length !== initialTranslationData.length) {
        const newLanguageData: IStoreTranslation[] = initialTranslationData.filter(
          (initialData: IStoreTranslation) =>
            !storeTranslationData.some(
              (existingData: IStoreTranslation) =>
                initialData.language === existingData.language
            )
        );
        storeTranslationData = storeTranslationData.concat(newLanguageData);
      }
    } else {
      storeTranslationData = initialTranslationData;
    }

    return {
      applicationTitle: settings.applicationTitle,
      salesEmail: settings.salesEmail,
      userAuthenticationVerificationURL:
        settings.userAuthenticationVerificationURL,
      userFrontendBaseURL: settings.userFrontendBaseURL,
      licenseInstanceLockTimeout: settings.licenseInstanceLockTimeout,
      adminRoleUUID: settings.adminRoleUUID,
      productManagerRoleUUID: settings.productManagerRoleUUID,
      salesRoleUUID: settings.salesRoleUUID,
      rssFeedUrl: settings.rssFeedUrl,
      renewalRefreshTokenPeriodInSeconds:
        settings.renewalRefreshTokenPeriodInSeconds,
      cacheFeedsTTL: settings.cacheFeedsTTL || 0,
      rssDelay: settings.rssDelay || 0,
      baseURL: settings.baseURL,
      refundWindow: settings.refundWindow || 0,
      jobsLockDuration: settings.jobsLockDuration || 0,
      unlicensedTransactionTimeLimit:
        settings.unlicensedTransactionTimeLimit || 0,
      frontendBaseURL: settings.frontendBaseURL,
      uniqueProductSummaryTimeslot: settings.uniqueProductSummaryTimeslot || 0,
      googleAddressValidationAPIKey: settings.googleAddressValidationAPIKey,
      translationData: storeTranslationData,
      isSubscriptionPaymentNotificationEmailEnabled:
        settings.isSubscriptionPaymentNotificationEmailEnabled
    };
  },
  validationSchema: Yup.object({
    applicationTitle: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .nullable(),
    salesEmail: Yup.string()
      .max(200, 'Must be 200 characters or less')
      .test('multipleEmails', 'each string must be an email', function(value) {
        const emails = value && value.replace(/\s/g, '').split(',');
        const emailIsValid = (email) => {
          const schema = Yup.string().email();
          return schema.isValidSync(email);
        };
        return emails && emails.every(emailIsValid);
      }),
    userAuthenticationVerificationURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, 'Must be a valid URL')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.userAuthenticationVerificationURL')
        })
      ),
    userFrontendBaseURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, 'Must be a valid URL')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.userFrontendBaseURL')
        })
      ),
    licenseInstanceLockTimeout: Yup.number()
      .moreThan(-1, 'Must be positive number')
      .typeError('Must be positive number'),
    adminRoleUUID: Yup.string()
      .min(36, 'Must be 36 characters')
      .max(36, 'Must be 36 characters')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.adminRoleUUID')
        })
      ),
    productManagerRoleUUID: Yup.string()
      .min(36, 'Must be 36 characters')
      .max(36, 'Must be 36 characters')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.productManagerRoleUUID')
        })
      ),
    salesRoleUUID: Yup.string()
      .min(36, 'Must be 36 characters')
      .max(36, 'Must be 36 characters')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.salesRoleUUID')
        })
      ),
    rssFeedUrl: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, 'Must be a valid URL'),
    renewalRefreshTokenPeriodInSeconds: Yup.number()
      .min(
        defaultRenewalRefreshTokenPeriodInSeconds,
        'Must be 5 minutes or longer'
      )
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.renewalRefreshTokenPeriodInSeconds')
        })
      ),
    cacheFeedsTTL: Yup.number().nullable(),
    rssDelay: Yup.number().nullable(),
    baseURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, 'Must be a valid URL')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.baseURL')
        })
      ),
    refundWindow: Yup.number()
      .moreThan(-1, 'Must be positive number')
      .typeError('Must be positive number')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.refundWindow')
        })
      ),
    jobsLockDuration: Yup.number()
      .moreThan(-1, 'Must be positive number')
      .typeError('Must be positive number')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.jobsLockDuration')
        })
      ),
    unlicensedTransactionTimeLimit: Yup.number()
      .moreThan(-1, 'Must be positive number')
      .typeError('Must be positive number')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.unlicensedTransactionTimeLimit')
        })
      ),
    frontendBaseURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, 'Must be a valid URL')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.frontendBaseURL')
        })
      ),
    uniqueProductSummaryTimeslot: Yup.number()
      .moreThan(-1, 'Must be positive number')
      .typeError('Must be positive number')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.settings.uniqueProductSummaryTimeslot')
        })
      ),
    googleAddressValidationAPIKey: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less'),
    translationData: Yup.array().of(
      Yup.object().shape({
        webTermsText: Yup.string().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.string()
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.settings.webTermsTextError')
              })
            ),
          otherwise: Yup.string().nullable()
        }),
        slaTermsText: Yup.string().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.string()
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.settings.slaTermsTextError')
              })
            ),
          otherwise: Yup.string().nullable()
        }),
        loggedOutWarningMessage: Yup.string().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.string().required(
            i18n.t('validation.required', {
              field: i18n.t('fields.settings.loggedOutWarningMessage')
            })
          ),
          otherwise: Yup.string().nullable()
        }),
        templateSLATermsText: Yup.string().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.string()
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.settings.templateSLATermsTextError')
              })
            ),
          otherwise: Yup.string().nullable()
        }),
        templateWebTermsText: Yup.string().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.string()
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.settings.templateWebTermsTextError')
              })
            ),
          otherwise: Yup.string().nullable()
        })
      })
    ),
    isSubscriptionPaymentNotificationEmailEnabled: Yup.boolean().oneOf([
      true,
      false
    ])
  }),
  enableReinitialize: true,
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit({
      ...values,
      ...bag.props.adminSettings.smtp,
      ...bag.props.adminSettings.braintree
    });
  }
})(SettingsForm);
