/**
* BillingEntity.ts (AbstractECommerce) *

* Copyright © 2024 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2024 
* @file BillingEntity.ts
* @author Rafael Rodrigues
* @copyright 2024 InstaLOD GmbH. All rights reserved.
* @section License
*/

import { TFunction } from 'i18next';

import { VatAcronyms } from '@abstract/abstractwebcommon-shared/enum/ecommerce/vatAcronyms';

/**
 * Defines if the vat id field should have the label "VAT ID" or "VAT ID or TAX ID" based on the selected country acronym.
 */
export const getTaxIDLabelForCountry = (selectedCountryAcronym: VatAcronyms | null, translation: TFunction): string => {
  // EU countries should return 'VAT ID', while others countries should return 'VAT ID or TAX ID'.
  return selectedCountryAcronym == null || Object.values(VatAcronyms).includes(selectedCountryAcronym)
    ? translation('fields.customer.vatId')
    : translation('fields.customer.vatOrTaxId')
}