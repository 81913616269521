/**
* CheckoutSummaryCondition.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file CheckoutSummaryCondition.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import './CheckoutSummary.css';

/**
 * Interface for CheckoutSummaryCondition properties.
 */
interface ICheckoutSummaryConditionProperties {
  description: any;
  value: any;
  onChange: (value: any) => void;
  keyIndex: string; /**< Key Index */
  isDisabled: boolean; /**< To disable the product options or not */
}

const CheckoutSummaryCondition = ({
  description,
  value,
  onChange = () => {},
  keyIndex,
  isDisabled
}: ICheckoutSummaryConditionProperties) => {
  const [checked, setChecked] = useState(value);
  useEffect(() => {
    setChecked(value);
  }, [value]);
  return (
    <div className="summary-checkbox-container d-flex float-left">
      <div>
        <Checkbox
          inputId={keyIndex}
          className="mr-4"
          checked={checked}
          onChange={(e) => {
            onChange(e.checked);
            setChecked(e.checked);
          }}
          disabled={isDisabled}
        />
      </div>
      <div>
        <label
          htmlFor={keyIndex}
          className="condition"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default CheckoutSummaryCondition;
