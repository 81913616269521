/*
 * ProductSLAInformationForm.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ProductSLAInformationForm.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import './ProductFormOptions.css';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * @interface IProductSLAInformationFormProperties
 */
interface IProductSLAInformationFormProperties {
  values: IProductTranslation[] | undefined;
  errors: any;
  handleChange: (event: any) => void;
  loading: boolean;
  handleBlur: (event: any) => void;
  touched: any;
  setFieldValue: any;
  selectedLanguage: string /**< Selected language */;
  selectedLanguageTranslationIndex: number /**< Selected language translation data index */;
  englishTranslationIndex: number /**< English translation data index */;
}

const ProductSLAInformationForm = (
  properties: IProductSLAInformationFormProperties
): JSX.Element => {
  const selectedLanguageTranslationIndex: number =
    properties.selectedLanguageTranslationIndex;
  const englishTranslationIndex: number = properties.englishTranslationIndex;
  let slaInformation: string[] =
    ((properties.values &&
      properties.values.length &&
      properties.values[englishTranslationIndex] &&
      properties.values[englishTranslationIndex].slaInformation) as string[]) ??
    [];

  /// Alwasy show 4 fields to add or edit SLA
  if (slaInformation && !slaInformation.length) {
    slaInformation = ['', '', '', ''];
  } else if (slaInformation && slaInformation.length < 4) {
    for (let i = slaInformation.length; i < 4; i++) {
      slaInformation = slaInformation.concat('');
    }
  }
  const translation = useTranslation().t;

  const renderInformationList = (formikArray: FieldArrayRenderProps) =>
    slaInformation &&
    slaInformation.map((information: any, index: number) => (
      <Row key={index}>
        <Col>
          <Row>
            <Form.Group as={Col} md="12" sm="12">
              <InstaInputText
                label={
                  index === 0
                    ? translation('fields.product.misc_info')
                    : translation('fields.product.license_software', {
                        index: index
                      })
                }
                name={`translationData.${selectedLanguageTranslationIndex}.slaInformation.${index}`}
                id={`translationData.${selectedLanguageTranslationIndex}.slaInformation.${index}`}
                isLoading={properties.loading}
                onChange={properties.handleChange}
                onBlur={properties.handleBlur}
                value={
                  (properties.values &&
                    properties.values.length &&
                    properties.values[selectedLanguageTranslationIndex] &&
                    properties.values[selectedLanguageTranslationIndex]
                      .slaInformation?.[index]) ??
                  ''
                }
                touched={
                  properties.touched &&
                  properties.touched.length &&
                  properties.touched[selectedLanguageTranslationIndex] &&
                  properties.touched[selectedLanguageTranslationIndex]
                    .slaInformation?.[index]
                }
                errors={
                  properties.errors &&
                  properties.errors.length &&
                  properties.errors[selectedLanguageTranslationIndex] &&
                  properties.errors[selectedLanguageTranslationIndex]
                    .slaInformation?.[index]
                }
                placeholder={
                  index === 0
                    ? translation('fields.product.misc_info_placeholder')
                    : translation('fields.product.license_software_placeholder')
                }
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
    ));

  return (
    <FieldArray
      name={`translationData.${selectedLanguageTranslationIndex}.slaInformation`}
      render={(formikArray) => (
        <>
          {renderInformationList(formikArray)}
          <Row className={!properties.errors ? 'd-none' : 'text-center'}>
            <Col sm={12}>
              {properties.errors &&
                properties.errors.label &&
                properties.errors.field &&
                translation(properties.errors.label, {
                  field: properties.errors.field
                })}
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <ActionButton
              onClick={() => {
                formikArray.push('');
              }}
            />
          </Row>
        </>
      )}
    />
  );
};

export default React.memo(ProductSLAInformationForm);
