/*
 * Discount.ts (AbstractWebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file Discount.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { IProduct } from "./Product";


/**
 * @interface IDiscount
 */
 export interface IDiscount {
  _id: string; /**< Discount Id */
  name: string; /**< Discount name */
  discountType: string;  /**< Discount type */
  duration?: number;  /**< Discount duration in months */
  status: string;  /**< Discount status */
  amount: number;  /**< Discount amount */
  percentage: number;  /**< Discount percentage */
  code: string;  /**< Discount code */
  used: boolean;  /**< Discount used or not */
  discountProductId: string;  /**< Discount product Id */
  product: IProduct /**< Bounded product */
  usageCount?: number /**< Total Usage of discount */
  discountTerm: DiscountTerm /**< Specifies the term length of discount */
  createdAt?: Date /**< When the discount was created */
  updatedAt?: Date /**< Last date that the discount was changed */
}

/**
 * Specifies the term length of discount
 */
export enum DiscountTerm {
  Once = 'Once',
  Permanent = 'Permanent',
  Custom = 'Custom'
}