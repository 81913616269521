/*
 * AddressValidation.ts.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file AddressValidation.ts.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {checkIsAddressValid} from '../Services/AddressValidationAPIService';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {
  IAddressValidation,
  IAddressValidationResponse
} from '@abstract/abstractwebcommon-shared/interfaces/addressValidation';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const CHECK_IS_ADDRESS_VALID_REQUEST =
  'external/check/is/address/valid/request';
const CHECK_IS_ADDRESS_VALID_SUCCESS =
  'external/check/is/address/valid/success';
const CHECK_IS_ADDRESS_VALID_FAILURE =
  'external/check/is/address/valid/failure';

const INITIAL_STATE = {
  validatedAddress: null,
  isCheckingValidity: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_IS_ADDRESS_VALID_REQUEST:
      return { ...state, isCheckingValidity: true, validatedAddress: null };
    case CHECK_IS_ADDRESS_VALID_SUCCESS:
      return {
        ...state,
        validatedAddress: action.payload.validationResponse.result.address,
        isCheckingValidity: false
      };
    case CHECK_IS_ADDRESS_VALID_FAILURE:
      return {
        ...state,
        isCheckingValidity: false,
        validatedAddress: null
      };
    default:
      return state;
  }
};

const isAddressValidRequest = () => ({
  type: CHECK_IS_ADDRESS_VALID_REQUEST
});

const isAddressValidSuccess = (payload: IAddressValidationResponse) => ({
  type: CHECK_IS_ADDRESS_VALID_SUCCESS,
  payload
});

const isAddressValidFailure = () => ({
  type: CHECK_IS_ADDRESS_VALID_FAILURE
});

export const isAddressValidAction = (
  addressToValidate: IAddressValidation
) => async (dispatch) => {
  try {
    dispatch(isAddressValidRequest());
    const result: IAPIEntityResponse<IAddressValidationResponse> = await asyncErrorHandler(checkIsAddressValid(
        addressToValidate
    ));

    if (result.error) {
      handleError({ message: result.error.message as string });
      dispatch(isAddressValidFailure());
    } else {
      dispatch(isAddressValidSuccess(result as IAddressValidationResponse));
    }

    return result;
  } catch (error) {
    dispatch(isAddressValidFailure());
    handleError({
      message:
        'Address validation unsuccessful. Please try again or contact our support team.'
    });
  }
};
