/**
* ShopBody.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2020 
* @file ShopBody.tsx
* @author Timothy Fadayini
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react';

/**
 * Interface for ShopBody properties.
 */
interface IShopBodyProperties {
  children: any;
}

const ShopBody = ({ children }: IShopBodyProperties) => {
  return (
    <div id="shopcontent" className="section light-section">
      {children}
    </div>
  );
};

export default ShopBody;
