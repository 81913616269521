/**
* UserAPISlice.ts (InstaLOD GmbH) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file UserAPISlice.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section Web Common
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState
} from '@reduxjs/toolkit';
import {IAPIEntityResponse,} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {IUser} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import {Reducer} from 'react';
import {UserAPI} from '../services/UserAPI';
import {CreateErrorLog} from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";
import { LocalStorage } from '../utils/sharedLocalStorage';
import { isStringEmptyOrNullOrUndefined } from '../../Shared/utils/sharedFunctions';

export const userAPIFeatureKey: string = 'userAPI';

/**
* Interface for User API State.
*/
export interface IUserAPIState {
  updateThemeModeStateError: any;
  updateLanguageSettingsModeStateError: any /**< Language settings mode error state. */;
}

export const userAPIInitialState: IUserAPIState = {
  updateThemeModeStateError: null,
  updateLanguageSettingsModeStateError: null
}

/// Reset UserAPI state
const clearOnRequest = (state: IUserAPIState) => {
  state.updateThemeModeStateError = null;
  state.updateLanguageSettingsModeStateError = null;
};

export const userAPIAdapter: EntityAdapter<IUserAPIState> = createEntityAdapter();
export const initialUserAPIState: EntityState<IUserAPIState> & IUserAPIState =
  userAPIAdapter.getInitialState(userAPIInitialState);
  
export const userAPISlice = createSlice({
  name: userAPIFeatureKey,
  initialState: initialUserAPIState,
  reducers: {
    reset: (state: IUserAPIState) => clearOnRequest(state),
    updateUserThemeModeStateActionRequest(state: IUserAPIState) {
      state.updateThemeModeStateError = null;
    },
    updateUserThemeModeStateActionSuccess(state: any, action) {
      state.updateThemeModeStateError = null;
    },
    updateUserThemeModeStateActionFailure(state: any, action) {
      state.updateThemeModeStateError = action.payload
    },
    updateUserLanguageSettingsModeStateActionRequest(state: IUserAPIState) {
      state.updateLanguageSettingsModeStateError = null;
    },
    updateUserLanguageSettingsModeStateActionSuccess(state: any, action) {
      state.updateLanguageSettingsModeStateError = null;
    },
    updateUserLanguageSettingsModeStateActionFailure(state: any, action) {
      state.updateLanguageSettingsModeStateError = action.payload
    },
  }
});

export const userAPIReducer: Reducer<EntityState<IUserAPIState> & IUserAPIState, AnyAction> =
  userAPISlice.reducer;
export const getUserAPIState: any = (rootState: any) => rootState[userAPIFeatureKey];
export const userAPIActions: any = userAPISlice.actions;

/**
* Interface for User API Actions.
*/
export interface IUserAPIActionsProperties {
  baseAPIURL: string;
  onCreateErrorLog: (payload: CreateErrorLog) => void;
}

/**
* User API Actions class.
*/
export class UserAPIActions {

  private userAPIApi: UserAPI;

  constructor(properties: IUserAPIActionsProperties) {
    this.userAPIApi = new UserAPI(properties.baseAPIURL, properties.onCreateErrorLog);
  }

  /**
     * Update user theme mode.
     */
  updateUserThemeModeStateAction = createAsyncThunk(
    'user/update/themeMode/state',
    async (payload: { userUUID: string, themeMode: string }, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const updateUserThemeModeStateActionRequest: any = userAPIActions.updateUserThemeModeStateActionRequest;
      const updateUserThemeModeStateActionSuccess: any = userAPIActions.updateUserThemeModeStateActionSuccess;
      const updateUserThemeModeStateActionFailure: any = userAPIActions.updateUserThemeModeStateActionFailure;
      try {
        dispatch(updateUserThemeModeStateActionRequest());
        const result: IAPIEntityResponse<IUser> = await asyncErrorHandler(this.userAPIApi.setUserThemeModeStateAPI(
            payload.userUUID,
            payload.themeMode,
        ));
        if (result.error) {
          dispatch(updateUserThemeModeStateActionFailure(result.error));
        } else {
          dispatch(updateUserThemeModeStateActionSuccess(result.data));
          return result.data;
        }
      } catch (exception: any) {
        dispatch(updateUserThemeModeStateActionFailure(exception.message));
      }
    }
  );

  /**
    * Update user language mode.
    */
  updateUserLanguageSettingsModeStateAction = createAsyncThunk(
    'user/update/languageSettingsMode/state',
    async (payload: { userUUID: string, languageSettingsMode: string }, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      const updateUserLanguageSettingsModeStateActionRequest: any = userAPIActions.updateUserLanguageSettingsModeStateActionRequest;
      const updateUserLanguageSettingsModeStateActionSuccess: any = userAPIActions.updateUserLanguageSettingsModeStateActionSuccess;
      const updateUserLanguageSettingsModeStateActionFailure: any = userAPIActions.updateUserLanguageSettingsModeStateActionFailure;
      try {
        dispatch(updateUserLanguageSettingsModeStateActionRequest());
        const result: IAPIEntityResponse<IUser> = await asyncErrorHandler(this.userAPIApi.setUserLanguageSettingsModeAPI(
            payload.userUUID,
            payload.languageSettingsMode,
        ));
        if (result.error) {
          if (
            LocalStorage.getXAuthToken() &&
            !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
          ) {
            dispatch(updateUserLanguageSettingsModeStateActionFailure(result.error));
          }
        } else {
          dispatch(updateUserLanguageSettingsModeStateActionSuccess(result.data));
          return result.data;
        }
      } catch (exception: any) {
        dispatch(updateUserLanguageSettingsModeStateActionFailure(exception.message));
      }
    }
  );
}
