/**
* AdminDashboardSkeletonWrapper.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues K, 2022 
* @file AdminDashboardSkeletonWrapper.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import AdminDashboardSkeletonDesktopView from './AdminDashboardSkeletonDesktopView'
import AdminDashboardSkeletonTabletMobileView from './AdminDashboardSkeletonTabletMobileView'

/**
 * Render a skeleton for Sidebar component when data is loading for it.
 * 
 * @returns JSX.Element
 */
const AdminDashboardSkeletonWrapper = (): JSX.Element => {
  const windowWidth: number = window.innerWidth;

  return (
    <>
      {windowWidth >= 992 //992px our current breakpoint to hide sidebar from left and display it on top
        ? (<AdminDashboardSkeletonDesktopView  />)
        : (<AdminDashboardSkeletonTabletMobileView  />)
      }
    </>
  )
}

export default AdminDashboardSkeletonWrapper