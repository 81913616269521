/**
 * UserTransaction.ts (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file UserTransaction.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';

const httpClient = new HttpClient(BASE_API_URL, createLog);

//Get User User Transactions
export const fetchUserTransactions = async (
  userUUID: string,
  skip: number,
  limit: number,
  searchTerm: any,
  sort: {
    sortField: string;
    sortOrder: number;
  }
): Promise<any> => {
  const relativeURL: string = paginationRequest(`/transactions/${userUUID}`, {
    skip,
    limit,
    searchTerm
  });

  return httpClient.post(relativeURL, { sort });
};
