/*
 * ProductTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductTable.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import classnames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import Price from '../../SubComponents/Price';
import './ProductTable.css';
import { Button } from 'react-bootstrap';
import { Plan } from 'braintree';
import {
  getTranslatedFreeProductDiscountName,
  getTranslatedSLAInformation
} from '../../../Utils/DynamicTranslate';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import {
  IOption,
  isUniqueProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import {
  calculateRemaininBillingCycles,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * Interface for EditableQuantity properties.
 */
interface IEditableQuantityProperties {
  productCount: number;
  setProductCount: (value: any) => void;
  addProduct: () => void;
  removeProduct: () => void;
  maxQuantity: number;
  isShopPage?: boolean /**< If product table is on the shop page(public route) */;
  isDisabled?: boolean /**< Disabled state */;
}

const EditableQuantity = ({
  productCount,
  setProductCount,
  addProduct,
  removeProduct,
  maxQuantity,
  isShopPage,
  isDisabled
}: IEditableQuantityProperties) => {
  const [helperCount, setHelperCount] = useState(productCount);
  // Debounce callback
  const [debouncedCallback] = useDebouncedCallback(
    // function
    (value) => {
      setProductCount(value);
    },
    // delay in ms
    1000
  );
  return (
    <div className="table-quantity-col custom-margin-quantity-column">
      <InputText
        id="product-quantity"
        value={helperCount}
        onChange={(e) => {
          setHelperCount(e.target.value);
          debouncedCallback(e.target.value);
        }}
        className="mr-1 text-center"
        onBlur={(e) => {
          if (e.target.value === '') {
            setHelperCount(1);
            debouncedCallback(1);
          }
          if (e.target.value > maxQuantity) {
            setHelperCount(maxQuantity);
            debouncedCallback(maxQuantity);
          }
          if (e.target.value < 0) {
            setHelperCount(1);
            debouncedCallback(1);
          }
        }}
        disabled={isDisabled}
      />
      <Button
        className={`custom-quantity-button-container mx-2 d-flex align-items-center justify-content-center ${
          isShopPage ? 'p-button p-button-primary' : ''
        }`}
        onClick={() => removeProduct()}
        disabled={isDisabled}
      >
        <i className="pi pi-minus"></i>
      </Button>
      <Button
        className={`custom-quantity-button-container mx-2 d-flex align-items-center justify-content-center ${
          isShopPage ? 'p-button p-button-primary' : ''
        }`}
        onClick={() => addProduct()}
        disabled={isDisabled}
      >
        <i className="pi pi-plus"></i>
      </Button>
    </div>
  );
};

/**
 * Interface for ProductTable properties.
 */
interface IProductTableProperties {
  products: any;
  priceCalculation: any;
  editableQuantity?: boolean;
  productCount?: number;
  removeProduct?: () => void;
  addProduct?: () => void;
  setProductCount?: (value: any) => void;
  className?: string;
  isShopPage?: boolean /**< If product table is on the shop page(public route) */;
  productSubscription: Plan /**< Product subscription */;
  languageSettingsMode: string /**< Language settings mode */;
  productTranslation?: IProductTranslation[] /**< Product translation */;
  transactedSubscription?: ISubscription /**< Subscription of transaction */;
  braintreeTransactionID?: string /**< Braintree Transaction ID */;
  isDisabled?: boolean /**< Disabled state */;
}

const ProductTable = ({
  products,
  priceCalculation,
  editableQuantity,
  productCount,
  removeProduct,
  addProduct,
  setProductCount,
  className,
  isShopPage,
  productSubscription,
  languageSettingsMode,
  productTranslation,
  transactedSubscription,
  braintreeTransactionID,
  isDisabled
}: IProductTableProperties) => {
  const { t } = useTranslation();
  const [isDurationColumnVisible, setDuartionColumnVisible] = useState<boolean>(
    false
  ); /** Duration column visibility. */
  const [
    isRemainingBillingCyclesColumnVisible,
    setRemainingBillingCyclesColumnVisible
  ] = useState<boolean>(
    false
  ); /** Remaining Billing cycles column visibility. */
  const [isMonthlyPriceColumnVisible, setMonthlyPriceColumnVisible] = useState<
    boolean
  >(false); /**< Monthly price column visibility. */
  const [freeProductDiscountName, setFreeProductDiscountName] = useState<
    string
  >(''); /**< Frre product discount name */
  const [updatedProductTranslation, setUpdatedProductTranslation] = useState<
    IProductTranslation[]
  >([]); /**< Product translation data */
  // Get product translation data
  useEffect(() => {
    if (!products[0].product?.translationData) {
      setUpdatedProductTranslation(productTranslation);
    } else {
      setUpdatedProductTranslation(products[0].product?.translationData);
    }
  }, [products, productTranslation]);

  useEffect(() => {
    // To get translated free product discount name
    const updatedFreeProductDiscountName: string =
      updatedProductTranslation && updatedProductTranslation.length
        ? getTranslatedFreeProductDiscountName(updatedProductTranslation)
        : '';
    setFreeProductDiscountName(updatedFreeProductDiscountName);
  }, [languageSettingsMode, updatedProductTranslation]);

  const renderDiscount = () => {
    if (!priceCalculation.discount) {
      return null;
    }

    const value = priceCalculation.discount.amount ? (
      <Price amount={-priceCalculation.discount.amount} />
    ) : (
      `${-priceCalculation.discount.percentage}%`
    );

    if (
      priceCalculation.discount.amount < 0 ||
      priceCalculation.discount.percentage < 0
    ) {
      return (
        priceCalculation.discount && (
          <tr className="select-auto">
            <td className="table-product-row-empty custom-padding-product-column">
              {priceCalculation.discount.name} ({value})
            </td>
            {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
              <td className="table-product-row-empty" />
            )}
            {isRemainingBillingCyclesColumnVisible ? (
              <td className="table-product-row-empty" />
            ) : (
              <></>
            )}
            <td className="table-product-row-empty" />
            <td className="table-product-row-empty" />
            <td className="text-right table-product-col text-nowrap">
              <b className="table-product-row-label">
                {t('checkoutPage.summary.fee')}:
              </b>
              <Price amount={priceCalculation.priceDiscount} />
            </td>
          </tr>
        )
      );
    }

    return (
      priceCalculation.discount && (
        <tr className="select-auto">
          <td className="table-product-row-empty custom-padding-product-column">
            {isUniqueProduct(products[0].product) ? (
              <>{freeProductDiscountName}</>
            ) : (
              <>{priceCalculation.discount.name}</>
            )}
          </td>
          {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
            <td className="table-product-row-empty" />
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <td className="table-product-row-empty" />
          ) : (
            <></>
          )}
          <td className="table-product-row-empty" />
          <td className="table-product-row-empty" />
          <td className="text-right table-product-col text-nowrap">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.discount')}:
            </b>
            <Price amount={priceCalculation.priceDiscount} />
          </td>
        </tr>
      )
    );
  };

  const ProductRow = ({ product, editableQuantity }) => {
    const productsDetails = product.product;
    // NOTE: As we're using MongoDB, changes in the product details won't reflect the stored transaction object.
    // We need to use the name from the object productRef to have the most up to date product name.
    const lastProductName: string =
      product.productRef?.name ?? productsDetails.name;
    const slaInformation: string[] = getTranslatedSLAInformation(
      productsDetails
    ); /**< To get translated SLA information */
    let remainingBillingCycles: number = 0; /**< Remaining billing cycle */

    /// To show duration column when product duration is available
    if (productsDetails.duration > 0) {
      setDuartionColumnVisible(true);
      const isMonthlyPriceVisible: boolean = !(
        productSubscription && productSubscription.billingFrequency == 12
      );

      if (isMonthlyPriceVisible) {
        // Calculate the remaining billing cylces
        remainingBillingCycles = calculateRemaininBillingCycles(
          braintreeTransactionID as string,
          transactedSubscription,
          productsDetails.duration
        );
        if (remainingBillingCycles != productsDetails.duration) {
          setRemainingBillingCyclesColumnVisible(true);
        } else {
          setRemainingBillingCyclesColumnVisible(false);
        }
      } else {
        setRemainingBillingCyclesColumnVisible(false);
      }

      // To check monthly subscription or not to display monthly price
      setMonthlyPriceColumnVisible(isMonthlyPriceVisible);
    } else {
      setDuartionColumnVisible(false);
      setRemainingBillingCyclesColumnVisible(false);
    }

    return (
      <>
        <tr className="table-product-row select-auto">
          <td className="table-product-col table-product-col custom-padding-product-column">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.product')}:
            </b>
            {lastProductName}
            {slaInformation.length ? (
              <div className="sla-information">
                <p className="custom-labels-product-column mb-0 mt-2">
                  {t('checkoutPage.summary.included_products')}
                </p>
                {slaInformation.map((item: string, index: number) => (
                  <>
                    {index === 0 ? (
                      <></>
                    ) : (
                      <>
                        {!isStringEmptyOrNullOrUndefined(item) ? (
                          <li
                            key={index}
                            className="custom-labels-product-column ml-3"
                          >
                            {item}
                          </li>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ))}
                {!isStringEmptyOrNullOrUndefined(slaInformation[0]) ? (
                  <p className="custom-labels-product-column mb-0 mt-2">
                    {slaInformation[0]}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </td>
          {isUniqueProduct(productsDetails) ? (
            <td className="text-center price table-product-col text-nowrap">
              <b className="table-product-row-label">
                {t('checkoutPage.summary.monthlyPrice', {
                  frequency:
                    (productSubscription &&
                    productSubscription.billingFrequency == 1
                      ? ''
                      : productSubscription &&
                        productSubscription.billingFrequency) ?? ''
                })}
                :
              </b>
              <Price
                amount={productsDetails.price / productsDetails.duration}
              />
            </td>
          ) : (
            <></>
          )}
          {!isUniqueProduct(productsDetails) ? (
            <>
              {productsDetails.duration > 0 && isMonthlyPriceColumnVisible ? (
                <td className="text-center price table-product-col text-nowrap">
                  <b className="table-product-row-label">
                    {t('checkoutPage.summary.monthlyPrice', {
                      frequency:
                        (productSubscription &&
                        productSubscription.billingFrequency == 1
                          ? ''
                          : productSubscription &&
                            productSubscription.billingFrequency) ?? ''
                    })}
                    :
                  </b>
                  <Price amount={productsDetails.price} />
                </td>
              ) : (
                <td className="text-center price table-product-col text-nowrap">
                  <b className="table-product-row-label">
                    {productSubscription &&
                    productSubscription.billingFrequency == 12
                      ? t('checkoutPage.summary.annualPrice')
                      : t('checkoutPage.summary.pricePerItem')}
                    :
                  </b>
                  <Price amount={productsDetails.price} />
                </td>
              )}
            </>
          ) : (
            <></>
          )}
          {productsDetails.duration > 0 && isMonthlyPriceColumnVisible && (
            <td className="text-center table-product-col">
              <b className="table-product-row-label">
                {t('checkoutPage.summary.totalBillingCycles')}:
              </b>
              {productsDetails.duration}
            </td>
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <td className="text-center table-product-col">
              <b className="table-product-row-label">
                {t('checkoutPage.summary.remainingBillingCycles')}:
              </b>
              {remainingBillingCycles}
            </td>
          ) : (
            <></>
          )}
          <td className="text-center table-product-col">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.quantity')}:
            </b>
            {editableQuantity ? (
              <EditableQuantity
                productCount={productCount}
                removeProduct={removeProduct}
                addProduct={addProduct}
                setProductCount={setProductCount}
                maxQuantity={productsDetails.maxQuantity}
                isShopPage={isShopPage}
                isDisabled={isDisabled}
              />
            ) : (
              product.quantity
            )}
          </td>
          <td className="text-right table-product-col text-nowrap">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.pricePerAllItems')}:
            </b>
            <Price amount={productsDetails.price * product.quantity} />
          </td>
        </tr>
        {product.options &&
          product.options.map((option: IOption, index: number) => {
            return (
              <tr className="table-options-row select-auto" key={index}>
                <td className="table-product-row-empty table-product-col-options-description align-middle">
                  <div className="d-none d-lg-block">{option.description}</div>
                  <div className="d-lg-none d-block">{option.description}</div>
                </td>
                <td className="text-center table-product-col align-middle table-option-per-item-price-col price">
                  {option.per_product_qty &
                  (option.amount && option.amount !== 0) ? (
                    <>
                      <Price amount={option.amount} />
                      <br />
                    </>
                  ) : null}
                  {option.per_product_qty &
                  (option.percentage && option.percentage !== 0) ? (
                    <Price
                      amount={(productsDetails.price / 100) * option.percentage}
                    />
                  ) : null}
                </td>
                {productsDetails.duration > 0 &&
                  isMonthlyPriceColumnVisible && (
                    <td className="table-product-row-empty" />
                  )}
                {isRemainingBillingCyclesColumnVisible ? (
                  <td className="table-product-row-empty" />
                ) : (
                  <></>
                )}
                <td className="text-center table-product-col align-middle table-option-quantity-col">
                  {option.per_product_qty && product.quantity}
                </td>
                <td className="text-right table-product-col align-middle">
                  <b className="table-product-row-label table-product-options-description">
                    {option.per_product_qty
                      ? `${product.quantity} x ${option.description}`
                      : option.description}
                    :
                  </b>
                  <div>
                    {option.amount && option.amount !== 0 ? (
                      <>
                        <Price
                          amount={
                            option.per_product_qty
                              ? option.amount * product.quantity
                              : option.amount
                          }
                          className="product-option-price"
                        />
                      </>
                    ) : null}
                    {option.percentage && option.percentage !== 0 ? (
                      <Price
                        amount={
                          option.per_product_qty
                            ? (productsDetails.price / 100) *
                              option.percentage *
                              product.quantity
                            : (productsDetails.price / 100) * option.percentage
                        }
                        className="product-option-price"
                      />
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  return (
    <table className={classnames('table', className)}>
      <thead>
        <tr className="table-product-head select-auto">
          <th scope="col" className="font-weight-bold text-nowrap border-top-0">
            {t('checkoutPage.summary.product')}
          </th>
          {isDurationColumnVisible && isMonthlyPriceColumnVisible ? (
            <th
              scope="col"
              className="font-weight-bold text-center text-nowrap border-top-0"
            >
              {t('checkoutPage.summary.monthlyPrice', {
                frequency:
                  (productSubscription &&
                  productSubscription.billingFrequency == 1
                    ? ''
                    : productSubscription &&
                      productSubscription.billingFrequency) ?? ''
              })}
            </th>
          ) : (
            <th
              scope="col"
              className="font-weight-bold text-center text-nowrap border-top-0"
            >
              {productSubscription && productSubscription.billingFrequency == 12
                ? t('checkoutPage.summary.annualPrice')
                : t('checkoutPage.summary.pricePerItem')}
            </th>
          )}
          {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
            <th
              scope="col"
              className="font-weight-bold text-center text-nowrap border-top-0"
            >
              {t('checkoutPage.summary.totalBillingCycles')}
            </th>
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <th
              scope="col"
              className="font-weight-bold text-center text-nowrap border-top-0"
            >
              {t('checkoutPage.summary.remainingBillingCycles')}
            </th>
          ) : (
            <></>
          )}
          <th
            scope="col"
            className="font-weight-bold text-center text-nowrap border-top-0"
          >
            {t('checkoutPage.summary.quantity')}
          </th>
          <th
            scope="col"
            className="font-weight-bold text-right text-nowrap border-top-0"
            style={{ width: '145px' }}
          >
            {t('checkoutPage.summary.pricePerAllItems')}
          </th>
        </tr>
      </thead>
      <tbody>
        {products &&
          products.length > 0 &&
          products.map((product, index) => (
            <ProductRow
              key={index}
              product={product}
              editableQuantity={editableQuantity}
            />
          ))}
        {renderDiscount()}
        <tr className="table-product-row select-auto">
          <td className="table-product-row-empty" />
          {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
            <td className="table-product-row-empty" />
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <td className="table-product-row-empty" />
          ) : (
            <></>
          )}
          <td className="table-product-row-empty" />
          <td className="text-right table-product-row-empty text-nowrap">
            {t('checkoutPage.summary.netPrice')}:
          </td>
          <td className="text-right price table-product-col text-nowrap">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.netPrice')}:
            </b>
            <Price amount={priceCalculation.priceNet} />
          </td>
        </tr>
        <tr className="table-product-row select-auto">
          <td className="table-product-row-empty" />
          {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
            <td className="table-product-row-empty" />
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <td className="table-product-row-empty" />
          ) : (
            <></>
          )}
          <td className="table-product-row-empty" />
          <td className="text-right table-product-row-empty">
            {priceCalculation.taxState === 'vat_custom'
              ? `${t('checkoutPage.summary.taxPrice')} ${
                  priceCalculation.taxRate
                }%`
              : t('checkoutPage.summary.taxPrice')}{' '}
            :
          </td>
          <td className="text-right price table-product-col text-nowrap">
            <b className="table-product-row-label">
              {priceCalculation.taxState === 'vat_custom'
                ? `${t('checkoutPage.summary.taxPrice')} ${
                    priceCalculation.taxRate
                  }%`
                : t('checkoutPage.summary.taxPrice')}
              :
            </b>
            {priceCalculation.taxState === 'vat_reverse_charge' ? (
              t('checkoutPage.summary.euReverseCharge')
            ) : priceCalculation.taxState === 'vat_no_taxes' ? (
              t('checkoutPage.summary.noTaxesApplicable')
            ) : (
              <Price amount={priceCalculation.tax} />
            )}
          </td>
        </tr>
        <tr className="table-product-row select-auto">
          <td className="table-product-row-empty" />
          {isDurationColumnVisible && isMonthlyPriceColumnVisible && (
            <td className="table-product-row-empty" />
          )}
          {isRemainingBillingCyclesColumnVisible ? (
            <td className="table-product-row-empty" />
          ) : (
            <></>
          )}
          <td className="table-product-row-empty" />
          <td className="text-right font-weight-bold table-product-row-empty text-nowrap">
            {t('checkoutPage.summary.totalPrice')}:
          </td>
          <td className="font-weight-bold text-right price table-product-col text-nowrap">
            <b className="table-product-row-label">
              {t('checkoutPage.summary.totalPrice')}:
            </b>
            <Price amount={priceCalculation.price} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ProductTable;
