import React, { useEffect, useState, ReactElement } from "react";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import ImagePreview from "../ImagePreview";
import { IInstaUploadInterface, RemoveButtonStyle } from ".";

interface IImageUploadWrapperInterface extends IInstaUploadInterface {
  onFileChange: (event: any) => void;
  onUpload: (event: any) => void;
  deleteHandler: (event: any) => void;
}

/// Holds the ImagePreview and the upload button
const ImageContainer = ({
  deleteHandler,
  imageUrl,
  onUpload,
  onFileChange,
  showUploadBtn,
  imgContainerClass = '',
  formGroupClass = '',
  isPlainBtn,
  plainBtnLabel,
  previewSizes,
  isHorizontalLayout: horizontalLayout,
  setToggleFullSizePreview,
  toggleFullSizePreview,
  uploadButtonClass,
  ...properties
}: IImageUploadWrapperInterface): ReactElement => {
  const [previewImage, setPreviewImage] = useState<string[]>([]);

  /// restore preview defaults
  const restorePreviewDefaults = (): void => {
    setPreviewImage(() => []);
  };

  /// renders upload button if showUploadBtn is true
  const getUploadButton = (): JSX.Element => {
    if (imageUrl && showUploadBtn) {
      return (
        <Col sm={12}>
          <Button className="float-right" onClick={(event) => onUpload(event)}>
            <i className="bi bi-cloud-upload"></i>
          </Button>
        </Col>
      );
    }
    return <></>;
  };

  /// onDeleteHandler Extension
  const onDeleteHandlerExtension = (event: any): void => {
    restorePreviewDefaults();
    deleteHandler(event);
  };

  /// get file upload button
  const getFileUploadBtn = (): JSX.Element => {
    if (!isPlainBtn) {
      return (
        <Form.File
          onChange={(event: any) => onFileChange(event.target)}
          accept=".jpeg,.png,.webp,.avif"
          title=""
          className={`${horizontalLayout ?
            '' : 'border bordered custom-upload-and-remove-file-width'
            }`}></Form.File>
      );
    }
    return (
      <Form.File
        onChange={(event: any) => onFileChange(event.target)}
        className={`${uploadButtonClass} custom-upload-and-remove-file-width`}
        label={plainBtnLabel}
        accept=".jpeg,.png,.webp,.avif"
        custom
        title=""
      ></Form.File>
    );
  }

  return (
    <>
      <Row className="rootImageContainer my-1 mx-0">
        {imageUrl && (
          <Col xs={horizontalLayout ? 6 : 12} className={horizontalLayout ? 'pb-0' : ''}>
            <div className={`instaImageContainer ${imgContainerClass}`}>
              <ImagePreview
                {...properties}
                imageUrl={imageUrl}
                deleteHandler={onDeleteHandlerExtension}
                setToggleFullSizePreview={setToggleFullSizePreview}
                toggleFullSizePreview={toggleFullSizePreview}
              />
            </div>
          </Col>
        )}

        <Col
          xs={horizontalLayout ? 6 : 12}
          className={`pb-0 
            ${formGroupClass} 
            d-flex justify-content-center align-items-center 
            ${imageUrl && properties.removeButtonStyle === RemoveButtonStyle.SEPARATE && 'flex-column'}`
          }
        >
          {getFileUploadBtn()}

          {imageUrl && properties.removeButtonStyle === RemoveButtonStyle.SEPARATE && (
            <Button className="d-flex justify-content-center align-items-center mt-2 custom custom-file custom-upload-and-remove-file-width" onClick={deleteHandler} variant="danger">
              <i className="bi bi-trash btn-icon"></i>
              Delete
            </Button>
          )}
        </Col>

        {getUploadButton()}

        {imageUrl &&
          previewSizes &&
          previewSizes.length > 0 &&
          previewImage &&
          previewImage.length > 0 && (
            <Col xs={12} className={"border-top bg-light my-4 pt-2"}>
              <Col xs={12}>
                <p>Preview(s)</p>
              </Col>
              <Row>
                {previewImage.map((imageUrl, index) => (
                  <Col key={index + 1}>
                    <p
                      className={
                        "d-flex align-items-center justify-content-center"
                      }
                    >
                      Size:{" "}
                      {previewSizes[index] &&
                        `${previewSizes[index].width} x ${previewSizes[index].height}`}
                    </p>
                    <div
                      className={
                        "h-100 d-flex align-items-center justify-content-center"
                      }
                    >
                      <ImagePreview
                        imageUrl={imageUrl}
                        altText="preview images"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
      </Row>
    </>
  );
};

export default ImageContainer;
