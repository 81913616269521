/*
 * Product.ts (AbstractWebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file Product.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { UseSeatQuantityEnum } from "../../enum/ecommerce/products";
import { IProductTranslation } from "./ProductTranslation";

/** Specifies the type of product transaction. */
export enum ProductType
{
	OneShot = "OneShot", /**< Specifies the product is a one-shot transaction. */
	PaidSubscription = "PaidSubscription", /**< Specifies the product is a paid subscription transaction. */
	FreeUniqueRenewable = "FreeUniqueRenewable", /**< Specifies the product is a renewable free unique transaction. */
	FreeUnique = "FreeUnique", /**< Specifies the product is a non-renewable free unique transaction (i.e. trial). */
}

export interface IOption {
  _id?: string; /**< Option id */
  name: string; /**< Option name */
  description: string; /**< Option description */
  amount: number; /**< Option amount */
  percentage: number; /**< Option percentage */
  per_product_qty: boolean; /**< Option per_product_qty */
  tooltipInformation: string; /**< Option information for tooltip */
}

export interface IProduct {
  _id: string; /**< Product id */
  name: string; /**< Product name */
  sku: string; /**< Product sku */
  quantity: number; /**< Product quantity */
  maxQuantity: number; /**< Product maxQuantity */
  image: string; /**< Product image */
  price: number; /**< Product price */
  url: string; /**< Product url */
  custom: boolean; /**< Product custom */
  live: boolean; /**< Product live */
  options: IOption[]; /**< Product options */
  sla: boolean; /**< SLA */
  duration: number /**< Product duration */;
  transactionCount?: number /**< Number of transactions */
  subscriptionPlanID?: string /**< If product has subscription, fill this field with the plan ID from Braintree service*/
  applicationID?: string /**< ApplicationID to tie a product to a specific application */;
  isLoginRequired?: boolean; /**< Is login required to purchase the product */
  createdAt?: Date /**< When the product was created */
  updatedAt?: Date /**< Last date that the product was changed */
  isDeleted: boolean /**< Is product deleted or not */;
  deletedAt: Date /**< Deletion date */;
  type: ProductType; /**< Specifies the type of the product. */
  quantityType: UseSeatQuantityEnum /**< Define whether License integration should use the field seatQuantity or maxQuantity */;
  seatQuantity?: number; /**< Number of seat quantity */
  formattedSKU: string /**< Removed non alpha-numeric characters from product SKU */;
  translationData?: IProductTranslation[] /**< Product translation data */;
  isPaymentSectionHidden: boolean /**< Defines if the payment section should be displayed or hidden during a transaction */;
  isDisabled: boolean /**< Disable state of product. If the product is disabled, it means that it is not yet ready for purchase */;
  isCustomerDetailsSectionHidden: boolean /**< Defines if the customer details section should be displayed or hidden during a transaction */;
  isSendingOrderConfirmationEmailAsBCCEnable: boolean /**< Defines if we should send the order confirmation email as BCC to sales team */;
  billingFrequency?: number /**< Defines the subscription plan frequency. We retrieve this value pinging Braintree, and we don't display this field in the UI; it's only for the server side */;
}

/**
 * @interface ICheckoutOption
 */
export interface ICheckoutOption {
  description: string; /**< Option description */
  value: false; /**< Checkout option value */
}

/** Determines whether the given product is unique product or not. */
export function isUniqueProduct(product: Pick<IProduct, "type">)
{
	return product.type == ProductType.FreeUnique || product.type == ProductType.FreeUniqueRenewable;
}

/** Determines whether the given product is a paid product or not. */
export function isPaidProduct(product: Pick<IProduct, "type">)
{
	return product.type == ProductType.OneShot || product.type == ProductType.PaidSubscription;
}

/** Determines whether the given product is a subscription product or not. */
export function isSubscription(product: Pick<IProduct, "type">)
{
	return product.type == ProductType.PaidSubscription || product.type == ProductType.FreeUnique || product.type == ProductType.FreeUniqueRenewable;
}

/** Determines whether the given product is a paid subscription product or not. */
export function isPaidSubscription(product: Pick<IProduct, "type">)
{
	return isPaidProduct(product) && isSubscription(product);
}