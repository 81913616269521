/**
* SettingsPermittedCountriesForm.tsx (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file SettingsPermittedCountriesForm.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Country, ICountry } from 'country-state-city';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { countries } from 'jsvat';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import SettingsPermittedCountriesOptions from './SettingsPermittedCountriesOptions';
import { DefaultTaxIDLabel } from '../../../Config';
import '@abstract/abstractwebcommon-client/common.css';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import i18n from '../../../Services/I18n';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { ICountryTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/CountryTranslation';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * Interface for SettingsPermittedCountriesForm properties.
 */
interface ISettingsPermittedCountriesFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  isLoading: boolean;
  handleDelete: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  countrySettings: any;
  data: any;
}

const SettingsPermittedCountriesForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isLoading,
  handleDelete,
  handleBlur,
  touched,
  setFieldValue,
  countrySettings,
  data
}: ISettingsPermittedCountriesFormProperties) => {
  const { t } = useTranslation();
  const [
    isDeleteConfirmationPopupVisible,
    setDeleteConfirmationPopupVisible
  ] = useState(false); /**< Delete Confirmation popup visibility. */
  const [
    deleteConfirmationPopupTarget,
    setDeleteConfirmationPopupTarget
  ] = useState(null); /**< Delete Confirmation popup target. */

  /// Handle delete country event
  const onDelete = (event) => {
    setDeleteConfirmationPopupVisible(true);
    setDeleteConfirmationPopupTarget(event.target);
  };

  const handleCountryChange = (event) => {
    handleChange(event);
    const countryMatch = countries.find(
      (country) => country.name === Country.getCountryByCode(event.value)?.name
    );
    if (countryMatch) {
      // providing suggestion for the regex
      setFieldValue('rules', countryMatch.rules.regex[0].toString());
    } else {
      // resetting the field if no country was matched with a jsvat country
      setFieldValue('rules', '');
    }
  };

  // To enable/disable Add button based on required fields.
  const isDisabled = () => {
    if (values.name !== '' && values.taxStateB2B && values.taxStateB2C)
      return false;
    else return true;
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={
          countrySettings && Object.keys(countrySettings).length > 0
        }
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          onDelete(event)
        }
        handleSubmitButton={() => handleSubmit(values)}
        disableSaveOrUpdateButton={isDisabled()}
      >
        <Row>
          <Col>
            <Form.Group as={Col} {...{ sm: 12 }} className="pl-0">
              <Form.Label className="required">
                {t('fields.customer.country')}
              </Form.Label>
              <Dropdown
                sizing={{ sm: 4 }}
                name="name"
                inputId="name"
                placeholder="Select a country"
                onChange={handleCountryChange}
                onBlur={handleBlur}
                value={values.name}
                disabled={countrySettings !== null}
                options={Country.getAllCountries()
                  .filter(
                    (country: ICountry) =>
                      !data.find(
                        (permitted) =>
                          permitted.name === country.isoCode &&
                          values.name !== country.isoCode
                      )
                  )
                  .map(
                    (country: ICountry) =>
                      ({
                        value: country.isoCode,
                        label: country.name
                      } as ICountryData)
                  )
                  .sort((country1: ICountryData, country2: ICountryData) =>
                    country1.label.localeCompare(country2.label)
                  )}
                className={touched.name && errors.name ? 'p-invalid' : ''}
              />
              {touched.name && errors.name ? (
                <small id="email-invalid" className="p-invalid error-text">
                  {errors.name}
                </small>
              ) : null}
            </Form.Group>

            <Panel
              header={t('fields.customer.taxIDSettings')}
              className="additional-options-panel"
            >
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.customer.taxIDLabel')}
                  name="identificationType"
                  id={'identificationType'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.identificationType}
                  errors={errors.identificationType}
                  value={values.identificationType}
                />
              </Form.Group>
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.customer.regex')}
                  name="rules"
                  id={'rules'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.rules}
                  errors={errors.rules}
                  value={values.rules}
                />
              </Form.Group>
            </Panel>

            <SettingsPermittedCountriesOptions
              values={values.options ? values.options : []}
              errors={errors.options}
              handleChange={handleChange}
              loading={isLoading}
              handleBlur={handleBlur}
              touched={touched.options}
              setFieldValue={setFieldValue}
              translationData={values?.translationData}
              translationErrors={errors?.translationData}
              translationTouched={touched?.translationData}
            />
            <Panel
              header={t('fields.countrySettings.b2bSettings_header')}
              className="text-left mt-3 additional-options-panel"
            >
              <Form.Group as={Col} {...{ sm: 12 }}>
                <Form.Label className="required">
                  {t('fields.countrySettings.taxState')}
                </Form.Label>
                <Dropdown
                  sizing={{ sm: 4 }}
                  name="taxStateB2B"
                  inputId="taxStateB2B"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.taxStateB2B}
                  options={[
                    {
                      value: 'vat_reverse_charge',
                      label: 'VAT Reverse Charge'
                    },
                    {
                      value: 'vat_no_taxes',
                      label: 'No Taxes'
                    },
                    {
                      value: 'vat_custom',
                      label: 'Custom'
                    }
                  ]}
                  className={
                    touched.taxStateB2B && errors.taxStateB2B ? 'p-invalid' : ''
                  }
                />
                {touched.taxStateB2B && errors.taxStateB2B ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {errors.taxStateB2B}
                  </small>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.countrySettings.taxRate')}
                  name="taxRateB2B"
                  id={'taxRateB2B'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.taxRateB2B}
                  errors={errors.taxRateB2B}
                  value={values.taxRateB2B}
                  disabled={values.taxStateB2B !== 'vat_custom'}
                  type="decimal"
                />
              </Form.Group>
              <Col>
                <Form.Group as={Col} sm={12} md={12}>
                  <Checkbox
                    inputId="b2b"
                    onChange={(e) => {
                      setFieldValue('b2b', !values.b2b);
                    }}
                    name="b2b"
                    checked={values.b2b}
                  />
                  &nbsp;&nbsp;
                  <Form.Label htmlFor="b2b">
                    {t('fields.countrySettings.live')}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Panel>
            <Panel
              header={t('fields.countrySettings.b2cSettings_header')}
              className="text-left mt-3 mb-3 additional-options-panel"
            >
              <Form.Group as={Col} {...{ sm: 12 }}>
                <Form.Label className="required">
                  {t('fields.countrySettings.taxState')}
                </Form.Label>
                <Dropdown
                  sizing={{ sm: 4 }}
                  name="taxStateB2C"
                  inputId="taxStateB2C"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.taxStateB2C}
                  options={[
                    {
                      value: 'vat_reverse_charge',
                      label: 'VAT Reverse Charge'
                    },
                    {
                      value: 'vat_no_taxes',
                      label: 'No Taxes'
                    },
                    {
                      value: 'vat_custom',
                      label: 'Custom'
                    }
                  ]}
                  className={
                    touched.taxStateB2C && errors.taxStateB2C ? 'p-invalid' : ''
                  }
                />
                {touched.taxStateB2C && errors.taxStateB2C ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {errors.taxStateB2C}
                  </small>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.countrySettings.taxRate')}
                  name="taxRateB2C"
                  id={'taxRateB2C'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.taxRateB2C}
                  errors={errors.taxRateB2C}
                  value={values.taxRateB2C}
                  disabled={values.taxStateB2C !== 'vat_custom'}
                  type="decimal"
                />
              </Form.Group>
              <Col>
                <Form.Group as={Col} sm={12} md={12}>
                  <Checkbox
                    inputId="b2c"
                    onChange={(e) => {
                      setFieldValue('b2c', !values.b2c);
                    }}
                    name="b2c"
                    checked={values.b2c}
                  />
                  &nbsp;&nbsp;
                  <Form.Label htmlFor="b2c">
                    {t('fields.countrySettings.live')}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Panel>
          </Col>
        </Row>

        <ConfirmationPopup
          target={deleteConfirmationPopupTarget}
          isShow={isDeleteConfirmationPopupVisible}
          title={t('confirm_messages.delete_record')}
          onAccept={() => {
            handleDelete([countrySettings._id]);
            setDeleteConfirmationPopupVisible(false);
          }}
          onReject={() => {
            setDeleteConfirmationPopupVisible(false);
          }}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('confirm_messages.no')}
          acceptLabel={t('confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
        />
      </FormWrapper>
    </>
  );
};

export default withFormik({
  mapPropsToValues: ({ countrySettings }) => {
    // Format inital country translation
    const initialTranslationData: ICountryTranslation[] = [];
    for (let index = 0; index < Languages.length; index++) {
      const language: string = Languages[index].value;
      initialTranslationData.push({
        language: language,
        checkoutConditionsDescription: []
      });
    }

    if (!countrySettings) {
      return {
        calcFn: () => {},
        name: '',
        codes: [],
        options: [],
        rules: '',
        b2b: true,
        b2c: true,
        identificationType: DefaultTaxIDLabel.vat,
        translationData: initialTranslationData
      };
    }

    // Note: Get the country translation if it already exists and corresponds to the same language array. Otherwise, add/delete a language object or use initialTranslationData.
    let countryTranslationData: ICountryTranslation[] = [];
    if (
      countrySettings.translationData &&
      countrySettings.translationData.length
    ) {
      initialTranslationData.forEach((initialData: ICountryTranslation) => {
        countrySettings.translationData.forEach(
          (existingData: ICountryTranslation) => {
            if (initialData.language === existingData.language) {
              countryTranslationData.push(existingData);
            }
          }
        );
      });
      if (countryTranslationData.length !== initialTranslationData.length) {
        const newLanguageData: ICountryTranslation[] = initialTranslationData.filter(
          (initialData: ICountryTranslation) =>
            !countryTranslationData.some(
              (existingData: ICountryTranslation) =>
                initialData.language === existingData.language
            )
        );
        countryTranslationData = countryTranslationData.concat(newLanguageData);
      }
    } else {
      countryTranslationData = initialTranslationData;
    }

    return {
      _id: countrySettings._id,
      calcFn: countrySettings.calcFn,
      name: countrySettings.name,
      codes: countrySettings.codes,
      rules: countrySettings.rules,
      identificationType: countrySettings.identificationType,
      b2b: countrySettings.b2b,
      b2c: countrySettings.b2c,
      taxRateB2B: countrySettings.taxRateB2B,
      taxStateB2B: countrySettings.taxStateB2B,
      taxRateB2C: countrySettings.taxRateB2C,
      taxStateB2C: countrySettings.taxStateB2C,
      options: countrySettings.options,
      translationData: countryTranslationData
    };
  },
  validationSchema: Yup.object({
    name: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.customer.country')
      })
    ),
    taxStateB2B: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.countrySettings.taxStateB2B')
      })
    ),
    taxRateB2B: Yup.number()
      .typeError('Must be a number')
      .when('taxStateB2B', {
        is: (taxStateValue) => taxStateValue === 'vat_custom',
        then: Yup.number().required(
          i18n.t('validation.required', {
            field: i18n.t('fields.countrySettings.taxRateB2B')
          })
        ),
        otherwise: Yup.number()
      }),
    taxStateB2C: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.countrySettings.taxStateB2C')
      })
    ),
    taxRateB2C: Yup.number()
      .typeError('Must be a number')
      .when('taxStateB2C', {
        is: (taxStateValue) => taxStateValue === 'vat_custom',
        then: Yup.number().required(
          i18n.t('validation.required', {
            field: i18n.t('fields.countrySettings.taxRateB2C')
          })
        ),
        otherwise: Yup.number()
      }),
    identificationType: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.customer.taxIDLabel')
      })
    ),
    options: Yup.array().of(
      Yup.object().shape({
        value: Yup.boolean()
      })
    ),
    translationData: Yup.array().of(
      Yup.object().shape({
        checkoutConditionsDescription: Yup.array().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.array().of(
            Yup.string()
              .min(3, 'Must be 3 characters or more')
              .nullable()
              .required(
                i18n.t('validation.required', {
                  field: i18n.t('fields.countrySettings.description')
                })
              )
          ),
          otherwise: Yup.array().of(
            Yup.string()
              .nullable()
              .min(3, 'Must be 3 characters or more')
          )
        })
      })
    )
  }),
  handleSubmit: (values, bag) => {
    if (bag.props.countrySettings) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (key == 'options') {
          if (values[key].length == bag.props.countrySettings[key].length) {
            const newOptionsString: string = JSON.stringify(values[key].sort());
            const initialOptionsString: string = JSON.stringify(
              bag.props.countrySettings[key].sort()
            );
            if (newOptionsString != initialOptionsString) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'translationData') {
          if (values[key].length == bag.props.countrySettings[key].length) {
            const newTranslationsString: string = JSON.stringify(
              values[key].sort()
            );
            const initialTranslationsString: string = JSON.stringify(
              bag.props.countrySettings[key].sort()
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          } else {
            const newTranslation = values[key].map(
              (translation: ICountryTranslation) => {
                return {
                  language: translation?.language,
                  checkoutConditionsDescription:
                    translation?.checkoutConditionsDescription
                };
              }
            );
            const newTranslationsString: string = JSON.stringify(
              newTranslation.sort()
            );
            // Format inital country translation
            const initialTranslationData: ICountryTranslation[] = [];
            for (let index = 0; index < Languages.length; index++) {
              const language: string = Languages[index].value;
              const existingTranslation: ICountryTranslation =
                bag.props.countrySettings[key] &&
                bag.props.countrySettings[key].length &&
                bag.props.countrySettings[key][
                  bag.props.countrySettings[key]?.findIndex(
                    (data: ICountryTranslation) => data.language === language
                  )
                ];
              initialTranslationData.push({
                language: language,
                checkoutConditionsDescription:
                  existingTranslation?.checkoutConditionsDescription ?? []
              });
            }
            const initialTranslationsString: string = JSON.stringify(
              initialTranslationData
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          }
        } else if (
          values[key as keyof typeof bag.props.countrySettings] !==
          bag.props.countrySettings[
            key as keyof typeof bag.props.countrySettings
          ]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit({ ...values });
      }
      return;
    }
    return bag.props.handleSubmit({ ...values });
  }
})(SettingsPermittedCountriesForm);
