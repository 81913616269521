/*
 * Header.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Header.tsx
 * @author James Ugbanu
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactNode, ReactElement } from "react";
import Helmet from "react-helmet";

//Header props interface.
interface IHeaderProps {
  children?:  ReactNode; /**< children component */
  faviconX180?: string; /**< favicon URL with 180px dimension. */
  faviconX32?: string; /**< favicon URL with 32px dimension. */
  faviconX16?: string; /**< favicon URL with 16px dimension. */
  applicationTitle?: string; /**< Application Title. */
}
const Header = ({
  children,
  faviconX180,
  faviconX32,
  faviconX16,
  applicationTitle
}: IHeaderProps): ReactElement => {
  return (
    <Helmet>
      <title>{applicationTitle}</title>
      <link rel="apple-touch-icon" sizes="180x180" href={faviconX180} />
      <link rel="icon" type="image/png" sizes="32x32" href={faviconX32} />
      <link rel="icon" type="image/png" sizes="16x16" href={faviconX16} />
      {children}
    </Helmet>
  );
};

export default Header;
