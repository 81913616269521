/*
 * ScrollToTop.tsx (WebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ScrollToTop.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section WebCommon
 */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [useLocation().pathname]);

  return null;
}

export default ScrollToTop;