/**
 * addressValidation.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file addressValidation.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum CountriesAddressValidationCoverage
 * Countries that Google covers and has validation through their service.
 * If you find any missing country in the list below, please add it.
 * Complete list: https://developers.google.com/maps/documentation/address-validation/coverage
 */
 export enum CountriesAddressValidationCoverageENUM {
  Argentina = "AR",
  Australia = "AU",
  Austria = "AT",
  Belgium = "BE",
  Brazil = "BR",
  Canada = "CA",
  Chile = "CL",
  Colombia = "CO",
  Czechia = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Latvia = "LV",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malaysia = "MY",
  Mexico = "MX",
  Netherlands = "NL",
  NewZealand = "NZ",
  Norway = "NO",
  Poland = "PL",
  PuertoRico = "PR",
  Singapore = "SG",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
  UnitedKingdom = "GB",
  UnitedStates = "US"
}

/**
 * @enum AddressFieldsToValidateENUM
 * Possible errors returned by Google Address Validation service.
 */
export enum AddressFieldsToValidateENUM {
  PostalCode = "postal_code",
  City = "administrative_area_level_2",
  RegionCode = "country",
  StateOrProvince = "administrative_area_level_1",
  Address1 = "route",
  Address1_PO1 = "point_of_interest",
}