/**
 * LogoutAllApplications.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LogoutAllApplications.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { IApplications } from "../../Shared/interfaces/user/applications";

/**
 * @interface ILogoutAllApplications
 */
export interface ILogoutAllApplications {
  applications: IApplications[]; /**< Applicaions to be logged out. */
  loggedOutApplicationUUID: string; /**< Loggedout Applications UUID. */
  logoutAction: () => void; /**< Logout handler. */
  redirectApplicationURL?: string; /**< Redirect Application URL. */
}

/// Handle to logout all applications.
export const logoutAllApplications = async (properties: ILogoutAllApplications) => {
  const loggedOutApplications: string[] = JSON.parse(localStorage.getItem('loggedOutApplications')) ?? []; /**< LoggedOut Applications. */
  const payload: string[] = [...loggedOutApplications]; /**< Payload to set localstorage */

  if(properties.applications) {
    /// When the application is logged off, set the localstorage variable with applicationUUID.
    if(properties.loggedOutApplicationUUID) { // ApplicationUUID is from loggedout application.
      payload.push(properties.loggedOutApplicationUUID)
      localStorage.setItem('loggedOutApplications', JSON.stringify(payload));
    }

    /// Logout all applications
    for(let i = 0; i < properties.applications.length; i++) {
      const application: IApplications = properties.applications[i];
      const applicationUUID: string = application.applicationUUID;
      const foundApplication: number = payload.indexOf(applicationUUID); /** Check whether the application is already logged out or not.*/
      
      if(foundApplication < 0) { // if not found, go that application to logout
        window.location.href = `${application['verificationURL']}?logoutAll=true&redirect_applicationURL=${properties.redirectApplicationURL}`;
      }
    }

    /// If all other applications are logged off, also log off the current application.
    if(payload.length === properties.applications.length){
      localStorage.clear();
      properties.logoutAction();
    }
  }
}
