/*
 * TransactionsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file TransactionsPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import Col from 'react-bootstrap/Col';
import { getTransactionList, updateTransactionAction } from '../../../Store/Transactions';
import TransactionTable from './TransactionTable';
import TransactionForm from './TransactionForm';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  ITablePayload,
  ISortEvent,
  IPageEvent
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

/**
 * Interface for TransactionsPage properties.
 */
interface ITransactionsPageProperties {
  getTransactionList: any;
  transactionList: any;
  transactionListIsFetching: any;
  count: number;
  updateTransactionAction: (transactionID: string, transactionList: any[], count: number) => void /* Redux action to update a transaction based on its ID. TODO: Replace the any[] interface to ITransaction from AWC. */
}

const TransactionsPage = ({
  getTransactionList,
  transactionList,
  transactionListIsFetching,
  count,
  updateTransactionAction
}: ITransactionsPageProperties) => {
  const translation = useTranslation().t;
  const [openForm, setOpenForm] = useState(false);
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */

  function createNewTransaction() {
    const userUUID = LocalStorage.getXUserUUID();
    // if user is signed in
    if (userUUID) {
      // he's good to go
      setOpenForm(true);
    } else {
      // redirect to login
      window.open(
        `${SharedCommomRouteName.validateRoute}?redirect_url=/admin/transactions`,
        `_self`
      );
    }
  }

  useEffect(() => {
    getTransactionList(
      payload.skip,
      payload.limit,
      payload.searchTerm,
      payload.sort,
      payload.filter
    );
  }, []);

  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    getTransactionList(
      updatedPayload.skip,
      updatedPayload.limit,
      updatedPayload.searchTerm,
      updatedPayload.sort,
      updatedPayload.filter
    );
  };

  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    getTransactionList(
      updatedPayload.skip,
      updatedPayload.limit,
      updatedPayload.searchTerm,
      updatedPayload.sort,
      updatedPayload.filter
    );
  };

  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    getTransactionList(
      updatedPayload.skip,
      updatedPayload.limit,
      updatedPayload.searchTerm,
      updatedPayload.sort,
      updatedPayload.filter
    );
  };

  const handleUpdateTransaction = (transactionID: string): void => {
    updateTransactionAction(transactionID, transactionList, count);
  };

  // Handle product filter update event
  const handleProductFilterUpdate = (filter: Record<string, any>): void => {
    const updatedPayload: ITablePayload = payload;
    updatedPayload.filter = filter;
    setPayload(updatedPayload);
    getTransactionList(
      updatedPayload.skip,
      updatedPayload.limit,
      updatedPayload.searchTerm,
      updatedPayload.sort,
      updatedPayload.filter
    );
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <TransactionTable
            transactions={transactionList}
            loading={transactionListIsFetching}
            skip={payload.skip}
            limit={payload.limit}
            count={count}
            sort={payload.sort}
            handlePageUpdate={handlePageUpdate}
            handleFilterUpdate={handleFilterUpdate}
            handleSortUpdate={handleSortUpdate}
            createNewTransaction={createNewTransaction}
            searchTerm={payload.searchTerm ?? ''}
            handleUpdateTransaction={handleUpdateTransaction}
            filter={payload.filter}
            handleProductFilterUpdate={handleProductFilterUpdate}
          />
        </Col>
      </Row>
      <DialogWrapper
        isDialogVisible={openForm}
        headerTitle={translation(
          'transaction.form.header.add_transaction_dialog'
        )}
        className="custom-transaction-dialog-container"
        onHide={() => {
          setOpenForm(false);
        }}
      >
        <TransactionForm setModalOpen={setOpenForm} />
      </DialogWrapper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    transactionList: state.transactions.transactionList,
    transactionListIsFetching: state.transactions.transactionListIsFetching,
    count: state.transactions.count
  };
}

const mapDispatchToProps = {
  getTransactionList,
  updateTransactionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
