/**
* NotFound.tsx (abstractecommerce) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file NotFound.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NotFoundBase from '@abstract/abstractwebcommon-client/NotFound/NotFoundBase';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/templates';

import { getAllTemplatesState } from '../../../Store/Templates';
import { Placeholders } from '../../../Config';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../../Services/Logs';

export interface ITemplatesState {
  list: ITemplates[];
}

const NotFound = () => {
  const templatesState: ITemplatesState = useSelector(getAllTemplatesState);
  const [templateToShow, setTemplateToShow] = useState<string | undefined>(
    undefined
  );

  const setPlaceholdersToTheTemplate = () => {
    const errorHandlerTemplate:
      | ITemplates
      | undefined = templatesState.list.find(
      (template) => template.name == 'Error Handler Template'
    );

    const transformedTemplate = errorHandlerTemplate?.template?.replace(
      /{{([a-zA-Z]+)}}/g,
      (_: any, valutToMatch: string) =>
        Placeholders[errorHandlerTemplate.name!][valutToMatch]
    );

    setTemplateToShow(transformedTemplate);
  };

  useEffect(() => {
    if (templatesState.list.length > 0) setPlaceholdersToTheTemplate();
  }, [templatesState]);

  return (
    <NotFoundBase
      isLoading={templatesState.list.length > 0}
      templateToShow={templateToShow!}
    />
  );
};

export default withErrorBoundary(NotFound, createLog);
