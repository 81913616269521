/*
 * ShowCheckOrUncheckIcon.tsx (WebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file ShowCheckOrUncheckIcon.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section WebCommon
 */

import * as React from 'react';

/**
 * @interface IShowCheckOrUncheckIconProperties
 */
 interface IShowCheckOrUncheckIconProperties {
  value: boolean /**< true or false */
}

/**
 * Render Check or Uncheck icon in data tables and expansion rows.
 * 
 * @param properties IShowCheckOrUncheckIconProperties interface
 * @returns JSX.Element
 */
const ShowCheckOrUncheckIcon = (properties: IShowCheckOrUncheckIconProperties): JSX.Element => {
  return (
    properties.value ? (
      <i className="pi pi-check text-success"></i>
    ) : (
      <i className="pi pi-times text-danger"></i>
    )
  )
}

export default ShowCheckOrUncheckIcon