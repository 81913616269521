/*
 * BillingForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file BillingForm.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Fieldset } from 'primereact/fieldset';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Dropdown } from 'primereact/dropdown';
import classnames from 'classnames';
import { Country, State, ICountry, IState } from 'country-state-city';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import Button from 'react-bootstrap/Button';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { DefaultTaxIDLabel } from '../../../Config';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import './TransactionPage.css';
import i18n from '../../../Services/I18n';
import { AutoComplete } from 'primereact/autocomplete';
import './BillingForm.css';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { getAllUsersWithoutPagination } from '../../../Store/Users';
import { useDispatch, useSelector } from 'react-redux';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { VatAcronyms } from '@abstract/abstractwebcommon-shared/enum/ecommerce/vatAcronyms';
import { ICountry as IEcommerceCountry } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import { isVatIDValidAction } from '../../../Store/VatIdAPI';
import {
  IProduct,
  isUniqueProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { AddressFieldsToValidateENUM } from '@abstract/abstractwebcommon-shared/enum/ecommerce/addressValidation';
import { getTaxIDLabelForCountry } from 'src/Utils/BillingEntity';

/**
 * Autocomplete user details
 */
export interface IAutoCompleteUserDetails extends IUser {
  fullDetails: string /**< Full user details with username, email, firstname, lastname */;
}

/**
 * Interface for BillingForm properties.
 */
interface IBillingFormProperties {
  values: {
    savedAddressId?: any;
    companyName?: string;
    isCompany?: boolean;
    identificationType?: any;
    country?: string;
    email?: string;
    vatId?: string;
    userUUID?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    zipcode?: string;
    stateOrProvince?: string;
    isSaveAddress?: boolean;
    isVatIDValid?: boolean;
    addressName?: string;
    useSameAddressForBilling?: boolean;
    billingFirstName?: string;
    billingLastName?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingZipcode?: string;
    billingStateOrProvince?: string;
    billingCountry?: string;
    billingCompanyName?: string;
    billingVatID?: string | null;
    [key: string]: any /**< Dynamic properties */;
  };
  errors?: {
    savedAddressId?: any;
    companyName?: string;
    isCompany?: boolean;
    identificationType?: any;
    country?: string;
    email?: string;
    vatId?: string;
    userUUID?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    zipcode?: string;
    stateOrProvince?: string;
    isSaveAddress?: boolean;
    addressName?: string;
    useSameAddressForBilling?: boolean;
    billingFirstName?: string;
    billingLastName?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingZipcode?: string;
    billingStateOrProvince?: string;
    billingCountry?: string;
    billingCompanyName?: string;
    billingVatID?: string | null;
    [key: string]: any /**< Dynamic properties */;
  };
  handleChange?: any;
  isLoading: boolean;
  handleBlur?: any;
  touched?: {
    savedAddressId?: any;
    companyName?: string;
    isCompany?: boolean;
    identificationType?: any;
    country?: string;
    email?: string;
    vatId?: string;
    userUUID?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    zipcode?: string;
    stateOrProvince?: string;
    isSaveAddress?: boolean;
    addressName?: string;
    useSameAddressForBilling?: boolean;
    billingFirstName?: string;
    billingLastName?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingZipcode?: string;
    billingStateOrProvince?: string;
    billingCountry?: string;
    billingCompanyName?: string;
    billingVatID?: string | null;
    [key: string]: any /**< Dynamic properties */;
  };
  onChange: any;
  updateFormError?: any;
  countries: any;
  setFieldValue?: any;
  setFieldTouched?: any;
  setFieldError?: any;
  userInformation: any;
  serialNumber: any;
  handleSaveAddress: any;
  isShopPage?: boolean /**< If billing form is on the shop page(public route) */;
  setProductPurchasedAlready: any /**< Product purchased already or not. Will update your state when Username field is changed. */;
  product: IProduct;
  setBillingFormSerialIndex: React.Dispatch<
    React.SetStateAction<number>
  > /**< Billing form serial index */;
  loggedOutMessageBanner: JSX.Element /**< Logged out message banner */;
}

const BillingForm = ({
  values,
  errors,
  handleChange,
  isLoading,
  handleBlur,
  touched,
  onChange,
  updateFormError,
  countries,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  userInformation,
  serialNumber,
  handleSaveAddress,
  isShopPage,
  setProductPurchasedAlready,
  product,
  setBillingFormSerialIndex,
  loggedOutMessageBanner
}: IBillingFormProperties) => {
  const { t } = useTranslation();
  const [searchedUser, setSearchedUser] = useState<
    string | IAutoCompleteUserDetails
  >(''); /**< Searched user */
  const usersState = useSelector((state) => state.users); /**< User state */
  const vatIdAPIState = useSelector(
    (state) => state.vatIdAPI
  ); /**< User state */
  const addressAPIValidation = useSelector(
    (state) => state.addressAPIValidation
  ); /**< User state */
  const dispatch = useDispatch();
  const [isUserFieldsReadOnly, setUserFieldsReadOnly] = useState<boolean>(
    false
  ); /**< User fields readonly */

  const [isAddressSavedOrUpdated, setAddressSavedOrUpdated] = useState<boolean>(
    false
  ); /**< Manage when disable Save or Update button */

  const [savedAddress, setSavedAddress] = useState<any>({} as any);
  const [vatNumber, setVatNumber] = useState<string | null>(null);
  const [isAEuropeanCountrySelected, setAEuropeanCountrySelected] = useState<
    boolean | null
  >(null);

  const [
    isAEuropeanCountrySelectedBillingAddress,
    setAEuropeanCountrySelectedBillingAddress
  ] = useState<boolean | null>(null);

  useEffect(() => {
    onChange(values);
  });

  useEffect(() => {
    updateFormError(errors);
  }, [updateFormError, errors]);

  const filterCountries = (permitted) => {
    return Country.getAllCountries()
      .filter(
        (country: ICountry) =>
          permitted &&
          permitted.some((permitted) => permitted.name === country.isoCode)
      )
      .map(
        (country: ICountry) =>
        ({
          value: country.isoCode,
          label: country.name
        } as ICountryData)
      )
      .sort((country1: ICountryData, country2: ICountryData) =>
        country1.label.localeCompare(country2.label)
      );
  };

  // Get all countries for free product purchase
  const getAllCountriesForFreeProduct = () => {
    return Country.getAllCountries()
      .map(
        (country: ICountry) =>
        ({
          value: country.isoCode,
          label: country.name
        } as ICountryData)
      )
      .sort((country1: ICountryData, country2: ICountryData) =>
        country1.label.localeCompare(country2.label)
      );
  };

  const [permittedCountries, setPermittedCountries] = useState(
    filterCountries(countries && countries.filter((vat) => vat.b2c))
  );

  const [statesOfCountry, setStatesOfCountry] = useState<ICountryData[]>(
    []
  ); /**< State of country */
  const [statesOfBillingCountry, setStatesOfBillingCountry] = useState<
    ICountryData[]
  >([]); /**< State of billing country */

  /// Get states of country.
  const getCountryStates = (country: string): ICountryData[] => {
    const states: ICountryData[] = State.getStatesOfCountry(country).map(
      (state: IState) =>
      ({
        value: state.isoCode,
        label: state.name
      } as ICountryData)
    );
    return states;
  };

  useEffect(() => {
    const statesList: ICountryData[] = getCountryStates(
      values.country as string
    );
    // If there is no state for the country, use the country code for the state
    if (!statesList.length) {
      setFieldValue('stateOrProvince', values.country);
    }
    setStatesOfCountry(statesList);
  }, [values.country]);

  useEffect(() => {
    const billingStatesList: ICountryData[] = getCountryStates(
      values.billingCountry as string
    );
    // If there is no state for the country, use the country code for the state
    if (!billingStatesList.length) {
      setFieldValue('billingStateOrProvince', values.billingCountry);
    }
    setStatesOfBillingCountry(billingStatesList);
  }, [values.billingCountry]);

  /**
   * filterAddress Get only permitted country address
   * @param userInformation
   * @returns
   */
  const filterAddress = (userInformation) => {
    return (
      userInformation &&
      userInformation.address &&
      userInformation.address
        .filter(
          (address) =>
            permittedCountries &&
            permittedCountries.some(
              (permitted) => permitted.value === address.country
            )
        )
        .map((address) => ({
          value: address.addressUUID,
          label: address.name
        }))
    );
  };

  const handleCustomerTypeChange = (e) => {
    if (e.target.value) {
      setPermittedCountries(
        filterCountries(countries.filter((vat) => vat.b2b))
      );
      handleChange(e);
    } else {
      setPermittedCountries(
        filterCountries(countries.filter((vat) => vat.b2c))
      );
      handleChange(e);
    }
  };

  const updateAddressFields = (userInformation, value) => {
    return (
      userInformation &&
      userInformation.address
        .filter((address) => address.addressUUID === value.savedAddressId)
        .map((address) => {
          setFieldValue('addressName', address.name);
          setFieldValue('country', address.country);
          setFieldValue('address1', address.address);
          setFieldValue('address2', address.addressTwo || '');
          setFieldValue('city', address.city);
          setFieldValue('stateOrProvince', address.stateOrProvince);
          setFieldValue('zipcode', address.zipCode || '');
        })
    );
  };
  useEffect(() => {
    if (values.savedAddressId) {
      updateAddressFields(userInformation, values);
    }
  }, [values.savedAddressId, userInformation]);

  useEffect(() => {
    if (countries) {
      setPermittedCountries(
        filterCountries(countries.filter((vat) => vat.b2c))
      );
    }
  }, [countries]);

  // Handler for user search
  const handleUserSearch = (event: any): void => {
    dispatch(
      getAllUsersWithoutPagination({ filter: event?.query.trim() || '' })
    );
  };

  // Handler for user change
  const handleUserChange = (event: any): void => {
    if (typeof event.value === 'string') {
      setSearchedUser(event.value);
    } else if (typeof event.value === 'object') {
      const user: IUser = event.value;
      if (user) {
        setSearchedUser({
          ...user,
          fullDetails: `${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`
        });
      }
    }
    if (isStringEmptyOrNullOrUndefined(event.value)) {
      setUserFieldsReadOnly(false);
    }
  };

  // Handler for selected user
  const handleSelectedUser = (event: any): void => {
    const user: IUser = event.value; /**< Selected user */
    setFieldValue('userUUID', user.userUUID);
    setFieldValue('username', user.username);
    setFieldValue('firstName', user.firstName);
    setFieldValue('lastName', user.lastName);
    setFieldValue('email', user.email);
    setUserFieldsReadOnly(true); /**< Lock user details to edit */
  };

  // Template for autocomplete component
  const itemTemplate = (user: IUser): JSX.Element => {
    return (
      <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>
    );
  };

  /// Handle change event
  const onChangeEvent = (event: any): void => {
    handleChange(event);
    revalidateBillingAddressValues(event.target);
  };

  //Note: Check if any field of saved address was updated and activate Save/Update button in case true.
  const revalidateBillingAddressValues = (
    currentFormValuesInHTMLFormat: any
  ): void => {
    const billingAddressValues: any = { ...values };
    if (billingAddressValues) {
      billingAddressValues[currentFormValuesInHTMLFormat.id as keyof any] =
        currentFormValuesInHTMLFormat.value;
    }

    const isFormValuesChanged: boolean = checkIfBillingAddressValuesChanged(
      billingAddressValues
    );

    setAddressSavedOrUpdated(isFormValuesChanged);
  };

  //Note: Wrapper if the billing values were changed.
  const checkIfBillingAddressValuesChanged = (
    billingAddressValues: any
  ): boolean => {
    return (
      billingAddressValues &&
      Object.keys(mapApplicationSettingsValues(billingAddressValues)).length !==
      0
    );
  };

  //Note: Check if the billing values were changed.
  const mapApplicationSettingsValues = (billingAddressValues: any) => {
    const payload: any = {};

    if (!billingAddressValues) {
      return {};
    }

    if (billingAddressValues.addressName !== savedAddress?.name) {
      payload['addressName'] = billingAddressValues?.addressName;
    }

    if (billingAddressValues.country !== savedAddress?.country) {
      payload['country'] = billingAddressValues?.country;
    }

    if (billingAddressValues.address1 !== savedAddress?.address) {
      payload['address1'] = billingAddressValues?.address1;
    }

    if (billingAddressValues.address2 !== savedAddress?.addressTwo) {
      payload['address2'] = billingAddressValues?.address2;
    }

    if (billingAddressValues.city !== savedAddress?.city) {
      payload['city'] = billingAddressValues?.city;
    }

    if (billingAddressValues.zipcode !== savedAddress?.zipCode) {
      payload['zipcode'] = billingAddressValues?.zipcode;
    }

    if (
      billingAddressValues.stateOrProvince !== savedAddress?.stateOrProvince
    ) {
      payload['stateOrProvince'] = billingAddressValues?.stateOrProvince;
    }

    return payload;
  };

  useEffect(() => {
    setAddressSavedOrUpdated(false);
    if (usersState.successReturn && userInformation) {
      setSavedAddress(
        userInformation?.address?.find(
          (userAddress: any) =>
            userAddress.addressUUID ===
            filterAddress(userInformation)?.find(
              (address) => address.label === values?.addressName
            )?.value
        )
      );

      values.savedAddressId = filterAddress(userInformation)?.find(
        (address) => address.label === values?.addressName
      )?.value;
    }
  }, [usersState.successReturn, usersState.errorReturn, userInformation]);

  const validateVatID = (vatID: string) => {
    const vatIDFieldToUse = values.useSameAddressForBilling
      ? values.vatId
      : values.billingVatID;

    if (vatIDFieldToUse && vatID && vatNumber !== vatID) {
      dispatch(isVatIDValidAction(vatIDFieldToUse));
      setVatNumber(vatID);
    }
  };

  const handleIfAEuropeanCountryIsSelected = (
    countryAcronym: VatAcronyms
  ): void => {
    if (countryAcronym) {
      const isEuropeanCountrySelected = Object.values(VatAcronyms).includes(
        countryAcronym
      );

      if (values.useSameAddressForBilling) {
        setAEuropeanCountrySelected(isEuropeanCountrySelected);

        if (!isEuropeanCountrySelected) {
          setFieldTouched('vatId', false);
        }
      }

      if (!values.useSameAddressForBilling) {
        setAEuropeanCountrySelectedBillingAddress(isEuropeanCountrySelected);

        if (!isEuropeanCountrySelected) {
          setFieldTouched('billingVatID', false);
        }
      }
    }
  };

  useEffect(() => {
    if (values.country) {
      handleIfAEuropeanCountryIsSelected(values.country as VatAcronyms);
    }
  }, [values.country]);

  useEffect(() => {
    if (values.billingCountry) {
      handleIfAEuropeanCountryIsSelected(values.billingCountry as VatAcronyms);
    }
  }, [values.billingCountry]);

  //Note: Apply error in the respective fields that failed to validate.
  useEffect(() => {
    if (addressAPIValidation.validatedAddress) {
      for (
        // eslint-disable-next-line prettier/prettier
        let index = 0;
        index <
        addressAPIValidation.validatedAddress.unconfirmedComponentTypes.length;
        index++
      ) {
        const invalidField: string =
          addressAPIValidation.validatedAddress.unconfirmedComponentTypes[
          index
          ];

        switch (invalidField) {
          case AddressFieldsToValidateENUM.RegionCode:
            setFieldError(
              'country',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.country')
              })
            );
            break;
          case AddressFieldsToValidateENUM.City:
            setFieldError(
              'city',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.city')
              })
            );
            break;
          case AddressFieldsToValidateENUM.PostalCode:
            setFieldError(
              'zipcode',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.zipcode')
              })
            );
            break;
          case AddressFieldsToValidateENUM.StateOrProvince:
            setFieldError(
              'stateOrProvince',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.stateOrProvince')
              })
            );
            break;
          case AddressFieldsToValidateENUM.Address1:
            setFieldError(
              'address1',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.address')
              })
            );
            break;
          case AddressFieldsToValidateENUM.Address1_PO1:
            setFieldError(
              'address1',
              i18n.t('validation.invalidField', {
                field: i18n.t('fields.customer.address')
              })
            );
            break;
        }
      }
    }
  }, [addressAPIValidation]);

  useEffect(() => {
    if (vatIdAPIState.isVatIdValid !== null) {
      values.isVatIDValid = vatIdAPIState.isVatIdValid;

      const vatIDFieldToUse: string = values.useSameAddressForBilling
        ? 'vatId'
        : 'billingVatID';

      if (!vatIdAPIState.isVatIdValid) {
        setFieldError(vatIDFieldToUse, 'Invalid VAT ID');
      } else {
        setFieldTouched(vatIDFieldToUse, false);
      }
    }
  }, [vatIdAPIState.isVatIdValid]);

  return (
    <>
      {' '}
      {isUniqueProduct(product) && product.isCustomerDetailsSectionHidden ? (
        <></>
      ) : (
        <>
          {setBillingFormSerialIndex(++serialNumber)}
          <Fieldset
            legend={t(`checkoutPage.billingData.header`, {
              number: `${serialNumber}.`
            })}
            className="custom-fieldset billing-form"
          >
            <Row>
              {loggedOutMessageBanner}
              <Form.Group as={Col} sm={12} md={12}>
                <Row>
                  <Col>
                    <RadioButton
                      inputId="isPersonal"
                      name="isCompany"
                      onChange={handleCustomerTypeChange}
                      value={false}
                      checked={!isLoading && values.isCompany === false}
                      disabled={isLoading}
                      className={classnames({
                        'p-error': touched.isCompany && errors.isCompany,
                        'is-invalid': touched.isCompany && errors.isCompany,
                        'is-valid': touched.isCompany && !errors.isCompany
                      })}
                    />
                    <Form.Label
                      htmlFor="isPersonal"
                      className="p-radiobutton-label"
                    >
                      {t('fields.customer.isPersonal')}
                    </Form.Label>
                  </Col>
                  <Col>
                    <RadioButton
                      inputId="isCompany"
                      name="isCompany"
                      onChange={(e) => handleCustomerTypeChange(e)}
                      value
                      checked={values.isCompany === true}
                      disabled={isLoading}
                      className={
                        touched.isCompany && errors.isCompany ? 'p-invalid' : ''
                      }
                    />
                    <Form.Label
                      htmlFor="isCompany"
                      className="p-radiobutton-label"
                    >
                      {t('fields.customer.isCompany')}
                    </Form.Label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} sm={12} md={12}>
                <Row>
                  {userInformation && (
                    <Col md={6} xs={12}>
                      <Form.Label>{t('label.savedAddress')}</Form.Label>
                      <Dropdown
                        name="savedAddressId"
                        onChange={(e) => {
                          setFieldValue('savedAddressId', e.target.value);
                          setFieldValue('vatId', '');
                          setVatNumber(null);
                        }}
                        onBlur={handleBlur}
                        value={
                          values.savedAddressId ||
                          filterAddress(userInformation)?.find(
                            (address) =>
                              address.label === savedAddress?.addressName
                          )?.value
                        }
                        disabled={
                          isLoading || !filterAddress(userInformation).length
                        }
                        options={filterAddress(userInformation)}
                        placeholder={isLoading ? '' : t('placeholders.address')}
                        showClear
                        className="custom-dropdrown-container-shop-page"
                      />
                    </Col>
                  )}
                  {values.isCompany && (
                    <Col md={6} xs={12}>
                      <InstaInputText
                        label={t('fields.customer.companyName')}
                        name="companyName"
                        isLoading={isLoading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.companyName}
                        errors={errors.companyName}
                        value={values.companyName}
                        labelClassName="required"
                      />
                    </Col>
                  )}
                </Row>
              </Form.Group>
              <Form.Group as={Col} sm={12} md={6}>
                <Form.Label htmlFor={'country'} className="required">
                  {t('fields.customer.country')}
                </Form.Label>
                <Dropdown
                  id="country"
                  name="country"
                  inputId="country"
                  onChange={(e) => {
                    handleIfAEuropeanCountryIsSelected(e.target.value);
                    onChangeEvent(e);
                    setFieldValue('country', e.target.value);
                    setFieldValue('vatId', '');
                    setVatNumber(null);
                    setTimeout(() => {
                      setFieldTouched('country');
                    }, 100);
                    if (values.isCompany) {
                      setFieldValue(
                        'identificationType',
                        countries.find(
                          (country) => country.name === e.target.value
                        )?.identificationType ?? t('fields.customer.vatId')
                      );
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.country}
                  disabled={isLoading}
                  options={
                    isUniqueProduct(product)
                      ? getAllCountriesForFreeProduct()
                      : permittedCountries
                  }
                  placeholder={isLoading ? '' : t('placeholders.country')}
                  className={`custom-dropdrown-container-shop-page ${touched.country && errors.country ? 'p-invalid ' : ''
                    }`}
                />
                {touched.country && errors.country ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {t(errors.country)}
                  </small>
                ) : null}
              </Form.Group>
              {!isShopPage ? (
                <Form.Group as={Col} sm="12" md="6">
                  <Form.Label htmlFor={'username'}>
                    {t('fields.customer.username')}
                  </Form.Label>
                  <AutoComplete
                    value={searchedUser}
                    suggestions={usersState.userList}
                    completeMethod={handleUserSearch}
                    field="fullDetails"
                    onChange={(e) => {
                      handleUserChange(e);
                      setProductPurchasedAlready(false);
                    }}
                    placeholder={
                      isLoading ? '' : t('fields.customer.searchPlaceholder')
                    }
                    size={100}
                    onSelect={handleSelectedUser}
                    itemTemplate={itemTemplate}
                    appendTo="self"
                    className="custom-dropdrown-container-shop-page "
                    forceSelection
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              <Form.Group as={Col} sm="12" md="6">
                <InstaInputText
                  label={t('fields.customer.email')}
                  name="email"
                  id={'email'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  errors={errors.email}
                  value={values.email}
                  labelClassName="required"
                  isReadOnly={isUserFieldsReadOnly}
                />
              </Form.Group>
              
              <Form.Group as={Col} sm="12" md="6">
                <InstaInputText
                  label={t('fields.customer.firstName')}
                  name="firstName"
                  id={'firstName'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.firstName}
                  errors={errors.firstName}
                  value={values.firstName}
                  labelClassName="required"
                  isReadOnly={isUserFieldsReadOnly}
                />
              </Form.Group>
              <Form.Group as={Col} sm="12" md="6">
                <InstaInputText
                  label={t('fields.customer.lastName')}
                  name="lastName"
                  id={'lastName'}
                  isLoading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.lastName}
                  errors={errors.lastName}
                  value={values.lastName}
                  labelClassName="required"
                  isReadOnly={isUserFieldsReadOnly}
                />
              </Form.Group>
              {values.isCompany && values.useSameAddressForBilling && (
                <Form.Group as={Col} sm="12" md="6">
                  <InstaInputText
                    label={getTaxIDLabelForCountry(
                      values.country as VatAcronyms,
                      t
                    )}
                    name="vatId"
                    id={'vatId'}
                    isLoading={isLoading}
                    onChange={(e) => {
                      handleChange(e);
                      if (isStringEmptyOrNullOrUndefined(e.target.value)) {
                        setFieldValue('isVatIDValid', false)
                      }
                    }}
                    onBlur={(e) => {
                      // NOTE: Validate only EuropeanCountry.
                      if (isAEuropeanCountrySelected) {
                        validateVatID(e.target.value);
                      }
                      // NOTE: We should reset the isVatIDValid if the vatId field is empty.
                      if (isStringEmptyOrNullOrUndefined(e.target.value)) {
                        setFieldValue('isVatIDValid', false)
                      }
                      handleBlur(e);
                    }}
                    touched={touched.vatId}
                    errors={errors.vatId}
                    value={values.vatId}
                    disabled={
                      values.country === '' || vatIdAPIState.isCheckingValidity
                    }
                    labelClassName={
                      isAEuropeanCountrySelected && !isUniqueProduct(product) ? 'required' : ''
                    }
                  />
                </Form.Group>
              )}
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.customer.address')}
                  name="address1"
                  id={'address1'}
                  isLoading={isLoading}
                  onChange={onChangeEvent}
                  onBlur={handleBlur}
                  touched={touched.address1}
                  errors={errors.address1}
                  value={values.address1}
                  labelClassName="required"
                />
              </Form.Group>
              <Form.Group as={Col} sm="12">
                <InstaInputText
                  label={t('fields.customer.address2')}
                  name="address2"
                  id={'address2'}
                  isLoading={isLoading}
                  onChange={onChangeEvent}
                  onBlur={handleBlur}
                  touched={touched.address2}
                  errors={errors.address2}
                  value={values.address2}
                />
              </Form.Group>
              <Form.Group as={Col} sm="12" md="6">
                <InstaInputText
                  label={t('fields.customer.city')}
                  name="city"
                  id={'city'}
                  isLoading={isLoading}
                  onChange={onChangeEvent}
                  onBlur={handleBlur}
                  touched={touched.city}
                  errors={errors.city}
                  value={values.city}
                  labelClassName="required"
                />
              </Form.Group>
              <Form.Group as={Col} sm="12" md="6">
                <InstaInputText
                  label={t('fields.customer.zipcode')}
                  name="zipcode"
                  id={'zipcode'}
                  isLoading={isLoading}
                  onChange={onChangeEvent}
                  onBlur={handleBlur}
                  touched={touched.zipcode}
                  errors={errors.zipcode}
                  value={values.zipcode}
                  labelClassName="required"
                />
              </Form.Group>
              {statesOfCountry.length ? (
                <Form.Group as={Col} sm="12" md="6">
                  <Form.Label htmlFor={'stateOrProvince'} className="required">
                    {t('fields.customer.stateOrProvince')}
                  </Form.Label>
                  <Dropdown
                    id="stateOrProvince"
                    name="stateOrProvince"
                    inputId="stateOrProvince"
                    onChange={(e) => {
                      onChangeEvent(e);
                      setFieldValue('stateOrProvince', e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.stateOrProvince}
                    disabled={isLoading || !values.country}
                    options={statesOfCountry}
                    placeholder={isLoading ? '' : t('placeholders.country')}
                    className={`custom-dropdrown-container-shop-page ${touched.stateOrProvince && errors.stateOrProvince
                        ? 'p-invalid '
                        : ''
                      }`}
                  />
                  {touched.stateOrProvince && errors.stateOrProvince ? (
                    <small id="email-invalid" className="p-invalid error-text">
                      {t(errors.stateOrProvince)}
                    </small>
                  ) : null}
                </Form.Group>
              ) : (
                <></>
              )}
            </Row>
            {userInformation && (
              <Row
                key="isSaveAddress"
                className={isUniqueProduct(product) ? 'pb-0' : 'pb-4'}
              >
                <Col>
                  <Checkbox
                    inputId="isSaveAddress"
                    checked={values.isSaveAddress}
                    onChange={handleChange}
                    name="isSaveAddress"
                    disabled={isLoading}
                  />
                  <Form.Label
                    htmlFor="isSaveAddress"
                    className="p-checkbox-label"
                  >
                    {!values.savedAddressId
                      ? t('checkoutPage.billingForm.saveAddressCheckbox')
                      : t('checkoutPage.billingForm.updateAddressCheckbox')}
                  </Form.Label>
                </Col>
              </Row>
            )}
            {userInformation && values.isSaveAddress && (
              <Row className="d-flex align-items-center">
                <Col sm={12} md={4}>
                  <InstaInputText
                    label={t('fields.customer.addressName')}
                    name="addressName"
                    id={'addressName'}
                    isLoading={isLoading}
                    onChange={(e) => {
                      handleChange(e);
                      onChangeEvent(e);
                    }}
                    onBlur={handleBlur}
                    touched={touched.addressName}
                    errors={errors.addressName}
                    value={values.addressName}
                  />
                </Col>
                <Col
                  sm={12}
                  md={2}
                  className="saveAddress-col d-flex align-items-center"
                >
                  {isLoading && <Loader />}
                  {!isLoading && (
                    <Button
                      className={`saveAddress-button d-flex align-items-center justify-content-center ${isShopPage ? 'p-button p-button-primary' : ''
                        }`}
                      disabled={
                        !values.addressName ||
                        !values.address1 ||
                        !values.city ||
                        !values.zipcode ||
                        !values.stateOrProvince ||
                        !values.country ||
                        usersState?.isAddressChanging ||
                        !isAddressSavedOrUpdated
                      }
                      variant="primary"
                      onClick={() => handleSaveAddress(values)}
                    >
                      <i className="bi bi-check2-circle btn-icon"></i>
                      {!values.savedAddressId
                        ? t('checkoutPage.billingForm.saveButtonText')
                        : t('checkoutPage.billingForm.updateButtonText')}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
            {!isUniqueProduct(product) ? (
              <Row key="useSameAddressForBilling">
                <Col>
                  <Checkbox
                    inputId="useSameAddressForBilling"
                    checked={
                      !isLoading && values.useSameAddressForBilling === true
                    }
                    onChange={(e) => {
                      handleChange(e);
                      // NOTE: We should clear the vatId error in case present in the errors object while checking/unchecking this checkbox option.
                      delete errors?.vatId
                      setFieldValue('vatId', '')
                      setFieldValue('billingVatID', '')
                      setFieldValue('isVatIDValid', false)
                    }}
                    name="useSameAddressForBilling"
                    disabled={isLoading}
                  />
                  <Form.Label
                    htmlFor="useSameAddressForBilling"
                    className="p-checkbox-label"
                  >
                    {t('fields.customer.useSameAddressForBilling')}
                  </Form.Label>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Fieldset>
          {!values.useSameAddressForBilling && !isUniqueProduct(product) && (
            <>
              {setBillingFormSerialIndex(++serialNumber)}
              <Fieldset
                legend={t('checkoutPage.billingAddress', {
                  number: `${serialNumber}.`
                })}
                className={
                  !values.useSameAddressForBilling
                    ? 'custom-billing-address-container'
                    : ''
                }
              >
                <Row>
                  <Form.Group as={Col} sm={12} md={12} className="pb-0">
                    <Row>
                      {values.isCompany ? 
                      <Form.Group as={Col} sm="12" md="6">
                        <InstaInputText
                          label={t('fields.customer.companyName')}
                          name="billingCompanyName"
                          id={'billingCompanyName'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingCompanyName}
                          errors={errors.billingCompanyName}
                          value={values.billingCompanyName}
                          labelClassName="required"
                        />
                      </Form.Group> : <></>}
                      <Form.Group as={Col} sm={12} md={6} className="pb-0">
                        <Form.Label className="required">
                          {t('fields.customer.country')}
                        </Form.Label>
                        <Dropdown
                          name="billingCountry"
                          inputId="billingCountry"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldTouched('billingCountry');
                            handleIfAEuropeanCountryIsSelected(e.target.value);
                            onChangeEvent(e);
                            setFieldValue('billingCountry', e.target.value);
                            setFieldValue('billingVatID', '');
                            setVatNumber(null);
                          }}
                          onBlur={handleBlur}
                          value={values.billingCountry}
                          disabled={isLoading}
                          options={
                            isUniqueProduct(product)
                              ? getAllCountriesForFreeProduct()
                              : permittedCountries
                          }
                          placeholder={
                            isLoading ? '' : t('placeholders.country')
                          }
                          className={
                            touched.billingCountry && errors.billingCountry
                              ? 'p-invalid custom-dropdrown-container-shop-page'
                              : 'custom-dropdrown-container-shop-page'
                          }
                        />
                        {touched.billingCountry && errors.billingCountry ? (
                          <small
                            id="email-invalid"
                            className="p-invalid error-text"
                          >
                            {t(errors.billingCountry)}
                          </small>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col} sm="12" md="6">
                        <InstaInputText
                          label={t('fields.customer.firstName')}
                          name="billingFirstName"
                          id={'billingFirstName'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingFirstName}
                          errors={errors.billingFirstName}
                          value={values.billingFirstName}
                          labelClassName="required"
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="12" md="6">
                        <InstaInputText
                          label={t('fields.customer.lastName')}
                          name="billingLastName"
                          id={'billingLastName'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingLastName}
                          errors={errors.billingLastName}
                          value={values.billingLastName}
                          labelClassName="required"
                        />
                      </Form.Group>
                      {values.isCompany && (
                        <Form.Group as={Col} sm="12" md="6">
                          <InstaInputText
                            label={getTaxIDLabelForCountry(
                              values.billingCountry as VatAcronyms,
                              t
                            )}
                            name="billingVatID"
                            id={'billingVatID'}
                            isLoading={isLoading}
                            onChange={(e) => {
                              handleChange(e);
                              if (isStringEmptyOrNullOrUndefined(e.target.value)) {
                                setFieldValue('isVatIDValid', false)
                              }
                            }}
                            touched={touched.billingVatID}
                            errors={errors.billingVatID}
                            value={values.billingVatID}
                            onBlur={(e) => {
                              // NOTE: Validate only EuropeanCountry
                              if (isAEuropeanCountrySelectedBillingAddress) {
                                validateVatID(e.target.value);
                              }
                              // NOTE: We should reset the isVatIDValid if the vatId field is empty.
                              if (isStringEmptyOrNullOrUndefined(e.target.value)) {
                                setFieldValue('isVatIDValid', false)
                              }
                              handleBlur(e);
                            }}
                            disabled={
                              values.billingCountry === '' ||
                              vatIdAPIState.isCheckingValidity
                            }
                            labelClassName={
                              isAEuropeanCountrySelectedBillingAddress && !values.useSameAddressForBilling
                                ? 'required'
                                : ''
                            }
                          />
                        </Form.Group>
                      )}
                      <Form.Group as={Col} sm="12">
                        <InstaInputText
                          label={t('fields.customer.address')}
                          name="billingAddress1"
                          id={'billingAddress1'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingAddress1}
                          errors={errors.billingAddress1}
                          value={values.billingAddress1}
                          labelClassName="required"
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="12">
                        <InstaInputText
                          label={t('fields.customer.address2')}
                          name="billingAddress2"
                          id={'billingAddress2'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingAddress2}
                          errors={errors.billingAddress2}
                          value={values.billingAddress2}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="12" md="6">
                        <InstaInputText
                          label={t('fields.customer.city')}
                          name="billingCity"
                          id={'billingCity'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingCity}
                          errors={errors.billingCity}
                          value={values.billingCity}
                          labelClassName="required"
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="12" md="6">
                        <InstaInputText
                          label={t('fields.customer.zipcode')}
                          name="billingZipcode"
                          id={'billingZipcode'}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.billingZipcode}
                          errors={errors.billingZipcode}
                          value={values.billingZipcode}
                          labelClassName="required"
                        />
                      </Form.Group>
                      
                      {statesOfBillingCountry.length ? (
                        <Form.Group as={Col} sm="12" md="6" className="pb-0">
                          <Form.Label
                            htmlFor={'billingStateOrProvince'}
                            className="required"
                          >
                            {t('fields.customer.stateOrProvince')}
                          </Form.Label>
                          <Dropdown
                            id="billingStateOrProvince"
                            name="billingStateOrProvince"
                            inputId="billingStateOrProvince"
                            onChange={(e) => {
                              setFieldValue(
                                'billingStateOrProvince',
                                e.target.value
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.billingStateOrProvince}
                            disabled={isLoading || !values.billingCountry}
                            options={statesOfBillingCountry}
                            placeholder={
                              isLoading ? '' : t('placeholders.country')
                            }
                            className={`custom-dropdrown-container-shop-page ${touched.billingStateOrProvince &&
                                errors.billingStateOrProvince
                                ? 'p-invalid '
                                : ''
                              }`}
                          />
                          {touched.billingStateOrProvince &&
                            errors.billingStateOrProvince ? (
                            <small
                              id="email-invalid"
                              className="p-invalid error-text"
                            >
                              {t(errors.billingStateOrProvince)}
                            </small>
                          ) : null}
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Form.Group>
                </Row>
              </Fieldset>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withFormik({
  validateOnChange: false,
  mapPropsToValues: ({ values }) => {
    if (Object.keys(values).length === 0) {
      return {
        userUUID: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        stateOrProvince: '',
        zipcode: '',
        country: '',
        billingFirstName: '',
        billingLastName: '',
        billingCompanyName: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingStateOrProvince: '',
        billingZipcode: '',
        billingCountry: '',
        billingVatID: '',
        isCompany: false,
        vatId: '',
        companyName: '',
        useSameAddressForBilling: true,
        customConditions: [],
        identificationType: DefaultTaxIDLabel.vat
      };
    }
    return values;
  },
  validationSchema: (props) => {
    // checks if string contains only numbers
    const checkForJustNumber = (value) => !/^\d+$/.test(value);

    if (
      props.product &&
      isUniqueProduct(props.product) &&
      props.product.isCustomerDetailsSectionHidden
    ) {
      return Yup.object({});
    } else {
      // validation schema
      let validationSpec = {
        country: Yup.string().required(
          i18n.t('validation.required', {
            field: i18n.t('fields.customer.country')
          })
        ),
        email: Yup.string()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.email')
            })
          )
          .email('validation.email'),
        firstName: Yup.string()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.firstName')
            })
          )
          .min(2, 'Must be 2 characters or more')
          .max(50, 'Must be 50 characters or less')
          .test('is-only-number', "Can't be just a number", checkForJustNumber),
        lastName: Yup.string()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.lastName')
            })
          )
          .min(2, 'Must be 2 characters or more')
          .max(50, 'Must be 50 characters or less')
          .test('is-only-number', "Can't be just a number", checkForJustNumber),
        address1: Yup.string()
          .min(2, 'Must be 2 characters or more')
          .max(200, 'Must be 200 characters or less')
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.address')
            })
          )
          .test('is-only-number', "Can't be just a number", checkForJustNumber),
        address2: Yup.string()
          .min(2, 'Must be 2 characters or more')
          .max(200, 'Must be 200 characters or less')
          .test('is-only-number', "Can't be just a number", checkForJustNumber),
        city: Yup.string()
          .min(2, 'Must be 2 characters or more')
          .max(100, 'Must be 100 characters or less')
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.city')
            })
          )
          .test('is-only-number', "Can't be just a number", checkForJustNumber),
        zipcode: Yup.string()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.zipcode')
            })
          )
          .min(2, 'Must be 2 characters or more')
          .max(20, 'Must be 20 characters or less'),
        stateOrProvince: Yup.string().required(
          i18n.t('validation.required', {
            field: i18n.t('fields.customer.stateOrProvince')
          })
        ),
        billingFirstName: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(50, 'Must be 50 characters or less')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.firstName')
              })
            )
            .test(
              'is-only-number',
              "Can't be just a number",
              checkForJustNumber
            )
        }),
        billingLastName: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(50, 'Must be 50 characters or less')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.lastName')
              })
            )
            .test(
              'is-only-number',
              "Can't be just a number",
              checkForJustNumber
            )
        }),
        billingAddress1: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(200, 'Must be 200 characters or less')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.address')
              })
            )
            .test(
              'is-only-number',
              "Can't be just a number",
              checkForJustNumber
            )
        }),
        billingAddress2: Yup.string()
          .min(2, 'Must be 2 characters or more')
          .max(200, 'Must be 200 characters or less'),
        billingCity: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.city')
              })
            )
            .test(
              'is-only-number',
              "Can't be just a number",
              checkForJustNumber
            )
        }),
        billingStateOrProvince: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string().required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.stateOrProvince')
            })
          )
        }),
        billingZipcode: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.zipcode')
              })
            )
            .min(2, 'Must be 2 characters or more')
            .max(20, 'Must be 20 characters or less')
        }),
        billingCountry: Yup.string().when('useSameAddressForBilling', {
          is: false,
          then: Yup.string().required(
            i18n.t('validation.required', {
              field: i18n.t('fields.customer.country')
            })
          )
        }),
        useSameAddressForBilling: Yup.bool()
      };

      if (props.values.isCompany) {
        const isVatIDFieldMandatory = Object.values(VatAcronyms).includes(
          props.values.country
        );

        const isBillingVatIDFieldMandatory = Object.values(
          VatAcronyms
        ).includes(props.values.billingCountry);

        validationSpec = {
          ...validationSpec,
          companyName: Yup.string()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.customer.companyName')
              })
            )
            .min(2, 'Must be 2 characters or more')
            .max(255, 'Must be 255 characters or less'),
          billingCompanyName: Yup.string().when('useSameAddressForBilling', {
            is: false,
            then: Yup.string()
              .required(
                i18n.t('validation.required', {
                  field: i18n.t('fields.customer.companyName')
                })
              )
              .min(2, 'Must be 2 characters or more')
              .max(255, 'Must be 255 characters or less')
          }),
          vatId: Yup.string().when('useSameAddressForBilling', {
            is: true,
            then: Yup.string()
              .test(
                'is-valid-vat',
                'Invalid VAT ID',
                async function (value: string | undefined) {
                  // Check if VAT ID is valid after calling the API.
                  if (props.values.isVatIDValid) return true

                  // Ensure a country is selected.
                  if (!props.values.country) {
                    throw this.createError({ message: 'Please select a country', path: this.path });
                  }

                  // Check if VAT ID is mandatory and if a value is provided.
                  if (isVatIDFieldMandatory && isStringEmptyOrNullOrUndefined(value)) {
                    throw this.createError({ message: i18n.t('validation.required', { field: i18n.t('fields.customer.vatId') }), path: this.path });
                  }

                  // Check VAT ID number format.
                  if (isVatIDFieldMandatory && value) {
                    const vatIdCheckRegexp = new RegExp(props.values.country.rules.slice(1, -1), 'i');
                    if (!vatIdCheckRegexp.test(value)) {
                      throw this.createError({ message: 'Invalid VAT ID', path: this.path });
                    }
                  }

                  // If VAT ID is not mandatory, it's valid.
                  return true;
                }
              )
          }),
          billingVatID: Yup.string().when('useSameAddressForBilling', {
            is: false,
            then: Yup.string()
              .test(
                'is-valid-vat',
                'Invalid VAT ID',
                async function (value: string | undefined) {
                  // Check if Billing VAT ID is valid after calling the API.
                  if (props.values.isVatIDValid) return true

                  // Ensure a billing country is selected.
                  if (!props.values.billingCountry) {
                    throw this.createError({ message: 'Please select a country', path: this.path });
                  }

                  // Check if Billing VAT ID is mandatory and if a value is provided.
                  if (isBillingVatIDFieldMandatory && !value) {
                    throw this.createError({ message: i18n.t('validation.required', { field: i18n.t('fields.customer.vatId') }), path: this.path });
                  }

                  // Check Billing VAT ID number format.
                  if (isBillingVatIDFieldMandatory && value) {
                    const vatIdCheckRegexp = new RegExp(props.values.billingCountry.rules.slice(1, -1), 'i');
                    if (!vatIdCheckRegexp.test(value)) {
                      throw this.createError({ message: 'Invalid VAT ID', path: this.path });
                    }
                  }

                  // If Billing VAT ID is not mandatory, it's valid.
                  return true;
                }
              )
          }),
        };
      }

      return Yup.object(validationSpec);
    }
  },
  handleSubmit: (values, bag) => {
    if (values.useSameAddressForBilling) {
      return bag.props.handleSubmit({
        ...values,
        billingFirstName: values.firstName,
        billingLastName: values.lastName,
        billingCompanyName: values.companyName,
        billingAddress1: values.address1,
        billingAddress2: values.address2,
        billingCity: values.city,
        billingStateOrProvince: values.stateOrProvince,
        billingZipcode: values.zipcode,
        billingCountry: values.country,
        billingVatID: null
      });
    }

    return bag.props.handleSubmit({ ...values });
  }
})(BillingForm);
