/*
 * SettingsSMTPForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingsSMTPForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import SharedSettingsSMTPForm from '@abstract/abstractwebcommon-client/SettingsPage/SettingsSMTPForm';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18n from '../../../Services/I18n';
import { DatabaseType } from '@abstract/abstractwebcommon-shared/constants/connectionDatabaseType';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { ISMTPSettingsBodyRequest } from '@abstract/abstractwebcommon-shared/interfaces/user/settings';
import { CheckboxProps } from 'primereact/checkbox';

/**
 * Interface for SettingsSMTPForm properties.
 */
interface ISettingsSMTPFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  handleTestSMTP: (values: any) => Promise<void>;
  loading: boolean;
  handleDelete: any;
  handleBlur: any;
  touched: any;
  adminSettings: Record<string, any> /**< Admin Settings. */;
  setFieldValue: any /**< Set field value */;
  isLoadingTestSMTP: boolean /**< Loading state for test smtp */;
}

const SettingsSMTPForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  handleTestSMTP,
  loading,
  handleDelete,
  handleBlur,
  touched,
  adminSettings,
  isLoadingTestSMTP,
  setFieldValue
}: ISettingsSMTPFormProperties) => {
  const translation = useTranslation().t;
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const [isSMTPUpdatesExist, setSMTPUpdatesExist] = useState<boolean>(
    false
  ); /**< isSMTPUpdatesExist. */
  const isSMTPErrorsExists: boolean =
    errors && Object.keys(errors).length > 0; /**< isSMTPErrorsExists. */

  /// Show save popup
  const handleSave = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event?.target);
  };

  /// Save on Accept
  const onAccept = () => {
    setShowConfirmationPopup(false);
    handleSubmit();
    setSMTPUpdatesExist(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmationPopup(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={translation('confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidateSMTPUpdates(event.target);
  };

  /// Set Field value
  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateSMTPUpdates({
      id: key,
      value
    });
  };

  /// Validate SMTP updates
  const revalidateSMTPUpdates = (currentUpdate: any): void => {
    const smtpValues: ISMTPSettingsBodyRequest = { ...values };
    if (smtpValues) {
      smtpValues[currentUpdate.id as keyof ISMTPSettingsBodyRequest] =
        currentUpdate.value;
    }
    setSMTPUpdatesExist(checkIfSMTPUpdatesExists(smtpValues));
  };

  /// Check SMTP updates exists or not.
  const checkIfSMTPUpdatesExists = (
    smtpValues: ISMTPSettingsBodyRequest
  ): boolean => {
    return smtpValues && Object.keys(mapSMTPValues(smtpValues)).length !== 0;
  };

  /// SMTP Values
  const mapSMTPValues = (smtpValues: ISMTPSettingsBodyRequest) => {
    const payload: ISMTPSettingsBodyRequest = {};
    const smtpSettings: Record<string, any> =
      adminSettings && adminSettings.smtp; /**< SMTP settings */
    if (!smtpSettings) {
      return {};
    }
    if (smtpSettings.SMTP_HOST !== smtpValues.SMTP_HOST) {
      payload['SMTP_HOST'] = smtpValues.SMTP_HOST;
    }
    if (smtpSettings.SMTP_PORT !== smtpValues.SMTP_PORT) {
      payload['SMTP_PORT'] = smtpValues.SMTP_PORT;
    }
    if (smtpSettings.SMTP_SECURE !== smtpValues.SMTP_SECURE) {
      payload['SMTP_SECURE'] = smtpValues.SMTP_SECURE;
    }
    if (smtpSettings.SMTP_USER !== smtpValues.SMTP_USER) {
      payload['SMTP_USER'] = smtpValues.SMTP_USER;
    }
    if (smtpSettings.SMTP_PASS !== smtpValues.SMTP_PASS) {
      payload['SMTP_PASS'] = smtpValues.SMTP_PASS;
    }
    if (smtpSettings.FROM_EMAIL !== smtpValues.FROM_EMAIL) {
      payload['FROM_EMAIL'] = smtpValues.FROM_EMAIL;
    }
    if (smtpSettings.FROM_NAME !== smtpValues.FROM_NAME) {
      payload['FROM_NAME'] = smtpValues.FROM_NAME;
    }
    return payload;
  };

  const testSMTPOnClick = (smtpValues: any): void => {
    handleTestSMTP(smtpValues);
  };

  return (
    <Fieldset legend={translation('setting.headers.change_smtp_settings')}>
      <Form>
        <Row>
          <SharedSettingsSMTPForm
            handleChange={onChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            values={values}
            databaseType={DatabaseType.mongoDB}
            handleOnChangeSecureCheckbox={(e: CheckboxProps) =>
              setFieldValueLocal('SMTP_SECURE', e.checked)
            }
            isLoading={!adminSettings}
          />
        </Row>
        <Row>
          <Col sm={6} className="pb-0 mb-2 mb-sm-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                loading ||
                isLoadingTestSMTP ||
                isShowConfirmationPopup ||
                !isSMTPUpdatesExist ||
                isSMTPErrorsExists
                  ? 'custom-disabled-button'
                  : ''
              }`}
              variant="primary"
              disabled={
                loading ||
                isLoadingTestSMTP ||
                isShowConfirmationPopup ||
                !isSMTPUpdatesExist ||
                isSMTPErrorsExists
              }
              onClick={handleSave}
            >
              {isLoadingTestSMTP
                ? translation('setting.loading')
                : translation('setting.save')}
            </Button>
          </Col>
          <Col sm={6} className="pb-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                isLoadingTestSMTP ||
                isShowConfirmationPopup ||
                isSMTPUpdatesExist
                  ? 'custom-disabled-button'
                  : ''
              }`}
              onClick={() => testSMTPOnClick(values)}
              disabled={
                isLoadingTestSMTP ||
                isShowConfirmationPopup ||
                isSMTPUpdatesExist
              }
            >
              {isLoadingTestSMTP
                ? translation('setting.loading')
                : translation('setting.testSmtp')}
            </Button>
          </Col>
        </Row>
      </Form>
      {getConfirmPopup()}
    </Fieldset>
  );
};

export default withFormik({
  mapPropsToValues: ({ adminSettings }) => {
    if (!adminSettings) return;

    const { smtp } = adminSettings;
    if (!smtp) {
      return {
        SMTP_HOST: '',
        SMTP_PORT: 465,
        SMTP_SECURE: '',
        SMTP_USER: '',
        SMTP_PASS: '',
        FROM_EMAIL: '',
        FROM_NAME: ''
      };
    }

    return {
      SMTP_HOST: smtp.SMTP_HOST,
      SMTP_PORT: smtp.SMTP_PORT,
      SMTP_SECURE: smtp.SMTP_SECURE,
      SMTP_USER: smtp.SMTP_USER,
      SMTP_PASS: smtp.SMTP_PASS,
      FROM_EMAIL: smtp.FROM_EMAIL,
      FROM_NAME: smtp.FROM_NAME
    };
  },
  validationSchema: Yup.object({
    SMTP_HOST: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.smtpSettings.host')
      })
    ),
    FROM_EMAIL: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.smtpSettings.from_email')
      })
    ),
    FROM_NAME: Yup.string().nullable(),
    SMTP_PORT: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.smtpSettings.port')
      })
    ),
    SMTP_USER: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.smtpSettings.user')
      })
    ),
    SMTP_PASS: Yup.string().required(
      i18n.t('validation.required', {
        field: i18n.t('fields.smtpSettings.password')
      })
    )
  }),
  enableReinitialize: true,
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit({
      ...values,
      ...bag.props.settings,
      ...bag.props.adminSettings.braintree
    });
  }
})(SettingsSMTPForm);
