/*
 * DateConversion.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file DateConversion.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {minutesToMilliseconds} from '../constants'
import { format } from 'date-fns';

export const primaryDateFormat: string = 'dd-MM-yyyy'
export const DD_MMMM_YYYY: string = 'dd. MMMM yyyy'
export const ordinalFormat: string = "do 'of' MMMM yyyy"

/**
 * Convert UTC date to local date
 *
 * @param string inputDate
 * @returns Date with the time zone offset
 */
export const convertUTCDateToLocalDate = (inputDate: string | Date): Date => {
  if (!inputDate) {
    return new Date();
  }

  const date: Date = new Date(inputDate);
  const newDate: Date = new Date(
    date.getTime() - (date.getTimezoneOffset() * minutesToMilliseconds)
  );

  return newDate;
};

/**
   * Format Date to display to the user (DDD MM, YYYY)
   * @param dateRange Date range from the library
   */
 export const getFormattedDate = (startDate: Date, endDate: Date): string => `${format(startDate, primaryDateFormat)} - ${format(endDate, primaryDateFormat)}`

 /**
   * Format Date to a custom format
   * @param dateValue Date value
   * @param dateFormat Date format
   */
 export const customFormatDate = (dateValue: Date | string, dateFormat: string) => format(
  new Date(dateValue),
  dateFormat
)