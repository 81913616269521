/*
 * ShopPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ShopPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { fetchProducts } from '../../Store/Products';
import { BASE_API_URL } from '../../Config';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import { useAppSelector } from '../../Hooks';
import ShopWrapper from './ShopWrapper';
import {
  IProduct,
  isUniqueProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../Services/Logs';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import {
  currencyFormatter,
  currencyType
} from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import './ProductPage.css';
import { RouteName } from '../../Utils/routesNames';
import { getTranslatedProductDescription } from '../../Utils/DynamicTranslate';

/**
 * Interface for ShopPage properties.
 */
interface IShopPageProperties {
  products: any;
  fetchProducts: any;
  loading: boolean;
  isPublicRoute: boolean /**< Is Public Route */;
  shopHeaderHTML: string /**< ShopHeader HTML */;
  shopFooterHTML: string /**< ShopFooter HTML */;
  languageSelector: JSX.Element /**< Language selector element */;
}

const ShopPage = ({
  products,
  fetchProducts,
  loading,
  isPublicRoute = false,
  shopHeaderHTML = '',
  shopFooterHTML = '',
  languageSelector
}: IShopPageProperties) => {
  const authState: IAuth = useAppSelector(
    (state) => state.userAuth
  ); /**< Auth State. */
  const [allProductImageCount, setAllProductImageCount] = useState<number>(
    0
  ); /**< All product image count. */

  /// If the user is not authenticated, go to the authentication page to validate the token and return to the shop page.
  useEffect(() => {
    if (!LocalStorage.getXAuthToken()) {
      window.location.href = `${SharedCommomRouteName.validateRoute}?redirect_url=/`;
    } else {
      /// If the user is admin, fetch products
      if (authState.isAdmin) {
        fetchProducts();
      } else {
        /// otherwise, go to Ecommerce application client page
        window.location.href = RouteName.clientTransactionsRoute;
      }
    }
  }, [authState]);

  /// Set all products image count.
  useEffect(() => {
    let count: number = 0;
    products &&
      products.map((product: IProduct) => {
        if (product.live && product.image) {
          count++;
          setAllProductImageCount(count);
        }
      });
    if (products && count === 0) {
      setProductBoxLoading(
        false
      ); /**< If there is no image to be loaded, set productboxloading to false.*/
    }
  }, [products]);

  const translation = useTranslation().t;
  const history = useHistory();
  const [isProductBoxLoading, setProductBoxLoading] = useState<boolean>(
    true
  ); /**< Product box loading state. */
  const [loadedImageCount, setLoadedImageCount] = useState<number>(
    0
  ); /**< Loaded image count. */

  /// Handles image load event.
  const onImageLoadHandler = () => {
    let count: number = loadedImageCount;
    count++;
    setLoadedImageCount(count);
    if (count === allProductImageCount) {
      setProductBoxLoading(false); // When all images are loaded, set productboxloading to false.
    }
  };

  const productBox = (product: IProduct) => {
    const header = product.image ? (
      <img
        src={product.image}
        onLoad={() => onImageLoadHandler()}
        onError={() => onImageLoadHandler()}
        style={{ height: '300px' }}
      />
    ) : (
      <></>
    );
    const footer = (
      <span>
        <Button
          className="p-button p-button-primary btn-block d-flex justify-content-center custom-buy-button-container"
          variant="primary"
          onClick={() => {
            history.push({
              pathname: `/${product?.formattedSKU}`,
              state: { product }
            });
          }}
        >
          {isUniqueProduct(product) ? (
            <>
              {translation('checkoutPage.getFreeProduct')}
              <div className="mx-2"></div>
              {currencyFormatter(
                (product.price / product.duration) as number
              )}{' '}
              {translation('shopPages.priceMonth')}
            </>
          ) : (
            <>
              {translation('checkoutPage.product_buy')}
              <div className="mx-2"></div>
              {currencyFormatter(product.price as number)}
            </>
          )}
        </Button>
      </span>
    );

    return (
      <>
        {product.live && (
          <Col sm={12} md={6} lg={4} className="d-flex justify-content-between">
            <Card
              title={product.name}
              subTitle={product.sku}
              style={{ width: '360px' }}
              className="ui-card-shadow h-100 keep-original-card-style keep-original-card-title-style"
              footer={footer}
              header={header}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslatedProductDescription(product)
                }}
              ></p>
            </Card>
          </Col>
        )}
      </>
    );
  };

  /// Get ShopPage container
  const getShopPageContainer = () => {
    return (
      <>
        {!loading && (
          <>
            <Container>
              <Row className="float-right languageSelector">
                {languageSelector}
              </Row>
            </Container>
            <Container>
              <Row>
                <Col sm={12} className="text-center">
                  <h1>{translation('shopPages.shopTitle')}</h1>
                </Col>
              </Row>
              <Row>
                {products.map((product: IProduct) => productBox(product))}
              </Row>
            </Container>
          </>
        )}
      </>
    );
  };

  /// Shop Page in public route
  if (isPublicRoute) {
    if (loading || isProductBoxLoading) {
      /// Note: The shop page remains hidden until all elements are loaded
      document.documentElement.setAttribute('hidden', 'hidden');
      document.body.classList.add('invisible');
    } else {
      document.documentElement.removeAttribute('hidden');
      document.body.classList.remove('invisible');
    }

    return (
      <ShopWrapper
        shopHeaderHTML={shopHeaderHTML}
        shopFooterHTML={shopFooterHTML}
      >
        {getShopPageContainer()}
      </ShopWrapper>
    );
  }

  return <>{getShopPageContainer()}</>;
};

function mapStateToProps(state) {
  return {
    products: state.products.list,
    loading: state.products.listIsFetching
  };
}

const mapDispatchToProps = {
  fetchProducts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(ShopPage, createLog));
