/*
 * Price.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file Price.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';

import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';

/**
 * Interface for Price properties.
 */
interface IPriceProperties {
  amount: any /** Amount to formatter */;
  currencyType?: string /** Currency type */;
  [key: string]: any /** Any other properties */;
}

const Price = ({ amount, currencyType, ...props }: IPriceProperties) => {
  return <span {...props}>{currencyFormatter(amount, currencyType)}</span>;
};

export default Price;
