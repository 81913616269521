/**
 * Formatter.ts (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file Formatter.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {renderToStaticMarkup} from 'react-dom/server';
import {Placeholders} from '../Config';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const parsePlaceholderUnderscore = (
  placeholder: string,
  placeholderValues: any // TODO - Use proper type and document.
) => {
  try {
    if (placeholder === '' || !placeholder || !placeholderValues) {
      return false;
    }
    const splitByUnderscore: any[] = placeholder.split('_');
    let value: any = placeholderValues;
    for (let i = 0; i < splitByUnderscore.length; i++) {
      // check if the value is an array, pick first
      if (Array.isArray(value)) {
        value = value[0][splitByUnderscore[i]];
      } else {
        value = value[splitByUnderscore[i]];
      }
      // also you changes template so scheck preview and header
    }
    if (!value || value === '') {
      return false;
    }
    return value;
  } catch (error) {
    return false;
  }
};

export const formatTemplate = (
  templateObject: any = {},
  settings: any = {},
  placeholderValues: any = {},
  isPartial = false,
  isPreviewMode = false
) => {
  let template = templateObject.template;
  const templateName = templateObject.name;
  if (template === '' || !template) {
    return '';
  }
  const regex = /\{{(\w+)}}/g;
  regex.lastIndex = 0; // reset regex index to avoid any error later.

  // set default values for placeholder objects
  const defaultPlaceholder = Placeholders.default ? Placeholders.default : {};
  const templatePlaceholder = Placeholders[templateName]
    ? Placeholders[templateName]
    : {};

  const matches = template.match(regex);
  if (matches && matches.length > 0) {
    for (let i = 0; i < matches.length; i++) {
      let value: string | boolean = '';
      const strippedPlaceholder = matches[i].substr(2).slice(0, -2); // remove braces
      if (
        strippedPlaceholder in defaultPlaceholder ||
        strippedPlaceholder in placeholderValues ||
        strippedPlaceholder in settings ||
        strippedPlaceholder in templatePlaceholder
      ) {
        // check for normal (default) placeholders in config
        if (strippedPlaceholder in defaultPlaceholder) {
          value = defaultPlaceholder[strippedPlaceholder];
        }
        // check for placeholders in settings(shop setting / store)
        if (strippedPlaceholder in settings) {
          value = settings[strippedPlaceholder];
        }
        // check for custom placeholders in placeholderValues
        if (strippedPlaceholder in placeholderValues) {
          value = placeholderValues[strippedPlaceholder];
        }
        // if not in PreviewMode, check for placeholders in config that are mapped to objects(i.e server models e.g customer object, product object)
        if (strippedPlaceholder in templatePlaceholder && !isPreviewMode) {
          const result: string | boolean = parsePlaceholderUnderscore(
            strippedPlaceholder,
            placeholderValues
          );
          if (!result) {
            value = isPartial ? matches[i] : '(missing value *)';
          } else {
            value = result;
          }
        }
      } else if (!isPartial) {
        value = '(missing value *)';
      } else {
        value = matches[i];
      }
      if (matches[i] != value) {
        template = template.replace(matches[i], value.toString());
      }
    }
  }
  return template;
};

/**
 * renderCustomComponent
 * @param component
 * @returns renderToStaticMarkup react component
 */
export const renderCustomComponent = (component: any) => {
  return renderToStaticMarkup(component);
};

/**
 * extractScriptsToBody Append script tag to the body
 * @param htmlString
 */
export const extractScripts = (template: string) => {
  const headerString = extractStringfromText(template, '<head>', '</head>');

  if (headerString) document.head.insertAdjacentHTML('beforeend', headerString);

  const bodyString = extractStringfromText(template, '<body>', '</body>');

  if (bodyString) {
    const el = document.createElement('div');
    el.innerHTML = bodyString;
    const scripts = el.querySelectorAll('script');

    for (let i = 0; i < scripts.length; i++) {
      const newScript = document.createElement('script');
      newScript.async = false;
      if (scripts[i].src) {
        newScript.setAttribute('src', scripts[i].src);
      } else {
        newScript.innerHTML = scripts[i].innerHTML;
      }
      document.body.appendChild(newScript);
    }
  }
};

export const getAllTag = (htmlString: any, tag: any) => {
  const el = document.createElement('div');
  el.innerHTML = htmlString;
  const scripts = el.querySelectorAll(tag);
  return scripts;
};

export const getScriptForText = (text: string) => {
  const regex = /<script[^<]*<\/script>/g;
  regex.lastIndex = 0; // reset regex index to avoid any error later.
  const matches = text.match(regex);
  return matches;
};

export const extractStringfromText = (
  text: string,
  beginning: string,
  end: string,
  isMatchingFromBegging: boolean = false
) => {
  const beginningIndex = text.indexOf(beginning);
  const endIndex = text.indexOf(end);
  if (beginningIndex < 0) {
    return '';
  }
  let value = '';
  if (isMatchingFromBegging) {
    value = text.substring(beginningIndex, endIndex);
  } else {
    value = text.substring(beginningIndex + beginning.length, endIndex);
  }
  return value;
};

/**
 * remove mastertemplate's head tags from document.head
 * @param htmlString
 */
export const removeSpecificHeadTags = async (template: string) => {
  const headerString: string = await asyncErrorHandler(extractStringfromText(
      template,
      '<head>',
      '</head>'
  )); /**< Get html head from master template. */
  const allHeadTags: NodeListOf<any> = await asyncErrorHandler(getAllTag(
            headerString,
            '*'
        )); /**< Get all tags from headerstring. */

  // remove tags from document.head.
  allHeadTags?.forEach((headTag) => {
    Array.prototype.slice
      .call(document.head.getElementsByTagName(headTag.tagName))
      ?.forEach((item) => {
        if (item.isEqualNode(headTag)) {
          item.parentNode.removeChild(item);
        }
      });
  });
};

/**
 * Check user role is Authorized or not
 * @param acceptedRoles
 * @param roles
 *
 * @returns boolean
 */
export const isRole = (acceptedRoles: string[], roles: string[]): boolean => {
  const isAllowed: boolean = acceptedRoles?.some((acceptedRole: string) => {
    const foundRole = roles?.findIndex((role: string) => role === acceptedRole);
    if (foundRole < 0) {
      return false;
    }
    return true;
  });

  return isAllowed;
};
