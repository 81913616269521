/*
 * DialogWrapper.tsx (WebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file DialogWrapper.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section WebCommon
 */

import React from 'react'
import { Dialog, DialogProps } from 'primereact/dialog';
import { TFunction } from 'i18next';

/**
 * @interface IDialogWrapperProperties
 */
interface IDialogWrapperProperties extends DialogProps {
  isDialogVisible: boolean /**< Show or hide the dialog */
  onHide: () => void /**< Function to trigger on hide dialog */
  headerTitle?: TFunction | string | React.DetailedHTMLProps<React.HTMLAttributes<any>, any> /**< Header title */
  isCustomClassName?: boolean /**< Add custom-dialog-container class name to the dialog container */
  blockScroll?: boolean /**< If the background scroll should be hide */
  [x: string]: any /**< others properties */
}

/**
 * Render a dialog wrapper.
 * 
 * @param properties IDialogWrapperProperties interface
 * @returns React.FC
 */
const DialogWrapper: React.FC<IDialogWrapperProperties> = (properties: IDialogWrapperProperties) => {
  //Desctructuring to get ...otherProperties props
  const { 
    isDialogVisible, 
    onHide, 
    headerTitle, 
    children,
    blockScroll,
    ...otherProperties 
  } = properties

  return (
    <Dialog
      {...otherProperties}
      className={`${properties.className ?? ''} ${properties.isCustomClassName ? 'custom-dialog-container' : ''}`}
      modal
      focusOnShow={false}
      visible={isDialogVisible}
      onHide={() => onHide()}
      header={headerTitle ?? ''}
      draggable={false}
      resizable={false}
      blockScroll={blockScroll ?? true}
    >
      {children}
    </Dialog>
  )
}

export default DialogWrapper