/*
 * TemplateEditorPage.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file TemplateEditorPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, {Dispatch, ReactElement, useEffect, useState} from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../../../Hooks';
import TemplateComponent from '@abstract/abstractwebcommon-client/TemplateEditor/TemplateComponent';
import {getAllTemplates, updateTemplate} from '../../../Store/Templates';
import {BASE_API_URL, Placeholders} from '../../../Config';
import {ITemplates} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/templates';
import {showToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shopCss = require('!css-loader!resolve-url-loader!sass-loader!../../../Scss/Shop.scss');

/**
 * Interface for TemplatePlacholder.
 */
interface ITemplatePlacholder {
  placeholderKey: string /**< Placeholder Key. */;
  placeholderValue: string /**< Placeholder Value. */;
}

/**
 * Interface for TemplatePayload.
 */
interface ITemplatePayload {
  _id: string /**< Template id. */;
  html: string /**< Template. */;
}

const TemplateEditorPage = (): ReactElement => {
  const translation: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const loading: boolean = useAppSelector(
    (state) => state.templates.listIsFetching
  ); /**< Templates fetching. */
  const templates: ITemplates[] = useAppSelector(
    (state) => state.templates.list
  ); /**< Templates */
  const [masterTemplate, setMasterTemplate] = useState<string>(
    ''
  ); /**< Master Template. */
  const [formattedTemplates, setFormattedTemplates] = useState<ITemplates[]>(
    []
  ); /**< Formatted Templates. */
  const shopSettings: IShopSettings = useAppSelector(
    (state) => state.shopSettings.list
  ); /**< Shop Settings. */
  const codeMirrorOptions = {
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  }; /**< Code Mirror options */

  /// Copy placeholder
  const copyPlaceholder = (item: string) => {
    navigator.clipboard.writeText(`{{${item}}}`);
    showToast({ severity: 'success', summary: translation('template.copy') });
  };

  /// onSubmit action
  const onTemplateSubmit = (updatedTemplate: ITemplatePayload) => {
    dispatch(updateTemplate(updatedTemplate._id, updatedTemplate.html));
  };

  useEffect(() => {
    const fetchTemplates = async () => {
        await asyncErrorHandler(dispatch(getAllTemplates()));
    };

    fetchTemplates();
  }, [getAllTemplates]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      setMasterTemplate(
        templates.find((template) => template.isMainEmailTemplate)?.template ||
          ''
      ); /**< Main Email Template. */

      //Add Placeholder & type to all templates
      const allTemplates: ITemplates[] = templates.map(
        (template: ITemplates) => {
          const templatePlaceholders: Record<string, string> =
            Placeholders[template.name || '']; /**< Template Placeholders. */
          const defaultPlacholders: Record<string, string> =
            Placeholders.default; /**< Default Plceholders. */

          const formatTemplatePlaceholders: ITemplatePlacholder[] = Object.keys(
            templatePlaceholders
          ).map((placeholderKey: string) => {
            return {
              placeholderKey: placeholderKey,
              placeholderValue: templatePlaceholders[placeholderKey]
            };
          }); /**< Format Template Plceholders. */
          const formatdefaultPlaceholders: ITemplatePlacholder[] = Object.keys(
            defaultPlacholders
          ).map((placeholderKey: string) => {
            return {
              placeholderKey: placeholderKey,
              placeholderValue: defaultPlacholders[placeholderKey]
            };
          }); /**< Format Default Plceholders. */

          const allPlaceholders: ITemplatePlacholder[] = [
            ...formatTemplatePlaceholders,
            ...formatdefaultPlaceholders
          ]; /**< Template Plceholders and Default Plceholders. */

          return {
            ...template,
            type: template.name,
            placeholder: allPlaceholders
          };
        }
      );
      setFormattedTemplates(allTemplates); /**< Formatted Templates. */
    }
  }, [templates]);

  return (
    <>
      <TemplateComponent
        codeMirrorOptions={{
          mode: 'text/html',
          ...codeMirrorOptions
        }}
        templates={formattedTemplates}
        submitHandler={onTemplateSubmit}
        submitClasses={'p-button p-button-primary'}
        editorClasses={'code-mirror-container'}
        changeLayoutClasses={'p-button p-button-primary'}
        masterTemplate={masterTemplate}
        copyPlaceholderHandler={copyPlaceholder}
        applicationCSS={shopCss || ''}
        customCSSLink={`${BASE_API_URL}${shopSettings.customCssLink}` || ''}
        isTemplateNameReadonly={true}
      />
    </>
  );
};
export default TemplateEditorPage;
