/*
 * DatatableColumn.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file DatatableColumn.tsx
 * @author Sai Charan K
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import OverflowableText from "../OverflowableText";
import './index.css';
import { showToast } from "../AlertToast/AlertToast";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface IDatatableColumnProperties {
  enableCopy?: any;
  title: string;
  data: any;
  className?: string;
  isEllipsisVisible?: boolean /**< Should display the ellipsis feature by default */
  onClickEventHandler?: () => void; /**< Handler for click event */
}

const DatatableColumn = ({
  enableCopy,
  title,
  data,
  className,
  isEllipsisVisible = true,
  onClickEventHandler
}: IDatatableColumnProperties): ReactElement => {
  const { t } = useTranslation();
  const [showCopyId, setShowCopyId] = useState<number>(-1);

  const getCopyText = () => {
    if (enableCopy && enableCopy?.value) {
      return (
        <p
          className="m-0 d-flex align-items-center justify-content-center"
          onMouseEnter={() => {
            setShowCopyId(enableCopy.id);
          }}
          onMouseLeave={() => setShowCopyId(-1)}
        >
          <span
            title={t("awc:/.base_datatable.datatable_column.copy")}
            className="w-100"
          >
            <OverflowableText text={data} position="left" />
          </span>
          <i className="far fa-copy" style={{ visibility: enableCopy.id === showCopyId ? 'visible' : 'hidden' }} onClick={onCopy} />
        </p>
      );
    }

    return typeof data === 'string' && isEllipsisVisible ? <OverflowableText text={data} /> : data
  };

  const onCopy = () => {
    navigator.clipboard.writeText(`${enableCopy?.value}`);
    showToast({
      severity: "success",
      summary: t("awc:/.base_datatable.datatable_column.copy_success", {
        text: `${title}`,
      }),
    });
  };

  return (
    <>
      <table className={`datatablecolumn-screen-s ${className}`}>
        <tbody>
          <tr>
            <td>
              <span className="titleCol">{title}</span>
            </td>
            <td>
              {getCopyText()}
            </td>
          </tr>
        </tbody>
      </table>
      <span className="datatablecolumn-screen-l" onClick={onClickEventHandler}>
        {getCopyText()}
      </span>
    </>
  );
};

export default DatatableColumn;
