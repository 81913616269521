import React from 'react'

import './ExpansionRow.css'

/**
 * @interface IExpansionRowProperties
 */
interface IExpansionRowProperties {
  isSmallBreakpoint?: boolean /**< Define if the breakpoint is SM. */
  children: React.ReactNode /**< Children to render (th and td). */
}

/**
 * Display expansion row functionality
 * @param properties type of IExpansionRowProperties
 * @returns JSX.Element
 */
const ExpansionRow = (properties: IExpansionRowProperties): JSX.Element => {
  const handleWhichRowStyleToDisplay = () =>
    properties.isSmallBreakpoint
      ? 'pt-2 expansion-row-container expansionContainer d-block d-sm-none' /* Note: this className is setted only in > 575px (sm breakpoint) or more high width */
      : 'text-left expansion-row-container tableExpansionSmall d-none d-sm-block' /* Note: this className is setted only in < 575px (sm breakpoint) or less width */

  return (
    <table className={handleWhichRowStyleToDisplay()}>
      {properties.children}
    </table>
  )
}

export default ExpansionRow