/*
 * CheckoutPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file CheckoutPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentData from './PaymentData';
import CheckoutSummary from './CheckoutSummary';
import { fetchOneProduct, resetProductState } from '../../../Store/Products';
import {
  fetchSettings,
  getUserApplicationSafeSettingsAction
} from '../../../Store/ShopSettings';
import ProductDetails from '../ProductDetails';
import { withPaymentProvider } from './WithPaymentProvider';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { calculatePrice } from '../../../Store/Price';
import ProductOptions from './ProductOptions';
import {
  handlePayment,
  toCheckProductAlreadyPurchased
} from '../../../Store/Transactions';
import TransactionError from './TransactionError';
import BillingForm from './BillingForm';
import DiscountCode from './DiscountCode';
import eventConstants from '../../../Utils/EventConstants';
import { checkDiscount, resetState } from '../../../Store/Discounts';
import {
  createOrUpdateAddress,
  getAuthenticatedUser,
  getUserProfileInformationAction
} from '../../../Store/Users';
import { createLog } from '../../../Services/Logs';
import { TermsAndConditionState } from '../../../Store/TermsAndCondition';

import './TransactionPage.css';
import ShopWrapper from '../ShopWrapper';
import { ICountry } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import { useAppSelector } from '../../../Hooks';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import LoginWidget from '@abstract/abstractwebcommon-client/LoginWidget/LoginWidget';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { IGetSafeSettingsResponse } from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import { customLocalStorageClearUtil } from '../../../Utils/localStorageUtils';
import { readApplicationDetails } from '../../../Services/Settings';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { isAddressValidAction } from 'src/Store/AddressValidation';
import { IAddressValidationResponse } from '@abstract/abstractwebcommon-shared/interfaces/addressValidation';
import {
  AddressFieldsToValidateENUM,
  CountriesAddressValidationCoverageENUM
} from '@abstract/abstractwebcommon-shared/enum/ecommerce/addressValidation';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { FormikErrors } from 'formik';
import {
  IOption,
  isUniqueProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { Message } from 'primereact/message';
import {
  getTranslatedDisabledProductMessage,
  getTranslatedLoggedOutWarningMessage
} from '../../../Utils/DynamicTranslate';
import { IUserProfile } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

/**
 * Interface for CheckoutPage properties.
 */
interface ICheckoutPageProperties {
  type: string;
  productID: any;
  setModalOpen: any;
  cleanUpMethod: () => void;
  paymentWrapperRef?: any;
  paymentWidgetReady?: any;
  widgetInstance?: any;
  createWidget?: any;
  destroyWidget?: any;
  checkPaymentMethod?: any;
  frontendPaymentError?: any;
  isPublicRoute: boolean /**< Is Public Route */;
  shopHeaderHTML: string /**< ShopHeader HTML */;
  shopFooterHTML: string /**< ShopFooter HTML */;
  languageSelector: JSX.Element /**< Language selector element */;
}

const CheckoutPage = ({
  type = 'shop',
  productID,
  setModalOpen,
  paymentWrapperRef,
  paymentWidgetReady,
  widgetInstance,
  createWidget,
  destroyWidget,
  checkPaymentMethod,
  frontendPaymentError,
  cleanUpMethod = () => {},
  isPublicRoute = false,
  shopHeaderHTML = '',
  shopFooterHTML = '',
  languageSelector
}: ICheckoutPageProperties) => {
  const {
    transactionIsCreating,
    token,
    tokenIsFetching,
    error: transactionError,
    paymentError,
    isSendingInvoiceEmail,
    isProcessingFreeProductTransaction,
    isCheckingProductAlreadyPurchased
  } = useSelector((state) => state.transactions);
  const user = useSelector((state) => state.userAuth);
  const usersState = useSelector((state) => state.users); /**< User state */
  const addressAPIValidation = useSelector(
    (state) => state.addressAPIValidation
  ); /**< User state */
  const { product, productIsFetching } = useSelector((state) => state.products);
  const { productOptions } = useSelector((state) => state.shopSettings.list);
  const settings: ISettings = useAppSelector((state) => state.settings);
  const shopSettings: IShopSettings = useAppSelector(
    (state) => state.shopSettings.list
  ); /**< Shop settings */
  const {
    calculation: priceCalculation,
    isFetching: priceCalculationIsFetching
  } = useSelector((state) => state.price);
  const termsAndConditionState: TermsAndConditionState = useSelector(
    (state) => state.termsAndCondition
  );

  const userProfileInformation: IUserProfile = useAppSelector(
    (state) => state.users.userProfileInformation
  );

  const [isFreeProduct, setIsFreeProduct] = useState<boolean>(false);

  const address = useSelector((state) => state.users);

  const history = useHistory();
  const productSKUFromParam = useParams().productSKU; /**< Product SKU */

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const { track } = eventConstants.event;
  const logAnalytics = (event, productId) => {
    analytics.track(event.name, {
      description: event.description,
      product: productId
    });
  };
  const [isProductPurchasedAlready, setProductPurchasedAlready] = useState<
    boolean
  >(false); /**< Is product purchased already or not. */
  const [paymentInfo, setPaymentInfo] = useState({});
  const [billingInfo, setBillingInfo] = useState({});
  const [productCount, setCount] = useState(1);
  const [options, setOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [discountCode, setDiscountCode] = useState(null);
  const [paymentFormCompleted, setPaymentFormCompleted] = useState(false);
  const [
    authenticatedUserInformation,
    setAuthenticatedUserInformation
  ] = useState(null);
  const [isCheckoutFormDisabled, setCheckoutFormDisabled] = useState<boolean>(
    false
  ); /**< To disable checkout form or not */
  const [selectedOptionsObject, setSelectedOptionsObject] = useState<IOption[]>(
    []
  ); /**< Selected options objects */

  const dispatch = useDispatch();

  const appliedDiscount = useSelector(
    (state) => state.discounts.checkedDiscount
  );
  const appliedDiscountNotFound = useSelector(
    (state) => state.discounts.checkedDiscountError
  );
  let countries: ICountry[] = useAppSelector(
    (state) => state.countries.list
  ); /**< Countries. */
  const userApplicationSafeSettings: IGetSafeSettingsResponse = useAppSelector(
    (state) => state.shopSettings.userApplicationSafeSettings
  ); /**< Get user application safe settings. */
  const [freeProductDiscountName, setFreeProductDiscountName] = useState<
    string
  >(''); /**< Free product discount name */
  const [customFieldsError, setCustomFieldsError] = useState<
    FormikErrors<{
      [k: string]: string;
    }>
  >({}); /**< Errors of custom fields */
  let serialIndex: number = 0; /**< Serial Index */
  const [billingFormSerialIndex, setBillingFormSerialIndex] = useState<number>(
    0
  ); /**< Billing form serial index */

  if (!countries) {
    countries = useAppSelector((state) => state.shopSettings.list.countries);
  }

  // function to get and set form error from billingForm
  const updateFormError = useCallback(
    (val) => {
      setFormErrors(val);
    },
    [setFormErrors]
  );

  useEffect(() => {
    dispatch(resetState()); /**< Reset discount state. */
    dispatch(
      getUserApplicationSafeSettingsAction()
    ); /**< Get user application safe settings */
  }, []);

  useEffect(() => {
    const token = LocalStorage.getXAuthToken();
    if (token) {
      dispatch(
        getAuthenticatedUser(token, (value) =>
          setAuthenticatedUserInformation(value)
        )
      );
    }
  }, [usersState.isAddressChanging]);

  // Fetch product
  useEffect(() => {
    if (productID) {
      dispatch(fetchOneProduct(productID));
    }
    if (productSKUFromParam) {
      dispatch(fetchOneProduct(productSKUFromParam));
    }
    dispatch(fetchSettings());
  }, [fetchOneProduct, productID, productSKUFromParam]);

  useEffect(() => {
    if (discountCode) {
      dispatch(checkDiscount(discountCode));
    }
  }, [discountCode]);

  // check whetever the credit card information is filled out or paypal form flow is completed
  useEffect(() => {
    if (widgetInstance) {
      widgetInstance.on('paymentMethodRequestable', (event) => {
        setPaymentFormCompleted(true);
      });
    }
  }, [widgetInstance]);

  // Calculate price
  useEffect(() => {
    if (product) {
      let country;
      let stateOrProvince;
      let isCompany: boolean = false;
      if (billingInfo.useSameAddressForBilling) {
        country = billingInfo.country;
        stateOrProvince = billingInfo.stateOrProvince;
        isCompany = billingInfo.isCompany;
      } else {
        country = billingInfo.billingCountry;
        stateOrProvince = billingInfo.billingStateOrProvince;
        isCompany = billingInfo.isCompany;
      }
      let defaultFreeProductDiscountName: string =
        ''; /**< Free product discount name */
      if (isFreeProduct && product?.translationData) {
        const englishTranslationIndex: number = product.translationData?.findIndex(
          (data: IProductTranslation) =>
            data.language === LanguageSettingsMode.english
        ) as number; /**< English translation data index */

        defaultFreeProductDiscountName =
          ((product.translationData.length &&
            product.translationData[englishTranslationIndex] &&
            product.translationData[englishTranslationIndex]
              .freeProductDiscountName) as string) ??
          ''; /**< Default free product discount name */
      }
      setFreeProductDiscountName(defaultFreeProductDiscountName);

      dispatch(
        calculatePrice(
          [{ id: product._id, quantity: productCount, options }],
          country,
          stateOrProvince || '',
          isFreeProduct
            ? defaultFreeProductDiscountName
            : appliedDiscount && appliedDiscount.code,
          isCompany
        )
      );
    }
  }, [
    product,
    productCount,
    billingInfo.country,
    billingInfo.stateOrProvince,
    billingInfo.useSameAddressForBilling,
    billingInfo.billingCountry,
    billingInfo.billingStateOrProvince,
    billingInfo.isCompany,
    options,
    productOptions,
    appliedDiscount
  ]);

  // Recreate payment widget on transaction error (Braintree requirement)
  useEffect(() => {
    if (transactionError !== null) {
      if (type === 'shop') {
        logAnalytics(track.PURCHASE_UNSUCCESSFUL, product._id);
      }

      destroyWidget(widgetInstance);
      createWidget();
    }
    setProductPurchasedAlready(
      false
    ); /**< Set it to false when product changes */
  }, [transactionError, product]);

  useEffect(() => {
    // on unmount
    return () => {
      dispatch(resetProductState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (LocalStorage.getXAuthToken() && user.isAuthenticated) {
      dispatch(
        getUserProfileInformationAction()
      ); /**< Get user profile card information that is missing in the authenticatedUserInformation useState hook. */
    }
  }, [LocalStorage.getXAuthToken()]);

  const addProduct = () => {
    if (productCount < product.maxQuantity) {
      setCount(productCount + 1);
    }
  };
  const removeProduct = async () => {
    if (productCount > 1) {
      setCount(productCount - 1);
    }
  };
  const setProductCount = (value) => {
    const intValue = parseInt(value);
    if (intValue >= 1 && intValue <= product.maxQuantity) {
      setCount(intValue);
    }
  };
  
  // trigger validation on billing form, which triggers payment validation if successful
  const billingFormRef: any = useRef();
  const handlePurchaseClick = (conditions, customFields) => {
    setShowFormErrors(true);
    if (billingFormRef && billingFormRef.current) {
      billingFormRef.current.setFieldValue('customConditions', conditions);
      billingFormRef.current.setFieldValue('customFields', customFields);

      // NOTE: We should only fulfill the below fields if the product requires to be logged in and if the field 'userUUID' is not filled.
      if (product?.isLoginRequired && isStringEmptyOrNullOrUndefined(billingInfo?.userUUID)) {
        billingFormRef.current.setFieldValue(
          'email',
          (authenticatedUserInformation && authenticatedUserInformation.email) ||
            ''
        );
        billingFormRef.current.setFieldValue(
          'firstName',
          (authenticatedUserInformation &&
            authenticatedUserInformation.firstName) ||
            ''
        );
        billingFormRef.current.setFieldValue(
          'lastName',
          (authenticatedUserInformation &&
            authenticatedUserInformation.lastName) ||
            ''
        );
      }
      
      // If user is not selected from billing form, use login userUUID.
      if (
        isStringEmptyOrNullOrUndefined(
          billingFormRef.current?.values?.userUUID
        )
      ) {
        billingFormRef.current.setFieldValue(
          'userUUID',
          LocalStorage.getXUserUUID()
        );
      }
      billingFormRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (billingFormRef && billingFormRef.current) {
      if (
        isStringEmptyOrNullOrUndefined(billingFormRef.current?.values?.email)
      ) {
        billingFormRef.current.setFieldValue(
          'email',
          (authenticatedUserInformation &&
            authenticatedUserInformation.email) ||
            ''
        );
      }
      if (
        isStringEmptyOrNullOrUndefined(
          billingFormRef.current?.values?.firstName
        )
      ) {
        billingFormRef.current.setFieldValue(
          'firstName',
          (authenticatedUserInformation &&
            authenticatedUserInformation.firstName) ||
            ''
        );
      }
      if (
        isStringEmptyOrNullOrUndefined(
          billingFormRef.current?.values?.lastName
        )
      ) {
        billingFormRef.current.setFieldValue(
          'lastName',
          (authenticatedUserInformation &&
            authenticatedUserInformation.lastName) ||
            ''
        );
      }
    }
  }, [authenticatedUserInformation, billingFormRef?.current]);

  useEffect(() => {
    // NOTE: If a login is required to purchase the product and the user is not authenticated (or) if the product is disabled, disable the checkout form.
    if (product && product?.isLoginRequired) {
      if (authenticatedUserInformation) {
        setCheckoutFormDisabled(false);
      } else {
        setCheckoutFormDisabled(true);
      }
    }
    if (product && product?.isDisabled) {
      setCheckoutFormDisabled(true);
    }
  }, [product, authenticatedUserInformation]);

  const handleApplyDiscount = (code) => {
    // set to empty discount to retrigger discount check
    setDiscountCode('');

    setDiscountCode(code);

    // track analytics for shop customers.
    if (type === 'shop') {
      if (code !== '') {
        logAnalytics(track.ADDED_DISCOUNT_CODE, product._id);
      } else {
        logAnalytics(track.REMOVED_DISCOUNT_CODE, product._id);
      }
    }
  };

  /**
   * Handles Address form submission.
   * @param values Form inputs
   */
  const handleSaveAddress = (values) => {
    const inputAddressValue = {
      name: values.addressName,
      country: values.country,
      address: values.address1,
      addressTwo: values.address2,
      city: values.city,
      stateOrProvince: values.stateOrProvince,
      zipCode: values.zipcode
    };
    dispatch(createOrUpdateAddress(inputAddressValue, values.savedAddressId));
  };

  // check if widget payment method is valid and then create transaction
  const checkPaymentAndCreateTransaction = async (formBillingInfo) => {
    const isGoogleAPIAvailable: boolean = !isStringEmptyOrNullOrUndefined(
      shopSettings?.googleAddressValidationAPIKey
    );

    let isAddressValidatyResponse: IAPIEntityResponse<
      IAddressValidationResponse
    > | null = null;

    const isSelectedCountryGoogleCoverage: boolean = Object.values(
      CountriesAddressValidationCoverageENUM
    ).includes(formBillingInfo.country);

    const isAddressValidationRequired: boolean =
      isSelectedCountryGoogleCoverage && isFreeProduct;

    let isAddressValidationInvalid: boolean = false;

    //Note: Check if the address should be validated.
    //Note: It only should validates if the selected Country has coverage by Google service and if the selected product is a free product.
    //Note: Check the coverage countries in the CountriesAddressValidationCoverageENUM ENUM.
    if (isAddressValidationRequired && isGoogleAPIAvailable) {
      const addressToValidate = {
        address: {
          regionCode: formBillingInfo.country,
          postalCode: formBillingInfo.zipcode,
          administrativeArea: formBillingInfo.stateOrProvince,
          locality: formBillingInfo.city,
          addressLines: [formBillingInfo.address1]
        }
      };

      isAddressValidatyResponse = await asyncErrorHandler(
        dispatch(isAddressValidAction(addressToValidate))
      );

      //Note: The field unconfirmedComponentTypes has the invalid fields. Must loop into it to check if user should continue or stop the purchase.
      for (const invalidField of isAddressValidatyResponse!.validationResponse
        .result.address.unconfirmedComponentTypes) {
        if (!isAddressValidationInvalid) {
          isAddressValidationInvalid = Object.values(
            AddressFieldsToValidateENUM
          ).includes(invalidField as AddressFieldsToValidateENUM);
        }
      }
    }

    if (
      !isAddressValidationInvalid &&
      customFieldsError &&
      Object.keys(customFieldsError).length === 0
    ) {
      const triggerCheckPaymentMethod = () =>
        checkPaymentMethod(priceCalculation.price, formBillingInfo);

      let isCreateTransaction: boolean = true;
      if (isUniqueProduct(product)) {
        if (!isStringEmptyOrNullOrUndefined(formBillingInfo.userUUID)) {
          const result = await asyncErrorHandler(
            dispatch(
              toCheckProductAlreadyPurchased(
                product._id,
                formBillingInfo.userUUID
              )
            )
          );

          /// Note: If the user has already purchased this product, do not allow another purchase
          if (result && result.isProductPurchased) {
            isCreateTransaction = false;
            setProductPurchasedAlready(result.isProductPurchased);
          }
        }
      }

      if (isCreateTransaction) {
        dispatch(
          handlePayment(
            triggerCheckPaymentMethod,
            priceCalculation,
            formBillingInfo,
            product,
            productCount,
            options,
            isFreeProduct ? freeProductDiscountName : discountCode,
            history,
            analytics,
            type,
            () => {
              setModalOpen(false);
              cleanUpMethod();
            }
          )
        );
      }
    }
  };

  const globalAndProductOptions =
    product && product.options.concat(productOptions);

  // Scrolls into invalid element on submit
  useEffect(() => {
    if (showFormErrors) {
      if (formErrors && Object.keys(formErrors).length > 0) {
        const invalidElements = Object.keys(formErrors);
        let elementLabel = null;
        const firstInvalidElement = document.getElementsByName(
          invalidElements[0]
        )[0];
        if (firstInvalidElement !== undefined) {
          if (firstInvalidElement.id !== '') {
            elementLabel = document.querySelector(
              'label[for=' + firstInvalidElement.id + ']'
            );
          } else {
            elementLabel = document.querySelector(
              'label[for=' + firstInvalidElement.name + ']'
            );
          }
          if (elementLabel !== null) {
            // Scroll to first invalid element label
            elementLabel.scrollIntoView({ behavior: 'smooth' });
          } else {
            // Scroll to first invalid element
            firstInvalidElement.scrollIntoView({ behavior: 'smooth' });
          }
          setShowFormErrors(false);
        }
      }
    }
  }, [showFormErrors, formErrors]);

  // Catch brain tree validation error
  useEffect(() => {
    const brainTreeForm = document.querySelector('.braintree-sheet--has-error');
    if (brainTreeForm !== null && brainTreeForm !== undefined) {
      brainTreeForm.scrollIntoView({ behavior: 'smooth' });
      setShowFormErrors(false);
    }
  }, [showFormErrors, document.querySelector('.braintree-sheet--has-error')]);

  /// Handles Logout
  const onLogout = () => {
    customLocalStorageClearUtil();
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode(),
      redirect_url: `/${product?.formattedSKU}/buy`
    }); /**< Query parameters */
    window.location.href = `${
      SharedCommomRouteName.validateRoute
    }?${parameters.toString()}`;
  };

  /// Handles register
  const onRegister = async () => {
    const application: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
      readApplicationDetails()
    ); // Get application.
    const applicationUUID: string = application?.application?.applicationUUID;
    const redirectURLParameters: URLSearchParams = new URLSearchParams({
      redirect_url: `/${product?.formattedSKU}/buy`
    }); /**< Redirect URL query parameters */
    const redirectURL: string = `${shopSettings?.frontendBaseURL}${
      SharedCommomRouteName.validateRoute
    }?${redirectURLParameters.toString()}`;
    const parameters: URLSearchParams = new URLSearchParams({
      app: applicationUUID,
      redirectURL: redirectURL
    }); /**< Query parameters */
    window.location.href = `${shopSettings?.userFrontendBaseURL}${
      SharedCommomRouteName.registerRoute
    }?${parameters.toString()}`;
  };

  useEffect(() => {
    if (product) {
      setIsFreeProduct(isUniqueProduct(product));
      createWidget();
    }
  }, [product]);

  if (!product) return <Loader />;

  /**
   * Get logged out message banner
   */
  const getLoggedOutMessageBanner = (isShopPage?: boolean): JSX.Element => {
    const loggedOutMessage: string = getTranslatedLoggedOutWarningMessage(
      shopSettings?.translationData
    ); /**< Logged out message */

    return (
      <>
        {isShopPage &&
        product?.isLoginRequired &&
        !authenticatedUserInformation &&
        !product?.isDisabled &&
        loggedOutMessage &&
        (serialIndex == 0 || serialIndex == 1) ? (
          <Col sm={12} md={12}>
            <div className="container warning-message-container">
              <div className="row">
                <div className="col-md-12 pb-0">
                  <p
                    className="warning-text-container"
                    dangerouslySetInnerHTML={{
                      __html: loggedOutMessage
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </>
    );
  };

  /// Get CheckoutPage Container
  const getCheckoutPageContainer = (isShopPage?: boolean) => {
    return (
      <>
        <Container>
          <Row className="float-right languageSelector">{languageSelector}</Row>
        </Container>
        <Container className="p-1 p-md-4 checkout">
          <div id="checkout">
            <ProductDetails product={product} loading={productIsFetching} />
            {product && product?.isDisabled ? (
              <Message
                severity="info"
                text={getTranslatedDisabledProductMessage(
                  product?.translationData
                )}
                className="disabled-message"
              />
            ) : (
              <></>
            )}
            <Row>
              {isShopPage ? (
                <Col sm={12} md={12}>
                  <LoginWidget
                    isShowLoginForm={product?.isLoginRequired}
                    loginText={
                      isUniqueProduct(product)
                        ? t('checkoutPage.loginCard.freeProductLoginText')
                        : t('checkoutPage.loginCard.regularProductLoginText')
                    }
                    registerText={t('checkoutPage.loginCard.registerText')}
                    loginButtonText={t(
                      'checkoutPage.loginCard.login_button_text'
                    )}
                    manageMyAbstractIDButtonText={t(
                      'checkoutPage.loginCard.manage_my_abstract_ID_button_text'
                    )}
                    logoutButtonText={t(
                      'checkoutPage.loginCard.logout_button_text'
                    )}
                    registerButtonText={t(
                      'checkoutPage.loginCard.register_button_text'
                    )}
                    loggedInText={t('checkoutPage.loginCard.logged_in_text')}
                    loginHandler={() => {
                      const parameters: URLSearchParams = new URLSearchParams({
                        redirect_url: `/${product?.formattedSKU}/buy`
                      }); /**< Redirect URL query parameters */
                      window.location.href = `${
                        SharedCommomRouteName.validateRoute
                      }?${parameters.toString()}`;
                    }}
                    logoutHandler={onLogout}
                    registerHandler={onRegister}
                    applicationTitle={
                      userApplicationSafeSettings?.applicationTitle ||
                      t('checkoutPage.loginCard.applicationTitle')
                    }
                    applicationLogoURL={
                      userApplicationSafeSettings?.logoImageUrl
                    }
                    userInformation={{
                      ...(authenticatedUserInformation ?? {}),
                      ...userProfileInformation
                    }}
                    manageAccountURL={`${
                      settings?.setting?.userFrontendBaseURL
                    }${
                      SharedCommomRouteName.loginRoute
                    }?token=${LocalStorage.getXAuthToken()}&themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}`}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col sm={12} md={12}>
                <BillingForm
                  updateFormError={updateFormError}
                  innerRef={billingFormRef}
                  values={billingInfo}
                  onChange={setBillingInfo}
                  handleSubmit={checkPaymentAndCreateTransaction}
                  isLoading={
                    transactionIsCreating ||
                    productIsFetching ||
                    address.isAddressChanging ||
                    isSendingInvoiceEmail ||
                    isProcessingFreeProductTransaction ||
                    isCheckingProductAlreadyPurchased ||
                    isCheckoutFormDisabled ||
                    addressAPIValidation.isCheckingValidity
                  }
                  countries={countries}
                  userInformation={authenticatedUserInformation}
                  serialNumber={serialIndex}
                  handleSaveAddress={handleSaveAddress}
                  isShopPage={isShopPage}
                  setProductPurchasedAlready={setProductPurchasedAlready}
                  product={product}
                  setBillingFormSerialIndex={setBillingFormSerialIndex}
                  loggedOutMessageBanner={getLoggedOutMessageBanner(isShopPage)}
                />
              </Col>
              {globalAndProductOptions && globalAndProductOptions.length > 0 && (
                <Col sm={12}>
                  <ProductOptions
                    product={product}
                    globalProductOptions={productOptions}
                    selectedOptions={options}
                    setOptions={setOptions}
                    values={billingInfo}
                    serialNumber={(serialIndex = billingFormSerialIndex + 1)}
                    isDisabled={isCheckoutFormDisabled}
                    storeTranslation={shopSettings?.translationData}
                    selectedOptionsObject={selectedOptionsObject}
                    setSelectedOptionsObject={setSelectedOptionsObject}
                    loggedOutMessageBanner={getLoggedOutMessageBanner(
                      isShopPage
                    )}
                  />
                </Col>
              )}
              <Col sm={12} md={12} id="payment-data">
                <PaymentData
                  token={token}
                  disabled={
                    transactionIsCreating ||
                    isSendingInvoiceEmail ||
                    addressAPIValidation.isCheckingValidity
                  }
                  loading={tokenIsFetching}
                  paymentInfoSet={Object.keys(paymentInfo).length > 0 || false}
                  setPaymentInfo={setPaymentInfo}
                  wrapperRef={paymentWrapperRef}
                  error={paymentError}
                  frontendPaymentError={frontendPaymentError}
                  values={billingInfo}
                  serialNumber={
                    globalAndProductOptions &&
                    globalAndProductOptions.length > 0
                      ? ++serialIndex
                      : (serialIndex = billingFormSerialIndex + 1)
                  }
                  isShopPage={isShopPage}
                  additionalClassNames={
                    product && !product.isPaymentSectionHidden ? '' : 'd-none'
                  }
                  loggedOutMessageBanner={getLoggedOutMessageBanner(isShopPage)}
                  isDisabled={isCheckoutFormDisabled}
                />
              </Col>
              {product && !isUniqueProduct(product) ? (
                <Col sm={12}>
                  <DiscountCode
                    handleSubmit={handleApplyDiscount}
                    discount={appliedDiscount}
                    discountNotFound={appliedDiscountNotFound}
                    billingInfo={billingInfo}
                    serialNumber={++serialIndex}
                    isShopPage={isShopPage}
                    loading={isCheckoutFormDisabled}
                    loggedOutMessageBanner={getLoggedOutMessageBanner(
                      isShopPage
                    )}
                  />
                </Col>
              ) : (
                <></>
              )}

              <Col sm={12}>
                <TransactionError error={transactionError} />
                {Object.keys(formErrors).length > 0 &&
                  formErrors.constructor === Object &&
                  showFormErrors && (
                    <TransactionError error={{ status: 'form_error' }} />
                  )}
                <CheckoutSummary
                  loading={
                    transactionIsCreating ||
                    productIsFetching ||
                    isSendingInvoiceEmail ||
                    isProcessingFreeProductTransaction ||
                    isCheckingProductAlreadyPurchased ||
                    addressAPIValidation.isCheckingValidity
                  }
                  addProduct={addProduct}
                  removeProduct={removeProduct}
                  setProductCount={setProductCount}
                  handleSubmit={handlePurchaseClick}
                  product={product}
                  productCount={productCount}
                  priceCalculation={priceCalculation}
                  priceCalculationIsFetching={priceCalculationIsFetching}
                  countries={countries}
                  billingInfo={billingInfo}
                  serialNumber={
                    product && !product.isPaymentSectionHidden
                      ? ++serialIndex
                      : (serialIndex = serialIndex--)
                  }
                  isShopPage={isShopPage}
                  isProductPurchasedAlready={isProductPurchasedAlready}
                  isDisabled={isCheckoutFormDisabled}
                  storeTranslation={shopSettings?.translationData}
                  selectedOptions={selectedOptionsObject}
                  setCustomFieldsError={setCustomFieldsError}
                  loggedOutMessageBanner={getLoggedOutMessageBanner(isShopPage)}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  };

  /// Checkout Page in public route.
  if (isPublicRoute) {
    if (productIsFetching || !userApplicationSafeSettings) {
      document.documentElement.setAttribute('hidden', 'hidden');
      document.body.classList.add('invisible');
    } else {
      document.documentElement.removeAttribute('hidden');
      document.body.classList.remove('invisible');
    }

    return (
      <ShopWrapper
        shopHeaderHTML={shopHeaderHTML}
        shopFooterHTML={shopFooterHTML}
      >
        {getCheckoutPageContainer(true)}
      </ShopWrapper>
    );
  }

  return <>{getCheckoutPageContainer()}</>;
};

export default withErrorBoundary(withPaymentProvider(CheckoutPage), createLog);
