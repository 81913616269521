/*
 * ClientSubscriptions.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file ClientSubscriptions.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {ISubscription} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import {getAllSubscriptionsByUserUUID} from '../../Services/Subscriptions/ClientAdminSubscriptions';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_REQUEST =
  'subscriptions/get/by/userUUID/request';
const GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_SUCCESS =
  'subscriptions/get/by/userUUID/success';
const GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_FAILURE =
  'subscriptions/get/by/userUUID/failure';

const INITIAL_STATE = {
  list: null,
  isFetchingList: false,
  listFetchError: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_REQUEST:
      return { ...state, isFetchingList: true };
    case GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_SUCCESS:
      return {
        ...state,
        listFetchError: false,
        list: action.payload.subscriptions,
        isFetchingList: false,
        totalRecords: action.payload.totalRecords
      };
    case GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        listFetchError: action.error,
        list: []
      };
    default:
      return state;
  }
};

const getSubscriptionsByUserUUIDRequest = () => ({
  type: GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_REQUEST
});

export const getSubscriptionsByUserUUIDSuccess = (
  subscriptions: ISubscription[],
  totalRecords: number
) => ({
  type: GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_SUCCESS,
  payload: { subscriptions, totalRecords }
});

const getSubscriptionsByUserUUIDFailure = (error: string) => ({
  type: GET_ALL_SUBSCRIPTIONS_BY_USER_UUUID_FAILURE,
  error
});

export const getSubscriptionsByUserUUIDAction = (
  skip?: number,
  limit?: number,
  searchTerm?: string,
  sort?: any
) => async (dispatch) => {
  const errorMessage: string = 'Get Subscriptions Failed.';

  try {
    dispatch(getSubscriptionsByUserUUIDRequest());
    const result: IAPIEntityResponse<ISubscription[]> = await asyncErrorHandler(getAllSubscriptionsByUserUUID(
        skip,
        limit,
        searchTerm,
        sort
    ));
    if (result.error) {
      if (result.status === 401) {
        handleError({ message: result?.error?.message });
      } else {
        handleError({ message: errorMessage });
      }
      dispatch(getSubscriptionsByUserUUIDFailure(result.error as string));
    } else {
      dispatch(
        getSubscriptionsByUserUUIDSuccess(
          result.subscriptions,
          result.totalRecords
        )
      );
    }
  } catch (error) {
    dispatch(getSubscriptionsByUserUUIDFailure(error as string));
    handleError({ message: errorMessage });
  }
};
