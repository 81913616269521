/**
* sharedRoutesNames.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file sharedRoutesNames.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

/**
 * @enum SharedMainRouteName
 * Use this file to centralize all shared main route names across our applications
 * Add route value below as needed.
 */
export enum SharedMainRouteName {
  adminRoute = '/admin' /**< /admin route name */,
  userRoute = '/user' /**< /user route name */,
  clientRoute = '/client' /**< /client route name */
}

/**
 * @enum SharedCommomRouteName
 * Use this file to centralize all shared commom route names across our applications
 * Add route value below as needed.
 */
export enum SharedCommomRouteName {
  loginRoute = '/login' /**< /login route name */,
  registerRoute = '/register' /**< /register route name */,
  configurationSettingsRoute = '/configuration-settings' /**< /configuration-settings route name */,
  code401Route = '/401' /**< /401 route name */,
  code404Route = '/404' /**< /404 route name */,
  validateRoute = '/validate' /**< /validate route name */,
  adminUserManagementRoute = `${SharedMainRouteName.adminRoute}/user-management` /**< /admin/user-management route name */,
  productBuyRoute = '/buy' /**< Product buy page */
}