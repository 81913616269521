/**
* DashboardSlice.ts (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022

* @file DashboardSlice.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import {
  AnyAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState
} from '@reduxjs/toolkit';
import {IReducerAction} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import i18n from '../services/i18n';
import {IFetchSystemVersionResponse} from '@abstract/abstractwebcommon-shared/interfaces/user/api';
import {IMappedFeed} from '@abstract/abstractwebcommon-shared/interfaces/feed';
import {Reducer} from 'react';
import {DashboardApi} from '../services/DashboardApi';
import {CreateErrorLog} from '../../Shared/utils/CreateErrorLog';
import {handleError} from '../ErrorHandler/ErrorHandler';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const dashboardFeatureKey: string = 'dashboard';

/**
* @Interface IDashboardState
*/
export interface IDashboardState {
  statistics: Record<string,number>;
  version: string;
  feedsData: IMappedFeed;
  systemWarnings: Record<string, string>[];
  isStatisticsFetched: boolean;
  isVersionFetched: boolean;
  isFeedFetched: boolean;
  isWarningsFetched: boolean;
  isLoading: boolean;
}

export const dashboardInitialState: IDashboardState = {
  statistics: null,
  version: null,
  feedsData: null,
  systemWarnings: [],
  isStatisticsFetched: false,
  isVersionFetched: false,
  isFeedFetched: false,
  isWarningsFetched: false,
  isLoading: true
};

export const dashboardAdapter: EntityAdapter<IDashboardState> = createEntityAdapter();
export const initialDashboardState: EntityState<IDashboardState> & IDashboardState =
  dashboardAdapter.getInitialState(dashboardInitialState);

/// Reset state
const clearState = (state: IDashboardState) => {
  state.isStatisticsFetched = false;
  state.isVersionFetched = false;
  state.isFeedFetched = false;
  state.isWarningsFetched = false;
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const dashboardSlice = createSlice({
  name: dashboardFeatureKey,
  initialState: initialDashboardState,
  reducers: {
    reset: (state: IDashboardState) => clearState(state),
    fetchStatisticsActionRequest(state) {
      state.isLoading = true;
      state.isStatisticsFetched = false;
    },
    fetchStatisticsActionSuccess(
      state: IDashboardState,
      action: IReducerAction<{ statistics: Record<string,number> }>
    ) {
      state.isLoading = false;
      state.isStatisticsFetched = true;
      state.statistics = action && action.payload && action.payload.statistics;
    },
    fetchStatisticsActionFailure(
      state: IDashboardState,
      action: IReducerAction<string>
    ) {
      state.isLoading = false;
      state.isStatisticsFetched = true;
      handleError({ message: action && action.payload || i18n.t("awc:/.dashboard.error_messages.dashboard_statistics_failure")});
    },
    fetchVersionActionRequest(state: IDashboardState) {
      state.isLoading = true;
      state.isVersionFetched = false;
    },
    fetchVersionActionSuccess(
      state: IDashboardState,
      action: IReducerAction<IFetchSystemVersionResponse>
    ) {
      state.isLoading = false;
      state.isVersionFetched = true;
      state.version = action && action.payload && action.payload.version;
    },
    fetchVersionActionFailure(
      state: IDashboardState,
      action: IReducerAction<string>
    ) {
      state.isLoading = false;
      state.isVersionFetched = true;
      handleError({ message: action && action.payload || i18n.t("awc:/.dashboard.error_messages.fetch_version_error")});
    },
    fetchFeedActionRequest(state: IDashboardState) {
      state.isLoading = true;
      state.isFeedFetched = false;
    },
    fetchFeedActionSuccess(state: IDashboardState, action: IReducerAction<IMappedFeed>) {
      state.isLoading = false;
      state.isFeedFetched = true;
      state.feedsData = action && action.payload;
    },
    fetchFeedActionFailure(
      state: IDashboardState,
      action: IReducerAction<string>
    ) {
      state.isLoading = false;
      state.isFeedFetched = true;
      handleError({ message: action && action.payload || i18n.t("awc:/.dashboard.error_messages.fetch_feed_error")});
    },
    fetchSystemWarningsRequest(state: IDashboardState) {
      state.isWarningsFetched = false;
    },
    fetchSystemWarningsSuccess(
      state: IDashboardState,
      action: IReducerAction<Record<string, string>[]>
    ) {
      state.isWarningsFetched = true;
      state.systemWarnings = action && action.payload;
    },
    fetchSystemWarningsFailure(
      state: IDashboardState,
      action: IReducerAction<string>
    ) {
      state.isWarningsFetched = true;
      handleError({ message: action && action.payload || i18n.t("awc:/.dashboard.error_messages.fetch_system_warnings_error")});
    }
  }
});

export const dashboardReducer: Reducer<EntityState<IDashboardState> & IDashboardState, AnyAction> =
  dashboardSlice.reducer;

export const dashboardActions: any = dashboardSlice.actions;

export const getDashboardState: any = (rootState: any) => rootState[dashboardFeatureKey];

/**
 * @Interface IDashboardActionsProperties
 */
export interface IDashboardActionsProperties {
  baseApiUrl: string;
  createLogApi: (payload: CreateErrorLog) => void;
  fetchStatisticsUrl: string;
  fetchVersionUrl: string;
  fetchFeedUrl: string;
  fetchSystemWarningsUrl: string;
}

/**
 * @class DashboardActions
 */
export class DashboardActions {

  private dashboardApi: DashboardApi;
  private fetchStatisticsUrl: string;
  private fetchVersionUrl: string;
  private fetchFeedUrl: string;
  private fetchSystemWarningsUrl: string;

  constructor(properties: IDashboardActionsProperties) {
    this.dashboardApi = new DashboardApi(properties.baseApiUrl, properties.createLogApi);
    this.fetchStatisticsUrl = properties.fetchStatisticsUrl;
    this.fetchVersionUrl = properties.fetchVersionUrl;
    this.fetchFeedUrl = properties.fetchFeedUrl;
    this.fetchSystemWarningsUrl = properties.fetchSystemWarningsUrl;
  }
  
  /**
   * Fetch Statistics Data Action.
   */
  fetchDashboardStatisticsAction = createAsyncThunk(
    'dashboard/statistics',
    async (payload: void, thunkAPI) => {
      const { dispatch } = thunkAPI;
      const {
        fetchStatisticsActionRequest,
        fetchStatisticsActionSuccess,
        fetchStatisticsActionFailure
      } = dashboardActions;
  
      try {
        dispatch(fetchStatisticsActionRequest());
        const response: IAPIEntityResponse<Record<string, number>> = await asyncErrorHandler(this.dashboardApi.fetchStatisticsApi(this.fetchStatisticsUrl));
        if (response.error) {
          const error: string = response.error?.message || response.error as string;
          dispatch(fetchStatisticsActionFailure(error));
        } else {
          dispatch(
            fetchStatisticsActionSuccess({
              statistics: response.data
            })
          );
        }
      } catch (error) {
        dispatch(fetchStatisticsActionFailure(error.message));
      }
    }
  );

  /**
   * Fetch Software version data Action.
   */
  fetchDashboardVersionAction = createAsyncThunk(
    'dashboard/version',
    async (payload: void, thunkAPI) => {
      const { dispatch } = thunkAPI;
      const { fetchVersionActionRequest, fetchVersionActionSuccess, fetchVersionActionFailure } =
        dashboardActions;
  
      try {
        dispatch(fetchVersionActionRequest());
        const response: IAPIEntityResponse<IFetchSystemVersionResponse> =
            await asyncErrorHandler(this.dashboardApi.fetchVersionApi(this.fetchVersionUrl));
        if (response.error) {
          const error: string = response.error?.message || response.error as string;
          dispatch(fetchVersionActionFailure(error));
        } 
        if (response.data) {
          dispatch(
            fetchVersionActionSuccess({
              version: response.data.version
            })
          )
        } else {
          if (response.status != 401) {
            dispatch(fetchVersionActionFailure(response.message));
          }
        }
      } catch (error) {
        dispatch(fetchVersionActionFailure(error.message));
      }
    }
  );

  /**
   * Fetch Feed Data Action.
   */
  fetchFeedAction = createAsyncThunk(
    'dashboard/fetchfeed',
    async (payload: void, thunkAPI) => {
      const { dispatch } = thunkAPI;
      const { fetchFeedActionRequest, fetchFeedActionSuccess, fetchFeedActionFailure } =
        dashboardActions;
        
      try {
        dispatch(fetchFeedActionRequest());
        const response: IAPIEntityResponse<IMappedFeed> = await asyncErrorHandler(this.dashboardApi.fetchFeedApi(this.fetchFeedUrl));
        if (response.error) {
          const error: string = response.error?.message || response.error as string;
          dispatch(fetchFeedActionFailure(error));
        } else {
          dispatch(fetchFeedActionSuccess(response.data));
        }
      } catch (error) {
        dispatch(fetchFeedActionFailure(error.message));
      }
    }
  );

  /**
   * Fetch System warnings Action.
   */
  fetchSystemWarningsAction = createAsyncThunk(
    'dashboard/fetchSystemWarnings',
    async (payload: void, thunkAPI) => {
      const { dispatch } = thunkAPI;
      const { fetchSystemWarningsRequest, fetchSystemWarningsSuccess, fetchSystemWarningsFailure } =
        dashboardActions;
  
      try {
        dispatch(fetchSystemWarningsRequest());
        const response: IAPIEntityResponse<Record<string, string>[]> =
            await asyncErrorHandler(this.dashboardApi.fetchSystemWarningsApi(this.fetchSystemWarningsUrl));
        if (response.error) {
          const error: string = response.error?.message || response.error as string;
          dispatch(fetchSystemWarningsFailure(error));
        } else {
          dispatch(fetchSystemWarningsSuccess(response.data));
        }
      } catch (error) {
        dispatch(fetchSystemWarningsFailure(error.message));
      }
    }
  );
}