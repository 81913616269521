/**
 * Convert dataURL to blob.
 * @param dataURL Base 64 URL.
 * @returns Blob.
 */
export const dataURLtoBlob = (dataURL: string) : Blob | null => {
    var splitDataURL: string[] = dataURL.split(',');
    if (splitDataURL) {
      let mimeType = (splitDataURL[0] as any).match(/:(.*?);/)[1],
        byteString: string = atob(splitDataURL[1]),
        byteStringLength: number = byteString.length,
        uInt8Array: Uint8Array = new Uint8Array(byteStringLength);

      while (byteStringLength--) {
        uInt8Array[byteStringLength] = byteString.charCodeAt(byteStringLength);
      }
      return new Blob([uInt8Array], { type: mimeType });
    }
    return null;
  };