/*
 * ClientContent.tsx (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file ClientContent.tsx
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 * @modified Rafael Rodrigues - 2022
 */

import React, { Suspense, useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { getRoutes } from '../../Routes/Routes';
import { useAppSelector } from '../../Hooks';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import PageHeader from '@abstract/abstractwebcommon-client/PageHeader/PageHeader';
import Col from 'react-bootstrap/Col';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

const ClientContent = () => {
  const user: IAuth = useAppSelector((state) => state.userAuth);
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */

  const [routes, setRoutes] = useState<any>([]); /**< Routes */

  // Get client routes when language changes
  useEffect(() => {
    const clientRoutes = getRoutes();
    setRoutes([...clientRoutes]);
  }, [languageSettingsMode]);

  //Note: Get client routes when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const clientRoutes = getRoutes();
      setRoutes([...clientRoutes]);
    }
  });

  /// Render specific component if isAuthenticated and if isAdmin, Else redirect to login.
  const renderComponent = routes.map(
    ({ path, Component, authenticated, headingKey }, key) => {
      if (authenticated && !user.isAuthenticated) {
        return (
          <Redirect
            key={key}
            to={{ pathname: SharedCommomRouteName.validateRoute }}
          />
        );
      }

      return (
        <Route exact path={path}>
          {headingKey ? (
            <div className="custom-page-header-container">
              <PageHeader title={headingKey} />
            </div>
          ) : null}
          <Col sm={12}>
            <Component />
          </Col>
        </Route>
      );
    }
  );

  return <Switch>{renderComponent}</Switch>;
};

export default ClientContent;
