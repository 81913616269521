/*
 * SettingsTermsAndConditionTable.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file SettingsTermsAndConditionTable.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useRef, useEffect } from 'react';
import { Column } from 'primereact/column';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import Button from 'react-bootstrap/Button';
import { Country } from 'country-state-city';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import {
  ITermsAndCondition,
  ITermsAndConditionTableProperties
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import SettingsTermsAndConditionForm from './SettingsTermsAndConditionForm';
import { translate } from '../../../Utils/Translate';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getTermsAndCondionAction,
  createOrUpdateTermsAndCondionAction,
  deleteTermsAndCondionAction,
  TermsAndConditionState
} from '../../../Store/TermsAndCondition';
import { useAppSelector } from '../../../Hooks';
import {
  ITablePayload,
  ISortEvent,
  IPageEvent
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { useTranslation } from 'react-i18next';

const SettingsTermsAndConditionTable = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [termsAndConditionSettings, setTermsAndConditionSettings] = useState<
    any
  >();
  const [selectedTermsAndCondition, setSelectedTermsAndConditions] = useState<
    ITermsAndCondition[] | null
  >(null);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const deleteButtonReference: any = useRef(null);
  const [searchTerm, setSearchTerm] = useState<string>(''); /**> Search term */
  const dispatch: Dispatch<any> = useDispatch();
  const termsAndConditionState: TermsAndConditionState = useAppSelector(
    (state) => state.termsAndCondition
  ); /**< termsAndConditionState */
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'termsAndCondition.createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const sort: ISortEvent = tablePayload?.sort; /**< Sort */
  const dataTableReference = useRef<any>(null);
  const translation = useTranslation().t;

  /// Get Terms and condition.
  useEffect(() => {
    tablePayload.searchTerm = searchTerm;
    dispatch(getTermsAndCondionAction(tablePayload));
  }, [searchTerm]);

  /// show delete popup
  const onDeleteButtonClicked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: ITermsAndCondition[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow = selectedValue.map((row: ITermsAndCondition) => row);
      setSelectedTermsAndConditions(selectedRow);
    }
  };

  /// Handles submit form
  const handleTermsAndConditionSubmit = (
    payload: ITermsAndCondition,
    id?: string
  ) => {
    if (id) {
      payload.id = id;
    }
    Object.assign(payload, tablePayload);
    dispatch(createOrUpdateTermsAndCondionAction(payload));
    setOpen(false);
  };

  const handleHideDialog = () => setOpen(false);

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      getTermsAndCondionAction({
        skip: updatedPayload.skip,
        limit: updatedPayload.limit,
        searchTerm: updatedPayload.searchTerm,
        sort: updatedPayload.sort
      })
    );
  };

  const header = (
    <>
      <div className="d-flex justify-content-between">
        <div className="headerTableContainer">
          <ActionButton
            variant="danger"
            onClick={(event: any) => onDeleteButtonClicked(event)}
            isDisabled={
              ((selectedTermsAndCondition &&
                Object.keys(selectedTermsAndCondition).length === 0) ||
                !selectedTermsAndCondition) ??
              false
            }
            buttonReference={deleteButtonReference}
          />
          <ActionButton
            onClick={() => {
              setOpen(true);
              setTermsAndConditionSettings(null);
            }}
          />
        </div>

        <SearchBar
          onSearchTermChanged={(searchedTerm) => setSearchTerm(searchedTerm)}
        />
      </div>
      <DialogWrapper
        isDialogVisible={open}
        onHide={() => setOpen(false)}
        headerTitle={
          termsAndConditionSettings
            ? translation(
                'termsAndCondition.edit_terms_and_conditions_header_dialog'
              )
            : translation(
                'termsAndCondition.add_terms_and_conditions_header_dialog'
              )
        }
      >
        <SettingsTermsAndConditionForm
          handleSubmit={handleTermsAndConditionSubmit}
          isLoading={termsAndConditionState.isTermsAndConditionLoading}
          editTermsAndCondition={termsAndConditionSettings}
          termsAndCondition={termsAndConditionState.termsAndCondition}
          handleHideDialog={handleHideDialog}
          tablePayload={tablePayload}
        />
      </DialogWrapper>
    </>
  );

  const handleEdit = (data: ITermsAndCondition) => {
    setOpen(true);
    setTermsAndConditionSettings(data);
  };
  /// Delete on Accept
  const onAccept = async () => {
    const deletionPayload: ITermsAndConditionTableProperties = {
      data: selectedTermsAndCondition,
      limit: tablePayload.limit,
      skip: tablePayload.skip,
      searchTerm: tablePayload.searchTerm,
      sort: tablePayload.sort
    };
    dispatch(deleteTermsAndCondionAction(deletionPayload));
    setShowConfirmation(false);
    setSelectedTermsAndConditions(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };
  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translate('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('confirm_messages.no')}
        acceptLabel={translate('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getTermsAndCondionAction(updatedPayload));

    //Note: only scroll to bottom if pagination was changed
    if (event.first !== tablePayload.skip) {
      dataTableReference.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <BaseDatatable
        ref={dataTableReference}
        value={termsAndConditionState.termsAndCondition}
        header={header}
        selection={selectedTermsAndCondition}
        onSelectionChange={onSelectionChange}
        rows={tablePayload.limit}
        first={tablePayload.skip}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        onPage={handlePageUpdate}
        onSort={handleSortUpdate}
        totalRecords={termsAndConditionState.totalRecords}
        dataKey="id"
      >
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="termsAndCondition.countryDetails.countryName"
          header={translate('termsAndCondition.country')}
          sortable
          body={(row: ITermsAndCondition) => (
            <DatatableColumn
              title={translate('termsAndCondition.country')}
              data={Country.getCountryByCode(row.country)?.name}
            />
          )}
        />
        <Column
          sortable
          field="termsAndCondition.link"
          header={translate('termsAndCondition.termsAndConditionLink')}
          body={(row: ITermsAndCondition) => (
            <DatatableColumn title="Link" data={row.link} />
          )}
        />
        <Column
          field="termsAndCondition.createdAt"
          header={translate('termsAndCondition.created')}
          sortable
          className="createdDateCol d-table-cell d-sm-none d-md-table-cell"
          headerClassName="createdDateCol d-table-cell d-sm-none d-md-table-cell"
          body={(row: ITermsAndCondition) => (
            <DatatableColumn
              title={translate('termsAndCondition.created')}
              data={row.createdAt ? formatDate(row.createdAt) : ''}
            />
          )}
        />
        <Column
          field="edit"
          body={(row: ITermsAndCondition) => {
            return (
              <Button
                variant="outline"
                className="custom-action-column-action-position custom-action-column-margin-mobile-view"
                onClick={() => handleEdit(row)}
              >
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="col-width-45 p-0 absolute-position-responsive-screen"
        />
      </BaseDatatable>
      {getConfirmPopup()}
    </>
  );
};

export default SettingsTermsAndConditionTable;
