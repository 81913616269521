/**
* StaticLinkForm.tsx (InstaLOD GmbH) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2021
* @file StaticLinkForm.tsx
* @author Timothy Fadayini
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

// Other dependencies
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';
import DatatableColumn from '../Table/DatatableColumn';
import BaseDatatable from '../Table/BaseDatatable';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '../InstaImageUpload';
import { InputText } from 'primereact/inputtext';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/staticLinks';
import { IPageEvent, ISortEvent, ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '../Constants';
import { showToast } from '../AlertToast/AlertToast';
import FormWrapper from '../FormControl/FormWrapper';
import ConfirmationPopup from '../ConfirmationPopup';

import '../common.css'
import './StaticLinksForm.css'

//Note: Transalations for the deletion item dialog
//This shared component is used across all projects
//For some reason, the standardize transalation aren't apply to the dialog of Ecommerce project. User and License projects are ok.
const transalateHeaderDeletionItemTitle = 'Do you want to delete this record?'
const transalateNoOptionDeletionItem = 'No'
const transalateYesOptionDeletionItem = 'Yes'

interface IStaticTableProperties {
  tableData: any; /**< Table data */
  isLoading: boolean; /**< True if the table is loading, false otherwise */
  deleteLinks: (selectedStaticLink: IStaticLink[]) => void; /**< Handle delete static links */
  handleSubmit: (payload?: any, staticLinkUUID?: string) => void; /**< Handle submit changes */
  uploadStaticLinkIcon: (event: any[]) => Promise<void>; /**< Handle upload static link icon */
  deleteStaticLinkIcon: (event: any) => void; /**< Handle delete static link icon */
  displayCroppedStaticLinkIcon: any; /**< Display cropped static link icon */
  setCroppedStaticLinkIcon: React.Dispatch<
    React.SetStateAction<any>
  > /**< Set cropped staticlink icon. */;
  staticLinksState: any; /**< Static links state */
  useObjectID?: boolean; /**< True if static links have an object id (MongoDB), false otherwise */
  refreshSaticLinksList: (updatedCriteria?: ITablePayload) => void; /** Header sort */
  referenceHook?: any; /** Header sort */
}
interface IRowData {
  data: IStaticLink;
}

const StaticLinkForm = ({
  tableData = [],
  handleSubmit,
  isLoading,
  deleteLinks,
  uploadStaticLinkIcon,
  deleteStaticLinkIcon,
  displayCroppedStaticLinkIcon,
  setCroppedStaticLinkIcon,
  staticLinksState,
  useObjectID = false,
  refreshSaticLinksList,
  referenceHook = null,
}: IStaticTableProperties): JSX.Element => {

  const translate: TFunction = useTranslation().t;

  const [selectedStaticLink, setSelectedStaticLink] = useState<IStaticLink[]>(null);

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [editStaticLink, setEditStaticLink] = useState<any>({});
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [isDeletingFromHeaderButton, setDeletingFromHeaderButton] = useState<boolean>(false);

  const ref: any = useRef(null);
  const staticLinkIcon: any = useRef(); /**< Reference for staticLinkIcon. */
  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  if (staticLinksState.criteria && staticLinksState.criteria.sort) {
    staticLinksState.criteria.sort &&
      Object.keys(staticLinksState.criteria.sort).forEach((key) => {
        defaultSortField = key; // Sortfield
        defaultSortOrder = staticLinksState.criteria.sort[key]; //Sortorder
      });
  }
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  const onRowClick = (event: IRowData): void => {
    const data = event.data;
    staticLinkIcon.current = data.icon; // Set Current icon value.
    setEditStaticLink(data);
    setIsDialogVisible(true);
    formik.resetForm();
    formik.setFieldValue('name', data.name);
    formik.setFieldValue('icon', data.icon);
    formik.setFieldValue('link', data.link);
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: IStaticLink[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow = selectedValue.map((row: IStaticLink) => row);
      setSelectedStaticLink(selectedRow);
    }
  };

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: ISortEvent) => {
    const updatedCriteria = { ...staticLinksState.criteria };
    updatedCriteria.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedCriteria.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }

    refreshSaticLinksList(updatedCriteria);
    setMultiSortMeta(event.multiSortMeta);
  };

  const onPage = (event: IPageEvent): void => {
    const updatedCriteria = { ...staticLinksState.criteria };
    updatedCriteria.limit = event.rows || defaultTableLimit;
    updatedCriteria.skip = event.first || 0;
    refreshSaticLinksList(updatedCriteria);
  };


  useEffect(() => {
    if (staticLinksState.isStaticLinkCreated || staticLinksState.isStaticLinkUpdated) {
      setIsDialogVisible(false);
      setEditStaticLink({});
      formik.resetForm();
    }
  });

  /// Div containing header action buttons
  const header = (
    <div className="headerTableContainer d-flex">
      <Button
        variant="danger"
        onClick={(event: any) => {
          deleteButtonClicked(event);
          setDeletingFromHeaderButton(true)
        }}
        ref={ref}
        className="mr-2 d-flex align-items-center"
        disabled={((selectedStaticLink && Object.keys(selectedStaticLink).length === 0) || !selectedStaticLink) ??
          false}
      >
        <i className="bi bi-trash btn-icon"></i>
        {translate('awc:/.staticLinkFieldset.delete')}
      </Button>
      <Button className="d-flex align-items-center" onClick={() => setIsDialogVisible(true)}>
        <i className="bi bi-plus btn-icon"></i>
        {translate('awc:/.staticLinkFieldset.add')}
      </Button>
    </div>
  );
  
  const getDataTable = (): JSX.Element => {
    return (
      <Col xs={12} className="px-0">
        <BaseDatatable
          value={tableData}
          selection={selectedStaticLink}
          onSelectionChange={(event: any) => onSelectionChange(event)}
          header={header}
          isLoading={!tableData}
          parentClass="appDataTable" /**< ClassName for div component. */
          dataKey={useObjectID ? 'id' : 'staticLinkUUID'}
          totalRecords={staticLinksState.totalRecords}
          selectionMode="checkbox"
          rows={staticLinksState.criteria.limit}
          first={staticLinksState.criteria.skip}
          onPage={(event: IPageEvent) => onPage(event)}
          onSort={(event: ISortEvent) => onSort(event)}
          ref={referenceHook ?? null}
          multiSortMeta={multiSortMeta}
          sortMode="multiple"
        >
          <Column selectionMode="multiple" className="mt-1 col-width-45" />
          <Column
            field="icon"
            body={(rows: any) => {
              const updated: string = rows.updated ? `?${rows.updated}` : ''; /**< Updated date. */
              return rows.icon ? <img className="img-fluid dimensions-15x15" alt="" src={`${rows.icon}${updated}`} /> : <></>
            }}
            header={translate('awc:/.staticLinkFieldset.icon')}
            className="icon-column-style d-none d-sm-table-cell"
            headerClassName="icon-column-style d-none d-sm-table-cell"
          />

          <Column
            field="name"
            sortable
            header={translate('awc:/.staticLinkFieldset.name')}
            body={(rowData: any) => (
              <DatatableColumn
                title={translate('awc:/.staticLinkFieldset.name')}
                data={rowData.name}
              />
            )}
          />
          <Column
            field="link"
            sortable
            header={translate('awc:/.staticLinkFieldset.url')}
            className="custom-url-column-hide-breakpoint"
            headerClassName="custom-url-column-hide-breakpoint"
            body={(rowData: any) => (
              <DatatableColumn
                title={translate('awc:/.staticLinkFieldset.url')}
                data={rowData.link}
              />
            )}
          />
          <Column
            field="edit"
            body={(rowData: any) => {
              return (
                <Button
                  className="custom-action-column-action-position"
                  onClick={() => {
                    onRowClick({ data: rowData });
                  }}
                  variant="outline">
                  <i className="bi bi-pencil-square editIcon fa-lg"></i>
                </Button>
              );
            }}
            className="p-0 col-width-45 absolute-position-responsive-screen"
          />
        </BaseDatatable>
      </Col>
    );
  };

  /// delete icon handler
  const deleteStaticLinkIconHandler = (event: any): void => {
    formik.setFieldValue('icon', '', false)
    deleteStaticLinkIcon(event);
  };

  /// upload icon handler
  const uploadStaticLinkIconHandler = (event: any): void => {
    formik.setErrors({ icon: undefined });
    uploadStaticLinkIcon(event);
  };

  /// error handler
  const errorHandler = (error: any) => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: translate('awc:/.image.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: translate('awc:/.image.upload_valid_image') });
    }
  };

  /// Delete static link on Accept
  const onAccept = (): void => {
    deleteLinks(isDeletingFromHeaderButton ? selectedStaticLink : [editStaticLink])
    setShowConfirmPopup(false);
    setIsDialogVisible(false);
    setDeletingFromHeaderButton(false);
    setSelectedStaticLink(null)
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmPopup(false);
    setDeletingFromHeaderButton(false);
  };

  const formik = useFormik({
    initialValues: {
      name: editStaticLink.name || '',
      icon: editStaticLink.icon || '',
      link: editStaticLink.link || ''
    },
    enableReinitialize: true,
    validationSchema: () => {
      return Yup.object({
        name: Yup.string()
          .min(2, translate('awc:/validations.min', { field: '2' }))
          .max(50, translate('awc:/validations.max', { field: '50' }))
          .required(
            translate('awc:/validations.required', { field: translate('awc:/.staticLinkFieldset.name') })
          ),
        link: Yup.string()
          .min(2, translate('awc:/validations.min', { field: '2' }))
          .max(500, translate('awc:/validations.max', { field: '500' }))
          .required(
            translate('awc:/validations.required', { field: translate('awc:/.staticLinkFieldset.url') })
          )
          .url(translate('awc:/validations.isUrl')),
        icon: Yup.string().nullable().notRequired()
      })
    },
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          /// To edit staticlink icon
          if (displayCroppedStaticLinkIcon) {
            payload['icon'] = displayCroppedStaticLinkIcon.name;
          }
          if (editStaticLink.id) {
            if (Object.keys(payload).length > 0) {
              handleSubmit(payload, useObjectID ? editStaticLink.id : editStaticLink.staticLinkUUID);
            }
          } else {
            handleSubmit(payload);
          }
        }
      });
    }
  });

  /// Calls confirmpopup
  const deleteButtonClicked = (event: any): void => {
    setShowConfirmPopup(true);
    setConfirmPopupTarget(event.target);
  };

  /// Handle hide dialog event.
  const handleHideDialog = (): void => {
    setIsDialogVisible(false);
    setEditStaticLink({});
    setCroppedStaticLinkIcon(null);
    formik.resetForm();
    staticLinkIcon.current = null;
  };

  const staticLinkForm: JSX.Element = (
    <DialogWrapper
      isDialogVisible={isDialogVisible}
      onHide={() => handleHideDialog()}
      headerTitle={
        editStaticLink.id
          ? translate('awc:/.staticLinkFieldset.edit_header')
          : translate('awc:/.staticLinkFieldset.add_header')
      }>
      <FormWrapper
        onSubmit={formik.handleSubmit}
        controlButtonLabel={Object.keys(editStaticLink).length > 0}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) => deleteButtonClicked(event)}
        handleSubmitButton={() => {
          if (!staticLinkIcon.current) {
            staticLinkIcon.current = displayCroppedStaticLinkIcon && displayCroppedStaticLinkIcon.name; // Set Current icon value.
          }
          formik.handleSubmit();
        }}>
        <Row>
          <Col xs={12}>
            <div className="bg-dark px-4 py-2 primary-border-radius">
              <InstaImageUpload
                showLegend={false}
                altText={translate('awc:/.staticLinkFieldset.icon_alt')}
                imageUrl={editStaticLink.icon || ''}
                showDelete={true}
                deleteHandler={deleteStaticLinkIconHandler}
                imgContainerClass={'px-3'}
                onChange={uploadStaticLinkIconHandler}
                noImageContainer={<i className="pi pi-image p-3 text-white" />}
                noImageText={translate('awc:/.image.no_image')}
                errorHandler={errorHandler}
                imgClass={'imageUrl rounded'}
                croppedImage={displayCroppedStaticLinkIcon}
                isPlainBtn={true}
                plainBtnLabel={translate('awc:/.staticLinkFieldset.choose_icon')}
                uploadButtonClass={'ml-0 col-md-12'}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <label htmlFor="name" className="required">{translate('awc:/.staticLinkFieldset.name')}</label>
            <InputText
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.name && formik.errors.name ? 'p-invalid' : ''}
            />
            {formik.touched.name && formik.errors.name ? (
              <small className="p-invalid">{formik.errors.name}</small>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <label htmlFor="link" className="required">{translate('awc:/.staticLinkFieldset.url')}</label>
            <InputText
              id="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.link && formik.errors.link ? 'p-invalid' : ''}
            />
            {formik.touched.link && formik.errors.link ? (
              <small className="p-invalid">{formik.errors.link}</small>
            ) : null}
          </Col>
        </Row>
      </FormWrapper>
    </DialogWrapper>
  );

  return (
    <>
      {getDataTable()}
      {staticLinkForm}

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmPopup}
        title={transalateHeaderDeletionItemTitle}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={transalateNoOptionDeletionItem}
        acceptLabel={transalateYesOptionDeletionItem}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    </>
  );
};
export default StaticLinkForm;