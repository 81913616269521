/*
 * MenuWrapper.tsx (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file MenuWrapper.tsx
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { Dispatch, MutableRefObject, useEffect, useState } from 'react';
import { useAppSelector } from '../../Hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import SidebarPage, {
  IMenuItem
} from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import { menuItemClick } from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { ISettings } from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import { Helmet } from 'react-helmet';
import { getUserApplicationsAction } from '../../Store/Settings';
import { useDispatch } from 'react-redux';
import {
  linkedApplicationsSidebarItems,
  staticLinkSidebarItems
} from '@abstract/abstractwebcommon-client/Sidebar/sharedMenus';
import {
  getAllStaticLinksAction,
  IStaticLinksState
} from '../../Store/StaticLinks';
import { userAuthenticationVerificationUrlPath } from '@abstract/abstractwebcommon-client/Constants';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {
  SharedMainRouteName,
  SharedCommomRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import i18n from '../../Services/I18n';
import { getUserProfileInformationAction } from '../../Store/Users';
import { IUserProfile } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

/**
 * Interface for MenuWrapper properties.
 */
interface IMenuWrapperProperties {
  menuItems: IMenuItem[] /**< Menu items */;
  menu: MutableRefObject<any> /**< Menu */;
  fnLogout: () => void /**< Logout Handler */;
  setMenuItems: React.Dispatch<
    React.SetStateAction<IMenuItem[]>
  > /**< SetMenuItems. */;
  history: any /**< History. */;
  isAdmin: boolean /**< Admin or not. */;
  themeMode: string /**< theme mode to use */;
  didChangeTheme: (theme: ThemeMode) => void /**< change theme function */;
  logoURL: string /**< Logo URL */;
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (
    language: string
  ) => void /**< change language function */;
}

const MenuWrapper: React.FC<any> = (properties: IMenuWrapperProperties) => {
  const dispatch: Dispatch<any> = useDispatch();
  const user: IAuth = useAppSelector((state) => state.userAuth);
  const userProfileInformation: IUserProfile = useAppSelector(
    (state) => state.users.userProfileInformation
  );
  const settings: ISettings = useAppSelector((state) => state.settings);
  const staticLinksState: IStaticLinksState = useAppSelector(
    (state) => state.staticLinks
  ); /**< Get StaticLink State. */
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [extraMenuStyles, setExtraMenuStyles] = useState<string>(
    ''
  ); /**< Menu styles. */
  const token: string | null = LocalStorage.getXAuthToken(); /**< User token. */
  const userFrontendBaseURL: string =
    settings &&
    settings.setting.userFrontendBaseURL; /**< User Frontend BaseURL. */
  const applicationTitle: string =
    (settings && settings.setting.applicationTitle) ||
    t('sidebar_title'); /**< Application Title. */

  const noImageContainer = (
    <h4 className="text-light">{applicationTitle}</h4>
  ); /**< Sidebar Header. */

  const [
    menuItemsToSendThroughSidebar,
    setMenuItemsToSendThroughSidebar
  ] = useState<IMenuItem[]>(properties.menuItems);

  /// Handle user menu items populated
  const handleUserMenuItemsPopulated = (styles: string): void => {
    setExtraMenuStyles(styles);
  };

  useEffect(() => {
    if (properties.menuItems) {
      const splitParameter: string = properties.isAdmin
        ? SharedMainRouteName.adminRoute
        : SharedMainRouteName.clientRoute;
      const id: string = pathname.split(splitParameter)[1]
        ? pathname.split(splitParameter)[1].split('/')[0]
        : 'transactions';
      menuItemClick(
        properties.menu,
        properties.setMenuItems,
        properties.history,
        pathname,
        id === '' ? 'transactions' : id,
        false
      );
    }

    dispatch(getUserApplicationsAction());
    if (
      LocalStorage.getXAuthToken() &&
      staticLinksState.allStaticLinks.length <= 0
    ) {
      dispatch(getAllStaticLinksAction()); /**< Get StaticLinks. */
    }
  }, []);

  /// Added static links and inked applications to menu items of Sidebar component
  useEffect(() => {
    setMenuItemsToSendThroughSidebar(() => {
      return [
        ...properties.menuItems,
        ...linkedApplicationsSidebarItems(
          settings.userApplications,
          `${userFrontendBaseURL}${userAuthenticationVerificationUrlPath}`
        ),
        ...staticLinkSidebarItems([...staticLinksState.allStaticLinks])
      ];
    });
  }, [
    staticLinksState.allStaticLinks,
    settings.userApplications,
    properties.menuItems
  ]);

  /// Checks if the user is authenticated, else redirect to /validate
  useEffect(() => {
    if (!LocalStorage.getXAuthToken() && !user.isAuthenticated) {
      window.location.href = SharedCommomRouteName.validateRoute;
    }
  }, [user]);

  useEffect(() => {
    if (LocalStorage.getXAuthToken() && user.isAuthenticated) {
      dispatch(
        getUserProfileInformationAction()
      ); /**< Get user profile card information. */
    }
  }, [LocalStorage.getXAuthToken()]);

  /// Logout all applications
  const logoutAllApplications = () => {
    const userApplications: IApplications[] = settings.userApplications
      .filter(
        (eachApplication: IApplications) =>
          eachApplication.isUserPermissionGranted === true &&
          eachApplication.verificationURL !== userFrontendBaseURL
      )
      .map((eachApplication) => {
        return {
          applicationUUID: eachApplication.applicationUUID,
          verificationURL: eachApplication.verificationURL
        };
      }); /**< UserApplications. */

    const parameters: URLSearchParams = new URLSearchParams({
      logoutAll: 'true',
      userApplications: JSON.stringify(userApplications)
    }); /**< Query parameters */
    window.location.href = `${
      SharedCommomRouteName.validateRoute
    }?${parameters.toString()}`;
  };

  return (
    <>
      {user.isAuthenticated && (
        <>
          <Helmet>
            <style type="text/css">{`
          ${extraMenuStyles}
        `}</style>
          </Helmet>

          <SidebarPage
            menu={properties.menu}
            menuItems={menuItemsToSendThroughSidebar}
            logoAlt={t('I18N.menu.logo_alt')}
            logoNoImageContainer={noImageContainer}
            logoUrl={properties.logoURL}
            onAccount={() => {
              // Redirect to AbstractUser Profile page.
              if (token) {
                window.open(
                  `${userFrontendBaseURL}${
                    SharedCommomRouteName.loginRoute
                  }?token=${token}&themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}`,
                  '_blank'
                );
              }
            }}
            onLogout={() => properties.fnLogout()}
            userInformation={userProfileInformation ?? {}}
            handleUserMenuItemsPopulated={handleUserMenuItemsPopulated}
            didChangeTheme={properties.didChangeTheme}
            themeMode={properties.themeMode}
            logoutAllText={t('user.menu.logout_all')}
            onLogoutAll={logoutAllApplications}
            languageSettingsMode={properties.languageSettingsMode}
            didChangeLanguage={properties.didChangeLanguage}
            i18nService={i18n}
          />
        </>
      )}
    </>
  );
};

export default React.memo(MenuWrapper);
