/*
 * TermsAndCondition.ts (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file TermsAndCondition.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {showSuccessToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {IAPIEntityResponse} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  ITermsAndCondition,
  ITermsAndConditionTableProperties
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import {IReducerAction} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  createOrUpdateTermsAndCondition,
  deleteTermsAndCondition,
  getTermsAndCondition
} from '../Services/termsAndCondition';
import {ITablePayload} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export const TERMSANDCONDITION_FEATURE_KEY = 'termsAndCondition';

export class TermsAndConditionState {
  termsAndCondition: ITermsAndCondition[];
  isTermsAndConditionLoading: boolean;
  isTermsAndConditionCreated: boolean;
  isTermsAndConditionUpdated: boolean;
  isTermsAndConditionDeleted: boolean;
  totalRecords: number;

  constructor(
    isTermsAndConditionLoading: boolean = false,
    isTermsAndConditionCreated: boolean = false,
    isTermsAndConditionUpdated: boolean = false,
    isTermsAndConditionDeleted: boolean = false,
    termsAndCondition: ITermsAndCondition[] = [],
    totalRecords: number = 0
  ) {
    this.termsAndCondition = termsAndCondition;
    this.isTermsAndConditionLoading = isTermsAndConditionLoading;
    this.isTermsAndConditionCreated = isTermsAndConditionCreated;
    this.isTermsAndConditionUpdated = isTermsAndConditionUpdated;
    this.isTermsAndConditionDeleted = isTermsAndConditionDeleted;
    this.totalRecords = totalRecords;
  }
}

/// Initial TermsAndCondition state
const INITIAL_STATE: TermsAndConditionState = Object.assign(
  {},
  new TermsAndConditionState()
);

export const termsAndCondionSlice = createSlice({
  name: TERMSANDCONDITION_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    createTermsAndConditionRequest(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = true;
    },
    createTermsAndConditionSuccess(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = false;
      state.isTermsAndConditionCreated = true;
    },
    createTermsAndConditionFailure(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = false;
    },
    getTermsAndConditionRequest(state: TermsAndConditionState) {
      state.isTermsAndConditionCreated = false;
      state.isTermsAndConditionUpdated = false;
      state.isTermsAndConditionLoading = true;
      state.isTermsAndConditionDeleted = false;
    },
    getTermsAndConditionSuccess(
      state: TermsAndConditionState,
      action: IReducerAction<ITermsAndConditionTableProperties>
    ) {
      state.isTermsAndConditionLoading = false;
      state.termsAndCondition = action.payload.data;
      state.totalRecords = action.payload.totalRecords ?? 0;
    },
    getTermsAndConditionFailure(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = false;
    },
    deleteTermsAndConditionRequest(state: TermsAndConditionState) {
      state.isTermsAndConditionCreated = false;
      state.isTermsAndConditionUpdated = false;
      state.isTermsAndConditionDeleted = false;
      state.isTermsAndConditionLoading = true;
      state.termsAndCondition = [];
    },
    deleteTermsAndConditionSuccess(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = false;
      state.isTermsAndConditionDeleted = true;
    },
    deleteTermsAndConditionFailure(state: TermsAndConditionState) {
      state.isTermsAndConditionLoading = false;
    }
  },
  extraReducers: {}
});

export const termsAndCondionReducer = termsAndCondionSlice.reducer;
export const termsAndCondionActions = termsAndCondionSlice.actions;

/**
 * Get termsAndCondion action.
 * @param payload
 */
export const getTermsAndCondionAction = createAsyncThunk(
  'termsAndCondion/get',
  async (payload: ITablePayload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const getTermsAndConditionRequest: any =
      termsAndCondionActions.getTermsAndConditionRequest;
    const getTermsAndConditionSuccess: any =
      termsAndCondionActions.getTermsAndConditionSuccess;
    const getTermsAndConditionFailure: any =
      termsAndCondionActions.getTermsAndConditionFailure;

    try {
      dispatch(getTermsAndConditionRequest());
      const response: IAPIEntityResponse<ITermsAndCondition[]> = await asyncErrorHandler(getTermsAndCondition(
          payload
      ));
      if (response.error) {
        handleError({
          message:
            response.error.message || 'Failed to fetch Terms and condition'
        });
        dispatch(getTermsAndConditionFailure());
        return;
      } else {
        dispatch(getTermsAndConditionSuccess(response));
        return;
      }
    } catch (exception) {
      dispatch(getTermsAndConditionFailure());
      handleError({ message: exception.message });
      return;
    }
  }
);

/**
 * Create terms and condition action.
 * @param payload
 */
export const createOrUpdateTermsAndCondionAction = createAsyncThunk(
  'termsAndCondion/createOrUpdate',
  async (payload: ITermsAndCondition, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const createTermsAndConditionRequest: any =
      termsAndCondionActions.createTermsAndConditionRequest;
    const createTermsAndConditionSuccess: any =
      termsAndCondionActions.createTermsAndConditionSuccess;
    const createTermsAndConditionFailure: any =
      termsAndCondionActions.createTermsAndConditionFailure;
    try {
      dispatch(createTermsAndConditionRequest());
      const result: IAPIEntityResponse<any> = await asyncErrorHandler(createOrUpdateTermsAndCondition(
          payload
      ));
      if (result.error) {
        dispatch(createTermsAndConditionFailure());
        handleError({
          message: result.error.message || 'Failed to fetch Terms and condition'
        });
        return;
      }

      const tablePayload: ITablePayload = {
        limit: payload.limit,
        skip: payload.skip,
        searchTerm: payload.searchTerm,
        sort: payload.sort
      };

      showSuccessToast(result.message ?? '');
      dispatch(createTermsAndConditionSuccess());
      dispatch(getTermsAndCondionAction(tablePayload));
    } catch (exception) {
      dispatch(createTermsAndConditionFailure());
      handleError({ message: exception.message });
      return;
    }
  }
);

/**
 * Delete static link(s) action
 */
export const deleteTermsAndCondionAction = createAsyncThunk(
  'termsAndCondion/delete',
  async (payload: ITermsAndConditionTableProperties, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const deleteTermsAndConditionRequest: any =
      termsAndCondionActions.deleteTermsAndConditionRequest;
    const deleteTermsAndConditionSuccess: any =
      termsAndCondionActions.deleteTermsAndConditionSuccess;
    const deleteTermsAndConditionFailure: any =
      termsAndCondionActions.deleteTermsAndConditionFailure;

    try {
      dispatch(deleteTermsAndConditionRequest());
      const paylodToSend: ITermsAndCondition[] = payload.data;
      const result = await asyncErrorHandler(deleteTermsAndCondition(paylodToSend));
      if (result.error) {
        handleError({
          message:
            result.error.message || 'Failed to delete terms and condition'
        });
        dispatch(deleteTermsAndConditionFailure());
        return;
      } else {
        const tablePayload: ITablePayload = {
          limit: payload.limit,
          skip: payload.skip,
          searchTerm: payload.searchTerm,
          sort: payload.sort
        };

        showSuccessToast(result.message ?? '');
        dispatch(deleteTermsAndConditionSuccess());
        dispatch(getTermsAndCondionAction(tablePayload));
      }
    } catch (exception) {
      handleError({ message: exception.message });
      dispatch(deleteTermsAndConditionFailure());
      return;
    }
  }
);
