/*
 * ProductCheckoutOptionsForm.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ProductCheckoutOptionsForm.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React from 'react';
import { Panel } from 'primereact/panel';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import Button from 'react-bootstrap/Button';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * @interface IProductCheckoutOptionsForm
 */
interface IProductCheckoutOptionsForm {
  values: IProductTranslation[] | undefined /**< Product checkout options */;
  isLoading: boolean /**< Loading state */;
  errors: any /**< Options errors */;
  handleChange: (event: any) => void /**< Handler for change event */;
  handleBlur: (event: any) => void /**< Handler for blur event */;
  touched: any /**< Options touched */;
  setFieldValue: any;
  selectedLanguage: string /**< Selected language */;
  selectedLanguageTranslationIndex: number /**< Selected language translation data index */;
  englishTranslationIndex: number /**< English translation data index */;
}

const ProductCheckoutOptionsForm = (
  properties: IProductCheckoutOptionsForm
): JSX.Element => {
  const translation = useTranslation().t;
  const selectedLanguageTranslationIndex: number =
    properties.selectedLanguageTranslationIndex;
  const englishTranslationIndex: number = properties.englishTranslationIndex;

  const checkoutOptions: string[] =
    ((properties.values &&
      properties.values.length &&
      properties.values[englishTranslationIndex] &&
      properties.values[englishTranslationIndex]
        .checkoutOptionsDescription) as string[]) ?? [];

  const renderOptionList = (formikArray: FieldArrayRenderProps) =>
    checkoutOptions &&
    checkoutOptions.map((checkoutOption: string, index: number) => (
      <Row key={index}>
        <div className="w-100 d-flex mt-2">
          <InstaTextArea
            sizing={{ sm: 9, md: 10 }}
            label={translation('product.checkout-options.description')}
            name={`translationData.${selectedLanguageTranslationIndex}.checkoutOptionsDescription.${index}`}
            id={`translationData.${selectedLanguageTranslationIndex}.checkoutOptionsDescription.${index}`}
            isLoading={properties.isLoading}
            onChange={properties.handleChange}
            onBlur={properties.handleBlur}
            value={
              (properties.values &&
                properties.values.length &&
                properties.values[selectedLanguageTranslationIndex] &&
                properties.values[selectedLanguageTranslationIndex]
                  .checkoutOptionsDescription?.[index]) ??
              ''
            }
            touched={
              properties.touched &&
              properties.touched.length &&
              properties.touched[selectedLanguageTranslationIndex] &&
              properties.touched[selectedLanguageTranslationIndex]
                .checkoutOptionsDescription?.[index]
            }
            errors={
              properties.errors &&
              properties.errors.length &&
              properties.errors[selectedLanguageTranslationIndex] &&
              properties.errors[selectedLanguageTranslationIndex]
                .checkoutOptionsDescription?.[index]
            }
            placeholder={translation(
              'product.checkout-options.description_placeholder'
            )}
            labelClassName={
              properties.selectedLanguage === LanguageSettingsMode.english
                ? 'required'
                : ''
            }
            rows={1}
          />
          <Col>
            <Button
              className="p-button-raised p-button-primary float-right"
              onClick={() => {
                formikArray.remove(index);
                // Remove corresponding translations
                if (properties.values && properties.values.length) {
                  for (
                    let translationIndex = 0;
                    translationIndex < properties.values.length;
                    translationIndex++
                  ) {
                    const newArray = properties.values[
                      translationIndex
                    ].checkoutOptionsDescription?.filter(
                      (option: string, optionIndex: number) =>
                        optionIndex !== index
                    );
                    properties.setFieldValue(
                      `translationData.${translationIndex}.checkoutOptionsDescription`,
                      newArray
                    );
                  }
                }
              }}
            >
              <i className="bi bi-dash btn-icon mr-0"></i>
            </Button>
          </Col>
        </div>
      </Row>
    ));

  return (
    <Panel
      header={translation('product.checkout-options.header')}
      className="p-0 additional-options-panel"
    >
      <FieldArray
        name={`translationData.${selectedLanguageTranslationIndex}.checkoutOptionsDescription`}
        render={(formikArray) => (
          <>
            {renderOptionList(formikArray)}
            <Row className={!properties.errors ? 'd-none' : 'text-center'}>
              <Col sm={12}>
                {properties.errors &&
                  properties.errors.label &&
                  properties.errors.field &&
                  translation(properties.errors.label, {
                    field: properties.errors.field
                  })}
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <ActionButton
                onClick={() => {
                  formikArray.push('');
                }}
              />
            </Row>
          </>
        )}
      />
    </Panel>
  );
};

export default React.memo(ProductCheckoutOptionsForm);
