/*
 * CssEditorPage.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file CssEditorPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, {Dispatch, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../../../Hooks';
import CSSEditorComponent from '@abstract/abstractwebcommon-client/TemplateEditor/CSSEditorComponent';
import {TFunction} from 'i18next';
import {getCustomCss, updateCustomCss} from '../../../Store/Templates';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const CssEditorPage = (): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const loading: boolean = useAppSelector(
    (state) => state.templates.cssIsFetching
  ); /**< CSS Template fetching. */
  const cssTemplate: string = useAppSelector(
    (state) => state.templates.css
  ); /**< CSS Template. */
  const codeMirrorOptions = {
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  }; /**< Code Mirror Options. */

  // Handle Css Template Update event
  const handleCssChange = (updatedCss: string) => {
    dispatch(updateCustomCss(updatedCss));
  };

  // Fetch Css Template
  useEffect(() => {
    const fetchCSSTemplate = async () => {
        await asyncErrorHandler(dispatch(getCustomCss()));
    };

    fetchCSSTemplate();
  }, [getCustomCss]);

  return (
    <>
      <CSSEditorComponent
        title={translation('admin.menu.css')}
        isLoading={loading}
        codeMirrorOptions={{
          mode: 'css',
          ...codeMirrorOptions
        }}
        submitHandler={handleCssChange}
        submitLabel={translation('template.save')}
        submitClasses={'p-button p-button-primary'}
        confirmationMessage={translation('confirm_messages.save_record')}
        confirmationNo={translation('confirm_messages.no')}
        confirmationYes={translation('confirm_messages.yes')}
        CSSTemplate={cssTemplate ? cssTemplate : ''}
      />
    </>
  );
};

export default CssEditorPage;
