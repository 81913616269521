/*
 * TransactionTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file TransactionTable.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import TransactionTableExpansion from './TransactionTableExpansion';
import {
  getTransactionList,
  licenseCreated,
  regenerateInvoice,
  updateBillingDetails
} from '../../../Store/Transactions';
import Price from '../../SubComponents/Price';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { TransactionStatus } from '@abstract/abstractwebcommon-shared/constants/ecommerce/TransactionConstants';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/EnvironmentVariables';
import { getApplicationEnvironmentVariablesAction } from 'src/Store/ShopSettings';
import { EnvironmentType } from '@abstract/abstractwebcommon-shared/enum/environmentType';
import { Button } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isRole } from '../../../Utils/Formatter';
import { fetchAllProducts } from '../../../Store/Products';

/**
 * Interface for TransactionTable properties.
 */
interface ITransactionTableProperties {
  transactions: any;
  loading: boolean;
  handlePageUpdate: any;
  handleFilterUpdate: any;
  handleSortUpdate: any;
  createNewTransaction: any;
  skip: number;
  limit: number;
  count: number;
  sort: any;
  searchTerm: string;
  handleUpdateTransaction: (
    transactionID: string
  ) => void /* Function to update a transaction based on its ID. */;
  filter: Record<string, any> /**< Filter object */;
  handleProductFilterUpdate: (
    filter: Record<string, any>
  ) => void /**< Handle product filter update event */;
}

const TransactionTable = ({
  transactions,
  loading,
  handlePageUpdate,
  handleFilterUpdate,
  handleSortUpdate,
  createNewTransaction,
  skip,
  limit,
  count,
  sort,
  searchTerm,
  handleUpdateTransaction,
  filter,
  handleProductFilterUpdate
}: ITransactionTableProperties) => {
  const transactionState = useSelector((state) => state.transactions);
  const environmentVariables: IPublicEnvironmentVariables = useSelector(
    (state) => state.shopSettings.environmentVariables
  ); /**< Get environmentVariables to enable/disabled the update transaction button based on the environment type. */

  const [
    showUpdateTransactionConfirmationPopup,
    setShowUpdateTransactionConfirmationPopup
  ] = useState<boolean>(false); /**< Show Confirmation Popup. */
  const [
    confirmUpdateTransactionPopupTarget,
    setConfirmUpdateTransactionPopupTarget
  ] = useState<any>(null); /**< ConfirmationPopup Target. */
  const [transactionToUpdate, setTransactionToUpdate] = useState<any>(
    null
  ); /**< Transaction to update using the function handleUpdateTransaction. */

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [licenseUpdateLoading, setLicenseUpdateLoading] = useState(false);
  const dispatch = useDispatch();
  const regenerateInvoiceHandler = async (rowData) => {
    await asyncErrorHandler(
      dispatch(regenerateInvoice(rowData.customer.email, rowData._id))
    );
  };
  const translation = useTranslation().t;
  const productsState = useSelector((state) => state.products);
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>(
    []
  ); /**< Selected Products. */
  const shopSettings = useSelector((state) => state.shopSettings.list);
  const roles: string[] = JSON.parse(LocalStorage.getRole()); /**< User role. */
  const adminRoleUUID: string =
    shopSettings?.adminRoleUUID; /**< Admin role UUID. */
  const isAdmin: boolean = isRole([adminRoleUUID], roles);

  const markLicenseCreatedHandler = async (rowData) => {
    setLicenseUpdateLoading(true);
    await asyncErrorHandler(dispatch(licenseCreated(rowData._id)));
    setLicenseUpdateLoading(false);
    await asyncErrorHandler(
      dispatch(getTransactionList(skip, limit, searchTerm, sort))
    );
  };

  /// Handler for updating billing details
  const billingDetailsUpdateHandler = async (
    id: string,
    payload: Record<string, any>
  ) => {
    await asyncErrorHandler(dispatch(updateBillingDetails(id, payload)));
    await asyncErrorHandler(
      dispatch(getTransactionList(skip, limit, searchTerm, sort))
    );
  };

  const sortField = sort && sort.sortField;
  const sortOrder = sort && sort.sortOrder;

  // Triggers on product multiselect change - Sets the value to the filter
  const onProductChange = (event: any): void => {
    const products: IProduct[] = event.value; /**< Selected Products. */
    setSelectedProducts(products);
    const filteredValue: string[] = products.map(
      (product: IProduct) => product.name
    ); /**< filteredValue */

    let updatedFilter: Record<string, any> = {};

    if (filteredValue?.length) {
      // If values exist then update the filter object with product key
      updatedFilter = {
        ...filter,
        'products.productName': { value: filteredValue }
      };
    } else {
      // If not omit product key from object
      updatedFilter = { ...filter };
      if (updatedFilter['products.productName']) {
        delete updatedFilter['products.productName'];
      }
    }

    handleProductFilterUpdate(updatedFilter);
  };

  // Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton onClick={createNewTransaction} />
      </div>
      {isAdmin ? (
        <div className="headerTableContainer header-search-filter">
          <SearchBar onSearchTermChanged={(data) => handleFilterUpdate(data)} />
          <MultiSelect
            optionLabel="name"
            dataKey="_id"
            name="product"
            inputId="productFilter"
            value={selectedProducts}
            options={productsState.allProducts}
            onChange={onProductChange}
            placeholder={translation('customersTable.user.products')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
          />
        </div>
      ) : (
        <SearchBar onSearchTermChanged={(data) => handleFilterUpdate(data)} />
      )}
    </div>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  // Control when the function to update the transaction by its ID can be used.
  const isUpdateTransactionFunctionUsable = (
    transactionDetails: any
  ): boolean => {
    return (
      transactionDetails.status !== TransactionStatus.SubmittedForSettlement &&
      transactionDetails.status !== TransactionStatus.SettlementPending &&
      transactionDetails.status !== TransactionStatus.Settling
    );
  };

  // Confirm update transaction confirmation popup.
  const onAcceptUpdateTransactionPopup = () => {
    handleUpdateTransaction(transactionToUpdate._id);
    setShowUpdateTransactionConfirmationPopup(false);
  };

  // Hide update transaction confirmation popup.
  const onRejectUpdateTransactionPopup = () => {
    setShowUpdateTransactionConfirmationPopup(false);
  };

  useEffect(() => {
    dispatch(
      getApplicationEnvironmentVariablesAction()
    ); /**< To fetch environment variables to enable/disabled the update transaction button based on the environment type. */

    if (isAdmin) {
      dispatch(fetchAllProducts()); /**< To fetch all products. */
    }
  }, []);

  return (
    <>
      <BaseDatatable
        value={transactions}
        isLoading={!transactions}
        expandedRows={expandedRows}
        header={header}
        first={skip}
        rows={limit}
        totalRecords={count}
        onPage={handlePageUpdate}
        onFilter={handleFilterUpdate}
        onSort={handleSortUpdate}
        sortField={sortField}
        sortOrder={sortOrder}
        rowsPerPageOptions={[10, 20, 50]}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        dataKey="_id"
        rowExpansionTemplate={(e) => (
          <TransactionTableExpansion
            rowData={e}
            regenerateInvoiceHandler={regenerateInvoiceHandler}
            markLicenseCreatedHandler={() => markLicenseCreatedHandler(e)}
            licenseUpdateLoading={licenseUpdateLoading}
            isAdminView={true}
            billingDetailsUpdateHandler={billingDetailsUpdateHandler}
          />
        )}
        responsive /**< Datatable responsive layout.*/
      >
        {/* Used to prevent row selection on row click */}
        <Column selectionMode="multiple" className="d-none" />
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column
          field="customer.customerName"
          header={translation('transaction.table.customer')}
          sortable
          body={(entry) => {
            const customerDisplayName = entry.customer
              ? `${entry.customer.fullName}`
              : '';
            const customerEmail: string = entry.customer
              ? entry.customer.email
              : '';
            return (
              <DatatableColumn
                title={translation('transaction.table.customer')}
                data={
                  <>
                    <span>{customerDisplayName}</span>
                    <br></br>
                    <span>{customerEmail}</span>
                  </>
                }
              />
            );
          }}
          className="col-width-250"
          headerClassName="col-width-250"
        />
        <Column
          field="usernamePassed"
          header={translation('transaction.table.username')}
          className="custom-userName-width-column"
          headerClassName="custom-userName-width-column"
          sortable
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.username')}
              data={transaction.usernamePassed}
            />
          )}
        />
        <Column
          sortable
          field="status"
          header={translation('transaction.table.status')}
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.status')}
              data={transaction.status}
            />
          )}
          className="d-table-cell d-sm-none d-xl-table-cell col-width-250"
          headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-250"
        />
        <Column
          sortable
          header={translation('transaction.table.price')}
          field="priceCalculation.priceNet"
          className="p-col-price d-table-cell d-sm-none d-md-table-cell"
          headerClassName="p-col-price d-table-cell d-sm-none d-md-table-cell"
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.price')}
              data={<Price amount={transaction.priceCalculation.priceNet} />}
            />
          )}
        />
        <Column
          sortable
          field="products.productName"
          header={translation('transaction.table.product')}
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.product')}
              data={transaction.products.map((product, index) => (
                <>
                  <span key={index}>{product?.productName}</span>
                  <br></br>
                </>
              ))}
            />
          )}
          className="col-width-250"
          headerClassName="col-width-250"
        />
        <Column
          field="invoiceNo"
          header={translation('transaction.table.invoice')}
          sortable
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.invoice')}
              data={
                <a
                  key={transaction._id}
                  href={transaction.invoiceLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-transparent"
                >
                  {transaction.invoiceNo}
                </a>
              }
            />
          )}
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
        />
        <Column
          field="transactionFulfilled"
          header={translation('transaction.table.license')}
          sortable
          className="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.license')}
              data={
                <ShowCheckOrUncheckIcon
                  value={transaction.transactionFulfilled}
                />
              }
            />
          )}
        />
        <Column
          field="failureReasonID"
          header={translation('transaction.table.recordCreatedSuccessfully')}
          sortable
          className="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell custom-header-min-width-allowed"
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.recordCreatedSuccessfully')}
              data={
                <ShowCheckOrUncheckIcon
                  value={isStringEmptyOrNullOrUndefined(
                    transaction.failureReasonID
                  )}
                />
              }
            />
          )}
        />
        <Column
          field="createdAt"
          header={translation('transaction.table.created')}
          sortable
          className="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-3xl-table-cell createdDateCol"
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.created')}
              data={
                transaction.createdAt ? formatDate(transaction.createdAt) : ''
              }
            />
          )}
        />
        <Column
          field="updatedAt"
          header={translation('transaction.table.updated')}
          sortable
          className="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
          headerClassName="d-table-cell d-sm-none d-3xl-table-cell updatedDateCol"
          body={(transaction) => (
            <DatatableColumn
              title={translation('transaction.table.updated')}
              data={
                transaction.updatedAt ? formatDate(transaction.updatedAt) : ''
              }
            />
          )}
        />

        {/* We only need to display this action on the Local and Staging environments. We should hide it on the Production environment. */}
        {environmentVariables &&
          environmentVariables.ENVIRONMENT_TYPE !==
            EnvironmentType.Production && (
            <Column
              field="update"
              body={(rowData: any) => {
                return (
                  <Button
                    title="Define transaction status as Settled"
                    className="custom-action-column-action-position"
                    onClick={(event) => {
                      if (!isUpdateTransactionFunctionUsable(rowData)) {
                        setShowUpdateTransactionConfirmationPopup(true);
                        setConfirmUpdateTransactionPopupTarget(event.target);
                        setTransactionToUpdate(rowData);
                      }
                    }}
                    variant="outline"
                    disabled={
                      transactionState.isProcessingUpdateTransaction ||
                      isUpdateTransactionFunctionUsable(rowData)
                    }
                  >
                    <i
                      className={`bi bi-arrow-up-square editIcon fa-lg ${
                        transactionState.isProcessingUpdateTransaction ||
                        isUpdateTransactionFunctionUsable(rowData)
                          ? 'custom-disabled-icon'
                          : ''
                      }`}
                    ></i>
                  </Button>
                );
              }}
              className="col-width-45 p-0 absolute-position-responsive-screen"
            />
          )}
      </BaseDatatable>

      <ConfirmationPopup
        target={confirmUpdateTransactionPopupTarget}
        isShow={showUpdateTransactionConfirmationPopup}
        title={translation('transaction.table.confirmUpdateTransanctionPopup')}
        onAccept={onAcceptUpdateTransactionPopup}
        onReject={onRejectUpdateTransactionPopup}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    </>
  );
};

export default TransactionTable;
