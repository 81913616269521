/**
 * UserAuthenticationToken.ts (InstaLOD GmbH)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2022
 *
 * @file UserAuthenticationToken.ts
 * @author Etienne Daher
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */


import { IApplications } from '../interfaces/user/applications';
import { IAuthUser } from '../interfaces/user/authUser';
import { IRole } from '../interfaces/user/role';

/**
 * User input and return type for token
 */
export interface IUserInformationToken extends IAuthUser {
  applications?: string[]; /**< Applications (from token) */
  roles?: IMappedRole[]; /**< Roles (from token)*/
  role?: IMappedRole[]; /**< Role (from token)*/
  exp?: number; /**< Expires in */ 
  iat?: number; /**< Issued at */
  themeMode?: string /** Current application theme mode saved by User. */
  languageSettingsMode?: string /**< Current application language settings mode saved by User. */
  redirectApplicationURL?: string /**< Redirect application URL for the invitation link functionality. */
}

/**
 * Mapped roles 
 */
export interface IMappedRole {
  name: string; /**< Name of roles */
  roleUUID: string; /**< uuid of roles */
}

/**
 * Contains user information encoded in token
 */
export class UserAuthenticationToken {
  private _id: number; /**< User ID */
  private _userID: number; /**< User ID */
  private _timeSinceLastValidation: string; /**< Time since last token validation */
  private _lastName: string; /**< Last name */
  private _firstName: string; /**< First name */
  private _userUUID: string; /**< User UUID */
  private _applications: string[]; /**< Applications */
  private _roles: IMappedRole[]; /**< Roles */
  private _username: string; /**< Username */
  private _email: string; /**< Email */
  private _isActive: boolean; /**< Is Active */
  private _isVerified: boolean; /**< Is Verified */
  private _themeMode: string; /**< Current application theme mode saved by User */
  private _tokenBalance: number; /**< Current application theme mode saved by User */
  private _notifications?: {
  	total: number;	/**< The total number of notifications the user has, including read notifications. */
    unread: number;	/**< The number of unread notifications on the user account. */
  }; /**< The number of notifications on the user account. */
  private _languageSettingsMode: string /**< Current application language settings mode saved by User. */
  private _redirectApplicationURL: string /**< Redirect application URL for the invitation link functionality. */
  private _iat: number /**< When the token was issued, in seconds. */
  private _exp: number /**< Expiration token date, in seconds. */
  
  /**
   * Initialize a new user token instance
   * @param user User information
   * @param isUserFromToken True if user information are extracted from the token, false otherwise.
   */
  public constructor(user: IUserInformationToken, isUserFromToken: boolean = false) {
    
      if (user) {
        this.id = user.id;
        this.userID = user.id;
        this.timeSinceLastValidation = user.timeSinceLastValidation;
        this.lastName = user.lastName;
        this.firstName = user.firstName;
        this.userUUID = user.userUUID;
        this.username = user.username;
        this.email = user.email;
        this.exp = user.exp;
        this.iat = user.iat;
        this.isActive = user.isActive;
        this.isVerified = user.isVerified;
        this.themeMode = user.themeMode;
        this.tokenBalance = user.tokenBalance;
        this._notifications = user.notifications;
        this.languageSettingsMode = user.languageSettingsMode;

        if (user?.redirectApplicationURL) {
          this.redirectApplicationURL = user.redirectApplicationURL;
        }

        // From user backend, or mapped from token
        this.applications = isUserFromToken
        ? (user.applications as string[])
        : this.formatUserApplications(user.applications as IApplications[]);

        if (user.roles) {
          // From token
          this.roles = user.roles;
        } else if (user.role) {
          this.roles = isUserFromToken
            ? (user.role as IMappedRole[])
            : this.formatUserRoles(user.role as IRole[]);
        }
      } else {
        return null;
      }
  }

  /**
   * Gets user stored information
   * @returns Object containing user information
   */
  public getUserAuthenticationToken(): IUserInformationToken {
    const userPayload: IUserInformationToken = {
      id: this.id,
      timeSinceLastValidation: this.timeSinceLastValidation,
      lastName: this.lastName,
      firstName: this.firstName,
      userUUID: this.userUUID,
      applications: this.applications,
      roles: this.roles,
      username: this.username,
      email: this.email,
      isActive: this.isActive,
      isVerified: this.isVerified,
      themeMode: this.themeMode,
      tokenBalance: this.tokenBalance,
      notifications: this.notifications,
      languageSettingsMode: this.languageSettingsMode,
      redirectApplicationURL: this?.redirectApplicationURL,
    };

    return userPayload;
  }

  /**
   * Gets user stored information for the clients side.
   * Utilized to verify token expiration by the EXP field.
   * @returns Object containing user information
   */
  public getUserAuthenticationTokenClientSide(): IUserInformationToken {
    const userPayload: IUserInformationToken = {
      userUUID: this.userUUID,
      exp: this.exp
    };

    return userPayload;
  }

  /**
   * Format user roles
   * @param roles User roles
   * @returns Array of roleUUID
   */
  public formatUserRoles(roles: IRole[]): IMappedRole[] {
    let mappedRoles: IMappedRole[] = null;
    if(roles !== undefined) {
      mappedRoles = roles.map((eachRole: IRole) => {
        return {
          name: eachRole.name, 
          roleUUID: eachRole.roleUUID
        }
      });
    } 
    return mappedRoles;
  }

  /**
   * Format user applications
   * @param applications User applications
   * @returns Array of applicationUUID
   */
  public formatUserApplications(applications: IApplications[]): string[] {
    return applications && applications.map(
      (eachApplication: IApplications) => eachApplication.applicationUUID
    );
  }


  /**
   * Get user id
   */
  public get id(): number {
    return this._id;
  }

  /**
   * Set user id
   */
  public set id(value: number) {
    this._id = value;
  }

  /**
   * Get user id
   */
   public get userID(): number {
    return this._userID;
  }

  /**
   * Set user id
   */
  public set userID(value: number) {
    this._userID = value;
  }

  /**
   * Get time since last token validation
   */
  public get timeSinceLastValidation(): string {
    return this._timeSinceLastValidation;
  }

  /**
   * Set time since last token validation
   */
  public set timeSinceLastValidation(value: string) {
    this._timeSinceLastValidation = value;
  }

  /**
   * Get user last name
   */
  public get lastName(): string {
    return this._lastName;
  }

  /**
   * Set user last name
   */
  public set lastName(value: string) {
    this._lastName = value;
  }

  /**
   * Get user first name
   */
  public get firstName(): string {
    return this._firstName;
  }

  /**
   * Set user first name
   */
  public set firstName(value: string) {
    this._firstName = value;
  }

  /**
   * Get user uuid
   */
  public get userUUID(): string {
    return this._userUUID;
  }

  /**
   * Set user uuid
   */
  public set userUUID(value: string) {
    this._userUUID = value;
  }

  /**
   * Get user applications
   */
  public get applications(): string[] {
    return this._applications;
  }

  /**
   * Set user applications
   */
  public set applications(value: string[]) {
    this._applications = value;
  }

  /**
   * Get user roles
   */
  public get roles(): IMappedRole[] {
    return this._roles;
  }

  /**
   * Set user roles
   */
  public set roles(value: IMappedRole[]) {
    this._roles = value;
  }

  /**
   * Get username
   */
  public get username(): string {
    return this._username;
  }

  /**
   * Set username
   */
  public set username(value: string) {
    this._username = value;
  }
  /**
   * Get user email
   */
  public get email(): string {
    return this._email;
  }

  /**
   * Set user email
   */
  public set email(value: string) {
    this._email = value;
  }
  
  /**
   * Get expiration time
   */
   public get exp(): number {
    return this._exp;
  }

  /**
   * Set expiration time
   */
  public set exp(value: number) {
    this._exp = value;
  }

  /**
   * Get issued at
   */
   public get iat(): number {
    return this._iat;
  }

  /**
   * Set issued at
   */
  public set iat(value: number) {
    this._iat = value;
  }

   /**
   * Get is active
   */
    public get isActive() {
      return this._isActive;
    }

    /**
   * Set is active
   */
     public set isActive(value: boolean) {
      this._isActive = value;
    }

    /**
   * Get is verified
   */
    public get isVerified() {
      return this._isVerified;
    }

    /**
   * Set is verified
   */
     public set isVerified(value: boolean) {
      this._isVerified = value;
    }

    /**
   * Get is themeMode
   */
    public get themeMode() {
      return this._themeMode;
    }

    /**
   * Set is themeMode
   */
     public set themeMode(value: string) {
      this._themeMode = value;
    }

    /**
      * Get languageSettingsMode
      */
    public get languageSettingsMode() {
      return this._languageSettingsMode;
    }

    /**
      * Set languageSettingsMode
      */
    public set languageSettingsMode(value: string) {
      this._languageSettingsMode = value;
	}

  /**
     * Get redirectApplicationURL
     */
  public get redirectApplicationURL() {
    return this._redirectApplicationURL;
  }

  /**
    * Set redirectApplicationURL
    */
  public set redirectApplicationURL(value: string) {
    this._redirectApplicationURL = value;
  }

	public get tokenBalance(): number { return this._tokenBalance; }
	public set tokenBalance(value: number) { this._tokenBalance = value; }

	public get notifications(): { total: number, unread: number } { return this._notifications; }
}