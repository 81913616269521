/*
 * DiscountsTypes.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file DiscountsTypes.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

//Used Options Map. Used in the getUsedOption function below.
const usedOptionsMap: Map<boolean, string> = new Map([
  [true, 'Used'],
  [false, 'Not Used']
]);
const initializedUsedOptionsMap = new Map(usedOptionsMap);

/**
 * Get used option based on the received option
 *
 * @param option boolean
 * @returns Used or Not used string
 */
export const getUsedOption = (option: boolean): string | undefined =>
  initializedUsedOptionsMap.get(option);

//Discount Type Options Map. Used in the getDiscountType function below.
const discountTypeOptionsMap: Map<string, string> = new Map([
  ['oneTime', 'Single-Use'],
  ['multi', 'Multi-Use']
]);
const initializedDiscountTypeOptionsMap = new Map(discountTypeOptionsMap);

/**
 * Get discount type based on the received option
 *
 * @param option string
 * @returns One Time or Multi-Use string
 */
export const getDiscountType = (option: string): string | undefined =>
  initializedDiscountTypeOptionsMap.get(option);

//Discount Types Options. Used in the Dropdown component.
export const discountTypeOptionsDropdown = [...discountTypeOptionsMap].map(
  (entry) => {
    return { label: entry[1], value: entry[0] };
  }
);

//Used Options. Used in the Dropdown component.
export const usedOptionsDropdown = [...usedOptionsMap].map((entry) => {
  return { label: entry[1], value: entry[0] };
});
