/*
 * LogsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2020
 *
 * @file LogsPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  fetchLogs,
  exportLogsAction,
  resetState,
  deleteLogsAction
} from '../../../Store/Logs';
import { endOfDay, startOfDay } from 'date-fns';
import LogTable from '@abstract/abstractwebcommon-client/Logs/LogTable';
import {
  defaultTableLimit,
  defaultTableOptions
} from '@abstract/abstractwebcommon-client/Constants';
import { ManualDateRangePicker } from '@abstract/abstractwebcommon-client/DateRangePicker/dateRangePicker';
import { Button } from 'react-bootstrap';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Range as IDateRange } from 'react-date-range';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import {
  capitalizeFirstCharacterOnString,
  formatDate
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { isJson } from '@abstract/abstractwebcommon-shared/utils/json';
import ReactJson from 'react-json-view';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { MultiSelect } from 'primereact/multiselect';
import ConfirmationDialog, {
  ConfirmationDialogMode
} from '@abstract/abstractwebcommon-client/ConfirmationDialog/ConfirmationDialog';
import { PruneLogsTimeFrameEnum } from '@abstract/abstractwebcommon-shared/enum/log';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

/**
 * Defines the interface for the prune logs dropdown component.
 * @interface IPruneLogsDropdownOption
 */
interface IPruneLogsDropdownOption {
  name: string /**< Defines the label option to display in the dropdown component. */;
  code: string /**< Defines the selected value option of the dropdown component. */;
}

const LogsPage = () => {
  const dispatch = useDispatch();

  const logs = useSelector((state) => state.logs);
  const defaultDate: IDateRange[] = [
    {
      startDate: startOfDay(new Date(2020, 1, 1)),
      endDate: endOfDay(new Date()),
      key: 'selection'
    }
  ]; /**< Initial date. */
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange[]>(
    defaultDate
  ); /**< Selected Date Range. */
  const translation: TFunction = useTranslation().t;
  const node: any = useRef();
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'createdAt',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const sharedVariablesAcrossDispatchFunction: [
    Record<string, any>,
    Date,
    Date
  ] = [
    payload.sort,
    selectedDateRange[0].startDate as Date,
    selectedDateRange[0].endDate as Date
  ]; /**< Shared Variables. */
  const [expandedRows, setExpandedRows] = useState<any>(
    {}
  ); /**< Expand Rows. */
  const [selectedLogs, setSelectedLogs] = useState([]); /**< Selected Logs. */
  const themeMode: string =
    LocalStorage.getThemeMode() || ThemeMode.lightMode; /**< Theme */
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    []
  ); /**< Selected Categories */
  const [selectedLevels, setSelectedLevels] = useState<string[]>(
    []
  ); /**< Selected Levels */

  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal
  ] = useState<boolean>(false);
  const [durationOptions, setDurationOptions] = useState<
    IPruneLogsDropdownOption[]
  >([]);

  const defaultDeleteLogsOptions: IPruneLogsDropdownOption = {
    name: PruneLogsTimeFrameEnum.OneYear,
    code: Object.entries(PruneLogsTimeFrameEnum).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, enumValue]) => enumValue === PruneLogsTimeFrameEnum.OneYear
    )?.[0] as string
  };

  // State to manage the selected option for the prune logs functionality.
  const [selectedDeleteLogsOption, setSelectedDeleteLogsOption] = useState<
    IPruneLogsDropdownOption
  >(defaultDeleteLogsOptions);

  /// Log Category
  const logCategories: string[] = [
    translation('logs.categories.store'),
    translation('logs.categories.transaction'),
    translation('logs.categories.product'),
    translation('logs.categories.template'),
    translation('logs.categories.securityViolation'),
    translation('logs.categories.error'),
    translation('logs.categories.subscription'),
    translation('logs.categories.application'),
    translation('logs.categories.email'),
    translation('logs.categories.log')
  ];

  // Log Level
  const logLevels: string[] = [
    translation('logs.levels.info'),
    translation('logs.levels.danger')
  ];

  useEffect(() => {
    if (!logs.listIsFetching) {
      dispatch(
        fetchLogs(
          payload.skip,
          payload.limit,
          payload.searchTerm,
          ...sharedVariablesAcrossDispatchFunction,
          payload.filter
        )
      );
    }
  }, [dispatch, selectedDateRange]);

  // Listens for export success messages
  useEffect(() => {
    if (logs.isLogExported) {
      showToast({
        severity: 'success',
        summary: translation('logs.success'),
        detail: translation('logs.export_logs_success')
      });
    }
    dispatch(resetState()); /**< Reset isLogExported state. */
  }, [logs.isLogExported]);

  /// Handle Page update Event.
  const handlePageUpdate = (event: any): void => {
    const { first, rows } = event;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(
      fetchLogs(
        first,
        rows,
        payload.searchTerm,
        ...sharedVariablesAcrossDispatchFunction,
        payload.filter
      )
    );
  };

  /// Handle Sort Event.
  const handleSortUpdate = (event: any): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: {
        sortField: event.sortField,
        sortOrder: event.sortOrder
      }
    });
    setPayload(updatedPayload);
    dispatch(
      fetchLogs(
        payload.skip,
        payload.limit,
        payload.searchTerm,
        event,
        selectedDateRange[0].startDate,
        selectedDateRange[0].endDate,
        payload.filter
      )
    );
  };

  /// Handle Filter Event
  const handleFilterUpdate = (event: any): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      fetchLogs(
        payload.skip,
        payload.limit,
        event,
        ...sharedVariablesAcrossDispatchFunction,
        payload.filter
      )
    );
  };

  const onExportCSV = () => {
    dispatch(
      exportLogsAction(
        payload.skip,
        9999999, //numbers of documents - limit variable
        payload.searchTerm,
        ...sharedVariablesAcrossDispatchFunction
      )
    );
  };

  /**
   * Defines the method to execute when the prune logs confirmation modal is closed.
   * @returns void
   */
  const onRejectDeleteLogsConfirmation = (): void => {
    setShowDeleteConfirmationModal(false);
    setSelectedDeleteLogsOption(defaultDeleteLogsOptions);
  };

  /**
   * Defines the method to execute when the Delete button of the confirmation modal is clicked.
   * @returns void
   */
  const onAcceptDeleteLogsConfirmation = (): void => {
    dispatch(deleteLogsAction(selectedDeleteLogsOption.code));
  };

  // Listens for changes in the log 'isRequestingDeleteLogsEndpoint' and 'isDeleteLogsEndpointSuccess' Redux states.
  // We should close the delete confirmation modal in case the endpoint returns a success status code.
  useEffect(() => {
    if (
      !logs.isRequestingDeleteLogsEndpoint &&
      logs.isDeleteLogsEndpointSuccess
    ) {
      onRejectDeleteLogsConfirmation();
      dispatch(
        fetchLogs(
          payload.skip,
          payload.limit,
          payload.searchTerm,
          ...sharedVariablesAcrossDispatchFunction,
          payload.filter
        )
      );
    }
  }, [logs.isRequestingDeleteLogsEndpoint, logs.isDeleteLogsEndpointSuccess]);

  useEffect(() => {
    const durationOptionsValues: string[] = Object.keys(PruneLogsTimeFrameEnum);
    const durationOptionsLabels: string[] = Object.values(
      PruneLogsTimeFrameEnum
    );
    const mappedDurationOptions: IPruneLogsDropdownOption[] = durationOptionsLabels.map(
      (durationLabel: string, index: number) => {
        return { name: durationLabel, code: durationOptionsValues[index] };
      }
    );
    setDurationOptions(mappedDurationOptions);
  }, []);

  /// Triggers on category multiselect change - Sets the value to the filter
  const onCategoryChange = (event: any): void => {
    setSelectedCategories(event.value);
    let selectedValue: string[] = [...event.value]; /**< Selected categories */
    let updatedFilter: Record<string, any> = {};
    if (selectedValue && selectedValue.length) {
      // To add [SecurityViolation, security_violation] to category filter
      if (
        selectedValue.includes(translation('logs.categories.securityViolation'))
      ) {
        selectedValue.push('security_violation');
      }
      selectedValue = selectedValue.map((value) => value.toLowerCase());

      /// If values exist then update the filter object with category key
      updatedFilter = { ...payload.filter, category: { value: selectedValue } };
    } else {
      /// If not omit category key from object
      updatedFilter = { ...payload.filter };
      if (updatedFilter.category) {
        delete updatedFilter.category;
      }
    }
    const updatedPayload: ITablePayload = payload;
    updatedPayload.filter = updatedFilter;
    setPayload(updatedPayload);
    dispatch(
      fetchLogs(
        payload.skip,
        payload.limit,
        payload.searchTerm,
        ...sharedVariablesAcrossDispatchFunction,
        updatedPayload.filter
      )
    );
  };

  /// Triggers on security level multiselect change - Sets the value to the filter
  const onSecurityLevelChange = (event: any): void => {
    setSelectedLevels(event.value);
    let selectedValue: string[] = [...event.value]; /**< Selected levels */
    let updatedFilter: Record<string, any> = {};
    if (selectedValue && selectedValue.length) {
      /// Change Danger to Error log level to filter in log document
      const index: number = selectedValue.indexOf(
        translation('logs.levels.danger')
      );
      if (index !== -1) {
        selectedValue[index] = translation('logs.categories.error');
      }
      selectedValue = selectedValue.map((value) => value.toLowerCase());
      /// If values exist then update the filter object with security level key
      updatedFilter = { ...payload.filter, level: { value: selectedValue } };
    } else {
      /// If not omit security level key from object
      updatedFilter = { ...payload.filter };
      if (updatedFilter.level) {
        delete updatedFilter.level;
      }
    }
    const updatedPayload: ITablePayload = payload;
    updatedPayload.filter = updatedFilter;

    setPayload(updatedPayload);
    dispatch(
      fetchLogs(
        payload.skip,
        payload.limit,
        payload.searchTerm,
        ...sharedVariablesAcrossDispatchFunction,
        updatedPayload.filter
      )
    );
  };

  /// Initialize multiselect for log category
  const categoryFilter: JSX.Element = (
    <MultiSelect
      value={selectedCategories}
      options={logCategories}
      onChange={onCategoryChange}
      placeholder={translation('logs.category_filter_placeholder')}
      appendTo={document.body}
      className="p-column-filter"
      filter
      panelClassName="logCategory" /**< Style applied to multiselect-items-wrapper. */
    />
  );

  /// Initialize multiselect for log level
  const levelFilter: JSX.Element = (
    <MultiSelect
      value={selectedLevels}
      options={logLevels}
      onChange={onSecurityLevelChange}
      placeholder={translation('logs.level_filter_placeholder')}
      appendTo={document.body}
      className="p-column-filter"
      filter
    />
  );

  /// Header Element for Log Table.
  const header: JSX.Element = (
    <>
      <div className="d-flex justify-content-between align-items-center mobile-screen-width-search-bar">
        <div className="d-flex headerTableContainer log-date-picker">
          <ManualDateRangePicker
            node={node}
            date={selectedDateRange}
            defaultDate={defaultDate}
            handleDateChange={(e) => setSelectedDateRange([e.selection])}
          />

          <Button
            className="ml-1 p-button-icon-only secondary-border-radius"
            onClick={() => onExportCSV()}
            disabled={logs.isExportingLog}
            variant="primary"
          >
            <i className="bi bi-download bi bi-align-center"></i>
          </Button>

          <Button
            className="ml-1 p-button-icon-only secondary-border-radius d-none d-xxl-block"
            onClick={() => setShowDeleteConfirmationModal(true)}
            variant="danger"
          >
            <i className="bi bi-trash bi bi-align-center"></i>
          </Button>
        </div>
        <div className="ml-1 headerTableContainer header-search-filter">
          <SearchBar
            onSearchTermChanged={(data: string) => handleFilterUpdate(data)}
          />
          {categoryFilter}
          {levelFilter}
        </div>
      </div>
    </>
  );

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any): void => {
    const selectedIds: any = event.value;
    setSelectedLogs(selectedIds);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.id]: true });
    }
  };

  /// Format Log details
  const getLogDetails = (logDetails: any) => {
    const logMessages: any[] = []; /**< Log messages */
    if (Array.isArray(logDetails)) {
      logDetails.map((log: any) => {
        if (log) {
          // The log.message should only be of the type string. We may have some unexpected errors that will record it as an object.
          if (typeof log.message === 'object') {
            logMessages.push(JSON.stringify(log.message));
          }

          if (typeof log.message === 'string') {
            logMessages.push(log.message);
          }
        }
      });
    } else {
      if (typeof logDetails === 'object') {
        logMessages.push(JSON.stringify(logDetails));
      }
    }

    const logJSONDetails: Record<string, any>[] = []; /**< Log JSON details */
    const logStringDetails: any[] = []; /**< Log string details */

    logMessages.map((logMessage: any) => {
      if (
        logMessage === null ||
        logMessage === undefined ||
        logMessage ===
          'null' /* We should check for the string null to cover old bugged records */
      ) {
        return;
      }

      const message: string = '-'; /**< Empty message. */

      if (logMessage === '') {
        logStringDetails.push(message);
      }

      if (isJson(logMessage)) {
        // To check log message is JSON or not.
        const parsedMessage = JSON.parse(logMessage);
        if (!parsedMessage) {
          logStringDetails.push(message);
        }
        const key: string | null = Object.keys(parsedMessage).length
          ? Object.keys(parsedMessage)[0]
          : null;

        if (!key) {
          logStringDetails.push(message);
        } else {
          if (typeof parsedMessage[key] === 'object') {
            if (parsedMessage[key]) {
              logJSONDetails.push(parsedMessage);
            } else {
              logStringDetails.push(logMessage);
            }
          } else {
            if (typeof parsedMessage === 'object') {
              logJSONDetails.push(parsedMessage);
            } else {
              logStringDetails.push(logMessage);
            }
          }
        }
      } else {
        logStringDetails.push(logMessage);
      }
    });

    /// JSON object in JSON viewer.
    if (logJSONDetails.length) {
      return (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name={false}
          enableClipboard={false}
          src={logJSONDetails}
          theme={
            themeMode === ThemeMode.lightMode ? 'rjv-default' : 'railscasts'
          }
        />
      );
    }

    /// Display as string if the JSON parsing fails
    if (logStringDetails.length) {
      return logStringDetails.map((logMessage: any) => {
        return (
          <div className="text-left">
            <pre className="error-category">{logMessage}</pre>
          </div>
        );
      });
    }
  };

  /// This specifies the template for row expansion.
  const GetRowExpansionTemplate = ({ rowData }: any) => {
    let securityLevel: string = rowData['level'];
    // If level is error, make it as danger
    if (securityLevel && securityLevel.toLowerCase() === 'error') {
      securityLevel = 'Danger';
    } else {
      // Capitalize the first letter of Security level
      securityLevel =
        securityLevel && capitalizeFirstCharacterOnString(securityLevel);
    }

    let category: string = rowData['category'];
    // Capitalize the first letter of log category
    category = category && capitalizeFirstCharacterOnString(category);

    return (
      <>
        <tr>
          <th>{translation('logs.date')}</th>
          <td>
            {rowData.createdAt
              ? formatDate(rowData.createdAt, { isTime: true })
              : ''}
          </td>
        </tr>
        <tr>
          <th>{translation('logs.author')}</th>
          <td>{rowData['author']}</td>
        </tr>
        <tr>
          <th>{translation('logs.category')}</th>
          <td>{category}</td>
        </tr>
        <tr>
          <th>{translation('logs.securityLevel')}</th>
          <td>{securityLevel}</td>
        </tr>
        <tr>
          <th>{translation('logs.ip')}</th>
          <td>{rowData['ip']}</td>
        </tr>
        <tr>
          <th>{translation('logs.activity')}</th>
          <td>{rowData['activity']}</td>
        </tr>
        {rowData.meta && rowData.meta.logDetails && (
          <>
            <tr>
              <th colSpan={100}>{translation('logs.details')}</th>
            </tr>
            <div>
              <Row>
                <Col sm="12">
                  <p className="mb-0">
                    {getLogDetails(rowData.meta.logDetails)}
                  </p>
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  };

  /// This will render the expansion row template.
  const renderExpansionRows = (rowData: any) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  return (
    <>
      <LogTable
        data={logs.list}
        header={header}
        rowsPerPage={defaultTableOptions}
        payload={{ limit: payload.limit, skip: payload.skip }}
        totalRecords={logs.count}
        onPage={handlePageUpdate}
        expandedRows={expandedRows}
        getRowExpansionTemplate={renderExpansionRows}
        setExpandedRows={setExpandedRows}
        expandRow={expandRow}
        multiSortMeta={'multiSortMeta'}
        onSort={handleSortUpdate}
        onFilter={handleFilterUpdate}
        selectedList={selectedLogs}
        onSelectionChange={onSelectionChange}
        sortMode="single"
        dataKey={'id'}
        isLoading={!logs.list}
        parentClass="responsiveBaseDataTable logDataTable"
        className="logDataTable"
        categoryFilterElement={[]}
        securityLevelFilterElement={[]}
        logDetails={getLogDetails}
        sortField={payload.sort.sortField}
        sortOrder={payload.sort.sortOrder}
        showExpander={true}
        clients={logs.userData}
        selectionMode="checkbox"
      />

      <ConfirmationDialog
        dialogTitle={translation('logs.deleteConfirmationModal.deleteTitle')}
        isShowConfirmationModal={showDeleteConfirmationModal}
        onReject={() => onRejectDeleteLogsConfirmation()}
        onAccept={() => onAcceptDeleteLogsConfirmation()}
        cancelButtonText={translation(
          'logs.deleteConfirmationModal.rejectButtonLabel'
        )}
        confirmButtonText={translation(
          'logs.deleteConfirmationModal.acceptButtonLabel'
        )}
        modalMode={ConfirmationDialogMode.Delete}
      >
        <>
          <Row as={Col} xs="2" sm="2" md="2" lg="2" xl="4">
            {translation(
              'logs.deleteConfirmationModal.firstConfirmationMessage'
            )}
            <br />
            {translation(
              'logs.deleteConfirmationModal.secondConfirmationMessage'
            )}
            <br />
            {translation(
              'logs.deleteConfirmationModal.thirdConfirmationMessage'
            )}
          </Row>
          <Row as={Col} xs="2" sm="2" md="2" lg="2" xl="4" className="mt-3">
            <Dropdown
              name="deleteLogsDropdown"
              optionLabel="name"
              value={selectedDeleteLogsOption}
              options={durationOptions}
              onChange={(event: DropdownChangeEvent) =>
                setSelectedDeleteLogsOption({
                  name: event.value.name,
                  code: event.value.code
                })
              }
              disabled={logs.isRequestingDeleteLogsEndpoint}
            />
          </Row>
        </>
      </ConfirmationDialog>
    </>
  );
};

export default LogsPage;
