/*
 * FormWrapper.tsx (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file FormWrapper.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation, TFunction } from 'react-i18next';

import Loader from '../Loader';

export enum FormWrapperCustomSaveButtonLabel {
  None = 'none',
  Confirm = 'confirm',
  Retry = "retry"
}

/**
 * Interface defining the React properties for the FormWrapper component.
 */
interface IFormWrapperProperties {
  children: React.ReactNode; /**< Content of the form. */
  controlButtonLabel: boolean; /**< Control when to show delete, save or update buttons. When true, UI will display Delete and Update button. When false, only Save button will be displayed. */
  customSaveButtonLabel?: FormWrapperCustomSaveButtonLabel /**< Defines if the component should display a custom label for the save/update button. */
  isLoading: boolean /**< Use this property to control when an editing or adding requesting is triggered. */
  disableDeleteButton?: boolean /**< Use to disable the delete button. */
  disableSaveOrUpdateButton?: boolean /**< Use to disable the save or update button. */
  handleDeleteButton?: (event: React.MouseEvent<HTMLButtonElement>) => void /**< Function to be executed after on click in delete button. */
  handleSubmitButton: () => void /**< Function to be executed after on click in save or update button. */
  [key: string]: any; /**< Any other properties. */
}

/**
 * Form component to be used to Add or Edit an item.
 * 
 * @param properties interface of IFormWrapperProperties
 * @returns JSX.Element
 */
const FormWrapper: React.FC<IFormWrapperProperties> = (properties: IFormWrapperProperties) => {
  //Destructuring properties to get the ..otherProperties properties
  const {
    children,
    controlButtonLabel,
    isLoading,
    disableDeleteButton,
    disableSaveOrUpdateButton,
    handleDeleteButton,
    handleSubmitButton,
    ...otherProperties
  } = properties

  const transalation: TFunction = useTranslation().t;

  const deleteButtonReference = useRef<HTMLButtonElement>(null);
  const saveOrUpdateButtonReference = useRef<HTMLButtonElement>(null);
  const customSaveButtonLabel: FormWrapperCustomSaveButtonLabel = properties.customSaveButtonLabel ?? FormWrapperCustomSaveButtonLabel.None

  const getSaveOrUpdateButton = (): JSX.Element => (
    <Button
      className="d-flex align-items-center"
      disabled={disableSaveOrUpdateButton ?? false}
      ref={saveOrUpdateButtonReference}
      type="button"
      variant="primary"
      onClick={() => handleSubmitButton()}>
      <i className="bi bi-check2-circle btn-icon"></i>
      {customSaveButtonLabel === FormWrapperCustomSaveButtonLabel.Confirm && transalation('awc:/.button.confirm')}
      {customSaveButtonLabel === FormWrapperCustomSaveButtonLabel.Retry && transalation('awc:/.button.retry')}
    
      {customSaveButtonLabel === FormWrapperCustomSaveButtonLabel.None && (controlButtonLabel
        ? transalation('awc:/.button.update')
        : transalation('awc:/.button.save'))}
    </Button>
  )

  const getDeleteButton = (): JSX.Element =>
    controlButtonLabel ? (
      <Button
        type="button"
        className="d-flex align-items-center"
        disabled={disableDeleteButton ?? false}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleDeleteButton && handleDeleteButton(event)}
        ref={deleteButtonReference}
        variant="danger">
        <i className="bi bi-trash btn-icon"></i>
        {transalation('awc:/.button.delete')}
      </Button>
    ) : <></>

  return (
    <>
      <Form {...otherProperties}>
        {children}

        <div className="d-flex align-items-center justify-content-between">
          {isLoading && (<Loader />)}
          {!isLoading && getDeleteButton()}
          {!isLoading && (<><div /> {getSaveOrUpdateButton()} </>)}
        </div>
      </Form>
    </>
  );
};

export default FormWrapper