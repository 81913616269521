/*
 * ProductsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductsPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import ProductTable from './ProductTable';
import {
  addOrEditGlobalProductOptions,
  addOrEditProduct,
  copyProductAction,
  deleteProduct,
  fetchProducts,
  toggleProductDialog
} from '../../../Store/Products';
import ProductForm from './ProductForm';
import ProductFormOptionsGlobal from './ProductFormOptionsGlobal';
import { useTranslation } from 'react-i18next';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { ProductType, isUniqueProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isRole } from '../../../Utils/Formatter';

const ProductsPage = () => {
  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const shopSettings = useSelector((state) => state.shopSettings.list);
  const [searchTerm, setSearchTerm] = useState('');
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const [isViewAllProducts, setViewAllProducts] = useState<boolean>(
    false
  ); /**< To view all products */
  const [isShowDeletedColumn, setShowDeletedColumn] = useState<boolean>(
    false
  ); /**< To show deleted column. */
  const roles: string[] = JSON.parse(LocalStorage.getRole()); /**< User role. */
  const adminRoleUUID: string =
    shopSettings?.adminRoleUUID; /**< Admin role UUID. */
  const isAdminRole: boolean = isRole([adminRoleUUID], roles);

  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: searchTerm,
      filter: tablePayload.filter
    };
    setPayload(updatedPayload);
    dispatch(
      fetchProducts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        isViewAllProducts,
        updatedPayload.filter
      )
    );
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      fetchProducts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        isViewAllProducts,
        updatedPayload.filter
      )
    );
  };

  // Handle filter update event
  const handleFilterUpdate = (updatedPayload: ITablePayload): void => {
    setPayload(updatedPayload);
    dispatch(
      fetchProducts(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        isViewAllProducts,
        updatedPayload.filter
      )
    );
  };

  useEffect(() => {
    dispatch(
      fetchProducts(
        tablePayload.skip,
        tablePayload.limit,
        searchTerm,
        tablePayload.sort,
        isViewAllProducts,
        tablePayload.filter
      )
    );
  }, [searchTerm]);

  /// Fetch live products on unmount
  useEffect(() => {
    return () => {
      dispatch(
        fetchProducts(
          tablePayload.skip,
          tablePayload.limit,
          searchTerm,
          tablePayload.sort,
          false,
          tablePayload.filter
        )
      );
    };
  }, [dispatch]);

  const handleAddOrEditProduct = async (values) => {
    //Note: Clean freeProductDiscountName field value in case isUniqueProduct is false
    if (!isUniqueProduct(values)) {
      if (values.translationData && values.translationData.length) {
        for (let index = 0; index < values.translationData.length; index++) {
          const updatedTranslationData = Object.assign(
            {},
            values.translationData[index]
          );
          updatedTranslationData.freeProductDiscountName = '';
          values.translationData[index] = updatedTranslationData;
        }
      }
    }

    if (!isUniqueProduct(values) && !values.sla) {
      // If the product is not unique and has no SLA, the duration is null
      values.duration = null;
    }

    if (!isUniqueProduct(values)) {
      values.isPaymentSectionHidden = false;
      values.isCustomerDetailsSectionHidden = false;
    }

    if (values.type === ProductType.OneShot) {
      // If the product type is OneShot, the duration is null
      values.duration = null;
    }

    //Note: Clean disabledProductMessage field value in case isDisabled is false
    if (!values.isDisabled) {
      if (values.translationData && values.translationData.length) {
        for (let index = 0; index < values.translationData.length; index++) {
          const updatedTranslationData = Object.assign(
            {},
            values.translationData[index]
          );
          updatedTranslationData.disabledProductMessage = '';
          values.translationData[index] = updatedTranslationData;
        }
      }
    }

    await asyncErrorHandler(dispatch(addOrEditProduct(values)));
    await asyncErrorHandler(
      dispatch(
        fetchProducts(
          tablePayload.skip,
          tablePayload.limit,
          searchTerm,
          tablePayload.sort,
          isViewAllProducts,
          tablePayload.filter
        )
      )
    );
  };

  const handleAddOrEditGlobalProductOptions = async (values) => {
    dispatch(addOrEditGlobalProductOptions(values));
  };

  const handleDialogOpen = () => {
    dispatch(toggleProductDialog(true, null));
  };
  const handleEditProduct = (e) => {
    dispatch(toggleProductDialog(true, e.data._id));
  };

  const handleDeactivation = async (product) => {
    const editedProduct = Object.assign({}, product);
    editedProduct.live = false; /**< Deactivate the product. */

    await asyncErrorHandler(dispatch(addOrEditProduct(editedProduct)));
    await asyncErrorHandler(
      dispatch(
        fetchProducts(
          tablePayload.skip,
          tablePayload.limit,
          searchTerm,
          tablePayload.sort,
          isViewAllProducts,
          tablePayload.filter
        )
      )
    );
  };

  const handleDelete = async (productIDs: string[]) => {
    await asyncErrorHandler(dispatch(deleteProduct(productIDs)));
    await asyncErrorHandler(
      dispatch(
        fetchProducts(
          tablePayload.skip,
          tablePayload.limit,
          searchTerm,
          tablePayload.sort,
          isViewAllProducts,
          tablePayload.filter
        )
      )
    );
  };

  const productToEdit =
    products.editedProduct &&
    products.list.find((product) => product._id === products.editedProduct);

  /// Handler to viewl all products.
  const handleViewAllProducts = async () => {
    setViewAllProducts(true); /**< Include all products */
    const updatedPayload: ITablePayload = {
      skip: 0,
      limit: defaultTableLimit,
      sort: {
        sortField: 'createdAt',
        sortOrder: -1
      },
      searchTerm: '',
      filter: {}
    };
    setPayload(updatedPayload);
    await asyncErrorHandler(
      dispatch(
        fetchProducts(
          updatedPayload.skip,
          updatedPayload.limit,
          updatedPayload.searchTerm,
          updatedPayload.sort,
          true,
          updatedPayload.filter
        )
      )
    );
    setShowDeletedColumn(true);
  };

  /**
   * Copy product handler
   * @param productIDs
   */
  const handleCopyProduct = async (productIDs: string[]) => {
    await asyncErrorHandler(dispatch(copyProductAction(productIDs)));
    await asyncErrorHandler(
      dispatch(
        fetchProducts(
          tablePayload.skip,
          tablePayload.limit,
          searchTerm,
          tablePayload.sort,
          isViewAllProducts,
          tablePayload.filter
        )
      )
    );
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <ProductTable
            products={products.list}
            loading={products.listIsFetching}
            handleDialogOpen={handleDialogOpen}
            handleEditProduct={handleEditProduct}
            setSearchTerm={setSearchTerm}
            handleDelete={handleDelete}
            handlePageUpdate={handlePageUpdate}
            handleSortUpdate={handleSortUpdate}
            tablePayload={tablePayload}
            totalRecords={products.totalRecords}
            handleViewAllProducts={handleViewAllProducts}
            isShowDeletedColumn={isShowDeletedColumn}
            handleCopyProduct={handleCopyProduct}
            handleFilterUpdate={handleFilterUpdate}
            isAdminRole={isAdminRole}
          />
        </Col>
      </Row>
      {shopSettings.productOptions &&
        products.list &&
        !products.isLoadingImages && (
          <Row>
            <Col sm={12}>
              <ProductFormOptionsGlobal
                options={shopSettings.productOptions}
                translationData={shopSettings.translationData}
                handleSubmit={handleAddOrEditGlobalProductOptions}
              />
            </Col>
          </Row>
        )}
      <DialogWrapper
        isDialogVisible={products.productDialogOpened}
        headerTitle={
          productToEdit
            ? translation('product.form.header.edit_product_dialog')
            : translation('product.form.header.add_product_dialog')
        }
        onHide={() => {
          dispatch(toggleProductDialog(false));
        }}
      >
        <ProductForm
          loading={products.productIsChanging}
          handleSubmit={handleAddOrEditProduct}
          product={productToEdit}
          handleDeactivation={handleDeactivation}
          handleDelete={handleDelete}
        />
      </DialogWrapper>
    </>
  );
};

export default ProductsPage;
