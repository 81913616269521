/*
 * I18n.ts (AbstractWebCommon)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file I18n.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../Translations/En/Translation.json';
import translationDe from '../Translations/De/Translation.json';
import translationJa from '../Translations/Ja/Translation.json';
import translationZh from '../Translations/Zh/Translation.json';
import translationKo from '../Translations/Ko/Translation.json';
import translationCommonEn from '@abstract/abstractwebcommon-client/translations/en.json';
import translationCommonDe from '@abstract/abstractwebcommon-client/translations/De.json';
import translationCommonJa from '@abstract/abstractwebcommon-client/translations/Ja.json';
import translationCommonZh from '@abstract/abstractwebcommon-client/translations/Zh.json';
import translationCommonKo from '@abstract/abstractwebcommon-client/translations/Ko.json';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translationEn.translation,
      common: translationEn.translation,
      awc: translationCommonEn.translation
    },
    de: {
      translation: translationDe.translation,
      common: translationDe.translation,
      awc: translationCommonDe.translation
    },
    ja: {
      translation: translationJa.translation,
      common: translationJa.translation,
      awc: translationCommonJa.translation
    },
    zh: {
      translation: translationZh.translation,
      common: translationZh.translation,
      awc: translationCommonZh.translation
    },
    ko: {
      translation: translationKo.translation,
      common: translationKo.translation,
      awc: translationCommonKo.translation
    }
  },
  lng: 'en',
  interpolation: { escapeValue: false }
});

export default i18n;
