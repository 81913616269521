/**
 * HttpClient.ts (Abstract Web Common)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file HttpClient.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section Abstract Web Common
 */

import jwtDecode from 'jwt-decode';

import {IAPIEntityResponse} from '../../Shared/interfaces/api';
import {CreateErrorLog} from '../../Shared/utils/CreateErrorLog';
import {IUserInformationToken, UserAuthenticationToken} from '../../Shared/utils/UserAuthenticationToken';
import {LocalStorage} from './sharedLocalStorage';
import {SharedCommomRouteName} from './sharedRoutesNames';
import {isStringEmptyOrNullOrUndefined} from '../../Shared/utils/sharedFunctions';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

export class HttpClient {
    private _baseUrl: string;
    private _onCreateErrorLog: (payload: CreateErrorLog) => void; /**< Create Log for Exception/Error. */
    /**
     * Creates an instance of the HttpClient
     * @constructor
     * @param {string} baseUrl - (Optional) If not specified, use full urls per request.  If supplied and a function passes a relative url, it will be appended to this
     * @param {(payload: CreateErrorLog) => void} - (Optional) Create Log for error.
     */
    constructor(baseUrl: string, onCreateErrorLog: (payload: CreateErrorLog) => void) {
        if (baseUrl) {
            this._baseUrl = baseUrl;
        }
        if (onCreateErrorLog) {
            this._onCreateErrorLog = onCreateErrorLog;
        }
    }


    /**
     * Gets a resource from an endpoint
     * @param {string} url - fully qualified url or relative path
     * @param {options} headers - (optional) headers object
     */
    public async get<T>(
        url: string,
        headers?: Record<string, any>,
        isAuthenticationRequired = true,
        isErrorLoggingEnabled = true,
        isErrorToastEnabled = true,
    ): Promise<IAPIEntityResponse<T>> {
        headers = this._headersFromOptions(headers, isAuthenticationRequired);
        const response: Response = await asyncErrorHandler(fetch(`${this._baseUrl}${url}`,
            {
                headers: headers,
            }));
        return await asyncErrorHandler(this.processResponse<T>(response, isErrorLoggingEnabled, isErrorToastEnabled));
    }

    /**
     * Gets resource(s) from an endpoint
     * T type of object returned.
     * @param {string} url - fully qualified or relative url
     * @param {string} data
     * @param {IRequestOptions} headers - (optional) header object
     */
    public async post<T>(
        url: string,
        data: Record<string, any>,
        headers?: Record<string, any>,
        isAuthenticationRequired = true,
        isErrorLoggingEnabled = true,
        isErrorToastEnabled = true
    ): Promise<IAPIEntityResponse<T>> {
        headers = this._headersFromOptions(headers, isAuthenticationRequired);
        const response: Response = await asyncErrorHandler(fetch(`${this._baseUrl}${url}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)

        }));
        return await asyncErrorHandler(this.processResponse<T>(response, isErrorLoggingEnabled, isErrorToastEnabled));
    }

    /**
     * Upload image
     * T type of object returned.
     * @param {string} url - fully qualified or relative url
     * @param {Formdata} data
     * @param {IRequestOptions} headers - (optional) header object
     */
    public async imageUpload<T>(
        url: string,
        data: FormData,
        headers?: Record<string, any>,
        isAuthenticationRequired = true,
        isImageUpload = true,
        isErrorLoggingEnabled = true,
        isErrorToastEnabled = true
    ): Promise<IAPIEntityResponse<T>> {
        headers = this._headersFromOptions(headers, isAuthenticationRequired, isImageUpload);
        const response: Response = await asyncErrorHandler(fetch(`${this._baseUrl}${url}`, {
            method: 'POST',
            headers: headers,
            body: data

        }));
        return await asyncErrorHandler(this.processResponse<T>(response, isErrorLoggingEnabled, isErrorToastEnabled));
    }

    /**
     * Update resource(s) from an endpoint
     * T type of object returned.
     * @param {string} url - fully qualified or relative url
     * @param {string} data
     * @param {options} headers - (optional) headers object
     */
    public async put<T>(
        url: string,
        data: Record<string, any>,
        headers?: Record<string, any>,
        isAuthenticationRequired = true,
        isErrorLoggingEnabled = true,
        isErrorToastEnabled = true,
    ): Promise<IAPIEntityResponse<T>> {
        headers = this._headersFromOptions(headers, isAuthenticationRequired);
        const response: Response = await asyncErrorHandler(fetch(`${this._baseUrl}${url}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)

        }));
        return await asyncErrorHandler(this.processResponse<T>(response, isErrorLoggingEnabled, isErrorToastEnabled));
    }


    /**
     * Deletes a resource from an endpoint
     * @param {string} url - fully qualified url or relative path
     * @param {IRequestOptions} headers - (optional) headers object
     */
    public async delete<T>(
        url: string,
        headers?: Record<string, any>,
        isAuthenticationRequired = true,
        isErrorLoggingEnabled = true,
        isErrorToastEnabled = true
    ): Promise<IAPIEntityResponse<T>> {
        headers = this._headersFromOptions(headers, isAuthenticationRequired);
        const response: Response = await asyncErrorHandler(fetch(`${this._baseUrl}${url}`, {
            method: 'DELETE',
            headers: headers,

        }));
        return await asyncErrorHandler(this.processResponse<T>(response, isErrorLoggingEnabled, isErrorToastEnabled));
    }


    private _headersFromOptions(headers?: Record<string, any>, isAuthenticationRequired = true, isImageUpload = false): Record<string, any> {
        headers = headers || {};
        
        const authToken: string = LocalStorage.getXAuthToken() || '';
        const userId = LocalStorage.getXUserUUID();
        const applicationID = LocalStorage.getXApplicationUUID() ?? LocalStorage.getApplicationUUID();

        if (isAuthenticationRequired && !authToken) {
            return;
        }

        const requestHeaders = () => {
            if (isImageUpload) {
                return new Headers({
                    Authorization: `Bearer ${authToken}`,
                    ...headers
                });
            }

            if (userId && applicationID) {
                return new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                    'x-user-id': userId || '',
                    Application: applicationID || '',
                    ...headers
                });
            }

            if (isAuthenticationRequired) {
                return new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                    ...headers
                });
            }

            return new Headers({
                'Content-Type': 'application/json',
                ...headers
            });
        };
        return requestHeaders();
    }

    protected async processResponse<T>(response: Response, isErrorLoggingEnabled: boolean, isErrorToastEnabled: boolean): Promise<IAPIEntityResponse<T>> {
        // Check if token is renewed, update token and user theme & language in local storage
        if (
            response.headers.get('renewedToken') &&
            (LocalStorage.getXAuthToken() !== response.headers.get('renewedToken')) &&
            !response.url.includes('user/update/themeMode') &&
            !response.url.includes('user/update/languageSettingsMode') /** Ignore this validation when calling endpoint to update theme mode & language settings mode in User DB */
        ) {
            const newDecodedToken: IUserInformationToken = new UserAuthenticationToken(
                jwtDecode(response.headers.get('renewedToken')),
                true
            ).getUserAuthenticationToken();

            const customStorageEvent: StorageEvent = new StorageEvent('storage', {
                storageArea: window.localStorage,
                key: LocalStorage.themeModeKey,
                oldValue: LocalStorage.getThemeMode(),
                newValue: newDecodedToken.themeMode,
            });
            //Fire a storage event to update theme mode
            window.dispatchEvent(customStorageEvent);

            const customLanguageStorageEvent: StorageEvent = new StorageEvent('storage', {
                storageArea: window.localStorage,
                key: LocalStorage.languageSettingsModeKey,
                oldValue: LocalStorage.getLanguageSettingsMode(),
                newValue: newDecodedToken.languageSettingsMode,
            });
            //Fire a storage event to update language settings mode
            window.dispatchEvent(customLanguageStorageEvent);

            LocalStorage.setXAuthToken(response.headers.get('renewedToken'))
        }

        //NOTE: If the response is 401, there is no need to create an error log
        if (isErrorToastEnabled && response.status === 401) {
            handleApiUnauthorizedError();
            return response.json();
        }
        if (isErrorToastEnabled && response.status === 403) {
            handleApiUnauthorizedError();
        }

        if (!response.ok) {
            // If response is not ok, create Error Log. 
            const statusCode: number = response.status; // Response status code
            const statusText: string = response.statusText; // Response status text
            const fetchURL: string = response.url; // Fetch URL

            const responseStatus: Record<string, any> = {
                ErrorDetail: {
                    status: statusCode,
                    errorDetail: statusText,
                    URL: fetchURL
                }
            }; /**< Format Response status. */

            const logPayload: CreateErrorLog = {
                error: JSON.stringify(responseStatus)
            }; /**< Log Payload */
            try {
                const body = await asyncErrorHandler(response.json());
                const responseBody: Record<string, any> = {
                    ErrorDetail: {
                        ...body,
                        URL: fetchURL
                    }
                }; /**< Format Response body. */

                logPayload.error = JSON.stringify(responseBody);
                logPayload.errorMessage = body.error.message ?? body.error;
                if (isErrorLoggingEnabled) {
                    this._onCreateErrorLog && this._onCreateErrorLog(logPayload); /**< Create Log with response body. */
                }
                return body;
            } catch (_error) {
                if (isErrorLoggingEnabled) {
                    logPayload.errorMessage = _error.message ?? _error as string;
                    this._onCreateErrorLog && this._onCreateErrorLog(logPayload); /**< Create Log with reponse status details. */
                }
                throw new Error(_error as string);
            }
        }
        return response.json();
    }
}

/**
* On forbidden or unauthorized, throw error toast, clear local storage and redirect to login page.
*/
const handleApiUnauthorizedError = () => {
    //If User project, it should redirect to /login page, otherwise should redirect to /validate?logout.
    const isNotUserProject: boolean = !isStringEmptyOrNullOrUndefined(LocalStorage.getXApplicationUUID()) 
        || !isStringEmptyOrNullOrUndefined(LocalStorage.getApplicationUUID())
    
    LocalStorage.removeAllLocalStorage();

    const parameters: URLSearchParams = new URLSearchParams({
        logout: 'true',
        themeMode: LocalStorage.getThemeMode(),
        languageSettingsMode: LocalStorage.getLanguageSettingsMode(),
    }); /**< Query parameters */

    // If the current page is shop page, redirect to shop page after login.
    if (window.location.href.includes(SharedCommomRouteName.productBuyRoute)) {
        parameters.append('redirect_url', window.location.pathname);
    }
    
    window.location.href = isNotUserProject 
        ?  `${SharedCommomRouteName.validateRoute}?${parameters.toString()}`
        :   SharedCommomRouteName.loginRoute;
};