/*
 * SettingsPermittedCountriesTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Pascal Mayr, 2020
 *
 * @file SettingsPermittedCountriesTable.tsx
 * @author Pascal Mayr
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useRef, useState } from 'react';
import { Column } from 'primereact/column';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Country } from 'country-state-city';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import SettingsPermittedCountriesForm from './SettingsPermittedCountriesForm';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { ICountry } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import {
  ITablePayload,
  ISortEvent,
  IPageEvent
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { useAppSelector } from '../../../Hooks';

/**
 * Interface for SettingsPermittedCountriesTable properties.
 */
interface ISettingsPermittedCountriesTableProperties {
  data: any;
  handleSubmit: any;
  handleDelete: any;
  tablePayload: ITablePayload /**  Table payload. */;
  setSearchTerm: any;
  isLoading: boolean /**< Is requesting server side */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  totalRecords: number /**< Total numbers of recorded countries. */;
  handlePageUpdate: (event: IPageEvent) => void /**< handle on change page. */;
  countryDataTableReference: any /**< Ref to manipulate the data table. */;
}

const SettingsPermittedCountriesTable = ({
  data,
  handleSubmit,
  handleDelete,
  tablePayload,
  setSearchTerm,
  handleSortUpdate,
  isLoading,
  totalRecords,
  handlePageUpdate,
  countryDataTableReference
}: ISettingsPermittedCountriesTableProperties) => {
  const sort: ISortEvent = tablePayload?.sort; /**< Sort */
  const [open, setOpen] = useState(false);
  const [countrySettings, setCountrySettings] = useState();
  const translation = useTranslation().t;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */
  const [selectedCountries, setSelectedCountries] = useState<ICountry[] | null>(
    null
  ); /**< Selected Countries. */
  const deleteButtonReference: any = useRef(
    null
  ); /**< Delete Button Reference. */
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const isCountryChanging: boolean = useAppSelector(
    (state) => state.countries.countryIsChanging
  ); /**< isCountryChanging */

  const header = (
    <>
      <div className="d-flex justify-content-between">
        <div className="headerTableContainer">
          <ActionButton
            variant="danger"
            onClick={(event: any) => deleteButtonClicked(event)}
            isDisabled={
              ((selectedCountries &&
                Object.keys(selectedCountries).length === 0) ||
                !selectedCountries) ??
              false
            }
            buttonReference={deleteButtonReference}
          />
          <ActionButton
            onClick={() => {
              setOpen(true);
              setCountrySettings(null);
            }}
          />
        </div>

        <SearchBar
          onSearchTermChanged={(searchedTerm) => setSearchTerm(searchedTerm)}
        />
      </div>
      <DialogWrapper
        isDialogVisible={open}
        onHide={() => setOpen(false)}
        headerTitle={
          countrySettings
            ? translation('fields.countrySettings.edit_country_header_dialog')
            : translation('fields.countrySettings.add_country_header_dialog')
        }
      >
        <SettingsPermittedCountriesForm
          countrySettings={countrySettings}
          data={data}
          handleSubmit={(data: ICountry) => {
            handleSubmit(data);
            setOpen(false);
          }}
          handleDelete={(countryIDs: string[]) => {
            handleDelete(countryIDs);
            setOpen(false);
          }}
          isLoading={isCountryChanging}
        />
      </DialogWrapper>
    </>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete Country(s) on Accept
  const onAccept = () => {
    const countryIDs: string[] = selectedCountries.map(
      (eachCountry: ICountry) => eachCountry._id
    ); /**< countryIDs */
    handleDelete(countryIDs);
    setShowConfirmation(false);
    setSelectedCountries(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation dialog
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  const handleEdit = (data: ICountry) => {
    setOpen(true);
    setCountrySettings(data);
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: ICountry[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow: ICountry[] = selectedValue.map((row: ICountry) => row);
      setSelectedCountries(selectedRow);
    }
  };

  const GetRowExpansionTemplate = (values: { rowData: ICountry }) => (
    <>
      <tr>
        <th>{translation('fields.countrySettings.table.country')}</th>
        <td>{values.rowData['name']}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.rules')}</th>
        <td>{values.rowData.rules ? values.rowData.rules : '-'}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.taxStateB2B')}</th>
        <td>{handleTaxStateB2BField(values.rowData.taxStateB2B)}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.taxRateB2B')}</th>
        <td>{values.rowData.taxRateB2B ? values.rowData.taxRateB2B : '-'}</td>
      </tr>
      <tr>
        <th>Ta{translation('fields.countrySettings.table.taxStateB2C')}</th>
        <td>{handleTaxStateB2CField(values.rowData.taxStateB2C)}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.taxRateB2C')}</th>
        <td>{values.rowData.taxRateB2C ? values.rowData.taxRateB2C : '-'}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.B2B')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.b2b} />}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.B2C')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.b2c} />}</td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.created')}</th>
        <td>
          {values.rowData.createdAt ? formatDate(values.rowData.createdAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('fields.countrySettings.table.updated')}</th>
        <td>
          {values.rowData.updatedAt ? formatDate(values.rowData.updatedAt) : ''}
        </td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: ICountry) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  const handleTaxStateB2BField = (taxStateB2B: string) => {
    return taxStateB2B === 'vat_custom'
      ? 'Custom Tax rate'
      : taxStateB2B === 'vat_reverse_charge'
      ? 'EU reverse charge'
      : 'No Taxes applicable';
  };

  const handleTaxStateB2CField = (taxStateB2C: string) => {
    return taxStateB2C === 'vat_custom'
      ? 'Custom Tax rate'
      : taxStateB2C === 'vat_reverse_charge'
      ? 'EU reverse charge'
      : 'No Taxes applicable';
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  /// Initialize Datatable
  const getDataTable = () => {
    return (
      <BaseDatatable
        ref={countryDataTableReference}
        value={data}
        header={header}
        isLoading={!data}
        expandedRows={expandedRows}
        dataKey="_id"
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        rowExpansionTemplate={renderExpansionRows}
        rows={tablePayload.limit}
        first={tablePayload.skip}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        selection={selectedCountries}
        onSelectionChange={onSelectionChange}
        onSort={handleSortUpdate}
        onPage={handlePageUpdate}
        totalRecords={totalRecords}
      >
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="countries.countryDetails.countryName"
          header={translation('fields.countrySettings.table.country')}
          sortable
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.country')}
              data={Country.getCountryByCode(country.name)?.name}
            />
          )}
        />
        <Column
          sortable
          field="countries.rules"
          header={translation('fields.countrySettings.table.rules')}
          headerClassName="d-table-cell d-sm-none d-md-table-cell"
          className="d-table-cell d-sm-none d-md-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.rules')}
              data={country.rules ? country.rules : '-'}
            />
          )}
        />
        <Column
          field="countries.taxB2B"
          header={translation('fields.countrySettings.table.taxStateB2B')}
          sortable
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.taxStateB2B')}
              data={handleTaxStateB2BField(country.taxStateB2B)}
            />
          )}
        />
        <Column
          field="countries.taxRateB2B"
          header={translation('fields.countrySettings.table.taxRateB2B')}
          headerClassName="d-table-cell d-sm-none d-xl-table-cell"
          className="d-table-cell d-sm-none d-xl-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.taxRateB2B')}
              data={country.taxRateB2B ? country.taxRateB2B : '-'}
            />
          )}
          sortable
        />
        <Column
          field="countries.taxB2C"
          header={translation('fields.countrySettings.table.taxStateB2C')}
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell"
          className="d-table-cell d-sm-none d-xxl-table-cell"
          sortable
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.taxStateB2C')}
              data={handleTaxStateB2CField(country.taxStateB2C)}
            />
          )}
        />
        <Column
          field="countries.taxRateB2C"
          header={translation('fields.countrySettings.table.taxRateB2C')}
          headerClassName="d-table-cell d-sm-none d-3xl-table-cell"
          className="d-table-cell d-sm-none d-3xl-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.taxRateB2C')}
              data={country.taxRateB2C ? country.taxRateB2C : '-'}
            />
          )}
          sortable
        />
        <Column
          field="countries.b2b"
          header={translation('fields.countrySettings.table.B2B')}
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-3xl-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.B2B')}
              data={<ShowCheckOrUncheckIcon value={country.b2b} />}
            />
          )}
          sortable
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-3xl-table-cell"
        />
        <Column
          field="countries.b2c"
          header={translation('fields.countrySettings.table.B2C')}
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-3xl-table-cell"
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-3xl-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.B2C')}
              data={<ShowCheckOrUncheckIcon value={country.b2c} />}
            />
          )}
          sortable
        />
        <Column
          field="countries.createdAt"
          header={translation('fields.countrySettings.table.created')}
          sortable
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          body={(country: ICountry) => (
            <DatatableColumn
              title={translation('fields.countrySettings.table.created')}
              data={country.createdAt ? formatDate(country.createdAt) : ''}
            />
          )}
        />
        <Column
          field="edit"
          body={(country: ICountry) => {
            return (
              <Button
                variant="outline"
                className="custom-action-column-action-position"
                onClick={() => handleEdit(country)}
              >
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="col-width-45 p-0 absolute-position-responsive-screen"
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmationPopup()}
    </>
  );
};

export default SettingsPermittedCountriesTable;
