/**
 * cropImage.ts (InstaLOD GmbH)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file cropImage.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {Area} from "react-easy-crop/types";
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

/**
 * Creates a new HTMLImageElement instance.
 * @param url Image source URL.
 * @returns Promise.
 */
export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image() as HTMLImageElement;
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })


/**
 * Crop image based on the specified pixel crop size.
 * @param imageSourceURL Image source URL
 * @param pixelCrop Cropped size.
 * @returns Base64 string.
 */
export const getCroppedImage = async(
  imageSourceURL: string,
  pixelCrop: Area
): Promise<any> => {
  const image = await asyncErrorHandler(createImage(imageSourceURL)) as HTMLImageElement;
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  const context: CanvasRenderingContext2D = canvas.getContext('2d')

  if (!context) {
    return null
  }

  const width: number = image.width;
  const height: number = image.height;

  // set canvas size to match the bounding box
  canvas.width = width
  canvas.height = height

  // translate canvas context to a central location to allow rotating and flipping around the center
  context.translate(width/ 2, height/ 2)
  context.translate(-width/ 2, -height/ 2)

  // draw rotated image
  context.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = context.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  context.putImageData(data, 0, 0)

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}
