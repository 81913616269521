/**
* Insert character in a text.
* @Character text supplied.
* @CharToInsert text to insert in the character.
* @stringPosition string to search for.
 */

export const insertStringAt = (Character: string, CharToInsert: string, stringPosition: string) => {
    const characterArray = Character.split(' ');
      const stringIndex = characterArray.indexOf(stringPosition)
       characterArray.splice(stringIndex+1, 0, CharToInsert);
    return characterArray.join(' ')
}