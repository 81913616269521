/*
 * validators.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file validators.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export const validators = {
  UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  NAME: /(?!^\d+$)^.+$/,
  RSS_URL: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})?$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&-]{8,}$/, // at least 1 character and 1 number, but can have special characters
  IMAGE_NAME: /^$|[a-zA-Z0-9_\ \-()]+\.(jpeg|jpg|png|webp|avif|bmp|gif)$/i,
  USERNAME: /^(?![0-9]*$)[a-zA-Z0-9]+$/, // alphanumeric & not only numbers
  WEBSITE: /https?:\/\/+[a-zA-Z]/g,
  NOT_GT_OR_LT: /^[^<>]*$/,
};
