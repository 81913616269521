/*
 * config.ts (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file config.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export const USER_BASE_API_URL =
  process.env.REACT_APP_USER_BASE_API_URL ||
  'http://localhost:8080/user-backend';
export const BASE_API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8084/api';
export const REACT_APP_USER_FRONTEND_BASE_URL =
  process.env.REACT_APP_USER_FRONTEND_BASE_URL;
export const BASE_DATE_FORMAT =
  process.env.REACT_APP_BASE_DATE_FORMAT || 'dd.MM.yyyy';
export const DATE_TIME_FORMAT =
  process.env.REACT_APP_DATE_TIME_FORMAT || 'dd.MM.yyyy hh:mm:ss';
export const TWENTY_FOUR_DATE_TIME_FORMAT =
  process.env.REACT_APP_TWENTY_FOUR_DATE_TIME_FORMAT || 'dd.MM.yyyy HH:mm:ss';
export const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION;
export const CURRENT_AWC_VERSION = process.env.REACT_APP_CURRENT_AWC_VERSION;
export const DefaultTaxIDLabel = {
  vat: 'VAT ID'
};

// placeholders
export const Placeholders: { [key: string]: any } = {
  'Transaction Template': {
    firstName: 'John',
    lastName: 'Doe',
    email: 'jd@instaLOD.com',
    address1: '991 stuttgart',
    address2: '199 stuttgart',
    companyName: 'InstaLOD',
    vatId: 'DE123456789',
    billingFirstName: 'John',
    billingLastName: 'Doe',
    billingCompanyName: 'InstaLOD',
    billingEmail: 'johndoe@gmail.com',
    billingAddress1: '231 stuttgart',
    billingAddress2: '132 stuttgart',
    billingCity: 'Ludwigsburg',
    billingStateOrProvince: 'Baden-Württemberg',
    billingZipcode: '10021',
    billingCountry: 'Germany',
    city: 'Ludwigsburg',
    stateOrProvince: 'Baden-Württemberg',
    country: 'Germany',
    zipcode: '10021',
    invoiceLink: 'https://www.instalod.com/invoice-link',
    transactionPageLink: 'https://www.instalod.com/transaction-page-link',
    transaction: 'd023ece0-e175-11ea-a575-7f2a1xe437cf',
    customer: 'John Doe'
  },
  'Transaction Summary Template': {
    customer_firstName: 'John',
    customer_lastName: 'Doe',
    customer_email: 'jd@instaLOD.com',
    customer_address1: '991 stuttgart',
    customer_address2: '199 stuttgart',
    customer_companyName: 'InstaLOD',
    customer_vatId: 'DE123456789',
    customer_billingFirstName: 'John',
    customer_billingLastName: 'Doe',
    customer_billingCompanyName: 'InstaLOD',
    customer_billingEmail: 'johndoe@gmail.com',
    customer_billingAddress1: '231 stuttgart',
    customer_billingAddress2: '132 stuttgart',
    customer_billingCity: 'Ludwigsburg',
    customer_billingStateOrProvince: 'Baden-Württemberg',
    customer_billingZipcode: '10021',
    customer_billingCountry: 'Germany',
    customer_city: 'Ludwigsburg',
    customer_stateOrProvince: 'Baden-Württemberg',
    customer_country: 'Germany',
    customer_zipcode: '10021',
    paymentMethod: 'credit_card',
    product_name: 'InstaLOD Grid',
    product_sku: 'instalod-grid',
    products_quantity: '3',
    priceCalculation_price: '10,000.00',
    terms_and_conditions_url: 'any-link',
    termsText:
      'This order is subject to the <a href="{{termsLink}}" id="terms_and_condition_url" target="_blank">terms and conditions</a> of the software license agreement that you have accepted. To ensure that you have a copy for your records, click the link provided above to download the agreement.'
  },
  'Email Template': {
    message: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt`
  },
  'Master Template': {
    body: `<div id='shopcontent' class="shop-body section light-section">
                    <h1>Shop Content</h1>
                </div>
          </div>`,
    currentYear: new Date().getFullYear(),
    applicationPageURL: 'http://instalod.com'
  },
  'Shop Sales Purchase Template': {
    product_name: 'Product Name',
    customer: 'John Doe',
    customer_firstName: 'John',
    customer_email: 'jd@customer.com',
    customer_id: 'd023ece0-e175-11ea-a575-7f2a1xe437cf',
    invoiceLink: 'https://www.instalod.com/invoice-link',
    transactionPageLink: 'https://www.instalod.com/transaction-page-link',
    adminTransactionPageLink:
      'https://www.instalod.com/admin-transaction-page-link',
    transaction_id: 'd023ece0-e175-11ea-a575-7f2a1xe437cf'
  },
  'Manual Sales Purchase Template': {
    product_name: 'Product Name',
    customer: 'John Doe',
    customer_firstName: 'John',
    customer_email: 'jd@customer.com',
    customer_id: 'd023ece0-e175-11ea-a575-7f2a1xe437cf',
    admin_username: 'Katterina',
    admin_id: 'd023ece0-e175-11ea-a57',
    invoiceLink: 'https://www.instalod.com/invoice-link',
    transactionPageLink: 'https://www.instalod.com/transaction-page-link',
    adminTransactionPageLink:
      'https://www.instalod.com/admin-transaction-page-link',
    transaction_id: 'd023ece0-e175-11ea-a575-7f2a1xe437cf'
  },
  'Add User Template': {
    creation_username: 'john',
    creation_password: 'password',
    domain_name: typeof window !== 'undefined' ? window.location.origin : ''
  },
  'Error Handler Template': {
    errorNumber: '404',
    titleDescription: 'Oops.. page not found',
    subtitleDescription: 'take me back to',
    takeMeBackTo: 'Home'
  },
  'Smtp Test Email Template': {
    firstName: 'John',
    lastName: 'Doe',
    applicationName: 'Abstract Ecommerce',
    applicationDescription: 'Abstract Ecommerce',
    applicationLogoUrl: 'https://cloud.InstaLOD.io/img/icon-footer-email.png'
  },
  'Order Confirmation Template - Non SLA Product': {
    product: 'InstaLod Studio License',
    quantity: 1,
    totalAmount: '$1799',
    customer: 'John Doe',
    billingCompanyName: 'TestCompany',
    customerAddress: 'Customer Address',
    billingAddress: 'xxx street',
    billingZipcode: '123456',
    billingCity: 'Stuttgart',
    customerBillingCountry: 'DE',
    customerVatID: 'DE123456789',
    paymentMethod: 'Credit Card',
    discountCode: 'xxx',
    invoiceLink: 'https://www.instalod.com/invoice-link',
    productDetails: 'Node-locked',
    effectiveDate: '12. January 2023',
    customerBillingAddress: 'Customer Address',
    customerBillingZipcode: 'Customer Zipcode',
    customerBillingCity: 'Customer City',
    termsText:
      '<p>This order is subject to the <a href="https://www.fleetster.net/legal/standard-terms-and-conditions.pdf">terms and conditions of this website</a> that you have accepted. To ensure that you have a copy for your records, please click the link provided above to download the agreement.</p>',
    productOptions: '{{productOptions}}',
    customerFirstName: 'John'
  },
  'Order Confirmation Template - SLA Product': {
    product: 'InstaLod Studio License',
    quantity: 1,
    licenseDuration: '1 year',
    totalAmount: '$1799',
    customer: 'John Doe',
    billingCompanyName: 'TestCompany',
    customerAddress: 'Customer Address',
    billingAddress: 'xxx street',
    billingZipcode: '123456',
    billingCity: 'Stuttgart',
    customerBillingCountry: 'DE',
    customerVatID: 'DE123456789',
    paymentMethod: 'Credit Card',
    discountCode: 'xxx',
    invoiceLink: 'https://www.instalod.com/invoice-link',
    productDetails: 'Node-locked',
    effectiveDate: '14. January 2023',
    customerBillingAddress: 'Customer Address',
    customerBillingZipcode: 'Customer Zipcode',
    customerBillingCity: 'Customer City',
    termsText:
      '<p>This order is subject to the <a href="https://www.fleetster.net/legal/standard-terms-and-conditions.pdf">terms and conditions of the software license agreement</a> that you have accepted. To ensure that you have a copy for your records, please click the link provided above to download the agreement.</p>',
    productDetailsList: '{{productDetailsList}}',
    customerFirstName: 'John'
  },
  'Subscription Refund Template': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product A'
  },
  'Subscription Cancel Template': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product B',
    billingEndDate: '07. June 2024'
  },
  'Summary Template': {
    transactions: '{{transactions}}',
    transactionsCount: 10
  },
  'Summary Template - Transaction details': {
    transactionID: 'Any transaction ID',
    productName: 'Product Name',
    userName: 'User Name',
    customerName: 'Customer Name',
    customerFirstName: 'John',
    customerEmail: 'customerEmail@asd.com'
  },
  'Subscription Payment Notification': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product C',
    nextBillingDate: '07. November 2024',
    invoiceLink: 'https://www.instalod.com/invoice-link'
  },
  'Order Confirmation Template - List Detail': {
    detail: 'List detail one'
  },
  'Subscription Terminate Template': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product B'
  },
  'Subscription Past Due Template': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product B',
    subscriptionPageURL: 'https://any-url.com'
  },
  'Subscription Pause Template Due To Failed Payment': {
    customer: 'John Doe',
    customerFirstName: 'John',
    productName: 'Product B'
  },
  'Subscription Unpause Update': {
    customer: 'John Doe',
    productName: 'Product B'
  },
  default: {}
};
