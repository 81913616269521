import { TransactionStatus } from '@abstract/abstractwebcommon-shared/constants/ecommerce/TransactionConstants';
/*
 * Transactions.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Transactions.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import CryptoJS from 'crypto-js';
import {
  getToken as getTokenCall,
  processFreeProductTransactionAPI,
  relayNonce,
  sendInvoice,
  toCheckProductAlreadyPurchasedAPI
} from '../Services/Api';
import {
  getTransactionCall,
  getTransactions,
  markAsLicenseCreated,
  regenerateInvoiceCall,
  updateBillingDetailsAPI,
  updateTransaction
} from '../Services/Transaction';
import { fetchOneProduct } from './Products';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import eventConstants from '../Utils/EventConstants';
import { translate } from '../Utils/Translate';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { RouteName } from '../Utils/routesNames';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { isUniqueProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';

const { track } = eventConstants.event;

const GET_TRANSACTION_REQUEST = 'transactions/fetchOne/request';
const GET_TRANSACTION_SUCCESS = 'transactions/fetchOne/success';
const GET_TRANSACTION_FAILURE = 'transactions/fetchOne/failure';

const GET_TRANSACTION_LIST_REQUEST = 'transactions/fetchList/request';
const GET_TRANSACTION_LIST_SUCCESS = 'transactions/fetchList/success';
const GET_TRANSACTION_LIST_FAILURE = 'transactions/fetchList/failure';

const CREATE_TRANSACTION_REQUEST = 'transactions/create/request';
const CREATE_TRANSACTION_SUCCESS = 'transactions/create/success';
const CREATE_TRANSACTION_FAILURE = 'transactions/create/failure';

const RESET_TRANSACTION_REQUEST = 'transactions/reset/request';

const GET_TOKEN_REQUEST = 'transactions/paymentToken/request';
const GET_TOKEN_SUCCESS = 'transactions/paymentToken/success';
const GET_TOKEN_FAILURE = 'transactions/paymentToken/failure';

const PAYMENT_REQUEST = 'payment/request';
const PAYMENT_SUCCESS = 'payment/success';
const PAYMENT_FAILURE = 'payment/failure';

const REGENERATE_INVOICE_REQUEST = 'invoice/regenerate/request';
const REGENERATE_INVOICE_SUCCESS = 'invoice/regenerate/success';
const REGENERATE_INVOICE_FAILURE = 'invoice/regenerate/failure';

const LICENSE_CREATED_REQUEST = 'transaction/licenseCreated/request';
const LICENSE_CREATED_SUCCESS = 'transaction/licenseCreated/success';
const LICENSE_CREATED_FAILURE = 'transaction/licenseCreated/failure';

const SEND_INVOICE_EMAIL_REQUEST = 'transaction/sendInvoiceEmail/request';
const SEND_INVOICE_EMAIL_SUCCESS = 'transaction/sendInvoiceEmail/success';
const SEND_INVOICE_EMAIL_FAILURE = 'transaction/sendInvoiceEmail/failure';

const UPDATE_TRANSACTION_REQUEST = 'transaction/updateTransaction/request';
const UPDATE_TRANSACTION_SUCCESS = 'transaction/updateTransaction/success';
const UPDATE_TRANSACTION_FAILURE = 'transaction/updateTransaction/failure';

const REDIRECT_FREE_PRODUCT = 'redirect/freeProduct';

/// To process free product transaction
const PROCESS_FREE_PRODUCT_TRANSACTION_REQUEST =
  'transaction/processFreeProductTransaction/request';
const PROCESS_FREE_PRODUCT_TRANSACTION_SUCCESS =
  'transaction/processFreeProductTransaction/success';
const PROCESS_FREE_PRODUCT_TRANSACTION_FAILURE =
  'transaction/processFreeProductTransaction/failure';

/// To check if the user has already purchased this product
const TO_CHECK_PRODUCT_ALREADY_PURCHASED_REQUEST =
  'transaction/checkProductAlreadyPurchased/request';
const TO_CHECK_PRODUCT_ALREADY_PURCHASED_SUCCESS =
  'transaction/checkProductAlreadyPurchased/success';
const TO_CHECK_PRODUCT_ALREADY_PURCHASED_FAILURE =
  'transaction/checkProductAlreadyPurchased/failure';

const INITIAL_STATE = {
  transactionIsCreating: false,
  transactionLicenseIsUpdating: false,
  transactionIsFetching: false,
  transactionData: {},
  paymentData: {},
  tokenIsFetching: false,
  token: null,
  userIPAddress: null /* The user IP address to inform in the 'threeDSecure' object of Braintree. */,
  transactionListIsFetching: true,
  transactionList: null,
  invoiceRegenerating: false,
  error: null,
  paymentError: null,
  skip: 0,
  limit: 10,
  count: 0,
  sort: { sortField: 'createdAt', sortOrder: -1 },
  isSendingInvoiceEmail: false,
  isProcessingFreeProductTransaction: false,
  isCheckingProductAlreadyPurchased: false,
  isProcessingUpdateTransaction: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION_REQUEST:
      return { ...state, transactionIsCreating: true };
    case CREATE_TRANSACTION_SUCCESS:
      window.sessionStorage.setItem(
        'transactionEmail',
        action.payload.customer.email
      );
      return {
        ...state,
        transactionIsCreating: false,
        transactionData: action.payload.transaction,
        paymentData: action.payload.payment
      };
    case CREATE_TRANSACTION_FAILURE:
      return { ...state, transactionIsCreating: false, error: action.payload };

    case GET_TRANSACTION_REQUEST:
      return {
        ...state,
        transactionIsFetching: true,
        error: null
      };
    case GET_TRANSACTION_SUCCESS:
      window.sessionStorage.setItem(
        'transactionEmail',
        action.payload.transaction.customer[0].email
      );
      return {
        ...state,
        error: null,
        transactionIsFetching: false,
        transactionData: action.payload.transaction
      };
    case GET_TRANSACTION_FAILURE:
      window.sessionStorage.removeItem('transactionEmail');
      return { ...state, transactionIsFetching: false, error: action.payload };

    case GET_TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        ...action.payload
      };
    case GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionListIsFetching: false,
        transactionList: action.payload.transactions,
        count: action.payload.count
      };
    case GET_TRANSACTION_LIST_FAILURE:
      return { ...state, transactionListIsFetching: false };

    case GET_TOKEN_REQUEST:
      return { ...state, tokenIsFetching: true };
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        tokenIsFetching: false,
        token: action.payload.token,
        userIPAddress: action.payload.userIPAddress
      };
    case GET_TOKEN_FAILURE:
      return { ...state, tokenIsFetching: false };

    case PAYMENT_REQUEST:
      return {
        ...state,
        transactionIsCreating: true,
        paymentError: null,
        error: null
      };
    case PAYMENT_SUCCESS:
      return { ...state, transactionIsCreating: false, data: action.payload };
    case PAYMENT_FAILURE:
      return {
        ...state,
        transactionIsCreating: false,
        paymentError: action.payload
      };

    case REGENERATE_INVOICE_REQUEST:
      return { ...state, invoiceRegenerating: true };
    case REGENERATE_INVOICE_SUCCESS:
      return { ...state, invoiceRegenerating: false };
    case REGENERATE_INVOICE_FAILURE:
      return { ...state, invoiceRegenerating: false };

    case LICENSE_CREATED_REQUEST:
      return { ...state, transactionLicenseIsUpdating: true };
    case LICENSE_CREATED_SUCCESS:
      return { ...state, transactionLicenseIsUpdating: false };
    case LICENSE_CREATED_FAILURE:
      return { ...state, transactionLicenseIsUpdating: false };

    case RESET_TRANSACTION_REQUEST:
      return {
        ...state,
        sort: {
          sortField: 'createdAt',
          sortOrder: -1
        }
      };

    case SEND_INVOICE_EMAIL_REQUEST:
      return { ...state, isSendingInvoiceEmail: true };
    case SEND_INVOICE_EMAIL_SUCCESS:
      return { ...state, isSendingInvoiceEmail: false };
    case SEND_INVOICE_EMAIL_FAILURE:
      return { ...state, isSendingInvoiceEmail: false };
    case PROCESS_FREE_PRODUCT_TRANSACTION_REQUEST:
      return { ...state, isProcessingFreeProductTransaction: true };
    case PROCESS_FREE_PRODUCT_TRANSACTION_SUCCESS:
      return { ...state, isProcessingFreeProductTransaction: false };
    case PROCESS_FREE_PRODUCT_TRANSACTION_FAILURE:
      return { ...state, isProcessingFreeProductTransaction: false };
    case REDIRECT_FREE_PRODUCT:
      window.location.href = `${RouteName.transactionRoute}/${action.payload.transactionID}`;
      return { ...state };
    case TO_CHECK_PRODUCT_ALREADY_PURCHASED_REQUEST:
      return {
        ...state,
        isCheckingProductAlreadyPurchased: true
      };
    case TO_CHECK_PRODUCT_ALREADY_PURCHASED_SUCCESS:
      return {
        ...state,
        isCheckingProductAlreadyPurchased: false
      };
    case TO_CHECK_PRODUCT_ALREADY_PURCHASED_FAILURE:
      return {
        ...state,
        isCheckingProductAlreadyPurchased: false
      };
    case UPDATE_TRANSACTION_REQUEST:
      return {
        ...state,
        isProcessingUpdateTransaction: true
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isProcessingUpdateTransaction: false
      };
    case UPDATE_TRANSACTION_FAILURE:
      return {
        ...state,
        isProcessingUpdateTransaction: false
      };
    default:
      return state;
  }
};

const createTransactionRequest = () => ({
  type: CREATE_TRANSACTION_REQUEST
});

const createTransactionSuccess = (transaction, customer, payment) => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload: {
    transaction,
    customer,
    payment
  }
});

const createTransactionFailure = (error) => ({
  type: CREATE_TRANSACTION_FAILURE,
  payload: error
});

const getTransactionRequest = () => ({
  type: GET_TRANSACTION_REQUEST
});

const getTransactionSuccess = (transaction) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: transaction
});

const getTransactionFailure = (error) => ({
  type: GET_TRANSACTION_FAILURE,
  payload: error
});

const getTokenRequest = () => ({
  type: GET_TOKEN_REQUEST
});

const getTokenSuccess = (token) => ({
  type: GET_TOKEN_SUCCESS,
  payload: token
});

const getTokenFailure = () => ({
  type: GET_TOKEN_FAILURE
});

const resetTransactionRequest = () => ({
  type: RESET_TRANSACTION_REQUEST
});

const getTransactionListRequest = (skip, limit, searchTerm, sort) => {
  const payload = { skip, limit, transactionListIsFetching: true };
  if (searchTerm) {
    payload.searchTerm = searchTerm;
  }
  payload.sort = sort;
  return {
    type: GET_TRANSACTION_LIST_REQUEST,
    payload
  };
};

const getTransactionListSuccess = (transactions, count) => ({
  type: GET_TRANSACTION_LIST_SUCCESS,
  payload: { transactions, count }
});

const getTransactionListFailure = () => ({
  type: GET_TRANSACTION_LIST_FAILURE
});

const regenerateInvoiceRequest = () => ({
  type: REGENERATE_INVOICE_REQUEST
});

const regenerateInvoiceSuccess = () => ({
  type: REGENERATE_INVOICE_SUCCESS
});

const regenerateInvoiceFailure = () => ({
  type: REGENERATE_INVOICE_FAILURE
});

const licenseCreatedRequest = () => ({
  type: LICENSE_CREATED_REQUEST
});

const licenseCreatedSuccess = () => ({
  type: LICENSE_CREATED_SUCCESS
});

const licenseCreatedFailure = () => ({
  type: LICENSE_CREATED_FAILURE
});

const sendInvoiceEmailRequest = () => ({
  type: SEND_INVOICE_EMAIL_REQUEST
});

const sendInvoiceEmailSuccess = () => ({
  type: SEND_INVOICE_EMAIL_SUCCESS
});

const sendInvoiceEmailFailure = () => ({
  type: SEND_INVOICE_EMAIL_FAILURE
});

/// To process free product transaction
const processFreeProductTransactionRequest = () => ({
  type: PROCESS_FREE_PRODUCT_TRANSACTION_REQUEST
});

const processFreeProductTransactionSuccess = () => ({
  type: PROCESS_FREE_PRODUCT_TRANSACTION_SUCCESS
});

const processFreeProductTransactionFailure = () => ({
  type: PROCESS_FREE_PRODUCT_TRANSACTION_FAILURE
});

const redirectFreeProductSuccess = (transactionID: string) => ({
  type: REDIRECT_FREE_PRODUCT,
  payload: { transactionID }
});

/// To check if the user has already purchased this product
const toCheckProductAlreadyPurchasedRequest = () => ({
  type: TO_CHECK_PRODUCT_ALREADY_PURCHASED_REQUEST
});

const toCheckProductAlreadyPurchasedSuccess = () => ({
  type: TO_CHECK_PRODUCT_ALREADY_PURCHASED_SUCCESS
});

const toCheckProductAlreadyPurchasedFailure = () => ({
  type: TO_CHECK_PRODUCT_ALREADY_PURCHASED_FAILURE
});

const updateTransactionRequest = () => ({
  type: UPDATE_TRANSACTION_REQUEST
});

const updateTransactionSuccess = () => ({
  type: UPDATE_TRANSACTION_SUCCESS
});

const updateTransactionFailure = () => ({
  type: UPDATE_TRANSACTION_FAILURE
});

export const getTransactionList = (
  skip,
  limit,
  searchTerm,
  sort,
  filter?: Record<string, any>
) => async (dispatch) => {
  try {
    const isAdmin = LocalStorage.getAdmin(); /**< User isAdmin or not */
    dispatch(getTransactionListRequest(skip, limit, searchTerm, sort));
    const response = await asyncErrorHandler(
      getTransactions(skip, limit, searchTerm, sort, isAdmin, filter ?? {})
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getTransactionListFailure());
    } else {
      dispatch(
        getTransactionListSuccess(response.transactions, response.count)
      );
    }
  } catch (e) {
    dispatch(getTransactionListFailure());
    handleError({ message: e.message });
  }
};

export const createTransaction = (
  billingData,
  paymentData,
  products,
  price,
  discountCode,
  history,
  analytics,
  type,
  successCallBack
) => async (dispatch, getState: any) => {
  try {
    dispatch(createTransactionRequest());
    const userUUID:
      | string
      | null = LocalStorage.getXUserUUID(); /**< UserUUID. */
    const username:
      | string
      | null = LocalStorage.getUserName(); /**< UserName. */

    const response = await asyncErrorHandler(
      relayNonce({
        ...billingData,
        paymentMethodNonce: paymentData?.nonce,
        products,
        price,
        discountCode,
        type,
        userUUID: !isStringEmptyOrNullOrUndefined(billingData.userUUID)
          ? billingData.userUUID
          : userUUID,
        username: !isStringEmptyOrNullOrUndefined(billingData.username)
          ? billingData.username
          : username
      })
    );

    if (response.error) {
      return dispatch(createTransactionFailure(response.error));
    }

    dispatch(
      createTransactionSuccess(
        response.transaction,
        response.customer,
        response.payment
      )
    );
    const isFreeProduct: boolean =
      products[0] &&
      isUniqueProduct(products[0]); /**< Product is free or not */

    if (type === 'manual') {
      //Note: If the purchased product is free, update the transaction status to settled, send an order confirmation email, and create a license immediately
      if (isFreeProduct) {
        await asyncErrorHandler(
          dispatch(
            processFreeProductTransaction(response.transaction._id, true)
          )
        );
      } else {
        showSuccessToast(
          'Transaction created successfully, sending the invoice.'
        );

        await asyncErrorHandler(
          dispatch(sendInvoiceEmail(response.transaction._id, true))
        ); /**< To send an Invoice email. */
      }
      const { transactions } = getState();
      const { skip, limit, sort } = transactions; /**< Pagination parameters. */

      dispatch(getTransactionList(skip, limit, '', sort));
      successCallBack(response);
    } else {
      //Note: If the purchased product is free, update the transaction status to settled, send an order confirmation email, and create a license immediately
      if (isFreeProduct) {
        await asyncErrorHandler(
          dispatch(
            processFreeProductTransaction(response.transaction._id, false)
          )
        );
      } else {
        showSuccessToast(
          'Transaction created successfully, sending the invoice.'
        );

        await asyncErrorHandler(
          dispatch(sendInvoiceEmail(response.transaction._id, true))
        ); /**< To send an Invoice email. */

        history.push(
          `${RouteName.transactionRoute}/${response.transaction._id}`
        );
      }

      analytics.track(track.PURCHASE_SUCCESSFUL.name, {
        description: track.PURCHASE_SUCCESSFUL.description,
        product: products[0].id
      });
    }
  } catch (error) {
    dispatch(createTransactionFailure(error));
  }
};

export const resetTransaction = () => async (dispatch) => {
  try {
    dispatch(resetTransactionRequest());
  } catch (e) {
    dispatch(
      getTransactionFailure(
        'Clearing the transaction request was not possible.'
      )
    );
  }
};

export const getTransaction = (transactionId, email) => async (dispatch) => {
  try {
    dispatch(getTransactionRequest());
    const response = await asyncErrorHandler(
      getTransactionCall(transactionId, email)
    );
    if (response.error) {
      handleError({
        message: response.error.message || 'Transaction or email not found'
      });
      dispatch(getTransactionFailure(response.error.message));
    } else {
      dispatch(getTransactionSuccess(response));
      dispatch(fetchOneProduct(response.transaction.products[0].product._id));
    }
  } catch (e) {
    dispatch(getTransactionFailure(e.message));
  }
};

export const getToken = () => async (dispatch) => {
  try {
    dispatch(getTokenRequest());
    const response = await asyncErrorHandler(getTokenCall());
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getTokenFailure());
    } else {
      dispatch(getTokenSuccess(response));
    }
  } catch (e) {
    dispatch(getTokenFailure());
    handleError({ message: e.message });
  }
};

const paymentRequest = () => {
  return { type: PAYMENT_REQUEST };
};

const paymentFailure = (error) => {
  return { type: PAYMENT_FAILURE, payload: error };
};

export const handlePayment = (
  checkPaymentMethod,
  priceCalculation,
  billingInfo,
  product,
  productCount,
  options,
  discountCode,
  history,
  analytics,
  type,
  successCallBack = () => {}
) => async (dispatch) => {
  try {
    dispatch(paymentRequest());
    let paymentPayload;

    if (product && !product.isPaymentSectionHidden) {
      paymentPayload = await asyncErrorHandler(checkPaymentMethod());
    }

    const productsToPurchase = [
      {
        id: product._id,
        quantity: productCount,
        options,
        subscriptionPlanID: product.subscriptionPlanID,
        duration: product.duration,
        price: product.price,
        type: product.type,
        isPaymentSectionHidden: product.isPaymentSectionHidden,
        isDisabled: product.isDisabled,
        isCustomerDetailsSectionHidden: product.isCustomerDetailsSectionHidden
      }
    ];

    dispatch(
      createTransaction(
        billingInfo,
        paymentPayload,
        productsToPurchase,
        priceCalculation.price,
        discountCode,
        history,
        analytics,
        type,
        successCallBack
      )
    );
  } catch (e) {
    dispatch(paymentFailure(e.message));
  }
};

export const regenerateInvoice = (email, transactionId) => async (dispatch) => {
  try {
    dispatch(regenerateInvoiceRequest);
    const hashedCustomerEmail = CryptoJS.SHA256(email).toString(
      CryptoJS.enc.Hex
    );
    const response = await asyncErrorHandler(
      regenerateInvoiceCall(hashedCustomerEmail, transactionId)
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(regenerateInvoiceFailure());
    } else {
      dispatch(regenerateInvoiceSuccess());
      showSuccessToast('Invoice regenerated successfully');
    }
  } catch (e) {
    dispatch(regenerateInvoiceFailure());
    if (e.error) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const licenseCreated = (transactionId) => async (dispatch) => {
  try {
    dispatch(licenseCreatedRequest);
    const response = await asyncErrorHandler(
      markAsLicenseCreated(transactionId)
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(licenseCreatedFailure());
    } else {
      dispatch(licenseCreatedSuccess());
      showSuccessToast('Transaction has been updated successfully');
    }
  } catch (e) {
    dispatch(regenerateInvoiceFailure());
    if (e.error) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

/**
 * Update customer transaction billing details
 * @param {*} transactionID
 * @param {*} payload billing details billingCompanyName, billingAddress1, billingCity,
  billingZipcode, billingStateOrProvince, billingCountry, billingAddress2
 * @returns
 */
export const updateBillingDetails = (transactionID, payload) => async (
  dispatch,
  getState
) => {
  try {
    const response = await asyncErrorHandler(
      updateBillingDetailsAPI(transactionID, payload)
    );
    if (response.error) {
      handleError({ message: response.error.message });
    }

    showSuccessToast(
      translate('messages.update_billing_details', {
        invoiceNo: response.data.invoiceNo,
        customerFirstName: response.data.customerFirstName,
        customerLastName: response.data.customerLastName
      })
    );
  } catch (exception) {
    handleError({ message: exception.message });
  }
};

/**
 * Send an Invoice Email to Customer
 * @param transactionID
 * @param isShowToast
 */
export const sendInvoiceEmail = (
  transactionID: string,
  isShowToast: boolean
) => async (dispatch: any) => {
  try {
    dispatch(sendInvoiceEmailRequest());
    const response = await asyncErrorHandler(sendInvoice(transactionID));

    if (response.error) {
      dispatch(sendInvoiceEmailFailure());
      if (isShowToast) {
        handleError({
          message: response.error.message || 'Failed to send invoice'
        });
      }
    } else {
      dispatch(sendInvoiceEmailSuccess());
      if (isShowToast) {
        showSuccessToast(response.message || 'Invoice email sent to customer');
      }
    }
  } catch (error) {
    dispatch(sendInvoiceEmailFailure());
    if (isShowToast) {
      handleError({ message: error.message || 'Failed to send invoice' });
    }
  }
};

/**
 * Process the free product transaction
 * @param transactionID
 */
export const processFreeProductTransaction = (
  transactionID: string,
  isManual: boolean
) => async (dispatch: any) => {
  try {
    dispatch(processFreeProductTransactionRequest());
    const response = await asyncErrorHandler(
      processFreeProductTransactionAPI(transactionID)
    );

    if (response.error) {
      dispatch(processFreeProductTransactionFailure());
      handleError({ message: response.error.message });
    } else {
      showSuccessToast(
        'Transaction created successfully, sending the invoice.'
      );

      await asyncErrorHandler(dispatch(processFreeProductTransactionSuccess()));

      await asyncErrorHandler(
        dispatch(sendInvoiceEmail(transactionID, true))
      ); /**< To send an Invoice email. */

      //NOTE: Redirect user to transaction summary page if the purchase is made from Shop's page.
      if (!isManual)
        await asyncErrorHandler(
          dispatch(redirectFreeProductSuccess(transactionID))
        );
    }
  } catch (error) {
    dispatch(processFreeProductTransactionFailure());
  }
};

/**
 * To check if the user has already purchased this product
 * @param productID
 * @param userUUID
 * @returns
 */
export const toCheckProductAlreadyPurchased = (
  productID: string,
  userUUID: string
) => async (dispatch: any) => {
  try {
    dispatch(toCheckProductAlreadyPurchasedRequest());
    const response: IAPIEntityResponse<any> = await asyncErrorHandler(
      toCheckProductAlreadyPurchasedAPI(productID, userUUID)
    );

    if (response.error) {
      dispatch(toCheckProductAlreadyPurchasedFailure());
    } else {
      dispatch(toCheckProductAlreadyPurchasedSuccess());
    }
    return response;
  } catch (error) {
    dispatch(toCheckProductAlreadyPurchasedFailure());
    return false;
  }
};

/**
 * Update a transaction based on its ID.
 * @param transactionID
 * @param isShowToast
 */
export const updateTransactionAction = (
  transactionID: string /* Transaction ID to be updated. */,
  transactionList: any[] /* Current transaction cached list to be updated. The type must be any for now because we don't have a shared interface for it. TODO: Replace the any[] interface to ITransaction from AWC. */,
  count: number /* Current transaction cached list count to be updated. */
) => async (dispatch) => {
  const errorMessage: string =
    'The attempt to change the transaction status to Settled has failed.';

  try {
    dispatch(updateTransactionRequest());

    const result: IAPIEntityResponse<string> = await asyncErrorHandler(
      updateTransaction(transactionID)
    );

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(updateTransactionFailure());
    } else {
      dispatch(updateTransactionSuccess());

      // NOTE: Update cached transaction.
      const updatedTransactionList: any[] = transactionList.map(
        (transaction: any) => {
          if (transaction._id === transactionID) {
            const newTransaction: any = { ...transaction };
            newTransaction.status = TransactionStatus.Settled;
            newTransaction.transactionFulfilled = true;

            return newTransaction;
          }
          return transaction;
        }
      );

      dispatch(getTransactionListSuccess(updatedTransactionList, count));
      showSuccessToast(result.message as string);
    }
  } catch (error) {
    dispatch(updateTransactionByIDFailure());
    handleError({ message: errorMessage });
  }
};
