/*
 * SubscriptionsForm.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file SubscriptionsForm.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {useEffect, useState} from 'react';
import {ISubscription} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import {withPaymentProvider} from '../../Shop/Checkout/WithPaymentProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FieldSkeleton from '@abstract/abstractwebcommon-client/SkeletonLoader/FieldSkeleton/FieldSkeleton';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

/**
 * @interface ISubscriptionFormProperties
 */
interface ISubscriptionFormProperties {
  editSubscription:
    | ISubscription
    | null
    | undefined /**< Edit subscription data */;
  onHide: () => void /**< Handler for dialog hide */;
  paymentWrapperRef?: any /**< Payment wrapper reference */;
  paymentWidgetReady?: boolean /**< Payment widget ready */;
  checkPaymentMethod?: any /**< Check payment method */;
  handleUpdate: (
    subscriptionID: string,
    paymentMethodNonce: string
  ) => void /**< Handle subscription payment method update functionality. */;
}

const SubscriptionsForm = (
  properties: ISubscriptionFormProperties
): JSX.Element => {
  const [isSaveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  // Style Choose another way to pay span button inside Braintree Drop in Component.
  // If we update Braintree drop in NPM version, we should look if anything broke here.
  useEffect(() => {
    // wrapperRef.current?.children[0]?.children[1]?.children[6] is the path for the required element.
    if (
      properties.paymentWrapperRef.current?.children[0]?.children[1]
        ?.children[6]
    ) {
      const chooseAnotherWayToPaySpanHTML: HTMLDivElement =
        properties.paymentWrapperRef.current?.children[0]?.children[1]
          ?.children[6];

      chooseAnotherWayToPaySpanHTML.classList.add('btn');
      chooseAnotherWayToPaySpanHTML.classList.add('btn-primary');
      chooseAnotherWayToPaySpanHTML.classList.add('mt-3');
      chooseAnotherWayToPaySpanHTML.classList.add(
        'custom-choose-another-way-to-pay-button-style'
      );
      chooseAnotherWayToPaySpanHTML.classList.add('justify-content-center');
    }
  }, [
    properties.paymentWrapperRef.current?.children[0]?.children[1]?.children[6]
  ]);

  const handleSave = async () => {
    try {
      if (properties.editSubscription) {
        const payload = await asyncErrorHandler(properties.checkPaymentMethod(
            properties.editSubscription.price,
            properties.editSubscription.customer
        ));

        if (payload) {
          setSaveButtonLoading(true);
            await asyncErrorHandler(properties.handleUpdate(
                properties.editSubscription._id as string,
                payload.nonce
            ));
        }
      }

      properties.onHide();
      setSaveButtonLoading(false);
    } catch (error) {
      setSaveButtonLoading(false);
    }
  };

  return (
    <FormWrapper
      controlButtonLabel={false}
      isLoading={isSaveButtonLoading}
      handleSubmitButton={() => handleSave()}
    >
      <Row className="mb-2">
        <Col className="h-auto">
          <div
            ref={properties.paymentWrapperRef}
            className={properties.paymentWidgetReady ? 'd-block' : 'd-none'}
          />
          {!properties.paymentWidgetReady && (
            <FieldSkeleton skeletonHeightCustomValue={146} />
          )}
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default withPaymentProvider(SubscriptionsForm);
