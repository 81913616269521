/*
 * WithPaymentProvider.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file WithPaymentProvider.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BraintreeWebDropIn, { Dropin } from 'braintree-web-drop-in';
import { getToken } from '../../../Store/Transactions';
import { sendFrontendError } from '../../../Services/FrontendError';
import { customAlphabet } from 'nanoid';
import { fetchSettings } from '../../../Store/ShopSettings';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';

export const withPaymentProvider = (component) => (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.transactions.token);
  const sharedSubscriptions = useSelector(
    (state) => state.sharedSubscriptions
  ); /**< Shared subscription state */
  const transactionState = useSelector(
    (state) => state.transactions
  ); /**< Transaction state */
  const paymentWrapperRef = useRef();
  const [widgetInstance, setWidgetInstance] = useState<Dropin>(null);

  const [paymentWidgetReady, setPaymentWidgetReady] = useState(false);
  const [frontendPaymentError, setFrontendPaymentError] = useState(null);

  const createPaymentWidgetPromise = () => {
    return new Promise((resolve, reject) => {
      BraintreeWebDropIn.create(
        {
          authorization: token,
          selector: paymentWrapperRef.current,
          card: {
            cardholderName: {
              required: true
            },
            overrides: {
              fields: {
                cvv: true,
                expirationDate: {
                  prefill:
                    sharedSubscriptions?.subscriptionPaymentMethod
                      ?.expirationDate
                },
                number: {
                  placeholder:
                    sharedSubscriptions?.subscriptionPaymentMethod
                      ?.maskedNumber ?? '•••• •••• •••• ••••'
                }
              },
              styles: {
                input: {
                  color:
                    LocalStorage.getThemeMode() === ThemeMode.darkMode
                      ? '#CCC'
                      : '#000000'
                },
                'input:focus': {
                  color:
                    LocalStorage.getThemeMode() === ThemeMode.darkMode
                      ? '#CCC'
                      : '#000000'
                }
              }
            }
          },
          paypal: {
            flow: 'vault',
            currency: 'USD'
          },
          threeDSecure: true,
          version: 2
        },
        (err, widgetInstance) => {
          if (err) {
            reject(err);
          }
          resolve(widgetInstance);
        }
      );
    });
  };

  const createWidget = () => {
    if (token && paymentWrapperRef.current) {
      createPaymentWidgetPromise(token, paymentWrapperRef.current)
        .then((widgetInstance) => {
          setPaymentWidgetReady(true);
          // save widget instance in state variable
          setWidgetInstance(widgetInstance);
          // state variable cannot be used for cleanup - assign temp variable
          return widgetInstance;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const destroyWidget = () => {
    if (widgetInstance && widgetInstance.teardown) {
      widgetInstance.teardown();
    }
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  useEffect(() => {
    dispatch(getToken());
  }, [getToken]);

  useEffect(() => {
    if (paymentWrapperRef.current == null || token == null) return () => {};

    createWidget();

    return () => {
      // required for clean up the braintree widget
      destroyWidget();
    };
  }, [token, paymentWrapperRef]);

  const checkPaymentMethod = (price, customerDetails) => {
    setFrontendPaymentError(null);
    return new Promise((resolve, reject) => {
      const threeDSecureDetails = {
        challengeRequested: true,
        collectDeviceData: true,
        additionalInformation: {
          ipAddress: transactionState.userIPAddress
        },
        amount: price,
        email: customerDetails.billingEmail,
        billingAddress: {
          givenName: customerDetails.billingFirstName.substring(0, 50),
          surname: customerDetails.billingLastName.substring(0, 50),
          streetAddress: customerDetails.billingAddress1.substring(0, 50),
          extendedAddress: customerDetails.billingAddress2.substring(0, 50),
          postalCode: customerDetails.billingZipcode.substring(0, 50),
          countryCodeAlpha2: customerDetails.billingCountry.substring(0, 50)
        }
      };

      widgetInstance.requestPaymentMethod(
        {
          threeDSecure: threeDSecureDetails
        },
        (err, payload) => {
          if (err) {
            if (err.name !== 'BraintreeError') {
              reject(err);
            } else {
              const nanoid = customAlphabet('1234567890abcdef', 8);
              const errorId = nanoid();
              setFrontendPaymentError({ error: err, errorId });
              console.log('Payment error', err, errorId);
              err.threeDSecureDetails = threeDSecureDetails;
              sendFrontendError(errorId, err).finally(() => reject(err));
            }
          } else {
            setFrontendPaymentError(null);
            resolve(payload);
          }
        }
      );
    });
  };

  return component({
    paymentWrapperRef,
    paymentWidgetReady,
    widgetInstance,
    createWidget,
    destroyWidget,
    checkPaymentMethod,
    frontendPaymentError,
    ...props
  });
};
