/*
 * NextStepButtonForm.tsx (AbstractECommerce)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file NextStepButtonForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React from 'react';
import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FieldArray } from 'formik';
import { IApplicationTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ApplicationTranslation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import Form from 'react-bootstrap/Form';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import Button from 'react-bootstrap/Button';
import { INextStepButton } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';

/**
 * @interface INextStepButtonFormProperties
 */
interface INextStepButtonFormProperties {
  values: INextStepButton[];
  errors: any;
  touched: any;
  handleChange: (event: any) => void;
  handleBlur: (event: any) => void;
  isLoading: boolean;
  setFieldValue: any;
  selectedLanguage: string /**< Selected language */;
  translationData:
    | IApplicationTranslation[]
    | undefined /**< Application translation data */;
  translationErrors: any /**< Application translation errors */;
  translationTouched: any /**< Application translation touched */;
  selectedLanguageTranslationIndex: number /**< Selected language translation data index */;
  englishTranslationIndex: number /**< English translation data index */;
}

const NextStepButtonForm = (
  properties: INextStepButtonFormProperties
): JSX.Element => {
  const translation: TFunction = useTranslation().t;

  // Change the order of nextbutton object array
  const changeArrayOrder = (from: number, to: number): void => {
    const newArray: INextStepButton[] = [...properties.values];
    newArray?.splice(to, 0, newArray?.splice(from, 1)[0]);
    properties.setFieldValue('nextStepTextButtons', newArray);
    for (
      let translationIndex = 0;
      translationIndex < properties.translationData.length;
      translationIndex++
    ) {
      const nextStepButtonsTextArray: string[] = [
        ...properties.translationData[translationIndex].nextStepButtonsText
      ];
      nextStepButtonsTextArray?.splice(
        to,
        0,
        nextStepButtonsTextArray?.splice(from, 1)[0]
      );
      properties.setFieldValue(
        `translationData.${translationIndex}.nextStepButtonsText`,
        nextStepButtonsTextArray
      );
    }
  };

  const renderNextStepButtonList = (formikArray) =>
    properties.values.map(
      (nextStepTextButtons: INextStepButton, index: number) => (
        <Row key={index}>
          <div className="w-100 d-flex mt-2">
            <Col>
              <Button
                className={`p-button-raised p-button-primary float-right ${
                  index === properties.values.length - 1 ? 'd-none' : ''
                }`}
                onClick={() => {
                  // Change the order of nextbutton object array
                  changeArrayOrder(index, index + 1);
                }}
              >
                <i className="bi bi-arrow-down btn-icon mr-0"></i>
              </Button>
              <Button
                className={`p-button-raised p-button-primary float-right mr-2 ${
                  index === 0 ? 'd-none' : ''
                }`}
                onClick={() => {
                  // Change the order of nextbutton object array
                  changeArrayOrder(index, index - 1);
                }}
              >
                <i className="bi bi-arrow-up btn-icon mr-0"></i>
              </Button>
              <Button
                className="p-button-raised p-button-primary float-right mr-2"
                onClick={() => {
                  formikArray.remove(index);
                  // Remove corresponding translations
                  if (
                    properties.translationData &&
                    properties.translationData.length
                  ) {
                    for (
                      let translationIndex = 0;
                      translationIndex < properties.translationData.length;
                      translationIndex++
                    ) {
                      const newArray = properties.translationData[
                        translationIndex
                      ].nextStepButtonsText?.filter(
                        (buttonText: string, buttonIndex: number) =>
                          buttonIndex !== index
                      );
                      properties.setFieldValue(
                        `translationData.${translationIndex}.nextStepButtonsText`,
                        newArray
                      );
                    }
                  }
                }}
              >
                <i className="bi bi-dash btn-icon mr-0"></i>
              </Button>
            </Col>
          </div>
          <Col>
            <Row>
              <Form.Group as={Col} sm="6">
                <InstaInputText
                  label={translation(
                    'application.fields.nextStepButton.fields.buttonText'
                  )}
                  name={`translationData.${properties.selectedLanguageTranslationIndex}.nextStepButtonsText.${index}`}
                  id={`translationData.${properties.selectedLanguageTranslationIndex}.nextStepButtonsText.${index}`}
                  isLoading={properties.isLoading}
                  onChange={properties.handleChange}
                  onBlur={properties.handleBlur}
                  value={
                    (properties.translationData &&
                      properties.translationData.length &&
                      properties.translationData[
                        properties.selectedLanguageTranslationIndex
                      ] &&
                      properties.translationData[
                        properties.selectedLanguageTranslationIndex
                      ].nextStepButtonsText?.[index]) ??
                    ''
                  }
                  touched={
                    properties.translationTouched &&
                    properties.translationTouched.length &&
                    properties.translationTouched[
                      properties.selectedLanguageTranslationIndex
                    ] &&
                    properties.translationTouched[
                      properties.selectedLanguageTranslationIndex
                    ].nextStepButtonsText?.[index]
                  }
                  errors={
                    properties.translationErrors &&
                    properties.translationErrors.length &&
                    properties.translationErrors[
                      properties.selectedLanguageTranslationIndex
                    ] &&
                    properties.translationErrors[
                      properties.selectedLanguageTranslationIndex
                    ].nextStepButtonsText?.[index]
                  }
                  labelClassName={
                    properties.selectedLanguage === LanguageSettingsMode.english
                      ? 'required'
                      : ''
                  }
                />
              </Form.Group>
              <Form.Group as={Col} sm="6">
                <InstaInputText
                  label={translation(
                    'application.fields.nextStepButton.fields.buttonURL'
                  )}
                  name={`nextStepTextButtons.${index}.buttonURL`}
                  id={`nextStepTextButtons.${index}.buttonURL`}
                  isLoading={properties.isLoading}
                  onChange={properties.handleChange}
                  onBlur={properties.handleBlur}
                  value={
                    properties.values &&
                    properties.values[index] &&
                    properties.values[index].buttonURL
                  }
                  touched={
                    properties.touched &&
                    properties.touched[index] &&
                    properties.touched[index].buttonURL
                  }
                  errors={
                    properties.errors &&
                    properties.errors[index] &&
                    properties.errors[index].buttonURL
                  }
                  labelClassName="required"
                />
              </Form.Group>
              <Form.Group as={Col} sm="6">
                <InstaInputText
                  label={translation(
                    'application.fields.nextStepButton.fields.buttonIcon'
                  )}
                  name={`nextStepTextButtons.${index}.buttonIcon`}
                  id={`nextStepTextButtons.${index}.buttonIcon`}
                  isLoading={properties.isLoading}
                  onChange={properties.handleChange}
                  onBlur={properties.handleBlur}
                  value={
                    properties.values &&
                    properties.values[index] &&
                    properties.values[index].buttonIcon
                  }
                  touched={
                    properties.touched &&
                    properties.touched[index] &&
                    properties.touched[index].buttonIcon
                  }
                  errors={
                    properties.errors &&
                    properties.errors[index] &&
                    properties.errors[index].buttonIcon
                  }
                />
              </Form.Group>
              <Form.Group as={Col} sm="6">
                <InstaInputText
                  label={translation(
                    'application.fields.nextStepButton.fields.buttonCSS'
                  )}
                  name={`nextStepTextButtons.${index}.buttonCSS`}
                  id={`nextStepTextButtons.${index}.buttonCSS`}
                  isLoading={properties.isLoading}
                  onChange={properties.handleChange}
                  onBlur={properties.handleBlur}
                  value={
                    properties.values &&
                    properties.values[index] &&
                    properties.values[index].buttonCSS
                  }
                  touched={
                    properties.touched &&
                    properties.touched[index] &&
                    properties.touched[index].buttonCSS
                  }
                  errors={
                    properties.errors &&
                    properties.errors[index] &&
                    properties.errors[index].buttonCSS
                  }
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      )
    );

  return (
    <Panel
      header={translation('application.fields.nextStepButton.header')}
      className={`p-0 additional-options-panel`}
    >
      <FieldArray
        name="nextStepTextButtons"
        render={(formikArray) => (
          <>
            {renderNextStepButtonList(formikArray)}
            <Row className={!properties.errors ? 'd-none' : 'text-center'}>
              <Col sm={12}>
                {properties.errors &&
                  properties.errors.label &&
                  properties.errors.field &&
                  translation(properties.errors.label, {
                    field: properties.errors.field
                  })}
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <ActionButton
                onClick={() => {
                  const newNextStepButton: INextStepButton = {
                    buttonText: '',
                    buttonURL: '',
                    buttonIcon: '',
                    buttonCSS: ''
                  };

                  // Add buttonText in corresponding translations when adding formikarray
                  if (
                    properties.translationData &&
                    properties.translationData.length
                  ) {
                    for (
                      let translationIndex = 0;
                      translationIndex < properties.translationData.length;
                      translationIndex++
                    ) {
                      const newArray = properties.translationData[
                        translationIndex
                      ].nextStepButtonsText?.concat('');
                      properties.setFieldValue(
                        `translationData.${translationIndex}.nextStepButtonsText`,
                        newArray
                      );
                    }
                  }
                  formikArray.push(newNextStepButton);
                }}
              />
            </Row>
          </>
        )}
      />
    </Panel>
  );
};

export default React.memo(NextStepButtonForm);
