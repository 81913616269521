/*
 * currencyFormatter.ts (AbstractUser & AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file currencyFormatter.ts
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
*/

/**
 * Currency type options.
 */
export const currencyType = {
  USD: 'USD',
  DOLLAR: '$',
  NONE: 'none'
}

/**
 * Pattern to format.
 * @param value Value to be formatted.
 * @returns string.
 */
const patternToFormatValue = (value: number): string => Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

/**
 * Format a value as currency.
 * @param valueToFormat Value to be formatted.
 * @param currencyType ENUM options of currencyType ENUM.
 * @param displayCurrencyTypeAsFirstString Display the currency type symbol as first or last string. True to display as first and false to display as last character.
 * @returns string.
 */
export const currencyFormatter = (
  valueToFormat: number,
  displayCurrencyTypeAsFirstString: boolean = true,
  currencyTypeReceived: string = currencyType.DOLLAR
): string => {
  //Example 1: $ 20,000.00
  //Example 2: € 20,000.00
  if (displayCurrencyTypeAsFirstString) {
    return (`${currencyTypeReceived}${patternToFormatValue(valueToFormat)}`)
  }

  //Example: 20,000.00
  if (currencyTypeReceived === currencyType.NONE) {
    return patternToFormatValue(valueToFormat)
  }

  //Example 1: 20,000.00 USD
  //Example 2: 20,000.00 EUR
  return (`${patternToFormatValue(valueToFormat)} ${currencyTypeReceived}`)
}