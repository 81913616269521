/*
 * SettingsSMTPForm.tsx (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file SettingsSMTPForm.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

import InstaInputText from '../FormControl/InstaInputText';
import { DatabaseType } from '../../Shared/constants/connectionDatabaseType'
import FieldSkeleton, { controlInputClassName } from '../SkeletonLoader/FieldSkeleton/FieldSkeleton';
import PopupInformation from '../FormControl/PopupInformation';

/**
 * @Interface defining the React properties for the SettingsSMTPForm component.
 */
interface ISettingsSMTPFormProperties {
  databaseType: string /**< Database type: mySQL or mongoDB. */
  handleChange: any /**< On change handle. */
  handleBlur: any /**< On blur handle. */
  touched: Record<string, any> /**< Touched fields from formik. */
  errors: Record<string, any> /**< Errors fields from formik. */
  values: Record<string, any> /**< Values fields from formik. */
  isLoading?: boolean /**< Is loading. */
  handleOnChangeSecureCheckbox?: (e: CheckboxChangeEvent) => void /**< On change handle for Secure checkbox. Only for mySQL database */
  isFromUserProject?: boolean /**< Used to adjust the UI of User project */
}

/**
 * Settings SMTP Form component to be used in Settings page.
 * @param properties ISettingsSMTPFormProperties
 * @returns JSX.Element
 */
const SettingsSMTPForm = (properties: ISettingsSMTPFormProperties): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  
  const RenderSkeletonComponent = (): JSX.Element =>
    properties.isLoading && (
      <FieldSkeleton />
    );

  const changeInputClassName = (): string =>
    controlInputClassName(properties.isLoading);

  return (
    <>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.host')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_HOST' : 'smtpHost'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_HOST' : 'smtpHost'}
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.SMTP_HOST : properties.touched.smtpHost}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.SMTP_HOST : properties.errors.smtpHost}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.SMTP_HOST : properties.values?.smtpHost}
          labelClassName="required"
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.host_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.port')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_PORT' : 'smtpPort'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_PORT' : 'smtpPort'}
          type="number"
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.SMTP_PORT : properties.touched.smtpPort}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.SMTP_PORT : properties.errors.smtpPort}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.SMTP_PORT : properties.values?.smtpPort}
          labelClassName="required"
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.port_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
      <Form.Group as={Col} sm="12" className={`d-flex align-items-center ${properties.isFromUserProject ? 'pb-0' : ''}`}>
        <Checkbox
          onChange={properties.handleOnChangeSecureCheckbox}
          name={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_SECURE' : 'smtpSecure'}
          inputId={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_SECURE' : 'smtpSecure'}
          checked={properties.databaseType === DatabaseType.mongoDB ? properties.values?.SMTP_SECURE : properties.values?.smtpSecure}
        />
        &nbsp;&nbsp;
        <Form.Label htmlFor={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_SECURE' : 'smtpSecure'} className="mb-0">{translation('awc:/.smtp.secure')}</Form.Label>
        <PopupInformation 
          id={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_SECURE' : 'smtpSecure'}
          popupText={translation('awc:/.smtp.secure_popupText')}
        />
      </Form.Group>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.user')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_USER' : 'smtpUser'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_USER' : 'smtpUser'}
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.SMTP_USER : properties.touched.smtpUser}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.SMTP_USER : properties.errors.smtpUser}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.SMTP_USER : properties.values?.smtpUser}
          labelClassName="required"
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.user_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.password')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_PASS' : 'smtpPassword'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'SMTP_PASS' : 'smtpPassword'}
          type="password"
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.SMTP_PASS : properties.touched.smtpPassword}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.SMTP_PASS : properties.errors.smtpPassword}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.SMTP_PASS : properties.values?.smtpPassword}
          labelClassName="required"
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.password_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.from_email')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'FROM_EMAIL' : 'fromEmail'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'FROM_EMAIL' : 'fromEmail'}
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.FROM_EMAIL : properties.touched.fromEmail}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.FROM_EMAIL : properties.errors.fromEmail}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.FROM_EMAIL : properties.values?.fromEmail}
          labelClassName="required"
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.fromEmail_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
      <Form.Group as={Col} sm="12" className={properties.isFromUserProject ? 'pb-0' : ''}>
        <InstaInputText
          label={translation('awc:/.smtp.from_name')}
          name={properties.databaseType === DatabaseType.mongoDB ? 'FROM_NAME' : 'fromName'}
          id={properties.databaseType === DatabaseType.mongoDB ? 'FROM_NAME' : 'fromName'}
          isLoading={properties.isLoading ?? false}
          onChange={properties.handleChange}
          onBlur={properties.handleBlur}
          touched={properties.databaseType === DatabaseType.mongoDB ? properties.touched.FROM_NAME : properties.touched.fromName}
          errors={properties.databaseType === DatabaseType.mongoDB ? properties.errors.FROM_NAME : properties.errors.fromName}
          value={properties.databaseType === DatabaseType.mongoDB ? properties.values?.FROM_NAME : properties.values?.fromName}
          spanClassName={changeInputClassName()}
          isShowInformationPopup={true}
          popupText={translation('awc:/.smtp.fromName_popupText')}
        />
        {RenderSkeletonComponent()}
      </Form.Group>
    </>
  )
}

export default SettingsSMTPForm