/*
 * ConfirmationPopup.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file ConfirmationPopup.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import { Popover, Overlay, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./index.css";

export interface IConfirmPopupProperties {
  popupId?: string;
  isShow?: boolean;
  target?: any;
  rejectLabel?: string;
  rejectBtnIcon?: string;
  rejectBtnClass?: string;
  onReject?: any;
  acceptBtnIcon?: string;
  acceptLabel?: string;
  acceptBtnClass?: string;
  onAccept?: any;
  title?: string;
  ref?: any;
  popupPosition?: 'top' | 'right'| 'bottom'| 'left'; /**< Popup position. */
}

const confirmationPopupInitialValues: IConfirmPopupProperties = {
  popupId: "confirmationPopup",
  isShow: false,
  target: null,
  rejectLabel: "No",
  rejectBtnIcon: "pi pi-times",
  rejectBtnClass: "danger",
  acceptBtnIcon: "pi pi-check",
  acceptLabel: "Yes",
  acceptBtnClass: "primary",
  title: "Confirm",
  ref: null,
  popupPosition: "top"
};

const ConfirmationPopup = ({
  onAccept,
  onReject,
  ...properties
}: IConfirmPopupProperties): ReactElement => {
  /// Accept button click - Call onAccept from properties
  const onPopupAccept = (event: any) => {
    if (typeof onAccept !== "function") {
      return;
    }
    onAccept(event);
  };

  /// Reject button click - Call onAccept from properties
  const onPopupReject = (event: any) => {
    if (typeof onReject !== "function") {
      return;
    }
    onReject(event);
  };

  /// Get the popup content which contains accept and reject buttons
  const getPopupContent = () => {
    return (
      <>
        <Row className="px-2 d-flex justify-content-between align-items-center">
          <Col xs={6} className="p-0">
            <Button
              type="button"
              className="d-flex align-items-center float-left"
              variant={
                properties?.rejectBtnClass ||
                confirmationPopupInitialValues.rejectBtnClass
              }
              onClick={onPopupReject}
            >
              <i
                className={`btn-icon ${
                  properties?.rejectBtnIcon ||
                  confirmationPopupInitialValues.rejectBtnIcon
                }`}
              ></i>
              {properties?.rejectLabel || confirmationPopupInitialValues.rejectLabel}
            </Button>
          </Col> 
          <Col xs={6} className="p-0">
            <Button
              type="button"
              className="d-flex align-items-center float-right"
              variant={
                properties?.acceptBtnClass ||
                confirmationPopupInitialValues.acceptBtnClass
              }
              onClick={onPopupAccept}
            >
              <i
                className={`${
                  properties?.acceptBtnIcon ||
                  confirmationPopupInitialValues.acceptBtnIcon
                } btn-icon`}
              ></i>
              {properties?.acceptLabel || confirmationPopupInitialValues.acceptLabel}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Overlay
     show={properties?.isShow} 
     target={properties?.target} 
     placement={properties?.popupPosition || confirmationPopupInitialValues.popupPosition}
     rootClose
     onHide={onPopupReject}>
      <Popover
        id={`${properties?.popupId || confirmationPopupInitialValues.popupId}`}
        className="confirmationPopupContainer"
      >
        <Popover.Title>
          {properties?.title || confirmationPopupInitialValues.title}
        </Popover.Title>
        <Popover.Content>{getPopupContent()}</Popover.Content>
      </Popover>
    </Overlay>
  );
};

export default ConfirmationPopup;
