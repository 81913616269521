/*
 * EmailConfirmationForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file EmailConfirmationForm.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { getTransaction } from '../../../Store/Transactions';
import Button from 'react-bootstrap/Button';
import i18n from '../../../Services/I18n';
import './TransactionDetails.css';

/**
 * Interface for EmailConfirmationForm properties.
 */
interface IEmailConfirmationFormProperties {
  transactionId: any;
  loading: boolean;
}

const EmailConfirmationForm = ({
  transactionId,
  loading
}: IEmailConfirmationFormProperties) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('validation.email')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.customer.email')
          })
        )
    }),
    onSubmit: (values) => {
      dispatch(getTransaction(transactionId, values.email));
    }
  });

  return (
    <>
      <Row className="m-2">
        <Col sm={12} className="d-flex justify-content-center p-0">
          <p className="email-confirmation">
            {t('transactionSuccessPage.emailConfirmationRequired')}
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Form.Group as={Col} sm="12" md="4">
          <InstaInputText
            label=""
            name="email"
            id={'email'}
            isLoading={loading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            errors={formik.errors.email}
            value={formik.values.email}
            placeholder={t('fields.customer.email')}
          />
        </Form.Group>
        <Col sm={12} md={2} className="d-flex email-confirm align-items-start">
          <Button
            className="p-button p-button-primary btn-block justify-content-center"
            variant="primary"
            onClick={() => formik.handleSubmit()}
            disabled={loading}
          >
            {t('transactionSuccessPage.emailConfirmationButton')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EmailConfirmationForm;
