/*
 * FeedCard.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FeedCard.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState } from "react";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import "./FeedCard.css";
import Button from "react-bootstrap/Button";
import { ProgressSpinner } from "primereact/progressspinner";
import { IMappedFeedItems } from "@abstract/abstractwebcommon-shared/interfaces/feed";
import { formatDate } from "@abstract/abstractwebcommon-shared/utils/sharedFunctions";

export interface IFeedCardProperties {
  feedsData?: IMappedFeedItems;
  isLoading?: boolean;
}

const FeedCardValues: IFeedCardProperties = {
  feedsData: {},
};

const FeedCard = ({ feedsData, isLoading }: IFeedCardProperties): ReactElement => {
  const [limit, setLimit] = useState<number>(3);
  const { t } = useTranslation();
  feedsData = feedsData || FeedCardValues.feedsData;

  const handleFeedClick = (feed: IMappedFeedItems): void => {
    window.open(feed.link);
  };

  let cards: JSX.Element[] = [];
  if (feedsData && feedsData.items && feedsData.items.length > 0) {
    const feedItems: IMappedFeedItems[] = feedsData.items.slice(0, limit) || [];
    cards = feedItems.map((eachFeed: IMappedFeedItems, index: number) => {
      const datePreview: Date | string = eachFeed.pubDate
        ? formatDate(eachFeed.pubDate)
        : "";
      return (
        <div className="custom-feed-container">
          <div className="d-flex flex-column">
            <p className={`w-100 mx-0 mb-0 ${index > 0 ? 'custom-margin-between-news' : ''}`}>{datePreview}</p>
            
            <label onClick={() => handleFeedClick(eachFeed)} className="font-weight-bold feedLabel">
              {eachFeed.title} - {eachFeed.creator}
            </label>
          </div>

          <p className="m-0">{eachFeed.description}</p>
        </div>
      );
    });
  } else {
    // empty template
    cards = [1, 2, 3].map(() => <Card className="custom-feed-container"></Card>);
  }

  // load more button click handler
  const onLoadMore = (): void => {
    if (!feedsData || !feedsData.items) {
      return;
    }
    if (limit < feedsData.items.length) {
      setLimit(limit + 3);
    }
  };

  const displayCards = (): JSX.Element | JSX.Element[] => {
    if (isLoading) {
      return <ProgressSpinner className="justifyCenter" />;
    } else {
      return (
        <>
          {cards}
          {feedsData.items && limit <= feedsData.items.length && (
            <div className="showMoreFeeds">
              <Button type="button" onClick={onLoadMore}>
                {t("awc:/.dashboard.feeds.loadMore")}
              </Button>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Card title={t("awc:/.dashboard.dashboard_fieldset.news")}>
        {displayCards()}
      </Card>
    </>
  );
};

export default FeedCard;
