/*
 * AdminSubscriptions.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file AdminSubscriptions.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { getAllSubscriptions } from '../../Services/Subscriptions/AdminSubscriptions';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const GET_ALL_SUBSCRIPTIONS_REQUEST: string =
  'subscriptions/get/all/admin/request';
const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'subscriptions/get/all/admin/success';
const GET_ALL_SUBSCRIPTIONS_FAILURE = 'subscriptions/get/all/admin/failure';

const INITIAL_STATE = {
  list: null,
  isFetchingList: false,
  listFetchError: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS_REQUEST:
      return { ...state, isFetchingList: true };
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        listFetchError: false,
        list: action.payload.subscriptions,
        isFetchingList: false,
        totalRecords: action.payload.totalRecords
      };
    case GET_ALL_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        listFetchError: action.error,
        list: []
      };
    default:
      return state;
  }
};

const getAllSubscriptionsRequest = () => ({
  type: GET_ALL_SUBSCRIPTIONS_REQUEST
});

export const getAllSubscriptionsSuccess = (
  subscriptions: ISubscription[],
  totalRecords: number
) => ({
  type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptions, totalRecords }
});

const getAllSubscriptionsFailure = (error: string) => ({
  type: GET_ALL_SUBSCRIPTIONS_FAILURE,
  error
});

export const getAllSubscriptionsAction = (
  skip?: number,
  limit?: number,
  searchTerm?: string,
  sort?: any,
  filter?: Record<string, any>
) => async (dispatch) => {
  const errorMessage: string = 'Get Subscriptions Failed.';

  try {
    dispatch(getAllSubscriptionsRequest());
    const result: IAPIEntityResponse<ISubscription[]> = await asyncErrorHandler(
      getAllSubscriptions(skip, limit, searchTerm, sort, filter ?? {})
    );
    if (result.error) {
      if (result.status === 401) {
        handleError({ message: result?.error?.message });
      } else {
        handleError({ message: errorMessage });
      }
      dispatch(getAllSubscriptionsFailure(result.error as string));
    } else {
      dispatch(
        getAllSubscriptionsSuccess(result.subscriptions, result.totalRecords)
      );
    }
  } catch (error) {
    dispatch(getAllSubscriptionsFailure(error as string));
    handleError({ message: errorMessage });
  }
};