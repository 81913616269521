/**
* Vat.ts (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Vat.ts
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import {createOrUpdate, getAllCountries, remove} from '../Services/Vat';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {showSuccessToast} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {ITablePayload} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {ICountry} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const ADD_COUNTRY_REQUEST = 'country/add/request';
const ADD_COUNTRY_SUCCESS = 'country/add/success';
const ADD_COUNTRY_FAILURE = 'country/add/failure';

const UPDATE_COUNTRY_REQUEST = 'country/update/request';
const UPDATE_COUNTRY_SUCCESS = 'country/update/success';
const UPDATE_COUNTRY_FAILURE = 'country/update/failure';

const DELETE_COUNTRY_REQUEST = 'country/update/request';
const DELETE_COUNTRY_SUCCESS = 'country/update/success';
const DELETE_COUNTRY_FAILURE = 'country/update/failure';

const GET_ALL_COUNTRIES_REQUEST = 'countries/gelAll/request';
const GET_ALL_COUNTRIES_SUCCESS = 'countries/gelAll/success';
const GET_ALL_COUNTRIES_FAILURE = 'countries/gelAll/failure';

const INITIAL_STATE = {
  list: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_COUNTRY_REQUEST:
      return { ...state, countryIsChanging: true };
    case ADD_COUNTRY_SUCCESS:
      return { ...state, countryIsChanging: false };
    case ADD_COUNTRY_FAILURE:
      return { ...state, countryIsChanging: false };
    case UPDATE_COUNTRY_REQUEST:
      return { ...state, countryIsChanging: true };
    case UPDATE_COUNTRY_SUCCESS:
      return { ...state, countryIsChanging: false };
    case UPDATE_COUNTRY_FAILURE:
      return { ...state, countryIsChanging: false };
    case DELETE_COUNTRY_REQUEST:
      return { ...state, countryIsChanging: true };
    case DELETE_COUNTRY_SUCCESS:
      return { ...state, countryIsChanging: false };
    case GET_ALL_COUNTRIES_REQUEST:
      return { ...state, countryIsChanging: true };
    case GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countryIsChanging: false,
        list: action.payload.countries,
        totalRecords: action.payload.totalRecords
      };
    case GET_ALL_COUNTRIES_FAILURE:
      return { ...state, countryIsChanging: false };
    default:
      return state;
  }
};

const getlAllCountriesRequest = () => ({
  type: GET_ALL_COUNTRIES_REQUEST
});

const getlAllCountriesSuccess = (
  countries: ICountry,
  totalRecords: number
) => ({
  payload: { countries, totalRecords },
  type: GET_ALL_COUNTRIES_SUCCESS
});

const getlAllCountriesFailure = () => ({
  type: GET_ALL_COUNTRIES_FAILURE
});

const addCountryRequest = () => ({
  type: ADD_COUNTRY_REQUEST
});

const addCountrySuccess = () => ({
  type: ADD_COUNTRY_SUCCESS
});

const addCountryFailure = () => ({
  type: ADD_COUNTRY_FAILURE
});

const updateCountryRequest = () => ({
  type: UPDATE_COUNTRY_REQUEST
});

const updateCountrySuccess = () => ({
  type: UPDATE_COUNTRY_SUCCESS
});

const updateCountryFailure = () => ({
  type: UPDATE_COUNTRY_FAILURE
});

const deleteCountryRequest = () => ({
  type: DELETE_COUNTRY_REQUEST
});

const deleteCountrySuccess = () => ({
  type: DELETE_COUNTRY_SUCCESS
});

const deleteCountryFailure = () => ({
  type: DELETE_COUNTRY_FAILURE
});

export const getAllCountriesAction = (payload: ITablePayload) => async (
  dispatch
) => {
  try {
    dispatch(getlAllCountriesRequest());
    const result = await asyncErrorHandler(getAllCountries(payload));
    if (result.error) {
      dispatch(getlAllCountriesFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(getlAllCountriesSuccess(result.countries, result.totalRecords));
    }
  } catch (e) {
    dispatch(getlAllCountriesFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const createCountry = (country) => async (dispatch) => {
  try {
    dispatch(addCountryRequest());
    const result = await asyncErrorHandler(createOrUpdate(country.data));
    if (result.error) {
      dispatch(addCountryFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(getAllCountriesAction(country.payload));
      showSuccessToast(result.message ?? '');
      dispatch(addCountrySuccess());
    }
  } catch (e) {
    dispatch(addCountryFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const updateCountry = (country) => async (dispatch) => {
  try {
    dispatch(updateCountryRequest());
    const result = await asyncErrorHandler(createOrUpdate(country));
    if (result.error) {
      dispatch(updateCountryFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(getAllCountriesAction(''));
      dispatch(updateCountrySuccess());
      showSuccessToast(result.message ?? '');
    }
  } catch (e) {
    dispatch(updateCountryFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const deleteCountry = (
  countryIDs: string[],
  tablePayload: ITablePayload
) => async (dispatch) => {
  try {
    dispatch(deleteCountryRequest());
    const result = await asyncErrorHandler(remove(countryIDs));
    if (result.error) {
      dispatch(deleteCountryFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(getAllCountriesAction(tablePayload));
      dispatch(deleteCountrySuccess());
      showSuccessToast(result.message ?? '');
    }
  } catch (e) {
    dispatch(deleteCountryFailure());
    handleError({ message: e.message });
  }
};
