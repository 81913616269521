/*
 * ImageRow.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file ImageRow.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react'

/**
 * @interface IImageRowProperties
 */
interface IImageRowProperties {
  imageURL?: string /**< Image URL string. */
  imageCount: number /**< Image counter. */
  setImageLoading: React.Dispatch<React.SetStateAction<boolean>> /**< State hook to control when image is loading. */;
  setImageCount: React.Dispatch<React.SetStateAction<number>> /**< State hook to count how many image were loaded. */;
  totalImagesCount: number /** Number of images to load */
  classNames?: string /** Additional CSS for the IMG element */
}

/**
 * Render Image HTML Tag inside a datatable
 * @param properties type of IImageRowProperties
 * @returns JSX.Element
 */
const ImageRow = (properties: IImageRowProperties): JSX.Element => {

  /**
   * Count how many images have to load and execute a callback when all images are loaded
   * @param count holds the number N that defines number of calls before functionToExecute is invoked
   * @param functionToExecute holds the function which will invoked
   * @returns Function
   */
  const customAfter = (count: number, functionToExecute: Function) => {
    return function (...rest): void {
      properties.setImageCount((previousValue: number) => previousValue + 1);
      if (count === properties.imageCount) {
        functionToExecute(...rest);
      }
    };
  }

  if (properties.totalImagesCount === 0) {
    properties.setImageLoading(false);
  }

  const handleImageLoading = customAfter(properties.totalImagesCount, () => {
    properties.setImageLoading(false);
  });
  
  return (
    <>
      {properties.imageURL ? <img
        className={`dimensions-50x50 ${properties.classNames ?? ''}`}
        alt=""
        src={properties.imageURL}
        onLoad={handleImageLoading}
        onError={handleImageLoading}
      /> : <></>}
    </>

  )
}

export default ImageRow