/**
* CustomerBillingDetailsForm.tsx (AbstractECommerce) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022 
* @file CustomerBillingDetailsForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { ReactElement, useState, useEffect } from 'react';
import classnames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country, ICountry, State, IState } from 'country-state-city';
import { translate } from '../../Utils/Translate';
import Button from 'react-bootstrap/Button';

/**
 * @interface ICustomerBillingDetailsFormProperties.
 */
interface ICustomerBillingDetailsFormProperties {
  customer: {
    billingCompanyName: string;
    billingAddress1: string;
    billingCity: string;
    billingZipcode: string;
    billingStateOrProvince: string;
    billingCountry: string;
    billingAddress2?: string;
    vatId?: string /**< VAT ID */;
    billingVatID?: string /**< Billing VAT ID */;
  } /**< customer information*/;
  handleSubmit: (
    payload: any
  ) => void /**< handle customer billing update submit function*/;
}

/**
 * Custome billing details form.
 * @param properties ICustomerBillingDetailsFormInterface.
 * @returns
 */
const CustomerBillingDetailsForm = (
  properties: ICustomerBillingDetailsFormProperties
): ReactElement => {
  const [countries, setCountries] = useState<ICountryData[]>([]);
  const [statesOfCountry, setStatesOfCountry] = useState<ICountryData[]>(
    []
  ); /**< State of country */

  /// Countries names
  const getCountries = () => {
    setCountries(
      Country.getAllCountries()
        .map(
          (country: ICountry) =>
            ({
              value: country.isoCode,
              label: country.name
            } as ICountryData)
        )
        .sort((country1: ICountryData, country2: ICountryData) =>
          country1.label.localeCompare(country2.label)
        )
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      billingCompanyName: properties.customer.billingCompanyName || '',
      billingAddress1: properties.customer.billingAddress1 || '',
      billingCity: properties.customer.billingCity || '',
      billingZipcode: properties.customer.billingZipcode || '',
      billingStateOrProvince: properties.customer.billingStateOrProvince || '',
      billingCountry: properties.customer.billingCountry || '',
      billingAddress2: properties.customer.billingAddress2 || '',
      vatId: properties.customer.billingVatID ?? properties.customer.vatId ?? ''
    },
    validationSchema: Yup.object({
      billingCompanyName: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(50, translate('validation.max', { field: '50' }))
        .matches(/(?!^\d+$)^.+$/, translate('validation.notOnlyNumbers'))
        .notRequired(),
      billingAddress1: Yup.string()
        .min(2, translate('/alidation.min', { field: '2' }))
        .max(500, translate('validation.max', { field: '500' }))
        .matches(/(?!^\d+$)^.+$/, translate('validation.notOnlyNumbers'))
        .required(
          translate('validation.required', {
            field: translate('fields.customer.address')
          })
        ),
      billingAddress2: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(500, translate('validation.max', { field: '500' }))
        .matches(/(?!^\d+$)^.+$/, translate('validation.notOnlyNumbers'))
        .nullable()
        .notRequired(),
      billingCity: Yup.string()
        .max(300, translate('validation.max', { field: '300' }))
        .matches(/(?!^\d+$)^.+$/, translate('validation.notOnlyNumbers'))
        .nullable()
        .required(
          translate('validation.required', {
            field: translate('fields.customer.city')
          })
        ),
      billingZipcode: Yup.string()
        .max(300, translate('validation.max', { field: '300' }))
        .nullable()
        .notRequired(),
      billingStateOrProvince: Yup.string().required(
        translate('validation.required', {
          field: translate('fields.customer.stateOrProvince')
        })
      ),
      billingCountry: Yup.string().required(
        translate('validation.required', {
          field: translate('fields.customer.country')
        })
      ),
      vatId: Yup.string()
        .max(50, translate('validation.max', { field: '50' }))
        .nullable()
        .notRequired()
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (Object.keys(payload).length) {
            if (properties.customer.billingVatID) {
              payload.billingVatID = payload.vatId;
              payload.vatId = properties.customer.vatId;
            }
            properties.handleSubmit(payload);
          }
        }
      });
    }
  });

  // Get states of country
  useEffect(() => {
    const states: ICountryData[] = State.getStatesOfCountry(
      formik.values.billingCountry
    ).map(
      (state: IState) =>
        ({
          value: state.isoCode,
          label: state.name
        } as ICountryData)
    );
    setStatesOfCountry(states);
  }, [formik.values.billingCountry]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <table className={classnames('table', 'admin-customer-table')}>
          <tbody>
            <tr>
              <td className="billing-description-col p-3">
                <b>{translate('fields.customer.companyName')}:</b>
              </td>
              <td className="billing-details">
                <InputText
                  id="billingCompanyName"
                  value={formik.values.billingCompanyName}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.billingCompanyName &&
                    formik.errors.billingCompanyName
                      ? 'p-invalid'
                      : ''
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.billingCompanyName &&
                formik.errors.billingCompanyName ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {translate(formik.errors.billingCompanyName)}
                  </small>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="billing-description-col">
                <b>{translate('fields.customer.address')}: </b>
              </td>
              <td className="billing-details">
                <InputTextarea
                  id="billingAddress1"
                  value={formik.values.billingAddress1}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.billingAddress1 &&
                    formik.errors.billingAddress1
                      ? 'p-invalid'
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  autoResize
                />
                {formik.touched.billingAddress1 &&
                formik.errors.billingAddress1 ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {translate(formik.errors.billingAddress1)}
                  </small>
                ) : null}
                <div className="d-flex mt-3">
                  <InputText
                    id="billingCity"
                    placeholder={translate('fields.customer.city')}
                    value={formik.values.billingCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.billingCity && formik.errors.billingCity
                        ? 'p-invalid mr-2'
                        : 'mr-2'
                    }
                  />
                  <InputText
                    id="billingZipcode"
                    placeholder={translate('fields.customer.zipcode')}
                    value={formik.values.billingZipcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.billingZipcode &&
                      formik.errors.billingZipcode
                        ? 'p-invalid'
                        : ''
                    }
                  />
                </div>
                {formik.touched.billingCity && formik.errors.billingCity ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {translate(formik.errors.billingCity)}
                  </small>
                ) : null}
                <br />
                <div className="d-flex">
                  <Dropdown
                    name="billingCountry"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.billingCountry}
                    options={countries}
                    className={
                      formik.touched.billingCountry &&
                      formik.errors.billingCountry
                        ? 'p-invalid mr-2'
                        : 'mr-2'
                    }
                  />
                  <Dropdown
                    id="billingStateOrProvince"
                    placeholder={translate('fields.customer.stateOrProvince')}
                    value={formik.values.billingStateOrProvince}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched.billingStateOrProvince &&
                      formik.errors.billingStateOrProvince
                        ? 'p-invalid mr-2'
                        : 'mr-2'
                    }
                    name="billingStateOrProvince"
                    inputId="billingStateOrProvince"
                    options={statesOfCountry}
                    disabled={
                      !formik.values.billingCountry || !statesOfCountry.length
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="billing-description-col billing-address2-description-col">
                <b>{translate('fields.customer.address2')}: </b>
              </td>
              <td className="billing-details">
                <InputTextarea
                  id="billingAddress2"
                  value={formik.values.billingAddress2}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.billingAddress2 &&
                    formik.errors.billingAddress2
                      ? 'p-invalid'
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  autoResize
                />
                {formik.touched.billingAddress2 &&
                formik.errors.billingAddress2 ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {translate(formik.errors.billingAddress2)}
                  </small>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="billing-description-col billing-address2-description-col">
                <b>{translate('fields.customer.vatId')}: </b>
              </td>
              <td className="vatId">
                <InputText
                  id="vatId"
                  value={formik.values.vatId}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.vatId && formik.errors.vatId
                      ? 'p-invalid'
                      : ''
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.touched.vatId && formik.errors.vatId ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {translate(formik.errors.vatId)}
                  </small>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <Button
        className="position-absolute edit-note-button"
        onClick={() => formik.handleSubmit()}
        variant="outline"
      >
        <i className="pi pi-save editIcon icon-button"></i>
      </Button>
    </>
  );
};

export default CustomerBillingDetailsForm;
