/*
 * CustomerTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file CustomerTable.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { CustomerTableExpansion } from './CustomerTableExpansion';
import { useTranslation } from 'react-i18next';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import UserDetailsPopup from '@abstract/abstractwebcommon-client/UserDetailsPopup';
import Tooltip from 'react-bootstrap/Tooltip';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MultiSelect } from 'primereact/multiselect';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../../../Store/Products';
import { isRole } from '../../../Utils/Formatter';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

/**
 * Interface for CustomerTable properties.
 */
interface ICustomerTableProperties {
  customers: any[];
  loading: boolean;
  handlePageUpdate: any;
  handleFilterUpdate: any;
  handleSortUpdate: any;
  skip: number;
  limit: number;
  count: number;
  sort: any;
  filter: Record<string, any> /**< Filter object */;
  handleProductFilterUpdate: (
    filter: Record<string, any>
  ) => void /**< Handle product filter update event */;
}

/**
 * The Customer Table displays list of users.
 */
const CustomerTable = ({
  customers,
  loading,
  handlePageUpdate,
  handleFilterUpdate,
  handleSortUpdate,
  skip,
  limit,
  count,
  sort,
  filter,
  handleProductFilterUpdate
}: ICustomerTableProperties) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>[]>(
    []
  );
  const sortField = sort && sort.sortField;
  const sortOrder = sort && sort.sortOrder;
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>(
    []
  ); /**< Selected Products. */
  const productsState = useSelector((state) => state.products);
  const shopSettings = useSelector((state) => state.shopSettings.list);
  const roles: string[] = JSON.parse(LocalStorage.getRole()); /**< User role. */
  const adminRoleUUID: string =
    shopSettings?.adminRoleUUID; /**< Admin role UUID. */
  const isAdminRole: boolean = isRole([adminRoleUUID], roles);

  // Triggers on product multiselect change - Sets the value to the filter
  const onProductChange = (event: any): void => {
    const products: IProduct[] = event.value; /**< Selected Products. */
    setSelectedProducts(products);
    const filteredValue: string[] = products.map(
      (product: IProduct) => product.name
    ); /**< filteredValue */

    let updatedFilter: Record<string, any> = {};

    if (filteredValue && filteredValue.length) {
      // If values exist then update the filter object with product key
      updatedFilter = {
        ...filter,
        products: { value: filteredValue }
      };
    } else {
      // If not omit product key from object
      updatedFilter = { ...filter };
      if (updatedFilter.products) {
        delete updatedFilter.products;
      }
    }

    handleProductFilterUpdate(updatedFilter);
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      {isAdminRole ? (
        <div className="headerTableContainer header-search-filter">
          <SearchBar onSearchTermChanged={(data) => handleFilterUpdate(data)} />
          <MultiSelect
            optionLabel="name"
            dataKey="_id"
            name="product"
            inputId="productFilter"
            value={selectedProducts}
            options={productsState.allProducts}
            onChange={onProductChange}
            placeholder={t('customersTable.user.products')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
          />
        </div>
      ) : (
        <SearchBar onSearchTermChanged={(data) => handleFilterUpdate(data)} />
      )}
    </div>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };

  useEffect(() => {
    if (isAdminRole) {
      dispatch(fetchAllProducts()); /**< To fetch all products. */
    }
  }, []);

  const dataTable = (
    <BaseDatatable
      value={customers}
      isLoading={!customers}
      header={header}
      first={skip}
      rows={limit}
      totalRecords={count}
      onPage={handlePageUpdate}
      onFilter={handleFilterUpdate}
      onSort={handleSortUpdate}
      sortField={sortField}
      sortOrder={sortOrder}
      expandedRows={expandedRows}
      onRowExpand={expandRow}
      onRowCollapse={() => setExpandedRows([])}
      rowExpansionTemplate={(data) => (
        <CustomerTableExpansion
          transactions={[...data.transactions].sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })}
        />
      )}
    >
      {/* Used to prevent row selection on row click */}
      <Column selectionMode="multiple" className="d-none" />
      <Column
        expander
        className="p-0 col-width-45"
        headerClassName="p-0 col-width-45"
      />
      <Column
        field="user.username"
        header={t('customersTable.user.name')}
        sortable
        className="custom-userName-width-column"
        headerClassName="custom-userName-width-column"
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.user.name')}
            data={
              row.userUUID && !row.deleted ? (
                <UserDetailsPopup
                  users={customers}
                  userUUID={row.userUUID}
                  key={row.id}
                />
              ) : (
                // Tooltip for deleted User & unregistered user.
                <div className="p-d-flex p-ai-center">
                  <OverlayTrigger
                    key="deleted-user"
                    placement="top"
                    trigger={'hover'}
                    overlay={
                      <Tooltip id="deleted-user">
                        {row.username
                          ? t('customersTable.tooltip.deleted_user')
                          : t('customersTable.tooltip.unregistered_user')}
                      </Tooltip>
                    }
                  >
                    <i className="custom-target-icon d-inline-block text-warning bi bi-exclamation-triangle mr-2"></i>
                  </OverlayTrigger>
                  {row.username ?? 'unregistered user'}
                </div>
              )
            }
          />
        )}
      />
      <Column
        field="user.email"
        header={t('customersTable.user.email')}
        sortable
        className="custom-userEmail-width-column"
        headerClassName="custom-userEmail-width-column"
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.user.email')}
            data={row.email}
          />
        )}
      />
      <Column
        field="products"
        header={t('customersTable.user.products')}
        className="d-table-cell d-sm-none d-md-table-cell"
        headerClassName="d-table-cell d-sm-none d-md-table-cell"
        sortable
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.user.products')}
            data={
              <>
                {row.products.map((product, index) => {
                  return (
                    <span key={index + 1}>
                      <span className="badge badge-secondary">
                        {product ? product : ''}
                      </span>{' '}
                      &nbsp;{' '}
                    </span>
                  );
                })}
              </>
            }
          />
        )}
      />
      <Column
        field="totalPrice"
        header={t('customersTable.user.price')}
        sortable
        className="custom-total-price-width-column d-table-cell d-sm-none d-xl-table-cell"
        headerClassName="custom-total-price-width-column d-table-cell d-sm-none d-xl-table-cell"
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.user.price')}
            data={currencyFormatter(row.totalPrice)}
          />
        )}
      />
      <Column
        field="totalTransactions"
        header={t('customersTable.user.transactions')}
        sortable
        className="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
        headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-xxl-table-cell"
        body={(row) => (
          <DatatableColumn
            title={t('customersTable.user.transactions')}
            data={row.totalTransactions}
          />
        )}
      />
    </BaseDatatable>
  );

  return (
    <>
      <Row>
        <Col xs={12}>{dataTable}</Col>
      </Row>
    </>
  );
};

export default CustomerTable;
