/*
 * ShopWrapper.tsx (AbstractECommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file ShopWrapper.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';

import ShopBody from './ShopBody';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

/**
 * @Interface IShopWrapperProperties.
 */
interface IShopWrapperProperties {
  children: React.ReactNode /** Render shop body */;
  shopHeaderHTML: string /**< ShopHeader HTML */;
  shopFooterHTML: string /**< ShopFooter HTML */;
}

/**
 * Wrapper that contains the main structure of Shop page
 * @param properties IShopWrapperProperties properties
 * @return {JSX.Element}
 */
const ShopWrapper = (properties: IShopWrapperProperties): JSX.Element => {
  //Note: Shop page shouldn't have dark mode.
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.themeModeKey) {
      document.body.className = ThemeMode.lightMode;
    }
  });

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: properties.shopHeaderHTML }} />
      <ShopBody>{properties.children}</ShopBody>
      <div dangerouslySetInnerHTML={{ __html: properties.shopFooterHTML }} />
    </>
  );
};

export default ShopWrapper;
