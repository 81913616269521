/*
 * AlertToast.tsx (AbstractWebCommon)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Polina Ovsiannikova, 2022
 *
 * @file AlertToast.tsx
 * @author Polina Ovsiannikova
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section Web Common
 */

import { Toast } from 'primereact/toast';
import React from 'react'

/**
 * IAlertToastMessage interface.
 */
export interface IAlertToastMessage {
    severity: string; /**< Severity level */
    summary: string; /**< Display message */
    detail?: string; /**< Display details */
}

let toast: any;

/**
 * Show toast function. When called, the toaster with the custom message is displayed.
 */
export const showToast = (message: IAlertToastMessage) => {
    if (toast) {
        // If the error is unauthorized, the toast message will not be displayed.
        if(message.detail != 'Unauthorized' && message.summary != 'Unauthorized') {
            toast.show({
                severity: message.severity,
                summary: message.summary,
                detail: message.detail
            });
        }
    }
}

/**
 * Show success toast function. When called, the success toaster with the message is displayed.
 */
export const showSuccessToast = (summary: string, detail?: string) => {
    showToast({severity: "success", summary: summary, detail: detail});
}

/**
 * A component that displays a toaster.
 */
export const AlertToast = () => {
    return (
        <Toast baseZIndex={2000} ref={(el) => (toast = el)} />
    )
}