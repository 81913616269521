/*
 * Loader.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2020
 *
 * @file Loader.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import "./index.css";
import { ProgressSpinner } from "primereact/progressspinner";

interface ILoader {
  className?: string
  [otherProperties:string]: any /** Others component properties  */
}

const Loader = ({ className, ...otherProperties }: ILoader): ReactElement => {
  return <ProgressSpinner className={className || 'd-flex'} { ...otherProperties }/>;
};

export default Loader;
