/*
 * SettingsFaviconUpload.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file SettingsFaviconUpload.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import InstaImageUpload, {
  RemoveButtonStyle
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';

/**
 * Interface for SettingsFaviconUpload properties.
 */
interface ISettingsFaviconUploadProperties {
  favoriteIcon: string /**< FavoriteIcon Id. */;
  uploadFavoriteIcon: (
    event: any
  ) => Promise<void> /**< Upload FavoriteIcon Handler. */;
  deleteFavoriteIcon: (event: any) => void /**< Delete FavoriteIcon Handler. */;
  errorHandler: (error: string) => void /**< Error Handler. */;
  displayCroppedLogo: Blob | null /**< Cropped FavoriteIcon. */;
  children: React.ReactNode /** Children elements */;
  changeInputClassName: (
    isFileField?: boolean
  ) =>
    | string
    | undefined /**< Control when to display the field in the form based on data loading */;
}

const SettingsFaviconUpload = (
  properties: ISettingsFaviconUploadProperties
): JSX.Element => {
  const translation: TFunction = useTranslation().t;
  const [toggleFullSizePreviewLogo, setToggleFullSizePreviewLogo] = useState<
    boolean
  >(false); /**< Full Size Preview. */

  /// Get favouriteIcon div
  const getFavouriteIcon = (): JSX.Element => {
    return (
      <>
        <div id="favouriteIcon" className="flex-grow-1 mt-2 input-fixed-height">
          <label htmlFor="favouriteIcon" className="flex-grow-1">
            {translation('setting.headers.change_favicon')}
          </label>
          <PopupInformation
            id={'favouriteIcon'}
            popupText={translation('setting.headers.favicon_popupText')}
          />
          <div
            className={`p-2 p-inputtext ${properties.changeInputClassName(
              true
            )}`}
          >
            <InstaImageUpload
              showLegend={false}
              imageUrl={properties.favoriteIcon}
              showDelete={true}
              removeButtonStyle={RemoveButtonStyle.SEPARATE}
              deleteHandler={properties.deleteFavoriteIcon}
              isHorizontalLayout
              onChange={properties.uploadFavoriteIcon}
              errorHandler={properties.errorHandler}
              imgClass={'imageUrl rounded'}
              altText={translation('insta_image.logo_alt')}
              plainBtnLabel={translation('generic_button_choose_file')}
              isPlainBtn={true}
              setToggleFullSizePreview={setToggleFullSizePreviewLogo}
              toggleFullSizePreview={toggleFullSizePreviewLogo}
              croppedImage={properties.displayCroppedLogo}
            />
          </div>
          {properties.children}
        </div>
      </>
    );
  };

  return <>{getFavouriteIcon()}</>;
};

export default SettingsFaviconUpload;
