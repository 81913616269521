/*
 * OverflowTip.tsx (AbstractLicense)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file OverflowTip.tsx
 * @author James Ugbanu
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useRef, useEffect, useState, ReactElement } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from "react-bootstrap/esm/Overlay";
import './index.css';

//OverflowTip props interface.
interface IOverflowableTextProperties {
  text: string; /**< Tooltip Text */
  position?: Placement; /**< Tooltip position */
}

// OverflowableText component
const OverflowableText = (properties: IOverflowableTextProperties): ReactElement => {
  const position: Placement = properties.position ?? 'auto';
  const [isTextOverflow, setTextOverflow] = useState<Boolean>(false);
  const textElementReference = useRef<HTMLInputElement | null>(null);
  const numberOfCharacter: number = 1;

  const compareSize = () => {
    const compare: Boolean =
      textElementReference.current?.scrollWidth > textElementReference.current?.clientWidth;
    setTextOverflow(compare);
  };


  useEffect(() => {
    compareSize();
    window.addEventListener('mouseover', compareSize);
  }, []);


  useEffect(() => () => {
    window.removeEventListener('mouseover', compareSize);
  }, []);
  
  return (
    properties.text.length > numberOfCharacter ? (
      (isTextOverflow) ? (
        <OverlayTrigger
          key={position}
          placement={position}
          overlay={
            <Tooltip id={`tooltip-${position}`}>
              {properties.text}
            </Tooltip>
          }>
          <div className={`${properties.text.length > numberOfCharacter ? 'text-overflow-ellipsis-expanded' : 'text-overflow-ellipsis'}`}>
            <span ref={textElementReference}>{properties.text}</span>
          </div>
        </OverlayTrigger>
      ) : (
        <div className={`${properties.text.length > numberOfCharacter ? 'text-overflow-ellipsis-expanded' : 'text-overflow-ellipsis'}`}>
          <span ref={textElementReference}>{properties.text}</span>
        </div>
      )
    ) : (
      <div>
        <span>{properties.text}</span>
      </div>
    )
  );
};

export default OverflowableText;