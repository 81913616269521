/*
 * sharedMenus.tsx (AbstractWebCommon)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file sharedMenus.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import jwtDecode from 'jwt-decode';

import { IStaticLink } from "../../Shared/interfaces/staticLinks";
import { IApplications } from "../../Shared/interfaces/user/applications";
import { IMenuItem } from "./SidebarPage";
import { IUserInformationToken } from '../../Shared/utils/UserAuthenticationToken';
import { LocalStorage } from '../utils/sharedLocalStorage'

/**
 * Verify and redirect user according of fields whenRegistered, isUserPermissionGranted and isAlwaysVisible
 * 
 * @param decodeJwt decoded JWT token
 * @param application requested application to be used in the conditions
 * @returns URl to be redirect - string
 */
const redirectValidations = (decodeJwt: IUserInformationToken, application: IApplications) => {
  //NOTE: When 'whenRegistered' or 'isAlwaysVisible' is true and user is registered in the requested application, the user will be redirected to the 
  //NOTE: Authentication verification URL with the JWT token in the URL.
  if ((application.whenRegistered && application.isUserPermissionGranted) || (application.isAlwaysVisible && application.isUserPermissionGranted)) {
    return `${application.verificationURL}${decodeJwt.applications.some(applicationUUID => applicationUUID === application.applicationUUID) ? `?token=${LocalStorage.getXAuthToken()}&themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}` : ''}`
  }

  //NOTE: Redirect user to 'nonRegisteredURL' when 'isAlwaysVisible' is true and user is not registered in the application. 
  return `${application.nonRegisteredURL}?themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}`;
}

/**
 * Added linked applications to menu items of Sidebar component
 * 
 * @param userApplications user applications array
 * @param userAuthenticationVerificationURL user authentication verification URL
 * @returns IMenuItem[] | null
 */
export const linkedApplicationsSidebarItems = (userApplications: IApplications[], userAuthenticationVerificationURL: string): IMenuItem[] | null => {
  if (!userApplications || userApplications.length === 0) {
    return []
  }

  if (LocalStorage.getXAuthToken()) {
    const decodeJwt: IUserInformationToken = jwtDecode(LocalStorage.getXAuthToken())
    
    return [...userApplications.map((application) => {
      //NOTE: Only render menu when 'isAlwaysVisible' or 'whenRegistered' os true.
      if (application.isAlwaysVisible || application.whenRegistered) {
        return {
          label: application.applicationName,
          icon: application.icon,
          isApplicationOrStaticLink: true,
          url: `${application.verificationURL}`,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            if(application.isUserPermissionGranted) {
              window.open(
                `${userAuthenticationVerificationURL}?verification_url=${application.verificationURL}&app=${application.applicationUUID}&themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}`,
                '_blank'
              );
            } else {
              window.open(
                redirectValidations(decodeJwt, application),
                '_blank'
              );
            }
          }
        }
      }

      return null
    })]
  }

  return [
    ...userApplications.map((application) => ({
      label: application.applicationName,
      icon: application.icon,
      isApplicationOrStaticLink: true,
      url: `${application.verificationURL}`,
      command: (event: any) => {
        event.originalEvent.preventDefault();
        window.open(
          `${application.verificationURL}`,
          '_blank'
        );
      }
    }))
  ]
}

/**
 * Added static links to menu items of Sidebar component
 * 
 * @param staticLinks static links array
 * @returns IMenuItem[]
 */
export const staticLinkSidebarItems = (staticLinks: IStaticLink[]): IMenuItem[] => {
  if (!staticLinks || staticLinks.length === 0) {
    return []
  }

  return ([
    ...staticLinks.map((staticLink) => ({
      label: staticLink.name,
      isApplicationOrStaticLink: true,
      icon: staticLink.icon,
      url: staticLink.link,
      command: (event: any) => {
        event.originalEvent.preventDefault();
        window.open(staticLink.link, '_blank');
      }
    }))
  ])
} 