/*
 * Applications.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Applications.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { uploadCall } from './Api';
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * To create or update application
 * @param application
 */
export const createOrUpdateApplication = async (application: any) => {
  const applicationData = {
    ...application
  };

  if (application._id) {
    // Update both logo file and data.
    return uploadCall(
      `${BASE_API_URL}/applications/update/${application._id}`,
      {
        file: application.file,
        data: applicationData
      }
    );
  }

  const formDataRequest = new FormData();

  if (!isStringEmptyOrNullOrUndefined(application.file)) {
    formDataRequest.append('file', application.file, application.file['name']);
  }

  formDataRequest.append('data', JSON.stringify(applicationData));

  return httpClient.imageUpload(`/applications/create`, formDataRequest);
};

/**
 * To remove Application(s)
 * @param payload applicationIDs
 * @returns Promise
 */
export const removeApplication = (payload: string[]) => {
  return httpClient.post(`/applications/delete`, { applicationIDs: payload });
};

/**
 * Get all paginated applications
 * @param skip
 * @param limit
 * @param searchTerm
 * @param sort
 * @returns
 */
export const getAllPaginatedApplications = async (
  skip = 0,
  limit = defaultTableLimit,
  searchTerm = '',
  sort = {
    sortField: 'createdAt',
    sortOrder: -1
  }
) => {
  const url = paginationRequest(`/applications/allPaginated`, {
    skip,
    limit,
    searchTerm
  });
  return httpClient.post(url, { sort });
};

/**
 * Get all applications without pagination,
 * @returns
 */
export const getAllApplications = (): Promise<IAPIEntityResponse<
  IApplication[]
>> => httpClient.get(`/applications/all`);

/**
 * Get user applications without pagination,
 * @returns
 */
export const getUserApplications = (): Promise<IAPIEntityResponse<
  IApplications[]
>> => httpClient.get(`/applications/userApplications`);
