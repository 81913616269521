/*
 * Braintree.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file Braintree.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {getAllBraintreePlans} from '../Services/Braintree';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {Plan} from 'braintree';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_ALL_BRAINTREE_PLANS_REQUEST = 'braintree/get/all/plans/request';
const GET_ALL_BRAINTREE_PLANS_SUCCESS = 'braintree/get/all/plans/success';
const GET_ALL_BRAINTREE_PLANS_FAILURE = 'braintree/get/all/plans/failure';

const INITIAL_STATE = {
  list: null,
  isFetchingList: false,
  listFetchFailedError: null,
  listValueAndLabel: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BRAINTREE_PLANS_REQUEST:
      return { ...state, isFetchingList: true };
    case GET_ALL_BRAINTREE_PLANS_SUCCESS:
      return {
        ...state,
        listFetchFailedError: false,
        list: action.payload,
        listValueAndLabel: action.payload.map((payload: Plan) => ({
          value: payload.id,
          label: payload.name
        })),
        isFetchingList: false
      };
    case GET_ALL_BRAINTREE_PLANS_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        listFetchFailedError: action.error
      };
    default:
      return state;
  }
};

const getAllBraintreePlansRequest = () => ({
  type: GET_ALL_BRAINTREE_PLANS_REQUEST
});

const getAllBraintreePlansSuccess = (payload: Plan[]) => ({
  type: GET_ALL_BRAINTREE_PLANS_SUCCESS,
  payload
});

const getAllBraintreePlansFailure = (error: string) => ({
  type: GET_ALL_BRAINTREE_PLANS_FAILURE,
  error
});

export const getAllBraintreePlansAction = () => async (dispatch) => {
  const errorMessage: string = 'Get Braintree Plans failed.';

  try {
    dispatch(getAllBraintreePlansRequest());
    const result = await asyncErrorHandler(getAllBraintreePlans());

    if (result.error) {
      handleError({ message: errorMessage });
      dispatch(getAllBraintreePlansFailure(result.error as string));
    } else {
      dispatch(getAllBraintreePlansSuccess(result as Plan[]));
    }
  } catch (error) {
    dispatch(getAllBraintreePlansFailure(error as string));
    handleError({ message: errorMessage });
  }
};
