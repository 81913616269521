/*
 * ErrorHandler.tsx (AbstractWebCommon)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Polina Ovsiannikova, 2022
 *
 * @file ErrorHandler.tsx
 * @author Polina Ovsiannikova
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section Web Common
 */

import { Toast } from 'primereact/toast';
import React from 'react'
import { LocalStorage } from '../utils/sharedLocalStorage';
import { isStringEmptyOrNullOrUndefined } from '../../Shared/utils/sharedFunctions';

/**
 * IError interface.
 */
export interface IError {
    code?: number | null; /**< Error code */
    message: string; /**< Error message */
    details?: string; /**< Error details */
}

const clearError = (error: IError) => {
    error.code = null;
    error.message = "";
    error.details = "";
}

let _error: IError = {
    message: "",
};

let toast: any;

/**
 * Handle error function. When called, the toaster with the error message is displayed.
 */
export const handleError = (error: IError) => {
    _error = error;
    // If the error is unauthorized, the toast message will not be displayed.
    if (_error.message && _error.message != 'Unauthorized' && !isStringEmptyOrNullOrUndefined(LocalStorage.getXAuthToken())) {
        const summary = (_error.code ? `${_error.code}: ` : "") + _error.message;
        toast.show({
            severity: 'error',
            summary: summary,
            detail: _error.details
        });
        clearError(_error);
    }
}

/**
 * A component that displays a toaster.
 */
export const ErrorHandler = () => {
    return (
        <Toast baseZIndex={2000} ref={(el) => (toast = el)} />
    )
}