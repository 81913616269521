/**
 * termsAndCondition.ts (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file termsAndCondition.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { ITermsAndCondition } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * Fetch TermsAndCondition
 * @return getTermsAndCondition[]
 */
export const getTermsAndCondition = async (
  payload: ITablePayload
): Promise<IAPIEntityResponse<ITermsAndCondition[]>> => {
  const url = paginationRequest(`/termsAndCondition/all`, {
    skip: payload.skip,
    limit: payload.limit,
    searchTerm: payload.searchTerm
  });
  return httpClient.post(url, { sort: payload.sort });
};

/**
 * Create or update Terms And Condition.
 */
export const createOrUpdateTermsAndCondition = async (
  payload: ITermsAndCondition
) => {
  let url: string;
  if (payload.id) {
    url = `/termsAndCondition/${payload.id}`;
    delete payload.id;
    return httpClient.put(url, payload);
  } else {
    url = `/termsAndCondition`;
    return httpClient.post(url, payload);
  }
};

/**
 * Delete Terms And Condition.
 */
export const deleteTermsAndCondition = async (
  payload: ITermsAndCondition[]
) => {
  return httpClient.post(`/termsAndCondition/delete`, {
    termsAndCondition: payload
  });
};
