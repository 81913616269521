/*
 * json.ts (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file json.ts
 * @author Martin Witczak
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Check value if parsable
 *
 * @note Can be done with try and catch (performance wise, regex is better)
 * @param string Input value
 * @returns boolean True if the input is a valid json  object
 */
export const isJson = (input: string) => {
  return /^[\],:{}\s]*$/.test(
    input
      .replace(/\\["\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );
};
