import React, { ComponentType, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import {
  extractScripts,
  formatTemplate,
  renderCustomComponent
} from '../Utils/Formatter';
import { useAppSelector } from '../Hooks';
import { AlertToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { ErrorHandler } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { BASE_API_URL } from '../Config';
import { InstaImage } from './SubComponents/InstaImage';
import { ITemplates } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/templates';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { removeSchemaFromURL } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';
import i18n from './../Services/I18n';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

interface IPlaceHolder {
  company: string;
  applicationPageURL: string;
  currentYear?: number;
}

/**
 * PublicRoute component is used for all unauthenticated route
 * This makes it easier to wrap unauthenticated components (Navbar, ShopWrapper)
 * @param Component is the component passed down to the router
 * @return {Component}
 */
const PublicRoute: ComponentType<any> = ({
  component: Component,
  languageSettingsMode: languageSettingsMode,
  didChangeLanguage: didChangeLanguage,
  ...rest
}) => {
  const templates: any = useAppSelector((state) => state.templates);
  const [isScriptsExtracted, setScriptsExtracted] = useState<boolean>(
    false
  ); /**< To check scripts extracted or not. */
  const shopSettings: IShopSettings = useAppSelector(
    (state) => state.shopSettings.list
  ); /**< Shop Settings. */
  const productApplicationPageURL: string = useAppSelector(
    (state) => state.products.applicationPageURL
  );
  const [shopHeaderHTML, setShopHeaderHTML] = useState<string>(
    ''
  ); /**< ShopHeader HTML */
  const [shopFooterHTML, setFooterHTML] = useState<string>(
    ''
  ); /**< ShopFooter HTML */
  const [applicationPageURL, setApplicationPageURL] = useState<
    string
  >(); /**< Application Page URL */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    languageSettingsMode ?? LocalStorage.getLanguageSettingsMode()
  ); /**< Selected language */

  useEffect(() => {
    didChangeLanguage && didChangeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  // Set selected language when language settings mode changes
  useEffect(() => {
    setSelectedLanguage(languageSettingsMode);
  }, [languageSettingsMode]);

  /// Note: The public route pages remain hidden and are visible on the corresponding pages until all elements are loaded.
  useEffect(() => {
    document.documentElement.setAttribute('hidden', 'hidden');
    document.body.classList.add('invisible');
  }, []);

  /// Application specific page URL
  useEffect(() => {
    setApplicationPageURL(productApplicationPageURL);
  }, [productApplicationPageURL]);

  useEffect(() => {
    const getShopTemplate = async () => {
      if (templates && templates.list.length > 0 && !templates.listIsFetching) {
        // get html head
        const masterTemplate: ITemplates = {
          ...templates.list.find((obj: any) => obj.isMasterTemplate === true)
        };
        const template: string = masterTemplate.template!;

        //Note: Remove dark mode on shop pages
        document.body.classList.add(ThemeMode.lightMode);
        document.body.classList.remove(ThemeMode.darkMode);

        if (
          (location &&
            location.pathname &&
            !location.pathname.includes('admin')) ||
          (location &&
            location.pathname &&
            location.pathname.includes('admin/login'))
        ) {
          extractScripts(template);
          setScriptsExtracted(true);

          /// Get ShopHeader HTML & ShopFooter HTML
          if (masterTemplate) {
            const header: string = await asyncErrorHandler(
              getFormattedHTML(masterTemplate, '<header', '</header>', 'navbar')
            );
            setShopHeaderHTML(header);
            const footer: string = await asyncErrorHandler(
              getFormattedHTML(masterTemplate, '<footer', '</footer>', 'footer')
            );
            setFooterHTML(footer);
          }
        }
      }
    };

    /// Extract scripts and get Shop Header & Footer HTML
    getShopTemplate();
  }, [templates, document, applicationPageURL, shopSettings]);

  /// GetPlaceholder for master template.
  const getCustomPlaceHolder = (placeholderPosition: string): IPlaceHolder => {
    let company: string = '';
    const currentYear: number = new Date().getFullYear(); /**< Current year. */
    const component = (
      <InstaImage
        src={`${BASE_API_URL}/store/image/${shopSettings.logo}?from=${placeholderPosition}`}
        style={{ width: '100px' }}
      />
    );
    company = renderCustomComponent(component);

    /// Create a custom placeholder with the dynamic variable 'company' and 'currentYear'
    let customPlaceHolder: IPlaceHolder = {
      company,
      applicationPageURL: applicationPageURL
        ? removeSchemaFromURL(applicationPageURL)
        : shopSettings?.frontendBaseURL
        ? removeSchemaFromURL(shopSettings?.frontendBaseURL)
        : ''
    };

    if (placeholderPosition === 'footer') {
      customPlaceHolder = {
        ...customPlaceHolder,
        currentYear
      };
    }
    return customPlaceHolder;
  };

  /// Get formatted HTML for Header & Footer.
  const getFormattedHTML = async (
    masterTemplate: ITemplates,
    tagStart: string,
    tagEnd: string,
    placeholderPosition: string
  ): Promise<string> => {
    const template: string = masterTemplate.template;
    const tagStartPosition: number = template.indexOf(
      tagStart
    ); /**< Tag start position */
    const tagEndPosition: number = template.indexOf(
      tagEnd
    ); /**< Tag end position */
    const tagHTML: string = template.slice(
      tagStartPosition,
      tagEndPosition
    ); /**< Tag HTML */
    const tagTemplate: ITemplates = {
      ...masterTemplate,
      template: tagHTML
    }; /**< Set template */
    const customPlaceHolder: IPlaceHolder = getCustomPlaceHolder(
      placeholderPosition
    ); /**< Get placeholder */

    const formattedTemplate: string = await asyncErrorHandler(
      formatTemplate(tagTemplate, shopSettings, customPlaceHolder)
    ); /**< Formatted template */

    return formattedTemplate;
  };

  return (
    <>
      {isScriptsExtracted && shopHeaderHTML && shopFooterHTML ? (
        <Route
          {...rest}
          render={(props) => (
            <>
              <AlertToast />
              <ErrorHandler />
              <>
                <Component
                  {...props}
                  isPublicRoute={true}
                  shopHeaderHTML={shopHeaderHTML}
                  shopFooterHTML={shopFooterHTML}
                  languageSelector={
                    <LanguageSelector
                      i18nService={i18n}
                      selectedLanguage={selectedLanguage}
                      setSelectedLanguage={setSelectedLanguage}
                    />
                  }
                />
              </>
            </>
          )}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PublicRoute;
