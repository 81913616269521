/**
 * AddressValidationAPIService.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file AddressValidationAPIService.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IAddressValidation,
  IAddressValidationResponse
} from '@abstract/abstractwebcommon-shared/interfaces/addressValidation';

const httpClient = new HttpClient(BASE_API_URL, createLog);

const baseURLPath: string = '/external/';

/**
 * Check if an address is valid.
 * @param addressToValidate Address object to validate. IAddressValidation interface.
 * @returns Promise<IAPIEntityResponse<IAddressValidationResponse>>
 */
export const checkIsAddressValid = (
  addressToValidate: IAddressValidation
): Promise<IAPIEntityResponse<IAddressValidationResponse>> =>
  httpClient.post(`${baseURLPath}check/address/validation`, {
    address: addressToValidate.address
  });
