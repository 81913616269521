/*
 * AnalyticsPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AnalyticsPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnalyticsContent} from './AnalyticsContent';
import {getAnalytics} from '../../../Store/Analytics';
import eventConstants from '../../../Utils/EventConstants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {CURRENT_AWC_VERSION, CURRENT_VERSION} from '../../../Config';
import {ManualDateRangePicker} from '@abstract/abstractwebcommon-client/DateRangePicker/dateRangePicker';
import './Analytics.css';
import {
  fetchDashboardStatisticsAction,
  fetchDashboardVersionAction,
  fetchFeedAction,
  fetchSystemWarningsAction
} from '../../../Store/Dashboard';
import {useAppSelector} from '../../../Hooks';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {ThemeMode} from '@abstract/abstractwebcommon-shared/enum/theme';
import Dashboard from '@abstract/abstractwebcommon-client/Dashboard';
import {ISoftwareVersionProperties} from '@abstract/abstractwebcommon-client/Dashboard/SoftwareVersion';
import {ISettings} from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import {
  dashboardActions,
  getDashboardState,
  IDashboardState
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import {IMappedFeed} from '@abstract/abstractwebcommon-shared/interfaces/feed';
import AdminDashboardSkeletonWrapper
  from '@abstract/abstractwebcommon-client/SkeletonLoader/DashboardSkeleton/AdminDashboard/AdminDashboardSkeletonWrapper';
import {LocalStorage} from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const AnalyticsPage = () => {
  const node = useRef();
  const defaultDate = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ];
  const [date, setDate] = useState(defaultDate);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.analytics.listIsFetching);
  const retrievalError = useSelector((state) => state.analytics.retrievalError);
  const formattedAnalytics = useSelector(
    (state) => state.analytics.formattedList
  );
  const dashboardState: IDashboardState = useSelector(
    getDashboardState
  ); /**< Dashboard data. */
  const backendVersion: string =
    dashboardState.version; /**< Backend version. */
  const translation: TFunction = useTranslation().t;
  const themeMode: string =
    LocalStorage.getThemeMode() || ThemeMode.lightMode; /**< Theme mode. */
  const chartData: Record<string, number> =
    dashboardState.statistics; /**< Dashboard statistics data. */
  const feedsData: IMappedFeed = dashboardState.feedsData; /**< Feed data. */
  const systemWarnings: Record<string, string>[] =
    dashboardState.systemWarnings; /**< System warnings. */
  const isLoading: boolean =
    !dashboardState.statistics ||
    !dashboardState.version ||
    !dashboardState.feedsData ||
    !dashboardState.systemWarnings;
  const successfulTransactions: number =
    (!isLoading && chartData.successfulTransactions) ||
    0; /**< Total successful transactions */
  const unsuccessfulTransactions: number =
    (!isLoading && chartData.unsuccessfulTransactions) ||
    0; /**< Total unsuccessful transactions */
  const discounts: number =
    (!isLoading && chartData.discounts) || 0; /**< Total discounts */
  const products: number =
    (!isLoading && chartData.products) || 0; /**< Total products */
  const settings: ISettings = useAppSelector(
    (state) => state.settings
  ); /**< Settings */

  /// System data
  const basicData = {
    labels: [
      translation('dashboard.labels.successfulTransactions'),
      translation('dashboard.labels.unsuccessfulTransactions'),
      translation('dashboard.labels.discounts'),
      translation('dashboard.labels.products')
    ],
    datasets: [
      {
        label: translation('dashboard.labels.total'),
        backgroundColor: '#42A5F5',
        data: [
          successfulTransactions,
          unsuccessfulTransactions,
          discounts,
          products
        ]
      }
    ]
  };

  const handleDateChange = async (event) => {
    const selection = event.selection;
    setDate([selection]);
      await asyncErrorHandler(dispatch(
          getAnalytics(selection.startDate, selection.endDate, eventConstants)
      ));
  };

  useEffect(() => {
    const fetchData = async () => {
        await asyncErrorHandler(dispatch(getAnalytics(new Date(), new Date(), eventConstants)));
    };
    fetchData();
  }, [getAnalytics]);

  useEffect(() => {
    dispatch(fetchDashboardStatisticsAction());
    dispatch(fetchDashboardVersionAction());
    dispatch(fetchFeedAction());
    dispatch(fetchSystemWarningsAction());

    // on unmount
    return () => {
      dispatch(dashboardActions.reset());
    };
  }, [dispatch]);

  if (
    !settings.setting ||
    !dashboardState.statistics ||
    !dashboardState.version ||
    !dashboardState.feedsData ||
    !dashboardState.systemWarnings
  ) {
    return <AdminDashboardSkeletonWrapper />;
  }
  /// Commit version details.
  const frontendCommitLink: string = `https://bitbucket.org/TheAbstractCo/abstractecommerce/commits/${CURRENT_VERSION}`;
  const frontendCurrentVersion: string = `${CURRENT_VERSION}`;
  const backendCommitLink: string = `https://bitbucket.org/TheAbstractCo/abstractecommerce/commits/${backendVersion}`;
  const backendCurrentVersion: string = backendVersion;
  const awcCommitLink: string = `https://bitbucket.org/TheAbstractCo/abstractecommerce/commits/${CURRENT_AWC_VERSION}`;
  const awcCurrentVersion: string = CURRENT_AWC_VERSION ?? '';
  const dashboardTitle: string =
    settings && settings.setting.applicationTitle
      ? translation('dashboard.dashboard_fieldset.welcome', {
          applicationTitle: settings.setting.applicationTitle
        })
      : translation('page_title'); /**< Dashboard Title. */
  const versionData: ISoftwareVersionProperties = {
    frontendCommitLink: frontendCommitLink,
    frontendCurrentVersion: frontendCurrentVersion,
    backendCommitLink: backendCommitLink,
    backendCurrentVersion: backendCurrentVersion,
    awcCommitLink: awcCommitLink,
    awcCurrentVersion: awcCurrentVersion
  }; /**< Software version data. */

  return (
    <>
      <Dashboard
        dashboardTitle={dashboardTitle}
        systemWarnings={systemWarnings}
        softwareVersion={versionData}
        feedsData={feedsData}
        isLoading={isLoading}
        themeMode={themeMode}
        systemData={basicData}
        systemDataNotes={translation('dashboard.note_text')}
      />
      <Row>
        <Col sm={12} md={9} lg={6}>
          <ManualDateRangePicker
            node={node}
            date={date}
            defaultDate={defaultDate}
            handleDateChange={handleDateChange}
            isClearButtonVisible={false}
          />
        </Col>
        <Col></Col>
      </Row>
      {!retrievalError && (
        <Row>
          <Col sm={12} md={12}>
            <AnalyticsContent data={formattedAnalytics} date={date} />
          </Col>
        </Row>
      )}
      {retrievalError && (
        <Row className="d-flex justify-content-center align-items-center mt-5 flex-column">
          <h2>An Error Occured</h2>
          <p>Couldn't retrieve analytics</p>
        </Row>
      )}
    </>
  );
};

export default AnalyticsPage;
