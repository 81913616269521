/*
 * InstaTable.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file InstaTable.tsx
 * @author Sai Charan K
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * This component has the responsive classes and design for tha datatable which is used in both user and license
 * Also this has the properties required for pagination, row expansion, header, servierside sorting and filtering.
 * If you are planning to use the table with the above requirements, use this component.
 */
import React, { ReactElement } from "react";
import BaseDatatable from "./BaseDatatable";

interface ITablePayload {
  limit: number;
  skip: number;
}

export interface IInstaTableProperties {
  expandedRows: any[];
  parentClass?: string;
  data: any[];
  header?: any;
  selectedList?: any;
  dataKey: string;
  className?: string;
  getRowExpansionTemplate: (data: any) => void;
  onSelectionChange: (event: any) => void;
  isLazy?: boolean;
  isLoading: boolean;
  payload?: ITablePayload;
  rowsPerPage?: number[];
  totalRecords: number;
  onPage?: (event: any) => void;
  expandRow?: (event: any) => void;
  setExpandedRows?: (event: any) => void;
  multiSortMeta?: any;
  onSort?: (event: any) => void;
  onFilter?: (event: any) => void;
  sortMode?: string;
  tableFilters?: any;
  sortField?: any;
  sortOrder?: any;
  children?: any;
  selectionMode?: string;
}

const InstaTable = ({
  expandedRows,
  parentClass,
  data,
  header,
  selectedList,
  dataKey,
  className,
  getRowExpansionTemplate,
  onSelectionChange,
  isLoading,
  payload,
  rowsPerPage,
  totalRecords,
  onPage,
  expandRow,
  setExpandedRows,
  multiSortMeta,
  onSort,
  onFilter,
  sortMode,
  tableFilters,
  selectionMode,
  ...properties
}: IInstaTableProperties): ReactElement => {
  return (
    <div
      className={
        Object.keys(expandedRows).length
          ? `base-datatable-responsive expanded ${parentClass}`
          : `base-datatable-responsive ${parentClass}`
      }
    >
      <BaseDatatable
        value={data}
        header={header}
        selection={selectedList}
        dataKey={dataKey}
        selectionMode={selectionMode || "multiple"}
        expandedRows={expandedRows}
        rowExpansionTemplate={(rowExpansionData: any) =>
          getRowExpansionTemplate(rowExpansionData)
        }
        onSelectionChange={(event: any) => onSelectionChange(event)}
        isLoading={isLoading}
        rows={payload?.limit}
        rowsPerPageOptions={rowsPerPage}
        first={payload?.skip}
        totalRecords={totalRecords}
        onPage={(event: any) => onPage(event)}
        onRowExpand={(event: any) => expandRow(event)}
        onRowCollapse={() => setExpandedRows({})}
        multiSortMeta={multiSortMeta}
        onSort={(event: any) => onSort(event)}
        onFilter={(event: any) => onFilter(event)}
        sortMode={sortMode}
        filters={tableFilters}
        {...properties}
      >
        {properties.children}
      </BaseDatatable>
    </div>
  );
};

export default InstaTable;
