/*
 * sharedFunctions.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file sharedFunctions.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { SubscriptionStatus } from '@abstract/abstractwebcommon-shared/enum/ecommerce/braintreeSubscriptionStatus';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { differenceInDays } from 'date-fns';

/**
 * Shared functions for the subscription entity.
 */
export class SubscriptionSharedFucntions {
  /**
   * Refund Subscription function can only be executed with it's within specific days window defined in Settings page since the subscription creation.
   * @param subscriptionDetails ISubscription.
   * @param refundWindow Refund window defined in Settings page.
   * @param returns boolean.
   */
  static isWithinSubscriptionRefundWindow = (
    subscriptionDetails: ISubscription,
    refundWindow: number
  ): boolean => {
    const daysSinceSubscriptionStart: number = differenceInDays(
      new Date(),
      new Date(subscriptionDetails.createdAt!)
    );

    return daysSinceSubscriptionStart <= refundWindow ? true : false;
  };

  /**
   * Validates if user can use the Refund a subscription in Subscription page. Used for admin and non-admin users.
   * @param subscriptionDetails ISubscription.
   * @param refundWindow Refund window defined in Settings page.
   * @param returns boolean.
   */
  static isRefundOptionAvailable = (
    subscription: ISubscription,
    refundWindow: number
  ): boolean =>
    subscription.localSubscriptionStatus === SubscriptionStatus.Active &&
    this.isWithinSubscriptionRefundWindow(subscription, refundWindow);
}
