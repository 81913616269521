/*
 * ProductForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductForm.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import ProductFormOptions from './ProductFormOptions';
import ProductFormFields from './ProductFormFields';
import { useTranslation } from 'react-i18next';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import i18n from '../../../Services/I18n';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import ProductSLAInformationForm from './ProductSLAInformationForm';
import Button from 'react-bootstrap/Button';
import ProductCheckoutOptionsForm from './ProductCheckoutOptionsForm';
import { UseSeatQuantityEnum } from '@abstract/abstractwebcommon-shared/enum/ecommerce/products';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { ProductType } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import {
  isArrayOfStringsTheSame,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import {
  IApplication,
  INextStepButton
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import NextStepButtonForm from '../ApplicationManagement/NextStepButtonForm';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import { IApplicationTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ApplicationTranslation';
import { Checkbox } from 'primereact/checkbox';
import Form from 'react-bootstrap/Form';

/**
 * Interface for ProductForm properties.
 */
interface IProductFormProperties {
  values?: {
    options?: any;
    price?: any;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  errors?: {
    options?: any;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  handleChange?: any;
  handleSubmit: (values: any) => void;
  loading: boolean;
  product: any;
  handleDeactivation: (product: any) => void;
  handleBlur?: any;
  touched?: {
    options?: any;
    translationData?: IProductTranslation[] /**< Product translation data */;
    [key: string]: any /**< Dynamic properties */;
  };
  setFieldValue?: any;
  handleDelete: (productsUUIDs: string[]) => void /**< Handle delete item */;
}

const ProductForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  product,
  handleDeactivation,
  handleBlur,
  touched,
  setFieldValue,
  handleDelete
}: IProductFormProperties) => {
  const translation = useTranslation().t;
  const [
    isDeactivateConfirmationDialogVisible,
    setDeactivateConfirmationDialogVisible
  ] = useState(false); /**< Deactivate Confirmation dialog visibility. */
  const [logoUploadStatus, setLogoUploadStatus] = useState(
    ''
  ); /**< Logo upload status. */
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */
  const [isShowSLAForm, setShowSLAForm] = useState<boolean>(
    values?.sla ?? false
  ); /**< Show SLA Form. */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    values?.translationData?.findIndex(
      (data: IProductTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = values?.translationData?.findIndex(
    (data: IProductTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */
  const [selectedApplication, setSelectedApplication] = useState<
    IApplication
  >(); /**< Selected Application */
  const [initialApplicationID, setInitialApplicationID] = useState<
    string
  >(); /**< Application ID */
  const [initialNextStepButtons, setInitialNextStepButtons] = useState<
    INextStepButton[]
  >(); /**< Next step buttons */
  const [initialTranslationData, setInitialTranslationData] = useState<
    IProductTranslation[]
  >(); /**< Translation data */
  const [
    isCallToActionCheckboxVisible,
    setCallToActionCheckboxVisible
  ] = useState<boolean>(false); /**< Show Call to action checkbox. */
  const [callToActionCheckboxValue, setCallToActionCheckboxValue] = useState<
    boolean
  >(false); /**< Set Call to action checkbox value. */

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete product on Accept
  const onAccept = () => {
    const productIDs: string[] = [product].map(
      (eachProduct: IProduct) => eachProduct._id
    ); /**< productIDs */
    handleDelete(productIDs);
    setIsShowingConfirmation(false);
  };

  // Set initial value of applicationID, nextstep buttons and translationData
  useEffect(() => {
    setInitialApplicationID(values?.applicationID);
    setInitialNextStepButtons(values?.nextStepTextButtons);
    setInitialTranslationData(values?.translationData);
  }, []);

  useEffect(() => {
    setSelectedLanguageTranslationIndex(
      values?.translationData?.findIndex(
        (data: IProductTranslation) => data.language === selectedLanguage
      )
    );
  }, [selectedLanguage, values?.translationData]);

  useEffect(() => {
    if (selectedApplication) {
      // Set CallToActionCheckboxValue based on product's next step text(in translation data) and next step buttons
      if (initialApplicationID === values?.applicationID) {
        setCallToActionCheckboxValue(
          initialNextStepButtons?.length !== 0 ||
            !isStringEmptyOrNullOrUndefined(
              initialTranslationData?.[englishTranslationIndex]?.nextStepText
            )
            ? true
            : false
        );
      } else {
        // If application changes, reset the checkbox value
        setCallToActionCheckboxValue(false);
      }

      // Set CallToActionCheckboxVisible based on selected application's next step text(in translation data) and next step buttons
      setCallToActionCheckboxVisible(
        selectedApplication?.nextStepTextButtons?.length !== 0 ||
          !isStringEmptyOrNullOrUndefined(
            selectedApplication?.translationData?.[englishTranslationIndex]
              ?.nextStepText
          ) ||
          initialNextStepButtons?.length !== 0 ||
          !isStringEmptyOrNullOrUndefined(
            initialTranslationData?.[englishTranslationIndex]?.nextStepText
          )
          ? true
          : false
      );
    }
  }, [selectedApplication]);

  useEffect(() => {
    if (selectedApplication && callToActionCheckboxValue) {
      // To update next step button object
      if (
        selectedApplication.nextStepTextButtons &&
        selectedApplication.nextStepTextButtons.length
      ) {
        if (initialApplicationID === values?.applicationID) {
          // If there are no nextStepTextButtons in product, set the application's nextStepTextButtons by default
          if (!initialNextStepButtons || initialNextStepButtons.length === 0) {
            setFieldValue(
              'nextStepTextButtons',
              selectedApplication.nextStepTextButtons
            );
          } else {
            setFieldValue('nextStepTextButtons', initialNextStepButtons);
          }
        } else {
          // If we change the application, set its nextStepTextButtons to product
          setFieldValue(
            'nextStepTextButtons',
            selectedApplication.nextStepTextButtons
          );
        }
      }
      // To update next step text and button translation
      if (
        selectedApplication.translationData &&
        selectedApplication.translationData.length
      ) {
        for (const language of Languages) {
          const applicationTranslation:
            | IApplicationTranslation
            | undefined = selectedApplication?.translationData.find(
            (data: IProductTranslation) => data.language === language.value
          );
          const translationIndex: number = initialTranslationData?.findIndex(
            (data: IProductTranslation) => data.language === language.value
          );
          if (initialApplicationID === values?.applicationID) {
            if (
              isStringEmptyOrNullOrUndefined(
                initialTranslationData?.[translationIndex]?.nextStepText
              )
            ) {
              setFieldValue(
                `translationData.${translationIndex}.nextStepText`,
                applicationTranslation?.nextStepText ?? ''
              );
            } else {
              setFieldValue(
                `translationData.${translationIndex}.nextStepText`,
                initialTranslationData?.[translationIndex]?.nextStepText
              );
            }
            if (
              !initialTranslationData?.[translationIndex]
                ?.nextStepButtonsText ||
              initialTranslationData?.[translationIndex]?.nextStepButtonsText
                .length === 0
            ) {
              setFieldValue(
                `translationData.${translationIndex}.nextStepButtonsText`,
                applicationTranslation?.nextStepButtonsText ?? []
              );
            } else {
              setFieldValue(
                `translationData.${translationIndex}.nextStepButtonsText`,
                initialTranslationData?.[translationIndex]
                  ?.nextStepButtonsText ?? []
              );
            }
          } else {
            setFieldValue(
              `translationData.${translationIndex}.nextStepText`,
              applicationTranslation?.nextStepText ?? ''
            );
            setFieldValue(
              `translationData.${translationIndex}.nextStepButtonsText`,
              applicationTranslation?.nextStepButtonsText ?? []
            );
          }
        }
      }
    }
  }, [selectedApplication, callToActionCheckboxValue]);

  return (
    <>
      <FormWrapper
        controlButtonLabel={product && Object.keys(product).length > 0}
        isLoading={loading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => {
          if (product) {
            values.logoUploadStatus = logoUploadStatus;
          }
          // If quantityType is fixed, set seatQuantity to 0
          if (
            values?.quantityType &&
            values?.quantityType === UseSeatQuantityEnum.Fixed
          ) {
            setFieldValue('seatQuantity', 0);
          }
          // If there is no nextStep text and buttons object in selected application(depends on isCallToActionCheckboxVisible) or if no need to save these values in product table (depends on callToActionCheckboxValue), empty next step and button values in product
          if (!isCallToActionCheckboxVisible || !callToActionCheckboxValue) {
            setFieldValue('nextStepTextButtons', []);
            for (const language of Languages) {
              const translationIndex: number = initialTranslationData?.findIndex(
                (data: IProductTranslation) => data.language === language.value
              );
              setFieldValue(
                `translationData.${translationIndex}.nextStepText`,
                ''
              );
              setFieldValue(
                `translationData.${translationIndex}.nextStepButtonsText`,
                []
              );
            }
          }
          handleSubmit(values);
          setLogoUploadStatus('');
        }}
      >
        <Row>
          <ProductFormFields
            values={values}
            errors={errors}
            handleChange={handleChange}
            loading={loading}
            handleBlur={handleBlur}
            touched={touched}
            product={product}
            setFieldValue={setFieldValue}
            setLogoUploadStatus={setLogoUploadStatus}
            setDeactivateConfirmationDialogVisible={
              setDeactivateConfirmationDialogVisible
            }
            setShowSLAForm={setShowSLAForm}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguageTranslationIndex={selectedLanguageTranslationIndex}
            englishTranslationIndex={englishTranslationIndex}
            selectedApplication={selectedApplication}
            setSelectedApplication={setSelectedApplication}
          />
        </Row>
        {isShowSLAForm && (
          <Row>
            <Col sm={12}>
              <ProductSLAInformationForm
                values={values?.translationData}
                errors={errors?.translationData}
                handleChange={handleChange}
                loading={loading}
                handleBlur={handleBlur}
                touched={touched?.translationData}
                setFieldValue={setFieldValue}
                selectedLanguage={selectedLanguage}
                selectedLanguageTranslationIndex={
                  selectedLanguageTranslationIndex
                }
                englishTranslationIndex={englishTranslationIndex}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            <ProductFormOptions
              values={values?.options}
              errors={errors?.options}
              handleChange={handleChange}
              loading={loading}
              handleBlur={handleBlur}
              touched={touched?.options}
              setFieldValue={setFieldValue}
              price={values?.price}
              selectedLanguage={selectedLanguage}
              translationData={values?.translationData}
              translationErrors={errors?.translationData}
              translationTouched={touched?.translationData}
              selectedLanguageTranslationIndex={
                selectedLanguageTranslationIndex
              }
              englishTranslationIndex={englishTranslationIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ProductCheckoutOptionsForm
              values={values?.translationData}
              errors={errors?.translationData}
              handleChange={handleChange}
              isLoading={loading}
              handleBlur={handleBlur}
              touched={touched?.translationData}
              setFieldValue={setFieldValue}
              selectedLanguage={selectedLanguage}
              selectedLanguageTranslationIndex={
                selectedLanguageTranslationIndex
              }
              englishTranslationIndex={englishTranslationIndex}
            />
          </Col>
        </Row>
        {isCallToActionCheckboxVisible ? (
          <Row>
            <Form.Group as={Col} sm={12} md={12}>
              <Checkbox
                inputId="isCallToActionAdded"
                onChange={(event) => {
                  setCallToActionCheckboxValue(event.checked);
                }}
                name="isCallToActionAdded"
                checked={callToActionCheckboxValue}
              />
              <Form.Label htmlFor="isCallToActionAdded" className="ml-2">
                {translation('fields.product.isCallToActionAdded')}
              </Form.Label>
            </Form.Group>
            {callToActionCheckboxValue ? (
              <>
                {!isStringEmptyOrNullOrUndefined(
                  selectedApplication?.translationData?.[
                    englishTranslationIndex
                  ]?.nextStepText
                ) ||
                !isStringEmptyOrNullOrUndefined(
                  initialTranslationData?.[englishTranslationIndex]
                    ?.nextStepText
                ) ? (
                  <InstaTextArea
                    sizing={{ sm: 12, md: 12 }}
                    label={translation('application.fields.nextStepText')}
                    fieldName={`translationData.${selectedLanguageTranslationIndex}.nextStepText`}
                    id={`translationData.${selectedLanguageTranslationIndex}.nextStepText`}
                    isLoading={loading}
                    value={
                      values?.translationData?.[
                        selectedLanguageTranslationIndex
                      ]?.nextStepText
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={
                      touched?.translationData?.[
                        selectedLanguageTranslationIndex
                      ]?.nextStepText
                    }
                    errors={
                      errors?.translationData?.[
                        selectedLanguageTranslationIndex
                      ]?.nextStepText
                    }
                    rows={2}
                  />
                ) : (
                  <></>
                )}
                {selectedApplication?.nextStepTextButtons?.length !== 0 ||
                initialNextStepButtons?.length !== 0 ? (
                  <Col sm={12}>
                    <NextStepButtonForm
                      values={values.nextStepTextButtons}
                      errors={errors.nextStepTextButtons}
                      handleChange={handleChange}
                      isLoading={loading}
                      handleBlur={handleBlur}
                      touched={touched.nextStepTextButtons}
                      setFieldValue={setFieldValue}
                      selectedLanguage={selectedLanguage}
                      translationData={values.translationData}
                      translationErrors={errors.translationData}
                      translationTouched={touched.translationData}
                      selectedLanguageTranslationIndex={
                        selectedLanguageTranslationIndex
                      }
                      englishTranslationIndex={englishTranslationIndex}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Row>
        ) : (
          <></>
        )}
        <Row>
          {product && product.live && (
            <DialogWrapper
              isDialogVisible={isDeactivateConfirmationDialogVisible}
              onHide={() => setDeactivateConfirmationDialogVisible(false)}
              headerTitle={translation('confirm_messages.deactivate_product')}
            >
              <Button
                className="d-flex align-items-center justify-content-center w-100"
                type="button"
                variant="primary"
                onClick={() => {
                  handleDeactivation(product);
                  setDeactivateConfirmationDialogVisible(false);
                }}
              >
                Confirm
              </Button>
            </DialogWrapper>
          )}
        </Row>
      </FormWrapper>

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowingConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={() => setIsShowingConfirmation(false)}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="top"
      />
    </>
  );
};

Yup.addMethod(Yup.array, 'unique', function(message, mapper) {
  return this.test(
    'unique',
    message,
    (list) => list.length === new Set(list.map(mapper)).size
  );
});

export default withFormik({
  mapPropsToValues: ({ product }) => {
    // Format inital product translation
    const initialTranslationData: IProductTranslation[] = [];
    for (let index = 0; index < Languages.length; index++) {
      const language: string = Languages[index].value;
      initialTranslationData.push({
        language: language,
        description: '',
        freeProductDiscountName: '',
        slaInformation: [],
        productOptionsDescription: [],
        checkoutOptionsDescription: [],
        productOptionsInformation: [],
        disabledProductMessage: '',
        nextStepText: '',
        nextStepButtonsText: []
      });
    }

    if (!product) {
      return {
        name: '',
        sku: '',
        maxQuantity: 0,
        seatQuantity: 0,
        price: 0,
        live: false,
        sla: false,
        options: [],
        image: '',
        file: '',
        subscriptionPlanID: '',
        duration: null,
        type: ProductType.OneShot,
        applicationID: '',
        isLoginRequired: false,
        quantityType: UseSeatQuantityEnum.Fixed,
        translationData: initialTranslationData,
        customFieldKeys: [],
        isPaymentSectionHidden: false,
        isDisabled: false,
        nextStepTextButtons: [],
        isCustomerDetailsSectionHidden: false
      };
    }

    // Note: Get the product translation if it already exists and corresponds to the same language array. Otherwise, add/delete a language object or use initialTranslationData.
    let productTranslationData: IProductTranslation[] = [];
    if (product.translationData && product.translationData.length) {
      initialTranslationData.forEach((initialData: IProductTranslation) => {
        product.translationData.forEach((existingData: IProductTranslation) => {
          if (initialData.language === existingData.language) {
            productTranslationData.push(existingData);
          }
        });
      });
      if (productTranslationData.length !== initialTranslationData.length) {
        const newLanguageData: IProductTranslation[] = initialTranslationData.filter(
          (initialData: IProductTranslation) =>
            !productTranslationData.some(
              (existingData: IProductTranslation) =>
                initialData.language === existingData.language
            )
        );
        productTranslationData = productTranslationData.concat(newLanguageData);
      }
    } else {
      productTranslationData = initialTranslationData;
    }

    return {
      name: product.name,
      sku: product.sku,
      maxQuantity: product.maxQuantity,
      seatQuantity: product.seatQuantity,
      price: product.price,
      options: product.options,
      live: product.live,
      sla: product.sla,
      image: product.image,
      subscriptionPlanID: product.subscriptionPlanID ?? '',
      duration: product.duration === 0 ? '' : product.duration,
      type: product.type,
      applicationID: product.applicationID ?? '',
      isLoginRequired: product.isLoginRequired,
      quantityType: product.quantityType,
      translationData: productTranslationData,
      customFieldKeys: product.customFieldKeys,
      isPaymentSectionHidden: product.isPaymentSectionHidden,
      isDisabled: product.isDisabled,
      nextStepTextButtons: product.nextStepTextButtons ?? [],
      isCustomerDetailsSectionHidden: product.isCustomerDetailsSectionHidden
    };
  },
  validateOnChange: true,
  validateOnMount: false,
  validationSchema: () => {
    const translationValidationSpec = {
      language: Yup.string(),
      description: Yup.string().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.string()
          .nullable()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.product.description')
            })
          ),
        otherwise: Yup.string().nullable()
      }),
      slaInformation: Yup.array().nullable(),
      productOptionsDescription: Yup.array().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.array().of(
          Yup.string()
            .min(3, 'Must be 3 characters or more')
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.productOptions.description')
              })
            )
        ),
        otherwise: Yup.array().of(
          Yup.string()
            .nullable()
            .min(3, 'Must be 3 characters or more')
        )
      }),
      checkoutOptionsDescription: Yup.array().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.array().of(
          Yup.string()
            .min(3, 'Must be 3 characters or more')
            .nullable()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('product.checkout-options.description')
              })
            )
        ),
        otherwise: Yup.array().of(
          Yup.string()
            .nullable()
            .min(3, 'Must be 3 characters or more')
        )
      }),
      productOptionsInformation: Yup.array().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.array().of(
          Yup.string()
            .min(3, 'Must be 3 characters or more')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.productOptions.information')
              })
            )
        ),
        otherwise: Yup.array().of(
          Yup.string()
            .nullable()
            .min(3, 'Must be 3 characters or more')
        )
      }),
      nextStepText: Yup.string().nullable(),
      nextStepButtonsText: Yup.array().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.array().of(
          Yup.string().required(
            i18n.t('validation.required', {
              field: i18n.t(
                'application.fields.nextStepButton.fields.buttonText'
              )
            })
          )
        ),
        otherwise: Yup.array().of(Yup.string().nullable())
      })
    }; /**< Translation validation */

    const validationSpec = {
      live: Yup.boolean(),
      sla: Yup.boolean(),
      type: Yup.string().oneOf(Object.keys(ProductType)),
      subscriptionPlanID: Yup.string().nullable(),
      name: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.name')
          })
        ),
      sku: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.sku')
          })
        ),
      maxQuantity: Yup.number()
        .typeError('Must be a number')
        .positive('Must be a number above 0')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.maxQuantity')
          })
        ),
      quantityType: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.quantityType')
          })
        ),
      seatQuantity: Yup.number()
        .nullable()
        .when('quantityType', {
          is: UseSeatQuantityEnum.Seat,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.seatQuantity')
              })
            )
        }),
      price: Yup.number()
        .typeError('Must be a number')
        .positive('Must be a number above 0')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.price')
          })
        ),
      options: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string()
              .min(3, 'Must be 3 characters or more')
              .required(
                i18n.t('validation.required', {
                  field: i18n.t('fields.productOptions.name')
                })
              ),
            amount: Yup.number(),
            percentage: Yup.number(),
            per_product_qty: Yup.boolean()
          })
        )
        .unique(
          { label: 'validation.unique', field: 'Name' },
          (option) => option.name
        ),
      duration: Yup.number()
        .nullable()
        .when('isUniqueProduct', {
          is: true,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.duration')
              })
            )
        })
        .when(['sla', 'type'], {
          is: (sla: boolean, type: string) =>
            sla && type !== ProductType.OneShot,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.duration')
              })
            )
        }),
      applicationID: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('fields.product.company')
        })
      ),
      isLoginRequired: Yup.boolean(),
      translationData: Yup.array()
        .when('isUniqueProduct', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              freeProductDiscountName: Yup.string().when('language', {
                is: LanguageSettingsMode.english,
                then: Yup.string().required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.product.freeProductDiscountName')
                  })
                ),
                otherwise: Yup.string().nullable()
              })
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              freeProductDiscountName: Yup.string().nullable()
            })
          )
        })
        .when('isDisabled', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              disabledProductMessage: Yup.string().when('language', {
                is: LanguageSettingsMode.english,
                then: Yup.string().required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.product.disabledProductMessage')
                  })
                ),
                otherwise: Yup.string().nullable()
              })
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              disabledProductMessage: Yup.string().nullable()
            })
          )
        }),
      customFieldKeys: Yup.array(),
      isPaymentSectionHidden: Yup.boolean().nullable(),
      isDisabled: Yup.boolean(),
      nextStepTextButtons: Yup.array().of(
        Yup.object().shape({
          buttonURL: Yup.string()
            .matches(validators.RSS_URL, i18n.t('validation.isUrl'))
            .required(
              i18n.t('validation.required', {
                field: i18n.t(
                  'application.fields.nextStepButton.fields.buttonURL'
                )
              })
            ),
          buttonIcon: Yup.string().nullable(),
          buttonCSS: Yup.string().nullable()
        })
      ),
      isCustomerDetailsSectionHidden: Yup.boolean().nullable()
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bag) => {
    if (bag.props.product) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (key == 'logoUploadStatus') {
          if (values[key]) {
            updatedFieldsCount++;
          }
        } else if (key == 'options') {
          if (values[key].length == bag.props.product[key].length) {
            const newOptionsString: string = JSON.stringify(values[key].sort());
            const initialOptionsString: string = JSON.stringify(
              bag.props.product[key].sort()
            );
            if (newOptionsString != initialOptionsString) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'translationData') {
          if (values[key].length == bag.props.product[key].length) {
            const newTranslationsString: string = JSON.stringify(
              values[key].sort()
            );
            const initialTranslationsString: string = JSON.stringify(
              bag.props.product[key].sort()
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          } else {
            const newTranslation = values[key].map(
              (translation: IProductTranslation) => {
                return {
                  language: translation?.language,
                  description: translation?.description,
                  freeProductDiscountName: translation?.freeProductDiscountName,
                  slaInformation: translation?.slaInformation,
                  productOptionsDescription:
                    translation?.productOptionsDescription,
                  checkoutOptionsDescription:
                    translation?.checkoutOptionsDescription,
                  productOptionsInformation:
                    translation?.productOptionsInformation,
                  disabledProductMessage: translation?.disabledProductMessage,
                  nextStepText: translation?.nextStepText,
                  nextStepButtonsText: translation?.nextStepButtonsText
                };
              }
            );
            const newTranslationsString: string = JSON.stringify(
              newTranslation.sort()
            );
            // Format inital product translation
            const initialTranslationData: IProductTranslation[] = [];
            for (let index = 0; index < Languages.length; index++) {
              const language: string = Languages[index].value;
              const existingTranslation: IProductTranslation =
                bag.props.product[key] &&
                bag.props.product[key].length &&
                bag.props.product[key][
                  bag.props.product[key]?.findIndex(
                    (data: IProductTranslation) => data.language === language
                  )
                ];
              initialTranslationData.push({
                language: language,
                description: existingTranslation?.description ?? '',
                freeProductDiscountName:
                  existingTranslation?.freeProductDiscountName ?? '',
                slaInformation: existingTranslation?.slaInformation ?? [],
                productOptionsDescription:
                  existingTranslation?.productOptionsDescription ?? [],
                checkoutOptionsDescription:
                  existingTranslation?.checkoutOptionsDescription ?? [],
                productOptionsInformation:
                  existingTranslation?.productOptionsInformation ?? [],
                disabledProductMessage:
                  existingTranslation?.disabledProductMessage ?? '',
                nextStepText: existingTranslation?.nextStepText ?? '',
                nextStepButtonsText:
                  existingTranslation?.nextStepButtonsText ?? []
              });
            }
            const initialTranslationsString: string = JSON.stringify(
              initialTranslationData
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          }
        } else if (key === 'customFieldKeys') {
          if (values[key].length == bag.props.product[key].length) {
            if (!isArrayOfStringsTheSame(values[key], bag.props.product[key])) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'nextStepTextButtons') {
          if (values[key].length == bag.props.product[key].length) {
            const newString: string = JSON.stringify(values[key].sort());
            const initialString: string = JSON.stringify(
              bag.props.product[key].sort()
            );
            if (newString != initialString) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'price') {
          if (
            values[key as keyof typeof bag.props.product] !=
            bag.props.product[key as keyof typeof bag.props.product]
          ) {
            updatedFieldsCount++;
          }
        } else if (
          key !== 'image' &&
          values[key as keyof typeof bag.props.product] !==
            bag.props.product[key as keyof typeof bag.props.product]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit({
          _id: bag.props.product._id,
          ...values
        });
      }
      return;
    }
    return bag.props.handleSubmit({ ...values });
  }
})(ProductForm);
