/*
 * SettingsInvoiceSummaryForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingsInvoiceSummaryForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import i18n from '../../../Services/I18n';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IPDFInvoice } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';

/**
 * @enum PDFInvoiceSettingFields
 */
export enum PDFInvoiceSettingFields {
  PaymentMethod = 'paymentMethod',
  SummaryText = 'summaryText',
  VatReverseCharge = 'vatReverseCharge',
  VatNoTaxes = 'vatNoTaxes'
}

/**
 * Interface for SettingsInvoiceSummaryForm properties.
 */
interface ISettingsInvoiceSummaryFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  loading: boolean;
  handleBlur: any;
  touched: any;
  adminSettings: any;
  RenderSkeletonComponent: any /**< Render a skeleton for each field in the form */;
  changeInputClassName: (
    isFileField?: boolean
  ) =>
    | string
    | undefined /**< Control when to display the field in the form based on data loading */;
}

const SettingsInvoiceSummaryForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  handleBlur,
  touched,
  adminSettings,
  RenderSkeletonComponent,
  changeInputClassName
}: ISettingsInvoiceSummaryFormProperties) => {
  const translation = useTranslation().t;
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const [isPDFInvoiceUpdatesExist, setPDFInvoiceUpdatesExist] = useState<
    boolean
  >(false); /**< isPDFInvoiceUpdatesExist. */
  const isPDFInvoiceErrorsExists: boolean =
    errors && Object.keys(errors).length > 0; /**< isPDFInvoiceErrorsExists. */

  /// Show save popup
  const handleSave = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event?.target);
  };

  /// Save on Accept
  const onAccept = () => {
    setShowConfirmationPopup(false);
    handleSubmit();
    setPDFInvoiceUpdatesExist(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmationPopup(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={translation('confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidatePDFInvoiceUpdates(event.target);
  };

  /// Validate PDF Invoice Summary updates
  const revalidatePDFInvoiceUpdates = (currentUpdate: any): void => {
    const invoiceValues: IPDFInvoice = { ...values };
    if (invoiceValues) {
      invoiceValues[currentUpdate.id as keyof IPDFInvoice] =
        currentUpdate.value;
    }
    setPDFInvoiceUpdatesExist(checkIfPDFInvoiceUpdatesExists(invoiceValues));
  };

  /// Check PDF Invoice updates exists or not.
  const checkIfPDFInvoiceUpdatesExists = (
    invoiceValues: IPDFInvoice
  ): boolean => {
    return (
      invoiceValues && Object.keys(mapInvoiceValues(invoiceValues)).length !== 0
    );
  };

  /// PDF Invoice Values
  const mapInvoiceValues = (invoiceValues: IPDFInvoice) => {
    const payload: IPDFInvoice = {};
    const pdfInvoice: Record<string, any> =
      adminSettings && adminSettings.pdfInvoice; /**< PDF Invoice */
    if (!pdfInvoice) {
      return {};
    }

    const remarkText: string =
      pdfInvoice.default.summary.find(
        (item) => item.name === PDFInvoiceSettingFields.SummaryText
      )?.text || '';

    const reverseTaxText: string =
      pdfInvoice.default.summary.find(
        (item) => item.name === PDFInvoiceSettingFields.VatReverseCharge
      )?.text || '';

    const noTaxText: string =
      pdfInvoice.default.summary.find(
        (item) => item.name === PDFInvoiceSettingFields.VatNoTaxes
      )?.text || '';

    if (remarkText !== invoiceValues.remarkText) {
      payload['remarkText'] = invoiceValues.remarkText;
    }
    if (reverseTaxText !== invoiceValues.reverseTaxText) {
      payload['reverseTaxText'] = invoiceValues.reverseTaxText;
    }
    if (noTaxText !== invoiceValues.noTaxText) {
      payload['noTaxText'] = invoiceValues.noTaxText;
    }

    return payload;
  };

  return (
    <Fieldset
      legend={translation('setting.headers.change_pdf_invoice_settings')}
    >
      <Form>
        <Row>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.invoiceSettings.remarkText')}
              name="remarkText"
              id={'remarkText'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.remarkText}
              errors={errors.remarkText}
              value={values?.remarkText}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={translation('setting.fields.remarkText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.invoiceSettings.noTaxText')}
              name="noTaxText"
              id={'noTaxText'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.noTaxText}
              errors={errors.noTaxText}
              value={values?.noTaxText}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={translation('setting.fields.noTaxText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
          <Form.Group as={Col} sm="12">
            <InstaInputText
              label={i18n.t('fields.invoiceSettings.reverseTaxText')}
              name="reverseTaxText"
              id={'reverseTaxText'}
              onChange={onChange}
              onBlur={handleBlur}
              touched={touched.reverseTaxText}
              errors={errors.reverseTaxText}
              value={values?.reverseTaxText}
              labelClassName="required"
              spanClassName={changeInputClassName()}
              isShowInformationPopup={true}
              popupText={translation('setting.fields.reverseTaxText_popupText')}
            />
            {RenderSkeletonComponent()}
          </Form.Group>
        </Row>
        <Row>
          <Col sm={12} className="clearfix pb-0">
            <Button
              className={`p-button-raised p-button-primary btn-block ${
                loading ||
                isShowConfirmationPopup ||
                !isPDFInvoiceUpdatesExist ||
                isPDFInvoiceErrorsExists
                  ? 'custom-disabled-button'
                  : ''
              }`}
              variant="primary"
              onClick={handleSave}
              disabled={
                loading ||
                isShowConfirmationPopup ||
                !isPDFInvoiceUpdatesExist ||
                isPDFInvoiceErrorsExists
              }
            >
              {translation('setting.save')}
            </Button>
          </Col>
        </Row>
      </Form>
      {getConfirmPopup()}
    </Fieldset>
  );
};

export default withFormik({
  mapPropsToValues: ({ adminSettings }) => {
    if (!adminSettings) return;

    const { pdfInvoice } = adminSettings;
    if (!pdfInvoice) {
      return {
        remarkText: '',
        noTaxText: '',
        reverseTaxText: ''
      };
    }

    return {
      remarkText:
        pdfInvoice.default.summary.find(
          (item) => item.name === PDFInvoiceSettingFields.SummaryText
        )?.text || '',
      reverseTaxText:
        pdfInvoice.default.summary.find(
          (item) => item.name === PDFInvoiceSettingFields.VatReverseCharge
        )?.text || '',
      noTaxText:
        pdfInvoice.default.summary.find(
          (item) => item.name === PDFInvoiceSettingFields.VatNoTaxes
        )?.text || ''
    };
  },
  validationSchema: Yup.object({
    remarkText: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.invoiceSettings.remarkText')
        })
      ),
    noTaxText: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.invoiceSettings.noTaxText')
        })
      ),
    reverseTaxText: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('fields.invoiceSettings.reverseTaxText')
        })
      )
  }),
  enableReinitialize: true,
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit({ ...values });
  }
})(SettingsInvoiceSummaryForm);
