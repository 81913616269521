/**
 * UserAPI.ts (InstaLOD GmbH)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file UserAPI.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { USER_BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import {
  IUserProfile
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

const httpClient = new HttpClient(USER_BASE_API_URL, createLog);

// NOTE: Service responsible for calling endpoints directly from the User API.

/**
 * Get user profile information
 * @param userUUID Defines the user UUID to retrieve the profile information. Optional parameter.
 * @returns
 */
export const getUserProfile = async (
  userUUID?: string
): Promise<IAPIEntityResponse<IUserProfile>> => {
  const userUUIDParameter: string = userUUID ?? LocalStorage.getXUserUUID();

  return httpClient.get(`/user/${userUUIDParameter}/profile`);
};
