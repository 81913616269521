/*
 * ProductOptions.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file ProductOptions.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import Price from '../../SubComponents/Price';
import { currencyType } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import {
  IOption,
  IProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import {
  getTranslatedProductOptions,
  getTranslatedProductOptionsInformation
} from '../../../Utils/DynamicTranslate';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * Interface for ProductOptions properties.
 */
interface IProductOptionsProperties {
  product: IProduct /**< Product details */;
  globalProductOptions: IOption[] /**< Global product options */;
  selectedOptions: any;
  setOptions: any;
  values: any;
  serialNumber: number;
  isDisabled: boolean /**< To disable the product options or not */;
  storeTranslation: IStoreTranslation[] /**< Store translation data */;
  selectedOptionsObject: IOption[] /**< Selected options object */;
  setSelectedOptionsObject: React.Dispatch<
    React.SetStateAction<IOption[]>
  > /**< Set Selected options object */;
  loggedOutMessageBanner: JSX.Element /**< Logged out message banner */;
}

const ProductOptions = ({
  product,
  globalProductOptions,
  selectedOptions,
  setOptions,
  values,
  serialNumber,
  isDisabled,
  storeTranslation,
  selectedOptionsObject,
  setSelectedOptionsObject,
  loggedOutMessageBanner
}: IProductOptionsProperties) => {
  const { t } = useTranslation();
  const translatedProductOptionsDescription: string[] = getTranslatedProductOptions(
    product?.translationData as IProductTranslation[]
  ); /**< To get translated product options description */
  const translatedProductOptionsInformation: string[] = getTranslatedProductOptionsInformation(
    product?.translationData as IProductTranslation[]
  ); /**< To get translated product options information */
  const productOptions: IOption[] =
    product &&
    product.options &&
    product.options.map((option: IOption, index: number) => ({
      ...option,
      description: translatedProductOptionsDescription[index],
      tooltipInformation: translatedProductOptionsInformation[index]
    }));

  const translatedGlobalProductOptionsDescription: string[] = getTranslatedProductOptions(
    storeTranslation as IStoreTranslation[]
  ); /**< To get translated global product options description */
  const translatedGlobalProductOptionsInformation: string[] = getTranslatedProductOptionsInformation(
    storeTranslation as IStoreTranslation[]
  ); /**< To get translated global product options inforamtion */

  const globalOptions: IOption[] =
    globalProductOptions &&
    globalProductOptions.map((option: IOption, index: number) => ({
      ...option,
      description: translatedGlobalProductOptionsDescription[index],
      tooltipInformation: translatedGlobalProductOptionsInformation[index]
    }));
  const allOptions: IOption[] = [
    ...(productOptions ?? []),
    ...(globalOptions ?? [])
  ]; /**< Product and global options */

  // Update the selected options object when language changes
  useEffect(() => {
    if (
      allOptions &&
      allOptions.length &&
      selectedOptionsObject &&
      selectedOptionsObject.length
    ) {
      const updatedSelectedOptionsObject: IOption[] = [
        ...selectedOptionsObject
      ].map((selectedOption: IOption) => {
        const description: string | undefined = allOptions.find(
          (option: IOption) => option._id === selectedOption._id
        )?.description;
        return {
          ...selectedOption,
          description: description ?? selectedOption.name
        };
      });

      setSelectedOptionsObject(updatedSelectedOptionsObject);
    }
  }, [LocalStorage.getLanguageSettingsMode()]);

  const updateOptions = (updatedField) => {
    if (updatedField.checked) {
      const selectedObject: IOption | undefined = allOptions.find(
        (option: IOption) => option.name === updatedField.value
      );
      if (selectedObject) {
        setSelectedOptionsObject([...selectedOptionsObject, selectedObject]);
      }
      setOptions([...selectedOptions, updatedField.value]);
    } else {
      setOptions(
        selectedOptions.filter((fieldName) => fieldName !== updatedField.value)
      );
      setSelectedOptionsObject(
        selectedOptionsObject.filter(
          (option: IOption) => option.name !== updatedField.value
        )
      );
    }
  };
  const optionRows = allOptions.map((field, index: number) => {
    return (
      <Row key={field.name} className={index !== 0 ? 'mt-3' : ''}>
        <Col>
          <Checkbox
            inputId={`product-option.${index}`}
            value={field.name}
            checked={selectedOptions.includes(field.name)}
            onChange={updateOptions}
            disabled={isDisabled}
          />
          <>
            <label
              htmlFor={`product-option.${index}`}
              className={
                field?.description?.length > 50
                  ? 'p-checkbox-label p-checkbox-label-lg'
                  : 'p-checkbox-label'
              }
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: field.description ?? field.name
                }}
              />
              {field.amount && field.amount !== 0 ? (
                <Badge
                  variant="secondary"
                  className="ml-2 product-option-badge"
                >
                  <Price
                    amount={field.amount}
                    currencyType={currencyType.USD}
                    className="text-monospace"
                  />
                </Badge>
              ) : null}
              {field.percentage && field.percentage !== 0 ? (
                <Badge
                  variant="secondary"
                  className="ml-2 product-option-badge"
                >
                  {field.percentage}%
                </Badge>
              ) : null}
            </label>
            {!isStringEmptyOrNullOrUndefined(field?.tooltipInformation) ? (
              <PopupInformation
                id={`product-option.${index}`}
                popupText={field?.tooltipInformation}
              />
            ) : (
              <></>
            )}
          </>
        </Col>
      </Row>
    );
  });

  return (
    <Fieldset
      legend={t('checkoutPage.summary.options', { number: `${serialNumber}.` })}
      className="custom-fieldset"
    >
      {loggedOutMessageBanner}
      {optionRows}
    </Fieldset>
  );
};

export default ProductOptions;
