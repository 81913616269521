/**
 * Analytics.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Analytics.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * Interface for analytics data.
 */
export interface IAnalyticsData {
  event: any;
  description: string;
  userAgent: any;
  initiatedAt: any;
  trackingId: any;
  [key: string]: any /**< Dynamic properties */;
}

/**
 * API service to add analytics.
 * @function
 */
export const create = (analyticsData: IAnalyticsData) => {
  return httpClient.post('/analytics', analyticsData, {}, false);
};

/**
 * API service to get analytics.
 * @function
 */
export const get = (startDate: Date, endDate: Date) => {
  return httpClient.get(
    `/analytics?start_date=${startDate}&end_date=${endDate}`
  );
};
