import i18next from 'i18next';

/**
 *
 * @param content This is the content to be translated
 * @param parameters This is an additional object used by i18next library
 * @returns translated content
 */
export const translate = (
  content: string,
  parameters?: { [key: string]: string }
) => i18next.t(content, parameters);
