/*
 * AdminPanel.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2020
 *
 * @file AdminPanel.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminContent from './AdminContent';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { useAppSelector } from '../../Hooks';
import { removeSpecificHeadTags, isRole } from '../../Utils/Formatter';
import MenuWrapper from '../Menu/MenuWrapper';
import { useTranslation } from 'react-i18next';
import {
  menuItemClick,
  dropDownMenuClickHandler,
  removeClassNameFromDropdownMenu,
  IDropdownMenuReference
} from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { createLog } from '../../Services/Logs';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import { ISettings } from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../Utils/routesNames';
import {
  SharedMainRouteName,
  SharedCommomRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';

/**
 * Interface for AdminPanel properties.
 */
interface IAdminPanelProperties {
  fnLogout: () => void;
  themeMode: string /**< theme mode to use */;
  didChangeTheme: (theme: ThemeMode) => void /**< change theme function */;
  languageSettingsMode: string /**< Language settings mode to use */;
  didChangeLanguage: (
    language: string
  ) => void /**< change language function */;
}

const AdminPanel = (properties: IAdminPanelProperties) => {
  const translation = useTranslation().t;
  const history = useHistory();
  const dispatch = useDispatch();
  const role = JSON.parse(LocalStorage.getRole()); /**< User role. */
  const templates = useAppSelector(
    (state) => state.templates
  ); /**< Get templates */
  const settings = useSelector((state) => state.shopSettings.list);
  const adminRoleUUID =
    settings && settings.adminRoleUUID; /**< Admin role UUID. */
  const productManagerRoleUUID =
    settings &&
    settings.productManagerRoleUUID; /**< Product manager role UUID. */
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(
    []
  ); /**< Menu items. */
  const menu = useRef(null); /**< Menu. */
  const templateEditorMenuReference: IDropdownMenuReference = useRef(
    null
  ); /**< Template Editor Menu Reference. */
  const templatesPathNames: string[] = [
    RouteName.adminTemplateEditorRoute,
    RouteName.adminCSSEditorRoute
  ]; /**< Templates Editor PathNames. */
  const user: IAuth = useAppSelector(
    (state) => state.userAuth
  ); /**< User state. */
  const [logoURL, setLogoURL] = useState<string>(''); /**< Logo URL. */
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(
    false
  ); /**< Logo Loading state. */
  const safeSettings: ISettings = useAppSelector(
    (state) => state.settings
  ); /**< Safe settings. */

  /// Get Templates Editor Menu element by classname.
  const getTemplatesEditorParentMenu = (): HTMLCollection => {
    return document.getElementsByClassName('template-editor');
  };

  /// Remove 'panel-menu-activated-item' className from dropdown menu
  const removeDropdownMenusClassName = () => {
    if (!templateEditorMenuReference.current) {
      templateEditorMenuReference.current = getTemplatesEditorParentMenu();
    }
    removeClassNameFromDropdownMenu(templateEditorMenuReference.current);
  };

  /// Templates Editor MenuClassName.
  const templatesEditorMenuClassName = (): string => {
    if (isRole([adminRoleUUID], role)) {
      if (templatesPathNames.indexOf(window.location.pathname) > -1) {
        return 'template-editor panel-menu-activated-item';
      } else {
        return 'template-editor';
      }
    } else {
      return 'd-none';
    }
  };

  // Get admin menu items
  const getAdminMenuItems = (): Array<IMenuItem> => {
    /// Menu items for admin user
    const adminMenuItems = adminRoleUUID &&
      productManagerRoleUUID && [
        {
          id: 'dashboard',
          label: translation('admin.menu.dashboard'),
          icon: 'far fa-home sidebar_icon',
          url: RouteName.adminDashboardRoute,
          pathToHighlight: RouteName.adminDashboardRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminDashboardRoute,
              'dashboard'
            );
          },
          menuClassName: isRole([adminRoleUUID], role) ? '' : 'd-none'
        },
        {
          id: 'products',
          label: translation('admin.menu.products'),
          icon: 'fas fa-box sidebar_icon',
          url: RouteName.adminProductsRoute,
          pathToHighlight: RouteName.adminProductsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminProductsRoute,
              'products'
            );
          },
          menuClassName: isRole([adminRoleUUID, productManagerRoleUUID], role)
            ? ''
            : 'd-none'
        },
        {
          id: 'customers',
          label: translation('admin.menu.customers'),
          icon: 'far fa-users sidebar_icon',
          url: RouteName.adminCustomersRoute,
          pathToHighlight: RouteName.adminCustomersRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminCustomersRoute,
              'customers'
            );
          }
        },
        {
          id: 'transactions',
          label: translation('admin.menu.transactions'),
          icon: 'fas fa-sack-dollar sidebar_icon',
          url: RouteName.adminTransactionsRoute,
          pathToHighlight: RouteName.adminTransactionsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminTransactionsRoute,
              'transactions'
            );
          }
        },
        {
          id: 'subscriptions',
          label: translation('admin.menu.subscriptions'),
          icon: 'fas fa-envelope-open-dollar sidebar_icon',
          url: RouteName.adminSubscriptionsRoute,
          pathToHighlight: RouteName.adminSubscriptionsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminSubscriptionsRoute,
              'subscriptions'
            );
          }
        },
        {
          id: 'discounts',
          label: translation('admin.menu.discounts'),
          icon: 'fas fa-percent sidebar_icon',
          url: RouteName.adminDiscountsRoute,
          pathToHighlight: RouteName.adminDiscountsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminDiscountsRoute,
              'discounts'
            );
          },
          menuClassName: isRole([adminRoleUUID, productManagerRoleUUID], role)
            ? ''
            : 'd-none'
        },
        {
          id: 'application-management',
          label: translation('admin.menu.applicationManagement'),
          icon: 'fas fa-th sidebar_icon',
          url: RouteName.adminApplicationManagementRoute,
          pathToHighlight: RouteName.adminApplicationManagementRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminApplicationManagementRoute,
              'application-management'
            );
          },
          menuClassName: isRole([adminRoleUUID], role) ? '' : 'd-none'
        },
        {
          id: 'templates',
          label: translation('admin.menu.templateeditor'),
          icon: 'fas fa-drafting-compass sidebar_icon',
          command: (event: any) => {
            const templateEditorMenu: HTMLCollection = getTemplatesEditorParentMenu(); /**< Template editor menu */
            templateEditorMenuReference.current = templateEditorMenu;
            dropDownMenuClickHandler(
              event,
              templatesPathNames,
              templateEditorMenuReference.current
            );
          },
          items: [
            {
              id: 'emailTemplates',
              label: translation('admin.menu.template-editor'),
              icon: 'fas fa-drafting-compass sidebar_icon',
              url: RouteName.adminTemplateEditorRoute,
              pathToHighlight: RouteName.adminTemplateEditorRoute,
              command: (event: any) => {
                event.originalEvent.preventDefault();
                menuItemClick(
                  menu,
                  setMenuItems,
                  history,
                  RouteName.adminTemplateEditorRoute,
                  'template-editor'
                );
              }
            },
            {
              id: 'css',
              label: translation('admin.menu.css'),
              icon: 'fas fa-drafting-compass sidebar_icon',
              url: RouteName.adminCSSEditorRoute,
              pathToHighlight: RouteName.adminCSSEditorRoute,
              command: (event: any) => {
                event.originalEvent.preventDefault();
                menuItemClick(
                  menu,
                  setMenuItems,
                  history,
                  RouteName.adminCSSEditorRoute,
                  'css-editor'
                );
              }
            }
          ],
          menuClassName: templatesEditorMenuClassName()
        },
        {
          id: 'settings',
          label: translation('admin.menu.settings'),
          icon: 'far fa-cog sidebar_icon',
          url: RouteName.adminSettingsRoute,
          pathToHighlight: RouteName.adminSettingsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminSettingsRoute,
              'settings'
            );
          },
          menuClassName: isRole([adminRoleUUID], role) ? '' : 'd-none'
        },
        {
          id: 'logs',
          label: translation('admin.menu.logs'),
          icon: 'far fa-calendar sidebar_icon',
          url: RouteName.adminLogsRoute,
          pathToHighlight: RouteName.adminLogsRoute,
          command: (event: any) => {
            event.originalEvent.preventDefault();
            removeDropdownMenusClassName();
            menuItemClick(
              menu,
              setMenuItems,
              history,
              RouteName.adminLogsRoute,
              'logs'
            );
          },
          menuClassName: isRole([adminRoleUUID], role) ? '' : 'd-none'
        }
      ];

    return adminMenuItems;
  };

  useEffect(() => {
    // Note: Custom CSS imports are overridden by master template. So remove those head tags from document.head.
    if (templates && templates.list.length > 0 && !templates.listIsFetching) {
      // get masterTemplate
      const masterTemplate = {
        ...templates.list.find((obj) => obj.isMasterTemplate === true)
      };
      const template = masterTemplate.template;
      if (
        location &&
        location.pathname &&
        !location.pathname.includes(SharedCommomRouteName.loginRoute) // Should not be removed for the login page.
      ) {
        removeSpecificHeadTags(template);
      }
    }
  }, []);

  // If user is admin, redirect to admin route.
  if (user.isAuthenticated && !user.isAdmin) {
    return <Redirect to={SharedMainRouteName.clientRoute} />;
  }

  /// Populate menuItems
  useEffect(() => {
    const updatedMenuItems: IMenuItem[] = getAdminMenuItems();
    if (updatedMenuItems && !menuItems.length) {
      setMenuItems(updatedMenuItems);
    }
  }, [adminRoleUUID, productManagerRoleUUID, menuItems.length]);

  /// Get Logo URL.
  useEffect(() => {
    const getLogoURL = async () => {
      if (safeSettings && safeSettings.setting && safeSettings.setting.logo) {
        setLogoURL(safeSettings.setting.logo);
        setLogoLoaded(true);
      } else {
        setLogoLoaded(true);
      }
    };
    if (user.isAuthenticated) {
      getLogoURL();
    } else {
      setLogoLoaded(true);
    }
  }, [safeSettings]);

  // Menuwrapper and page content
  const getPageContent = () => {
    return (
      <>
        <MenuWrapper
          menuItems={menuItems}
          menu={menu}
          fnLogout={properties.fnLogout}
          setMenuItems={setMenuItems}
          history={history}
          isAdmin={true}
          didChangeTheme={properties.didChangeTheme}
          themeMode={properties.themeMode}
          logoURL={logoURL}
          languageSettingsMode={properties.languageSettingsMode}
          didChangeLanguage={properties.didChangeLanguage}
        />
        <Col className="main-body" id="main-body">
          {<AdminContent />}
        </Col>
      </>
    );
  };

  /// Populate menuItems when language changes
  useEffect(() => {
    const updatedMenuItems: IMenuItem[] = getAdminMenuItems();
    if (updatedMenuItems) {
      setMenuItems([...updatedMenuItems]);
    }
  }, [properties.languageSettingsMode]);

  //Note: Get admin menu items when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const updatedMenuItems: IMenuItem[] = getAdminMenuItems();

      if (updatedMenuItems) {
        setMenuItems([...updatedMenuItems]);
      }
    }
  });

  return (
    <>
      {isLogoLoaded && menuItems.length ? (
        <main className="d-flex flex-column h-100">
          <Container fluid={true}>
            <Row>{getPageContent()}</Row>
          </Container>
        </main>
      ) : (
        <></>
      )}
    </>
  );
};

export default withErrorBoundary(AdminPanel, createLog);
