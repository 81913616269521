/**
* ConfigurationSettingsPage.ts (InstaLOD GmbH) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022 
* @file ConfigurationSettingsPage.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import './ConfigurationSettingsPage.css';

/**
 * @interface IConfigurationSettingsPageProperties
 */
interface IConfigurationSettingsPageProperties {
  configurationSettings: Record<string, any> /**< Settings to configure application. */;
  handleUpdateSettings: (
    settings: Record<string, any>
  ) => Promise<void> /**< Call endpoint to update configuration settings. */;
}

const ConfigurationSettingsPage = (
  properties: IConfigurationSettingsPageProperties
): JSX.Element => {
  const translation: TFunction = useTranslation().t;

  /// Handle submit event.
  const handleSubmit = (settings: Record<string, any>): void => {
    properties.handleUpdateSettings(settings);
  };

  const formik = useFormik({
    initialValues: properties.configurationSettings,
    validationSchema: () => {
      const shapes = {};
      Object.keys(properties.configurationSettings).forEach((key: string) => {
        shapes[key] = Yup.string().required(translation('awc:/validations.required', { field: key })).nullable();
      });

      return Yup.object().shape(shapes);
    },
    onSubmit: (data: Record<string, any>) => handleSubmit(data)
  });

  return (
    <Col xs="12" className="p-2 configuration-container login-pages-global-container">
      <Fieldset legend={translation('awc:/.configSettings.title')}>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            {properties.configurationSettings &&
              Object.keys(properties.configurationSettings).map((key: string) => {
                return (
                  <Col key={key} xs={12}>
                    <label htmlFor={key} className="required">
                      {key}
                    </label>
                    <InputText
                      id={key}
                      value={formik.values[key]}
                      onChange={formik.handleChange}
                      className={formik.touched[key] && formik.errors[key] ? 'p-invalid' : ''}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[key] && formik.errors[key] ? (
                      <small id="name-invalid" className="p-invalid">
                        {formik.errors[key]}
                      </small>
                    ) : null}
                  </Col>
                );
              })}
          </Row>
        </form>
        <Row>
          <Col md="4"></Col>
          <Col md={4}>
            <Button
              className="p-button-raised p-button-primary btn-block"
              onClick={() => formik.handleSubmit()}>
              {translation('awc:/.configSettings.save')}
            </Button>
          </Col>
        </Row>
      </Fieldset>
    </Col>
  );
};

export default ConfigurationSettingsPage;
