/*
 * dateRangeSelectors.js (AbstractUser & AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file dateRangeSelectors.js
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
*/

import {
  endOfDay,
  startOfDay,
  addMonths,
  startOfWeek,
  isSameDay,
  addYears,
  addWeeks
} from 'date-fns';

// Define startDate as today by default.
let startDate : Date = new Date();

// Handle date range selection event.
const staticRangeHandler = {
  range: () => ({
    startDate: startOfDay(startDate),
    endDate: endOfDay(startDate)
  }),
  isSelected(range: any) {
    const definedRange = this.range();
    startDate = range.startDate; /**< Replace default startDate as selected startDate. */

    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

export function createStaticRanges(ranges: any) {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
}

// Default static range for Datepicker
export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'One Week from start date',
    range: () => ({
      startDate: startOfDay(startDate),
      endDate: endOfDay(addWeeks(startDate, 1))
    })
  },
  {
    label: 'One Month from start date',
    range: () => ({
      startDate: startOfDay(startDate),
      endDate: endOfDay(addMonths(startDate, 1))
    })
  },
  {
    label: 'One Year from start date',
    range: () => ({
      startDate: startOfDay(startDate),
      endDate: endOfDay(addYears(startDate, 1))
    })
  },
  {
    label: 'Two Years from start date',
    range: () => ({
      startDate: startOfDay(startDate),
      endDate: endOfDay(addYears(startDate, 2))
    })
  },
  {
    label: 'Three Years from start date',
    range: () => ({
      startDate: startOfDay(startDate),
      endDate: endOfDay(addYears(startDate, 3))
    })
  }
]);
