/**
 * braintreeSubscriptionStatus.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file braintreeSubscriptionStatus.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * @enum SubscriptionStatus
 * Possibles subscription status of Braintree service
 */
 export enum SubscriptionStatus {
  Pending = 'Pending',
  Active = 'Active',
  PastDue = 'Past Due',
  Expired = 'Expired',
  Canceled = 'Canceled',
  Refunded = 'Refunded',
}