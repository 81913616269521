/*
 * Applications.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Applications.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  createOrUpdateApplication,
  getAllApplications,
  getAllPaginatedApplications,
  getUserApplications,
  removeApplication
} from '../Services/Applications';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const GET_PAGINATED_APPLICATIONS_REQUEST = 'applications/fetchList/request';
const GET_PAGINATED_APPLICATIONS_SUCCESS = 'applications/fetchList/success';
const GET_PAGINATED_APPLICATIONS_FAILURE = 'applications/fetchList/failure';

const ADD_APPLICATION_REQUEST = 'applications/add/request';
const ADD_APPLICATION_SUCCESS = 'applications/add/success';
const ADD_APPLICATION_FAILURE = 'applications/add/failure';

const DELETE_APPLICATION_REQUEST = 'applications/delete/request';
const DELETE_APPLICATION_SUCCESS = 'applications/delete/success';
const DELETE_APPLICATION_FAILURE = 'applications/delete/failure';

const TOGGLE_APPLICATION_DIALOG = 'applications/dialog/toggle';

const GET_ALL_APPLICATIONS_REQUEST = 'applications/get/all/request';
const GET_ALL_APPLICATIONS_SUCCESS = 'applications/get/all/success';
const GET_ALL_APPLICATIONS_FAILURE = 'applications/get/all/failure';

const GET_USER_APPLICATIONS_REQUEST =
  'applications/get/allUserApplications/request';
const GET_USER_APPLICATIONS_SUCCESS =
  'applications/get/allUserApplications/success';
const GET_USER_APPLICATIONS_FAILURE =
  'applications/get/allUserApplications/failure';

const INITIAL_STATE = {
  list: null,
  isFetchingList: true,
  isApplicationChanging: false,
  isApplicationDialogOpened: false,
  editedApplication: null,
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  },
  totalRecords: 0,
  allApplications: null,
  isFetchingAllApplications: false,
  listValueAndLabel: null,
  isFetchingUserApplications: false,
  userApplications: null,
  userApplicationtListValueAndLabel: null
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PAGINATED_APPLICATIONS_REQUEST:
      return { ...state, isFetchingList: true };
    case GET_PAGINATED_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        list: action.payload.applications,
        totalRecords: action.payload.totalRecords
      };
    case GET_PAGINATED_APPLICATIONS_FAILURE:
      return { ...state, isFetchingList: false };
    case ADD_APPLICATION_REQUEST:
      return { ...state, isApplicationChanging: true };
    case ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        isApplicationChanging: false,
        isApplicationDialogOpened: false
      };
    case ADD_APPLICATION_FAILURE:
      return { ...state, isApplicationChanging: false };
    case DELETE_APPLICATION_REQUEST:
      return { ...state, isApplicationChanging: true };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        isApplicationChanging: false,
        isApplicationDialogOpened: false
      };
    case DELETE_APPLICATION_FAILURE:
      return { ...state, isApplicationChanging: false };
    case TOGGLE_APPLICATION_DIALOG:
      return {
        ...state,
        isApplicationDialogOpened: action.payload.isOpened,
        editedApplication: action.payload.application
      };
    case GET_ALL_APPLICATIONS_REQUEST:
      return { ...state, isFetchingAllApplications: true };
    case GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingAllApplications: false,
        allApplications: action.payload,
        listValueAndLabel: action.payload.map((payload: IApplication) => ({
          value: payload._id,
          label: payload.applicationName
        }))
      };
    case GET_ALL_APPLICATIONS_FAILURE:
      return { ...state, isFetchingAllApplications: false };
    case GET_USER_APPLICATIONS_REQUEST:
      return { ...state, isFetchingUserApplications: true };
    case GET_USER_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingUserApplications: false,
        userApplications: action.payload,
        userApplicationtListValueAndLabel: action.payload.map(
          (payload: IApplications) => ({
            value: payload.applicationUUID,
            label: payload.applicationName
          })
        )
      };
    case GET_USER_APPLICATIONS_FAILURE:
      return { ...state, isFetchingUserApplications: false };
    default:
      return state;
  }
};

const getPaginatedApplicationsRequest = () => ({
  type: GET_PAGINATED_APPLICATIONS_REQUEST
});

const getPaginatedApplicationsSuccess = (
  applications: IApplication[],
  totalRecords: number
) => ({
  type: GET_PAGINATED_APPLICATIONS_SUCCESS,
  payload: { applications, totalRecords }
});

const getPaginatedApplicationsFailure = () => ({
  type: GET_PAGINATED_APPLICATIONS_FAILURE
});

const addApplicationRequest = () => ({
  type: ADD_APPLICATION_REQUEST
});

const addApplicationSuccess = () => ({
  type: ADD_APPLICATION_SUCCESS
});

const addApplicationFailure = () => ({
  type: ADD_APPLICATION_FAILURE
});

const deleteApplicationRequest = () => ({
  type: DELETE_APPLICATION_REQUEST
});

const deleteApplicationSuccess = () => ({
  type: DELETE_APPLICATION_SUCCESS
});

const deleteApplicationFailure = () => ({
  type: DELETE_APPLICATION_FAILURE
});

const getAllApplicationsRequest = () => ({
  type: GET_ALL_APPLICATIONS_REQUEST
});

const getAllApplicationsSuccess = (payload: IApplication[]) => ({
  type: GET_ALL_APPLICATIONS_SUCCESS,
  payload
});

const getAllApplicationsFailure = () => ({
  type: GET_ALL_APPLICATIONS_SUCCESS
});

const getUserApplicationsRequest = () => ({
  type: GET_USER_APPLICATIONS_REQUEST
});

const getUserApplicationsSuccess = (payload: IApplications[]) => ({
  type: GET_USER_APPLICATIONS_SUCCESS,
  payload
});

const getUserApplicationsFailure = () => ({
  type: GET_USER_APPLICATIONS_FAILURE
});

/**
 * Toggle application dialog
 * @param isOpened
 * @param application
 * @returns
 */
export const toggleApplicationDialog = (
  isOpened: boolean,
  application: IApplication | null
) => {
  return {
    type: TOGGLE_APPLICATION_DIALOG,
    payload: {
      isOpened,
      application
    }
  };
};

/**
 * Fetch all paginated applications
 * @param skip
 * @param limit
 * @param searchTerm
 * @param sort
 * @returns
 */
export const fetchAllPaginatedApplications = (
  skip?: number,
  limit?: number,
  searchTerm?: string,
  sort?: any
) => async (dispatch: any) => {
  try {
    dispatch(getPaginatedApplicationsRequest());
    const result = await asyncErrorHandler(
      getAllPaginatedApplications(skip, limit, searchTerm, sort)
    );
    if (result.error) {
      handleError({
        message: result.error.message || 'Failed to get all applications'
      });
      dispatch(getPaginatedApplicationsFailure());
    } else {
      dispatch(
        getPaginatedApplicationsSuccess(
          result.applications,
          result.totalRecords
        )
      );
    }
  } catch (exception) {
    handleError({
      message: exception?.message || 'Failed to get all applications'
    });
    dispatch(getPaginatedApplicationsFailure());
  }
};

/**
 * Add or edit application
 * @param application
 * @returns
 */
export const addOrEditApplication = (
  application: Record<string, any>
) => async (dispatch: any) => {
  try {
    dispatch(addApplicationRequest());

    const result = await asyncErrorHandler(
      createOrUpdateApplication(application)
    );
    if (result.error) {
      if (application._id) {
        handleError({
          message: result.error.message || 'Failed to update application'
        });
      } else {
        handleError({
          message: result.error.message || 'Failed to create application'
        });
      }
      dispatch(addApplicationFailure());
    } else {
      dispatch(addApplicationSuccess());
      if (application._id) {
        showSuccessToast(result.message || 'Application updated successfully');
      } else {
        showSuccessToast(result.message || 'Application created successfully');
      }
    }
    return result;
  } catch (exception) {
    dispatch(addApplicationFailure());
    handleError({ message: exception.message });
  }
};

/**
 * Delete application(s)
 * @param applicationIDs
 * @returns
 */
export const deleteApplication = (applicationIDs: string[]) => async (
  dispatch: any
) => {
  try {
    dispatch(deleteApplicationRequest());
    const result = await asyncErrorHandler(removeApplication(applicationIDs));
    if (result.error) {
      handleError({
        message: result.error.message || 'Failed to delete application(s)'
      });
      dispatch(deleteApplicationFailure());
    } else {
      dispatch(deleteApplicationSuccess());
      showSuccessToast(result.message || 'Deleted Application(s) successfully');
    }
  } catch (exception) {
    dispatch(deleteApplicationFailure());
    handleError({ message: exception.message });
  }
};

/**
 * Fetch all applications
 * @param skip
 * @param limit
 * @param searchTerm
 * @param sort
 * @returns
 */
export const fetchAllApplications = () => async (dispatch: any) => {
  try {
    dispatch(getAllApplicationsRequest());
    const result = await asyncErrorHandler(getAllApplications());
    if (result.error) {
      handleError({
        message: result.error.message || 'Failed to get all applications'
      });
      dispatch(getAllApplicationsFailure());
    } else {
      dispatch(getAllApplicationsSuccess(result.applications));
    }
  } catch (exception) {
    handleError({ message: exception.message });
    dispatch(getAllApplicationsFailure());
  }
};

/**
 * Fetch user applications
 * @param skip
 * @param limit
 * @param searchTerm
 * @param sort
 * @returns
 */
export const fetchUserApplications = () => async (dispatch: any) => {
  try {
    dispatch(getUserApplicationsRequest());
    const result = await asyncErrorHandler(getUserApplications());
    if (result.error) {
      handleError({
        message: result.error.message || 'Failed to get user applications'
      });
      dispatch(getUserApplicationsFailure());
    } else {
      dispatch(getUserApplicationsSuccess(result.userApplications));
    }
  } catch (exception) {
    handleError({ message: exception.message });
    dispatch(getUserApplicationsFailure());
  }
};
