
/**
 * downloadFile.ts (Abstract Web Common)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file downloadFile.ts
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section Abstract Web Common
 */

import {IAPIErrorData, IFetchConfiguration} from '../../Shared/interfaces/api';
import {LocalStorage} from './sharedLocalStorage';
import {SharedCommomRouteName} from './sharedRoutesNames';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

interface IBlob extends Blob {
  error?: string;
}

/**
  Download files from server.
  @param String url - the url for the request
  @param String method - the method for the request
  @param String filename - the filename to be generate and downloaded
  @param Object body - the body for the request
  @param Object headers - the headers for the request
  @param Boolean isAuthenticationRequired - is authenticated required
  @return void
*/
export const downloadFile = async (
  url: string,
  method: string,
  filename: string,
  body?: Record<string, any>,
  headers?: Record<string, any>,
  isAuthenticationRequired = true
): Promise<IAPIErrorData | void | { error: string }> => {
  const authToken: string | null = LocalStorage.getXAuthToken();
  if (isAuthenticationRequired && !authToken) {
    localStorage.clear();
    sessionStorage.clear();
    const currentURL: string = window.location.href.toLowerCase();
    if (currentURL.indexOf('verification_url') === -1) {
      window.location.href = `${SharedCommomRouteName.loginRoute}#session-timeout`;
    }
    return;
  }

  const requestHeaders = (): Headers => {
    if (isAuthenticationRequired) {
      return new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...headers
      });
    }

    return new Headers({
      'Content-Type': 'application/json',
      ...headers
    });
  };

  const config: IFetchConfiguration = {
    method,
    headers: requestHeaders()
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const result: Response = await asyncErrorHandler(fetch(url, config));
  if (result.status === 401) {
    return { error: 'Error in exporting logs' };
  }

  if (result.status === 403) {
    // On forbidden: clear storage and request login
    localStorage.clear();
    sessionStorage.clear();

    /// just added the verification check just for robustness. Shouldn't happen here.
    const currentURL: string = window.location.href.toLowerCase();
    if (currentURL.indexOf('verification_url') === -1) {
      window.location.href = '/';
    }
  }

  if (!result.ok) {
    try {
      const body = await asyncErrorHandler(result.json());
      return body;
    } catch (_error: any) {
      throw new Error(_error);
    }
  }

  const blobResponse: IBlob = await asyncErrorHandler(result.blob());

  if (blobResponse.error) {
    return { error: blobResponse.error };
  }

  const objectURL: string = window.URL.createObjectURL(blobResponse);
  const anchorElement: HTMLAnchorElement = document.createElement('a');
  anchorElement.href = objectURL;
  anchorElement.download = filename;
  document.body.appendChild(anchorElement); // we need to append the element to the dom -> otherwise it will not work in firefox
  anchorElement.click();
  anchorElement.remove(); //afterwards we remove the element again
};