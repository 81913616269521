/*
 * PopupInformation.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file PopupInformation.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { ReactElement } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './PopupInformation.css';

/**
 * @interface IPopupInformation
 */
interface IPopupInformation {
  id: string; /**< Element Id */
  popupText: string; /**< Popup text */
}

const PopupInformation = (properties: IPopupInformation): ReactElement => {
   
  /// Tooltip Content
  const TooltipComponent = (
    <Tooltip id={properties.id}>
      {properties.popupText}
    </Tooltip>
  ); 

  return (
    <>
      &nbsp;
      <OverlayTrigger
        key={properties.id}
        placement="top"
        trigger={"hover"}
        overlay={TooltipComponent}
      >
        <i className="fa fa-info-circle info-icon"></i>
      </OverlayTrigger>
    </>
  )
}

export default PopupInformation;