/*
 * Price.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Price.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {fetchPriceCalculation} from '../Services/Price';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const PRICE_CALCULATION_REQUEST = 'price/calculation/request';
const PRICE_CALCULATION_SUCCESS = 'price/calculation/success';
const PRICE_CALCULATION_FAILURE = 'price/calculation/failure';

const INITIAL_STATE = {
  calculation: null,
  isFetching: false,
  error: null
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRICE_CALCULATION_REQUEST:
      return { ...state, isFetching: true };
    case PRICE_CALCULATION_SUCCESS:
      return { ...state, isFetching: false, calculation: action.payload };
    case PRICE_CALCULATION_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}

const priceCalculationRequest = (product) => {
  return { type: PRICE_CALCULATION_REQUEST, payload: product };
};

const priceCalculationSuccess = (calculation) => {
  return { type: PRICE_CALCULATION_SUCCESS, payload: calculation };
};

const priceCalculationFailure = (error) => {
  return { type: PRICE_CALCULATION_FAILURE, payload: error };
};

export const calculatePrice = (
  product,
  country,
  stateOrProvince,
  discountCode,
  isCompany
) => async (dispatch) => {
  dispatch(priceCalculationRequest(product));
  try {
    const calculation = await asyncErrorHandler(fetchPriceCalculation(
        product,
        country,
        stateOrProvince,
        discountCode,
        isCompany
    ));
    dispatch(priceCalculationSuccess(calculation));
  } catch (e) {
    dispatch(priceCalculationFailure(e.message));
  }
};
