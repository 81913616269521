/*
 * TemplatePreview.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file TemplatePreview.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, {ReactElement, useEffect, useRef, useState} from "react";
import {endListTemplateDelimiter, startListTemplateDelimiter} from '@abstract/abstractwebcommon-shared/constants';
import {insertStringAt} from '../utils/insertStringAt';

import './TemplatePreview.css'
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

interface ITemplatePreviewProperties {
  html: string;
  masterTemplate: string;
  placeholders: any;
  className?: string;
  masterBodyPlaceholder?: string;
  applicationCSS?: any;
  isEmail: boolean;
  customCSSLink: string;
}
/**
 * Template Preview component.
 * @name TemplatePreview
 * @param {object} - Properties object consisting of html and masterTemplate and placeholders.
 */
const TemplatePreview = ({
  html,
  masterTemplate,
  placeholders = {},
  className = "",
  masterBodyPlaceholder = "{{message}}",
  applicationCSS = "",
  isEmail = true,
  customCSSLink,
}: ITemplatePreviewProperties): ReactElement => {
  const [formattedHtml, setFormattedHtml] = useState("");
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const iframeReference = useRef(null);
  const iframeWindow = iframeReference?.current?.contentWindow;

/// Sets the current position when scrolling in iframe
  const onScroll = () => {
    const position = iframeWindow?.pageYOffset;
      setScrollPosition(position)
  };

/// Sets scroll position of to an iframe
  const iframeLoaded = () => {
    iframeWindow?.scrollTo(0, scrollPosition);
  }

  useEffect(() => {
      iframeWindow?.addEventListener("scroll", onScroll);
      return () => iframeWindow?.removeEventListener("scroll", onScroll);
  }, [html, iframeWindow])

  /// formats html with placeholders when a html, placeholders change.
  useEffect(() => {
    /// Format template with parameters.
    const formatTemplate = async (template = "", params: any[], customCSSLink: string) => {
      let templateHtml = template;
      templateHtml = insertStringAt(templateHtml, `<link id="customCss" rel="stylesheet" href="${customCSSLink}">`, '<head>\n')

      const paramsAsObjects: any = {};
      params.forEach((eachParam: any) => {
        paramsAsObjects[eachParam.placeholderKey] = eachParam.placeholderValue;
      });

      // special handling for dynamic clause
      const userEmailData: any = []; // random example to show preview for 3 records
      for (let index = 0; index < 3; index++) {
        userEmailData.push({
          username: `${paramsAsObjects.usernameListItem}_${index}`,
          verificationListItem: `${paramsAsObjects["verificationListItem"]}${index}`,
        });
      }

      const dynamicStartKey: string = startListTemplateDelimiter;
      const dynamicEndKey: string = endListTemplateDelimiter;
      if (
        templateHtml &&
        templateHtml.includes(dynamicStartKey) &&
        templateHtml.includes(dynamicEndKey)
      ) {
        const dynamicStartIndex: number = templateHtml.indexOf(dynamicStartKey);
        const dynamicEndIndex: number = templateHtml.indexOf(dynamicEndKey);
        const dynamicTextFromTemplate: string = templateHtml.substring(
          dynamicStartIndex + dynamicStartKey.length,
          dynamicEndIndex
        );
        let dynamicText: string = ``;
        // special case for username and verification code link in multiple user templates
        for (const eachUserEmailData of userEmailData) {
          dynamicText += dynamicTextFromTemplate;
          dynamicText = dynamicText.replace(
            `{{usernameListItem}}`,
            eachUserEmailData.username
          );
          dynamicText = dynamicText.replace(
            `{{verificationListItem}}`,
            eachUserEmailData.verificationListItem
          );
        }

        // update templateHtml with the updated dynamic text
        templateHtml =
          templateHtml.substring(0, dynamicStartIndex) +
          dynamicText +
          templateHtml.substring(
            dynamicEndIndex + dynamicEndKey.length,
            templateHtml.length
          );
      }

      // regular fields update
      params.forEach((param) => {
        const regex = new RegExp(`{{${param.placeholderKey}}}`, "g");
        templateHtml = templateHtml.replace(regex, param.placeholderValue);
      });

      return templateHtml;
    };

    const master = masterTemplate || "";
    let currentFormattedHtml = html;
    if (master && master !== "") {
      currentFormattedHtml = master.replace(masterBodyPlaceholder, html);
    }

    if(!isEmail) {
      currentFormattedHtml = ` <!DOCTYPE html>
        <html lang="en">
          <head>
            <style>
                ${applicationCSS}
            </style>      
            <link id="customCss" rel="stylesheet" href="${customCSSLink}">                 
            </head>
            <body>
              <div id="root">
                <div class="App">
                  ${html}
                </div>
              </div>
            </body>
            </html>
        `;
    }

    /// formats html contentn with placeholders
    const formatHTML = async () => {
      const formatted = await asyncErrorHandler(formatTemplate(
          currentFormattedHtml,
          placeholders,
          customCSSLink
      ));
      setFormattedHtml(formatted);
    };

    formatHTML();
  }, [html, placeholders, masterBodyPlaceholder, masterTemplate]);

  return (
    <iframe
      ref={iframeReference}
      title="Preview template"
      srcDoc={formattedHtml}
      className={`w-100 ${className} custom-template-preview-container`}
      onLoad={iframeLoaded}
    ></iframe>
  );
};

export default TemplatePreview;
