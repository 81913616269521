/*
 * CustomProductForm.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file CustomProductForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fieldset } from 'primereact/fieldset';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import ProductFormFields from './ProductFormFields';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18n from '../../../Services/I18n';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { ProductType } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import ProductSLAInformationForm from './ProductSLAInformationForm';
import { UseSeatQuantityEnum } from '@abstract/abstractwebcommon-shared/enum/ecommerce/products';

/**
 * Interface for CustomProductForm properties.
 */
interface ICustomProductFormProperties {
  values: any;
  errors: any;
  handleChange: any;
  handleSubmit: any;
  loading: any;
  product: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  editing: any;
  productId: any;
}

const CustomProductForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  loading,
  product,
  handleBlur,
  touched,
  setFieldValue,
  editing,
  productId
}: ICustomProductFormProperties) => {
  const translation = useTranslation().t;
  const [isSLAFormVisible, setSLAFormVisible] = useState<boolean>(
    values?.sla ?? false
  ); /**< Show SLA Form. */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const heading = 'Add custom Product';
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    values?.translationData?.findIndex(
      (data: IProductTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = values?.translationData?.findIndex(
    (data: IProductTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */

  useEffect(() => {
    setSelectedLanguageTranslationIndex(
      values?.translationData?.findIndex(
        (data: IProductTranslation) => data.language === selectedLanguage
      )
    );
  }, [selectedLanguage, values?.translationData]);

  return (
    <>
      <Fieldset legend={heading}>
        <Form>
          <Row>
            <ProductFormFields
              values={values}
              customForm
              errors={errors}
              handleChange={handleChange}
              loading={!editing}
              handleBlur={handleBlur}
              touched={touched}
              product={product}
              setFieldValue={setFieldValue}
              setShowSLAForm={setSLAFormVisible}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              selectedLanguageTranslationIndex={
                selectedLanguageTranslationIndex
              }
              englishTranslationIndex={englishTranslationIndex}
            />
          </Row>
          {isSLAFormVisible && (
            <Row>
              <Col sm={12}>
                <ProductSLAInformationForm
                  values={values?.translationData}
                  errors={errors?.translationData}
                  handleChange={handleChange}
                  loading={loading}
                  handleBlur={handleBlur}
                  touched={touched?.translationData}
                  setFieldValue={setFieldValue}
                  selectedLanguage={selectedLanguage}
                  selectedLanguageTranslationIndex={
                    selectedLanguageTranslationIndex
                  }
                  englishTranslationIndex={englishTranslationIndex}
                />
              </Col>
            </Row>
          )}
          {editing && (
            <Button
              className="float-right"
              variant="primary"
              onClick={async () => {
                handleSubmit(values);
              }}
            >
              {translation('product.add_product_dialog.confirm')}
            </Button>
          )}
        </Form>
      </Fieldset>
    </>
  );
};

Yup.addMethod(Yup.array, 'unique', function(message, mapper) {
  return this.test(
    'unique',
    message,
    (list) => list.length === new Set(list.map(mapper)).size
  );
});

export default withFormik({
  mapPropsToValues: () => {
    // Format inital product translation
    const initialTranslationData: IProductTranslation[] = [];
    for (let index = 0; index < Languages.length; index++) {
      const language: string = Languages[index].value;
      initialTranslationData.push({
        language: language,
        description: '',
        freeProductDiscountName: '',
        slaInformation: [],
        disabledProductMessage: ''
      });
    }

    return {
      name: '',
      sku: '',
      quantity: 0,
      maxQuantity: 100,
      seatQuantity: 0,
      price: 0,
      live: true,
      custom: true,
      sla: false,
      options: [],
      subscriptionPlanID: '',
      duration: null,
      type: ProductType.OneShot,
      applicationID: '',
      quantityType: UseSeatQuantityEnum.Fixed,
      isLoginRequired: false,
      translationData: initialTranslationData,
      isDisabled: false
    };
  },
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: () => {
    const translationValidationSpec = {
      language: Yup.string(),
      description: Yup.string().when('language', {
        is: LanguageSettingsMode.english,
        then: Yup.string()
          .nullable()
          .required(
            i18n.t('validation.required', {
              field: i18n.t('fields.product.description')
            })
          ),
        otherwise: Yup.string().nullable()
      }),
      slaInformation: Yup.array()
    }; /**< Translation validation */

    const validationSpec = {
      sla: Yup.boolean(),
      ProductType: Yup.string().oneOf(Object.keys(ProductType)),
      subscriptionPlanID: Yup.string(),
      name: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.name')
          })
        ),
      sku: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.sku')
          })
        ),
      quantityType: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.quantityType')
          })
        ),
      seatQuantity: Yup.number()
        .nullable()
        .when('quantityType', {
          is: UseSeatQuantityEnum.Seat,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.seatQuantity')
              })
            )
        }),
      price: Yup.number()
        .typeError('Must be a number')
        .positive('Must be a number above 0')
        .required(
          i18n.t('validation.required', {
            field: i18n.t('fields.product.price')
          })
        ),
      duration: Yup.number()
        .nullable()
        .when('isUniqueProduct', {
          is: true,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.duration')
              })
            )
        })
        .when(['sla', 'type'], {
          is: (sla: boolean, type: string) =>
            sla && type !== ProductType.OneShot,
          then: Yup.number()
            .typeError('Must be a number')
            .positive('Must be a number above 0')
            .required(
              i18n.t('validation.required', {
                field: i18n.t('fields.product.duration')
              })
            )
        }),
      applicationID: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('fields.product.company')
        })
      ),
      isLoginRequired: Yup.boolean(),
      translationData: Yup.array()
        .when('isUniqueProduct', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              freeProductDiscountName: Yup.string().when('language', {
                is: LanguageSettingsMode.english,
                then: Yup.string().required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.product.freeProductDiscountName')
                  })
                ),
                otherwise: Yup.string().nullable()
              })
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              freeProductDiscountName: Yup.string().nullable()
            })
          )
        })
        .when('isDisabled', {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              disabledProductMessage: Yup.string().when('language', {
                is: LanguageSettingsMode.english,
                then: Yup.string().required(
                  i18n.t('validation.required', {
                    field: i18n.t('fields.product.disabledProductMessage')
                  })
                ),
                otherwise: Yup.string().nullable()
              })
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              ...translationValidationSpec,
              disabledProductMessage: Yup.string().nullable()
            })
          )
        }),
      isDisabled: Yup.boolean()
    };
    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bag) => {
    if (bag.props.productId) {
      return bag.props.handleSubmit({
        _id: bag.props.productId,
        ...values
      });
    }
    return bag.props.handleSubmit({ ...values });
  }
})(CustomProductForm);
