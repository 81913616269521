/*
 * LanguageSettingsModeUtils.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LanguageSettingsModeUtils.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { LanguageSettingsMode } from "../../Shared/interfaces/Language";
import { isStringEmptyOrNullOrUndefined } from "../../Shared/utils/sharedFunctions";

/**
  Use to change language.
  @param String language - language state to update
  @param Service i18nService - service to translate
  @return void
*/
export const changeUserLanguage = (language: string, i18nService: any): void => {
  i18nService.changeLanguage(language)
}

/**
  Validates if the selected language is one of our valid options.
  @param language Defines the language to validate.
  @return string
*/
export const validateLanguageSetting = (language: string): string => {
  const validLanguages: LanguageSettingsMode[] = Object.values(LanguageSettingsMode);

  // We should check if the provided language is one of the valid options.
  if (validLanguages.includes(language as LanguageSettingsMode) && !isStringEmptyOrNullOrUndefined(language)) {
    return language;
  }

  // We should return 'en' if the language is not valid.
  return LanguageSettingsMode.english;
}