/*
 * ApplicationForm.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ApplicationForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { Col, Form, Row } from 'react-bootstrap';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import i18n from '../../../Services/I18n';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { Dropdown } from 'primereact/dropdown';
import { fetchUserApplications } from '../../../Store/Applications';
import { useDispatch, useSelector } from 'react-redux';
import {
  isArrayOfStringsTheSame,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import {
  LanguageSettingsMode,
  Languages
} from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { IApplicationTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ApplicationTranslation';
import LanguageSelector from '@abstract/abstractwebcommon-client/LanguageSelector/LanguageSelector';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import NextStepButtonForm from './NextStepButtonForm';

/**
 * Interface for ApplicationForm properties.
 */
interface IApplicationFormProperties {
  values: any;
  errors: any;
  touched: any;
  handleChange: (event: any) => void;
  handleBlur: (event: any) => void;
  handleSubmit: (values: IApplication) => void;
  isLoading: boolean;
  application: IApplication;
  handleDelete: (applicationsIDs: string[]) => void;
  setFieldValue: any;
}

const ApplicationForm = (
  properties: IApplicationFormProperties
): JSX.Element => {
  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(
    false
  ); /**< To Show Confirmation popup. */
  const [confirmationPopupTarget, setConfirmationPopupTarget] = useState<any>(
    null
  ); /**< ConfirmPopupTarget. */
  const [initiateLogoUpload, setInitiateLogoUpload] = useState(
    null
  ); /**Initiate Logo Upload. */
  const [isLogoFileChanged, setLogoFileChanged] = useState<boolean>(
    false
  ); /**< Logo File changed or not. */
  const [croppedLogo, setCroppedLogo] = useState(null); /**< Cropped Logo. */
  const [logoUploadStatus, setLogoUploadStatus] = useState<string>(
    ''
  ); /**< Logo upload status. */
  const applicationState = useSelector(
    (state) => state.applications
  ); /**< Application state */
  const [customFieldName, setCustomFieldName] = useState<string>(
    ''
  ); /**< Entered custom field name */
  const [customFieldKeys, setCustomFieldKeys] = useState<string[]>(
    properties.values.customFieldKeys
  ); /**< Custom field keys */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageSettingsMode.english
  ); /**< Selected language */
  const [
    selectedLanguageTranslationIndex,
    setSelectedLanguageTranslationIndex
  ] = useState<number>(
    properties.values?.translationData?.findIndex(
      (data: IApplicationTranslation) => data.language === selectedLanguage
    )
  ); /**< Selected language translation data index */
  const englishTranslationIndex: number = properties.values?.translationData?.findIndex(
    (data: IApplicationTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmationPopup(true);
    setConfirmationPopupTarget(event.target);
  };

  /// Delete application on Accept
  const onAccept = () => {
    const applicationIDs: string[] = [properties.application].map(
      (eachApplication: IApplication) => eachApplication._id
    ) as string[]; /**< applicationIDs */
    properties.handleDelete(applicationIDs);
    setShowConfirmationPopup(false);
  };

  // To delete invoice Logo.
  const deleteInvoiceLogo = () => {
    setLogoUploadStatus('delete');
  };

  // To upload invoice Logo.
  const uploadLogo = async (files: any) => {
    const file = files && files[0];
    if (file.type.indexOf('image/') !== -1) {
      // Crop Dialog.
      setInitiateLogoUpload(file);
      setLogoFileChanged(true);
      setLogoUploadStatus('add');
    } else {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  /// Handle Image Upload Error handler.
  const errorHandler = (error: any) => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({
        severity: 'error',
        summary: translation('insta_image.no_image_uploaded')
      });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({
        severity: 'error',
        summary: translation('insta_image.upload_valid_image')
      });
    }
  };

  /// Handle Logo crop event
  const handleLogoImageCropComplete = (image: any) => {
    setCroppedLogo(image);
    setInitiateLogoUpload(image);
    properties.setFieldValue('file', image);
  };

  /// Get logo div
  const getInvoiceLogo = () => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={properties.values.invoiceLogo || null}
          showDelete={true}
          deleteHandler={deleteInvoiceLogo}
          imgContainerClass={'px-3'}
          onChange={uploadLogo}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={translation('insta_image.logo_alt')}
          isPlainBtn={true}
          plainBtnLabel={translation(
            'application.fields.choose_application_image'
          )}
          croppedImage={croppedLogo}
        />
      </div>
    );
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setShowConfirmationPopup(false);
  };

  useEffect(() => {
    dispatch(fetchUserApplications()); // To get user applications.
  }, []);

  /// Handler for user application selection
  const onUserApplicationSelectionEvent = (event: any) => {
    if (!event.target.value) {
      event.target.value = '';
    } else {
      if (applicationState.userApplications) {
        const selectedUserApplication: IApplications = applicationState.userApplications.find(
          (userApplication: IApplications) =>
            userApplication.applicationUUID === event.target.value
        );
        if (selectedUserApplication) {
          properties.setFieldValue(
            'applicationName',
            selectedUserApplication.applicationName
          );
          properties.setFieldValue(
            'description',
            selectedUserApplication.description
          );
        }
      }
    }

    properties.handleChange(event);
  };

  /**
   * Add custom field key
   */
  const onAddCustomFieldKey = (): void => {
    const newCustomFieldKeyArray: string[] = Array.from(
      new Set([...customFieldKeys, customFieldName])
    );
    setCustomFieldKeys(newCustomFieldKeyArray);
    properties.setFieldValue('customFieldKeys', newCustomFieldKeyArray);
    setCustomFieldName('');
  };

  /**
   * Remove custom field key
   */
  const onRemoveCustomFieldKey = (keyIndex: number): void => {
    const filteredCustomFieldKeys: string[] = customFieldKeys.filter(
      (item: string, index: number) => index !== keyIndex
    ); /**< Filtered custom field keys */
    setCustomFieldKeys(filteredCustomFieldKeys);
    properties.setFieldValue('customFieldKeys', filteredCustomFieldKeys);
  };

  useEffect(() => {
    setSelectedLanguageTranslationIndex(
      properties.values?.translationData?.findIndex(
        (data: IApplicationTranslation) => data.language === selectedLanguage
      )
    );
  }, [selectedLanguage, properties.values?.translationData]);

  return (
    <>
      <Col sm={12}>{getInvoiceLogo()}</Col>
      <FormWrapper
        controlButtonLabel={
          properties.application &&
          Object.keys(properties.application).length > 0
        }
        isLoading={properties.isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClicked(event)
        }
        handleSubmitButton={() => {
          if (properties.application) {
            properties.values.logoUploadStatus = logoUploadStatus;
          }
          properties.handleSubmit(properties.values);
          setLogoUploadStatus('');
        }}
      >
        <Row>
          <Form.Group as={Col} sm="12" md="12">
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label>
              {translation('application.fields.userApplication')}
            </Form.Label>
            <Dropdown
              placeholder={
                !applicationState.userApplications ||
                applicationState.userApplications.length === 0
                  ? 'No User Application'
                  : translation(
                      'application.fields.userApplication_placeholder'
                    )
              }
              name="applicationUUID"
              inputId="applicationUUID"
              onChange={onUserApplicationSelectionEvent}
              onBlur={properties.handleBlur}
              value={properties.values.applicationUUID}
              disabled={
                !applicationState.userApplications ||
                applicationState.userApplications.length === 0 ||
                properties.application
                  ? true
                  : false
              }
              options={applicationState.userApplicationtListValueAndLabel}
              className={
                properties.touched.applicationUUID &&
                properties.errors.applicationUUID
                  ? 'p-invalid'
                  : ''
              }
              showClear={
                !isStringEmptyOrNullOrUndefined(
                  properties.values.applicationUUID
                )
              }
            />
            {properties.touched.applicationUUID &&
            properties.errors.applicationUUID ? (
              <small id="email-invalid" className="p-invalid error-text">
                {properties.errors.applicationUUID}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.applicationName')}
              name="applicationName"
              id={'applicationName'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.applicationName}
              errors={properties.errors.applicationName}
              value={properties.values.applicationName}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.description')}
            </Form.Label>
            <InputTextarea
              id="description"
              name="description"
              disabled={properties.isLoading}
              value={properties.values.description}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.description && properties.errors.description
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.description && properties.errors.description ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.description)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyLegalName')}
              name="companyLegalName"
              id={'companyLegalName'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyLegalName}
              errors={properties.errors.companyLegalName}
              value={properties.values.companyLegalName}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyShortName')}
              name="companyShortName"
              id={'companyShortName'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyShortName}
              errors={properties.errors.companyShortName}
              value={properties.values.companyShortName}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.companyAddress')}
            </Form.Label>
            <InputTextarea
              id="companyAddress"
              name="companyAddress"
              disabled={properties.isLoading}
              value={properties.values.companyAddress}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.companyAddress &&
                properties.errors.companyAddress
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.companyAddress &&
            properties.errors.companyAddress ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.companyAddress)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyCity')}
              name="companyCity"
              id={'companyCity'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyCity}
              errors={properties.errors.companyCity}
              value={properties.values.companyCity}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyCountry')}
              name="companyCountry"
              id={'companyCountry'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyCountry}
              errors={properties.errors.companyCountry}
              value={properties.values.companyCountry}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyZIPCode')}
              name="companyZIPCode"
              id={'companyZIPCode'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyZIPCode}
              errors={properties.errors.companyZIPCode}
              value={properties.values.companyZIPCode}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyWebsite')}
              name="companyWebsite"
              id={'companyWebsite'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyWebsite}
              errors={properties.errors.companyWebsite}
              value={properties.values.companyWebsite}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyContactEmail')}
              name="companyContactEmail"
              id={'companyContactEmail'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyContactEmail}
              errors={properties.errors.companyContactEmail}
              value={properties.values.companyContactEmail}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyContactNumber')}
              name="companyContactNumber"
              id={'companyContactNumber'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyContactNumber}
              errors={properties.errors.companyContactNumber}
              value={properties.values.companyContactNumber}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyDETaxID')}
              name="companyDETaxID"
              id={'companyDETaxID'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyDETaxID}
              errors={properties.errors.companyDETaxID}
              value={properties.values.companyDETaxID}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.companyEUVatID')}
              name="companyEUVatID"
              id={'companyEUVatID'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.companyEUVatID}
              errors={properties.errors.companyEUVatID}
              value={properties.values.companyEUVatID}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <InstaInputText
              label={translation('application.fields.invoiceNumberPrefix')}
              name="invoiceNumberPrefix"
              id={'invoiceNumberPrefix'}
              isLoading={properties.isLoading}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              touched={properties.touched.invoiceNumberPrefix}
              errors={properties.errors.invoiceNumberPrefix}
              value={properties.values.invoiceNumberPrefix}
              labelClassName="required"
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.invoiceFooter1')}
            </Form.Label>
            <InputTextarea
              id="invoiceFooter1"
              name="invoiceFooter1"
              disabled={properties.isLoading}
              value={properties.values.invoiceFooter1}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.invoiceFooter1 &&
                properties.errors.invoiceFooter1
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.invoiceFooter1 &&
            properties.errors.invoiceFooter1 ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.invoiceFooter1)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.invoiceFooter2')}
            </Form.Label>
            <InputTextarea
              id="invoiceFooter2"
              name="invoiceFooter2"
              disabled={properties.isLoading}
              value={properties.values.invoiceFooter2}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.invoiceFooter2 &&
                properties.errors.invoiceFooter2
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.invoiceFooter2 &&
            properties.errors.invoiceFooter2 ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.invoiceFooter2)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.invoiceFooter3')}
            </Form.Label>
            <InputTextarea
              id="invoiceFooter3"
              name="invoiceFooter3"
              disabled={properties.isLoading}
              value={properties.values.invoiceFooter3}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.invoiceFooter3 &&
                properties.errors.invoiceFooter3
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.invoiceFooter3 &&
            properties.errors.invoiceFooter3 ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.invoiceFooter3)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label>
              {translation('application.fields.notificationURL')}
            </Form.Label>
            <InputTextarea
              id="notificationURL"
              name="notificationURL"
              disabled={properties.isLoading}
              value={properties.values.notificationURL}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.notificationURL &&
                properties.errors.notificationURL
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.notificationURL &&
            properties.errors.notificationURL ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.notificationURL)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label>
              {translation('application.fields.applicationPageURL')}
            </Form.Label>
            <InputTextarea
              id="applicationPageURL"
              name="applicationPageURL"
              disabled={properties.isLoading}
              value={properties.values.applicationPageURL}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.applicationPageURL &&
                properties.errors.applicationPageURL
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.applicationPageURL &&
            properties.errors.applicationPageURL ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.applicationPageURL)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label htmlFor={'customFieldKeys'}>
              {translation('application.fields.customFields')}
            </Form.Label>
            <InputText
              id="customFieldKeys"
              value={customFieldName}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  onAddCustomFieldKey();
                }
              }}
              onChange={(event) => {
                setCustomFieldName(event.target.value);
              }}
              className={
                properties.touched.customFieldKeys &&
                properties.errors.customFieldKeys
                  ? 'p-invalid'
                  : ''
              }
              onBlur={properties.handleBlur}
            />
            <>
              <div className="d-flex justify-content-start flex-wrap mt-2">
                {customFieldKeys &&
                  customFieldKeys.map &&
                  customFieldKeys.map(
                    (customFieldKey: string, index: number) => {
                      return (
                        <>
                          {' '}
                          <div className="p-tag mx-1 mb-2 selectedIP">
                            {customFieldKey}{' '}
                            <span
                              className="pi pi-times-circle clickable"
                              onClick={(event: any) =>
                                onRemoveCustomFieldKey(index)
                              }
                            />
                          </div>{' '}
                        </>
                      );
                    }
                  )}
              </div>
            </>
            {properties.touched.customFieldKeys &&
            properties.errors.customFieldKeys ? (
              <small id="name-invalid" className="p-invalid">
                {properties.errors.customFieldKeys}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="12" md="6">
            <Form.Label className="required">
              {translation('application.fields.slaTermsLink')}
            </Form.Label>
            <InputTextarea
              id="slaTermsLink"
              name="slaTermsLink"
              disabled={properties.isLoading}
              value={properties.values.slaTermsLink}
              onChange={properties.handleChange}
              onBlur={properties.handleBlur}
              className={
                properties.touched.slaTermsLink &&
                properties.errors.slaTermsLink
                  ? 'p-invalid'
                  : ''
              }
              rows={1}
              autoResize
            />
            {properties.touched.slaTermsLink &&
            properties.errors.slaTermsLink ? (
              <small id="email-invalid" className="p-invalid error-text">
                {translation(properties.errors.slaTermsLink)}
              </small>
            ) : null}
          </Form.Group>
          <InstaTextArea
            sizing={{ sm: 12, md: 12 }}
            label={translation('application.fields.nextStepText')}
            fieldName={`translationData.${selectedLanguageTranslationIndex}.nextStepText`}
            id={`translationData.${selectedLanguageTranslationIndex}.nextStepText`}
            isLoading={properties.isLoading}
            value={
              properties.values.translationData &&
              properties.values.translationData.length &&
              properties.values.translationData[
                selectedLanguageTranslationIndex
              ] &&
              properties.values.translationData[
                selectedLanguageTranslationIndex
              ].nextStepText
            }
            onChange={properties.handleChange}
            onBlur={properties.handleBlur}
            touched={
              properties.touched.translationData &&
              properties.touched.translationData.length &&
              properties.touched.translationData[
                selectedLanguageTranslationIndex
              ] &&
              properties.touched.translationData[
                selectedLanguageTranslationIndex
              ].nextStepText
            }
            errors={
              properties.errors.translationData &&
              properties.errors.translationData.length &&
              properties.errors.translationData[
                selectedLanguageTranslationIndex
              ] &&
              properties.errors.translationData[
                selectedLanguageTranslationIndex
              ].nextStepText
            }
            rows={2}
          />
        </Row>
        <Row>
          <Col sm={12}>
            <NextStepButtonForm
              values={properties.values?.nextStepTextButtons}
              errors={properties.errors?.nextStepTextButtons}
              handleChange={properties.handleChange}
              isLoading={properties.isLoading}
              handleBlur={properties.handleBlur}
              touched={properties.touched?.nextStepTextButtons}
              setFieldValue={properties.setFieldValue}
              selectedLanguage={selectedLanguage}
              translationData={properties.values?.translationData}
              translationErrors={properties.errors?.translationData}
              translationTouched={properties.touched?.translationData}
              selectedLanguageTranslationIndex={
                selectedLanguageTranslationIndex
              }
              englishTranslationIndex={englishTranslationIndex}
            />
          </Col>
        </Row>
      </FormWrapper>
      <CropDialog
        isVisible={isLogoFileChanged}
        setVisible={setLogoFileChanged}
        file={initiateLogoUpload}
        onImageCropComplete={handleLogoImageCropComplete}
      />
      <ConfirmationPopup
        target={confirmationPopupTarget}
        isShow={isShowConfirmationPopup}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="top"
      />
    </>
  );
};

export default withFormik({
  mapPropsToValues: ({ application }) => {
    // Format inital application translation
    const initialTranslationData: IApplicationTranslation[] = [];
    for (const language of Languages) {
      initialTranslationData.push({
        language: language.value,
        nextStepText: '',
        nextStepButtonsText: []
      });
    }
    if (!application) {
      return {
        applicationName: '',
        description: '',
        applicationUUID: '',
        invoiceLogo: '',
        companyLegalName: '',
        companyShortName: '',
        companyAddress: '',
        companyCity: '',
        companyCountry: '',
        companyZIPCode: '',
        companyWebsite: '',
        companyContactEmail: '',
        companyContactNumber: '',
        companyDETaxID: '',
        companyEUVatID: '',
        invoiceNumberPrefix: '',
        invoiceFooter1: '',
        invoiceFooter2: '',
        invoiceFooter3: '',
        notificationURL: '',
        applicationPageURL: '',
        customFieldKeys: [],
        slaTermsLink: '',
        nextStepTextButtons: [],
        translationData: initialTranslationData
      };
    }

    // Note: Get the application translation if it already exists and corresponds to the same language array. Otherwise, add/delete a language object or use initialTranslationData.
    let applicationTranslationData: IApplicationTranslation[] = [];
    if (application.translationData && application.translationData.length) {
      initialTranslationData.forEach((initialData: IApplicationTranslation) => {
        application.translationData.forEach(
          (existingData: IApplicationTranslation) => {
            if (initialData.language === existingData.language) {
              applicationTranslationData.push(existingData);
            }
          }
        );
      });
      if (applicationTranslationData.length !== initialTranslationData.length) {
        const newLanguageData: IApplicationTranslation[] = initialTranslationData.filter(
          (initialData: IApplicationTranslation) =>
            !applicationTranslationData.some(
              (existingData: IApplicationTranslation) =>
                initialData.language === existingData.language
            )
        );
        applicationTranslationData = applicationTranslationData.concat(
          newLanguageData
        );
      }
    } else {
      applicationTranslationData = initialTranslationData;
    }
    return {
      applicationName: application.applicationName,
      description: application.description,
      applicationUUID: application.applicationUUID ?? '',
      invoiceLogo: application.invoiceLogo,
      companyLegalName: application.companyLegalName,
      companyShortName: application.companyShortName,
      companyAddress: application.companyAddress,
      companyCity: application.companyCity,
      companyCountry: application.companyCountry,
      companyZIPCode: application.companyZIPCode,
      companyWebsite: application.companyWebsite,
      companyContactEmail: application.companyContactEmail,
      companyContactNumber: application.companyContactNumber,
      companyDETaxID: application.companyDETaxID,
      companyEUVatID: application.companyEUVatID,
      invoiceNumberPrefix: application.invoiceNumberPrefix,
      invoiceFooter1: application.invoiceFooter1,
      invoiceFooter2: application.invoiceFooter2,
      invoiceFooter3: application.invoiceFooter3,
      notificationURL: application.notificationURL,
      applicationPageURL: application.applicationPageURL,
      customFieldKeys: application.customFieldKeys,
      slaTermsLink: application.slaTermsLink,
      nextStepTextButtons: application.nextStepTextButtons ?? [],
      translationData: applicationTranslationData
    };
  },
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: Yup.object().shape({
    applicationName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.applicationName')
        })
      )
      .nullable(),
    description: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(200, 'Must be 200 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.description')
        })
      )
      .nullable(),
    applicationUUID: Yup.string(),
    invoiceLogoImageName: Yup.object()
      .nullable()
      .notRequired(),
    companyLegalName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(200, 'Must be 200 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyLegalName')
        })
      )
      .nullable(),
    companyShortName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(200, 'Must be 200 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyShortName')
        })
      )
      .nullable(),
    companyAddress: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyAddress')
        })
      )
      .nullable(),
    companyCity: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(200, 'Must be 200 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyCity')
        })
      )
      .nullable(),
    companyCountry: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyCountry')
        })
      )
      .nullable(),
    companyZIPCode: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyZIPCode')
        })
      )
      .nullable(),
    companyWebsite: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyWebsite')
        })
      )
      .nullable(),
    companyContactEmail: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(200, 'Must be 200 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyContactEmail')
        })
      )
      .nullable(),
    companyContactNumber: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyContactNumber')
        })
      )
      .nullable(),
    companyDETaxID: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyDETaxID')
        })
      )
      .nullable(),
    companyEUVatID: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.companyEUVatID')
        })
      )
      .nullable(),
    invoiceNumberPrefix: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.invoiceNumberPrefix')
        })
      )
      .nullable(),
    invoiceFooter1: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.invoiceFooter1')
        })
      )
      .nullable(),
    invoiceFooter2: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.invoiceFooter2')
        })
      )
      .nullable(),
    invoiceFooter3: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .required(
        i18n.t('validation.required', {
          field: i18n.t('application.fields.invoiceFooter3')
        })
      )
      .nullable(),
    notificationURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(/https?:\/\/+[a-zA-Z]/g, i18n.t('validation.isUrl'))
      .nullable()
      .notRequired(),
    applicationPageURL: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .nullable()
      .notRequired(),
    customFieldKeys: Yup.array(),
    slaTermsLink: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(500, 'Must be 500 characters or less')
      .matches(validators.RSS_URL, i18n.t('validation.isUrl'))
      .required(),
    nextStepTextButtons: Yup.array().of(
      Yup.object().shape({
        buttonURL: Yup.string()
          .matches(validators.RSS_URL, i18n.t('validation.isUrl'))
          .required(
            i18n.t('validation.required', {
              field: i18n.t(
                'application.fields.nextStepButton.fields.buttonURL'
              )
            })
          ),
        buttonIcon: Yup.string().nullable(),
        buttonCSS: Yup.string().nullable()
      })
    ),
    translationData: Yup.array().of(
      Yup.object().shape({
        language: Yup.string(),
        nextStepText: Yup.string().nullable(),
        nextStepButtonsText: Yup.array().when('language', {
          is: LanguageSettingsMode.english,
          then: Yup.array().of(
            Yup.string().required(
              i18n.t('validation.required', {
                field: i18n.t(
                  'application.fields.nextStepButton.fields.buttonText'
                )
              })
            )
          ),
          otherwise: Yup.array().of(Yup.string().nullable())
        })
      })
    )
  }),
  handleSubmit: (values, bag) => {
    if (bag.props.application) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (key == 'logoUploadStatus') {
          if (values[key]) {
            updatedFieldsCount++;
          }
        } else if (key == 'customFieldKeys') {
          if (values[key].length == bag.props.application[key].length) {
            if (
              !isArrayOfStringsTheSame(values[key], bag.props.application[key])
            ) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key == 'nextStepTextButtons') {
          if (values[key].length == bag.props.application[key].length) {
            const newString: string = JSON.stringify(values[key].sort());
            const initialString: string = JSON.stringify(
              bag.props.application[key].sort()
            );
            if (newString != initialString) {
              updatedFieldsCount++;
            }
          } else {
            updatedFieldsCount++;
          }
        } else if (key === 'translationData') {
          if (values[key].length == bag.props.application[key].length) {
            const newTranslationsString: string = JSON.stringify(
              values[key].sort()
            );
            const initialTranslationsString: string = JSON.stringify(
              bag.props.application[key].sort()
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          } else {
            const newTranslation = values[key].map(
              (translation: IApplicationTranslation) => {
                return {
                  language: translation?.language,
                  nextStepText: translation?.nextStepText,
                  nextStepButtonsText: translation?.nextStepButtonsText
                };
              }
            );
            const newTranslationsString: string = JSON.stringify(
              newTranslation.sort()
            );
            // Format inital application translation
            const initialTranslationData: IApplicationTranslation[] = [];
            for (const language of Languages) {
              const existingTranslation: IApplicationTranslation =
                bag.props.application[key] &&
                bag.props.application[key].length &&
                bag.props.application[key][
                  bag.props.application[key]?.findIndex(
                    (data: IApplicationTranslation) =>
                      data.language === language.value
                  )
                ];
              initialTranslationData.push({
                language: language.value,
                nextStepText: existingTranslation?.nextStepText ?? '',
                nextStepButtonsText:
                  existingTranslation?.nextStepButtonsText ?? []
              });
            }
            const initialTranslationsString: string = JSON.stringify(
              initialTranslationData
            );
            if (newTranslationsString != initialTranslationsString) {
              updatedFieldsCount++;
            }
          }
        } else if (
          key !== 'invoiceLogo' &&
          values[key as keyof typeof bag.props.application] !==
            bag.props.application[key as keyof typeof bag.props.application]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit({
          _id: bag.props.application._id,
          ...values
        });
      }
      return;
    }
    return bag.props.handleSubmit({ ...values });
  }
})(ApplicationForm);
