/*
 * PlaceholderComponent.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file PlaceholderComponent.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, ReactElement } from "react";
import Loader from "../Loader";
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { InputText } from "primereact/inputtext";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import BaseDatatable from "../Table/BaseDatatable";
import { Column } from "primereact/column";
import DatatableColumn from "../Table/DatatableColumn";
import { defaultTableLimit } from '../Constants';

/**
 * Template placeholder component.
 * @name PlaceholderComponent
 * @param {object} - Props object consisting of placeholders and templateType.
 */
const PlaceholderComponent = ({
  placeholders,
  templateType,
  copyPlaceholderHandler,
  updatePlaceholderHandler,
}: any): ReactElement => {
  const [editPlaceholder, setEditPlaceholder] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const { t } = useTranslation();

  /// Triggers edit mode of the placeholders
  const toggleEditMode = (item: any) => {
    const placeholderValue = item.placeholderValue;
    setPlaceholder(placeholderValue);
    setEditPlaceholder(item);
  };

  /// Triggers to handle the placeholder update action.
  const handleUpdatePlaceholder = () => {
    updatePlaceholderHandler(editPlaceholder, placeholder, templateType);
    setEditPlaceholder("");
  };

  if (!placeholders) {
    return <Loader />;
  }

  if (placeholders.length === 0) {
    return <>{t("awc:/.templateEditor.placeholders.no_placeholder")}</>;
  }

  /// Table header
  const header: JSX.Element = (
    <div className="text-center">{t("awc:/.templateEditor.template.placeholders")}</div>
  );

  return (
    <>
      <div className="responsiveBaseDataTable max-height">
        <BaseDatatable
          value={placeholders}
          rows={defaultTableLimit}
          paginator={false} /** No paginator */
          header={header}
          parentClass="datatable-withoutPagination" /**< ClassName for div Component.*/
        >
          {/* Used to prevent row selection on row click */}
          <Column selectionMode="multiple" className="d-none" />
          <Column expander className="invisible d-sm-none p-0 col-width-45" headerClassName="invisible d-sm-none p-0 col-width-45" />
          <Column
            field="placeholderKey"
            header={t("awc:/.templateEditor.placeholders.name")}
            body={(rowData: any) => (
              <DatatableColumn
                title={t("awc:/.templateEditor.placeholders.name")}
                data={<Button
                  variant="outline"
                  className="p-0"
                  onClick={() => {
                    copyPlaceholderHandler(rowData?.placeholderKey || "");
                  }}
                >
                  {rowData?.placeholderKey || ""}
                </Button>}
              />
            )}
          ></Column>
          <Column
            field="placeholderValue"
            header={t("awc:/.templateEditor.placeholders.value")}
            body={(rowData: any) => {
              return (
                <DatatableColumn
                  title={t("awc:/.templateEditor.placeholders.value")}
                  data={rowData?.placeholderValue}
                />
              );
            }}
          ></Column>
          <Column
            field="placeHolder"
            className="p-0 col-width-45 absolute-position-responsive-screen"
            body={(rowData: any) => {
              return (
                <Button
                  className="custom-action-column-action-position"
                  variant="outline"
                  onClick={() => toggleEditMode(rowData)}
                >
                  <i className="bi bi-pencil-square editIcon fa-lg"></i>
                </Button>
              );
            }}
          />
        </BaseDatatable>
      </div>

      <DialogWrapper
        isDialogVisible={editPlaceholder !== ""}
        onHide={() => setEditPlaceholder("")}
        headerTitle={t("awc:/.templateEditor.placeholders.edit_placeholder")}
      >
        <InputText
          type={"text"}
          name={"placeholder"}
          value={placeholder}
          onChange={(e: any) => setPlaceholder(e.target.value)}
          className={placeholder === "" ? "p-error" : ""}
        />
        <Button
          type="button"
          className="mt-3 d-flex align-items-center float-right"
          onClick={handleUpdatePlaceholder}
        >
          <i className="bi bi-check2-circle btn-icon"></i>
          {t("awc:/.templateEditor.placeholders.save")}
        </Button>
      </DialogWrapper>
    </>
  );
};

export default PlaceholderComponent;
