/**
* pagination.request.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file pagination.request.ts
* @author Pascal Mayr
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

export const paginationRequest = (url: string, payload: any): string => {
  
  if (!payload) {
    payload = {};
  }
  let queryParameters: string = `?limit=${payload.limit ?? 10}&skip=${payload.skip || 0}&searchTerm=${encodeURIComponent(payload.searchTerm || '')}`;

  if (payload.sort) {
    queryParameters = `${queryParameters}&sort=${encodeURIComponent(JSON.stringify(payload.sort))}`;
  }
  if (payload.filter) {
    queryParameters = `${queryParameters}&filter=${encodeURIComponent(JSON.stringify(payload.filter))}`;
  }
  return `${url}${queryParameters}`;
};
