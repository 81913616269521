import React from 'react';
import { Card } from 'primereact/card';
import Col from 'react-bootstrap/Col';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import './Analytics.css';

/**
 * IChartTypes
 */
type IChartTypes =
  | 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | undefined;

/**
 * @interface IChartProperties
 */
interface IChartProperties {
  type?: IChartTypes /**< Chart Types. */;
  series?: Array<any>;
  width?: string | number /**< Width of the chart */;
  height?: string | number /**< Height of the chart */;
  options?: ApexOptions;
  [key: string]: any;
}

/**
 * @interface IDimensions
 */
interface IDimensions {
  small: number /**< Chart small dimension */;
  medium: number /**< Chart medium dimension */;
  large: number /**< Chart large dimension */;
}

/**
 * Base class that other classes inherit from.
 */
abstract class DashboardItem {
  abstract getDashboard(): JSX.Element;
  abstract getTitle(): string;
}

/**
 * Numeric dashboard item
 */
export class NumericDashboardItem extends DashboardItem {
  private title: string;
  private value: number;

  public constructor(title: string, value: number) {
    super();
    this.title = title;
    this.value = value;
  }

  getDashboard(): JSX.Element {
    return (
      <Col sm={12} md={6} lg={3} className="custom-card-wrapper">
        <Card title={this.title} className="custom-card-container">
          <div className="d-flex align-items-center">
            Total:
            <h4 className="mt-2 ml-2 text-break">{this.value}</h4>
          </div>
        </Card>
      </Col>
    );
  }
  getTitle(): string {
    return this.title;
  }
}

/**
 * Chart dashboard item
 */
export class ChartDashboardItem extends DashboardItem {
  private title: string;
  private chartProperties: IChartProperties;
  private smallDimension: number = 12;
  private mediumDimension: number = this.smallDimension;
  private largeDimension: number = this.mediumDimension;

  public constructor(
    title: string,
    chartProperties: IChartProperties,
    dimensions?: IDimensions
  ) {
    super();
    this.title = title;
    this.chartProperties = chartProperties;
    if (dimensions !== null && dimensions !== undefined) {
      this.smallDimension = dimensions.small || 12;
      this.mediumDimension = dimensions.medium || this.smallDimension;
      this.largeDimension = dimensions.large || this.mediumDimension;
    }
  }

  getDashboard(): JSX.Element {
    return (
      <Col
        sm={this.smallDimension}
        md={this.mediumDimension}
        lg={this.largeDimension}
      >
        <Card title={this.title}>
          <div>
            <Chart
              options={this.chartProperties.options}
              series={this.chartProperties.series}
              type={this.chartProperties.type}
              width={this.chartProperties.width}
              height={this.chartProperties.height}
            />
          </div>
        </Card>
      </Col>
    );
  }

  getChartType(): IChartTypes {
    return this.chartProperties.type;
  }

  getTitle(): string {
    return this.title;
  }
}

/**
 * Component dashboard item
 */
export class CustomComponentDashboardItem extends DashboardItem {
  private component: JSX.Element;
  private title: string;

  public constructor(title: string, component: JSX.Element) {
    super();
    this.title = title;
    this.component = component;
  }

  getDashboard(): JSX.Element {
    return (
      <Col sm={12} md={12}>
        <Card title={this.title}>
          <div>{this.component}</div>
        </Card>
      </Col>
    );
  }

  getTitle(): string {
    return this.title;
  }
}
