/*
 * ApplicationManagementPage.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ApplicationManagementPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import ApplicationTable from './ApplicationTable';
import { IApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { useAppSelector } from '../../../Hooks';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  addOrEditApplication,
  deleteApplication,
  fetchAllPaginatedApplications,
  toggleApplicationDialog
} from '../../../Store/Applications';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import ApplicationForm from './ApplicationForm';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const ApplicationManagementPage = (): JSX.Element => {
  const translation = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const applications: IApplication[] = useAppSelector(
    (state) => state.applications.list
  );
  const editedApplication: IApplication = useAppSelector(
    (state) => state.applications.editedApplication
  );
  const isDialogOpened: boolean = useAppSelector(
    (state) => state.applications.isApplicationDialogOpened
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.applications.isFetchingList
  );
  const isApplicationChanging: boolean = useAppSelector(
    (state) => state.applications.isApplicationChanging
  ); /**< isApplicationChanging */
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: 'createdAt', sortOrder: -1 },
    searchTerm: ''
  }); /**< Default Payload */
  const totalRecords: number = useAppSelector(
    (state) => state.applications.totalRecords
  ); /**< Applications TotalRecords. */

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const updatedPayload: ITablePayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(
      fetchAllPaginatedApplications(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      fetchAllPaginatedApplications(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = tablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      fetchAllPaginatedApplications(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort
      )
    );
  };

  useEffect(() => {
    dispatch(
      fetchAllPaginatedApplications(
        tablePayload.skip,
        tablePayload.limit,
        tablePayload.searchTerm,
        tablePayload.sort
      )
    ); /**< Fetch all applications. */
  }, []);

  /// Handler for add or edit application
  const handleAddOrEditApplication = async (values: Record<string, any>) => {
    await asyncErrorHandler(dispatch(addOrEditApplication(values)));

    await asyncErrorHandler(
      dispatch(
        fetchAllPaginatedApplications(
          tablePayload.skip,
          tablePayload.limit,
          tablePayload.searchTerm,
          tablePayload.sort
        )
      )
    ); /**< Fetch all applications. */
  };

  /// Handler for dialog open
  const handleDialogOpen = async () => {
    await asyncErrorHandler(dispatch(toggleApplicationDialog(true, null)));
  };

  /// Handler for edit application
  const handleEditApplication = async (event: any) => {
    await asyncErrorHandler(
      dispatch(toggleApplicationDialog(true, event.data))
    );
  };

  /// Handler for delete application(s)
  const handleDelete = async (applicationIDs: string[]) => {
    await asyncErrorHandler(dispatch(deleteApplication(applicationIDs)));
    await asyncErrorHandler(
      dispatch(
        fetchAllPaginatedApplications(
          tablePayload.skip,
          tablePayload.limit,
          tablePayload.searchTerm,
          tablePayload.sort
        )
      )
    ); /**< Fetch all applications. */
  };

  const applicationToEdit: IApplication | undefined =
    editedApplication &&
    applications.find(
      (application: IApplication) => application._id === editedApplication._id
    );

  return (
    <>
      <Row>
        <Col sm={12}>
          <ApplicationTable
            applications={applications}
            handleDialogOpen={handleDialogOpen}
            handleEdit={handleEditApplication}
            isLoading={isLoading}
            handleDelete={handleDelete}
            handlePageUpdate={handlePageUpdate}
            handleSortUpdate={handleSortUpdate}
            handleFilterUpdate={handleFilterUpdate}
            tablePayload={tablePayload}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <DialogWrapper
        isDialogVisible={isDialogOpened}
        headerTitle={
          applicationToEdit
            ? translation('application.form.header.edit_application_dialog')
            : translation('application.form.header.add_application_dialog')
        }
        onHide={() => {
          dispatch(toggleApplicationDialog(false, null));
        }}
      >
        <ApplicationForm
          handleSubmit={handleAddOrEditApplication}
          application={applicationToEdit}
          handleDelete={handleDelete}
          isLoading={isApplicationChanging}
        />
      </DialogWrapper>
    </>
  );
};

export default ApplicationManagementPage;
