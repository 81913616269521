/*
 * CustomersPage.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file CustomersPage.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomerTable from './CustomerTable';
import { getAllTransactionsListGroupByUsername } from '../../../Store/Customers';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

const CustomersPage = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers);

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: { sortField: '_id', sortOrder: 1 },
    searchTerm: ''
  }); /**< Default Payload */

  useEffect(() => {
    dispatch(
      getAllTransactionsListGroupByUsername(
        payload.skip,
        payload.limit,
        payload.searchTerm,
        payload.sort,
        payload.filter
      )
    );
  }, []);

  const handlePageUpdate = (event) => {
    const { first, rows } = event;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: payload.sort,
      searchTerm: payload.searchTerm,
      filter: payload.filter
    };
    setPayload(updatedPayload);
    dispatch(
      getAllTransactionsListGroupByUsername(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        updatedPayload.filter
      )
    );
  };
  const handleSortUpdate = (event) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: event,
      searchTerm: payload.searchTerm,
      filter: payload.filter
    };
    setPayload(updatedPayload);
    dispatch(
      getAllTransactionsListGroupByUsername(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        updatedPayload.filter
      )
    );
  };

  const handleFilterUpdate = (event) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: payload.sort,
      searchTerm: event,
      filter: payload.filter
    };
    setPayload(updatedPayload);
    dispatch(
      getAllTransactionsListGroupByUsername(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        updatedPayload.filter
      )
    );
  };

  // Handle product filter update event
  const handleProductFilterUpdate = (filter: Record<string, any>): void => {
    const updatedPayload: ITablePayload = payload;
    updatedPayload.filter = filter;
    setPayload(updatedPayload);
    dispatch(
      getAllTransactionsListGroupByUsername(
        updatedPayload.skip,
        updatedPayload.limit,
        updatedPayload.searchTerm,
        updatedPayload.sort,
        updatedPayload.filter
      )
    );
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <CustomerTable
            customers={customers.list}
            loading={customers.listIsFetching}
            handlePageUpdate={handlePageUpdate}
            handleFilterUpdate={handleFilterUpdate}
            handleSortUpdate={handleSortUpdate}
            handleProductFilterUpdate={handleProductFilterUpdate}
            count={customers.count}
            limit={payload.limit}
            skip={payload.skip}
            sort={payload.sort}
            filter={payload.filter}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomersPage;
