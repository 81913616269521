/**
 * FrontendError.ts (InstaLOD GmbH)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2021
 *
 * @file FrontendError.ts
 * @author Martin Witczak
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * API service to send frontend error to the backend.
 * @function
 */
export const sendFrontendError = (errorId: string, error: any) => {
  return httpClient.post(`/logs/report-error`, {
    errorId: errorId,
    error: JSON.stringify(error)
  });
};
