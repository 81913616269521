/*
 * LogTable.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Sai Charan K, 2021
 *
 * @file LogTable.tsx
 * @author Sai Charan K
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { ReactElement } from "react";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import InstaTable, { IInstaTableProperties } from "../Table/InstaTable";
import DatatableColumn from "../Table/DatatableColumn";
import "./index.css";
import UserDetailsPopup from '../UserDetailsPopup';
import { formatDate, capitalizeFirstCharacterOnString } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

import "../common.css"

interface ILogTableProperties extends IInstaTableProperties {
  categoryFilterElement: any;
  securityLevelFilterElement: any;
  showExpander?: any;
  logDetails: (rowData: any) => any;
  refreshList?: () => any;
  clients?: any; /**< Client data for UserDetailsPopup. */
  isLoadingClients?: boolean /**< Is client data fetching. */
}

const LogTable = ({
  categoryFilterElement,
  securityLevelFilterElement,
  showExpander,
  clients,
  ...properties
}: ILogTableProperties): ReactElement => {
  const { t } = useTranslation();
  let userData: any; /**< User details. */
  
  // Clients from abstractlicense to update userData for UserDetailsPopup.
  if (clients) {
    userData = clients;
  } else {
    userData = properties.data;
  }
  
  const getRowExpander = (): ReactElement => {
    if (showExpander) {
      return (
        <Column expander className="p-0 col-width-3" headerClassName="p-0 col-width-3" />
      );
    }
    return <></>;
  };

  const getLogTable = () => {
    return (
      <>
        <InstaTable selectionMode="checkbox" {...properties}>
          {getRowExpander()}
          {/* Used to prevent row selection on row click */}
          <Column selectionMode="multiple" className="d-none" />
          <Column
            field="createdAt"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.created")}
            sortable
            className="col-width-7"
            body={(rowData: any) => (
              <DatatableColumn
                title={t("awc:/.logs.logs_fieldset.datatable.columns.created")}
                data={
                  rowData?.createdAt
                    ? formatDate(rowData.createdAt)
                    : ""
                }
              />
            )}
          />
          <Column
            field="author"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.author")}
            sortable
            body={(rowData: any) => {
              if (userData !== undefined) {
                const authorUUID: string = rowData.authorUUID !== undefined ? rowData.authorUUID : rowData.userUUID;
                if (authorUUID !== undefined && authorUUID !== null) {
                  return (
                    <>
                      {/* IdentityCard to display userName, user, email and last login. */}
                      <div className="d-none d-sm-block">
                        <UserDetailsPopup
                          users={userData}
                          isLoading={properties.isLoadingClients ?? false}
                          userUUID={authorUUID}
                          key={rowData.id}
                        />
                      </div>
                      <div className="d-block d-sm-none">
                        <DatatableColumn
                          title={t("awc:/.logs.logs_fieldset.datatable.columns.author")}
                          data={<UserDetailsPopup
                            users={userData}
                            isLoading={properties.isLoadingClients ?? false}
                            userUUID={authorUUID}
                            key={rowData.id}
                          />}
                        />
                      </div>
                    </>
                  );
                }

                return (
                  <DatatableColumn
                    title={t("awc:/.logs.logs_fieldset.datatable.columns.author")}
                    data={rowData && rowData.author}
                  />
                )
              }
            }}
            className="col-width-10"
          />
          <Column
            field="category"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.category")}
            sortable
            className="col-width-10 text-nowrap"
            body={(rowData: any) => (
              <DatatableColumn
                title={t("awc:/.logs.logs_fieldset.datatable.columns.category")}
                data={<span className="p-tag p-tag-primary">{rowData.category && capitalizeFirstCharacterOnString(rowData.category)}</span>}
              />
            )}
          />
          <Column
            field="level"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.securityLevel")}
            sortable
            className="d-table-cell d-sm-none d-md-table-cell col-width-10 text-nowrap"
            headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-10 text-nowrap"
            body={(rowData: any) => (
              <DatatableColumn
                title={t(
                  "awc:/.logs.logs_fieldset.datatable.columns.securityLevel"
                )}
                data={
                  <>
                    {rowData.level && rowData.level.toLowerCase() === "info" && (
                      <span className="p-tag bg-info">
                        {t(
                          "awc:/.logs.logs_fieldset.datatable.securityLevels.info"
                        )}
                      </span>
                    )}
                    {rowData.level && rowData.level.toLowerCase() === "warning" && (
                      <span className="p-tag p-tag-warning">
                        {t(
                          "awc:/.logs.logs_fieldset.datatable.securityLevels.warning"
                        )}
                      </span>
                    )}
                    {rowData.level && (rowData.level.toLowerCase() === "danger" || rowData.level.toLowerCase() === "error") && (
                      <span className="p-tag p-tag-danger">
                        {t(
                          "awc:/.logs.logs_fieldset.datatable.securityLevels.danger"
                        )}
                      </span>
                    )}
                  </>
                }
              />
            )}
          />
          <Column
            field="activity"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.activity")}
            sortable
            className="d-table-cell d-sm-none d-xl-table-cell"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell"
            body={(rowData: any) => (
              <DatatableColumn
                title={t("awc:/.logs.logs_fieldset.datatable.columns.activity")}
                data={<span>{rowData.activity || ""}</span>}
              />
            )}
          />
          <Column
            field="ip"
            header={t("awc:/.logs.logs_fieldset.datatable.columns.remote")}
            sortable
            className="col-width-12 d-sm-none d-xl-table-cell"
            headerClassName="table-col-5 d-sm-none d-xl-table-cell"
            body={(rowData: any) => (
              <DatatableColumn
                title={t("awc:/.logs.logs_fieldset.datatable.columns.remote")}
                data={rowData?.ip || ""}
              />
            )}
          />
        </InstaTable>
      </>
    );
  };

  return getLogTable();
};

export default LogTable;
