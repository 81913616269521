/*
 * CSSEditorComponent.js (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2021
 *
 * @file CssEditorComponent.js
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState, ReactElement } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/css/css";
import "codemirror/theme/dracula.css";
import Button from "react-bootstrap/Button";
import Loader from "../Loader";
import ConfirmationPopup from "../ConfirmationPopup";
import { Card } from "primereact/card";

import './index.css'

interface ICSSTemplateProperties {
  title?: string;
  subtitle?: string;
  CSSTemplate: string;
  submitHandler: (updatedTemplate: any) => void;
  submitLabel: string;
  submitClasses?: string;
  isLoading?: boolean;
  codeMirrorOptions: any;
  confirmationMessage: string;
  confirmationYes: string;
  confirmationNo: string;
}

const CSSEditorComponent = ({
  isLoading = false,
  submitClasses,
  submitLabel,
  submitHandler,
  codeMirrorOptions,
  title = "",
  confirmationMessage,
  confirmationNo,
  confirmationYes,
  CSSTemplate,
}: ICSSTemplateProperties): ReactElement => {
  const [cssText, setCssText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [initialCssText, setInitialCssText] = useState<string>();

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={confirmationMessage}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={confirmationNo}
        acceptLabel={confirmationYes}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  /// show save popup
  const saveBtnClick = (event: any) => {
    if (initialCssText == cssText){
      return;
    }
    setShowConfirmation(true);
    setConfirmPopupTarget(event?.target);
  };

  /// save on Accept
  const onAccept = () => {
    setShowConfirmation(false);
    submitHandler(cssText);
    setInitialCssText(cssText);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    setCssText(CSSTemplate);
    setInitialCssText(CSSTemplate);
  }, [CSSTemplate]);

  return (
    <>
      <Row className="code-mirror-container">
        <Col className="h-100">
          <Row>
            <Col sm={4}>
              <Button
                className={`${submitClasses} custom-button-height`}
                onClick={saveBtnClick}
              >
                {submitLabel}
              </Button>
            </Col>
          </Row>
          <CodeMirror
            value={cssText}
            options={{
              mode: "css",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, css) => {
              setCssText(css);
            }}
            className="CodeMirror"
          />
        </Col>
      </Row>
      {getConfirmPopup()}
    </>
  );
};

export default CSSEditorComponent;
