/*
 * StaticLinks.ts (AbstractEcommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file StaticLinks.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/settings';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createOrUpdateStaticLink,
  deleteStaticLinks,
  getAllStaticLinks,
  getPaginatedStaticLinks
} from '../Services/StaticLinks';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const STATICLINK_FEATURE_KEY = 'staticLinks';

/**
 * IStaticLinksState
 */
export interface IStaticLinksState {
  staticLinks: IStaticLink[] | null /**< StaticLinks. */;
  isStaticLinksLoading: boolean /**< StaticLinksLoading. */;
  isStaticLinkCreated: boolean /**< StaticLinkCreated. */;
  isStaticLinkUpdated: boolean /**< StaticLinkUpdated. */;
  isStaticLinksDeleted: boolean /**< StaticLinksDeleted. */;
  sort: ISortEvent /**< Sort event. */;
  totalRecords: number | null /** Total records */;
  criteria: any /** Sort and pagination variables */;
  allStaticLinks: IStaticLink[] /**< All StaticLinks. */;
}

const defaultCriteria = {
  limit: defaultTableLimit,
  skip: 0,
  sort: {
    sortField: 'staticLinks.createdAt',
    sortOrder: -1
  }
};

/// Initial StaticLink state
const INITIAL_STATE: IStaticLinksState = {
  staticLinks: null,
  isStaticLinksLoading: false,
  isStaticLinkCreated: false,
  isStaticLinkUpdated: false,
  isStaticLinksDeleted: false,
  sort: {
    sortField: 'staticLinks.createdAt',
    sortOrder: -1 //Desc order, adding the default.
  },
  totalRecords: null,
  criteria: defaultCriteria,
  allStaticLinks: []
};

export const staticLinksSlice = createSlice({
  name: STATICLINK_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    createStaticLinkActionRequest(state: IStaticLinksState) {
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkCreated = false;
    },
    createStaticLinkActionSuccess(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkCreated = true;
    },
    createStaticLinkActionFailure(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
    },
    getStaticLinksActionRequest(state: IStaticLinksState) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
    },
    getStaticLinksActionSuccess(
      state: IStaticLinksState,
      action: IReducerAction<{
        result: IStaticLink[];
        sort: ISortEvent;
        totalRecords: number;
        payload: ITablePayload;
      }>
    ) {
      state.isStaticLinksLoading = false;
      state.staticLinks = action.payload.result;
      state.sort = action.payload.sort;
      state.totalRecords = action.payload.totalRecords;
      state.criteria.limit = action.payload.payload.limit;
      state.criteria.skip = action.payload.payload.skip;
      state.criteria.sort = action.payload.payload.sort;
    },
    getStaticLinksActionFailure(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
    },
    updateStaticLinkActionRequest(state: IStaticLinksState) {
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkUpdated = false;
    },
    updateStaticLinkActionSuccess(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkUpdated = true;
    },
    updateStaticLinkActionFailure(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
    },
    deleteStaticLinksActionRequest(state: IStaticLinksState) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksDeleted = false;
      state.isStaticLinksLoading = true;
    },
    deleteStaticLinksActionSuccess(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinksDeleted = true;
    },
    deleteStaticLinksActionFailure(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
    },
    getAllStaticLinksActionRequest(state: IStaticLinksState) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.allStaticLinks = [];
    },
    getAllStaticLinksActionSuccess(
      state: IStaticLinksState,
      action: IReducerAction<{
        result: IStaticLink[];
      }>
    ) {
      state.isStaticLinksLoading = false;
      state.allStaticLinks = action.payload.result;
    },
    getAllStaticLinksActionFailure(state: IStaticLinksState) {
      state.isStaticLinksLoading = false;
    }
  },
  extraReducers: {}
});

export const staticLinkReducer = staticLinksSlice.reducer;
export const staticLinkActions = staticLinksSlice.actions;

/**
 * Get paginated staticLink action.
 * @param payload
 */
export const getPaginatedStaticLinksAction = createAsyncThunk(
  'staticLinks/getPaginated',
  async (payload: ITablePayload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const getStaticLinksActionRequest: any =
      staticLinkActions.getStaticLinksActionRequest;
    const getStaticLinksActionSuccess: any =
      staticLinkActions.getStaticLinksActionSuccess;
    const getStaticLinksActionFailure: any =
      staticLinkActions.getStaticLinksActionFailure;

    try {
      dispatch(getStaticLinksActionRequest());
      if (!payload) {
        payload = {
          limit: defaultTableLimit,
          skip: 0,
          sort: { sortField: 'staticLinks.createdAt', sortOrder: -1 },
          searchTerm: ''
        };
      }
      const response: IAPIEntityResponse<any> = await asyncErrorHandler(
        getPaginatedStaticLinks(payload)
      );
      if (response.error) {
        handleError({
          message: response.error.message || 'Failed to fetch StaticLinks'
        });
        dispatch(getStaticLinksActionFailure());
        return;
      } else {
        const staticLinks = response.data;
        if (staticLinks) {
          const result = await asyncErrorHandler(Promise.all(staticLinks));
          dispatch(
            getStaticLinksActionSuccess({
              result,
              sort: payload.sort,
              totalRecords: response.totalRecords,
              payload
            })
          );
          return result;
        }
      }
    } catch (exception) {
      dispatch(getStaticLinksActionFailure());
      handleError({ message: exception.message });
      return;
    }
  }
);

/**
 * Create staticLink action.
 * @param payload
 */
export const createStaticLinkAction = createAsyncThunk(
  'staticLinks/create',
  async (payload: any, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const createStaticLinkActionRequest: any =
      staticLinkActions.createStaticLinkActionRequest;
    const createStaticLinkActionSuccess: any =
      staticLinkActions.createStaticLinkActionSuccess;
    const createStaticLinkActionFailure: any =
      staticLinkActions.createStaticLinkActionFailure;

    try {
      dispatch(createStaticLinkActionRequest());
      const staticLinkData: any = payload;
      const uploadIcon: any = staticLinkData.uploadStaticLinkIcon;
      const staticLink: any = staticLinkData.staticLink;
      if (uploadIcon) {
        staticLink['file'] = uploadIcon.file;
      }
      if (staticLink) {
        const result: IAPIEntityResponse<IStaticLink> = await asyncErrorHandler(
          createOrUpdateStaticLink(staticLink, null)
        );
        if (result.error) {
          dispatch(createStaticLinkActionFailure());
          handleError({
            message: result.error.message || 'Failed to create StaticLink'
          });
          return;
        }
        showSuccessToast(result.message ?? '');
        dispatch(createStaticLinkActionSuccess());
        dispatch(getPaginatedStaticLinksAction(payload.tablePayload));
        dispatch(getAllStaticLinksAction());
      }
    } catch (exception) {
      dispatch(createStaticLinkActionFailure());
      handleError({ message: exception.message });
      return;
    }
  }
);

/**
 * Update static links Action.
 */
export const updateStaticLinkAction = createAsyncThunk(
  'staticLinks/update',
  async (payload: any, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const updateStaticLinkActionRequest: any =
      staticLinkActions.updateStaticLinkActionRequest;
    const updateStaticLinkActionSuccess: any =
      staticLinkActions.updateStaticLinkActionSuccess;
    const updateStaticLinkActionFailure: any =
      staticLinkActions.updateStaticLinkActionFailure;

    try {
      dispatch(updateStaticLinkActionRequest());
      const staticLinkData: any = payload.data;
      const staticLinkID: string = payload.staticLinkID;
      const uploadIcon: any = staticLinkData.uploadStaticLinkIcon;
      const staticLink: any = staticLinkData.staticLink;

      if (uploadIcon) {
        staticLink['file'] = uploadIcon.file;
      }

      if (staticLinkData.deleteStaticLinkIcon) {
        staticLink['logoUploadStatus'] = 'delete';
      }

      if (staticLink) {
        const result: IAPIEntityResponse<IStaticLink> = await asyncErrorHandler(
          createOrUpdateStaticLink(staticLink, staticLinkID)
        );
        if (result.error) {
          dispatch(updateStaticLinkActionFailure());
          handleError({
            message: result.error.message || 'Failed to update StaticLink'
          });
          return;
        }
        showSuccessToast(result.message ?? '');
        dispatch(updateStaticLinkActionSuccess());
        dispatch(getPaginatedStaticLinksAction(payload.data.tablePayload));
        dispatch(getAllStaticLinksAction());
      }
    } catch (exception) {
      handleError({ message: exception.message });
      dispatch(updateStaticLinkActionFailure());
      return;
    }
  }
);

/**
 * Delete static link(s) action
 */
export const deleteStaticLinkAction = createAsyncThunk(
  'staticLinks/delete',
  async (payload: any, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const deleteStaticLinksActionRequest: any =
      staticLinkActions.deleteStaticLinksActionRequest;
    const deleteStaticLinksActionSuccess: any =
      staticLinkActions.deleteStaticLinksActionSuccess;
    const deleteStaticLinksActionFailure: any =
      staticLinkActions.deleteStaticLinksActionFailure;

    try {
      dispatch(deleteStaticLinksActionRequest());
      const result = await asyncErrorHandler(deleteStaticLinks(payload.uuids));
      if (result.error) {
        handleError({
          message: result.error.message || 'Failed to delete StaticLink'
        });
        dispatch(deleteStaticLinksActionFailure());
        return;
      } else {
        showSuccessToast(result.message ?? '');
        dispatch(deleteStaticLinksActionSuccess());
        dispatch(getPaginatedStaticLinksAction(payload.tablePayload));
        dispatch(getAllStaticLinksAction());
      }
    } catch (exception) {
      handleError({ message: exception.message });
      dispatch(deleteStaticLinksActionFailure());
      return;
    }
  }
);

/**
 * Get all staticLinks action.
 * @param payload
 */
export const getAllStaticLinksAction = createAsyncThunk(
  'staticLinks/getAll',
  async (payload: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const getAllStaticLinksActionRequest: any =
      staticLinkActions.getAllStaticLinksActionRequest;
    const getAllStaticLinksActionSuccess: any =
      staticLinkActions.getAllStaticLinksActionSuccess;
    const getAllStaticLinksActionFailure: any =
      staticLinkActions.getAllStaticLinksActionFailure;

    try {
      dispatch(getAllStaticLinksActionRequest());

      const response: IAPIEntityResponse<any> = await asyncErrorHandler(
        getAllStaticLinks()
      );
      if (response.error) {
        handleError({
          message: response.error.message || 'Failed to fetch all StaticLinks'
        });
        dispatch(getAllStaticLinksActionFailure());
        return;
      } else {
        const staticLinks = response.data;
        if (staticLinks) {
          const result = await asyncErrorHandler(Promise.all(staticLinks));
          dispatch(
            getAllStaticLinksActionSuccess({
              result
            })
          );
          return result;
        }
      }
    } catch (exception) {
      dispatch(getAllStaticLinksActionFailure());
      handleError({ message: exception.message });
      return;
    }
  }
);
