/**
* FieldSkeleton.tsx (abstractuser) *

* Copyright © 2023 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues, 2023 
* @file FieldSkeleton.tsx
* @author Rafael Rodrigues
* @copyright 2023 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../SkeletonWrapper'

import './FieldSkeleton.css'

/**
 * Enum to use in the FieldSkeleton component
 */
export enum SkeletonHeight {
  Default = 'default',
  FileType = 'fileType'
}

/**
 * Default values for the height of FieldSkeleton component
 */
const skeletonHeight = {
  default: 39.72, /** Represent 39.72px of height */
  fileType: 126 /** Represent 126px of height */
}

/**
 * Control when to display the element field
 */
export const controlInputClassName = (isLoading: boolean, isFileField = false): string =>
    isLoading ? 'd-none' : (isFileField ? 'd-block' :'d-flex');

/**
 * @interface DataTableSkeleton
 */
interface IFieldSkeletonProperties {
  skeletonHeightCustomValue?: number /** Skeleton height value */
  skeletonHeightType?: SkeletonHeight /** Skeleton height style */
  className?: string /** Custom class name */
}

/**
 * Render a skeleton for field component.
 * @param properties IFieldSkeletonProperties interface
 * @returns JSX.Element
 */
const FieldSkeleton = (properties: IFieldSkeletonProperties): JSX.Element => {
  const skeletonHeightType: number = properties.skeletonHeightType ? skeletonHeight[properties.skeletonHeightType] : skeletonHeight.default

  return <SkeletonWrapper className={`field-skeleton-container ${properties.className}`} height={properties.skeletonHeightCustomValue ?? skeletonHeightType} />
}

export default FieldSkeleton