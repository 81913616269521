/*
 * TransactionDetails.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file TransactionDetails.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import ProductTable from './ProductTable';
import { CustomerDetailsTable } from '../../Common/CustomerDetailsTable';
import { CustomerBillingDetailsTable } from '../../Common/CustomerBillingDetailsTable';
import { InstaImage } from '../../SubComponents/InstaImage';
import { BASE_API_URL } from '../../../Config';
import TemplateConverter from '../../Admin/Template/TemplateConverter';
import Button from 'react-bootstrap/Button';
import { Plan } from 'braintree';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { getProductSubscription } from '../../../Services/Product';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import './TransactionDetails.css';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { IOption } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import {
  getProductOptionsDescription,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import {
  getTranslatedNextStepButtonsText,
  getTranslatedNextStepText,
  getTranslatedTemplateSLATermsText,
  getTranslatedTemplateWebTermsText
} from '../../../Utils/DynamicTranslate';
import { INextStepButton } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';

// We should define a key to apply the correct order while displaying the CTA buttons.
// On the Application or Product form level, admins users can define the CTA products.
// CTA buttons with the CSS class name 'primary-row-{number}' will be displayed in the first row of the CTA CSS container.
// Examples:
// 1. primary-row-1
// 2. primary-row-2
// 3. primary-row-3
const CTA_PRIMARY_CLASSNAME: string = 'primary-row-'

/**
 * Interface for TransactionDetails properties.
 */
interface ITransactionDetailsProperties {
  transaction: any;
  customer: any;
  setTransactionSummaryLoading: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Loading state. */;
}

const TransactionDetails = ({
  transaction,
  customer,
  setTransactionSummaryLoading
}: ITransactionDetailsProperties) => {
  const hashedCustomerEmail = CryptoJS.SHA256(
    transaction.customer[0].email
  ).toString(CryptoJS.enc.Hex);
  const settings = useSelector((state) => state.shopSettings.list);
  const { t } = useTranslation();
  const { priceCalculation, products } = transaction;
  const templates = useSelector((state) => state.templates);
  const [template, setTemplate] = useState();
  const purchasedProduct = { product: products[0].product };
  const storeTranslation: IStoreTranslation[] =
    settings.translationData; /**< Store translation data */
  const [termsText, setTermsText] = useState<string>(); /**< Terms text */
  const productState = useSelector((state) => state.products);

  /**
   * Get formatted SLA terms text
   * @param templateSLATermsText
   */
  const getFormattedSLATermsText = (templateSLATermsText: string): string => {
    // Replace the link with an application's SLA Terms link in the SLA Terms text
    const slaTermsText: string = templateSLATermsText?.replaceAll(
      '{{URL}}',
      productState?.product?.application?.slaTermsLink
    );

    return slaTermsText;
  };

  const customPlaceholders = {
    ...transaction,
    ...purchasedProduct,
    ...settings,
    termsText: products[0].product?.sla
      ? getFormattedSLATermsText(termsText)
      : termsText
  };
  const [productSubscription, setProductSubscription] = useState<
    Plan
  >(); /**< Product subscription */
  const [
    isLoadingProductSubscription,
    setLoadingProductSubscription
  ] = useState<boolean>(true); /**< Loading state */
  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode() || LanguageSettingsMode.english
  ); /**< Language settings mode */
  const [formattedProductOptions, setFormattedProductOptions] = useState<
    IOption[]
  >([]); /**< Add description to product options array */
  const [productTranslation, setProductTranslation] = useState<
    IProductTranslation[]
  >([]); /**< Product translation data */
  const [
    translatedNextStepButtonsText,
    setTranslatedNextStepButtonsText
  ] = useState<string[]>([]); /**< Get translated nextStepButtonsText */
  const englishTranslationIndex: number = productState?.product?.translationData?.findIndex(
    (data: IProductTranslation) =>
      data.language === LanguageSettingsMode.english
  ) as number; /**< English translation data index */

  useEffect(() => {
    setLanguageSettingsMode(LocalStorage.getLanguageSettingsMode());
    setTermsText(
      products[0].product?.sla
        ? getTranslatedTemplateSLATermsText(storeTranslation)
        : getTranslatedTemplateWebTermsText(storeTranslation)
    );
  }, [LocalStorage.getLanguageSettingsMode()]);

  useEffect(() => {
    const getTemplate = () => {
      if (templates && templates.list.length > 0 && !templates.listIsFetching) {
        const transactionTemplates = templates.list.find(
          (obj) => obj.name === 'Transaction Summary Template'
        );
        setTemplate(transactionTemplates);
      }
    };
    getTemplate();
  }, [templates]);

  /**
   * Get product subscription data
   */
  const getProductSubscriptionData = async (): Promise<void> => {
    if (products[0].product && products[0].product.subscriptionPlanID) {
      // If there is no subscription data in product(if product from transaction object), fetch it from backend using product subscriptionPlanID.
      if (!products[0].product?.subscriptionPlan) {
        const subscription: IAPIEntityResponse<Plan> = await asyncErrorHandler(
          getProductSubscription(products[0].product.subscriptionPlanID)
        ); // Get product subscription

        if (subscription && subscription.subscriptionPlan) {
          setProductSubscription(subscription.subscriptionPlan);
        }
      } else {
        setProductSubscription(products[0].product?.subscriptionPlan);
      }
    }
    setLoadingProductSubscription(false);
  };

  useEffect(() => {
    getProductSubscriptionData();
    setProductTranslation(products[0]?.product?.translationData);
  }, [products]);

  /**
   * Get product options
   */
  const getProductOptions = async () => {
    const allOptions: IOption[] = await asyncErrorHandler(
      getProductOptionsDescription(
        products[0],
        settings,
        languageSettingsMode,
        productTranslation
      )
    );
    setFormattedProductOptions(allOptions);
  };

  /**
   * Interface for the RenderCTAContainerContent React component.
   */
  interface IRenderCTAContainerContent {
    translationIndex: number /* Property to manage the array object index. */, 
    nextStepButton: INextStepButton /* CTA buttons details. */
  }

  /**
   * React component to render the CTA container content.
   * This component displays the CTA button translation and other details related to the CTA button.
   * @returns JSX.Element
   */
  const RenderCTAContainerContent = ({ translationIndex, nextStepButton }: IRenderCTAContainerContent): JSX.Element => (
    <a
      key={translationIndex}
      href={nextStepButton.buttonURL}
      id="calltoaction-button"
      className={`custom-ancor-container btn ${nextStepButton.buttonCSS} mr-4 d-flex align-items-center justify-content-center custom-calltoaction-container-buttons`}
      target="_blank"
      rel="noreferrer"
    >
      {translatedNextStepButtonsText[translationIndex]} {''}
      <i
        className={`${nextStepButton.buttonIcon} ml-2`}
      ></i>
    </a>
  )
  
  /**
   * React component to render the CTA container.
   * This component handle what and how to display the CTA buttons.
   * While displaying the CTA container, we have the primary and the secondary row of CTA buttons.
   * @returns JSX.Element
   */
  const RenderCTAContainer = (): JSX.Element => {
    /**
     * Interface for the ICTAButtons interface.
    */
    interface ICustomCTAButtons extends INextStepButton {
      translationIndex: number /* Property to manage the array object index. */
    }

    /**
     * Interface for the below reduce logic.
    */
    interface ICTAButtons {
      ctaPrimaryButtons: ICustomCTAButtons[] /* Buttons to display in the primary CTA row. */;
      ctaSecondaryButtons: ICustomCTAButtons[] /* Buttons to display in the secondary CTA row. */
    }
    
    // We should define what are the buttons for the primary and secondary rows.
    const { ctaPrimaryButtons, ctaSecondaryButtons }: ICTAButtons = (productState?.product?.nextStepTextButtons ?? []).reduce((ctaButtonAccumulator: ICTAButtons, ctaButtonDetails: INextStepButton, index: number) => {
      if (ctaButtonDetails.buttonCSS?.includes(CTA_PRIMARY_CLASSNAME)) {
        ctaButtonAccumulator.ctaPrimaryButtons.push({ ...ctaButtonDetails, translationIndex: index });
      } else {
        ctaButtonAccumulator.ctaSecondaryButtons.push({ ...ctaButtonDetails, translationIndex: index });
      }
      return ctaButtonAccumulator;
    }, { ctaPrimaryButtons: [], ctaSecondaryButtons: [] });

    // Custom logic to order the buttons from the 'ctaPrimaryButtons' array.
    // Example of output:
    // 1. primary-row-1
    // 2. primary-row-2
    // 3. primary-row-3
    const sortedCTASecondaryButtons: ICustomCTAButtons[] = ctaPrimaryButtons.sort((firstButton: INextStepButton, secondButton: INextStepButton) => {
      const ctaRegex: RegExp = new RegExp(`${CTA_PRIMARY_CLASSNAME}(\\d+)`);

      const matchA: RegExpMatchArray | null = firstButton.buttonCSS!.match(ctaRegex);
      const matchB: RegExpMatchArray | null = secondButton.buttonCSS!.match(ctaRegex);

      const numA: number = matchA ? parseInt(matchA[1], 10) : Infinity;
      const numB: number = matchB ? parseInt(matchB[1], 10) : Infinity;

      return numA - numB;
    });

    return (
      <>
        <div className="col-md-12 d-flex flex-wrap">
          {sortedCTASecondaryButtons.map(
            (nextStepButton: ICustomCTAButtons) => {
              return (
                <RenderCTAContainerContent translationIndex={nextStepButton.translationIndex} nextStepButton={nextStepButton} />
              );
            }
          )}
        </div>

        <div className="col-md-12 d-flex flex-wrap calltoaction-second-row-container">
          {ctaSecondaryButtons.map(
            (nextStepButton: ICustomCTAButtons) => {
              return (
                <RenderCTAContainerContent translationIndex={nextStepButton.translationIndex} nextStepButton={nextStepButton} />
              );
            }
          )}
        </div>
      </>
    )
  }

  useEffect(() => {
    getProductOptions();
    setTranslatedNextStepButtonsText(
      getTranslatedNextStepButtonsText(productTranslation)
    );
  }, [languageSettingsMode, productTranslation]);

  return (
    <>
      {isLoadingProductSubscription || productState.productIsFetching ? (
        <></>
      ) : (
        <>
          <Row>
            <Col sm={12} className="template-html">
              <TemplateConverter
                template={template}
                customPlaceholderValues={customPlaceholders}
                setLoading={setTransactionSummaryLoading}
              />
            </Col>
            {productState?.product?.nextStepTextButtons?.length !== 0 ||
              !isStringEmptyOrNullOrUndefined(
                productState?.product?.translationData?.[englishTranslationIndex]
                  ?.nextStepText
              ) ? (
              <Col sm={12} className="">
                <div className="container calltoaction-container">
                  <div className="row">
                    <div className="col-md-12 pb-0">
                      <p
                        className="custom-next-step-text-container"
                        dangerouslySetInnerHTML={{
                          __html: getTranslatedNextStepText(
                            productState?.product?.translationData
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* React component to render the CTA container */}
                    <RenderCTAContainer />
                  </div>
                </div>
              </Col>
            ) : (
              <></>
            )}
            <Col sm={12}>
              <Fieldset
                legend={`${t('transactionSuccessPage.orderData.header')} ${transaction._id
                  }`}
              >
                <ProductTable
                  products={[
                    {
                      options: formattedProductOptions,
                      product: products[0].product,
                      quantity: products[0].quantity,
                      _id: products[0]._id,
                      productRef: products[0].productRef
                    }
                  ]}
                  priceCalculation={priceCalculation}
                  productSubscription={productSubscription}
                  languageSettingsMode={languageSettingsMode}
                  productTranslation={productTranslation}
                  transactedSubscription={transaction?.subscription}
                  braintreeTransactionID={transaction?.braintreeTransactionID}
                />
                <Row>
                  <Col sm={12}>
                    <h4>
                      Status:{' '}
                      {transaction &&
                        transaction.payment &&
                        transaction.payment.success
                        ? t('transactionSuccessPage.paymentConfirmed')
                        : t('transactionSuccessPage.paymentNotProcessed')}
                    </h4>
                  </Col>
                </Row>
              </Fieldset>
            </Col>
          </Row>
          <Row>
            <Col className="custom-margin-between-section">
              <Fieldset
                legend={t('transactionSuccessPage.customerData.header')}
                style={{ minHeight: customer.address1 ? '330px' : '' }}
              >
                <CustomerDetailsTable customer={customer} />
              </Fieldset>
            </Col>
            {transaction && transaction.payment && transaction.payment.success && (
              <Col sm={12} md={6}>
                <Fieldset
                  legend={t('transactionSuccessPage.paymentData.header')}
                  style={{ minHeight: '330px' }}
                >
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="billing-payment-description-col text-left border-top-0">
                          <b>
                            {t(
                              'transactionSuccessPage.paymentData.paymentMethod'
                            )}
                            :&nbsp;
                          </b>
                        </td>
                        <td className="text-right border-top-0">
                          {t(`paymentMethod.${transaction.paymentMethod}`)}
                          <br />
                          <br />
                          {transaction.paymentMethod === 'credit_card' && (
                            <>
                              <InstaImage
                                src={
                                  transaction.payment.transaction.creditCard
                                    .imageUrl
                                }
                              />
                              &nbsp;&nbsp;&nbsp;
                              {
                                transaction.payment.transaction.creditCard
                                  .maskedNumber
                              }
                            </>
                          )}
                          {transaction.paymentMethod === 'paypal_account' && (
                            <>
                              <InstaImage
                                src={
                                  transaction.payment.transaction.paypal
                                    .imageUrl
                                }
                              />{' '}
                              {
                                transaction.payment.transaction.paypal
                                  .payerFirstName
                              }{' '}
                              {
                                transaction.payment.transaction.paypal
                                  .payerLastName
                              }
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="billing-payment-description-col text-left">
                          <b>{t('fields.transaction.status')}:&nbsp;</b>
                        </td>
                        <td className="text-right">
                          {` ${transaction.status[0].toUpperCase()}${transaction.status
                            .replace(/_/g, ' ')
                            .slice(1)}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Fieldset>
              </Col>
            )}
          </Row>
          {customer.billingAddress1 ? (
            <Row>
              <Col>
                <Fieldset
                  legend={t('transactionSuccessPage.billingData.header')}
                >
                  <CustomerBillingDetailsTable customer={customer} />
                </Fieldset>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className="d-print-none pt-4 d-md-flex">
            <Col className="transaction-success-actions">
              <Button
                className="p-button p-button-primary btn-block mb-3"
                variant="primary"
                onClick={() =>
                  window.open(
                    `${BASE_API_URL}/pdf/${transaction._id}/${hashedCustomerEmail}`
                  )
                }
              >
                {t('transactionSuccessPage.printInvoice')}
              </Button>
              <Button
                className="p-button p-button-primary btn-block"
                variant="primary"
                onClick={() => window.print()}
              >
                {t('transactionSuccessPage.printPage')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TransactionDetails;
