/**
* TextEditorDialog.tsx (InstaLOD GmbH) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2023 
* @file TextEditorDialog.tsx
* @author Alaguvelammal Alagusubbiah
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { ReactElement } from 'react';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import FormWrapper from '../FormControl/FormWrapper';
import { Editor } from 'primereact/editor';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import './TextEditorDialog.css';

/**
 * @interface ITextEditorDialog
 */
interface ITextEditorDialog {
  isShowDialog: boolean /**< To Show text editor dialog. */;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>> /**< set Show text editor dialog. */;
  formikValue: string /**< Formik value. */;
  settingValue: string /**< Setting value. */;
  textHTML: string /**< Text HTML . */;
  setTextHTML: React.Dispatch<React.SetStateAction<string>> /**< Set text HTML. */;
  editHeaderText: string /**< Edit header text. */;
  addHeaderText: string /**< Add header text. */;
  formikField: string /**< Formik field. */;
  isLoading: boolean /**< Loading state */;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void /**< Set field value */;
  setApplicationSettingsUpdatesExist: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Set application updates exist */;
}

// Get dialog for editing text with HTML element.
const TextEditorDialog = (properties: ITextEditorDialog): ReactElement => {
  // Header for Editor
  const renderHeader = (): ReactElement => {
    return (
      <span className="ql-formats">
        <button className="ql-link" aria-label="Link"></button>
      </span>
    );
  };
  const header = renderHeader();

  return (
    <DialogWrapper
      isDialogVisible={properties.isShowDialog}
      headerTitle={properties.formikValue ? properties.editHeaderText : properties.addHeaderText}
      onHide={() => {
        properties.setShowDialog(false);
      }}>
      <FormWrapper
        controlButtonLabel={false}
        isLoading={properties.isLoading}
        handleSubmitButton={() => {
          properties.setShowDialog(false);
          if (properties.settingValue !== properties.textHTML) {
            properties.setFieldValue(properties.formikField, properties.textHTML);
            properties.setApplicationSettingsUpdatesExist(true);
          } else {
            properties.setFieldValue(properties.formikField, properties.textHTML);
          }
        }}>
        <Row>
          <Form.Group as={Col} sm="12" md="12">
            <Editor
              value={properties.textHTML}
              onTextChange={(event) => {
                properties.setTextHTML(event.htmlValue as string);
              }}
              headerTemplate={header}
            />
          </Form.Group>
        </Row>
      </FormWrapper>
    </DialogWrapper>
  );
};

export default TextEditorDialog;
