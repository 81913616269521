/**
* Vat.ts (AbstractECommerce) *

* Copyright © 2020 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Pascal Mayr, 2020 
* @file Vat.ts
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const createOrUpdate = async (country: any) => {
  if (country._id) {
    return httpClient.put(`/vat/${country._id}`, country);
  }
  return httpClient.post(`/vat`, country);
};

/**
 * Service to remove Country(s)
 * @param payload countryIDs
 * @returns Promise
 */
export const remove = (payload: string[]) => {
  return httpClient.post(`/vat/delete`, { countryIDs: payload });
};

/**
 * API service to get all countries.
 * @function
 */
export const getAllCountries = (payload: ITablePayload) => {
  const url: string = paginationRequest(`/vat/all`, {
    skip: payload.skip,
    limit: payload.limit,
    searchTerm: payload.searchTerm
  });
  return httpClient.post(url, { sort: payload.sort });
};
