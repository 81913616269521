/*
 * SubscriptionsTable.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file SubscriptionsTable.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useSelector } from 'react-redux';
import { SubscriptionStatus } from '@abstract/abstractwebcommon-shared/enum/ecommerce/braintreeSubscriptionStatus';
import { SubscriptionSharedFucntions } from '../../../Utils/Subscriptions/sharedFunctions';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import SubscriptionsForm from '../../Admin/Subscriptions/SubscriptionForm';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import RetrySubscriptionForm from 'src/Components/Admin/Subscriptions/RetrySubscriptionForm';

/**
 * Interface for SubscriptionsTable properties.
 */
interface ISubscriptionsTableProperties {
  subscriptions: ISubscription[];
  isLoading: boolean;
  tablePayload: ITablePayload /**  Table payload. */;
  handlePageUpdate: (
    event: IPageEvent
  ) => void /**< Handle Table page update. */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  retrySubscription: (
    subscriptionID: string,
    transactionPrice?: string
  ) => void /**< Handle retry subscription functionality. The Production environment won't pass the transaction price.*/;
  handleFilterUpdate: (
    event: string
  ) => void /**< Handle filter update event. */;
  refundSubscription: (
    subscriptionID: string
  ) => void /**< Handle refund subscription functionality. */;
  cancelSubscription: (
    subscriptionID: string
  ) => void /**< Handle cancel subscription functionality. */;
  handleSubscriptionPaymentMethodUpdate: (
    subscriptionID: string,
    paymentMethodNonce: string
  ) => void /**< Handle subscription payment method update functionality. */;
  resetPaymentMethod: () => void /**< To reset payment method. */;
  getSubscriptionPaymentMethod: (
    subscriptionID: string
  ) => void /**< Get subscription payment method. */;
  totalRecords: number;
}

const SubscriptionsTable = (properties: ISubscriptionsTableProperties) => {
  const translation = useTranslation().t;
  const store = useSelector((state) => state.shopSettings);

  const refundWindow: number = store.list.refundWindow;
  const sharedSubscriptionStore = useSelector(
    (state) => state.sharedSubscriptions
  );
  const sort: ISortEvent = properties.tablePayload?.sort; /**< Sort */
  const [
    isRefundConfirmationPopupVisible,
    setRefundConfirmationPopupVisible
  ] = useState<boolean>(false);
  const [
    isCancelConfirmationPopupVisible,
    setCancelConfirmationPopupVisible
  ] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [subscriptionID, setSubscriptionID] = useState<string | null>(
    null
  ); /**< Get subscription to perform actions, like refund and cancel subscription. */
  const [isDialogVisible, setDialogVisible] = useState<boolean>(
    false
  ); /**< Is dialog visible */
  const [
    editSubscription,
    setEditSubscription
  ] = useState<ISubscription | null>(); /**< Edit subscription */
  const [isOnlyLocalSubscriptions, setOnlyLocalSubscriptions] = useState<
    boolean | null
  >(null); /**< Used to hide or display refund column in the table component */

  /* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338*/
  // const [
  //   isSubscriptionsWithNoPastDueReason,
  //   setSubscriptionsWithNoPastDueReason
  // ] = useState<boolean | null>(
  //   null
  // ); /**< Used to hide or display the expand row option */

  const [
    isRetrySubscriptionFormVisible,
    setRetrySubscriptionFormVisible
  ] = useState<boolean>(
    false
  ); /**< Used to hide or display the confirmation popup for the retry button. */

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar
        onSearchTermChanged={(data) => properties.handleFilterUpdate(data)}
      />
    </div>
  );

  // Accept dialog function for Refund option.
  const onAcceptRefundConfirmation = () => {
    setRefundConfirmationPopupVisible(false);
    properties.refundSubscription(subscriptionID as string);
  };

  // Reject dialog function for Refund option.
  const onRejectRefundConfirmation = () => {
    setRefundConfirmationPopupVisible(false);
  };

  // Accept dialog function for Cancel option.
  const onAcceptCancelConfirmation = () => {
    setCancelConfirmationPopupVisible(false);
    properties.cancelSubscription(subscriptionID as string);
  };

  // Reject dialog function for Cancel option.
  const onRejectCancelConfirmation = () => {
    setCancelConfirmationPopupVisible(false);
  };

  /// Show edit dialog
  const onEditButtonClicked = async (rowData: ISubscription) => {
    await asyncErrorHandler(
      properties.getSubscriptionPaymentMethod(rowData._id as string)
    );
    setEditSubscription(rowData);
    setDialogVisible(true);
  };

  // Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  /* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338*/
  // const allowExpansion = (rowData: ISubscription) => {
  //   return isStringEmptyOrNullOrUndefined(rowData.pastDueReason ?? '');
  // };

  // const GetRowExpansionTemplate = (values: { rowData: ISubscription }) => (
  //   <tr>
  //     <th>
  //       {translation('adminSubscriptions.table.expansion_row.pastDueReason')}
  //     </th>
  //     <td>{values.rowData['pastDueReason']}</td>
  //   </tr>
  // );

  // const renderExpansionRows = (rowData: ISubscription) => (
  //   <>
  //     <ExpansionRow>
  //       <GetRowExpansionTemplate rowData={rowData} />
  //     </ExpansionRow>

  //     <ExpansionRow isSmallBreakpoint={true}>
  //       <GetRowExpansionTemplate rowData={rowData} />
  //     </ExpansionRow>
  //   </>
  // );

  // Verify if a subscription is in the past due status.
  const isSubscriptionPastDue = (rowData: ISubscription): boolean =>
    rowData.status === SubscriptionStatus.PastDue;

  // Defines if the page is requesting a subscription endpoint.
  const isRequestingSubscriptionEndpoints = (): boolean =>
    sharedSubscriptionStore.isRetryingSubscription ||
    sharedSubscriptionStore.isRequestingEndpoint;

  useEffect(() => {
    if (properties.subscriptions && properties?.subscriptions.length > 0) {
      setOnlyLocalSubscriptions(
        properties.subscriptions.every(
          (subscription: ISubscription) => subscription.price === '0.00'
        )
      );

      /* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338*/
      // setSubscriptionsWithNoPastDueReason(
      //       properties.subscriptions.some((subscription: ISubscription) =>
      //         isStringEmptyOrNullOrUndefined(subscription.pastDueReason ?? '')
      //       )
      //     );
    }
  }, [properties.subscriptions]);

  // useEffect to handle the retry subscription form properly.
  useEffect(() => {
    if (sharedSubscriptionStore.isRetrySubscriptionSuccessful) {
      setRetrySubscriptionFormVisible(false);
    }
  }, [sharedSubscriptionStore.isRetrySubscriptionSuccessful]);

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={properties.subscriptions}
        isLoading={!properties.subscriptions}
        dataKey="_id"
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        first={properties.tablePayload.skip}
        rows={properties.tablePayload.limit}
        onPage={properties.handlePageUpdate}
        onSort={properties.handleSortUpdate}
        header={header}
        totalRecords={properties.totalRecords}
        expandedRows={expandedRows}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        /* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338*/
        // rowExpansionTemplate={renderExpansionRows}
      >
        {/* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338*/}
        {/* {isSubscriptionsWithNoPastDueReason ? (
          <Column
            expander={allowExpansion}
            className={`p-0 col-width-45 ${
              !isSubscriptionsWithNoPastDueReason ? 'invisible' : ''
            }`}
            headerClassName={`p-0 col-width-45 ${
              !isSubscriptionsWithNoPastDueReason ? 'invisible' : ''
            }`}
          />
        ) : null} */}
        <Column selectionMode="multiple" className="d-none" />
        <Column
          expander
          className="invisible d-sm-none p-0 col-width-45"
          headerClassName="invisible d-sm-none p-0 col-width-45"
        />
        <Column
          field="transaction.products.productName"
          header={translation('clientSubscriptions.table.product')}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('clientSubscriptions.table.product')}
              data={rowData?.product?.name}
            />
          )}
        />
        <Column
          field="priceAsNumber" // Note: Sorting in priceAsNumber(type is double) field
          header={translation('clientSubscriptions.table.price')}
          headerClassName="d-table-cell d-sm-none d-xl-table-cell"
          className="d-table-cell d-sm-none d-xl-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('clientSubscriptions.table.price')}
              data={currencyFormatter(Number(rowData.price))}
            />
          )}
        />
        <Column
          field="subscriptionStartDate"
          header={translation('clientSubscriptions.table.subscriptionStart')}
          headerClassName="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
          className="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('clientSubscriptions.table.subscriptionStart')}
              data={formatDate(rowData.subscriptionStartDate!)}
            />
          )}
        />
        <Column
          field="subscriptionEndDate"
          header={translation('clientSubscriptions.table.subscriptionEnd')}
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('clientSubscriptions.table.subscriptionEnd')}
              data={
                rowData.subscriptionEndDate
                  ? formatDate(rowData.subscriptionEndDate)
                  : 'Never expires'
              }
            />
          )}
        />
        <Column
          field="status"
          header={translation('clientSubscriptions.table.status')}
          headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-md-table-cell"
          className="custom-header-min-width-allowed d-table-cell d-sm-none d-md-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('clientSubscriptions.table.status')}
              data={rowData.status}
            />
          )}
        />
        <Column
          field="refundSubscription"
          className={`absolute-position-responsive-screen col-width-45 p-2 ${
            isOnlyLocalSubscriptions
              ? 'd-none'
              : 'd-table-cell d-sm-none d-xl-table-cell'
          }`}
          headerClassName={`${
            isOnlyLocalSubscriptions
              ? 'd-none'
              : 'd-table-cell d-sm-none d-xl-table-cell'
          }`}
          body={(rowData: ISubscription) => {
            return (
              <>
                {rowData.price === '0.00' ? (
                  <></>
                ) : (
                  <div className="d-flex justify-content-center version-button-container">
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        rowData.status === SubscriptionStatus.Active &&
                        !SubscriptionSharedFucntions.isWithinSubscriptionRefundWindow(
                          rowData,
                          refundWindow
                        ) ? (
                          <Tooltip id="refundSubscriptionTooltipID">
                            {translation(
                              'sharedSubscription.table.refund_button_tooltip',
                              { salesEmail: store.list.salesEmail }
                            )}
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <Button
                        className={`${
                          SubscriptionSharedFucntions.isRefundOptionAvailable(
                            rowData,
                            refundWindow
                          ) && !sharedSubscriptionStore.isRequestingEndpoint
                            ? ''
                            : 'custom-disabled-button'
                        } custom-action-column-action-position d-none d-sm-block`}
                        style={{ minWidth: '80px' }}
                        onClick={(event) => {
                          if (
                            SubscriptionSharedFucntions.isRefundOptionAvailable(
                              rowData,
                              refundWindow
                            ) &&
                            !sharedSubscriptionStore.isRequestingEndpoint
                          ) {
                            setRefundConfirmationPopupVisible(true);
                            setConfirmPopupTarget(event.target);
                            setSubscriptionID(rowData._id as string);
                          }
                        }}
                        disabled={
                          !SubscriptionSharedFucntions.isRefundOptionAvailable(
                            rowData,
                            refundWindow
                          ) || sharedSubscriptionStore.isRequestingEndpoint
                        }
                      >
                        {translation('sharedSubscription.table.refund_button')}
                      </Button>
                    </OverlayTrigger>

                    <Button
                      className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-60"
                      variant="outline"
                      onClick={(event) => {
                        if (
                          SubscriptionSharedFucntions.isRefundOptionAvailable(
                            rowData,
                            refundWindow
                          ) &&
                          !sharedSubscriptionStore.isRequestingEndpoint
                        ) {
                          setRefundConfirmationPopupVisible(true);
                          setConfirmPopupTarget(event.target);
                          setSubscriptionID(rowData._id as string);
                        }
                      }}
                      disabled={
                        !SubscriptionSharedFucntions.isRefundOptionAvailable(
                          rowData,
                          refundWindow
                        ) || sharedSubscriptionStore.isRequestingEndpoint
                      }
                    >
                      <i
                        className={`bi piggy-bank editIcon fa-lg ${
                          SubscriptionSharedFucntions.isRefundOptionAvailable(
                            rowData,
                            refundWindow
                          )
                            ? ''
                            : 'custom-disabled-icon'
                        }`}
                      />
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        />
        <Column
          field="cancelSubscription"
          className="absolute-position-responsive-screen col-width-45 p-2"
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  className={`${
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-button'
                      : ''
                  } custom-action-column-action-position d-none d-sm-block`}
                  style={{ minWidth: '80px' }}
                  onClick={(event) => {
                    if (
                      (rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus ===
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setCancelConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  {translation('sharedSubscription.table.cancel_button')}
                </Button>

                <Button
                  className={`custom-action-column-action-position d-block d-sm-none ${
                    isOnlyLocalSubscriptions ? '' : 'position-absolute-mr-30'
                  }`}
                  variant="outline"
                  onClick={(event) => {
                    if (
                      (rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus !==
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setCancelConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  <i
                    className={`bi bi-x-square editIcon fa-lg ${
                      rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                      rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Refunded
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="retrySubscription"
          className={`absolute-position-responsive-screen col-width-45 p-2 d-table-cell d-sm-none d-md-table-cell`}
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  className={`${
                    isSubscriptionPastDue(rowData)
                      ? ''
                      : 'custom-disabled-button'
                  } custom-action-column-action-position d-none d-sm-block ${
                    isRequestingSubscriptionEndpoints()
                      ? 'custom-disabled-button'
                      : ''
                  }`}
                  style={{ minWidth: '80px' }}
                  onClick={(event) => {
                    if (
                      (isSubscriptionPastDue(rowData) &&
                        !sharedSubscriptionStore.isRetryingSubscription) ||
                      sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setRetrySubscriptionFormVisible(true);
                      setConfirmPopupTarget(event.target);
                      setEditSubscription(rowData);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    !isSubscriptionPastDue(rowData) ||
                    isRequestingSubscriptionEndpoints()
                  }
                >
                  {translation('adminSubscriptions.table.retryButton')}
                </Button>

                <Button
                  className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-60"
                  variant="outline"
                  onClick={(event) => {
                    if (
                      (isSubscriptionPastDue(rowData) &&
                        !sharedSubscriptionStore.isRetryingSubscription) ||
                      sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setRetrySubscriptionFormVisible(true);
                      setConfirmPopupTarget(event.target);
                      setEditSubscription(rowData);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    !isSubscriptionPastDue(rowData) ||
                    isRequestingSubscriptionEndpoints()
                  }
                >
                  <i
                    className={`bi bi-arrow-clockwise editIcon fa-lg ${
                      isSubscriptionPastDue(rowData) ||
                      !isRequestingSubscriptionEndpoints()
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="edit"
          header={translation('sharedSubscription.table.edit_payment')}
          headerClassName={`p-2 ${isOnlyLocalSubscriptions ? 'd-none' : ''}`}
          className={`custom-action-column-action-position col-width-45 p-0 ${
            isOnlyLocalSubscriptions ? 'd-none' : ''
          }`}
          body={(rowData: ISubscription) => {
            return (
              <Button
                className={`${
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Canceled ||
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Refunded ||
                  sharedSubscriptionStore.isRequestingEndpoint ||
                  rowData.price == '0.00'
                    ? 'custom-disabled-icon'
                    : ''
                } custom-action-column-action-position`}
                onClick={() => {
                  onEditButtonClicked(rowData);
                }}
                variant="outline"
                disabled={
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Canceled ||
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Refunded ||
                  sharedSubscriptionStore.isRequestingEndpoint ||
                  rowData.price == '0.00'
                }
              >
                <i
                  className={`bi bi-pencil-square editIcon fa-lg ${
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    rowData.price == '0.00' ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-icon'
                      : ''
                  }`}
                />
              </Button>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  /// Triggered on SubscriptionsForm dialog hide event.
  const onHide = () => {
    setEditSubscription(null);
    setDialogVisible(false);
    properties.resetPaymentMethod();
  };
  /// Returns the subscriptions dialog form.
  const getSubscriptionsDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isDialogVisible}
        onHide={() => onHide()}
        headerTitle={translation(
          'sharedSubscription.subscription_dialog_header'
        )}
      >
        <SubscriptionsForm
          editSubscription={editSubscription}
          onHide={onHide}
          handleUpdate={properties.handleSubscriptionPaymentMethodUpdate}
        />
      </DialogWrapper>
    );
  };

  // Used only in the Staging and Local environments.
  const onAcceptRetrySubscriptionFormConfirmation = (): void => {
    properties.retrySubscription(subscriptionID as string);
  };

  return (
    <>
      {getDataTable()}
      {getSubscriptionsDialog()}

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isRefundConfirmationPopupVisible}
        title={translation('/confirm_messages.refund_subscription')}
        onAccept={onAcceptRefundConfirmation}
        onReject={onRejectRefundConfirmation}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('/confirm_messages.no')}
        acceptLabel={translation('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isCancelConfirmationPopupVisible}
        title={translation('/confirm_messages.cancel_subscription')}
        onAccept={onAcceptCancelConfirmation}
        onReject={onRejectCancelConfirmation}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('/confirm_messages.no')}
        acceptLabel={translation('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />

      <RetrySubscriptionForm
        isLoading={sharedSubscriptionStore.isRetryingSubscription}
        handleSubmit={onAcceptRetrySubscriptionFormConfirmation}
        subscription={editSubscription as ISubscription}
        isRetrySubscriptionFormVisible={isRetrySubscriptionFormVisible}
        onHide={() => setRetrySubscriptionFormVisible(false)}
      />
    </>
  );
};

export default SubscriptionsTable;
