/**
 * Customers.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Customers.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const read = (customerId: string) => {
  return httpClient.get(`/customers/${customerId}`);
};

export const getAll = async (skip: number, limit: number) => {
  const url = paginationRequest(`/customers`, { skip, limit });
  const result = await asyncErrorHandler(httpClient.get(url));
  return result.customers;
};

export const update = async (customer: any) => {
  return httpClient.put(`/customers/${customer._id}`, customer);
};

export const remove = async (customerId: string) => {
  return httpClient.delete(`/customers/${customerId}`);
};

// Get customer transactions group by username.
export const getAllTransactionsGroupByUsername = (
  skip: number,
  limit: number,
  searchTerm: string = '',
  sort: any,
  isAdmin: boolean,
  filter: Record<string, any>
) => {
  const url = paginationRequest(`/transactions/groupByUser`, {
    skip,
    limit,
    searchTerm,
    filter
  });

  return httpClient.post(url, { sort, isAdmin });
};

// Fill transaction's userdata from user backend.
export const fillTransactionsUserData = () => {
  return httpClient.get(`/transactions/fillUserData`);
};
