/*
 * CheckoutSummary.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file CheckoutSummary.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { Fieldset } from 'primereact/fieldset';
import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'use-analytics';
import { Checkbox } from 'primereact/checkbox';
import { useDebounce } from 'use-debounce';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import eventConstants from '../../../Utils/EventConstants';
import ProductTable from '../TransactionSuccess/ProductTable';
import CheckoutSummaryCondition from './CheckoutSummaryCondition';
import Button from 'react-bootstrap/Button';
import { Message } from 'primereact/message';
import './CheckoutSummary.css';
import {
  ICheckoutOption,
  IOption,
  IProduct,
  isUniqueProduct
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import {
  getTranslatedCheckoutCondition,
  getTranslatedCheckoutOption,
  getTranslatedSLATermsText,
  getTranslatedWebTermsText
} from '../../../Utils/DynamicTranslate';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { IStoreTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/StoreTranslation';
import { ICheckoutConditionOption } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/countries';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { Form } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { Formik, FormikErrors } from 'formik';
import { string, object } from 'yup';

/**
 * Interface for CheckoutSummary properties.
 */
interface ICheckoutSummaryProperties {
  handleSubmit: (conditions: any, customFields: Record<string, string>) => void;
  disabled?: any;
  loading: any;
  product: {
    maxQuantity?: number;
    _id?: any;
    [key: string]: any /**< Dynamic properties */;
  };
  addProduct: () => void;
  removeProduct: () => void;
  setProductCount: (value: any) => void;
  productCount: number;
  priceCalculation: {
    optionsDetails?: any;
    [key: string]: any /**< Dynamic properties */;
  };
  priceCalculationIsFetching: any;
  countries: any;
  billingInfo: {
    country: any;
    [key: string]: any /**< Dynamic properties */;
  };
  serialNumber: any;
  isShopPage?: boolean /**< If checkout summary is on the shop page(public route) */;
  isProductPurchasedAlready?: boolean /**< Product purchased already or not */;
  isDisabled: boolean /**< To disable the product options or not */;
  storeTranslation: IStoreTranslation[] /**< Store translation data */;
  selectedOptions: IOption[] /**< Slected options */;
  setCustomFieldsError: React.Dispatch<
    React.SetStateAction<
      FormikErrors<{
        [k: string]: string;
      }>
    >
  > /**< Custom fields error */;
  loggedOutMessageBanner: JSX.Element /**< Logged out message banner */;
}

/**
 * @interface IField
 */
interface IField {
  name: string /**< Field name */;
  initialValue: string /**< Initial value */;
  type: any /**< Field type */;
}

const CheckoutSummary = ({
  handleSubmit,
  disabled,
  loading,
  product,
  addProduct,
  removeProduct,
  setProductCount,
  productCount,
  priceCalculation,
  priceCalculationIsFetching,
  countries,
  billingInfo,
  serialNumber,
  isShopPage,
  isProductPurchasedAlready,
  isDisabled,
  storeTranslation,
  selectedOptions,
  setCustomFieldsError,
  loggedOutMessageBanner
}: ICheckoutSummaryProperties) => {
  const { t } = useTranslation();
  const toast: React.MutableRefObject<null> = useRef(null);
  const analytics = useAnalytics();
  const { track } = eventConstants.event;
  const logAnalytics = (event, productId) => {
    analytics.track(event.name, {
      description: event.description,
      product: productId
    });
  };

  const country =
    countries &&
    countries.find((country) => country.name === billingInfo.country);

  const [conditions, setConditions] = useState<ICheckoutConditionOption[]>([]);
  const [productCheckoutOptions, setProductCheckoutOptions] = useState<
    ICheckoutOption[]
  >([]); /**< Product custom checkout options */

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [customTermsAccepted, setCustomTermsAccepted] = useState(true);
  const [
    isProductCheckoutOptionsAccepted,
    setProductCheckoutOptionsAccepted
  ] = useState(true); /**< To check product checkout options accepted or not */
  const [loader] = useDebounce(priceCalculationIsFetching, 1000);
  const [disabledPurchase] = useDebounce(
    !(termsAccepted && customTermsAccepted && isProductCheckoutOptionsAccepted),
    100
  );
  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode() || LanguageSettingsMode.english
  ); /**< Language settings mode */
  const [customFields, setCustomFields] = useState<Record<string, string>>(
    {}
  ); /**< Custom fields */
  const isCustomFieldsRowVisible: boolean =
    product && product.customFieldKeys && product.customFieldKeys.length
      ? true
      : false; /**< Custom fields row visibility */
  const [formikErrors, setFormikErrors] = useState<
    FormikErrors<{
      [k: string]: string;
    }>
  >({}); /**< Formik Errors */
  const [formikCustomFields, setFormikCustomFields] = useState<IField[]>(
    []
  ); /**< Formik custom fields */
  const [isShowFormikErrors, setShowFormikErrors] = useState<boolean>(
    false
  ); /**< To show formik errors */
  const formRef = useRef();

  // Replace the link with an application's SLA Terms link in the SLA Terms text
  const translatedSLATermsText: string = getTranslatedSLATermsText(
    storeTranslation
  );
  const slaTermsText: string = translatedSLATermsText?.replaceAll(
    '{{URL}}',
    product?.application?.slaTermsLink
  );

  useEffect(() => {
    if (country && country.options.length > 0) {
      const translatedCheckOutConditions: string[] = getTranslatedCheckoutCondition(
        country
      ); /**< To get translated checkout conditions */
      let checkoutConditions: ICheckoutConditionOption[] = [];
      if (conditions?.length != 0) {
        checkoutConditions = conditions.map(
          (option: ICheckoutConditionOption, index: number) => ({
            ...option,
            description: translatedCheckOutConditions[index]
          })
        );
      } else {
        checkoutConditions = country.options.map(
          (option: ICheckoutConditionOption, index: number) => ({
            ...option,
            description: translatedCheckOutConditions[index]
          })
        );
      }

      setConditions(checkoutConditions);
      if (!country.options.some((condition) => condition.value === false)) {
        setCustomTermsAccepted(true);
      } else {
        setCustomTermsAccepted(false);
      }
    } else {
      // Assign an empty array to the country conditions in case the selected/changed country doesn't have defined options.
      setConditions([]);
      setCustomTermsAccepted(true); // Since the default value is "true", we should define "true".
      setProductCheckoutOptionsAccepted(true); // Since the default value is "true", we should define "true".
    }

    setLanguageSettingsMode(LocalStorage.getLanguageSettingsMode());
  }, [country, LocalStorage.getLanguageSettingsMode()]);

  useEffect(() => {
    if (product) {
      const translatedCheckOutOptions: string[] = getTranslatedCheckoutOption(
        product as IProduct
      ); /**< To get translated checkout options */
      let checkoutOptions: ICheckoutOption[] = [];
      if (productCheckoutOptions?.length != 0) {
        checkoutOptions = productCheckoutOptions.map(
          (option: ICheckoutOption, index: number) => ({
            ...option,
            description: translatedCheckOutOptions[index]
          })
        );
      } else {
        checkoutOptions = translatedCheckOutOptions.map((option: string) => ({
          description: option,
          value: false
        }));
      }

      setProductCheckoutOptions(checkoutOptions);
      if (
        !checkoutOptions.some(
          (option: ICheckoutOption) => option.value === false
        )
      ) {
        setProductCheckoutOptionsAccepted(true);
      } else {
        setProductCheckoutOptionsAccepted(false);
      }
    }
  }, [product, LocalStorage.getLanguageSettingsMode()]);

  // Format formik custom fields
  useEffect(() => {
    if (product && product.customFieldKeys && product.customFieldKeys.length) {
      const fields: IField[] = [];
      product.customFieldKeys.map((customFieldKey: string) => {
        fields.push({
          name: customFieldKey,
          initialValue: '',
          type: string().required()
        });
      });
      setFormikCustomFields(fields);
    }
  }, [product]);

  useEffect(() => {
    setCustomFieldsError(formikErrors);
  }, [formikErrors]);

  const handleConditionsUpdate = (conditions) => {
    if (!conditions.some((condition) => condition.value === false)) {
      setCustomTermsAccepted(true);
    } else {
      setCustomTermsAccepted(false);
    }
    if (
      !productCheckoutOptions.some(
        (option: ICheckoutOption) => option.value === false
      )
    ) {
      setProductCheckoutOptionsAccepted(true);
    } else {
      setProductCheckoutOptionsAccepted(false);
    }
  };

  const initialValues = Object.fromEntries(
    formikCustomFields?.map((field: IField) => [field.name, field.initialValue])
  ); /**< Initial values of custom fields */
  const SchemaObject = Object.fromEntries(
    formikCustomFields?.map((field: IField) => [field.name, field.type])
  ); /**< Schema object of custom fields */
  const customFieldsSchema = object().shape(SchemaObject);

  return (
    <Fieldset
      legend={t('checkoutPage.summary.header', { number: `${serialNumber}.` })}
      className="noselect custom-fieldset"
    >
      {loader && <Loader style={{ minHeight: '300px' }} />}
      {priceCalculation && !loader && (
        <>
          {loggedOutMessageBanner}
          <ProductTable
            products={[
              {
                options: selectedOptions,
                product,
                quantity: productCount
              }
            ]}
            priceCalculation={priceCalculation}
            editableQuantity={product.maxQuantity > 1}
            productCount={productCount}
            removeProduct={removeProduct}
            addProduct={addProduct}
            setProductCount={setProductCount}
            isShopPage={isShopPage}
            productSubscription={
              product.subscriptionPlanID && product.subscriptionPlan
            }
            languageSettingsMode={languageSettingsMode}
            isDisabled={isDisabled}
          />
        </>
      )}
      <Row>
        {isCustomFieldsRowVisible ? (
          <Col>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => setCustomFields(values)}
              validationSchema={customFieldsSchema}
              innerRef={formRef}
            >
              {({ values, handleChange, handleBlur, touched, errors }) => (
                <>
                  {formikCustomFields?.map(({ name }, index) => {
                    return (
                      <Row key={index}>
                        <Form.Group as={Col} sm="12" md="6">
                          <Form.Label htmlFor={name} className="required">
                            {name}
                          </Form.Label>
                          <InputText
                            id={name}
                            name={name}
                            value={values?.[name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              (touched?.[name] && errors?.[name]) ||
                              (errors?.[name] && isShowFormikErrors)
                                ? 'p-invalid'
                                : ''
                            }
                            disabled={isDisabled}
                          />
                          {setFormikErrors(errors)}
                          {(touched?.[name] && errors?.[name]) ||
                          (errors?.[name] && isShowFormikErrors) ? (
                            <small id="name-invalid" className="p-invalid">
                              {t('validation.required', {
                                field: name
                              })}
                            </small>
                          ) : null}
                        </Form.Group>
                      </Row>
                    );
                  })}
                </>
              )}
            </Formik>
          </Col>
        ) : (
          <></>
        )}
        {isCustomFieldsRowVisible ? <></> : <Col></Col>}
        <Col md={12}>
          <Col></Col>
          <Col>
            <div className="summary-checkbox-container d-flex float-left">
              <div>
                <Checkbox
                  inputId="agreement-text"
                  className="mr-4"
                  checked={termsAccepted}
                  onChange={(e) => {
                    setTermsAccepted(e.checked);
                  }}
                  disabled={isDisabled}
                />
              </div>
              <div>
                {product && product.sla ? (
                  <label
                    htmlFor="agreement-text"
                    id="agreement-text"
                    dangerouslySetInnerHTML={{
                      __html: slaTermsText
                    }}
                  />
                ) : (
                  <label
                    htmlFor="agreement-text"
                    id="agreement-text"
                    dangerouslySetInnerHTML={{
                      __html: getTranslatedWebTermsText(storeTranslation)
                    }}
                  />
                )}
              </div>
            </div>
            {[...productCheckoutOptions, ...conditions].map((option, index) => {
              return (
                <CheckoutSummaryCondition
                  keyIndex={`${index}.condition`}
                  description={option.description}
                  value={option.value}
                  onChange={(value) => {
                    const helperConditions = [
                      ...productCheckoutOptions,
                      ...conditions
                    ];
                    const index = helperConditions.findIndex(
                      (condition) =>
                        condition.description === option.description &&
                        condition.value !== value
                    );
                    helperConditions[index].value = value;
                    handleConditionsUpdate(helperConditions);
                  }}
                  isDisabled={isDisabled}
                />
              );
            })}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col sm={12} md={5} className="pb-0">
          {loading ? <Loader /> : <div />}

          {!loading && !isProductPurchasedAlready ? (
            <Button
              className={`float-right justify-content-center ${
                isShopPage ? 'p-button p-button-primary' : ''
              }`}
              variant="primary"
              disabled={disabledPurchase || loading || isDisabled}
              onClick={() => {
                setShowFormikErrors(true);
                logAnalytics(track.CHECKOUT_AND_PAYMENT, product._id);
                if (formRef.current) {
                  formRef.current.handleSubmit();
                }
                handleSubmit(
                  [...productCheckoutOptions, ...conditions],
                  formRef?.current?.values
                );
              }}
            >
              <i className="fa fa-shopping-cart btn-icon"></i>
              {isUniqueProduct(product)
                ? t('checkoutPage.summary.getFreeProduct')
                : t('checkoutPage.summary.purchase')}
            </Button>
          ) : (
            <div />
          )}
        </Col>

        {isProductPurchasedAlready ? (
          <Col sm={12} md={5}>
            <Message
              severity="error"
              text={t('checkoutPage.product_already_purchasedError')}
              className="float-right justify-content-center customMessageToast"
            />
          </Col>
        ) : (
          <div />
        )}
      </Row>
    </Fieldset>
  );
};

export default CheckoutSummary;
