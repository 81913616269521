/*
 * InstaInputTextArea.tex (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file InstaInputTextArea.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { InputTextarea } from 'primereact/inputtextarea';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IInstaFormControlProperties } from "./form-control.interface";
import PopupInformation from "./PopupInformation";

/**
 * Interface defining the React properties for the InstaTextArea component.
 */
interface IInstaInputTextAreaProperties extends IInstaFormControlProperties {
    type?: string; /**< Type of InputTextarea. */
    labelAfter?: string; /**< Label after input. */
    fieldName: string; /**< Name of calender field. */
    isLoading: boolean; /**< True/False based on loading state. */
    onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement> | Event) => void; /**< Keypress event of InputTextarea. */
    sizing?: {[key: string]: number } /**< Form grid layout size. */;
    rows?: number; /**< Number of rows */
    cols?: number; /**< Number of columns */
    labelClassName?: string; /**< The label's CSS class name to be used. */
    formClassName?: string; /**< The form's CSS class name to be used. */
    isShowInformationPopup?: boolean; /**< To show information popup */
    popupText?: string; /**< Popup text */  
    id?: string; /**< Tag identifier */
}

/**
 *  The InstaTextArea component displays a multi line input text UI allowing the user 
 *  the input text.
 */
export const InstaTextArea = ({
  label,
  labelAfter = '',
  fieldName,
  isLoading,
  onChange,
  onBlur,
  onKeyPress,
  touched,
  errors,
  value,
  rows = 5,
  cols = 30,
  type = 'text',
  sizing = { sm: 12, md: 6 },
  labelClassName = '',
  formClassName = '',
  className,
  isShowInformationPopup = false,
  popupText,
  id,
  ...properties
}: IInstaInputTextAreaProperties ): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Col} {...sizing} className={formClassName}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      {isShowInformationPopup ?
       <PopupInformation 
          id={id} 
          popupText={popupText}
        />
         : <></> 
      }
      <InputTextarea
        name={fieldName}
        rows={rows}
        cols={cols}
        disabled={isLoading}
        value={value}
        onKeyPress={onKeyPress}
        onChange={onChange}
        onBlur={onBlur}
        autoResize
        className={classnames(className, {
          'p-invalid':  touched && errors
        })}
        {...properties}
      />
      {labelAfter && <Form.Label>{labelAfter}</Form.Label>}
      {touched && errors ? 
        (
          <small id="email-invalid" className="p-invalid error-text">
            {t(errors)}
          </small>
        ) : 
        null
      }
    </Form.Group>
  );
};
