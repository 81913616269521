/*
 * Products.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Products.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  copyProduct,
  createOrUpdate,
  createOrUpdateGlobalOptions,
  getAllProducts,
  getAllProductsGroupByType,
  getProducts,
  read,
  remove
} from '../Services/Product';
import { fetchSettings } from './ShopSettings';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';

const GET_PRODUCTS_REQUEST = 'products/fetchList/request';
const GET_PRODUCTS_SUCCESS = 'products/fetchList/success';
const GET_PRODUCTS_FAILURE = 'products/fetchList/failure';

const GET_ONE_PRODUCT_REQUEST = 'products/fetchOne/request';
const GET_ONE_PRODUCT_SUCCESS = 'products/fetchOne/success';
const GET_ONE_PRODUCT_FAILURE = 'products/fetchOne/failure';

const GET_ONE_PRODUCT_OPTIONS_REQUEST = 'products/options/fetchOne/request';
const GET_ONE_PRODUCT_OPTIONS_SUCCESS = 'products/options/fetchOne/success';
const GET_ONE_PRODUCT_OPTIONS_FAILURE = 'products/options/fetchOne/failure';

const ADD_PRODUCT_REQUEST = 'products/add/request';
const ADD_PRODUCT_SUCCESS = 'products/add/success';
const ADD_PRODUCT_FAILURE = 'products/add/failure';

const ADD_PRODUCT_OPTIONS_REQUEST = 'products/options/add/request';
const ADD_PRODUCT_OPTIONS_SUCCESS = 'products/options/add/success';
const ADD_PRODUCT_OPTIONS_FAILURE = 'products/options/add/failure';

const DELETE_PRODUCT_REQUEST = 'products/delete/request';
const DELETE_PRODUCT_SUCCESS = 'products/delete/success';
const DELETE_PRODUCT_FAILURE = 'products/delete/failure';

const DELETE_PRODUCT_IMAGE_REQUEST = 'products/image/delete/request';
const DELETE_PRODUCT_IMAGE_SUCCESS = 'products/image/delete/success';
const DELETE_PRODUCT_IMAGE_FAILURE = 'products/image/delete/failure';

const TOGGLE_PRODUCT_DIALOG = 'products/dialog/toggle';
const RESET_STATE = 'product/reset'; /**< Reset Product state. */
const ALL_IMAGES_LOADED_SUCCESS = 'products/image/all/load/success';

const GET_ALL_PRODUCTS_REQUEST = 'all/products/fetchList/request';
const GET_ALL_PRODUCTS_SUCCESS = 'all/products/fetchList/success';
const GET_ALL_PRODUCTS_FAILURE = 'all/products/fetchList/failure';

const GET_ALL_PRODUCTS_GROUP_BY_TYPE_REQUEST =
  'all/products/groupByType/fetchList/request';
const GET_ALL_PRODUCTS_GROUP_BY_TYPE_SUCCESS =
  'all/products/groupByType/fetchList/success';
const GET_ALL_PRODUCTS_GROUP_BY_TYPE_FAILURE =
  'all/products/groupByType/fetchList/failure';

const INITIAL_STATE = {
  list: null,
  listIsFetching: true,
  product: null,
  productIsFetching: false,
  productIsChanging: false,
  productDialogOpened: false,
  editedProduct: null,
  globalProductOptionsIsChanging: false,
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  },
  totalRecords: 0,
  isLoadingImages: true /** Control when all images are loading and when are loaded */,
  applicationPageURL: null /**< Application page URL */,
  allProducts: null /**< Get all products */,
  allProductsGroupByType: null /** Get all products group by type */
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return { ...state, listIsFetching: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        list: action.payload.products,
        totalRecords: action.payload.totalRecords
      };
    case GET_PRODUCTS_FAILURE:
      return { ...state, listIsFetching: false };
    case GET_ONE_PRODUCT_REQUEST:
      return { ...state, productIsFetching: true };
    case GET_ONE_PRODUCT_SUCCESS:
      return {
        ...state,
        productIsFetching: false,
        product: action.payload.product,
        applicationPageURL:
          action.payload.product?.application?.applicationPageURL
      };
    case GET_ONE_PRODUCT_FAILURE:
      return { ...state, productIsFetching: false };
    case GET_ONE_PRODUCT_OPTIONS_REQUEST:
      return { ...state };
    case GET_ONE_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        product: { ...state.product, ...{ options: action.payload } }
      };
    case GET_ONE_PRODUCT_OPTIONS_FAILURE:
      return { ...state };
    case ADD_PRODUCT_REQUEST:
      return { ...state, productIsChanging: true };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, productIsChanging: false, productDialogOpened: false };
    case ADD_PRODUCT_FAILURE:
      return { ...state, productIsChanging: false };
    case ADD_PRODUCT_OPTIONS_REQUEST:
      return { ...state, globalProductOptionsIsChanging: true };
    case ADD_PRODUCT_OPTIONS_SUCCESS:
      return { ...state, globalProductOptionsIsChanging: false };
    case ADD_PRODUCT_OPTIONS_FAILURE:
      return { ...state, globalProductOptionsIsChanging: false };
    case DELETE_PRODUCT_REQUEST:
      return { ...state, productIsChanging: true };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, productIsChanging: false, productDialogOpened: false };
    case DELETE_PRODUCT_FAILURE:
      return { ...state, productIsChanging: false };
    case TOGGLE_PRODUCT_DIALOG:
      return {
        ...state,
        productDialogOpened: action.payload.opened,
        editedProduct: action.payload.product
      };
    case DELETE_PRODUCT_IMAGE_REQUEST:
      return { ...state, productIsChanging: true };
    case DELETE_PRODUCT_IMAGE_SUCCESS:
      return { ...state, productIsChanging: false };
    case DELETE_PRODUCT_IMAGE_FAILURE:
      return { ...state, productIsChanging: false };
    case ALL_IMAGES_LOADED_SUCCESS:
      return { ...state, isLoadingImages: false };
    case RESET_STATE:
      return { ...state, applicationPageURL: null };
    case GET_ALL_PRODUCTS_REQUEST:
      return { ...state };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.payload.products
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return { ...state };
    case GET_ALL_PRODUCTS_GROUP_BY_TYPE_REQUEST:
      return { ...state };
    case GET_ALL_PRODUCTS_GROUP_BY_TYPE_SUCCESS:
      return {
        ...state,
        allProductsGroupByType: action.payload.products
      };
    case GET_ALL_PRODUCTS_GROUP_BY_TYPE_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

const getProductsRequest = () => ({
  type: GET_PRODUCTS_REQUEST
});

const getProductsSuccess = (products: any, totalRecords: number) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: { products, totalRecords }
});

const getProductsFailure = () => ({
  type: GET_PRODUCTS_FAILURE
});

const getOneProductRequest = () => ({
  type: GET_ONE_PRODUCT_REQUEST
});

const getOneProductSuccess = (payload) => ({
  type: GET_ONE_PRODUCT_SUCCESS,
  payload: payload
});

const getOneProductFailure = () => ({
  type: GET_ONE_PRODUCT_FAILURE
});

const getOneProductOptionsRequest = () => ({
  type: GET_ONE_PRODUCT_OPTIONS_REQUEST
});

const getOneProductOptionsSuccess = (options) => ({
  type: GET_ONE_PRODUCT_OPTIONS_SUCCESS,
  payload: options
});

const getOneProductOptionsFailure = () => ({
  type: GET_ONE_PRODUCT_OPTIONS_FAILURE
});

const addProductsRequest = (product) => ({
  type: ADD_PRODUCT_REQUEST,
  payload: product
});

const addProductsSuccess = () => ({
  type: ADD_PRODUCT_SUCCESS
});

const addProductsFailure = () => ({
  type: ADD_PRODUCT_FAILURE
});

const addProductOptionsRequest = (options) => ({
  type: ADD_PRODUCT_OPTIONS_REQUEST,
  payload: options
});

const addProductOptionsSuccess = () => ({
  type: ADD_PRODUCT_OPTIONS_SUCCESS
});

const addProductOptionsFailure = () => ({
  type: ADD_PRODUCT_OPTIONS_FAILURE
});

const deleteProductsRequest = () => ({
  type: DELETE_PRODUCT_REQUEST
});

const deleteProductsSuccess = () => ({
  type: DELETE_PRODUCT_SUCCESS
});

const deleteProductsFailure = () => ({
  type: DELETE_PRODUCT_FAILURE
});

const areAllImagesLoadedSuccess = () => ({
  type: ALL_IMAGES_LOADED_SUCCESS
});

const getAllProductsRequest = () => ({
  type: GET_ALL_PRODUCTS_REQUEST
});

const getAllProductsSuccess = (products: IProduct[]) => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: { products }
});

const getAllProductsFailure = () => ({
  type: GET_ALL_PRODUCTS_FAILURE
});

const getAllProductsGroupByTypeRequest = () => ({
  type: GET_ALL_PRODUCTS_GROUP_BY_TYPE_REQUEST
});

const getAllProductsGroupByTypeSuccess = (products: IProduct[]) => ({
  type: GET_ALL_PRODUCTS_GROUP_BY_TYPE_SUCCESS,
  payload: { products }
});

const getAllProductsGroupByTypeFailure = () => ({
  type: GET_ALL_PRODUCTS_GROUP_BY_TYPE_FAILURE
});

/// Reset state
export const resetProductState = () => ({
  type: RESET_STATE
});

export const toggleProductDialog = (opened, product) => {
  return {
    type: TOGGLE_PRODUCT_DIALOG,
    payload: {
      opened,
      product
    }
  };
};

export const areAllImagesLoadedSuccessfuly = () => async (dispatch) => {
  try {
    dispatch(areAllImagesLoadedSuccess());
  } catch (e) {
    handleError({ message: e.message });
  }
};

export const fetchOneProduct = (producSKUOrID: string) => async (dispatch) => {
  try {
    dispatch(getOneProductRequest());
    const result = await asyncErrorHandler(read(producSKUOrID));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getOneProductFailure());
    } else {
      dispatch(getOneProductSuccess(result));
    }
  } catch (e) {
    handleError({ message: e.message });
  }
};

export const fetchOneProductOptions = (producSKUOrID: string) => async (
  dispatch
) => {
  try {
    dispatch(getOneProductOptionsRequest());
    const result = await asyncErrorHandler(read(producSKUOrID));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getOneProductOptionsFailure());
    } else {
      dispatch(getOneProductOptionsSuccess(result.product.options));
    }
  } catch (e) {
    handleError({ message: e.message });
  }
};

export const fetchProducts = (
  skip?: number,
  limit?: number,
  searchTerm?: string,
  sort?: any,
  isViewAllProducts?: boolean,
  filter?: Record<string, any>
) => async (dispatch) => {
  try {
    dispatch(getProductsRequest());
    const result = await asyncErrorHandler(
      getProducts(
        skip,
        limit,
        searchTerm,
        sort,
        isViewAllProducts as boolean,
        filter ?? {}
      )
    );
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getProductsFailure());
    } else {
      dispatch(getProductsSuccess(result.products, result.totalRecords));
    }
  } catch (e) {
    handleError({ message: e.message });
  }
};

export const addOrEditProduct = (product, isCustom = false) => async (
  dispatch
) => {
  try {
    const productToAdd = {
      ...product,
      quantity: 0
    };
    dispatch(addProductsRequest());
    const result = await asyncErrorHandler(createOrUpdate(productToAdd));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(addProductsFailure());
    } else {
      dispatch(addProductsSuccess());
      if (!isCustom) {
        showSuccessToast(result.message);
      }
    }
    return result;
  } catch (e) {
    dispatch(addProductsFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const addOrEditGlobalProductOptions = (options) => async (dispatch) => {
  try {
    dispatch(addProductOptionsRequest());
    const result = await asyncErrorHandler(
      createOrUpdateGlobalOptions(options)
    );
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(addProductOptionsFailure());
    } else {
      dispatch(addProductOptionsSuccess());
      dispatch(fetchSettings());
      showSuccessToast(result.message ?? '');
    }
    return result;
  } catch (e) {
    dispatch(addProductOptionsFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

export const deleteProduct = (productIDs: string[], isCustom = false) => async (
  dispatch
) => {
  try {
    dispatch(deleteProductsRequest());
    const result = await asyncErrorHandler(remove(productIDs));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(deleteProductsFailure());
    } else {
      dispatch(deleteProductsSuccess());
      if (!isCustom) {
        showSuccessToast(result.message ?? '');
      }
    }
  } catch (e) {
    dispatch(addProductsFailure());
    if (e.error.message) {
      handleError({ message: e.error.message });
    } else {
      handleError({ message: e.message });
    }
  }
};

/**
 * Copy product(s) Action.
 * @param payload
 */
export const copyProductAction = (payload: string[]) => async (dispatch) => {
  try {
    dispatch(addProductsRequest());
    const result = await asyncErrorHandler(copyProduct(payload));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(addProductsFailure());
    } else {
      dispatch(addProductsSuccess());
      showSuccessToast(result.message);
    }
  } catch (exception) {
    dispatch(addProductsFailure());
    if (exception.error.message) {
      handleError({ message: exception.error.message });
    } else {
      handleError({ message: exception.message });
    }
  }
};

/**
 * Fetch all products.
 */
export const fetchAllProducts = () => async (dispatch) => {
  try {
    dispatch(getAllProductsRequest());
    const result = await asyncErrorHandler(getAllProducts());
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getAllProductsFailure());
    } else {
      dispatch(getAllProductsSuccess(result.products));
    }
  } catch (e) {
    handleError({ message: e.message });
  }
};

/**
 * Fetch all products group by type.
 */
export const fetchAllProductsGroupByType = () => async (dispatch) => {
  try {
    dispatch(getAllProductsGroupByTypeRequest());
    const result = await asyncErrorHandler(getAllProductsGroupByType());
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getAllProductsGroupByTypeFailure());
    } else {
      dispatch(getAllProductsGroupByTypeSuccess(result.products));
    }
  } catch (e) {
    handleError({ message: e.message });
  }
};
