/*
 * VatIdAPI.ts (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file VatIdAPI.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { checkIsVatIdValid } from '../Services/VatIdAPIService';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const CHECK_IS_VAT_ID_VALID_REQUEST = 'external/check/is/vat/id/valid/request';
const CHECK_IS_VAT_ID_VALID_SUCCESS = 'external/check/is/vat/id/valid/success';
const CHECK_IS_VAT_ID_VALID_FAILURE = 'external/check/is/vat/id/valid/failure';

const INITIAL_STATE = {
  isVatIdValid: null,
  isCheckingValidity: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_IS_VAT_ID_VALID_REQUEST:
      return { ...state, isCheckingValidity: true, isVatIdValid: null };
    case CHECK_IS_VAT_ID_VALID_SUCCESS:
      return {
        ...state,
        isVatIdValid: action.payload,
        isCheckingValidity: false
      };
    case CHECK_IS_VAT_ID_VALID_FAILURE:
      return {
        ...state,
        isCheckingValidity: false,
        isVatIdValid: false
      };
    default:
      return state;
  }
};

const isVatIDValidRequest = () => ({
  type: CHECK_IS_VAT_ID_VALID_REQUEST
});

const isVatIDValidSuccess = (payload: boolean) => ({
  type: CHECK_IS_VAT_ID_VALID_SUCCESS,
  payload
});

const isVatIDValidFailure = () => ({
  type: CHECK_IS_VAT_ID_VALID_FAILURE
});

export const isVatIDValidAction = (vatID: string) => async (dispatch) => {
  try {
    dispatch(isVatIDValidRequest());
    const result = await asyncErrorHandler(checkIsVatIdValid(vatID));

    if (result.error) {
      handleError({ message: result.error.message as string });
      dispatch(isVatIDValidFailure());
    } else {
      dispatch(isVatIDValidSuccess(result.data?.isVatIdValid as boolean));
    }
  } catch (error) {
    dispatch(isVatIDValidFailure());
    handleError({
      message: 'VAT ID validation was unsuccessful. Please try again later.'
    });
  }
};
