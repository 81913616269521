/**
* UserCache.ts (AbstractECommerce) *

* Copyright © 2024 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2024 
* @file UserCache.ts
* @author Rafael Rodrigues
* @copyright 2024 InstaLOD GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * Endpoint to synchronize the data from the User cache collection using User project as reference.
 */
export const synchronizeUserCacheData = async (): Promise<IAPIEntityResponse<{
  message: string;
}>> => httpClient.get(`/userCache/synchronize/user/data`);
