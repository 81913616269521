/**
 * ClientAdminSubscriptions.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file ClientAdminSubscriptions.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from '../Logs';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

const httpClient = new HttpClient(BASE_API_URL, createLog);

const baseURLPath: string = '/subscriptions/client/';

/**
 * Get all subscriptions by user UUID.
 * @param skip Skip value for pagination.
 * @param limit Limit value for pagination.
 * @param searchTerm Search term to find specific record.
 * @param sort Sort field and sort order of a field to find specific record.
 * @param returns Promise<IAPIEntityResponse<ISubscription[]>>.
 */
export const getAllSubscriptionsByUserUUID = (
  skip = 0,
  limit = defaultTableLimit,
  searchTerm = '',
  sort = {
    sortField: 'createdAt',
    sortOrder: -1
  }
): Promise<IAPIEntityResponse<ISubscription[]>> => {
  const url = paginationRequest(`get/by/${LocalStorage.getXUserUUID()}`, {
    skip,
    limit,
    searchTerm
  });
  return httpClient.post(`${baseURLPath}${url}`, { sort });
};
